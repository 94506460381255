import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
let PiBaseVideoResizeMixin = class PiBaseVideoResizeMixin extends Vue {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    //#endregion
    //#region props
    //#endregion
    //#region data
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     * 改变video大小
     */
    async setVideoSize() {
        const video = this.$el?.querySelector('video');
        if (!video) {
            return;
        }
        // 优化 chrome86下切换布局卡顿
        window.requestAnimationFrame(() => {
            const { clientWidth, clientHeight } = video.parentElement;
            video.width = clientWidth;
            video.height = clientHeight;
        });
    }
    //#endregion
    //#region hooks
    mounted() {
        //切换页面大小
        const fnResize = () => {
            this.setVideoSize().catch((err) => console.error(err));
        };
        window.addEventListener('resize', fnResize);
        this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', fnResize));
        fnResize();
    }
};
PiBaseVideoResizeMixin = __decorate([
    Component
], PiBaseVideoResizeMixin);
export default PiBaseVideoResizeMixin;
