import { addListener } from './listener';
/**
 * 手机号匹配,不匹配置空,绑定
 * @param el
 */
export const phoneFilter = (el, tagName) => {
    const reg = /(13|14|15|16|17|18|19)\d{9}$/;
    const vnode = el.__vue__;
    const callback = () => {
        let value = el.value;
        if (!value) {
            return;
        }
        if (!reg.test(value)) {
            if (tagName === 'Input') {
                vnode.$emit('input', '');
            }
            else {
                el.value = '';
            }
        }
    };
    if (tagName === 'Input') {
        vnode.$on('on-blur', callback.bind(vnode));
    }
    else {
        addListener(el, 'blur', callback.bind(el));
        el.__input_filter__ = {
            blur: callback,
        };
    }
};
