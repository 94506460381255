import BetterScrollJs from 'better-scroll';
/**
 * 存储在 HTMLElement 的插件 BetterScroll 实例 key
 */
export const EL_BETTER_SCROLL = '_pi_betterScroll';
/**
 * 判断 better-scroll 插件是否初始化完成
 *
 * @param el
 */
export const isBetterScrollJsInitialized = function (el, options) {
    return !!getBetterScrollJsInstance(el, options);
};
/**
 * 获取 better-scroll 插件实例
 *
 * @param el
 */
export const getBetterScrollJsInstance = function (el, options) {
    const targets = getEle(el, options);
    if (!targets || !targets.length) {
        console.warn('未找到目标滚动元素！', el, options);
        return;
    }
    if (targets.length <= 1) {
        return targets[0][EL_BETTER_SCROLL];
    }
    return targets.map((p) => p[EL_BETTER_SCROLL]);
};
/**
 * 获取目标 dom
 * @param el
 * @param options
 */
const getEle = function (el, options) {
    if (options && options.selector) {
        return el.querySelectorAll(options.selector);
    }
    return [el];
};
/**
 * better-scroll 插件初始化
 *
 * @param el
 * @param options
 */
const initPlugin = function (el, options) {
    let promise = (options && options.initAsync) || Promise.resolve(void 0);
    promise
        .then(() => {
        const targets = getEle(el, options);
        if (!targets || !targets.length) {
            console.warn('未找到目标滚动元素！', el, options);
            return;
        }
        targets.forEach((target) => {
            //之前存在直接销毁
            let bsInstances = getBetterScrollJsInstance(target, options);
            if (bsInstances) {
                if (!Array.isArray(bsInstances)) {
                    bsInstances = [bsInstances];
                    bsInstances.forEach((bsInstance) => {
                        bsInstance && bsInstance.destroy();
                    });
                }
            }
            // 初始化样式
            const { classList } = target;
            classList.add('pi-bscroll');
            classList.add('pi-bscroll-position');
            // 根据 PC 端效果定制 options
            // TODO: 定制触控端/手机端 options
            // mouseWheel,observeDOM,pullDownRefresh,pullUpLoad,scrollbar,slide,wheel,zoom,nestedScroll,infinity,movable
            const defaultOptions = {
                scrollX: true,
                scrollY: true,
                freeScroll: true,
                bounce: false,
                bounceTime: 300,
                preventDefault: false,
                bindToWrapper: true,
                probeType: 2,
                observeDOM: true,
                mouseWheel: {
                    speed: 20,
                    invert: false,
                    easeTime: 300,
                },
                scrollbar: {
                    fade: true,
                    interactive: true,
                    scrollbarTrackClickable: true,
                },
                disableMouse: false,
                disableTouch: false, //开启触摸事件
            };
            options = Object.assign({}, defaultOptions, options);
            //用hODeepObjectMerge出bug,待处理
            // options = hODeepObjectMerge(
            //   {} as OptionScrollDirective,
            //   hODeepClone(defaultOptions),
            //   options!,
            // );
            // 初始化
            const bScroll = new BetterScrollJs(target, options);
            //绑定事件
            const events = options.events || {};
            let key;
            for (key in events) {
                if (events.hasOwnProperty(key)) {
                    const fn = events[key];
                    // TODO: 还有很多 hooks 需要支持扩展
                    fn && bScroll.on(key, fn);
                }
            }
            //将对象挂到html元素上
            target[EL_BETTER_SCROLL] = bScroll;
            //执行回调
            options.initScrollSuccess && options.initScrollSuccess(bScroll);
        });
    })
        .catch((err) => Promise.reject(err));
};
/**
 * Vue 指令初始化
 */
export const BetterScroll = {
    inserted(el, binding, vNode) {
        initPlugin(el, binding.value);
    },
    unbind(el, binding) {
        let bsInstances = getBetterScrollJsInstance(el, binding.value);
        if (bsInstances) {
            if (!Array.isArray(bsInstances)) {
                bsInstances = [bsInstances];
                bsInstances.forEach((bsInstance) => {
                    bsInstance && bsInstance.destroy && bsInstance.destroy();
                });
            }
        }
        // 重置样式
        if (el.classList) {
            el.classList.remove('pi-bscroll');
            el.classList.remove('pi-bscroll-position');
        }
    },
};
