import { hAjaxGetJson, UrlType, PermissionSettings, ConvertUrl, hErrorShow, } from 'bc$/main';
import Vue from 'vue';
class GetGlobalConfigClass {
    /**
     * 全局配置
     */
    static async getData(globalConfigUrl, callback) {
        if (!globalConfigUrl) {
            return true;
        }
        let isSuccess = false;
        //此处异步
        await hAjaxGetJson(ConvertUrl(globalConfigUrl, UrlType.Api))
            .instance.then(res => {
            isSuccess = true;
            if (!res) {
                // new Vue().$Message.error('获取全局配置错误！');
                console.error('获取全局配置失败！');
                return;
            }
            let { data: rData } = res;
            if (!rData) {
                new Vue().$Message.error('返回全局配置数据格式错误-1！');
                return;
            }
            let { data: dData } = rData;
            if (!dData) {
                new Vue().$Message.error('返回全局配置数据格式错误-2！');
                return;
            }
            const widgetInstances = dData[0].widgetInstances || [];
            // if (!widgetInstances) {
            //   new Vue().$Message.error('返回全局配置数据格式错误-3！');
            //   return;
            // }
            PermissionSettings.splice(0);
            widgetInstances.forEach(wgt => {
                wgt.actions.forEach(act => {
                    let state = 0;
                    if (act.clickable === true) {
                        state = 2;
                    }
                    else if (act.clickable === false) {
                        state = 1;
                    }
                    PermissionSettings.push({
                        wgtId: wgt.pkid,
                        code: act.code,
                        state,
                    });
                });
            });
            typeof callback === 'function' && callback(dData);
        })
            .catch((err) => hErrorShow({
            err,
            message: '获取全局配置错误！',
        }));
        //返回是否成功
        return isSuccess;
    }
}
export const GetGlobalConfig = GetGlobalConfigClass.getData;
