import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
let NodeParseMixin = class NodeParseMixin extends VueMixinBase {
    //#region data
    /**
     * 闪烁动画样式
     */
    flashingClassName = 'pi-svg-node-flashing';
    /**
     * 闪烁动画间隔 ms
     *
     * 暂时无效
     */
    nodeFlashingInterval = 500;
    //#endregion
    //#region methods
    /**
     * 图片路径动画
     */
    nodeImagePathHandler(ele, imgPath) {
        if (!ele) {
            return;
        }
        ele.setAttribute('href', imgPath);
    }
    /**
     * 文本动画
     */
    nodeTextHandler(ele, color, displayVal) {
        if (!ele) {
            return;
        }
        if (color) {
            const { style } = ele;
            style.fill = color;
        }
        this.updateSvgTxtEleDisplayVal(ele, displayVal);
    }
    /**
     * 画刷动画
     */
    nodeBrushHandler(ele, color) {
        if (!ele) {
            return;
        }
        // 暂时只支持 SVGRectElement 设置背景色
        if (ele instanceof SVGTextElement) {
            // TODO: 可能还有其他元素
            console.warn(`不支持 SVG Rect 元素设置背景色`, ele, color);
            return;
        }
        if (color) {
            const { style } = ele;
            style.fill = color;
        }
    }
    /**
     * 画笔动画
     */
    nodeStrokeHandler(ele, color) {
        if (!ele) {
            return;
        }
        if (color) {
            const { style } = ele;
            style.stroke = color;
        }
    }
    /**
     * 节点闪烁逻辑
     *
     * @param id
     * @param isFlashing
     */
    nodeFlashingHandler(ele, isFlashing) {
        if (!ele) {
            return;
        }
        const { flashingClassName } = this;
        if (isFlashing) {
            ele.classList.add(flashingClassName);
        }
        else {
            ele.classList.remove(flashingClassName);
        }
    }
    /**
     * 节点显示、隐藏逻辑
     *
     * @param ele
     * @param isShow
     */
    nodeShowHandler(ele, isShow) {
        if (!ele) {
            return;
        }
        ele.style.display = isShow ? '' : 'none';
    }
    /**
     * 命令链接
     *
     * @param PointId
     * @param PtPath
     * @param DynDesc
     * @param Param1
     * @param Param2
     * @param Param3
     * @param Param4
     * @param Param5
     */
    nodeCustomCommand(PointId, PtPath, DynDesc, Param1, Param2, Param3, Param4, Param5) {
        // 对外扩展
        this.$emit('point-customer-command', {
            PointId,
            PtPath,
            DynDesc,
            Param1,
            Param2,
            Param3,
            Param4,
            Param5,
        });
    }
    /**
     * 更新 svg text dom 元素显示值
     *
     * @param id
     * @param displayValue
     */
    updateSvgTxtEleDisplayVal(dom, displayValue) {
        if (!dom || displayValue === undefined || displayValue === null) {
            console.warn(`无法查找到点对应的 svg element 或者显示的文本内容未知`);
            return;
        }
        // 文本类型  <text> <tspan/> </text>
        if (dom.tagName === 'text') {
            const { firstElementChild } = dom;
            if (firstElementChild) {
                // 对第一个元素内容进行替换
                firstElementChild.innerHTML = displayValue;
            }
            else {
                // 对 dom 内容进行替换
                dom.innerHTML = displayValue;
            }
        }
    }
};
NodeParseMixin = __decorate([
    Component
], NodeParseMixin);
export default NodeParseMixin;
