var PiBaseSvgVideoModal_1;
import { __decorate } from "tslib";
import { apiAjaxHandle, } from 'bc$/bases/api.base';
import { BcVersion, ConvertUrl, hAjaxGet, PiBaseModal, UrlType, VueBase, } from 'bc$/main';
import { Spin } from 'iview';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PiBaseModalMixin } from '../../mixins/pi-base-modal.mixin';
import PiBaseVideoCtrlPlay1 from '../../../pi-base-video-ctrl-play-1/pi-base-video-ctrl-play-1.component';
import PiBaseVideoCtrl from '../../../pi-base-video-ctrl/pi-base-video-ctrl.component';
import template from './pi-base-svg-video-modal.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-svg-video-modal.component.scss';
let PiBaseSvgVideoModal = PiBaseSvgVideoModal_1 = class PiBaseSvgVideoModal extends Mixins(VueBase, PiBaseModalMixin) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseSvgVideoModal';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseSvgVideoModal_1?.NAME,
        author: 'luot',
        title: '视频弹窗',
        version: BcVersion,
        updateDate: '2020-09-24',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 视频云台控制　URL
     */
    videoControlUrl;
    /**
     * 视频信息　URL
     */
    videoInfoUrl;
    /**
     * 视频动画信息
     */
    pointAnimateData;
    /**
     * 视频id (暂无作用)
     */
    id;
    //#endregion
    //#region data
    /**
     * 加载状态
     */
    isLoading = false;
    /**
     * 视频参数
     */
    options = {};
    /**
     * 右侧表单信息
     */
    formData = null;
    /**
     * 后台视屏url 是否配置错误
     */
    isVideoUrlError = false;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseSvgVideoModal_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    /**
     * 视频信息表单
     */
    get videoFormItems() {
        const { videoForm } = this.options;
        return videoForm;
    }
    /**
     * 视频地址
     */
    get videoUrl() {
        const { videoUrl } = this.options;
        return videoUrl || '';
    }
    /**
     * 弹窗狂赌
     */
    get modalWidth() {
        const { modalWidth } = this.options;
        return modalWidth || 1600;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     * 显示视频
     */
    onShowVideo() {
        this.isLoading = true;
        this.isVideoUrlError = false;
        const { pointAnimateData } = this;
        const cameraCode = pointAnimateData?.Param2 || '';
        if (!cameraCode) {
            this.isLoading = false;
            return;
        }
        this.reqVideoFormData(cameraCode)
            .then(({ formData, videoInfo }) => {
            const newOptions = {};
            newOptions.videoUrl = videoInfo.cameraUrl || '';
            newOptions.videoCode = videoInfo.cameraCode || '';
            newOptions.videoControlUrl = this.videoControlUrl || '';
            newOptions.id = this.id;
            newOptions.videoForm = formData;
            newOptions.params = pointAnimateData;
            newOptions.controlFlag = videoInfo.controlFlag;
            this.options = newOptions;
            if (!this.options.videoUrl) {
                this.isVideoUrlError = true;
            }
        })
            .catch((err) => {
            console.error(`摄像头信息获取失败，请联系系统管理员！${err}`);
        })
            .finally(() => {
            this.isLoading = false;
        })
            .catch((err) => {
            console.error(`摄像头信息获取失败，请联系系统管理员！${err}`);
        });
    }
    /**
     *获取摄像头表单信息信息
     *
     * @param id
     */
    reqVideoFormData(id) {
        if (!id) {
            return Promise.resolve({});
        }
        return new Promise((resolve, reject) => {
            this.apiGetV1VideoCameraDetail({ id }, {
                cbThen: (dData) => {
                    if (dData && dData[0]) {
                        const data = dData[0];
                        const { cameraVo, formDic } = data;
                        const formData = this.renderSvgVideoFormData(cameraVo, formDic);
                        const res = { formData, videoInfo: cameraVo };
                        resolve(res);
                    }
                },
                cbCatch: (err) => {
                    console.error(`解析SVG摄像头信息错误：${err}`);
                    resolve({});
                },
                cbFinally: () => {
                    this.isLoading = false;
                },
            });
        });
    }
    /**
     * 摄像头信息数据解析
     */
    renderSvgVideoFormData(cameraVo, formDicList) {
        if (!formDicList || formDicList.length === 0 || !cameraVo) {
            return [];
        }
        let result = [];
        formDicList.forEach((item) => {
            const tmpRes = {};
            const { label, field } = item;
            tmpRes.key = cameraVo[field] || '';
            tmpRes.label = label || '';
            result.push(tmpRes);
        });
        return result;
    }
    /**
     * 单个摄像头的信息
     * @param params ajax请求需要的参数对象
     * cameraId 摄像头id
     * @param options 自定义配置参数
     * @return OK
     */
    apiGetV1VideoCameraDetail(params, options) {
        const { videoInfoUrl } = this;
        if (!videoInfoUrl) {
            return;
        }
        const { id: cameraId } = params || {};
        // 请求地址
        let url_ = ConvertUrl(videoInfoUrl, UrlType.Api);
        if (cameraId === undefined || cameraId === null) {
            throw new Error("The parameter 'cameraId' must be defined and cannot be null.");
        }
        else {
            url_ += '?id=' + encodeURIComponent('' + cameraId) + '&';
        }
        url_ = url_.replace(/[?&]$/g, '');
        const { config = {} } = options || {};
        const ajaxInfo = hAjaxGet(url_, config);
        return apiAjaxHandle(false, ajaxInfo, '单个摄像头的信息', options);
    }
    //#endregion
    //#region hooks
    created() {
        this.onShowVideo();
    }
};
__decorate([
    Prop({ type: String, required: true })
], PiBaseSvgVideoModal.prototype, "videoControlUrl", void 0);
__decorate([
    Prop({ type: String, required: true })
], PiBaseSvgVideoModal.prototype, "videoInfoUrl", void 0);
__decorate([
    Prop({ type: Object, required: true })
], PiBaseSvgVideoModal.prototype, "pointAnimateData", void 0);
__decorate([
    Prop({ type: String, required: true })
], PiBaseSvgVideoModal.prototype, "id", void 0);
PiBaseSvgVideoModal = PiBaseSvgVideoModal_1 = __decorate([
    Component({
        template,
        components: {
            PiBaseModal,
            PiBaseVideoCtrl,
            PiBaseVideoCtrlPlay1,
            Spin,
        },
    })
], PiBaseSvgVideoModal);
export default PiBaseSvgVideoModal;
