/**
 * 栅格内成员的类型
 */
export var GridMemberEnum;
(function (GridMemberEnum) {
    /**
     * 布局行
     */
    GridMemberEnum[GridMemberEnum["Row"] = 0] = "Row";
    /**
     * 布局列
     */
    GridMemberEnum[GridMemberEnum["Col"] = 1] = "Col";
    /**
     * 非布局组件
     */
    GridMemberEnum[GridMemberEnum["Wgt"] = 2] = "Wgt";
})(GridMemberEnum || (GridMemberEnum = {}));
