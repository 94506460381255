var PiBaseFullCalendarHeader_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase, BcVersion } from 'bc$/main';
import template from './pi-base-full-calendar-header.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-full-calendar-header.component.scss';
import { dateFunc } from '../dataMap/dateFunc';
let PiBaseFullCalendarHeader = PiBaseFullCalendarHeader_1 = class PiBaseFullCalendarHeader extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    // public static NAME = 'PiBaseFullCalendarHeader';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: 'PiBaseFullCalendarHeader',
        author: 'zhanjx',
        title: '无标题',
        version: BcVersion,
        updateDate: '2021-11-03',
        description: '',
    };
    //#endregion
    //#region props
    currentDate;
    titleFormat;
    firstDay;
    monthNames;
    //#endregion
    //#region data
    /**
     * 年月日标题信息
     */
    title = '';
    leftArrow = '<';
    rightArrow = '>';
    headDate = new Date();
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseFullCalendarHeader_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region 函数类属性
    //#endregion
    //#region watch
    'watch.currentDate'(val) {
        if (!val) {
            return;
        }
        this.headDate = val;
        // console.log('currentDate', val);
        // this.headDate = JSON.parse(JSON.stringify(val))
    }
    //#endregion
    //#region methods
    /**
     * 切换上个月
     */
    goPrev() {
        this.headDate = this.changeMonth(this.headDate, -1);
        this.dispatchEvent();
    }
    /**
     * 切换下个月
     */
    goNext() {
        this.headDate = this.changeMonth(this.headDate, 1);
        this.dispatchEvent();
    }
    /**
     * 更改月份
     * @param date
     * @param num
     * @returns
     */
    changeMonth(date, num) {
        const dt = new Date(date);
        return new Date(dt.setMonth(dt.getMonth() + num));
    }
    /**
     * 传递事件
     */
    dispatchEvent() {
        this.title = dateFunc.format(this.headDate, this.titleFormat, this.monthNames);
        const startDate = dateFunc.getStartDate(this.headDate);
        const curWeekDay = startDate.getDay();
        // 1st day of this monthView
        let diff = parseInt(this.firstDay) - curWeekDay;
        if (diff) {
            diff -= 7;
        }
        startDate.setDate(startDate.getDate() + diff);
        // the month view is 6*7
        const endDate = dateFunc.changeDay(startDate, 41);
        // 1st day of current month
        const currentDate = dateFunc.getStartDate(this.headDate);
        this.$emit('change', dateFunc.format(startDate, 'yyyy-MM-dd'), dateFunc.format(endDate, 'yyyy-MM-dd'), dateFunc.format(currentDate, 'yyyy-MM-dd'), this.headDate);
    }
    //#endregion
    //#region hooks
    created() {
        this.dispatchEvent();
    }
};
__decorate([
    Prop({
        type: Date,
        default: () => new Date(),
    })
], PiBaseFullCalendarHeader.prototype, "currentDate", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseFullCalendarHeader.prototype, "titleFormat", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0,
    })
], PiBaseFullCalendarHeader.prototype, "firstDay", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseFullCalendarHeader.prototype, "monthNames", void 0);
__decorate([
    Watch('currentDate', { immediate: true, deep: true })
], PiBaseFullCalendarHeader.prototype, "watch.currentDate", null);
PiBaseFullCalendarHeader = PiBaseFullCalendarHeader_1 = __decorate([
    Component({
        template,
    })
], PiBaseFullCalendarHeader);
export default PiBaseFullCalendarHeader;
