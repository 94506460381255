import { PermissionSettings } from 'bc$/bases/config.base';
/**
 * 权限指令处理器
 *
 * @param el
 * @param binding
 * @param vnode
 */
const PermissionHandler = (el, binding, vnode) => {
    //输入参数
    const options = binding.value;
    if (!options) {
        console.error('未提供v-has参数！');
        return;
    }
    const { oldValue } = binding;
    try {
        // 参数未更新，无需处理
        if (JSON.stringify(oldValue) === JSON.stringify(options)) {
            return;
        }
    }
    catch (err) {
        console.error(`v-has指令解析错误：${err}`);
    }
    //获取按钮权限类别和id
    let { id, wgtId, code } = options;
    //检测类别
    if (typeof code !== 'string' || !code.length) {
        console.error('未提供正确的v-has参数 code！');
        return;
    }
    //获取组件实例的操作权限
    let permission = undefined;
    //是否自定义id模式,这时候会忽略组件id
    if (id) {
        permission = PermissionSettings.find((p) => p.id === id && p.code === code);
    }
    else {
        //操作权限是配置到组件实例上的，所以需要组件id
        if (typeof wgtId !== 'string' || !wgtId) {
            const context = vnode.context;
            if (context) {
                wgtId = context?.widgetInfo?.pkid;
            }
            if (typeof wgtId !== 'string' || !wgtId) {
                console.error('未提供正确的v-has参数 wgtId！');
                return;
            }
        }
        permission = PermissionSettings.find((p) => p.wgtId === wgtId && p.code === code);
    }
    //设置渲染方式
    switch (permission?.state) {
        //2：可点击
        case 2:
            break;
        //1：不可点击
        case 1:
            el.setAttribute('disabled', 'disabled');
            break;
        //0：不渲染
        // case 0:
        default:
            const pNode = el.parentNode;
            pNode && pNode.removeChild(el);
            break;
    }
};
//v-has="{code:'add', wgtId:'0'}"
export const Permission = {
    // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
    inserted: (el, binding, vnode) => {
        PermissionHandler(el, binding, vnode);
    },
    // // 指令所在组件的 VNode 及其子 VNode 全部更新后调用。
    // componentUpdated: (el, binding, vnode) => {
    //   PermissionHandler(el, binding, vnode);
    // },
};
