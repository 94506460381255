import { hNumToFixed } from 'bc$/utils/number.helper';
import { hConvertAmount } from 'bc$/utils/amount.helper';
import { hDDate2String, hDString2Date } from 'bc$/utils/date.helper';
// let utilHelper = {
//   install: function (Vue: any) {
//     for (let key in utilArr) {
//       Vue.filter(key, (utilArr as any)[key])
//     }
//   },
// }
// let utilHelper = {
//   fNumToFixed: Vue.filter('hNumToFixed', hNumToFixed)
// }
let utilHelper = {
    fNumToFixed: {
        install: function (Vue) {
            Vue.filter('fNumToFixed', hNumToFixed);
        },
    },
    fConvertAmount: {
        install: function (Vue) {
            Vue.filter('fConvertAmount', hConvertAmount);
        },
    },
    fDate2String: {
        install: function (Vue) {
            Vue.filter('fDate2String', hDDate2String);
        },
    },
    fString2Date: {
        install: function (Vue) {
            Vue.filter('fString2Date', hDString2Date);
        },
    },
};
export const { fNumToFixed: fNumToFixed, fConvertAmount: fConvertAmount, fDate2String: fDate2String, fString2Date: fString2Date, } = utilHelper;
