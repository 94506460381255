var PiBasePasswordModify_1;
import { __decorate } from "tslib";
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { BcVersion } from 'bc$/bases/config.base';
import { hAjaxPostJson, hCrypSha256, hErrorShow, PiStatusEnum, UrlType, VueBase, hVCheckPwd, ApiUrlSetting, } from 'bc$/main';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import { Button, Dropdown, DropdownItem, DropdownMenu, Form, FormItem, Input, } from 'iview';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
// tslint:disable-next-line: no-var-requires
import template from './pi-base-password-modify.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-password-modify.component.scss';
let PiBasePasswordModify = PiBasePasswordModify_1 = class PiBasePasswordModify extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBasePasswordModify';
    /**
     * 关于信息
     */
    static ABOUT = {
        author: 'wuzp',
        name: PiBasePasswordModify_1?.NAME,
        title: '修改密码组件',
        version: BcVersion,
        updateDate: '2019-07-06',
        description: '',
    };
    //#endregion
    //#region props
    oldPwdLabel;
    newPwdLabel;
    newPwdCheckLabel;
    showCancelBtn;
    cancelBtnText;
    okBtnText;
    submitChange;
    /**
     * 自定义密码修改回调
     */
    customPasswordChangeCallback;
    isPostBody;
    isInitSetFocus;
    /**
     * 错误底部边距
     */
    itemErrBottom;
    /**
     * 校验新密码属性函数
     */
    validateNewPasswordProp;
    //#endregion
    //#region data
    //#region 函数类属性
    //#endregion
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    //原密码错误提示
    isShowErrorNotice = false;
    //表单参数
    formData = {
        oldPassword: '',
        newPassword: '',
        newPasswordCheck: '',
    };
    formValidate = {};
    //#region computed
    get about() {
        return PiBasePasswordModify_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * 最终错误底部边距
     */
    get targetItemErrBottom() {
        return this.formData.newPassword ? this.itemErrBottom : 24;
    }
    /**
     * 修改按钮可用状态
     */
    get isOkBtnDisabled() {
        const { formData } = this;
        return (!formData.oldPassword ||
            !formData.newPassword ||
            !formData.newPasswordCheck);
    }
    //#endregion
    //#region 函数类属性
    /** 密码验证 */
    validateNewPassword = (rule, value, callback) => {
        const vStr = hVCheckPwd(value);
        if (vStr) {
            callback(new Error(vStr));
        }
        else {
            callback();
        }
    };
    /** 密码验证 */
    validateNewPasswordCheck = (rule, value, callback) => {
        if (this.formData.newPassword !== value) {
            callback(new Error('两次输入密码不一致'));
        }
        else {
            callback();
        }
    };
    //#endregion
    //#region watch
    'watch.oldPassword'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.isShowErrorNotice = false;
    }
    //#endregion
    //#region methods
    /**
     * 提交表单
     */
    handleSubmit() {
        this.$refs['form']
            .validate(async (valid) => {
            if (valid) {
                await this.changePassword();
            }
            // else {
            //   this.$Message.error('表单验证不通过');
            // }
        })
            .catch((err) => {
            console.log(err);
        });
    }
    /**
     *取消表单
     */
    handleReset() {
        this.$refs['form'].resetFields();
        this.$emit('closePasswordModify');
    }
    /**
     * 获取内置密码集
     */
    getBuiltInPasswordCollection() {
        const { newPassword, oldPassword, } = this.formData;
        return {
            unEncryption: {
                newPassword,
                oldPassword,
            },
            sha256Encryption: {
                newPassword: hCrypSha256(newPassword),
                oldPassword: hCrypSha256(oldPassword),
            },
        };
    }
    //提交密码修改
    async changePassword() {
        const { submitChange, customPasswordChangeCallback, } = this;
        if (customPasswordChangeCallback) {
            const passwordCollection = this.getBuiltInPasswordCollection();
            await customPasswordChangeCallback(passwordCollection)?.then(() => {
                this.$emit('on-success');
                this.$emit('closePasswordModify');
            });
            return;
        }
        let params = {
            newPassword: hCrypSha256(this.formData.newPassword),
            oldPassword: hCrypSha256(this.formData.oldPassword),
        };
        if (submitChange) {
            await submitChange(params, {
                cbThen: () => {
                    this.$emit('on-success');
                    this.$emit('closePasswordModify');
                },
            });
            return;
        }
        return this.resetAjax('changePassword', () => {
            const unConvertUrl = ApiUrlSetting.AccountInfo.ModifyPassword ||
                '/system-manage-service/v1/user/upd_password';
            const url = this.convertUrl(unConvertUrl, UrlType.Api);
            const ajaxInfo = this.isPostBody
                ? hAjaxPostJson(url, params)
                : hAjaxPostJson(url, null, { params });
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    this.$emit('on-success');
                    this.$emit('closePasswordModify');
                    this.$Message.success('密码修改成功');
                }
                else if (data.status === PiStatusEnum.OtherError) {
                    this.isShowErrorNotice = true;
                    this.$Message.error('密码修改失败');
                }
                else {
                    this.$Message.error('密码修改失败');
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
            }));
            return ajaxInfo;
        });
    }
    /**
     * 验证表单
     */
    doformValidate() {
        this.formValidate = {
            oldPassword: [
                { required: true, message: '原密码不能为空', trigger: 'blur' },
            ],
            newPassword: [
                { required: true, message: '新密码不能为空', trigger: 'blur' },
                { validator: this.validateNewPasswordProp ?? this.validateNewPassword, trigger: 'blur' },
            ],
            newPasswordCheck: [
                { required: true, message: '验证密码不能为空', trigger: 'blur' },
                { validator: this.validateNewPasswordCheck, trigger: 'blur' },
            ],
        };
    }
    /**
     * 设置焦点
     */
    setFocus() {
        this.$nextTick(() => {
            const input = document.querySelector('.pi-widget-pi-base-password-modify input[type=text]:enabled');
            input && input.focus();
        });
    }
    //#endregion
    //#region hooks
    created() {
        //设置数据源弹窗显示的可选项内容
        this.doformValidate();
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
    }
    mounted() {
        //设置焦点
        this.isInitSetFocus && this.setFocus();
    }
};
__decorate([
    Prop({
        type: String,
        default: '原密码:',
    })
], PiBasePasswordModify.prototype, "oldPwdLabel", void 0);
__decorate([
    Prop({
        type: String,
        default: '新密码:',
    })
], PiBasePasswordModify.prototype, "newPwdLabel", void 0);
__decorate([
    Prop({
        type: String,
        default: '确认新密码:',
    })
], PiBasePasswordModify.prototype, "newPwdCheckLabel", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBasePasswordModify.prototype, "showCancelBtn", void 0);
__decorate([
    Prop({
        type: String,
        default: '取消',
    })
], PiBasePasswordModify.prototype, "cancelBtnText", void 0);
__decorate([
    Prop({
        type: String,
        default: '确定',
    })
], PiBasePasswordModify.prototype, "okBtnText", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBasePasswordModify.prototype, "submitChange", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBasePasswordModify.prototype, "customPasswordChangeCallback", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBasePasswordModify.prototype, "isPostBody", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBasePasswordModify.prototype, "isInitSetFocus", void 0);
__decorate([
    Prop({
        type: Number,
        default: 24,
    })
], PiBasePasswordModify.prototype, "itemErrBottom", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBasePasswordModify.prototype, "validateNewPasswordProp", void 0);
__decorate([
    Watch('formData.oldPassword')
], PiBasePasswordModify.prototype, "watch.oldPassword", null);
PiBasePasswordModify = PiBasePasswordModify_1 = __decorate([
    Component({
        components: {
            Dropdown,
            DropdownMenu,
            DropdownItem,
            Form,
            FormItem,
            Input,
            Button,
        },
        template,
        mixins: [WindowEventMixin],
    })
], PiBasePasswordModify);
export default PiBasePasswordModify;
