import { __decorate } from "tslib";
import { Tooltip } from 'iview';
import { Component } from 'vue-property-decorator';
import { PiSvgAnimateTypeEnum, PiSvgCommandAnimateEnum, } from '../pi-base-graphics-configuration-svg.enum';
import { VueMixinBase } from './vue-mixin.base';
let PointToolTipMixin = class PointToolTipMixin extends VueMixinBase {
    //#region data
    /**
     * Tooltip 相关绑定数据
     */
    pointTooltipOptions = {
        /**
         * 最后hover的 HTML Element
         */
        lastHoverEl: null,
        /**
         * 显示位置
         */
        placement: 'top',
        /**
         * 显示状态
         */
        isShow: false,
        /**
         * tooltip data
         */
        data: [],
        /**
         * 样式
         */
        style: {
            left: '0px',
            top: '0px',
            width: '0px',
            height: '0px',
        },
    };
    //#endregion
    //#region methods
    /**
     * 日历浮动提示挂载后事件
     */
    svgTooltipMounted() {
        const tooltipComp = this.$refs['svg-point-tooltip'];
        if (!tooltipComp) {
            return;
        }
        // 自行实现添加 tooltip-popper 样式
        const popperEl = tooltipComp.$refs['popper'];
        popperEl && popperEl.classList.add('pi-svg-tooltip-pop');
    }
    /**
     * 设备点hover事件绑定处理器
     *
     * @param el
     * @param data
     */
    tooltipEventListener(event) {
        const { pointTooltipOptions } = this;
        pointTooltipOptions.isShow = false;
        const el = (event.target || event.srcElement);
        // 处理鼠标快速移动，触发多次hover逻辑
        let { lastHoverEl } = pointTooltipOptions;
        if (el && el !== lastHoverEl) {
            const oldId = lastHoverEl?.getAttribute('id');
            oldId && this.removeTimeOut(`tooltipEventListener_${oldId}`);
        }
        const id = el?.getAttribute('id');
        if (!id) {
            return;
        }
        lastHoverEl = el;
        this.resetTimeOut(`tooltipEventListener_${id}`, () => {
            // 查找出对应的视频动画数据
            let videoAnimationData = this.getDynDataByiIdType(id, PiSvgAnimateTypeEnum.Video);
            // 存在视频动画配置
            if (videoAnimationData) {
                const { Param1, // 'popwnd_view'
                Param2: videoId, // 摄像机ID
                Param3: infoJson, // 浮动提示信息 JSON数组
                 } = videoAnimationData;
                // 不存在摄像机 ID或者视频 param1 不为浮动提示, 则不处理
                if (Param1 !== PiSvgCommandAnimateEnum.VideoToolTip || !videoId) {
                    return;
                }
                // 浮动信息数据
                const tooltipInfo = [`摄像机ID：${videoId}`];
                try {
                    if (infoJson) {
                        const parseInfo = JSON.parse(infoJson);
                        if (Array.isArray(parseInfo)) {
                            tooltipInfo.push(...parseInfo);
                            pointTooltipOptions.data = tooltipInfo;
                        }
                        this.tooltipPositionHandler(el);
                        pointTooltipOptions.isShow = true;
                    }
                }
                catch (err) {
                    console.error(`解析视频动画浮动提示信息错误，请检查SVG配置！${err}`);
                }
            }
        }, 100);
    }
    /**
     *  tooltip 显示位置
     */
    tooltipPositionHandler(el) {
        // 处理toolTip位置
        const svg = this.svgContentEl;
        if (!svg) {
            return;
        }
        const domRect = el.getBoundingClientRect();
        const { width, height, left, top } = domRect;
        this.pointTooltipOptions.style = {
            width: width + 'px',
            height: height + 'px',
            left: left + 'px',
            top: top + 'px',
        };
        // // TODO: 这里位置算法有问题，位置存在便宜，待优化
        // const svgRect = svg.getBoundingClientRect(),
        //   group = svg.firstElementChild as SVGGElement,
        //   groupRect = group.getBoundingClientRect(),
        //   svgOffsetTop = svgRect.top,
        //   svgOffsetLeft = svgRect.left,
        //   elOffset = el.getBoundingClientRect(),
        //   elOffsetTop = elOffset.top,
        //   elOffsetBottom = elOffset.bottom,
        //   elOffsetLeft = elOffset.left,
        //   elOffsetRight = elOffset.right,
        //   svgCoreX = (groupRect.left + groupRect.right) / 2,
        //   svgCoreY = (groupRect.top + groupRect.bottom) / 2,
        //   coreX = (elOffsetLeft + elOffsetRight) / 2,
        //   coreY = (elOffsetTop + elOffsetBottom) / 2;
        // let distanceHeight = 0,
        //   distanceWidth = 0;
        // //通过判断象限来识别显示位置
        // const { pointTooltipOptions } = this;
        // if (coreX > svgCoreX) {
        //   //第一象限
        //   if (coreY <= svgCoreY) {
        //     pointTooltipOptions.placement = 'left-start';
        //     distanceHeight = elOffsetTop - svgOffsetTop;
        //     distanceWidth = elOffsetLeft - svgOffsetLeft;
        //   } else {
        //     //第四象限
        //     pointTooltipOptions.placement = 'left-end';
        //     distanceWidth = elOffsetLeft - svgOffsetLeft;
        //     distanceHeight = elOffsetTop - svgOffsetTop;
        //   }
        // } else {
        //   //第二象限
        //   if (coreY <= svgCoreY) {
        //     pointTooltipOptions.placement = 'right-start';
        //     distanceWidth = elOffsetRight - svgOffsetLeft;
        //     distanceHeight = elOffsetBottom - svgOffsetTop;
        //   }
        //   //第三象限
        //   else {
        //     pointTooltipOptions.placement = 'right-end';
        //     distanceWidth = elOffsetRight - svgOffsetLeft;
        //     distanceHeight = elOffsetTop - svgOffsetTop;
        //   }
        // }
        // // 修改 placement 值 重绘tooltip
        // const toolTipComp = this.$refs['svg-point-tooltip'] as Tooltip;
        // const tooltipEl = toolTipComp.$el as HTMLElement;
        // const tooltipStyle = tooltipEl.style;
        // tooltipStyle.top = `${distanceHeight}px`;
        // tooltipStyle.left = `${distanceWidth}px`;
        // toolTipComp.$nextTick(() => {
        //   const popperJS = (toolTipComp as any)['popperJS'];
        //   popperJS &&
        //     (popperJS['options']['placement'] = pointTooltipOptions.placement);
        //   (toolTipComp as any)['updatePopper']();
        // });
    }
};
PointToolTipMixin = __decorate([
    Component({
        components: {
            Tooltip,
        },
    })
], PointToolTipMixin);
export default PointToolTipMixin;
