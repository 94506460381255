var PiBaseRingGraph_1;
import { __decorate } from "tslib";
import { VueBase } from 'bc$/main';
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BcVersion } from 'bc$/bases/config.base';
import { WidgetInstanceEntity } from 'bc$/entities/layout';
import template from './pi-base-ring-graph.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-ring-graph.component.scss';
import { Spin, Dropdown } from 'iview';
import VEcharts from 'vue-echarts-v3';
import { fnEChartsOptionsFactory } from '../../contents/echarts/pi-options-factory';
import { registerEchartsTheme } from '../../contents/echarts/pi-themes';
import { hODeepObjectMerge, hODeepClone, hAjaxGet, hErrorShow, } from 'bc$/utils';
let PiBaseRingGraph = PiBaseRingGraph_1 = class PiBaseRingGraph extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseRingGraph';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseRingGraph_1?.NAME,
        author: 'caiQw',
        title: '曲线折线图',
        version: BcVersion,
        updateDate: '2019-08-9',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * echart数据 请求地址
     */
    url;
    /**
     * option参数配置,属性值的优先级都次于以下单独传入的值
     */
    option;
    /**
     * y轴横向坐标值
     */
    graphic;
    /**
     * 标题
     */
    title;
    /**
     * 副标题
     */
    subTitle;
    /**
     * line值的列表组合
     */
    seriesData;
    /**
     * 图例颜色
     */
    color;
    /**
     * 鼠标移动是否开启动画
     */
    hoverAnimation;
    //#endregion
    //#region data
    /**
     * legend的data配置项
     */
    legendData = [];
    /**
     * series的配置参数
     */
    seriesConfig = {
        name: '',
        type: 'pie',
        radius: ['30%', '40%'],
        // 是否直接显示分类标签
        label: {
            normal: {
                show: false,
                position: 'center',
            },
        },
        data: [],
    };
    /**
     * echarts配置数据
     */
    ecConfig = {
        theme: 'pi-default',
        loading: true,
        options: fnEChartsOptionsFactory({
            // 标题
            title: {
                text: this.title || '',
                subtext: this.subTitle || '',
                x: 'center', //位置默认居中
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}: {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                textStyle: {
                    color: 'auto',
                },
                right: '0',
                top: 'center',
                icon: 'stack',
                data: [],
            },
            // graphic 是原生图形元素组件
            graphic: [],
            series: [],
        }),
    };
    isShowProperty = false;
    /**
     * 参数
     */
    options = (() => {
        const options = new WidgetInstanceEntity();
        options.attrObj.Attr = {
            isShowDownLoad: false,
        };
        return options;
    })();
    //#endregion
    //#region computed
    get about() {
        return PiBaseRingGraph_1.ABOUT;
    }
    get titleAll() {
        return this.options.title;
    }
    get attr() {
        return this.options.attrObj.Attr;
    }
    //#endregion
    //#region 函数类属性
    /**
     * 属性工具栏菜单,任何组件都是使用自身的属性配置方法
     */
    toolbarShowProperty = () => {
        this.isShowProperty = true;
    };
    /**
     * 给外部调用执行联动的实现函数
     * @param data
     */
    toolbarCbLink = (data) => {
        //修改查询条件
    };
    //#endregion
    //#region watch
    'watch.url'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            this.getChartData(newVal);
        }
    }
    'watch.option'(newVal, oldVal) {
        hODeepObjectMerge(this.ecConfig.options, this.option);
    }
    'watch.seriesData'(newVal, oldVal) {
        let legendData = [];
        let series = [];
        if (newVal instanceof Array && newVal[0] && newVal[0] instanceof Array) {
            // 当seriesData子项是数组时
            series =
                (newVal instanceof Array &&
                    newVal[0] &&
                    newVal[0] instanceof Array &&
                    newVal.map((item, index) => {
                        let seriesConfig = hODeepClone(this.seriesConfig);
                        this.$set(seriesConfig, 'data', item || []);
                        // 将series配置项与传进来的series对象进行合并
                        hODeepObjectMerge(seriesConfig, this.ecConfig.options.series[index] || {});
                        item.forEach((ele) => {
                            legendData.push(ele.name || '');
                            this.legendData.push(ele);
                        });
                        return seriesConfig;
                    })) ||
                    [];
            this.$set(this.ecConfig.options, 'series', series);
        }
        else if (newVal instanceof Array && newVal[0] instanceof Object) {
            // 当seriesData子项是对象时
            let seriesConfig = hODeepClone(this.seriesConfig);
            this.$set(seriesConfig, 'data', newVal || []);
            // 将series配置项与传进来的series对象进行合并
            hODeepObjectMerge(seriesConfig, this.ecConfig.options.series[0] || {});
            newVal.forEach((item, index) => {
                legendData.push(item.name || '');
                this.legendData.push(item);
                if (this.color) {
                    switch (Object.prototype.toString.call(this.color)) {
                        case '[object Array]':
                            item.itemStyle = {
                                color: this.color[index] || '#999',
                            };
                            break;
                        case '[object String]':
                            item.itemStyle = {
                                color: (typeof this.color === 'string' && this.color) || '#999',
                            };
                            break;
                        default:
                            break;
                    }
                }
                typeof this.hoverAnimation === 'boolean' &&
                    (item.hoverAnimation = this.hoverAnimation);
            });
            this.$set(this.ecConfig.options, 'series', seriesConfig);
        }
        this.$set(this.ecConfig.options.legend, 'formatter', (name) => {
            let target = 1;
            let total = 0;
            for (let i = 0, length = this.legendData.length; i < length; i++) {
                if (this.legendData[i].name === name) {
                    target = this.legendData[i].value;
                }
                total = total + Number(this.legendData[i].value);
            }
            let arr = [
                name +
                    '     ' +
                    target +
                    '     ' +
                    (((target / total) * 100).toFixed(2) + '%'),
            ];
            return arr.join('\n');
        });
        this.$set(this.ecConfig.options.legend, 'data', legendData);
    }
    'watch.graphic'(newVal, oldVal) {
        this.ecConfig.options.graphic = newVal;
    }
    'watch.title'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            this.$set(this.ecConfig.options.title, 'text', newVal);
        }
    }
    'watch.subTitle'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            this.$set(this.ecConfig.options.title, 'subtext', newVal);
        }
    }
    //#endregion
    //#region methods
    /**
     * 修改options数据
     */
    changeOptions(optionsNew) {
        // this.options = hODeepClone(optionsNew);
        // // this.widgetInfo.attrJson = JSON.stringify(optionsNew);
        // this.$emit('on-change-widget-info', this.options);
    }
    /**
     * 获取eChart的数据源，优先级最高
     * @param url 请求地址
     */
    getChartData(url) {
        hAjaxGet(url)
            .instance.then((res) => {
            const { data: rData, status } = res;
            if (!rData || status === 0) {
                return;
            }
            const { data: dData } = rData;
            if (!dData || !dData.length) {
                return;
            }
            // 图例数据
            let legendData = [];
            let seriesConfig = hODeepClone(this.seriesConfig);
            dData.forEach((item, index) => {
                legendData.push(item.name);
                // 当legend的颜色值不为空的时候设置样式
                if (this.color) {
                    switch (Object.prototype.toString.call(this.color)) {
                        case '[object Array]':
                            item.itemStyle = {
                                color: this.color[index] || '#999',
                            };
                            break;
                        case '[object String]':
                            item.itemStyle = {
                                color: (typeof this.color === 'string' && this.color) || '#999',
                            };
                            break;
                        default:
                            break;
                    }
                }
                typeof this.hoverAnimation === 'boolean' &&
                    (item.hoverAnimation = this.hoverAnimation);
            });
            this.seriesConfig.data = dData;
            this.$set(seriesConfig, 'data', dData || []);
            this.ecConfig.options.series.push(seriesConfig);
            this.ecConfig.options.legend.data = legendData;
        })
            .catch((err) => hErrorShow({
            err,
        }));
    }
    //#endregion
    beforeCreate() {
        registerEchartsTheme();
    }
    //#region hooks
    created() {
        //设置数据源弹窗显示的可选项内容
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
    }
    mounted() {
        this.$nextTick(() => {
            window.onresize = () => {
                this.$refs['vecharts'] && this.$refs['vecharts'].resize();
            };
        });
    }
    beforeDestroy() {
        window.onresize = null;
    }
};
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseRingGraph.prototype, "url", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseRingGraph.prototype, "option", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseRingGraph.prototype, "graphic", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseRingGraph.prototype, "title", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseRingGraph.prototype, "subTitle", void 0);
__decorate([
    Prop({
        type: Array,
    })
], PiBaseRingGraph.prototype, "seriesData", void 0);
__decorate([
    Prop({
        type: String || Array,
    })
], PiBaseRingGraph.prototype, "color", void 0);
__decorate([
    Prop({
        type: Boolean,
    })
], PiBaseRingGraph.prototype, "hoverAnimation", void 0);
__decorate([
    Watch('url', { immediate: true, deep: true })
], PiBaseRingGraph.prototype, "watch.url", null);
__decorate([
    Watch('option', { immediate: true, deep: true })
], PiBaseRingGraph.prototype, "watch.option", null);
__decorate([
    Watch('seriesData', { immediate: true, deep: true })
], PiBaseRingGraph.prototype, "watch.seriesData", null);
__decorate([
    Watch('graphic', { immediate: true, deep: true })
], PiBaseRingGraph.prototype, "watch.graphic", null);
__decorate([
    Watch('title', { immediate: true, deep: true })
], PiBaseRingGraph.prototype, "watch.title", null);
__decorate([
    Watch('subTitle', { immediate: true, deep: true })
], PiBaseRingGraph.prototype, "watch.subTitle", null);
PiBaseRingGraph = PiBaseRingGraph_1 = __decorate([
    Component({
        components: {
            Spin,
            Dropdown,
            VEcharts,
        },
        template,
    })
], PiBaseRingGraph);
export default PiBaseRingGraph;
