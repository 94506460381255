/// <summary>
/// ajax状态枚举
/// </summary>
export var PiStatusEnum;
(function (PiStatusEnum) {
    /// <summary>
    /// 成功
    /// </summary>
    PiStatusEnum[PiStatusEnum["Success"] = 0] = "Success";
    /// <summary>
    /// 参数不正确
    /// </summary>
    PiStatusEnum[PiStatusEnum["RequestParaError"] = 1] = "RequestParaError";
    /// <summary>
    /// JSON 参数格式错误
    /// </summary>
    PiStatusEnum[PiStatusEnum["JsonParaException"] = 2] = "JsonParaException";
    /// <summary>
    /// 数据库操作失败
    /// </summary>
    PiStatusEnum[PiStatusEnum["DbOperateError"] = 3] = "DbOperateError";
    /// <summary>
    /// 用户没有权限
    /// </summary>
    PiStatusEnum[PiStatusEnum["UserNoAuth"] = 4] = "UserNoAuth";
    /// <summary>
    /// 服务器忙
    /// </summary>
    PiStatusEnum[PiStatusEnum["SysSoBusy"] = 5] = "SysSoBusy";
    /// <summary>
    /// 用户未登录
    /// </summary>
    PiStatusEnum[PiStatusEnum["UserNoLogin"] = 6] = "UserNoLogin";
    /// <summary>
    /// 其他错误（自定义提示内容）
    /// </summary>
    PiStatusEnum[PiStatusEnum["OtherError"] = 7] = "OtherError";
    /// <summary>
    /// 当保存操作时需要进行再次确认
    /// </summary>
    PiStatusEnum[PiStatusEnum["NeedConfirm"] = 8] = "NeedConfirm";
})(PiStatusEnum || (PiStatusEnum = {}));
