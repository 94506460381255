export const ProjectId = {
    /**
     * 存储多个项目公用的storage内容
     */
    publicId: 'e7b9158a5bec4b3c906a5aa451a058ab',
    /**
     * 存储当前项目的storage内容
     */
    privateId: '', //fc65b843c52b49c3b488d2f49d970a1c
};
let privateId = '';
Object.defineProperty(ProjectId, 'privateId', {
    get() {
        return privateId || location.origin;
    },
    set(value) {
        privateId = value;
    },
});
const storage = {
    localPublic: {},
    localPrivate: {},
    sessionPublic: {},
    sessionPrivate: {},
};
/**
 * 项目公用的localStorage数据
 */
Object.defineProperty(storage, 'localPublic', {
    get() {
        return {
            getItem: (key) => {
                const id = ProjectId.publicId;
                return getItem(window.localStorage, id, key);
            },
            setItem: (key, value) => {
                const id = ProjectId.publicId;
                setItem(window.localStorage, id, key, value);
            },
            removeItem: (...keys) => {
                const id = ProjectId.publicId;
                removeItem(window.localStorage, id, ...keys);
            },
        };
    },
});
/**
 * 当前项目私有的localStorage数据
 */
Object.defineProperty(storage, 'localPrivate', {
    get() {
        return {
            getItem: (key) => {
                const id = ProjectId.privateId;
                return getItem(window.localStorage, id, key);
            },
            setItem: (key, value) => {
                const id = ProjectId.privateId;
                setItem(window.localStorage, id, key, value);
            },
            removeItem: (...keys) => {
                const id = ProjectId.privateId;
                removeItem(window.localStorage, id, ...keys);
            },
        };
    },
});
/**
 * 项目公用的sessionStorage数据
 */
Object.defineProperty(storage, 'sessionPublic', {
    get() {
        return {
            getItem: (key) => {
                const id = ProjectId.publicId;
                return getItem(window.sessionStorage, id, key);
            },
            setItem: (key, value) => {
                const id = ProjectId.publicId;
                setItem(window.sessionStorage, id, key, value);
            },
            removeItem: (...keys) => {
                const id = ProjectId.publicId;
                removeItem(window.sessionStorage, id, ...keys);
            },
        };
    },
});
/**
 * 当前项目私有的sessionStorage数据
 */
Object.defineProperty(storage, 'sessionPrivate', {
    get() {
        return {
            getItem: (key) => {
                const id = ProjectId.privateId;
                return getItem(window.sessionStorage, id, key);
            },
            setItem: (key, value) => {
                const id = ProjectId.privateId;
                setItem(window.sessionStorage, id, key, value);
            },
            removeItem: (...keys) => {
                const id = ProjectId.privateId;
                removeItem(window.sessionStorage, id, ...keys);
            },
        };
    },
});
/**
 * 获取存储到storage的数据,可能为私有也可能为公有数据
 * @param storage
 * @param id 当前id前缀
 * @param key 保存的键名
 */
function getItem(storage, id, key) {
    const str = storage.getItem(id);
    if (!str) {
        return null;
    }
    try {
        const obj = JSON.parse(str);
        return obj[key];
    }
    catch {
        return null;
    }
}
/**
 * 设置存储到storage的数据,可能为私有也可能为公有数据
 * @param storage
 * @param id 当前id前缀
 * @param key 保存的键名
 * @param value 保存的键值
 */
function setItem(storage, id, key, value) {
    const str = storage.getItem(id);
    let result = JSON.stringify({
        [key]: value,
    });
    if (str) {
        try {
            const obj = JSON.parse(str);
            obj[key] = value;
            result = JSON.stringify(obj);
        }
        catch {
            result = JSON.stringify({ [key]: value });
        }
    }
    storage.setItem(id, result);
}
/**
 * 移除存储到storage的数据,可能为私有也可能为公有数据
 * @param storage
 * @param id 当前id前缀
 * @param keys 待删除的键
 */
function removeItem(storage, id, ...keys) {
    const str = storage.getItem(id);
    if (str) {
        let result;
        try {
            const obj = JSON.parse(str);
            keys.forEach((key) => {
                delete obj[key];
            });
            result = JSON.stringify(obj);
        }
        catch {
            result = '{}';
        }
        storage.setItem(id, result);
    }
}
export const { sessionPublic: hSessionPublic, sessionPrivate: hSessionPrivate, localPublic: hLocalPublic, localPrivate: hLocalPrivate, } = storage;
