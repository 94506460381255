var PiBaseImageSelect_1;
import { __decorate } from "tslib";
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { hODeepClone } from 'bc$/utils/object.helper';
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { Select, Icon, Input, Upload, Row, Col, Option, Button, ColorPicker, } from 'iview';
import { hAjaxGet, hAjaxDelete, UrlType, ConvertUrl, hErrorShow, WidgetBase, } from 'bc$/main';
import PiBaseModal from '../pi-base-modal/pi-base-modal.component';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import template from './pi-base-image-select.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-image-select.component.scss';
let PiBaseImageSelect = PiBaseImageSelect_1 = class PiBaseImageSelect extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseImageSelect';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseImageSelect_1?.NAME,
        author: 'caiqw',
        title: '图片选择组件',
        version: BcVersion,
        updateDate: '2019-05-22',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 打开图片选择模态框
     */
    isModalMask;
    /**
     * 获取图片列表的网址
     */
    imageListUrl;
    /**
     * 上传图片的网址
     */
    uploadUrl;
    /**
     * 删除图片的网址
     */
    deleteUrl;
    /**
     * 上传图片的参数
     */
    data;
    /**
     * 当前选中的image id
     */
    imageId;
    /**
     * 模态框标题
     */
    title;
    /**
     * 模态框宽度
     */
    width;
    /**
     * 图片最大字节
     */
    maxSize;
    /**
     * 图片大多上传张数
     */
    maxNum;
    /**
     * 是否隐藏模态框footer
     */
    footerHide;
    /**
     * 默认背景图片颜色
     */
    defaultBgColor;
    /**
     * 默认背景图片颜色
     */
    fieldMap;
    /**
     * 确认方法
     */
    onOkBtn;
    //#endregion
    //#region data
    /**
     * 是否关闭图标组件弹框
     */
    isShow = false;
    /**
     * 弹窗层级
     */
    zIndex = 1000;
    /**
     * 宽度
     */
    modalWidth = hReactiveUtil.convertToReactivePx(this.width);
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    isNone = false;
    /**
     * 图片框的背景色
     */
    bgColor = '#ccc';
    /**
     * image搜索关键词
     */
    searchImage = '';
    /**
     * 图片选中的分类
     */
    imageClassifyType = '';
    /**
     * 选中的image 的 id
     */
    imageSelected = '';
    /**
     * 当前选中的图标在该类下的索引
     */
    imageIndex = -1;
    /**
     * 选中的分类索引
     */
    classifyIndex = -1;
    /**
     * 第一次选中的图标在该类下的索引
     */
    fstImageIndex = -1;
    /**
     * 第一次选中的image id
     */
    fstImageSelected = '';
    /**
     * 第一次选中的分类
     */
    fstImageClassifyType = '';
    /**
     * 图片列表
     */
    imageList = [];
    /**
     * 上传图片时的data
     */
    uploadData = {};
    /**
     * 是否点击了删除按钮
     */
    isDelete = false;
    /**
     * 等待删除的图片
     */
    chooseDelImages = [];
    options = {
        titlePre: '',
        title: PiBaseImageSelect_1.ABOUT.title,
        titleSuf: '',
        isShowDownLoad: false,
    };
    //#endregion
    //#region computed
    get about() {
        return PiBaseImageSelect_1.ABOUT;
    }
    get titleAll() {
        const options = this.options;
        return `${options.titlePre}${options.title}${options.titleSuf}`;
    }
    /**
     * 转换为绝对路径，该路径会根据类型随机获取url 基础地址并添加子站点url
     */
    get currentUrl() {
        return ConvertUrl(this.uploadUrl, UrlType.Api);
    }
    //#endregion
    //#region watch
    'watch.data'(newVal, oldVal) {
        this.uploadData = hODeepClone(newVal);
    }
    'watch.isModalMask'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.isShow = newVal;
        if (!this.imageListUrl) {
            console.error('请传入imageListUrl参数');
            return;
        }
        else if (!this.deleteUrl) {
            console.error('请传入deleteUrl参数');
        }
        else if (!this.uploadUrl) {
            console.error('请传入uploadUrl参数');
        }
        this.getImageList();
    }
    'watch.isShow'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (!newVal) {
            this.$destroy();
        }
    }
    /**
     * 查询图标，并向父组件返回当前查询字段
     * @param val 新值
     * @param oVal 旧值
     */
    'watch.searchImage'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.judgeImageIsShow(newVal);
    }
    /**
     * 选择图标分类，并向父组件返回当前选中的分类
     * @param val 新值
     * @param oVal 旧值
     */
    'watch.imageClassifyType'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (oldVal) {
            this.getSelectedIndexByClassify(newVal);
        }
    }
    //#endregion
    //#region methods
    /**
     * 上传文件格式不正确
     * @param file 文件信息
     */
    handleFormatError(file) {
        this.$Notice.warning({
            title: '文件格式不正确',
            desc: '文件 ' + file.name + ' 格式不正确，请上传 jpg 或 png 格式的图片。',
        });
    }
    /**
     * 上传图片大小超出
     * @param file 文件信息
     */
    handleMaxSize(file) {
        this.$Notice.warning({
            title: '超出文件大小限制',
            desc: `文件${file.name}太大，不能超过${this.maxSize} 字节。`,
        });
    }
    /**
     * 判断图片个数是否超出
     */
    handleBeforeUpload() {
        let check = this.imageList.length < this.maxNum;
        if (!this.uploadUrl) {
            console.error('请传入uploadUrl参数');
        }
        else if (!check) {
            this.$Notice.warning({
                title: `最多只能上传 ${this.maxNum} 张图片。`,
            });
        }
        else {
            this.uploadData.imageClassifyType = this.imageClassifyType;
        }
        return check;
    }
    /**
     * 图片上传错误时做的处理
     * @param error 错误信息
     */
    handleError(error) {
        console.error(error);
    }
    /**
     * 获取图片列表，并初始化show全部显示
     */
    getImageList() {
        const reqUrl = this.convertUrl(this.imageListUrl, UrlType.Api);
        return this.resetAjax('getImageList', () => {
            const ajaxInfo = hAjaxGet(reqUrl);
            ajaxInfo.instance
                .then((res) => {
                const dData = res.data.data;
                const { fieldMap } = this;
                if (fieldMap) {
                    const keys = Object.keys(fieldMap.get);
                    dData.forEach((item) => {
                        item.list.forEach((imageItem) => {
                            keys.forEach((key) => {
                                const key2 = fieldMap[key];
                                imageItem[key2] = imageItem[key];
                                delete imageItem[key];
                            });
                        });
                    });
                }
                dData.forEach((item) => {
                    item.list.forEach((imageItem) => {
                        imageItem.show = true;
                    });
                });
                this.imageList = dData;
                this.getSelectedIndex(this.imageId);
            })
                .catch((err) => hErrorShow({
                err,
            }));
            return ajaxInfo;
        });
    }
    /**
     * 上传成功后的返回新的图片数组
     */
    handleSuccess(res, file, fileList) {
        try {
            res.forEach((item) => {
                let imageTypeIndex = this.imageList.findIndex((p) => item.id === p.id);
                if (~imageTypeIndex) {
                    item.list.forEach((imageItem) => {
                        imageItem.show = true;
                    });
                    this.imageList[imageTypeIndex].list = this.imageList[imageTypeIndex].list.concat(item.list);
                }
            });
        }
        catch (err) {
            console.error(err);
        }
    }
    /**
     * 获取分类
     * @param className 传进来的 className
     */
    getClassifyName(className) {
        let reg = new RegExp(` pi-type-([^ ]+)`);
        const arr = reg.exec(className);
        return (arr && arr[1]) || '';
    }
    /**
     * 切换分类时获取当前分类的索引
     * @param classifyId 分类的id
     */
    getSelectedIndexByClassify(classifyId) {
        this.classifyIndex = this.imageList.findIndex((p) => p.id === classifyId);
        if (this.fstImageClassifyType === classifyId) {
            this.imageIndex = this.fstImageIndex;
            this.imageSelected = this.fstImageSelected;
            this.chooseDelImages = [this.imageSelected];
        }
        else {
            this.imageIndex = -1;
            this.imageSelected = '';
            this.chooseDelImages = [];
        }
    }
    /**
     * 获取当前选中的索引值（imageIndex），当前选中图标的class（imageIndex）
     * @param className 当前选中图标的class
     */
    getSelectedIndex(imageId) {
        // 当前选中image分类索引
        let classifyIndex = -1;
        //当前选中的image在该类中的索引
        let index = -1;
        for (let i = 0, length = this.imageList.length; i < length; i++) {
            index = this.imageList[i].list.findIndex((p) => p.id === imageId);
            if (~index) {
                classifyIndex = i;
                break;
            }
        }
        // if (!~index || !~classifyIndex) {
        //   return;
        // }
        // 当前选中image分类
        const imageClassifyType = (this.imageClassifyType = this.imageList[classifyIndex].id) ||
            (this.imageList && this.imageList[0].id) ||
            '';
        // if (!imageClassifyType) {
        //   return;
        // }
        const imageObj = this.imageList.find((p) => p.id === imageClassifyType);
        // if (!imageObj) {
        //   return;
        // }
        const imageList = (imageObj && imageObj.list) || [];
        const curIcon = imageList[index];
        this.imageIndex = index;
        this.imageSelected = (curIcon && curIcon.id) || '';
        this.classifyIndex = classifyIndex;
        this.fstImageClassifyType = imageClassifyType;
        this.fstImageIndex = this.imageIndex;
        this.fstImageSelected = this.imageSelected;
        this.chooseDelImages = [this.imageSelected];
    }
    /**
     * 判断搜索关键词是否在image的 tag 关键词中
     * @param tag image原本的搜索关键词
     * @param key 用户的搜索关键词
     */
    getImageKey(tag, key) {
        if (!key || !tag) {
            return true;
        }
        const keyList = key.split(' ');
        for (let i = 0, length = keyList.length; i < length; i++) {
            if (keyList[i] && tag.indexOf(keyList[i]) !== -1) {
                return true;
            }
        }
        return false;
    }
    /**
     * 判断当前图标应该显示与否
     * @param key 当前图标
     */
    judgeImageIsShow(key) {
        this.imageList.forEach((item) => {
            item.list.forEach((imageItem) => {
                imageItem.show = this.getImageKey(imageItem.tag ? imageItem.tag : '', key);
            });
        });
    }
    /**
     * 删除图片
     */
    deleteImages() {
        if (this.chooseDelImages.length) {
            this.isDelete = true;
            this.$Modal.confirm({
                title: '',
                content: '<p style="color:red">确定删除选中的图片吗？<p>',
                onOk: () => {
                    let config = {
                        data: {
                            ids: this.chooseDelImages,
                        },
                    };
                    const { fieldMap } = this;
                    if (fieldMap) {
                        const keys = Object.keys(fieldMap.get);
                        const { data } = config;
                        keys.forEach((key) => {
                            const key2 = fieldMap[key];
                            data[key2] = data[key];
                            delete data[key];
                        });
                    }
                    const reqUrl = this.convertUrl(this.deleteUrl, UrlType.Api);
                    return this.resetAjax('deleteImages', () => {
                        const ajaxInfo = hAjaxDelete(reqUrl, config);
                        ajaxInfo.instance
                            .then((res) => {
                            if (res && res.status === 200) {
                                try {
                                    res.data.data.forEach((id) => {
                                        let imageIndex = this.imageList[this.classifyIndex].list.findIndex((p) => p.id === id);
                                        if (~imageIndex) {
                                            this.imageList[this.classifyIndex].list.splice(imageIndex, 1);
                                            if (id === this.imageSelected) {
                                                this.imageSelected = '';
                                                this.chooseDelImages = [];
                                            }
                                        }
                                    });
                                }
                                catch (err) {
                                    console.error(err, '请注意返回的data应该是一个数组');
                                }
                            }
                            this.chooseDelImages = [this.imageSelected];
                            this.isDelete = false;
                        })
                            .catch((err) => hErrorShow({
                            err,
                            showInPage: true,
                            context: this,
                            message: '图片删除失败！',
                        }));
                        return ajaxInfo;
                    });
                },
                onCancel: () => {
                    this.$Message.info('取消删除');
                    this.isDelete = false;
                    this.chooseDelImages = [this.imageSelected];
                },
            });
        }
    }
    /**
     * 选择图标
     * @param item 当前选中的图标
     * @param index 当前图标索引
     */
    chooseImage(e, item, index) {
        let ctrlKey = e.ctrlKey;
        if (ctrlKey && this.chooseDelImages.length > 0) {
            // 当按下ctrl键时
            let imageIndex = this.chooseDelImages.findIndex((p) => p === item.id);
            if (~imageIndex) {
                this.chooseDelImages.splice(imageIndex, 1);
            }
            else {
                this.chooseDelImages.push(item.id);
            }
        }
        else {
            this.imageSelected = item.id;
            this.imageIndex = index;
            this.chooseDelImages = [item.id];
        }
    }
    /**
     * 点击其它地方清除选中的哦图片
     */
    clearChoose() {
        this.imageSelected = '';
        this.chooseDelImages = [];
    }
    /**
     * 判断是否显示删除边框
     */
    judgeIsDel(id) {
        return (this.chooseDelImages.some((p) => p === id) &&
            (this.isDelete ? true : this.chooseDelImages.length > 1));
    }
    /**
     * 取消按钮
     */
    onCancel() {
        this.isShow = false;
    }
    /**
     * 确认按钮
     */
    onOk() {
        this.isShow = false;
        let res = {};
        if (this.imageSelected) {
            res = {
                id: this.imageSelected,
                classify: this.imageClassifyType,
            };
        }
        typeof this.onOkBtn === 'function' && this.onOkBtn(res);
    }
    onWindowResize() {
        this.modalWidth = hReactiveUtil.convertToReactivePx(this.width);
    }
    //#endregion
    //#region hooks
    created() {
        //监控弹窗状态,显示时置为上层
        this.zIndex = WidgetBase.iModalCount++;
        this.bgColor = this.defaultBgColor;
    }
    /**
     * 销毁实例前
     */
    beforeDestroy() {
        WidgetBase.iModalCount--;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseImageSelect.prototype, "isModalMask", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseImageSelect.prototype, "imageListUrl", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseImageSelect.prototype, "uploadUrl", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseImageSelect.prototype, "deleteUrl", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseImageSelect.prototype, "data", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseImageSelect.prototype, "imageId", void 0);
__decorate([
    Prop({
        type: String,
        default: '选择图片',
    })
], PiBaseImageSelect.prototype, "title", void 0);
__decorate([
    Prop({
        type: Number,
        default: 800,
    })
], PiBaseImageSelect.prototype, "width", void 0);
__decorate([
    Prop({
        type: Number,
        default: 2048,
    })
], PiBaseImageSelect.prototype, "maxSize", void 0);
__decorate([
    Prop({
        type: Number,
        default: Infinity,
    })
], PiBaseImageSelect.prototype, "maxNum", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseImageSelect.prototype, "footerHide", void 0);
__decorate([
    Prop({
        type: String,
        default: '#ccc',
    })
], PiBaseImageSelect.prototype, "defaultBgColor", void 0);
__decorate([
    Prop({
        type: Object,
    })
], PiBaseImageSelect.prototype, "fieldMap", void 0);
__decorate([
    Prop({
        type: Function,
        default: () => (res) => ({}),
    })
], PiBaseImageSelect.prototype, "onOkBtn", void 0);
__decorate([
    Watch('data', { immediate: true, deep: true })
], PiBaseImageSelect.prototype, "watch.data", null);
__decorate([
    Watch('isModalMask', { immediate: true, deep: true })
], PiBaseImageSelect.prototype, "watch.isModalMask", null);
__decorate([
    Watch('isShow', { immediate: true, deep: true })
], PiBaseImageSelect.prototype, "watch.isShow", null);
__decorate([
    Watch('searchImage')
], PiBaseImageSelect.prototype, "watch.searchImage", null);
__decorate([
    Watch('imageClassifyType', { immediate: true, deep: true })
], PiBaseImageSelect.prototype, "watch.imageClassifyType", null);
PiBaseImageSelect = PiBaseImageSelect_1 = __decorate([
    Component({
        components: {
            Select,
            Input,
            Row,
            Col,
            Option,
            Button,
            Icon,
            Upload,
            ColorPicker,
            PiBaseModal,
        },
        template,
        mixins: [WindowEventMixin],
    })
], PiBaseImageSelect);
export const PiBaseStaticImageSelect = {
    install: (Vue, options) => {
        function createDialogCmp(options) {
            const opt = {
                propsData: {
                    isModalMask: options.isModalMask || true,
                    imageListUrl: options.imageListUrl || '',
                    uploadUrl: options.uploadUrl || '',
                    deleteUrl: options.deleteUrl || '',
                    data: options.data || '',
                    maxSize: options.maxSize || 2048,
                    maxNum: options.maxNum || Infinity,
                    imageId: options.imageId || '',
                    title: options.title || '选择图片',
                    width: options.width || 800,
                    footerHide: options.footerHide || true,
                    defaultBgColor: options.defaultBgColor || '#ccc',
                    onOkBtn: options.onOkBtn || '',
                },
                destroyed: () => {
                    el && el.remove();
                },
            };
            const render = options.render;
            if (render && typeof render === 'function') {
                opt.render = function (createElement, context) {
                    const propsData = opt.propsData;
                    const optCreateEle = {
                        props: hODeepClone(propsData),
                    };
                    return createElement(PiBaseImageSelect, optCreateEle, [
                        render(createElement, context),
                    ]);
                };
            }
            const cmp = new PiBaseImageSelect(opt);
            const el = cmp.$mount().$el;
            const piParentNode = options.piParentNode || document.body;
            piParentNode.appendChild(el);
            return cmp;
        }
        function show(options) {
            let opt;
            switch (typeof options) {
                case 'string':
                    opt = {};
                    break;
                case 'object':
                    opt = options;
                    break;
                default:
                    console.error('参数错误！');
                    return;
            }
            return createDialogCmp(opt);
        }
        const property = (options && options.property) || '$piImageSelect';
        Vue.prototype[property] = show;
    },
};
