import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AddClass, HasClass, RemoveClass } from '../utils/dom';
import { default as Utils } from '../utils/utils';
let CellEditMixin = class CellEditMixin extends Vue {
    // cell edit
    cellEdit(e, callback, rowIndex, rowData, field) {
        let target = e.target, oldVal, editInput, editInputLen, actionFun, textAlign = '', childTarget;
        while ((target &&
            target.className &&
            target.className.indexOf('v-table-body-cell') === -1) ||
            !target.className) {
            target = target.parentNode;
        }
        // 子节点（span节点）
        childTarget = target.children[0];
        // 把子节点影藏掉
        childTarget.style.display = 'none';
        if (HasClass(target, 'cell-editing')) {
            return false;
        }
        AddClass(target, 'cell-editing');
        oldVal = childTarget.innerText.trim();
        if (target.style.textAlign) {
            textAlign = target.style.textAlign || '';
        }
        editInput = document.createElement('input');
        editInput.value = oldVal;
        editInput.className = 'cell-edit-input';
        editInput.style.textAlign = textAlign;
        editInput.style.width = '100%';
        editInput.style.height = 'calc(100% - 4px)';
        //editInput.style = `width:100%;height: 100%;text-align: ${textAlign};`;
        target.appendChild(editInput);
        editInput.focus();
        editInputLen = editInput.value.length;
        if (document.selection) {
            let ctr = editInput.createTextRange();
            ctr.moveStart('character', editInputLen);
            ctr.collapse();
            ctr.select();
        }
        else if (typeof editInput.selectionStart == 'number' &&
            typeof editInput.selectionEnd == 'number') {
            editInput.selectionStart = editInput.selectionEnd = editInputLen;
        }
        actionFun = function (e) {
            if (typeof e.keyCode === 'undefined' ||
                e.keyCode === 0 ||
                e.keyCode == 13) {
                if (HasClass(target, 'cell-editing')) {
                    RemoveClass(target, 'cell-editing');
                }
                else {
                    return false;
                }
                childTarget.style.display = '';
                // fixed this.value bug in IE9
                callback(editInput.value, oldVal);
                Utils.unbind(editInput, 'blur', actionFun);
                Utils.unbind(editInput, 'keydown', actionFun);
                target.removeChild(editInput);
            }
        };
        Utils.bind(editInput, 'blur', actionFun);
        Utils.bind(editInput, 'keydown', actionFun);
    }
    // 单元格点击
    cellEditClick(e, isEdit, rowData, field, rowIndex) {
        const that = this;
        if (isEdit) {
            // 单元格内容变化后的回调
            let onCellEditCallBack = function (newValue, oldVal) {
                that.cellEditDone &&
                    that.cellEditDone(newValue, oldVal, rowIndex, rowData, field);
            };
            this.cellEdit(e, onCellEditCallBack, rowIndex, rowData, field);
        }
    }
};
CellEditMixin = __decorate([
    Component
], CellEditMixin);
export default CellEditMixin;
