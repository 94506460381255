var PiBasePageListMenu_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import Vue from 'vue';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { BetterScroll } from 'bc$/directives/scroll';
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
//导入指定
Vue.directive('scroll', BetterScroll);
//导入组件
import { Modal, Layout, Button, Sider, Menu, MenuItem, Icon, Submenu, Row, Col, Select, Option, } from 'iview';
import { PiStatusEnum, hAjaxAll, UrlType, ApiUrlSetting, PiBaseStorePageModule, hErrorShow, hReactiveUtil, hODeepClone, } from 'bc$/main';
// tslint:disable-next-line: no-import-side-effect
import 'tree-component/dist/tree.min.css';
// import { default as PiBasePageListModal } from './pi-base-page-list-modal/pi-base-page-list-modal.component';
import template from './pi-base-page-list-menu.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-page-list-menu.component.scss';
let PiBasePageListMenu = PiBasePageListMenu_1 = class PiBasePageListMenu extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBasePageListMenu';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBasePageListMenu_1?.NAME,
        author: 'wuzp',
        title: '页面菜单组件',
        version: BcVersion,
        updateDate: '2019-05-30',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 收起菜单时的传入宽度
     */
    width;
    /**
     * 展开菜单时的传入宽度
     */
    widthExpand;
    rootMenuId;
    /**
     * 外部提供菜单内容
     */
    menusData;
    /**
     * 需要打开的指定页面的id
     */
    curPageId;
    //#endregion
    //#region data
    /**
     * 收起最小宽度
     */
    minWidth = 35;
    /**
     * 展开最小宽度
     */
    minWidthExpand = 155;
    /**
     * 菜单收起宽度
     */
    listWidth = Math.max(this.minWidth, hReactiveUtil.convertToReactivePx(this.width));
    /**
     * 菜单展开宽度
     */
    listWidthExpand = Math.max(this.minWidthExpand, hReactiveUtil.convertToReactivePx(this.widthExpand));
    /**
     * 是否渲染组件菜单
     */
    isRenderMenu = true;
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    curPageIdInner = this.curPageId;
    replaceHomeIcon = 'md-home';
    /**
     * 左边栏收缩
     */
    isCollapsed = true;
    /**
     * 显示弹窗
     */
    // isShowModalInChild = false;
    /**
     * 初始化展开页面
     */
    initOpenNames = [];
    /**
     * 初始化展开页面
     */
    initActiveName = '';
    /**
     * 显示的页面清单
     */
    menusDataInner = [];
    /**
     * 弹窗的数据
     */
    resultData = [
        {
            id: '0',
            text: '菜单管理',
            children: [],
            textIcon: '',
            parentPkId: '',
            orderNo: 0,
            subSystemId: '',
            pageName: '',
            state: {
                opened: true,
                selected: true,
                disabled: false,
                loading: false,
                highlighted: false,
                openable: true,
                dropPosition: 1 /* up */,
                dropAllowed: false,
            },
        },
    ];
    childTreeData = [];
    isShowComponent = false;
    //#endregion
    //#region computed
    get about() {
        return PiBasePageListMenu_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    get storePage() {
        return PiBaseStorePageModule;
    }
    //#endregion
    //#region watch
    'watch.width'(newVal) {
        this.changeListWidth();
    }
    'watch.minWidth'(newVal) {
        this.changeListWidth();
    }
    'watch.widthExpand'(newVal) {
        this.changeListWidthExpand();
    }
    'watch.minWidthExpand'(newVal) {
        this.changeListWidthExpand();
    }
    'watch.rootMenuId'(newVal, oldVal) {
        this.isRenderMenu = false;
        this.$nextTick(() => {
            this.isRenderMenu = true;
        });
    }
    'watch.curPageId'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (this.curPageIdInner !== newVal) {
            this.curPageIdInner = newVal;
            this.jumpPageByRouter();
        }
    }
    'watch.menusData'(newVal, oldVal) {
        this.menusDataInner.splice(0, this.menusDataInner.length, ...(newVal || []));
        this.$emit('updateAllPageInfo', this.menusDataInner);
    }
    'watch.menusDataInner'(newVal, oldVal) {
        if (!newVal || !newVal.length) {
            return;
        }
        this.isShowComponent = true;
        //转换后的menus
        this.childTreeData.splice(0);
        const { resultData } = this;
        resultData[0].children = this.getTreeMenus(newVal, this.rootMenuId);
        if (newVal.length > 0) {
            this.jumpPageByRouter();
        }
        this.childTreeData = hODeepClone(resultData);
    }
    // /**
    //  * 页面内路由跳转
    //  * @param newVal 页面点击传来的数据
    //  * @param oldVal
    //  */
    // @Watch('storePage.tapToPageId')
    // 'watch.storePage.tapToPageId'(newVal: string, oldVal: string) {
    //   if (newVal === oldVal) {
    //     return;
    //   }
    //   this.menusDataInner.forEach(item => {
    //     if (newVal === item.pkid) {
    //       this.curPageIdInner = item.pkid;
    //       this.$emit('updateCurrentPageInfo', item);
    //     }
    //   });
    // }
    //#endregion
    //#region methods
    /**
     * 鼠标放上去展开
     */
    collapsedSiderOut() {
        this.isCollapsed = false;
    }
    /**
     * 鼠标放上去收缩
     */
    collapsedSiderHide(e) {
        if (!this.isCollapsed) {
            const wrapper = this.$el.querySelector('.pi-base-page-list-menu-wrapper');
            if (wrapper) {
                const rect = wrapper.getBoundingClientRect();
                const { left, width, top, height } = rect;
                // const width = this.isCollapsed ? this.listWidth : this.listWidthExpand;
                const { pageX, pageY } = e;
                if (pageX > left &&
                    pageX < left + width &&
                    pageY > top &&
                    pageY < top + height) {
                    return;
                }
            }
        }
        this.isCollapsed = true;
    }
    /**
     * 触摸事件也处理展开和收缩
     */
    touchendSider(e) {
        if (!this.isCollapsed) {
            const wrapper = this.$el.querySelector('.pi-base-page-list-menu-wrapper');
            if (wrapper) {
                const rect = wrapper.getBoundingClientRect();
                const { left, top, height } = rect;
                const width = this.isCollapsed ? this.listWidth : this.listWidthExpand;
                const touch = e.changedTouches[0];
                const { pageX, pageY } = touch;
                if (pageX >= left &&
                    pageX <= left + width &&
                    pageY >= top &&
                    pageY <= top + height) {
                    return;
                }
            }
        }
        this.isCollapsed = !this.isCollapsed;
    }
    /**
     *
     * @param data
     */
    getTreeMenus(data, pid) {
        const result = [];
        let temp = [];
        for (let i = 0, length = data.length; i < length; i++) {
            if (data[i].parentPkId == pid) {
                const obj = {
                    text: data[i].pageName,
                    id: data[i].pkid,
                    menuId: data[i]?.meta?.menuId ?? data[i].pkid,
                    textIcon: data[i].icon,
                    parentPkId: data[i].parentPkId,
                    orderNo: data[i].orderNo,
                    subSystemId: data[i].subSystemId,
                    pageName: data[i].pageName,
                    pageComponentName: data[i].pageComponentName,
                    children: [],
                    state: {
                        opened: false,
                        selected: false,
                        disabled: false,
                        loading: false,
                        highlighted: false,
                        openable: false,
                        dropPosition: 1 /* up */,
                        dropAllowed: false,
                        isRender: data[i]?.meta?.isRender ?? true,
                    },
                };
                temp = this.getTreeMenus(data, data[i].pkid);
                if (temp.length > 0) {
                    obj.children = temp;
                    obj.state.openable = true;
                }
                result.push(obj);
            }
        }
        return result;
    }
    /**
     *
     * @param currentPageData
     * @param isLayer
     */
    handleClick(currentPageData, isLayer = false) {
        this.goToCurrentPage(currentPageData, isLayer);
    }
    /**
     * 根据路由参数跳转到指定页面
     */
    jumpPageByRouter() {
        const { resultData } = this;
        //获取指定页面的相关信息
        const fnGetCurPage = (pageId, pages) => {
            if (!pageId || !pages) {
                return null;
            }
            for (let i = 0, length = pages.length; i < length; i++) {
                const page = pages[i];
                if (page.id === pageId) {
                    return page;
                }
                const { children } = page;
                if (children) {
                    const result = fnGetCurPage(pageId, children);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        };
        //检测是否有需要打开指定的页面
        let pageTarget = null;
        const { curPageIdInner } = this;
        if (!pageTarget && curPageIdInner) {
            pageTarget = fnGetCurPage(curPageIdInner, resultData);
        }
        //数组不为空才有当前显示页面
        if (!pageTarget) {
            //获取第一个叶子节点
            const fnGetLeafNode = (menu) => {
                if (menu?.children?.length) {
                    const child = menu.children?.[0];
                    return fnGetLeafNode(child);
                }
                return menu;
            };
            pageTarget = fnGetLeafNode(resultData[0].children[0]);
        }
        //跳转页面
        const isLayer = !resultData ||
            !resultData[0] ||
            !resultData[0].children ||
            !resultData[0].children.length;
        this.goToCurrentPage(pageTarget, isLayer, true);
        //获取处于打开的菜单
        if (!pageTarget) {
            return;
        }
        const targetId = pageTarget.menuId;
        //记录之前打开的页面
        const initOpenNames = [...(this.initOpenNames || [])];
        const { rootMenuId, menusDataInner } = this;
        const getParIds = (id) => {
            if (!id || id === rootMenuId) {
                return;
            }
            const page = menusDataInner.find((p) => p.pkid === id);
            if (page) {
                //避免重复
                if (!initOpenNames.some((p) => p === id)) {
                    initOpenNames.push(id);
                }
                getParIds(page.parentPkId);
            }
        };
        getParIds(targetId);
        //设置当前打开的菜单
        this.initOpenNames = initOpenNames;
        this.initActiveName = targetId;
    }
    /**
     * 过滤获取渲染项
     */
    getRenderItem(menus) {
        return menus.filter(item => item.state.isRender);
    }
    /**
     *
     *跳转当前显示页面
     * @param currentPageData
     * @param isLayer 是否在2层以上
     */
    goToCurrentPage(currentPageData, isLayer, isTranslateQuery = false) {
        if (!currentPageData) {
            return;
        }
        let isLeaf = currentPageData.children && currentPageData.children.length > 0;
        //点击无子节点弹出去当前数据
        let item = this.convertCurrentPage(currentPageData);
        isTranslateQuery && (item.query = {});
        if (!isLeaf) {
            // this.curPageIdInner = item.pkid; //一开始并没有从外部传curPageId 所以在此赋值了
            this.$emit('updateCurrentPageInfo', item);
        }
        else {
            if (isLayer) {
                this.$emit('layerShowPage', item);
            }
            else {
                this.goToCurrentPage(currentPageData.children[0], true);
                return;
            }
        }
    }
    /**
     *
     * @param currentPageData 树结构转转换为基础数组
     */
    convertCurrentPage(currentPageData) {
        let item = {
            icon: currentPageData.textIcon,
            orderNo: currentPageData.orderNo,
            pageName: currentPageData.text,
            parentPkId: currentPageData.parentPkId,
            pkid: currentPageData.id,
            subSystemId: currentPageData.subSystemId,
            pageComponentName: currentPageData.pageComponentName || '',
        };
        return item;
    }
    /**
     *获取组件数据
     * @param url 布局url
     * @memberof PiBaseComponentMenu
     */
    getMenuData() {
        //加载菜单所有数据url
        const reqUrlMenusList = this.convertUrl(ApiUrlSetting.WidgetConfig.PageListMenus.MenusList, UrlType.Api);
        //加载运营商权限所有数据url
        const reqUrlOperatorMenusList = this.convertUrl(ApiUrlSetting.WidgetConfig.PageListMenus.OperatorMenusList, UrlType.Api);
        //加载运营商权限所有数据url
        const reqUrlOperatorConfigMenusList = this.convertUrl(ApiUrlSetting.WidgetConfig.PageListMenus.OperatorMenusListStatus, UrlType.Api);
        //ConfigAjaxInfoBase<Promise<AxiosResponse<SResultPagedEntity<any>>[]>>
        const ajaxInfo = hAjaxAll([
            reqUrlMenusList,
            reqUrlOperatorMenusList,
            reqUrlOperatorConfigMenusList,
        ].map((u, i) => {
            let config = {
                method: 'get',
                url: u,
            };
            //运营商权限列表需要带参数
            if (i === 1) {
                config.params = {
                    key1: 'page',
                    key2: 'opr_status',
                };
            }
            return config;
        }));
        ajaxInfo.instance
            .then((res) => {
            //接口是否都请求成功
            let isSuccess = res[0].data.status === PiStatusEnum.Success &&
                res[1].data.status === PiStatusEnum.Success &&
                res[2].data.status === PiStatusEnum.Success;
            //请求成功，则执行菜单数据获取
            if (!isSuccess) {
                return;
            }
            // 所有页面获取菜单信息
            let pageData = res[0].data.data;
            // 运营商权限获取菜单信息
            let pageOperatorData = res[1].data.data;
            //运营商审核状态获取信息
            let statusData = res[2].data.data;
            if (!statusData || statusData.length == 0) {
                //如果没有运营商权限，则直接弹出去
                this.menusDataInner = pageData;
            }
            else {
                //如果有运营商权限限制，则通过运营商id  获取页面信息
                let status = statusData[0];
                let operatorPageIds = [];
                let operatorMenus = [];
                let contrastNo = 0;
                for (let item of pageOperatorData) {
                    //运营商状态获取运营商商配置页面id
                    item.configKey3 == status
                        ? (operatorPageIds = item.value.split(','))
                        : contrastNo++;
                }
                //若运营商审核通过，则运营商审核状态在运营商限制权限那找不到，则返回所有数据
                if (contrastNo === pageOperatorData.length) {
                    this.menusDataInner = pageData;
                }
                else {
                    for (const pageMenu of pageData) {
                        //运营商商配置页面id得到页面菜单
                        for (const pageOperatorMenu of operatorPageIds) {
                            if (pageMenu.pkid === pageOperatorMenu) {
                                operatorMenus.push(pageMenu);
                            }
                        }
                    }
                    this.menusDataInner = operatorMenus;
                }
            }
            //把所有页面信息弹出去
            this.$emit('updateAllPageInfo', this.menusDataInner);
        })
            .catch((err) => hErrorShow({
            err,
        }));
        return ajaxInfo;
    }
    /**
     * 改变窗体大小
     */
    onWindowResize() {
        this.changeListWidth();
        this.changeListWidthExpand();
    }
    changeListWidth() {
        this.listWidth = Math.max(this.minWidth, hReactiveUtil.convertToReactivePx(this.width));
    }
    changeListWidthExpand() {
        this.listWidthExpand = Math.max(this.minWidthExpand, hReactiveUtil.convertToReactivePx(this.widthExpand));
    }
    //#endregion
    //#region hooks
    created() {
        //获取页面列表信息
        !this.menusData && this.getMenuData();
        // //添加页面后，改变菜单栏以及树结构
        // this.$on('addChildPage', (param: IPageData[]) => {
        //   this.menusDataInner = this.menusDataInner.concat(param);
        //   this.$Message.success('添加页面成功');
        // });
        // //删除页面后，改变菜单栏以及树结构
        // this.$on('deletePage', (params: string[]) => {
        //   for (let id of params) {
        //     this.menusDataInner.forEach((item, index) => {
        //       if (item.pkid === id) {
        //         this.menusDataInner.splice(index, 1);
        //       }
        //     });
        //   }
        // });
        // //修改页面后，改变菜单栏以及树结构
        // this.$on('changePage', (param: IPageData) => {
        //   this.menusDataInner.forEach((item, index) => {
        //     if (item.pkid === param.pkid) {
        //       item.pageName = param.pageName;
        //       item.icon = param.icon;
        //     }
        //   });
        //   this.$Message.success('修改页面成功');
        // });
        // this.$on('update:isShowModalInChild', (val: boolean) => {
        //   this.isShowModalInChild = val;
        // });
        // // // 转换后的menus
        // // this.getTreeMenus(this.data, this.resultData, 0);
    }
};
__decorate([
    Prop({
        type: Number,
        default: () => 50,
    })
], PiBasePageListMenu.prototype, "width", void 0);
__decorate([
    Prop({
        type: Number,
        default: 220,
    })
], PiBasePageListMenu.prototype, "widthExpand", void 0);
__decorate([
    Prop({
        type: String,
        default: '0',
    })
], PiBasePageListMenu.prototype, "rootMenuId", void 0);
__decorate([
    Prop({
        type: Array,
    })
], PiBasePageListMenu.prototype, "menusData", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBasePageListMenu.prototype, "curPageId", void 0);
__decorate([
    Watch('width', { immediate: true, deep: true })
], PiBasePageListMenu.prototype, "watch.width", null);
__decorate([
    Watch('minWidth', { immediate: true, deep: true })
], PiBasePageListMenu.prototype, "watch.minWidth", null);
__decorate([
    Watch('widthExpand', { immediate: true, deep: true })
], PiBasePageListMenu.prototype, "watch.widthExpand", null);
__decorate([
    Watch('minWidthExpand', { immediate: true, deep: true })
], PiBasePageListMenu.prototype, "watch.minWidthExpand", null);
__decorate([
    Watch('rootMenuId', { immediate: true, deep: true })
], PiBasePageListMenu.prototype, "watch.rootMenuId", null);
__decorate([
    Watch('curPageId', { immediate: true, deep: true })
], PiBasePageListMenu.prototype, "watch.curPageId", null);
__decorate([
    Watch('menusData', { deep: true, immediate: true })
], PiBasePageListMenu.prototype, "watch.menusData", null);
__decorate([
    Watch('menusDataInner', { deep: true, immediate: true })
], PiBasePageListMenu.prototype, "watch.menusDataInner", null);
PiBasePageListMenu = PiBasePageListMenu_1 = __decorate([
    Component({
        components: {
            Modal,
            Button,
            Layout,
            Sider,
            Menu,
            MenuItem,
            Icon,
            Submenu,
            Row,
            Col,
            Select,
            Option,
            // PiBasePageListModal,
        },
        template,
        mixins: [WindowEventMixin],
    })
], PiBasePageListMenu);
export default PiBasePageListMenu;
