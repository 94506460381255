/**
 * UserSessionStorage
 */
export var UserSessionStorage;
(function (UserSessionStorage) {
    UserSessionStorage["isUpdateToken"] = "is_update_token";
    UserSessionStorage["accessToken"] = "access_token";
    UserSessionStorage["refreshToken"] = "refresh_token";
    UserSessionStorage["expiresTime"] = "expires_time";
    UserSessionStorage["refreshUrl"] = "refresh_url";
    UserSessionStorage["targetUrl"] = "target_url";
    UserSessionStorage["userPkId"] = "user_pk_id";
    UserSessionStorage["hasAdmin"] = "has_admin";
    UserSessionStorage["userName"] = "user_name";
    UserSessionStorage["roleType"] = "role_type";
    UserSessionStorage["loginInfo"] = "login_info";
    UserSessionStorage["fullName"] = "full_name";
})(UserSessionStorage || (UserSessionStorage = {}));
