/**
 * 绑定事件监听
 * @param el
 * @param type
 * @param listener
 */
export const addListener = (el, type, listener) => {
    el.addEventListener(type, listener, false);
};
/**
 * 解绑事件监听
 * @param el
 * @param type
 * @param listener
 */
export const removeListener = (el, type, listener) => {
    el.removeEventListener(type, listener, false);
};
