// tslint:disable-next-line: no-var-requires
export let IconListAll = require('bc$/assets/fonts/iconfont/icon-list.json');
// tslint:disable-next-line: no-import-side-effect
import './contents/styles/index.scss';
// tslint:disable-next-line: no-import-side-effect
import './contents/themes/default/style.scss';
// tslint:disable-next-line: no-import-side-effect
import 'bc$/extends/js.extend';
// tslint:disable-next-line: no-import-side-effect
import 'bc$/directives';
export * from 'bc$/locales';
export * from 'bc$/bases';
export * from 'bc$/components';
export * from 'bc$/extends';
export * from 'bc$/filters';
export * from 'bc$/utils';
export * from 'bc$/mixins';
export * from 'bc$/views';
export * from 'bc$/enums';
export * from 'bc$/entities';
export * from 'bc$/urls';
export * from 'bc$/contents/echarts';
export * from 'bc$/store';
export * from 'bc$/tests';
export * from 'bc$/config';
