import { PiStatusEnum } from 'bc$/enums';
import axios from 'axios';
import { ApiUrlSetting } from 'bc$/urls';
import { PiBaseStoreUserModule, hTokenRemove, AjaxAbortName, } from 'bc$/main';
import { BaseConfigAxios } from 'bc$/config';
import { Message } from 'iview';
if (axios) {
    const interceptors = (axios.interceptors = axios.interceptors || {});
    const request = (interceptors.request = interceptors.request || {});
    const response = (interceptors.response = interceptors.response || {});
    !request.use &&
        (request.use = (onFulfilled, onRejected) => 0);
    !response.use &&
        (response.use = (onFulfilled, onRejected) => 0);
    axios.interceptors.request.use((config) => {
        config = config || {};
        //默认不缓存解决chrome同时并发相同接口导致串行的问题
        if (!config.cache) {
            config.params = config.params || {};
            config.params['_r'] = `${Math.random()}`.slice(2, 6);
        }
        if (!config.global) {
            // config.timeout = 6000;
            const token = PiBaseStoreUserModule.accessToken || '';
            const headers = config.headers || {};
            if (token) {
                headers[BaseConfigAxios.tokenField] = `${BaseConfigAxios.tokenType}${token}`;
            }
            headers['Accept-Language'] = 'zh-CN,zh;q=0.9,en;q=0.8';
            headers['Pragma'] = 'no-cache';
            headers['Accept'] =
                headers['Accept'] || 'application/json, text/plain, */*';
            headers['Content-Type'] = headers['Content-Type'] || 'application/json';
            config.headers = headers;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    let isJumping = false;
    axios.interceptors.response.use((res) => {
        return res;
    }, (err) => {
        const res = err.response ||
            {
                status: 200,
            };
        let config = err.config || {};
        const { ErrorCode } = ApiUrlSetting;
        if (!config.global) {
            switch (res.status) {
                case 200:
                    break;
                case 401:
                    let errContent = '登录已失效，请重新登录！';
                    if (!isJumping) {
                        isJumping = true;
                        if (res.data && res.data.status === PiStatusEnum.UserNoAuth) {
                            switch (res.data.msg) {
                                case 'ACCOUNT_BEEN_CROWDED_OUT':
                                    errContent = '账号在别处登录,您被迫下线！';
                                    //清除刷新token的定时器
                                    hTokenRemove();
                                    break;
                            }
                        }
                        setTimeout(() => {
                            Message.destroy();
                            Message.error(errContent);
                        }, 0);
                        const url401 = ErrorCode.Login;
                        if (url401) {
                            //2秒类不执行此类错误弹窗,然后跳转
                            setTimeout(() => {
                                //若目标地址跟现在地址一致，则不跳转
                                if (location.href !== url401) {
                                    isJumping = false;
                                    if (window.__POWERED_BY_QIANKUN__) {
                                        location.href = '/';
                                    }
                                    else {
                                        location.href = url401;
                                    }
                                }
                            }, 2000);
                            return Promise.reject({ message: AjaxAbortName });
                        }
                    }
                    setTimeout(() => {
                        Message.destroy();
                        Message.error(errContent);
                    }, 0);
                    break;
                case 403:
                    console.error('403错误', err);
                    const url403 = ErrorCode.C403;
                    if (url403) {
                        location.href = url403;
                    }
                    break;
                case 404:
                    console.error('404错误', err);
                    const url404 = ErrorCode.C404;
                    if (url404) {
                        location.href = url404;
                    }
                    break;
                case 500:
                    console.error('500错误', err);
                    const url500 = ErrorCode.C500;
                    if (url500) {
                        location.href = url500;
                    }
                    break;
                case 501:
                    console.error('501错误', err);
                    const url501 = ErrorCode.C501;
                    if (url501) {
                        location.href = ErrorCode.C501;
                    }
                    break;
                case 502:
                    console.error('502错误', err);
                    const url502 = ErrorCode.C502;
                    if (url502) {
                        location.href = ErrorCode.C502;
                    }
                    break;
                case 503:
                    console.error('503错误', err);
                    const url503 = ErrorCode.C503;
                    if (url503) {
                        location.href = ErrorCode.C503;
                    }
                    break;
            }
        }
        return Promise.reject(err);
    });
}
