var PiBaseUpload_1;
import { __decorate } from "tslib";
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import { Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { ConvertUrl, UrlType } from 'bc$/bases/config.base';
import { Upload } from 'iview';
import template from './pi-base-upload.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-upload.component.scss';
import PiBaseUploadProgress from '../pi-base-upload-progress/pi-base-upload-progress.component';
let PiBaseUpload = PiBaseUpload_1 = class PiBaseUpload extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseUpload';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseUpload_1?.NAME,
        author: 'wuzp',
        title: '资源上传',
        version: BcVersion,
        updateDate: '2019-06-25',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 上传url
     *  */
    url;
    /**
     * 是否多选
     *  */
    multiple;
    /**
     * 上传文件类型
     *  */
    accept;
    /**
     * 文件格式检查
     *  */
    format;
    /**
     *
     */
    maxSize;
    //#endregion
    //#endregion data
    fileList = [];
    /**
     * 上传进度
     */
    percent = 0;
    /**
     * 是否关闭进度条Modal
     */
    isCloseProgressModal = false;
    formatFileText = '';
    //#endregion
    //#region computed
    get about() {
        return PiBaseUpload_1.ABOUT;
    }
    get titleAll() {
        return PiBaseUpload_1?.NAME;
    }
    /**
     * 转换为绝对路径，该路径会根据类型随机获取url 基础地址并添加子站点url
     */
    get currentUrl() {
        return ConvertUrl(this.url, UrlType.Api);
    }
    //#endregion
    //#region methods
    /**
     * 上传文件格式不正确
     * @param file 文件信息
     */
    handleFormatError(file) {
        this.$Message.warning({
            content: '文件格式不正确',
        });
    }
    /**
     *上传成功
     *@memberof PiBaseUpload
     */
    handleSuccess(res, file, fileList) {
        //关闭进度条弹窗
        this.isCloseProgressModal = true;
        this.fileList.splice(0);
        this.fileList.push(...fileList);
        this.$emit('handleSuccess', this.fileList, file);
    }
    /**
     *删除文件
     *@memberof PiBaseUpload
     */
    handleRemove(file) {
        const fileList = this.$refs.upload.fileList;
        this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
    }
    /**
     *上传大小超过指定大小
     *@memberof PiBaseUpload
     */
    handleMaxSize(file) {
        // this.$Notice.warning({
        //   title: 'Exceeding file size limit',
        //   desc: 'File  ' + file.name + ' is too large, no more than ' + this.maxSize / 1024 + 'M.',
        // });
        this.$Message.warning({
            content: '文件超过指定大小',
        });
    }
    /**
     *上传进度
     *@memberof PiBaseUpload
     */
    handleProgress(event, file, fileList) {
        let timer = setInterval(() => {
            this.percent = Number(file.percentage.toFixed(0));
            if (file.percentage === 100) {
                clearInterval(timer);
            }
        });
        this.$emit('handleProgress', file);
    }
    //#endregion
    /**
     * 终止文件上传
     */
    beforeUpload() {
        this.openProgressModal();
        return true;
    }
    /**
     * 文件上传错误
     */
    HandleError() {
        this.isCloseProgressModal = true;
    }
    /**
     * 打开进度条弹出框
     */
    openProgressModal() {
        this.isCloseProgressModal = false;
        this.percent = 0;
        const modalInstance = this.$piModal({
            piParentNodeFn: this.$el.getRootNode,
            className: 'pi-transfer-modal',
            maskClosable: false,
            width: hReactiveUtil.convertToReactivePx(520),
            title: '文件上传进度22',
            footerHide: true,
            render: (h) => {
                return h(PiBaseUploadProgress, {
                    props: {
                        percent: this.percent,
                        isCloseProgressModal: this.isCloseProgressModal,
                    },
                    on: {
                        'on-close-Modal': () => {
                            modalInstance.isShowModal = false;
                        },
                    },
                });
            },
            renderHeader: (h) => {
                const head = h('div', {
                    style: {
                        color: '#1b91e9',
                    },
                    domProps: {
                        innerHTML: '文件上传进度条',
                    },
                });
                return h('div', {}, [head]);
            },
        });
    }
    //#endregion
    //#region hooks
    created() {
        this.formatFileText = this.format.join(', ');
        //设置数据源弹窗显示的可选项内容
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
        //先用默认值覆盖,再还原修改后的值
        // this.options = hODeepObjectMerge(
        //   this.options,
        //   this.widgetInfo.attrObj.Attr,
        // );
        // Object.assign(this.options, this.widgetInfo.attrObj.Attr);
    }
    mounted() {
    }
};
__decorate([
    Prop()
], PiBaseUpload.prototype, "url", void 0);
__decorate([
    Prop({
        default: false,
    })
], PiBaseUpload.prototype, "multiple", void 0);
__decorate([
    Prop({
        default: '',
    })
], PiBaseUpload.prototype, "accept", void 0);
__decorate([
    Prop({
        default: () => [],
    })
], PiBaseUpload.prototype, "format", void 0);
__decorate([
    Prop({
        default: 2048,
    })
], PiBaseUpload.prototype, "maxSize", void 0);
PiBaseUpload = PiBaseUpload_1 = __decorate([
    Component({
        components: {
            Upload,
        },
        template,
    })
], PiBaseUpload);
export default PiBaseUpload;
