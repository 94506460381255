export class LayoutWidgetEntity {
    /**
     * /front-service/v1/layout/widget/by_page
     */
    WidgetGetByPage;
    /**
     * /front-service/v1/layout/widget
     */
    WidgetGet;
    /**
     * /front-service/v1/layout/widget/upd
     */
    WidgetUpdate;
    /**
     * /front-service/v1/layout/widget/del
     */
    WidgetDelete;
}
