import { __decorate } from "tslib";
import { hConvertAttr } from 'bc$/utils';
import { PiUndefined } from 'bc$/decorators/pi-undefined.decorator';
/**
 * 行分割后台接口 - 部分参数 - 行添加
 */
export class RowUpdate {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    pkid;
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
    columns;
}
__decorate([
    PiUndefined()
], RowUpdate.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], RowUpdate.prototype, "attrJson", void 0);
__decorate([
    PiUndefined()
], RowUpdate.prototype, "columns", void 0);
/**
 * 行合并 - 部分参数 - 列信息
 */
export class ColumnUpdateParam {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    pkid;
    orderNo;
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], ColumnUpdateParam.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], ColumnUpdateParam.prototype, "orderNo", void 0);
__decorate([
    PiUndefined()
], ColumnUpdateParam.prototype, "attrJson", void 0);
/**
 * 行分割后台接口 - 部分参数 - 行更新
 */
export class RowUpdateParam {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    pkid;
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], RowUpdateParam.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], RowUpdateParam.prototype, "attrJson", void 0);
/**
 * 行合并后台接口 - 部分参数 - 行信息
 */
export class RowSpanParam {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 行 pkid
     */
    pkid;
    /**
     * 自定义数据
     */
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
    /**
     * 子列
     */
    columns;
}
__decorate([
    PiUndefined()
], RowSpanParam.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], RowSpanParam.prototype, "attrJson", void 0);
__decorate([
    PiUndefined()
], RowSpanParam.prototype, "columns", void 0);
/**
 * 行合并后台接口 - 部分参数 - 列信息
 */
export class ColumnParam {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 列 pkid
     */
    pkid;
    /**
     * 列自定义参数
     */
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
    /**
     * 列序号
     */
    orderNo;
}
__decorate([
    PiUndefined()
], ColumnParam.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], ColumnParam.prototype, "attrJson", void 0);
__decorate([
    PiUndefined()
], ColumnParam.prototype, "orderNo", void 0);
/**
 * 行分割后台接口 - 部分参数 - 行添加
 */
export class RowAddParam {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    orderNo;
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
    columns;
}
__decorate([
    PiUndefined()
], RowAddParam.prototype, "orderNo", void 0);
__decorate([
    PiUndefined()
], RowAddParam.prototype, "attrJson", void 0);
__decorate([
    PiUndefined()
], RowAddParam.prototype, "columns", void 0);
/**
 * 行分割后台接口 - 部分参数 - 列添加
 */
export class ColumnAddParam {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    orderNo;
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], ColumnAddParam.prototype, "orderNo", void 0);
__decorate([
    PiUndefined()
], ColumnAddParam.prototype, "attrJson", void 0);
/**
 * 后台请求 - 合并列 - columns
 */
export class ColumnSpanReqParamColumn {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 列pkid，新列不传该参数
     */
    pkid;
    /**
     * 排序序号
     */
    orderNo;
    /**
     * 自定义数据
     */
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], ColumnSpanReqParamColumn.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], ColumnSpanReqParamColumn.prototype, "orderNo", void 0);
__decorate([
    PiUndefined()
], ColumnSpanReqParamColumn.prototype, "attrJson", void 0);
/**
 * 后台请求 - 拆分列 - columns
 */
export class ColumnSplitReqParamColumn {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 列pkid，新列不传该参数
     */
    pkid;
    /**
     * 排序序号
     */
    orderNo;
    /**
     * 自定义数据
     */
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], ColumnSplitReqParamColumn.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], ColumnSplitReqParamColumn.prototype, "orderNo", void 0);
__decorate([
    PiUndefined()
], ColumnSplitReqParamColumn.prototype, "attrJson", void 0);
/**
 * 后台请求返回 - 拆分列 - columns
 */
export class ColumnSplitRespParamColumn {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 列 pkId
     */
    pkid;
    /**
     * 排序序号
     */
    orderNo;
    /**
     * 自定义数据
     */
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], ColumnSplitRespParamColumn.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], ColumnSplitRespParamColumn.prototype, "orderNo", void 0);
__decorate([
    PiUndefined()
], ColumnSplitRespParamColumn.prototype, "attrJson", void 0);
