var PiBaseMap_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { PiBaseStoreMapModule } from 'bc$/main';
import Vue from 'vue';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-map.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-map.component.scss';
import { PiBaseMapEvent } from './pi-base-map.enum';
let PiBaseMap = PiBaseMap_1 = class PiBaseMap extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseMap';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseMap_1?.NAME,
        author: 'hex',
        title: '地图组件',
        version: BcVersion,
        updateDate: '2020-05-18',
        description: '',
    };
    //#endregion
    //#region props
    options;
    //#endregion
    //#region data
    /**
     * 高德 map options：默认
     */
    defaultOptions = {
        marker: {
            map: {
                lng: 'lng',
                lat: 'lat',
            },
        },
    };
    /**
     * 高德 map options：自定义
     */
    customOptions = {};
    /**
     * 地图：实例
     */
    mapInstance = undefined;
    /**
     * 点聚合：实例
     */
    markerClustererInstance = undefined;
    /**
     * 信息弹窗：实例
     */
    infoWindowInstance = undefined;
    /**
     * Marker：对象集合
     */
    markerInstanceList = [];
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseMap_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return PiBaseMap_1?.NAME;
    }
    /**
     * 高德 map options:合并后
     */
    get mergedOptions() {
        return Object.assign({}, this.defaultOptions, this.options);
    }
    //#endregion
    //#region watch
    /**
     * 监听 标记数据
     * @param newValue
     */
    'watch.options.marker.data'(newValue) {
        this.addMarkerList(newValue);
    }
    //#endregion
    //#region methods
    /**
     * 初始化该组件逻辑
     */
    init() {
        this.loadMap()
            .then(() => {
            this.initMap();
        })
            .catch();
    }
    /**
     * 加载AMap JS
     */
    loadMap() {
        return new Promise((resolve, reject) => {
            if (typeof window.AMap !== 'undefined') {
                resolve(void 0);
                return;
            }
            // 加载AMap JS
            const script = document.createElement('script');
            script.src = PiBaseStoreMapModule.url;
            script.addEventListener('load', () => {
                if (typeof window.AMap !== 'undefined') {
                    resolve(void 0);
                }
                else {
                    reject();
                }
            });
            script.addEventListener('error', () => {
                reject();
            });
            script.addEventListener('abort', () => {
                reject();
            });
            document.body.appendChild(script);
        });
    }
    /**
     * 初始化地图，以及相关组件
     */
    initMap() {
        // let mapContainer = this.$el.querySelector('.pi-map');
        // 初始化地图
        this.mapInstance = new AMap.Map(this.$el, this.mergedOptions);
        // 地图加载完毕
        this.mapInstance.on('complete', () => {
            // 可优化
            if (this.mergedOptions) {
                const { data } = this.mergedOptions.marker;
                if (data) {
                    this.addMarkerList(data);
                }
            }
            // events
            const { on } = this.mergedOptions;
            if (on) {
                Object.keys(on).forEach((eventName) => {
                    const fn = on[eventName];
                    this.mapInstance.on(eventName, fn);
                });
            }
            this.$emit(PiBaseMapEvent.ON_COMPLETE, this.mapInstance);
            // 注册事件
        });
    }
    /**
     * 清除已有标记
     */
    clearMarker() {
        if (!this.mapInstance) {
            return;
        }
        // 清除聚合点标记
        this.markerClustererInstance && this.markerClustererInstance.clearMarkers();
        this.markerInstanceList.splice(0);
        this.mapInstance.clearMap();
    }
    /**
     * 添加Marker集合
     */
    addMarkerList(markerDataList) {
        if (!this.mapInstance) {
            return;
        }
        const { marker, infoWindow } = this.mergedOptions;
        if (!marker) {
            return;
        }
        // 清除已有标记
        this.clearMarker();
        // 遍历添加markerOptions
        markerDataList.forEach((markerData, index) => {
            let optionsExtend = {};
            // 自定义配置
            if (marker.optionsExtend && typeof marker.optionsExtend == 'function') {
                optionsExtend = marker.optionsExtend(markerData, index);
            }
            // 通用配置
            const mergedMarkerOptions = Object.assign({}, marker.options, optionsExtend);
            const { map, on } = marker;
            // position
            const lngField = map.lng;
            const latField = map.lat;
            let lng = markerData[lngField];
            let lat = markerData[latField];
            if (lng && lat) {
                mergedMarkerOptions.position = new AMap.LngLat(Number(lng), Number(lat));
            }
            // extData
            mergedMarkerOptions.extData = markerData;
            // instance
            const markerInstance = new AMap.Marker(mergedMarkerOptions);
            // infoWindow
            if (infoWindow &&
                (infoWindow.component || infoWindow.componentGenerator)) {
                const eventName = infoWindow.trigger || 'click';
                markerInstance.on(eventName, () => {
                    this.openInfoWindow(markerInstance);
                });
            }
            // events
            if (on) {
                Object.keys(on).forEach((eventName) => {
                    const fn = on[eventName];
                    markerInstance.on(eventName, fn);
                });
            }
            this.markerInstanceList.push(markerInstance);
        });
        // 在地图上添加标记
        this.mapInstance.add(this.markerInstanceList);
        // 初始化Clusterer
        this.initMarkerClusterer();
    }
    /**
     * 初始化Clusterer
     */
    initMarkerClusterer() {
        if (!this.mapInstance) {
            return;
        }
        this.mapInstance.plugin(['AMap.MarkerClusterer'], () => {
            if (!this.mapInstance || !this.mergedOptions.markerClusterer) {
                return;
            }
            const { options } = this.mergedOptions.markerClusterer;
            this.markerClustererInstance = new AMap.MarkerClusterer(this.mapInstance, this.markerInstanceList, options);
        });
    }
    /**
     * 打开信息弹窗
     * @param
     */
    openInfoWindow(marker) {
        if (!this.mapInstance || !this.mergedOptions.infoWindow) {
            return;
        }
        let { options, component, componentGenerator, } = this.mergedOptions.infoWindow;
        let piInfoWindow;
        if (componentGenerator && typeof componentGenerator === 'function') {
            piInfoWindow = componentGenerator(marker);
        }
        else if (component) {
            piInfoWindow = component;
        }
        let content;
        // component
        if (piInfoWindow) {
            const wrap = new Vue({
                render: (h) => h(piInfoWindow, {
                    props: { data: marker.getExtData(), map: this.mapInstance },
                }),
            }).$mount();
            content = wrap.$el;
        }
        // 合并配置
        const mergedOptions = Object.assign({
            content,
            offset: new AMap.Pixel(0, -36),
        }, options);
        //创建信息窗体
        this.infoWindowInstance = new AMap.InfoWindow(mergedOptions);
        this.infoWindowInstance.open(this.mapInstance, marker.getPosition());
    }
    //#endregion
    //#region hooks
    created() {
        this.init();
    }
};
__decorate([
    Prop({ type: Object })
], PiBaseMap.prototype, "options", void 0);
__decorate([
    Watch('options.marker.data', { immediate: true })
], PiBaseMap.prototype, "watch.options.marker.data", null);
PiBaseMap = PiBaseMap_1 = __decorate([
    Component({
        template,
    })
], PiBaseMap);
export default PiBaseMap;
