var PiBaseFullCalendar_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase, BcVersion } from 'bc$/main';
import template from './pi-base-full-calendar.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-full-calendar.component.scss';
import langSets from './components/dataMap/langSets';
import PiBaseFullCalendarHeader from './components/pi-base-full-calendar-header/pi-base-full-calendar-header.component';
import PiBaseFullCalendarBody from './components/pi-base-full-calendar-body/pi-base-full-calendar-body.component';
let PiBaseFullCalendar = PiBaseFullCalendar_1 = class PiBaseFullCalendar extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    // public static NAME = 'PiBaseFullCalendar';
    /**
     * 语言项
     */
    static lang = 'zh';
    /**
     * 第一天日期
     */
    static firstDay = 0;
    /**
     * 关于信息
     */
    static ABOUT = {
        name: 'PiBaseFullCalendar',
        author: 'zhanjx',
        title: '日历排班组件',
        version: BcVersion,
        updateDate: '2021-11-03',
        description: '',
    };
    //#endregion
    //#region data
    currentDate = new Date();
    //#endregion
    //#region props
    holidayArr;
    events;
    lang;
    firstDay;
    titleFormat;
    monthNames;
    weekNames;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseFullCalendar_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region 函数类属性
    //#endregion
    //#region 静态方法
    static setLang(lang) {
        PiBaseFullCalendar_1.lang = lang;
    }
    static getLang() {
        return PiBaseFullCalendar_1.lang;
    }
    static setFirstDay(firstDay) {
        PiBaseFullCalendar_1.firstDay = firstDay;
    }
    static getFirstDay() {
        return PiBaseFullCalendar_1.firstDay;
    }
    //#endregion
    //#region watch
    'watch.lang'(val) {
        PiBaseFullCalendar_1.lang = val;
        console.log('PiBaseFullCalendar.lang->', PiBaseFullCalendar_1.lang, '\t titleFormat->', this.titleFormat);
    }
    'watch.firstDay'(val) {
        PiBaseFullCalendar_1.firstDay = val;
        console.log('PiBaseFullCalendar.firstDay->', PiBaseFullCalendar_1.firstDay, '\t weekNames->', this.weekNames);
    }
    //#endregion
    //#region methods
    /**
     * 月份更改事件传递
     * @param start
     * @param end
     * @param currentStart
     * @param current
     */
    emitChangeMonth(start, end, currentStart, current) {
        const fcBody = this.$refs['fcBody'];
        if (!!fcBody) {
            fcBody.showMore = false;
            fcBody.showDuty = false;
        }
        // console.log('currentDate 2', this.currentDate);
        this.currentDate = current;
        // console.log('currentDate 3', this.currentDate);
        this.$emit('changeMonth', start, end, currentStart);
    }
    /**
     * 菜单项点击事件传递
     * @param event
     * @param jsEvent
     * @param pos
     */
    emitEventClick(event, jsEvent, pos) {
        this.$emit('eventClick', event, jsEvent, pos);
    }
    /**
     * 日期点击事件传递
     * @param day
     * @param jsEvent
     */
    emitDayClick(day, jsEvent) {
        this.$emit('dayClick', day, jsEvent);
    }
    /**
     * 更多点击事件传递
     * @param day
     * @param events
     * @param jsEvent
     */
    emitMoreClick(day, events, jsEvent) {
        this.$emit('moreClick', day, events, jsEvent);
    }
};
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseFullCalendar.prototype, "holidayArr", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseFullCalendar.prototype, "events", void 0);
__decorate([
    Prop({
        type: String,
        default: 'zh',
    })
], PiBaseFullCalendar.prototype, "lang", void 0);
__decorate([
    Prop({
        type: [Number, String],
        validator(val) {
            const res = parseInt(val);
            return res >= 0 && res <= 6;
        },
        default: 0,
    })
], PiBaseFullCalendar.prototype, "firstDay", void 0);
__decorate([
    Prop({
        type: String,
        default: () => langSets[PiBaseFullCalendar_1.lang].titleFormat,
    })
], PiBaseFullCalendar.prototype, "titleFormat", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => langSets[PiBaseFullCalendar_1.lang].monthNames,
    })
], PiBaseFullCalendar.prototype, "monthNames", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => {
            const arr = langSets[PiBaseFullCalendar_1.lang].weekNames;
            return arr
                .slice(PiBaseFullCalendar_1.firstDay)
                .concat(arr.slice(0, PiBaseFullCalendar_1.firstDay));
        },
    })
], PiBaseFullCalendar.prototype, "weekNames", void 0);
__decorate([
    Watch('lang', { immediate: true })
], PiBaseFullCalendar.prototype, "watch.lang", null);
__decorate([
    Watch('firstDay', { immediate: true })
], PiBaseFullCalendar.prototype, "watch.firstDay", null);
PiBaseFullCalendar = PiBaseFullCalendar_1 = __decorate([
    Component({
        template,
        components: {
            'fc-header': PiBaseFullCalendarHeader,
            'fc-body': PiBaseFullCalendarBody,
        },
    })
], PiBaseFullCalendar);
export default PiBaseFullCalendar;
