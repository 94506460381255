var PiBaseComponentMenu_1;
import { __decorate } from "tslib";
// tslint:disable-next-line:no-var-requires
let LayoutData = require('bc$/contents/register-config/layout.config.json');
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { PiStatusEnum } from 'bc$/enums/system/pi-status.enum';
import { AjaxAbortName, ApiUrlSetting, ConvertUrl, hErrorShow, hReactiveUtil, UrlType, } from 'bc$/main';
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { PiBaseStoreLayoutModule } from 'bc$/store/modules/pi-base-layout.store';
import { PiBaseStorePageModule } from 'bc$/store/modules/pi-base-page.store';
import { PiBaseStoreWidgetModule } from 'bc$/store/modules/pi-base-widget.store';
import { hAjaxGet, hAjaxPostJson } from 'bc$/utils/ajax.helper';
import { Col, Icon, Layout, Menu, MenuItem, Option, Row, Select, Sider, Submenu, } from 'iview';
import { Component, Mixins } from 'vue-property-decorator';
import { LayoutMode } from '../../enums/layout/layout.enum';
import { default as PiBaseCenter } from '../pi-base-center/pi-base-center.component';
import { revertDom } from '../pi-base-center/pi-base-center.utils';
import { default as PiBaseColumn } from '../pi-base-column/pi-base-column.component';
import template from './pi-base-component-menu.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-component-menu.component.scss';
let PiBaseComponentMenu = PiBaseComponentMenu_1 = class PiBaseComponentMenu extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseComponentMenu';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseComponentMenu_1?.NAME,
        author: 'wuzp',
        title: '组件菜单组件',
        version: BcVersion,
        updateDate: '2019-06-03',
        description: '',
    };
    /**
     * 新增行列错误信息
     */
    static ADD_ROWS_COLUMNS_DATA_FAIL = `Component ${PiBaseComponentMenu_1?.NAME} add rows columns data fail:`;
    /**
     * 新增组件错误信息
     */
    static ADD_COMPONENT_DATA_FAIL = `Component ${PiBaseComponentMenu_1?.NAME} add component data fail:`;
    /**
     * 新增组件错误信息
     */
    static URL_NO_DEFINED_ERROR = `Component ${PiBaseComponentMenu_1?.NAME} URL is not defined:`;
    /**
     * 新增组件错误信息
     */
    static GET_PARAMS_FAIL_ADD_WIDGET_INSTANCE = `Component ${PiBaseComponentMenu_1?.NAME} get request params fail: add widget instance needs componentPkId and columnPkId`;
    //#endregion
    //#region props
    //#endregion
    //#region data
    isMouseenterHand = false;
    isShow = false;
    isMouseenterHandInShow = false;
    rowCount = 1;
    colCount = 1;
    menuLayoutData = [];
    /**
     * 业务组件：一维数组
     */
    menuData = [];
    /**
     * 业务组件 - 一维数组
     */
    widgetEntities = [];
    /**
     * sortableJs options
     */
    sortableJsOptionsLayout = {
        group: {
            name: PiBaseColumn.SORTABLEJS_GROUP_NAME,
            pull: 'clone',
            put: false,
        },
        handle: '.pi-widget-layout',
        easing: 'cubic-bezier(1, 0, 0, 1)',
        draggable: '.pi-widget-layout,.pi-row-layout',
        animation: 500,
        onEnd: this.sortableJsEventEndLayout,
    };
    /**
     * 拖拽组件参数 - 用户自定义布局
     */
    sortableJsOptionsLayoutUser = {
        group: {
            name: PiBaseColumn.SORTABLEJS_GROUP_NAME,
            pull: 'clone',
            put: false,
        },
        handle: '.pi-drag-icon',
        easing: 'cubic-bezier(1, 0, 0, 1)',
        draggable: '.pi-drag-icon,.pi-row-layout',
        animation: 500,
        onEnd: this.sortableJsEventEndLayout,
    };
    /**
     * 拖拽插件参数 - 组件拖拽
     */
    sortableJsOptionsComponents = {
        group: {
            name: PiBaseColumn.SORTABLEJS_GROUP_NAME,
            pull: 'clone',
            put: false,
        },
        handle: '.pi-widget',
        easing: 'cubic-bezier(1, 0, 0, 1)',
        draggable: '.pi-base-component-name,.pi-column-layout',
        animation: 500,
        onEnd: this.sortableJsEventEndComponent,
    };
    //#endregion
    //#region computed
    get about() {
        return PiBaseComponentMenu_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * store中存储的页面相关的信息
     */
    get storePage() {
        return PiBaseStorePageModule;
    }
    /**
     * store中存储的布局相关的信息
     */
    get storeLayout() {
        return PiBaseStoreLayoutModule;
    }
    /**
     * store中存储的组件相关的信息
     */
    get storeWidget() {
        return PiBaseStoreWidgetModule;
    }
    get siderWidth() {
        return hReactiveUtil.convertToReactivePx(200);
    }
    //#endregion
    //#region methods
    /**
     * sortableJs 鼠标释放逻辑
     * @param event
     */
    sortableJsEventEndLayout(event) {
        const fromHel = event.from;
        const fromIndex = event.oldIndex;
        const toHel = event.to;
        const toDraggableIndex = event.newDraggableIndex;
        const toIndex = event.newIndex;
        // 检查目标dom是否为列组件
        if (!this.checkDomIsColumnOrCenterComponent(toHel, fromHel)) {
            return;
        }
        // 还原 sortablejs 操作的 dom, 防止和 vue 虚拟 dom 冲突
        revertDom(toHel, fromHel, toIndex, fromIndex, true);
        this.$nextTick(() => {
            const reqParams = this.getRowsAddReqParam(fromHel, toHel, toDraggableIndex);
            reqParams &&
                this.reqRowsColumnsAdd(ApiUrlSetting.WidgetConfig.PiBaseCenter.RowsColumnsAddUrl, reqParams);
        });
    }
    /**
     * 判断目标dom是列组件/Center组件
     *
     * @param toDom
     * @param fromDom
     * @param showErrorMessage
     * @param errorMessage
     */
    checkDomIsColumnOrCenterComponent(toDom, fromDom, showErrorMessage = true, errorMessage = '业务组件只能拖入列单元格中！') {
        if (!toDom || !fromDom) {
            showErrorMessage && this.$Message.warning(errorMessage);
            return false;
        }
        // 判断拖拽的元素是否为布局组件
        let fromDomIsLayoutIcon = false;
        const toVue = toDom.__vue__;
        if (fromDom.querySelector('.pi-widget-layout')) {
            fromDomIsLayoutIcon = true;
        }
        // 拖拽布局组件
        if (fromDomIsLayoutIcon) {
            if (!(toVue.column ||
                toVue.$data._IS_CENTER_COMPONENT)) {
                showErrorMessage &&
                    this.$Message.warning('布局组件只能拖入单元格列或者内容组件中！');
                return false;
            }
            return true;
        }
        else {
            // 拖拽业务组件
            if (!toVue.column) {
                // 目标是PiCenter组件提示用户先放入布局组件
                if (toVue.$data._IS_CENTER_COMPONENT) {
                    showErrorMessage &&
                        this.$Message.warning('业务组件只允许放入布局组件中！');
                }
                else {
                    showErrorMessage && this.$Message.warning(errorMessage);
                }
                return false;
            }
            return true;
        }
    }
    /**
     * sortableJs 鼠标释放逻辑 - 拖拽组件
     * @param event
     */
    sortableJsEventEndComponent(event) {
        const fromHel = event.from;
        const fromIndex = event.oldIndex;
        const toHel = event.to;
        // const toDraggableIndex = event.newDraggableIndex;
        const toIndex = event.newIndex;
        const toVue = toHel.__vue__;
        // 检查目标dom是否为列组件
        if (!this.checkDomIsColumnOrCenterComponent(toHel, fromHel)) {
            return;
        }
        // 还原 sortablejs 操作的 dom, 防止和 vue 虚拟 dom 冲突
        revertDom(toHel, fromHel, toIndex, fromIndex, true);
        this.$nextTick(() => {
            const componentHel = fromHel.querySelector('.pi-base-component-name');
            const componentPkId = componentHel && componentHel.getAttribute('data-id');
            const columnEntity = toVue.column;
            const columnPkId = columnEntity.pkid;
            if (componentPkId && columnPkId) {
                const reqParams = this.getComponentAddReqParam(componentPkId, columnPkId);
                this.reqComponentAdd(ApiUrlSetting.WidgetConfig.ComponentInstance.Add, reqParams);
            }
            else {
                console.warn(PiBaseComponentMenu_1.GET_PARAMS_FAIL_ADD_WIDGET_INSTANCE);
            }
        });
    }
    /**
     * 后台请求 - 新增业务组件
     * @param url
     * @param reqParams
     */
    reqComponentAdd(url, reqParams) {
        if (!reqParams || !url) {
            console.warn(`${PiBaseComponentMenu_1.URL_NO_DEFINED_ERROR} rowsColumnsAddUrl`);
            return { instance: Promise.resolve(void 0) };
        }
        const tabId = this.storePage.curTab.tabId;
        if (!tabId) {
            return;
        }
        const reqUrl = ConvertUrl(url, UrlType.Api);
        return this.resetAjax('reqComponentAdd', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, reqParams);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    // 后台请求成功
                    const respComponent = data.data && data.data.length ? data.data[0] : null;
                    if (respComponent) {
                        // 把后台返回的组件实例信息存储到组件
                        this.storeWidget.addWidgetInstanceInfo({
                            tabId,
                            data: respComponent,
                        });
                        this.$emit('onAddComponentSuccess', {
                            tabId,
                            respComponent,
                        });
                    }
                }
                else {
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseComponentMenu_1.ADD_COMPONENT_DATA_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onAddComponentError', res);
                }
            })
                .catch((err) => hErrorShow({
                err,
                message: PiBaseComponentMenu_1.ADD_COMPONENT_DATA_FAIL,
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onAddComponentError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 后台请求 - 组件实例添加到页面
     * @param widgetPkId
     * @param columnPkId
     */
    getComponentAddReqParam(widgetPkId, columnPkId) {
        return {
            widgetPkId,
            columnPkId,
        };
    }
    /**
     * 后台请求 - 新增行列
     */
    reqRowsColumnsAdd(url, reqParams) {
        if (!url) {
            console.warn(`${PiBaseComponentMenu_1.URL_NO_DEFINED_ERROR} rowsColumnsAddUrl`);
            return { instance: Promise.resolve(void 0) };
        }
        const reqUrl = ConvertUrl(url, UrlType.Api);
        return this.resetAjax('reqRowsColumnsAdd', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, reqParams);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    // 后台请求成功
                    const respRowsColumns = data.data && data.data.length ? data.data[0] : null;
                    if (respRowsColumns &&
                        this.storePage.curTab.pkid === respRowsColumns.pagePkId) {
                        const tabId = this.storePage.curTab.tabId;
                        // 强转类型
                        respRowsColumns.columns &&
                            this.storeLayout.updateColumns({
                                tabId,
                                columns: respRowsColumns.columns,
                            });
                        respRowsColumns.rows &&
                            this.storeLayout.updateRows({
                                tabId,
                                rows: respRowsColumns.rows,
                            });
                    }
                }
                else {
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseComponentMenu_1.ADD_ROWS_COLUMNS_DATA_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onAddRowsColumnsError', res);
                }
            })
                .catch((err) => hErrorShow({
                err,
                message: PiBaseComponentMenu_1.ADD_ROWS_COLUMNS_DATA_FAIL,
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onAddRowsColumnsError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 后台请求 - 生成请求参数
     */
    getRowsAddReqParam(dom, toHel, toIndex) {
        if (!dom || !toHel) {
            return;
        }
        // 获取 dom 上绑定的行列数据
        const targetDom = dom.querySelector('.pi-widget-layout');
        const domWithData = targetDom ? targetDom.firstChild : null;
        let dataStringFromDom = '';
        if (domWithData) {
            dataStringFromDom = domWithData.getAttribute('data');
        }
        else if (!domWithData) {
            dataStringFromDom = this.rowCount + '-' + this.colCount;
        }
        // 特殊处理行列布局自定义行列布局数据
        if (dataStringFromDom && dataStringFromDom.indexOf('-') > 0) {
            const vs = dataStringFromDom.split('-'), rowCount = ~~vs[0], colCount = ~~vs[1];
            if (rowCount > 0 && colCount > 0) {
                const rows = [];
                const colNum = 24 / colCount;
                for (let i = 0; i < rowCount; i++) {
                    const cols = [];
                    for (let j = 0; j < colCount; j++) {
                        cols.push(colNum);
                    }
                    rows.push(cols);
                }
                dataStringFromDom = JSON.stringify(rows);
            }
        }
        if (!dataStringFromDom) {
            return;
        }
        let result = {};
        let reqRows = [];
        result.pagePkId = this.storePage.curTab.pkid;
        const fromDomRowsData = JSON.parse(dataStringFromDom);
        const isBaseCenterComponent = toHel['__vue__'].$data
            ._IS_CENTER_COMPONENT;
        // 插入到根节点
        if (isBaseCenterComponent) {
            // 处理 columnPkId
            result.columnPkId = PiBaseCenter.ROOT_ROWS_COLUMN_PKID;
            // 处理 rows
            const vueInstance = toHel['__vue__'];
            const rowsPiBaseCenter = vueInstance.rows;
            const rowAddRowOrderNo = this.getInsertRowOrderNo(rowsPiBaseCenter, toIndex);
            if (dataStringFromDom && dataStringFromDom !== '') {
                reqRows =
                    this.getDragRowSettings(fromDomRowsData, rowAddRowOrderNo) || [];
            }
        }
        else {
            // 插入到列单元格
            const vueInstance = toHel['__vue__'];
            const columnEntity = vueInstance.column;
            // 处理 columnPkId
            result.columnPkId = vueInstance.column.pkid;
            // 处理行 rows
            const rowAddRowOrderNo = this.getInsertRowOrderNo(vueInstance.rows, toIndex);
            reqRows =
                this.getDragRowSettings(fromDomRowsData, rowAddRowOrderNo) || [];
        }
        reqRows && (result.rows = reqRows);
        return result;
    }
    /**
     *
     * @param rowAddParam
     * @param indexRow
     */
    getDragRowSettings(rowAddParam, indexRow) {
        if (!rowAddParam ||
            !rowAddParam.length ||
            Number.isNaN(indexRow) ||
            indexRow < 0) {
            return;
        }
        let result = [];
        const baseRowHeight = (100 / rowAddParam.length).toFixed(2);
        indexRow--; //特殊处理
        for (let r of rowAddParam) {
            const row = {
                orderNo: ++indexRow,
                attrJson: `{"heightPct": ${baseRowHeight}}`,
                columns: [],
            };
            //数据类型数字则认为只有一列,且该数字为该列所占列数
            if (typeof r === 'number' && r <= 24) {
                // TODO: 这个作校验
            }
            else {
                let indexCol = 0;
                //提供的行为对象则认为有多列,且该数字为该列所占列数
                for (let c of r) {
                    let col = {
                        orderNo: ++indexCol,
                    };
                    //提供的列为数字,则认为该数字为该列所占列数
                    if (typeof c === 'number') {
                        if (!this.addDragCol(row, col, c)) {
                            return;
                        }
                    }
                    else {
                        //执行递归获取子行数据对象
                        const dc = c;
                        const tmpRows = this.getDragRowSettings(dc.r, indexRow);
                        col.rows = tmpRows || [];
                        if (col.rows.length <= 0 || !this.addDragCol(row, col, dc.n)) {
                            return [];
                        }
                    }
                }
            }
            //写入待返回的行集合
            result.push(row);
        }
        return result;
    }
    /**
     * 拖动时检测是否允许拖动并设置上列的属性初始值
     * @param row
     * @param col
     * @param value
     */
    addDragCol(row, col, value) {
        if (value < 0 || value > 24) {
            // this.$Message.error(`提供的列数必须为0~24之间的整数,不能是${value}！`);
            return false;
        }
        col.attrJson = `{"widthPct": ${value}}`;
        row.columns.push(col);
        return true;
    }
    /**
     * 获取插入位置前一条数据的 OrderNo: 根据兄弟节点数据和插入位置生成
     * @param rows 兄弟行数据
     * @param index  插入位置
     */
    getInsertRowOrderNo(rows, index) {
        if (!rows || !rows.length) {
            return index;
        }
        const rowLength = rows.length;
        if (rowLength === index) {
            return rows[rowLength - 1].orderNo;
        }
        // 判断插入行位置
        let insertRow;
        const lastRowIndex = rowLength - 1;
        if (index >= lastRowIndex) {
            insertRow = rows[lastRowIndex];
        }
        else {
            insertRow = rows[index];
        }
        return insertRow.orderNo;
    }
    /**
     *显示可点击块
     * @memberof PiBaseComponentMenu
     */
    showComponentMenuHand() {
        if (!this.isShow) {
            this.isMouseenterHand = true;
        }
        else {
            this.isMouseenterHandInShow = true;
        }
    }
    /**
     *隐藏可点击块
     * @memberof PiBaseComponentMenu
     */
    hideComponentMenuHand() {
        this.isMouseenterHand = false;
    }
    /**
     *显示组件列表区域
     * @memberof PiBaseComponentMenu
     */
    showComponentMenuLayoutContent() {
        if (this.isShow) {
            this.isShow = false;
            this.isMouseenterHand = false;
            this.isMouseenterHandInShow = false;
        }
        else {
            this.isShow = true;
            this.isMouseenterHand = false;
        }
        this.storeLayout.setLayoutMode({
            mode: this.isShow ? LayoutMode.design : LayoutMode.noDesign,
        });
        // 提交事件告知当前组件宽度
        this.emitToggleEvent();
    }
    /**
     * 提交事件告知当前组件宽度
     */
    emitToggleEvent() {
        this.$emit('toggle-show', {
            show: this.isShow,
            currentWidth: this.isShow ? this.siderWidth : 0,
        });
    }
    /**
     * 获取组件数据
     * @param {string} url 布局url
     * @memberof PiBaseComponentMenu
     */
    getMenuData() {
        //加载组件菜单佈局
        const reqUrl = ConvertUrl(ApiUrlSetting.WidgetConfig.ComponentMenus.MenuData, UrlType.Api);
        return this.resetAjax('getMenuData', () => {
            const ajaxInfo = hAjaxGet(reqUrl);
            ajaxInfo.instance
                .then((res) => {
                if (res.data.status !== PiStatusEnum.Success) {
                    console.error('加载组件菜单失败！');
                    return;
                }
                this.menuData = res.data.data;
                // 存储业务组件信息到 Vuex，一维数组
                const { menuData, widgetEntities: menuDataSingle } = this;
                menuDataSingle.splice(0);
                let widgetEntities = [];
                menuData.forEach((p) => {
                    const widgetGroupPkId = p.pkid;
                    const tmpWidgets = p.widgets;
                    let tmpWidgetEntities = [];
                    tmpWidgets &&
                        tmpWidgets.forEach((item) => {
                            if (item) {
                                let tmpData = Object.assign({}, item, {
                                    widgetGroupPkId,
                                    attrJson: '{}',
                                });
                                tmpWidgetEntities.push(tmpData);
                            }
                        });
                    widgetEntities.push(...tmpWidgetEntities);
                });
                this.storeWidget.setWidgetInfos({
                    data: widgetEntities,
                });
            })
                .catch((err) => hErrorShow({
                context: this,
                err,
                showInPage: true,
            }));
            return ajaxInfo;
        });
    }
    //#endregion
    //#region hooks
    created() {
        //获取组件布局数据
        this.menuLayoutData = LayoutData;
        //获取组件菜单数据
        this.getMenuData();
    }
    /**
     * window resize事件
     */
    onWindowResize() {
        this.emitToggleEvent();
    }
};
PiBaseComponentMenu = PiBaseComponentMenu_1 = __decorate([
    Component({
        components: {
            Layout,
            Sider,
            Menu,
            MenuItem,
            Icon,
            Submenu,
            Row,
            Col,
            Select,
            Option,
        },
        template,
        mixins: [WindowEventMixin],
    })
], PiBaseComponentMenu);
export default PiBaseComponentMenu;
