import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
let FullScreenMixin = class FullScreenMixin extends VueMixinBase {
    /**
     * 监听全屏切换事件
     */
    onFullScreenChangeEvent() {
        document.addEventListener('fullscreenchange', this.onToggleFullScreen);
    }
    /**
     * 移除监听全屏切换事件
     */
    offFullScreenChangeEvent() {
        document.removeEventListener('fullscreenchange', this.onToggleFullScreen);
    }
    /**
     * 切换全屏
     */
    onToggleFullScreen() { }
    /**
     * 单击全屏按钮
     */
    onClick2FullScreen() {
        const { contextMenu, fullScreenHEl } = this;
        contextMenu.isFullScreenSvg = !contextMenu.isFullScreenSvg;
        // 支持全屏
        if (fullScreenHEl && fullScreenHEl.requestFullscreen) {
            fullScreenHEl
                .requestFullscreen()
                .catch((err) => {
                this.$Message.error(`尝试全屏失败: ${err.message} (${err.name})`);
            })
                .then(() => {
                this.$emit('on-toggle-full-screen', contextMenu.isFullScreenSvg);
            })
                .catch((err) => {
                console.error(`全屏失败：${err}`);
            });
        }
    }
    /**
     * 退出全屏
     */
    exitFullscreen() {
        if (this.contextMenu.isFullScreenSvg) {
            document.exitFullscreen().catch((err) => {
                console.error(`推出全屏失败：${err}`);
            });
        }
    }
};
FullScreenMixin = __decorate([
    Component
], FullScreenMixin);
export default FullScreenMixin;
