import { __decorate } from "tslib";
import { LayoutMode } from 'bc$/enums/layout/layout.enum';
import { PiBaseStoreLayoutModule } from 'bc$/store/modules/pi-base-layout.store';
import { Prop, Vue, Mixins, Component } from 'vue-property-decorator';
import { DataSourceOptionsDimTypeEnum, DataSourceOptionsSelectTypeEnum, DataSourceOptionsDefaultTimeNodeEnum, DataSourceOptionsShowTypeEnum, } from './data-source.base';
import { hUSaveFile } from 'bc$/utils/usually.helper';
import { hODeepObjectMerge } from 'bc$/utils/object.helper';
import { hDDate2String } from 'bc$/utils/date.helper';
// tslint:disable-next-line: no-import-side-effect
import './widget.base.scss';
import { PiBaseStoreUserModule, PiBaseStoreWidgetModule, hReactiveUtil, } from 'bc$/main';
import { VueBase } from './vue.base';
import { PiBaseStaticWidgetToolbar } from 'bc$/components/pi-base-widget-toolbar/pi-base-widget-toolbar.component';
Vue.use(PiBaseStaticWidgetToolbar);
let WidgetBase = class WidgetBase extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 控制弹窗显示的 zindex 层级
     */
    static iModalCount = 1000;
    /**
     * 日期选择组件的快捷选择设置
     */
    static optionsDatePicker = {
        /**
         * 日期视图
         */
        date: {
            /**
             * 单个
             */
            single: {
                shortcuts: [
                    {
                        text: '今天',
                        value() {
                            return new Date();
                        },
                    },
                    {
                        text: '昨天',
                        value() {
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                            return start;
                        },
                    },
                ],
            },
            /**
             * 多个
             */
            multy: {
                shortcuts: [
                    {
                        text: '今天',
                        value() {
                            const start = new Date();
                            const end = new Date(start);
                            return [start, end];
                        },
                    },
                    {
                        text: '昨天',
                        value() {
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                            const end = new Date(start);
                            return [start, end];
                        },
                    },
                ],
            },
        },
        /**
         * 月份视图
         */
        month: {
            /**
             * 单个
             */
            single: {
                shortcuts: [
                    {
                        text: '上月',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                            return start;
                        },
                    },
                    {
                        text: '本月',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear(), date.getMonth(), 1);
                            return start;
                        },
                    },
                ],
            },
            /**
             * 多个
             */
            multy: {
                shortcuts: [
                    {
                        text: '上月',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                            const end = new Date(date.getFullYear(), date.getMonth(), 0);
                            return [start, end];
                        },
                    },
                    {
                        text: '本月',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear(), date.getMonth(), 1);
                            const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                            return [start, end];
                        },
                    },
                ],
            },
        },
        /**
         * 年视图
         */
        year: {
            /**
             * 单个
             */
            single: {
                shortcuts: [
                    {
                        text: '上年',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear() - 1, 0, 1);
                            return start;
                        },
                    },
                    {
                        text: '本年',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear(), 0, 1);
                            return start;
                        },
                    },
                ],
            },
            /**
             * 多个
             */
            multy: {
                shortcuts: [
                    {
                        text: '上年',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear() - 1, 0, 1);
                            const end = new Date(date.getFullYear() - 1, 11, 31);
                            return [start, end];
                        },
                    },
                    {
                        text: '本年',
                        value() {
                            const date = new Date();
                            const start = new Date(date.getFullYear(), 0, 1);
                            const end = new Date(date.getFullYear(), 11, 31);
                            return [start, end];
                        },
                    },
                ],
            },
        },
    };
    //#endregion
    //#region props
    /**
     * 当前所在页面唯一页签id
     */
    tabId;
    /**
     * 当前组件
     */
    widgetInfo;
    /**
     * 外部传入的联动的数据源设置
     */
    linkSearchProp;
    //#endregion
    //#region data
    /**
     * 联动的数据源设置
     */
    linkSearchInner = null;
    /**
     * 当前组件相关配置数据
     */
    options;
    /**
     * 数据源弹窗需要的配置参数
     */
    dataSourceOptions = {
        /**
         * 维度类型 single：单维，multy：多维
         */
        dimType: DataSourceOptionsDimTypeEnum.Single,
        /**
         * 维度节点选中类型 single：单个，multy：多个
         */
        selectType: DataSourceOptionsSelectTypeEnum.Multy,
        /**
         * 测量量选中类型 single：单个，multy：多个
         */
        measureType: DataSourceOptionsSelectTypeEnum.Multy,
        /**
         * 时间段类型可选清单,undefined将会选中所有的可选项
         */
        timeNodeTypes: [],
        /**
         * 默认时间段类型时，默认 今天(4)
         */
        defaultTimeNode: DataSourceOptionsDefaultTimeNodeEnum.CurDay,
        /**
         * 显示方式 none：正常显示，node：只显示维度，dim：显示维度相关
         */
        showType: DataSourceOptionsShowTypeEnum.None,
    };
    //#endregion
    //#region computed
    /**
     * 是否在布局模式下
     */
    get isLayoutMode() {
        return PiBaseStoreLayoutModule.layoutMode === LayoutMode.design;
    }
    /**
     * 计算通用配置中设置生成样式
     */
    get styleJson() {
        return {
            opacity: this.widgetInfo.opacity / 100,
        };
    }
    /**
     * 数据源
     */
    get linkSearch() {
        return this.linkSearchInner || this.linkSearchProp;
    }
    //#endregion
    //#region methods
    /**
     * 验证时间是否符合要求
     * @param timeType
     * @param timeList
     * @param callback
     */
    validateTimeList(timeType, timeList, callback) {
        if (!timeList || !Array.isArray(timeList) || timeList.length < 2) {
            return;
        }
        let timeStart = timeList[0];
        let timeEnd = timeList[1];
        const isStr = typeof timeStart === 'string';
        if (isStr) {
            timeStart = new Date(timeList[0]);
        }
        if (typeof timeEnd === 'string') {
            timeEnd = new Date(timeList[1]);
        }
        if (timeStart > timeEnd) {
            const dt = timeStart;
            timeStart = timeEnd;
            timeEnd = dt;
        }
        const tne = DataSourceOptionsDefaultTimeNodeEnum;
        switch (timeType) {
            case tne.Year:
                if (timeEnd.getFullYear() - timeStart.getFullYear() > 100) {
                    this.$Message.warning({
                        content: '时间类型为年,时间跨度不能超过100年！',
                        duration: 3,
                    });
                    timeEnd = new Date(timeStart.toString());
                    timeEnd.setFullYear(timeStart.getFullYear() + 100);
                }
                break;
            case tne.Quarter:
                if (timeEnd.getFullYear() - timeStart.getFullYear() > 20) {
                    this.$Message.warning({
                        content: '时间类型为季,时间跨度不能超过20年！',
                        duration: 3,
                    });
                    timeEnd = new Date(timeStart.toString());
                    timeEnd.setFullYear(timeStart.getFullYear() + 20);
                }
                break;
            case tne.Month:
                if (timeEnd.getFullYear() - timeStart.getFullYear() > 20) {
                    this.$Message.warning({
                        content: '时间类型为月,时间跨度不能超过20年！',
                        duration: 3,
                    });
                    timeEnd = new Date(timeStart.toString());
                    timeEnd.setFullYear(timeStart.getFullYear() + 20);
                }
                break;
            case tne.Week:
                if ((timeEnd.getTime() - timeStart.getTime()) / (1000 * 60 * 60 * 24) >
                    180) {
                    this.$Message.warning({
                        content: '时间类型为周,时间跨度不能超过180天！',
                        duration: 3,
                    });
                    timeEnd = new Date(timeStart.toString());
                    timeEnd.setDate(timeEnd.getDate() + 180);
                }
                break;
            case tne.Day:
                if ((timeEnd.getTime() - timeStart.getTime()) / (1000 * 60 * 60 * 24) >
                    180) {
                    this.$Message.warning({
                        content: '时间类型为日,时间跨度不能超过180天！',
                        duration: 3,
                    });
                    timeEnd = new Date(timeStart.toString());
                    timeEnd.setDate(timeEnd.getDate() + 180);
                }
                break;
            case tne.Hour:
                if ((timeEnd.getTime() - timeStart.getTime()) / (1000 * 60 * 60 * 24) >
                    10) {
                    this.$Message.warning({
                        content: '时间类型为时,时间跨度不能超过10天！',
                        duration: 3,
                    });
                    timeEnd = new Date(timeStart.toString());
                    timeEnd.setDate(timeEnd.getDate() + 10);
                }
                break;
            case tne.Min:
                if ((timeEnd.getTime() - timeStart.getTime()) / (1000 * 60 * 60 * 24) >
                    2) {
                    this.$Message.warning({
                        content: '时间类型为十五分钟,时间跨度不能超过2天！',
                        duration: 3,
                    });
                    timeEnd = new Date(timeStart.toString());
                    timeEnd.setDate(timeEnd.getDate() + 2);
                }
                break;
            //case tne.curMin:
            //    break;
            //case tne.curDay:
            //    break;
            //case tne.curWeek:
            //    break;
            //case tne.curMonth:
            //    break;
            //case tne.curQuarter:
            //    break;
            //case tne.curYear:
            //    break;
            //case tne.lastDay:
            //    break;
            //case tne.lastWeek:
            //    break;
            //case tne.lastMonth:
            //    break;
            //case tne.lastQuarter:
            //    break;
            //case tne.lastYear:
            //    break;
        }
        const timeStartOld = isStr
            ? new Date(timeList[0])
            : timeList[0];
        const timeEndOld = isStr
            ? new Date(timeList[1])
            : timeList[1];
        const isChangeStart = timeStart.getTime() !== timeStartOld.getTime();
        const isChangeEnd = timeEnd.getTime() !== timeEndOld.getTime();
        if (isChangeStart) {
            timeList[0] = isStr
                ? hDDate2String(timeStart, 'yyyy-MM-dd HH:mm:ss')
                : timeStart;
        }
        if (isChangeEnd) {
            timeList[1] = isStr
                ? hDDate2String(timeEnd, 'yyyy-MM-dd HH:mm:ss')
                : timeEnd;
        }
        if (isChangeStart || isChangeEnd) {
            callback &&
                typeof callback === 'function' &&
                callback([timeStart, timeEnd]);
        }
    }
    /**
     * 下载按钮的子项被点击
     * @param name 单击的子项的名称标识
     * @param table 下载表格时提供的表格vue实例
     * @param downloadName 下载后的文件名称
     */
    onClickDownload(name, table, downloadName) {
        const fileName = downloadName || `${new Date().getTime()}`;
        switch (name) {
            case 'html2png':
                this.resetTimeOut('onClickDownload', () => {
                    const el = this.$el;
                    //设置了svg的宽高的对象数组
                    let nodesWidth = [];
                    let nodesHeight = [];
                    el.querySelectorAll('svg:visible').forEach((node) => {
                        const style = node.getAttribute('style');
                        const noWidth = !style || !~style.indexOf('width:');
                        const noHeight = !style || !~style.indexOf('height:');
                        noWidth &&
                            (node.style.width = node.offsetWidth + 'px') &&
                            nodesWidth.push(node);
                        noHeight &&
                            (node.style.height = node.offsetHeight + 'px') &&
                            nodesHeight.push(node);
                    });
                    //执行转换
                    const rect = el.getBoundingClientRect();
                    html2canvas(el, {
                        useCORS: true,
                        foreignObjectRendering: true,
                        x: rect.left,
                        y: rect.top + 20,
                        //letterRendering: true,
                        width: rect.width,
                        height: rect.height, //,
                        //scale: 4
                    })
                        .then((canvas) => {
                        //执行下载
                        const dataURL = canvas.toDataURL('image/png');
                        hUSaveFile(dataURL, fileName);
                        //恢复svg
                        let reg = /width:(.+?); ?/;
                        nodesWidth.forEach((p) => {
                            if (p) {
                                const style = p.getAttribute('style');
                                style && p.setAttribute('style', style.replace(reg, ''));
                            }
                        });
                        reg = /height:(.+?); ?/;
                        nodesHeight.forEach((p) => {
                            const style = p.getAttribute('style');
                            style && p.setAttribute('style', style.replace(reg, ''));
                        });
                    })
                        .catch((err) => {
                        console.error(err);
                    });
                }, 250);
                break;
            case 'canvas2png':
                //执行下载
                const canvas = this.$refs['vecharts'].$el.querySelector('canvas');
                if (canvas) {
                    const dataURL = canvas.toDataURL('image/png');
                    hUSaveFile(dataURL, fileName);
                }
                break;
        }
    }
    /**
     * 联动事件
     * @param search 联动的查询条件对象
     */
    runLink(search) {
        //需要联动的组件id清单
        const link = this.widgetInfo.attrObj.Link || [];
        if (link.length <= 0) {
            return;
        }
        //当前数据
        search = search || this.widgetInfo.attrObj.Search;
        // //执行联动
        // const center = this.$root.$refs['LayoutCenter'] as LayoutCenter;
        // center.vueWidgets.forEach(vue => {
        //   const widget = vue.widgetInfo;
        //   const id = widget.id;
        //   if (widget && link.some(p => p === id)) {
        //     const func: Function = vue['toolbarCbLink'];
        //     func && typeof func === 'function' && func(search);
        //   }
        // });
    }
    // /**
    //  * 数据源确认后的回调函数
    //  */
    // dataSourceOk(
    //   value: WidgetDataSourceCallbackDataEntity,
    //   callback?: (value?: WidgetDataSourceCallbackDataEntity) => any,
    // ) {
    //   let widget = this.widgetInfo;
    //   let params = {
    //     id: widget.pkid,
    //     key: 'Search',
    //     value: value.search,
    //   };
    //   //同步执行保存
    //   //写入ajax对象列表统一管理
    //   return this.resetAjax('UpdWidgetAttrJson', () => {
    //     const ajax = hAjaxPostJson('/api/User/UpdWidgetAttrJson', params);
    //     ajax.instance
    //       .finally(() => {
    //         // //用户多次调用此 ajax 导致移除ajax请求
    //         // if (textStatus !== VueBase.abortAjaxName) {
    //         //   this.removeAjax('onClickDownload_DownloadExcel');
    //         // }
    //       })
    //       .then(res => {
    //         if (!res || !res.data) {
    //           console.error('修改组件数据源失败！');
    //           return;
    //         }
    //         if (res.status !== PiStatusEnum.SUCCESS) {
    //           this.$Message.error('修改组件数据源失败！' + res.data.msg);
    //           return;
    //         }
    //         //修改该组件数据源数据
    //         widget.attrObj!.Search = hODeepClone(value.search);
    //         //执行当前配置数据源组件回调重新渲染组件数据
    //         callback && typeof callback === 'function' && callback(value);
    //         this.$Message.success('修改组件数据源成功！');
    //       })
    // .catch((err: PiAxiosError) =>
    //   hErrorShow({
    //     context: this,
    //     err,
    //     showInPage: true,
    //     message: '修改组件数据源报错！'
    //   }),
    // );
    //     //返回xhr对象
    //     return ajax;
    //   });
    // }
    /**
     * 添加当前组件实例到Center 中vue实例数组
     */
    add2Center() {
        //添加当前vue实例
        // const center = this.$root.$refs['LayoutCenter'] as LayoutCenter;
        // const vueWidgets = center.vueWidgets;
        // vueWidgets.push(this);
    }
    /**
     * 从Center 中vue实例数组中删除当前组件实例
     */
    removeOfCenter() {
        // //删除当前vue实例
        // const center = this.$root.$refs['LayoutCenter'] as LayoutCenter;
        // if (center) {
        //   const vueWidgets = center.vueWidgets;
        //   const index = vueWidgets.findIndex(p => p === this);
        //   index >= 0 && vueWidgets.splice(index, 1);
        // }
    }
    /**
     * 根元素鼠标移入时
     */
    onRootMouseEnter() {
        //获取工具条所需要实例心里
        const { about, toolbarShowProperty, widgetInfo } = this;
        let toolData = {
            about,
            toolbarShowProperty,
            widgetInfo,
        };
        //鼠标放上去修改实例信息
        PiBaseStoreWidgetModule.setSaveToolbarInfo({
            data: toolData,
        });
        //定位位置
        let el = this.$el, rect = el.getBoundingClientRect();
        PiBaseStoreWidgetModule.setToolbarPosition({
            left: rect.left + el.offsetWidth - hReactiveUtil.convertToReactivePx(35),
            top: rect.top + hReactiveUtil.convertToReactivePx(5),
            isShow: true,
        });
        //创建工具条
        if (PiBaseStoreUserModule.hasAdmin) {
            !PiBaseStoreWidgetModule.isCreateWidgetInstanceInfos &&
                this.$piWidgetToolbar &&
                this.$piWidgetToolbar({});
        }
    }
    /**
     * 根元素鼠标移出时
     */
    onRootMouseLeave() {
        const { toolPosition } = PiBaseStoreWidgetModule;
        PiBaseStoreWidgetModule.setToolbarPosition({
            left: toolPosition.left,
            top: toolPosition.top,
            isShow: false,
        });
    }
    //#endregion
    //#region hooks
    /**
     * vue实例事件回调 created
     */
    created() {
        const { options, widgetInfo } = this;
        if (!options) {
            // console.error('组件缺少必须的options定义！');
            return;
        }
        if (!widgetInfo) {
            console.error('组件缺少必须的widgetInfo输入参数！');
            return;
        }
        widgetInfo.attrJson = JSON.stringify(Object.assign({
            Attr: {},
            Data: {},
            Link: [],
            Search: {},
        }, widgetInfo.attrObj));
        let key;
        for (key in widgetInfo) {
            if (key !== 'attrObj') {
                if (key === 'attrJson') {
                    options[key] = JSON.stringify(hODeepObjectMerge(JSON.parse(JSON.stringify(options.attrObj)), JSON.parse(JSON.stringify(widgetInfo.attrObj))));
                }
                else {
                    options[key] = JSON.parse(JSON.stringify(widgetInfo[key]));
                }
            }
        }
        //保存当前实例
        this.add2Center();
    }
    /**
     * 销毁实例前
     */
    beforeDestroy() {
        //删除当前vue实例
        this.removeOfCenter();
    }
};
__decorate([
    Prop({
        type: String,
        required: true,
        default: '',
    })
], WidgetBase.prototype, "tabId", void 0);
__decorate([
    Prop({
        type: Object,
        required: true,
    })
], WidgetBase.prototype, "widgetInfo", void 0);
__decorate([
    Prop(Object)
], WidgetBase.prototype, "linkSearchProp", void 0);
WidgetBase = __decorate([
    Component
], WidgetBase);
export { WidgetBase };
