var PiBaseEasyTableLoading_1;
import { __decorate } from "tslib";
import { Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-easy-table-loading.component.html';
let PiBaseEasyTableLoading = PiBaseEasyTableLoading_1 = class PiBaseEasyTableLoading extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEasyTableLoading';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEasyTableLoading_1?.NAME,
        author: 'yangd',
        title: '无标题',
        version: BcVersion,
        updateDate: '2019-08-08',
        description: '',
    };
    //#endregion
    //#region props
    loadingContent;
    loadingOpacity;
    titleRows;
    titleRowHeight;
    columns;
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEasyTableLoading_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    setPosition() {
        let loadingEle = this.$el, loadingContentEle = this.$el.querySelector('.v-table-loading-content'), titleHeight = 0;
        if (this.columns && this.columns.length > 0) {
            titleHeight =
                this.titleRows && this.titleRows.length > 0
                    ? this.titleRows.length * this.titleRowHeight
                    : this.titleRowHeight;
        }
        loadingContentEle.style.top =
            (loadingEle.clientHeight + titleHeight) / 2 -
                loadingContentEle.clientHeight / 2 +
                'px';
    }
    //#endregion
    //#region hooks
    mounted() {
        this.$nextTick(() => {
            this.setPosition();
        });
    }
};
__decorate([
    Prop({
        type: String,
    })
], PiBaseEasyTableLoading.prototype, "loadingContent", void 0);
__decorate([
    Prop({
        type: Number,
    })
], PiBaseEasyTableLoading.prototype, "loadingOpacity", void 0);
__decorate([
    Prop({
        type: Array,
    })
], PiBaseEasyTableLoading.prototype, "titleRows", void 0);
__decorate([
    Prop({
        type: Number,
    })
], PiBaseEasyTableLoading.prototype, "titleRowHeight", void 0);
__decorate([
    Prop({
        type: Array,
    })
], PiBaseEasyTableLoading.prototype, "columns", void 0);
PiBaseEasyTableLoading = PiBaseEasyTableLoading_1 = __decorate([
    Component({
        template,
    })
], PiBaseEasyTableLoading);
export default PiBaseEasyTableLoading;
