import Vue from 'vue';
import { Selectable } from './selectable';
import { Draggable } from './draggable';
import { Sortable } from './sortable';
import { Permission } from './permission';
import { BetterScroll } from './scroll';
import { Resize } from './resize';
import { InputFilter } from './input-filter';
import { TweenDirective } from './tween';
export * from './selectable';
// tslint:disable-next-line: no-import-side-effect
import './scroll/pi-better-scroll.scss';
Vue.directive('drag', Draggable);
Vue.directive('sort', Sortable);
Vue.directive('has', Permission);
Vue.directive('select', Selectable);
Vue.directive('scroll', BetterScroll);
Vue.directive('resize', Resize);
Vue.directive('input-filter', InputFilter);
Vue.directive('tween', TweenDirective);
