import { debounce } from 'lodash';
/**
 * 响应式的转换工具
 */
export const hReactiveUtil = {
    /**
     * 是否有监听屏幕尺寸变化
     */
    hasWindowResizeEventListener: false,
    /**
     * HTML元素的字体大小
     */
    _HTMLFontSize: 0,
    /**
     * 相对尺寸（rem）与像素尺寸（px）的转换比例
     * 1rem = ?px
     */
    remUnit: Number(process.env.VUE_APP_REM_UNIT) || 16,
    /**
     * window Event事件防抖对象
     */
    _resizeDebounced: debounce(() => {
        hReactiveUtil.getHTMLFontSize(true);
    }, 200),
    /**
     * 获取HTML元素的字体大小
     * @param force 强制查询
     */
    getHTMLFontSize(force) {
        if (force || !this._HTMLFontSize) {
            const html = document.querySelector('html');
            let fontSize = html.style.fontSize;
            // let fontSize = (html.getAttribute('style') || '').replace(
            //   /^.*font-size: ?(.+?(vw|vh|px)).*$/i,
            //   '$1',
            // );
            if (!fontSize) {
                fontSize = window.getComputedStyle(html).fontSize;
            }
            // px
            if (fontSize.indexOf('px') !== -1) {
                this._HTMLFontSize = Number(fontSize.replace('px', ''));
            }
            // vw
            else if (fontSize.indexOf('vw') !== -1) {
                const ratio = Number(fontSize.replace('vw', ''));
                const clientWidth = document.body.clientWidth;
                this._HTMLFontSize = Math.floor(clientWidth * ratio) / 100 || 1;
            }
            // vh
            else if (fontSize.indexOf('vh') !== -1) {
                const ratio = Number(fontSize.replace('vh', ''));
                const clientHeight = document.body.clientHeight;
                this._HTMLFontSize = Math.floor(clientHeight * ratio) / 100 || 1;
            }
            else {
                fontSize &&
                    console.error('html元素的fontsize仅支持vw、vw和px三种单位！');
            }
        }
        return this._HTMLFontSize;
    },
    /**
     * 设定的像素按比例转换到响应式的像素
     * @param pxValue
     */
    convertToReactivePx(pxValue) {
        if (!pxValue) {
            return pxValue;
        }
        const fontSize = this.getHTMLFontSize();
        return fontSize ? Math.floor((fontSize / this.remUnit) * pxValue) : pxValue;
    },
    /**
     * px转换rem
     * @param pxValue
     */
    convertToRem(pxValue) {
        if (!pxValue) {
            return pxValue;
        }
        return Math.round((pxValue * 1e10) / this.remUnit) / 1e10;
    },
};
/**
 * 监听屏幕尺寸变化，及时获取最新HTML元素的字体大小
 */
if (!hReactiveUtil.hasWindowResizeEventListener) {
    hReactiveUtil.hasWindowResizeEventListener = true;
    window.addEventListener('resize', hReactiveUtil._resizeDebounced);
}
