import { cloneDeep } from 'lodash';
/**
 * 是否对象
 * @param o 传入的变量
 */
function hOCheckObject(o) {
    return typeof o === 'object' && o !== null;
}
/**
 * 获取对象类型
 *
 * @param o 传入的变量
 *
 * @returns
 *   '[object Object]' / '[object Array]' / '[object Number]' / '[object String]' / '[object Date]'
 *    / '[object Function]' / '[object Symbol]' / '[object Boolean]' / '[object Null]' / '[object Undefined]' / '[object RegExp]'
 *   / ...(es6新对象待补充)
 */
function getDataType(o) {
    return Object.prototype.toString.call(o);
}
/**
 * 深拷贝
 *
 * 排除不可枚举的属性
 *
 * （TODO: 待支持： 正则，Set Map Iterator等对象类型）+
 *
 * @param o 待克隆的对象
 */
function hODeepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    let newObj;
    if (Array.isArray(obj)) {
        newObj = [...obj];
    }
    else if (getDataType.call(null, obj) === '[object Date]') {
        newObj = new Date(obj);
    }
    else if (typeof obj === 'function') {
        newObj = new Function('return ' + obj.toString())();
    }
    else {
        newObj = { ...obj };
    }
    if (typeof newObj === 'function' ||
        getDataType.call(null, newObj) === '[object Date]') {
        return newObj;
    }
    Reflect.ownKeys(newObj).forEach((key) => {
        const keyAlias = key;
        // 排除不可枚举属性
        if (newObj.propertyIsEnumerable(keyAlias)) {
            const keyValue = obj[keyAlias];
            // 排除日期类型执行深克隆
            // TODO: 其他对象类型处理逻辑待补充~(如正则 Map Set...)
            if (getDataType.call(null, keyValue) === '[object Date]') {
                newObj[keyAlias] = new Date(keyValue);
            }
            else {
                newObj[keyAlias] =
                    hOCheckObject(keyValue) && keyValue
                        ? hODeepClone(keyValue)
                        : keyValue;
            }
        }
    });
    return newObj;
}
/*
 *深度合并对象
 *
 * @param firstObj 被合并的对象
 * @param otherObj 需要合并的对象
 *
 * 注意:
 * 1. 目标值和来源值类型不一致(数组和对象 / 基础类型和对象类型 / null和非null等)
 *      a. 目标是null或基础类型返回来源
 *      b. 来源是null或基础类型返回目标
 *      c. 类型不同以来源为准
 *      d. 均为基础类型返回空对象
 * 2. 目标属性值和来源属性值同为数组时执行覆盖，同为对象时执行合并
 * 3. 来源属性值类型为基础类型，将覆盖目标属性值
 *
 */
function hODeepObjectMerge(firstObj, ...otherObj) {
    switch (typeof firstObj) {
        case 'object':
            const isArr = Array.isArray(firstObj);
            otherObj.forEach((item) => {
                if (Array.isArray(item) !== isArr) {
                    console.warn('待合并的对象请考虑使用一致的数据类型！', firstObj, item);
                }
            });
            break;
        case 'function':
            break;
        default:
            console.warn('firstObj必须是object类型！', firstObj);
            break;
    }
    let firstConvert = firstObj;
    const toString = Object.prototype.toString;
    for (let index = 0, length = otherObj.length; index < length; index++) {
        const element = otherObj[index];
        // 如果目标和来源数据类型不一致，则使用来源
        const eleToString = toString.call(element);
        const firstConvertToString = toString.call(firstConvert);
        if (eleToString !== firstConvertToString) {
            console.warn(`存在不同类型的数据合并，可能导致Vue响应式失效，请注意！" ${element} - ${firstConvert}"`);
            //目标是null或基础类型,则返回来源
            if (null === firstConvert || typeof firstConvert !== 'object') {
                firstConvert = cloneDeep(element);
                continue;
            }
            //来源是null或基础类型，则返回目标
            if (null === element || typeof element !== 'object') {
                continue;
            }
            //类型不同以来源为准
            if (typeof firstConvert === 'object' && typeof element === 'object') {
                firstConvert = cloneDeep(element);
                continue;
            }
            //均为基础类型返回空对象
            firstConvert = {};
            continue;
        }
        // 目标和来源类型一致
        // let keys = Reflect.ownKeys(element);
        const keys = Object.keys(element);
        keys.push(...Object.getOwnPropertySymbols(element));
        for (let index = 0, len = keys.length; index < len; index++) {
            const key = keys[index];
            // 目标属性值
            let firstConvertItem = firstConvert[key];
            // 来源属性值
            let cloneEleItem = element[key];
            // 来源属性值为对象（Array & Object）
            if (typeof cloneEleItem === 'object') {
                // 对来源对象进行克隆
                cloneEleItem = cloneDeep(cloneEleItem);
            }
            // 目标属性值和来源属性值均为数组，清空目标数组
            if (Array.isArray(firstConvertItem) && Array.isArray(cloneEleItem)) {
                firstConvertItem.splice(0);
            }
            // 来源属性值是对象（Array & Object）
            if (hOCheckObject(cloneEleItem)) {
                // 来源属性值是数组
                if (Array.isArray(cloneEleItem) ||
                    getDataType.call(null, cloneEleItem) === '[object Date]') {
                    // 把来源值数组赋值给目标值
                    firstConvert[key] = cloneEleItem;
                }
                else {
                    // 来源属性值为非数组(如Array/基础类型等)
                    // 判断目标非对象（基础类型/数组均为非对象）
                    if (!hOCheckObject(firstConvertItem) ||
                        Array.isArray(firstConvertItem)) {
                        // 目标属性值设置为空对象
                        firstConvertItem = firstConvert[key] = {};
                    }
                    // 来源属性值为对象，执行深度克隆
                    hODeepObjectMerge(firstConvertItem, cloneEleItem);
                }
            }
            else {
                // 来源属性值为基础类型数据
                // 来源属性值和目标属性值不相等
                if (firstConvertItem !== element[key]) {
                    switch (typeof cloneEleItem) {
                        case 'number':
                        case 'string':
                        case 'boolean':
                        case 'object': //应该只剩下null
                        case 'function':
                            // 来源属性值赋值给目标属性值
                            // （可能存在以下清空：1.基础类型赋值给任意类型）
                            firstConvert[key] = element[key];
                            break;
                    }
                }
            }
        }
    }
    return firstConvert;
}
/**
 * 清除一个对象内部属性和子孙对象以及子孙对象的属性
 * @param obj
 */
function hOClearObject(obj) {
    const names = Object.getOwnPropertyNames(obj);
    for (const key of names) {
        const attr = obj[key];
        if (typeof attr === 'object' && attr !== null) {
            hOClearObject(attr);
        }
        obj && (obj[key] = null);
    }
    //通过设置__proto__为null取消继承关系
    obj && (obj['__proto__'] = null);
}
/**
 *数值对象原样转换成字符串
 * @param value
 */
function hOObject2Num(value) {
    if (!isFinite(value) || value === null) {
        return '--';
    }
    typeof value !== 'number' && (value = parseFloat(value));
    return value + '';
}
export { hOCheckObject, hODeepClone, hODeepObjectMerge, hOClearObject, hOObject2Num, };
