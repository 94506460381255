var PiBaseRank_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-rank.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-rank.component.scss';
import { hODeepClone } from 'bc$/utils';
let PiBaseRank = PiBaseRank_1 = class PiBaseRank extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseRank';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseRank_1?.NAME,
        author: 'caiqw',
        title: '排名组件',
        version: BcVersion,
        updateDate: '2019-06-19',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 需要排序的数据
     */
    data;
    /**
     * 是否显示序列号
     */
    isShowNum;
    /**
     * 排名是否按升序
     */
    sortByAsc;
    /**
     * 自定义className
     */
    className;
    /**
     * 自定义样式
     */
    styles;
    /**
     * 自定义进度条颜色
     */
    bgColor;
    /**
     * 单位
     */
    unit;
    //#endregion
    //#region data
    options = {
        titlePre: '',
        title: PiBaseRank_1.ABOUT.title,
        titleSuf: '',
        isShowDownLoad: false,
    };
    dataList = [];
    //#endregion
    //#region computed
    get about() {
        return PiBaseRank_1.ABOUT;
    }
    get titleAll() {
        const options = this.options;
        return `${options.titlePre}${options.title}${options.titleSuf}`;
    }
    //#endregion
    //#region watch
    'watch.data'(newVal, oldVal) {
        let dataList = hODeepClone(newVal);
        let minValue = null;
        let maxValue = null;
        dataList.forEach((item) => {
            const _curData = Number(item.value) || 0;
            if (maxValue === null || minValue === null) {
                maxValue = _curData;
                minValue = _curData;
            }
            maxValue = _curData > maxValue ? _curData : maxValue;
            minValue = _curData > minValue ? minValue : _curData;
        });
        if (!maxValue) {
            maxValue = 100;
        }
        if (!minValue) {
            minValue = 0;
        }
        minValue =
            minValue == 0 ? minValue : minValue - Math.abs(maxValue - minValue) * 0.1;
        const differValue = Math.abs(maxValue - minValue) * 1.2;
        const basicValue = minValue;
        dataList.forEach((item) => {
            let _value = Number(item.value) || 0;
            item.width = (Math.abs(_value - basicValue) * 100) / differValue;
        });
        this.dataList = this.sortByKey(dataList, this.sortByAsc);
    }
    //#endregion
    //#region methods
    /**
     * 数组对象排序
     * @param array 被排序的数组
     * @param sortByAsc true按升序false为降序
     */
    sortByKey(array, sortByAsc) {
        return array.sort(function (a, b) {
            const x = Number(sortByAsc ? a.width : b.width) || 0;
            const y = Number(sortByAsc ? b.width : a.width) || 0;
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }
    //#endregion
    //#region 函数类属性
    //#endregion
    //#region hooks
    created() { }
};
__decorate([
    Prop({
        type: Array,
        required: true,
    })
], PiBaseRank.prototype, "data", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseRank.prototype, "isShowNum", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseRank.prototype, "sortByAsc", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseRank.prototype, "className", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseRank.prototype, "styles", void 0);
__decorate([
    Prop({
        type: String,
        default: '#1b9ee0',
    })
], PiBaseRank.prototype, "bgColor", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseRank.prototype, "unit", void 0);
__decorate([
    Watch('data', { immediate: true, deep: true })
], PiBaseRank.prototype, "watch.data", null);
PiBaseRank = PiBaseRank_1 = __decorate([
    Component({
        template,
    })
], PiBaseRank);
export default PiBaseRank;
