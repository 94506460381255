/**
 * 树类型
 */
export var PageSessionStorageEnum;
(function (PageSessionStorageEnum) {
    /**
     * 全屏
     */
    PageSessionStorageEnum["Fullscreen"] = "fullscreen";
})(PageSessionStorageEnum || (PageSessionStorageEnum = {}));
