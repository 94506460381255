var PiBaseEasyTable_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { EL_BETTER_SCROLL } from 'bc$/directives/scroll/scroll.directive';
import { hODeepClone } from 'bc$/main';
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { hODeepObjectMerge } from 'bc$/utils/object.helper';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { default as VCheckboxGroup } from './components/pi-base-easy-table-checkbox-group/pi-base-easy-table-checkbox-group.component';
import { default as VCheckbox } from './components/pi-base-easy-table-checkbox/pi-base-easy-table-checkbox.component';
import PiBaseEasyTableColumnCustom from './components/pi-base-easy-table-column-custom';
import { default as VDropdown } from './components/pi-base-easy-table-dropdown/pi-base-easy-table-dropdown.component';
import { default as TableEmpty } from './components/pi-base-easy-table-empty/pi-base-easy-table-empty.component';
import { default as Loading } from './components/pi-base-easy-table-loading/pi-base-easy-table-loading.component';
import { default as BodyCellMergeMixin } from './mixins/body-cell-merge-mixin';
import { default as CellEditMixin } from './mixins/cell-edit-mixin';
import { default as CheckboxSelectionMixin } from './mixins/checkbox-selection-mixin';
import { default as ClassesMixin } from './mixins/classes-mixin';
import { default as DragWidthMixin } from './mixins/drag-width-mixin';
import { default as FrozenColumnsMixin } from './mixins/frozen-columns-mixin';
import { default as ScrollBarControlMixin } from './mixins/scroll-bar-control-mixin';
import { default as ScrollControlMixin } from './mixins/scroll-control-mixin';
import { default as SortControlMixin } from './mixins/sort-control-mixin';
import { default as TableEmptyMixin } from './mixins/table-empty-mixin';
import { default as TableFiltersMixin } from './mixins/table-filters-mixin';
import { default as TableFooterMixin } from './mixins/table-footer-mixin';
import { default as TableResizeMixin } from './mixins/table-resize-mixin';
import { default as TableRowMouseEventsMixin } from './mixins/table-row-mouse-events-mixin';
import { default as TitleCellMergeMixin } from './mixins/title-cell-merge-mixin';
import template from './pi-base-easy-table.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-easy-table.component.scss';
import { default as DeepClone } from './utils/deep-clone';
import { default as Utils } from './utils/utils';
export * from './pi-base-easy-table.entity';
const PiBase = VueBase;
let PiBaseEasyTable = PiBaseEasyTable_1 = class PiBaseEasyTable extends PiBase {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEasyTable';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEasyTable_1?.NAME,
        author: 'yangd',
        title: '基础表格',
        version: BcVersion,
        updateDate: '2019-08-08',
        description: '',
    };
    static SWIPE = {
        style: 'cubic-bezier(0.23, 1, 0.32, 1)',
        fn: function (t) {
            return 1 + --t * t * t * t * t;
        },
    };
    static SWIPE_BOUNCE = {
        style: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
        fn: function fn(t) {
            return t * (2 - t);
        },
    };
    static BOUNCE = {
        style: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
        fn: function fn(t) {
            return 1 - --t * t * t * t;
        },
    };
    /**
     * v-scroll 滚轮事件 Code
     */
    static V_SCROLL_MOUSE_WHEEL_EVENT_CODE = 'mousewheelMove';
    /**
     * 表格默认最大宽度
     */
    static DEF_MAX_WIDTH = 10000;
    //#endregion
    //#region props
    /**
     * 开启多选状态
     */
    multipleCheck;
    cmpsDynamic;
    scrollWidth;
    width;
    minWidth;
    height;
    minHeight;
    titleRowHeight;
    /**
     * 随着浏览器窗口改变，横向自适应
     */
    isHorizontalResize;
    /**
     * 随着浏览器窗口改变，垂直自适应
     */
    isVerticalResize;
    /**
     * 表格内容太少时,页脚是否接着内容显示
     */
    isFooterFollow;
    /**
     * 垂直自适应偏移量
     */
    verticalResizeOffset;
    tableBgColor;
    /**
     * 表头背景颜色
     */
    titleBgColor;
    /**
     * 奇数行颜色
     */
    oddBgColor;
    /**
     * 偶数行颜色
     */
    evenBgColor;
    /**
     * 内容行高
     */
    rowHeight;
    /**
     * 是否自动调节行高
     */
    isAutoResizeCellHeight;
    /**
     * 多列排序
     */
    multipleSort;
    /**
     * 只在 升序和倒序切换
     */
    sortAlways;
    columns;
    /**
     * 特殊表头
     */
    titleRows;
    tableData;
    /**
     * 分页序号
     */
    pagingIndex;
    /**
     * 没数据时的html
     */
    errorContent;
    /**
     * 没数据时内容区域高度
     */
    errorContentHeight;
    /**
     * 是否正在加载,为false 则会显示错误信息（如果加载时间较长，最好设置为true,数据返回后设置为false）
     */
    isLoading;
    loadingContent;
    /**
     * 不设置则没有hover效果
     */
    rowHoverColor;
    rowClickColor;
    showVerticalBorder;
    showHorizontalBorder;
    footer;
    footerRowHeight;
    columnWidthDrag;
    loadingOpacity;
    /**
     * 表体单元格样式回调
     */
    columnCellClassName;
    /**
     * footer单元格样式回调
     */
    footerCellClassName;
    /**
     * 行单击回调
     */
    rowClick;
    /**
     * 行双击回调
     */
    rowDblclick;
    /**
     * 表头单元格单击回调
     */
    titleClick;
    /**
     * 表头单元格双击回调
     */
    titleDblclick;
    /**
     * 鼠标进入行的回调
     */
    rowMouseEnter;
    /**
     * 鼠标离开行的回调
     */
    rowMouseLeave;
    /**
     * 单元格编辑完成回调
     */
    cellEditDone;
    /**
     * 单元格合并
     */
    cellMerge;
    /**
     * select all
     */
    selectAll;
    /**
     * 单个checkbox change event
     */
    selectChange;
    /**
     * checkbox-group change event
     */
    selectGroupChange;
    /**
     * filter event
     */
    filterMethod;
    /**
     * 行拖拽排序是否启用-有合并单元格时就算开启了也不会实现拖拽
     */
    rowDragSort;
    /**
     * 开启行点击选中复选框功能
     */
    rowClickCheckGroupChange;
    /**
     * 挂载时自动调整表格视窗
     */
    isAdjustBodyViewHeightOnMounted;
    /**
     * 双击复制内容
     */
    isAllowDbClickCopyContent;
    /**
     * 是否禁用左侧冻结列用户行为滚动
     */
    isDisabledLeftFrozenScroll;
    /**
     * 是否禁用滚动条轨道点击事件
     */
    isDisabledScrollTrackClickable;
    //#endregion
    //#region data
    /**
     * 表头，表体是否处于滚动中
     */
    isTableScrolling = false;
    /**
     * 属性值的响应式代理
     */
    propReactiveProxy = {
        titleRowHeight: this.titleRowHeight,
        rowHeight: this.rowHeight,
        footerRowHeight: this.footerRowHeight,
        errorContentHeight: this.errorContentHeight,
        minWidth: this.minWidth,
        minHeight: this.minHeight,
    };
    // 本地列表数据
    internalTableData = [];
    // 本地宽度
    internalWidth = 0;
    // 本地高度
    internalHeight = 0;
    // 本地列数据
    internalColumns = [];
    // 本地复杂表头数据
    internalTitleRows = [];
    errorMsg = ' V-Table error: ';
    // 最大宽度（当width:'max'时）
    maxWidth = PiBaseEasyTable_1.DEF_MAX_WIDTH;
    hasFrozenColumn = false; // 是否拥有固定列（false时最后一列的右边border无边框）
    resizeTimer = null;
    /**
     * 滚动条实例
     */
    scrollerList = [];
    lock = false;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEasyTable_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    // 是否是复杂表头
    get isComplexTitle() {
        return (Array.isArray(this.internalTitleRows) && this.internalTitleRows.length > 0);
    }
    // 获取表格高度
    get getTableHeight() {
        if (this.isVerticalResize) {
            return '100%';
        }
        //与源码不同
        if (this.isTableEmpty) {
            return `${this.tableEmptyHeight}px`;
        }
        return `${this.internalHeight}px`;
        //return this.isTableEmpty ? this.tableEmptyHeight : this.internalHeight;
    }
    // 左侧区域宽度
    get leftViewWidth() {
        let result = 0;
        if (this.hasFrozenColumn) {
            result = this.frozenCols.reduce((total, curr) => total + curr.width, 0);
        }
        return result;
    }
    // 右侧区域宽度
    get rightViewWidth() {
        let result = this.internalWidth - this.leftViewWidth;
        //-2是边框
        return this.hasFrozenColumn ? result - 2 : result;
    }
    // 左侧、右侧区域高度
    get bodyViewHeight() {
        let result;
        if (this.internalTitleRows.length > 0) {
            result =
                this.internalHeight -
                    this.propReactiveProxy.titleRowHeight *
                        (this.internalTitleRows.length + this.getTitleRowspanTotalCount);
        }
        else {
            result = this.internalHeight - this.propReactiveProxy.titleRowHeight;
        }
        // 1px: 当有滚动条时，使滚动条显示全
        result -= this.footerTotalHeight + 1;
        return result;
    }
    // 所有列的总宽度
    get totalColumnsWidth() {
        return this.internalColumns.reduce(function (total, curr) {
            return curr.width ? total + curr.width : total;
        }, 0);
    }
    // 获取未固定列的总宽度
    get totalNoFrozenColumnsWidth() {
        return this.noFrozenCols.reduce(function (total, curr) {
            return curr.width ? total + curr.width : total;
        }, 0);
    }
    // 获取所有的字段
    get getColumnsFields() {
        return this.internalColumns.map((item) => {
            return item.field;
        });
    }
    // 获取非固定列的字段集合
    get getNoFrozenColumnsFields() {
        return this.internalColumns
            .filter((x) => !x.isFrozen)
            .map((item) => {
            return item.field;
        });
    }
    // 获取固定列的字段集合
    get getFrozenColumnsFields() {
        return this.internalColumns
            .filter((x) => x.isFrozen)
            .map((item) => {
            return item.field;
        });
    }
    // 获取当前组件根元素的css class
    get rootClasses() {
        return {
            'pi-table-loading': this.isLoading,
            'pi-horizontal-resize': this.isHorizontalResize,
            'pi-no-horizontal-scroll': this.noHorizontalScroll,
            'pi-has-footer': this.frozenFooterCols.length + this.noFrozenFooterCols.length > 0,
            'pi-table-auto-cell-height': this.isAutoResizeCellHeight,
        };
    }
    // 获取当前组件根元素的css style
    get rootStyles() {
        const styleOption = {
            width: this.internalWidth + 'px',
            height: this.getTableHeight,
            'background-color': this.tableBgColor,
        };
        this.isAutoResizeCellHeight && (styleOption['--height'] = `${this.rowHeight}px`);
        return styleOption;
        // return {
        //   width: this.internalWidth + 'px',
        //   height: this.getTableHeight,
        //   'background-color': this.tableBgColor,
        // };
    }
    /**
     * sortableJs options
     */
    get rowSortableJsOptions() {
        return {
            sort: this.rowDragSort && !this.cellMerge,
            group: {
                name: '表格拖拽',
                pull: false,
                put: false,
            },
            onStart: this.sortableJsEventStart,
            onEnd: this.sortableJsEventEnd,
            // handle: '.v-table-row',
            draggable: '.v-table-row',
            easing: 'cubic-bezier(1, 0, 0, 1)',
            animation: 500,
        };
    }
    /**
     * v-scroll滚动条交互配置项
     */
    get leftScrollbarInteractOptions() {
        const isAble = !this.isDisabledLeftFrozenScroll;
        return Object.freeze({
            fade: true,
            interactive: isAble,
            scrollbarTrackClickable: isAble,
        });
    }
    get rightHeadScrollbarInteractOptions() {
        return Object.freeze({
            fade: true,
            interactive: true,
            scrollbarTrackClickable: false,
        });
    }
    //#endregion
    //#region watch
    // 重新跟新列信息
    'watch.columns'(newVal, oldVal) {
        this.initColumns();
        // fix issue #261
        this.resize();
    }
    // 重新覆盖复杂表头信息
    'watch.titleRows'(newVal, oldVal) {
        this.initColumns();
    }
    // deep watch
    'watch.tableData'(newVal, oldVal) {
        const tableDataOld = this.internalTableData;
        const tableDataNew = this.initInternalTableData();
        let isChangeFieldOnly = tableDataOld.length === tableDataNew.length;
        for (let i = 0, length = tableDataNew.length; i < length; i++) {
            const rowNew = tableDataNew[i];
            const rowOld = tableDataOld.find((p) => p._pi_guid === rowNew._pi_guid);
            if (rowOld) {
                if (JSON.stringify(rowOld) !== JSON.stringify(rowNew)) {
                    hODeepObjectMerge(rowOld, rowNew);
                }
                tableDataNew.splice(i, 1, rowOld);
            }
            else {
                isChangeFieldOnly = false;
            }
        }
        this.internalTableData = tableDataNew;
        // 清除选中行索引，解决刷新数据时由于行索引未发生变化不触发设置背景颜色bug
        this.clearCurrentRow();
        if (isChangeFieldOnly) {
            return;
        }
        this.skipRenderCells = [];
        // this.internalTableData = this.initInternalTableData();
        this.updateCheckboxGroupModel();
        this.tableEmpty();
        if (Array.isArray(newVal) && newVal.length > 0) {
            this.initView();
            this.scrollControl();
        }
        this.resize();
        // 刷新滚动条
        this.scrollerRefresh();
    }
    'watch.pagingIndex'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.clearCurrentRow();
        this.bodyScrollTop();
    }
    // ------响应式Watch----start
    'watch.titleRowHeight'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.refreshPropReactiveProxy();
    }
    'watch.rowHeight'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.refreshPropReactiveProxy();
    }
    'watch.footerRowHeight'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.refreshPropReactiveProxy();
    }
    'watch.errorContentHeight'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.refreshPropReactiveProxy();
    }
    'watch.minWidth'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.refreshPropReactiveProxy();
    }
    'watch.minHeight'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.refreshPropReactiveProxy();
    }
    // ------响应式Watch----end
    //#endregion
    //#region methods
    //#region 处理表格内部高度不充满容器问题
    /**
     * 调整表格视窗高度
     * @param isInit 是否是初始化时候调用
     */
    adjustBodyViewHeight(isInit = false) {
        const resizeViewHeight = () => {
            this.$nextTick(() => {
                setTimeout(() => {
                    const totalHeight = this.$refs.tableRoot.clientHeight;
                    const titleHeight = this.$refs.tableRightHead?.clientHeight ?? 0;
                    const leftViewInstance = this.$refs.tableLeftBody;
                    const rightViewInstance = this.$refs.tableRightViewBody;
                    const bodyHeight = totalHeight - titleHeight + 1;
                    leftViewInstance && (leftViewInstance.style.height = `${bodyHeight}px`);
                    rightViewInstance && (rightViewInstance.style.height = `${bodyHeight}px`);
                }, 0);
            });
        };
        if (this.isAdjustBodyViewHeightOnMounted) {
            if (this.getFrozenColumnsFields.length > 0) {
                //有冻结列时取左右表格当前行的最大高度为行高度
                setTimeout(() => {
                    const leftViewBodyRows = this.$refs.tableLeftBody.querySelectorAll('.v-table-row');
                    const rightViewBodyRows = this.$refs.tableRightViewBody.querySelectorAll('.v-table-row');
                    rightViewBodyRows.forEach((dom, index) => {
                        const leftHeight = leftViewBodyRows[index].clientHeight;
                        const rightHeight = dom.clientHeight;
                        const isLeftDirection = leftHeight < rightHeight;
                        const height = isLeftDirection ? rightHeight : leftHeight;
                        isLeftDirection ? leftViewBodyRows[index].style.height = `${height}px`
                            : rightViewBodyRows[index].style.height = `${height}px`;
                    });
                    resizeViewHeight();
                }, 0);
            }
            else {
                resizeViewHeight();
            }
        }
        else {
            !isInit && resizeViewHeight();
        }
    }
    /**
     * 双击复制内容
     */
    async dbClickCopyContent(content) {
        if (navigator.clipboard && window.isSecureContext) {
            try {
                await navigator.clipboard.writeText(content);
                this.$Message.success(`成功复制到剪切板!`);
            }
            catch (err) {
                console.error('Failed to copy: ', err);
                this.$Message.error(`复制失败，错误原因:${err}`);
            }
        }
        else {
            const textArea = document.createElement('textarea');
            textArea.value = content;
            // 使text area不在viewport，同时设置不可见
            textArea.style.position = 'absolute';
            textArea.style.opacity = '0';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand('copy') ? this.$Message.success(`成功复制到剪切板!`)
                : this.$Message.error(`复制失败`);
            textArea.remove();
        }
    }
    //#region 处理滚动条拖动时，头部列错位问题
    /**
     * 滚动条强制刷新
     */
    scrollerRefresh() {
        this.resetTimeOut('scrollerRefresh', () => {
            const { scrollerList } = this;
            if (scrollerList && scrollerList.length) {
                scrollerList.forEach((scroller) => {
                    scroller &&
                        this.$nextTick(() => {
                            scroller.refresh();
                        });
                });
            }
        });
    }
    /**
     * 表格滚动条初始化异步
     *
     * 处理在拖动底部滚动条时头滚动条错位问题
     */
    scrollerPromise(id = Math.random()) {
        return new Promise((res) => {
            window.setTimeout(() => {
                // console.warn('--------- 滚动条初始化 ', id);
                res(void 0);
            }, 500);
        });
    }
    /**
     * 滚动条初始化完成后事件
     * @param bsc
     */
    initScrollSuccess(bsc, isDisabled = false) {
        if (bsc) {
            isDisabled ? bsc.disable() : bsc.refresh();
            this.scrollerList.push(bsc);
            this.patchScrollHandler(bsc);
        }
    }
    /**
     * 滚动条bug修复
     * @param bsc
     */
    patchScrollHandler(bsc) {
        const isRightTableBodyTrackDom = bsc.content.classList.contains('v-table-btable');
        //只处理rightTableBody的scroll
        if (isRightTableBodyTrackDom) {
            //解决点击滚动条时因为多触发一次置底/顶事件而造成的错位Bug
            this.$nextTick(() => {
                let bscrollXTrackDom = bsc.content.nextSibling;
                let bscrollXDom = bscrollXTrackDom?.querySelector('.bscroll-indicator');
                let bscrollYTrackDom = bscrollXTrackDom.nextSibling;
                let bscrollYDom = bscrollYTrackDom?.querySelector('.bscroll-indicator');
                //缓存滚动条轨道触发置底/顶事件之前的位置
                const mousedownFn = e => {
                    bsc.cacheClickX = e.offsetX;
                    // bsc.cacheLastScrollX = bsc.cacheScrollX || 0;
                    bsc.cacheScrollX = Math.abs(bsc.x);
                    bsc.cacheClickY = e.offsetY;
                    // bsc.cacheLastScrollY = bsc.cacheScrollY || 0;
                    bsc.cacheScrollY = Math.abs(bsc.y);
                    bsc.cacheX = bsc.x;
                    bsc.cacheY = bsc.y;
                    e.preventDefault();
                    e.stopPropagation();
                };
                //重置回缓存位置
                const scrollResetPos = () => {
                    bsc.scrollTo(bsc.cacheX, bsc.cacheY, 0, undefined);
                };
                //禁用滚动条的点击事件
                const disabledClickScrollFn = e => {
                    e.preventDefault();
                    e.stopPropagation();
                };
                //修复点击横向滚动track时错位Bug
                const fixSyncScrollXTrackClickFn = () => {
                    const curPosX = Math.abs(bsc.x);
                    if (!bsc.moveDistanceX) {
                        bsc.moveDistanceX = curPosX;
                        bsc.isLargeSizeColumn = Math.abs(bsc.maxScrollX) !== bsc.moveDistanceX;
                    }
                    const tableRightHead = this.$refs['tableRightHead'];
                    const bScrollRightHead = tableRightHead?.[EL_BETTER_SCROLL];
                    //大体量数据列时情况
                    if (bsc.isLargeSizeColumn) {
                        //TODO: 先禁用，暂时的无bug解决方案
                        scrollResetPos();
                        //TODO: 表头貌似会从头开始滚动
                        // const moveDirection = bsc.cacheScrollX >= bsc.cacheLastScrollX ? 'right' : 'left';
                        // bScrollRightHead?.scrollTo(
                        //   moveDirection == 'right' ? bsc.cacheScrollX + bsc.moveDistanceX : bsc.cacheScrollX - bsc.moveDistanceX,
                        //   0,
                        //   1,
                        //   undefined,
                        // );
                    }
                    else { //小体量数据列时情况
                        const moveDirection = bsc.cacheClickX > bsc.cacheScrollX ? 'right' : 'left';
                        bScrollRightHead?.scrollTo(moveDirection == 'right' ? bsc.maxScrollX : bsc.minScrollX, 0, 300, undefined);
                    }
                };
                //修复点击纵向滚动track时错位Bug
                const fixSyncScrollYTrackClickFn = () => {
                    const curPosY = Math.abs(bsc.y);
                    if (!bsc.moveDistanceY) {
                        bsc.moveDistanceY = curPosY;
                        bsc.isLargeSizeRow = Math.abs(bsc.maxScrollY) !== bsc.moveDistanceY;
                    }
                    const tableLeftBody = this.$refs['tableLeftBody'];
                    const bScrollLeftBody = tableLeftBody?.[EL_BETTER_SCROLL];
                    if (bsc.isLargeSizeRow) {
                        //TODO: 先禁用，暂时的无bug解决方案
                        scrollResetPos();
                        //TODO: 表体貌似会从头开始滚动
                        // const moveDirection = bsc.cacheScrollY >= bsc.cacheLastScrollY ? 'down' : 'up';
                        // bScrollLeftBody?.scrollTo(
                        //   bScrollLeftBody.x,
                        //   moveDirection == 'down' ? bsc.cacheScrollY + bsc.moveDistanceY : bsc.cacheScrollY - bsc.moveDistanceY,
                        //   1,
                        //   undefined,
                        // );
                    }
                    else {
                        const moveDirection = bsc.cacheClickY > bsc.cacheScrollY ? 'down' : 'up';
                        bScrollLeftBody?.scrollTo(bScrollLeftBody.x, moveDirection == 'down' ? bsc.maxScrollY : bsc.minScrollY, 300, undefined);
                    }
                };
                bscrollXDom?.addEventListener('click', disabledClickScrollFn);
                bscrollXTrackDom?.addEventListener('mousedown', mousedownFn);
                bscrollYDom?.addEventListener('click', disabledClickScrollFn);
                bscrollYTrackDom?.addEventListener('mousedown', mousedownFn);
                if (this.isDisabledScrollTrackClickable) {
                    bscrollXTrackDom?.addEventListener('click', scrollResetPos);
                    bscrollYTrackDom?.addEventListener('click', scrollResetPos);
                }
                else {
                    bscrollXTrackDom?.addEventListener('click', fixSyncScrollXTrackClickFn);
                    bscrollYTrackDom?.addEventListener('click', fixSyncScrollYTrackClickFn);
                }
                //垃圾回收，防止内存泄漏
                this.$once('hook:beforeDestroy', () => {
                    bscrollXDom?.removeEventListener('click', disabledClickScrollFn);
                    bscrollXTrackDom?.removeEventListener('mousedown', mousedownFn);
                    bscrollYDom?.removeEventListener('click', disabledClickScrollFn);
                    bscrollYTrackDom?.removeEventListener('mousedown', mousedownFn);
                    if (this.isDisabledScrollTrackClickable) {
                        bscrollXTrackDom?.removeEventListener('click', scrollResetPos);
                        bscrollYTrackDom?.removeEventListener('click', scrollResetPos);
                    }
                    else {
                        bscrollXTrackDom?.removeEventListener('click', fixSyncScrollXTrackClickFn);
                        bscrollYTrackDom?.removeEventListener('click', fixSyncScrollYTrackClickFn);
                    }
                    bscrollXDom = null;
                    bscrollXTrackDom = null;
                    bscrollYDom = null;
                    bscrollYTrackDom = null;
                });
            });
        }
    }
    //#endregion
    /**
     * v-scroll 事件
     */
    getVScrollEvents(fn) {
        return {
            // 鼠标点击拖动
            scroll: (pos) => fn(pos, 'scroll'),
            scrollEnd: (pos) => fn(pos, 'scrollEnd'),
            // 滚轮
            mousewheelMove: (pos) => fn(pos, PiBaseEasyTable_1.V_SCROLL_MOUSE_WHEEL_EVENT_CODE),
            // // 触控 touch move
            // move: (pos: BsPosition) => fn(pos, 'move'),
        };
    }
    /**
     * Element dragging started
     * @param event
     */
    sortableJsEventStart(event) {
        // 禁用滚动条，优化性能
        this.$piDispatch(this, 'updateScrollBarState', false, 'PiBaseEasyTable', true);
    }
    /**
     * Element dragging ended
     * @param event
     */
    sortableJsEventEnd(event) {
        const { oldIndex, newIndex } = event;
        const oldData = hODeepClone(this.internalTableData[oldIndex]);
        if (oldIndex === newIndex) {
            return;
        }
        if (newIndex > oldIndex) {
            this.internalTableData.splice(newIndex + 1, 0, oldData);
            this.internalTableData.splice(oldIndex, 1);
        }
        else if (newIndex < oldIndex) {
            this.internalTableData.splice(newIndex, 0, oldData);
            this.internalTableData.splice(oldIndex + 1, 1);
        }
        this.$emit('on-sort-data', this.internalTableData);
    }
    customCompFunc(params) {
        this.$emit('on-custom-comp', params);
    }
    // 行颜色
    trBgColor(num) {
        if ((this.evenBgColor && this.evenBgColor.length > 0) ||
            (this.oddBgColor && this.oddBgColor.length > 0)) {
            return num % 2 === 0
                ? { 'background-color': this.evenBgColor }
                : { 'background-color': this.oddBgColor };
        }
    }
    // 设置 column 列的样式
    setColumnCellClassName(rowIndex, field, rowData) {
        return (this.columnCellClassName &&
            this.columnCellClassName(rowIndex, field, rowData));
    }
    // 获取每个表头列的宽度
    titleColumnWidth(fields) {
        let result = 0;
        if (Array.isArray(fields)) {
            let matchItems = this.internalColumns.filter((item, index) => {
                return fields.some((x) => x === item.field);
            });
            result = matchItems.reduce((total, curr) => total + curr.width, 0);
        }
        else {
            console.error(this.errorMsg + 'the fields attribute must be a array in titleRows');
        }
        return result;
    }
    // 获取每个表头列的高度
    titleColumnHeight(rowspan) {
        if (rowspan && rowspan > 0) {
            return this.propReactiveProxy.titleRowHeight * rowspan;
        }
        else {
            return this.propReactiveProxy.titleRowHeight;
        }
    }
    // 超出的title提示
    overflowTitle(row, rowIndex, col) {
        let result = '';
        if (typeof col.formatter === 'function') {
            let val = col.formatter(row, rowIndex, this.pagingIndex, col.field);
            this.$refs['test'];
            // 如果是html 不处理
            if (Utils.isHtml(val)) {
                result = '';
            }
            else {
                result = val;
            }
        }
        else {
            result = row[col.field];
        }
        return result;
    }
    // 获取所有列的总高度
    getTotalColumnsHeight() {
        let titleTotalHeight = this.internalTitleRows && this.internalTitleRows.length > 0
            ? this.propReactiveProxy.titleRowHeight * this.internalTitleRows.length
            : this.propReactiveProxy.titleRowHeight;
        titleTotalHeight += this.footerTotalHeight;
        return (titleTotalHeight +
            this.internalTableData.length * this.propReactiveProxy.rowHeight +
            1);
    }
    // 初始化width
    initTableWidth() {
        this.internalWidth = this.isHorizontalResize
            ? this.maxWidth
            : this.width;
    }
    // 当宽度设置 && 非固定列未设置宽度时（列自适应）初始化列集合
    initColumns() {
        this.internalHeight = this.height;
        this.footerTotalHeight = this.getFooterTotalRowHeight;
        const columnsNew = Array.isArray(this.columns)
            ? DeepClone(this.columns)
            : [];
        this.addGuid(columnsNew);
        this.internalColumns = columnsNew;
        // 修改columns的width
        this.internalColumns.forEach((item, index) => {
            if (item.width) {
                item.width = hReactiveUtil.convertToReactivePx(this.columns[index].width);
            }
        });
        const titleRowsNew = Array.isArray(this.titleRows)
            ? DeepClone(this.titleRows)
            : [];
        this.addGuid(titleRowsNew);
        this.internalTitleRows = titleRowsNew;
        this.initColumnsFilters();
        this.initResizeColumns();
        this.hasFrozenColumn = this.internalColumns.some((x) => x.isFrozen);
        this.initTableWidth();
        this.setSortColumns();
        let self = this, widthCountCheck = 0;
        if (self.internalWidth && self.internalWidth > 0) {
            self.internalColumns.forEach(function (item) {
                if (!(item.width && item.width > 0)) {
                    widthCountCheck++;
                    if (self.isHorizontalResize) {
                        console.error(self.errorMsg +
                            "If you are using the isHorizontalResize property,Please set the value for each column's width");
                    }
                    else {
                        item.width = self.internalWidth - self.totalColumnsWidth;
                    }
                }
            });
        }
        if (widthCountCheck > 1) {
            console.error(this.errorMsg + 'Only allow one column is not set width');
        }
    }
    // 当没设置宽度和高度时动态计算
    initView() {
        // 当没有设置宽度计算总宽度
        if (!(this.internalWidth && this.internalWidth > 0)) {
            if (this.columns && this.columns.length > 0) {
                this.internalWidth = this.columns.reduce((total, curr) => total + curr.width, 0);
            }
        }
        let totalColumnsHeight = this.getTotalColumnsHeight();
        // 当没有设置高度时计算总高度 || 设置的高度大于所有列高度之和时
        if (!(this.height && this.height > 0) || this.height > totalColumnsHeight) {
            if (!this.isVerticalResize) {
                this.internalHeight = totalColumnsHeight;
            }
        }
        else if (this.height <= totalColumnsHeight) {
            this.internalHeight = this.height;
        }
    }
    /**
     * 添加行唯一id到行数据或列数据或表头行数据
     */
    addGuid(arr) {
        //与源码不同
        if (Array.isArray(arr)) {
            const time = new Date().getTime();
            const fnSetGuid = (p, index, index2) => {
                if (!p._pi_guid) {
                    if (!index2) {
                        index2 = 0;
                    }
                    p._pi_guid = `${time}_${index}_${index2}`;
                }
            };
            arr.forEach((p, index) => {
                if (Array.isArray(p)) {
                    p.forEach((p2, index2) => {
                        fnSetGuid(p2, index, index2);
                    });
                }
                else {
                    fnSetGuid(p, index);
                }
            });
        }
    }
    initInternalTableData() {
        const dataNew = Array.isArray(this.tableData)
            ? DeepClone(this.tableData)
            : [];
        this.addGuid(dataNew);
        return dataNew;
    }
    // 对外暴露（隐藏显示切换时）
    resize() {
        // fixed bug in IE9 #17
        clearTimeout(this.resizeTimer);
        this.resizeTimer = window.setTimeout(() => {
            this.$nextTick(() => {
                this.tableResize();
            });
        });
    }
    /**
     * 添加的冻结列左列滚动事件回调,与源码不同
     * 修改滚动到的位置
     * @param pos 新的位置
     * @param actionType 事件类型
     */
    scrollTableLeftBody(pos, actionType) {
        if (this.isTableScrolling || this.isLoading) {
            return;
        }
        this.isTableScrolling = true;
        const tableLeftHead = this.$refs['tableLeftHead'];
        if (tableLeftHead) {
            const bScrollLeftHead = tableLeftHead[EL_BETTER_SCROLL];
            if (bScrollLeftHead) {
                bScrollLeftHead.scrollTo(pos.x, 0);
            }
        }
        // 判断滚轮事件
        const isWheel = actionType === PiBaseEasyTable_1.V_SCROLL_MOUSE_WHEEL_EVENT_CODE;
        const tableRightBody = this.$refs['tableRightBody'];
        if (tableRightBody) {
            const bScrollRightBody = tableRightBody.$el[EL_BETTER_SCROLL];
            if (bScrollRightBody) {
                //右侧表身仅纵向跟随
                bScrollRightBody.scrollTo(bScrollRightBody.x, pos.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        const tableLeftFooter = this.$refs['tableLeftFooter'];
        if (tableLeftFooter) {
            const bScrollLeftFooter = tableLeftFooter[EL_BETTER_SCROLL];
            if (bScrollLeftFooter) {
                //左侧表脚仅横向跟随
                bScrollLeftFooter.scrollTo(pos.x, bScrollLeftFooter.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        this.isTableScrolling = false;
    }
    /**
     * 添加的冻结列右列滚动事件回调,与源码不同
     * 修改滚动到的位置
     * @param pos 新的位置
     * @param actionType 事件类型
     */
    scrollTableRightBody(pos, actionType) {
        if (this.isTableScrolling || this.isLoading) {
            return;
        }
        this.isTableScrolling = true;
        // 判断滚轮事件
        const isWheel = actionType === PiBaseEasyTable_1.V_SCROLL_MOUSE_WHEEL_EVENT_CODE;
        const tableRightHead = this.$refs['tableRightHead'];
        if (tableRightHead) {
            const bScrollRightHead = tableRightHead[EL_BETTER_SCROLL];
            if (bScrollRightHead) {
                //右侧表头仅横向跟随
                // console.warn(
                //   `--------${new Date().valueOf()}---右侧头部开始执行位移`,
                //   pos.x,
                // );
                bScrollRightHead.scrollTo(pos.x, 0, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : undefined);
            }
        }
        const tableLeftBody = this.$refs['tableLeftBody'];
        if (tableLeftBody) {
            const bScrollLeftBody = tableLeftBody[EL_BETTER_SCROLL];
            if (bScrollLeftBody) {
                //左侧表身仅纵向跟随
                bScrollLeftBody.scrollTo(bScrollLeftBody.x, pos.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : undefined);
            }
        }
        const tableRightFooter = this.$refs['tableRightFooter'];
        if (tableRightFooter) {
            const bScrollRightFooter = tableRightFooter[EL_BETTER_SCROLL];
            if (bScrollRightFooter) {
                //右侧表脚仅横向跟随
                bScrollRightFooter.scrollTo(pos.x, bScrollRightFooter.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : undefined);
            }
        }
        this.isTableScrolling = false;
    }
    /**
     * 右侧表头滚动带动表体滚动
     * 添加的冻结列右列滚动事件回调,与源码不同
     * 修改滚动到的位置
     * @param pos 新的位置
     * @param actionType 事件类型
     */
    scrollTableRightHead(pos, actionType) {
        if (this.isTableScrolling || this.isLoading) {
            return;
        }
        this.isTableScrolling = true;
        // 判断滚轮事件
        const isWheel = actionType === PiBaseEasyTable_1.V_SCROLL_MOUSE_WHEEL_EVENT_CODE;
        const tableRightBody = this.$refs['tableRightBody'];
        if (tableRightBody) {
            const bScrollRightBody = tableRightBody.$el[EL_BETTER_SCROLL];
            if (bScrollRightBody) {
                bScrollRightBody.scrollTo(pos.x, 0, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : undefined);
            }
        }
        const tableRightFooter = this.$refs['tableRightFooter'];
        if (tableRightFooter) {
            const bScrollRightFooter = tableRightFooter[EL_BETTER_SCROLL];
            if (bScrollRightFooter) {
                //右侧表脚仅横向跟随
                bScrollRightFooter.scrollTo(pos.x, bScrollRightFooter.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : undefined);
            }
        }
        this.isTableScrolling = false;
    }
    /**
     * 左侧表脚滚动带动表体滚动
     * 添加的冻结列右列滚动事件回调,与源码不同
     * 修改滚动到的位置
     * @param pos 新的位置
     * @param actionType 事件类型
     */
    scrollTableLeftFooter(pos, actionType) {
        if (this.isTableScrolling || this.isLoading) {
            return;
        }
        this.isTableScrolling = true;
        // 判断滚轮事件
        const isWheel = actionType === PiBaseEasyTable_1.V_SCROLL_MOUSE_WHEEL_EVENT_CODE;
        const tableLeftHead = this.$refs['tableLeftHead'];
        if (tableLeftHead) {
            const bScrollLeftHead = tableLeftHead[EL_BETTER_SCROLL];
            if (bScrollLeftHead) {
                //左侧表头仅横向跟随
                bScrollLeftHead.scrollTo(pos.x, 0);
            }
        }
        const tableRightFooter = this.$refs['tableRightFooter'];
        if (tableRightFooter) {
            const bScrollRightFooter = tableRightFooter[EL_BETTER_SCROLL];
            if (bScrollRightFooter) {
                //右侧表脚仅纵向跟随
                bScrollRightFooter.scrollTo(bScrollRightFooter.x, pos.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        const tableLeftBody = this.$refs['tableLeftBody'];
        if (tableLeftBody) {
            const bScrollLeftBody = tableLeftBody[EL_BETTER_SCROLL];
            if (bScrollLeftBody) {
                //左侧表身仅横向跟随
                bScrollLeftBody.scrollTo(pos.x, bScrollLeftBody.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        this.isTableScrolling = false;
    }
    /**
     * 右侧表脚滚动带动表体滚动
     * 添加的冻结列右列滚动事件回调,与源码不同
     * 修改滚动到的位置
     * @param pos 新的位置
     * @param actionType 事件类型
     */
    scrollTableRightFooter(pos, actionType) {
        if (this.isTableScrolling || this.isLoading) {
            return;
        }
        this.isTableScrolling = true;
        // 判断滚轮事件
        const isWheel = actionType === PiBaseEasyTable_1.V_SCROLL_MOUSE_WHEEL_EVENT_CODE;
        const tableLeftFooter = this.$refs['tableLeftFooter'];
        if (tableLeftFooter) {
            const bScrollLeftFooter = tableLeftFooter[EL_BETTER_SCROLL];
            if (bScrollLeftFooter) {
                //左侧表脚仅纵向跟随
                bScrollLeftFooter.scrollTo(bScrollLeftFooter.x, pos.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        const tableRightHead = this.$refs['tableRightHead'];
        if (tableRightHead) {
            const bScrollRightHead = tableRightHead[EL_BETTER_SCROLL];
            if (bScrollRightHead) {
                //右侧表头仅横向跟随
                bScrollRightHead.scrollTo(pos.x, 0, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        const tableLeftBody = this.$refs['tableLeftBody'];
        if (tableLeftBody) {
            const bScrollLeftBody = tableLeftBody[EL_BETTER_SCROLL];
            if (bScrollLeftBody) {
                //左侧表身仅纵向跟随
                bScrollLeftBody.scrollTo(bScrollLeftBody.x, pos.y, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        const tableRightBody = this.$refs['tableRightBody'];
        if (tableRightBody) {
            const bScrollRightBody = tableRightBody.$el[EL_BETTER_SCROLL];
            if (bScrollRightBody) {
                //右侧表身仅横向跟随
                bScrollRightBody.scrollTo(pos.x, 0, isWheel ? 300 : 0, isWheel ? PiBaseEasyTable_1.BOUNCE : PiBaseEasyTable_1.SWIPE);
            }
        }
        this.isTableScrolling = false;
    }
    /**
     * 响应式处理
     * 重新计算propReactiveProxy的个属性值
     */
    refreshPropReactiveProxy() {
        // 重新计算propReactiveProxy的值
        Object.keys(this.propReactiveProxy).forEach((key) => {
            this.propReactiveProxy[key] = hReactiveUtil.convertToReactivePx(this[key]);
        });
    }
    /**
     * 虚拟滚动条下div截获鼠标按下事件,禁止冒泡
     * 解决虚拟滚动条下编辑单元格内容无法拖动选择编辑原来的内容
     */
    handleMouseDown(eDown) {
        const fnMouseMove = (eMove) => {
            if (eMove.target.classList.contains('cell-edit-input')) {
                if (eMove.stopPropagation) {
                    eMove.stopPropagation();
                }
                else if (window.event) {
                    window.event.cancelBubble = true;
                }
            }
        };
        // 鼠标移动事件处理
        const curTarget = eDown.currentTarget;
        curTarget.addEventListener('mousemove', fnMouseMove);
        // 鼠标弹起时销毁鼠标移动事件处理
        curTarget.addEventListener('mouseup', () => {
            curTarget.removeEventListener('mousemove', fnMouseMove);
        });
    }
    //#endregion
    //#region hooks
    created() {
        this.refreshPropReactiveProxy();
        this.addGuid(this.tableData); //与源码不同
        this.addGuid(this.columns); //与源码不同
        this.addGuid(this.titleRows); //与源码不同
        this.$options.components = Object.assign({}, this.$options.components, this.cmpsDynamic || {});
        const tableDataNew = this.initInternalTableData();
        this.internalTableData = tableDataNew;
        if (Array.isArray(this.columns) && this.columns.length > 0) {
            this.initColumns();
        }
        this.updateCheckboxGroupModel();
        this.initView();
    }
    beforeDestroy() {
        clearTimeout(this.resizeTimer);
    }
    mounted() {
        this.setScrollbarWidth();
        this.tableEmpty();
        this.resize();
        if (Array.isArray(this.tableData) && this.tableData.length > 0) {
            this.scrollControl();
        }
        this.controlScrollBar();
        // console.warn('----表格挂载了');
        this.adjustBodyViewHeight(true);
    }
    /**
     *
     */
    updated() {
        // console.warn('----表格更新了');
    }
    /**
     * window resize事件
     */
    onWindowResize() {
        this.refreshPropReactiveProxy();
        this.initColumns();
        this.initView();
        this.resize();
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTable.prototype, "multipleCheck", void 0);
__decorate([
    Prop({
        type: Object,
        required: false,
    })
], PiBaseEasyTable.prototype, "cmpsDynamic", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0, //与源码不同
    })
], PiBaseEasyTable.prototype, "scrollWidth", void 0);
__decorate([
    Prop({
        type: [Number, String],
    })
], PiBaseEasyTable.prototype, "width", void 0);
__decorate([
    Prop({
        type: Number,
        default: 50,
    })
], PiBaseEasyTable.prototype, "minWidth", void 0);
__decorate([
    Prop({
        type: Number,
        required: false,
    })
], PiBaseEasyTable.prototype, "height", void 0);
__decorate([
    Prop({
        type: Number,
        default: 50,
    })
], PiBaseEasyTable.prototype, "minHeight", void 0);
__decorate([
    Prop({
        type: Number,
        default: 38,
    })
], PiBaseEasyTable.prototype, "titleRowHeight", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "isHorizontalResize", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "isVerticalResize", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTable.prototype, "isFooterFollow", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0,
    })
], PiBaseEasyTable.prototype, "verticalResizeOffset", void 0);
__decorate([
    Prop({
        type: String,
        default: '#fff',
    })
], PiBaseEasyTable.prototype, "tableBgColor", void 0);
__decorate([
    Prop({
        type: String,
        default: '#d1ebff',
    })
], PiBaseEasyTable.prototype, "titleBgColor", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseEasyTable.prototype, "oddBgColor", void 0);
__decorate([
    Prop({
        type: String,
        default: '#fbfbfb',
    })
], PiBaseEasyTable.prototype, "evenBgColor", void 0);
__decorate([
    Prop({
        type: Number,
        default: 40,
    })
], PiBaseEasyTable.prototype, "rowHeight", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "isAutoResizeCellHeight", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTable.prototype, "multipleSort", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "sortAlways", void 0);
__decorate([
    Prop({
        type: Array,
        required: true,
    })
], PiBaseEasyTable.prototype, "columns", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseEasyTable.prototype, "titleRows", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseEasyTable.prototype, "tableData", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0,
    })
], PiBaseEasyTable.prototype, "pagingIndex", void 0);
__decorate([
    Prop({
        type: String,
        default: '暂无数据',
    })
], PiBaseEasyTable.prototype, "errorContent", void 0);
__decorate([
    Prop({
        type: Number,
        default: 50,
    })
], PiBaseEasyTable.prototype, "errorContentHeight", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "isLoading", void 0);
__decorate([
    Prop({
        type: String,
        default: `<span><i class="v-icon-spin5 animate-loading-23" style="font-size: ${hReactiveUtil.convertToRem(28)}rem;opacity:0.6;"></i></span>`,
    })
], PiBaseEasyTable.prototype, "loadingContent", void 0);
__decorate([
    Prop({
        type: String,
        default: '#edf7fd',
    })
], PiBaseEasyTable.prototype, "rowHoverColor", void 0);
__decorate([
    Prop({
        type: String,
        default: '#e5f2ff',
    })
], PiBaseEasyTable.prototype, "rowClickColor", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTable.prototype, "showVerticalBorder", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTable.prototype, "showHorizontalBorder", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseEasyTable.prototype, "footer", void 0);
__decorate([
    Prop({
        type: Number,
        default: 40,
    })
], PiBaseEasyTable.prototype, "footerRowHeight", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "columnWidthDrag", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0.6,
    })
], PiBaseEasyTable.prototype, "loadingOpacity", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "columnCellClassName", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "footerCellClassName", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "rowClick", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "rowDblclick", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "titleClick", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "titleDblclick", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "rowMouseEnter", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "rowMouseLeave", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "cellEditDone", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "cellMerge", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "selectAll", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "selectChange", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "selectGroupChange", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseEasyTable.prototype, "filterMethod", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "rowDragSort", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "rowClickCheckGroupChange", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "isAdjustBodyViewHeightOnMounted", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTable.prototype, "isAllowDbClickCopyContent", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTable.prototype, "isDisabledLeftFrozenScroll", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTable.prototype, "isDisabledScrollTrackClickable", void 0);
__decorate([
    Watch('columns', { deep: true })
], PiBaseEasyTable.prototype, "watch.columns", null);
__decorate([
    Watch('titleRows', { deep: true })
], PiBaseEasyTable.prototype, "watch.titleRows", null);
__decorate([
    Watch('tableData', { deep: true })
], PiBaseEasyTable.prototype, "watch.tableData", null);
__decorate([
    Watch('pagingIndex', { deep: true })
], PiBaseEasyTable.prototype, "watch.pagingIndex", null);
__decorate([
    Watch('titleRowHeight')
], PiBaseEasyTable.prototype, "watch.titleRowHeight", null);
__decorate([
    Watch('rowHeight')
], PiBaseEasyTable.prototype, "watch.rowHeight", null);
__decorate([
    Watch('footerRowHeight')
], PiBaseEasyTable.prototype, "watch.footerRowHeight", null);
__decorate([
    Watch('errorContentHeight')
], PiBaseEasyTable.prototype, "watch.errorContentHeight", null);
__decorate([
    Watch('minWidth')
], PiBaseEasyTable.prototype, "watch.minWidth", null);
__decorate([
    Watch('minHeight')
], PiBaseEasyTable.prototype, "watch.minHeight", null);
PiBaseEasyTable = PiBaseEasyTable_1 = __decorate([
    Component({
        name: 'v-table',
        components: {
            TableEmpty,
            Loading,
            VCheckboxGroup,
            VCheckbox,
            VDropdown,
            PiBaseEasyTableColumnCustom,
        },
        template,
        mixins: [
            WindowEventMixin,
            TableEmptyMixin,
            ClassesMixin,
            TableResizeMixin,
            FrozenColumnsMixin,
            ScrollControlMixin,
            SortControlMixin,
            DragWidthMixin,
            CellEditMixin,
            BodyCellMergeMixin,
            TitleCellMergeMixin,
            CheckboxSelectionMixin,
            TableFooterMixin,
            ScrollBarControlMixin,
            TableRowMouseEventsMixin,
            TableFiltersMixin,
        ],
    })
], PiBaseEasyTable);
export default PiBaseEasyTable;
