/*
 * source:https://github.com/pvorb/clone/blob/master/clone.js
 */
export default (function () {
    'use strict';
    function _instanceof(obj, type) {
        return type != null && obj instanceof type;
    }
    let nativeMap;
    try {
        nativeMap = Map;
    }
    catch (_) {
        // maybe a reference error because no `Map`. Give it a dummy value that no
        // value will ever be an instanceof.
        nativeMap = function () { };
    }
    let nativeSet;
    try {
        nativeSet = Set;
    }
    catch (_) {
        nativeSet = function () { };
    }
    let nativePromise;
    try {
        nativePromise = Promise;
    }
    catch (_) {
        nativePromise = function () { };
    }
    /**
     * Clones (copies) an Object using deep copying.
     *
     * This function supports circular references by default, but if you are certain
     * there are no circular references in your object, you can save some CPU time
     * by calling clone(obj, false).
     *
     * Caution: if `circular` is false and `parent` contains circular references,
     * your program may enter an infinite loop and crash.
     *
     * @param `parent` - the object to be cloned
     * @param `circular` - set to true if the object to be cloned may contain
     *    circular references. (optional - true by default)
     * @param `depth` - set to a number if the object is only to be cloned to
     *    a particular depth. (optional - defaults to Infinity)
     * @param `prototype` - sets the prototype to be used when cloning an object.
     *    (optional - defaults to parent prototype).
     * @param `includeNonEnumerable` - set to true if the non-enumerable properties
     *    should be cloned as well. Non-enumerable properties on the prototype
     *    chain will be ignored. (optional - false by default)
     */
    function clone(parent, circular, depth, prototype, includeNonEnumerable) {
        if (typeof circular === 'object') {
            depth = circular.depth;
            prototype = circular.prototype;
            includeNonEnumerable = circular.includeNonEnumerable;
            circular = circular.circular;
        }
        // maintain two arrays for circular references, where corresponding parents
        // and children have the same index
        const allParents = [];
        const allChildren = [];
        const useBuffer = typeof Buffer != 'undefined';
        if (typeof circular == 'undefined') {
            circular = true;
        }
        if (typeof depth == 'undefined') {
            depth = Infinity;
        }
        // recurse this function so we don't reset allParents and allChildren
        function _clone(parentInner, depth) {
            // cloning null always returns null
            if (parentInner === null) {
                return null;
            }
            if (depth === 0) {
                return parentInner;
            }
            let child;
            let proto;
            if (typeof parentInner != 'object') {
                return parentInner;
            }
            if (_instanceof(parentInner, nativeMap)) {
                child = new nativeMap();
            }
            else if (_instanceof(parentInner, nativeSet)) {
                child = new nativeSet();
            }
            else if (_instanceof(parentInner, nativePromise)) {
                child = new nativePromise(function (resolve, reject) {
                    parentInner.then(function (value) {
                        resolve(_clone(value, depth - 1));
                    }, function (err) {
                        reject(_clone(err, depth - 1));
                    });
                });
            }
            else if (clone.__isArray(parentInner)) {
                child = [];
            }
            else if (clone.__isRegExp(parentInner)) {
                const parent2 = parentInner;
                child = new RegExp(parent2.source, __getRegExpFlags(parent2));
                if (parent2.lastIndex) {
                    child.lastIndex = parent2.lastIndex;
                }
            }
            else if (clone.__isDate(parentInner)) {
                const parent2 = parentInner;
                child = new Date(parent2.getTime());
            }
            else if (useBuffer && Buffer.isBuffer(parentInner)) {
                child = new Buffer(parentInner.length);
                parentInner.copy(child);
                return child;
            }
            else if (_instanceof(parentInner, Error)) {
                child = Object.create(parentInner);
            }
            else {
                if (typeof prototype == 'undefined') {
                    proto = Object.getPrototypeOf(parentInner);
                    child = Object.create(proto);
                }
                else {
                    child = Object.create(prototype);
                    proto = prototype;
                }
            }
            if (circular) {
                let index = allParents.indexOf(parentInner);
                if (index != -1) {
                    return allChildren[index];
                }
                allParents.push(parentInner);
                allChildren.push(child);
            }
            if (_instanceof(parentInner, nativeMap)) {
                parentInner.forEach(function (value, key) {
                    let keyChild = _clone(key, depth - 1);
                    let valueChild = _clone(value, depth - 1);
                    child.set(keyChild, valueChild);
                });
            }
            if (_instanceof(parentInner, nativeSet)) {
                parentInner.forEach(function (value) {
                    let entryChild = _clone(value, depth - 1);
                    child.add(entryChild);
                });
            }
            for (let i in parentInner) {
                let attrs;
                if (proto) {
                    attrs = Object.getOwnPropertyDescriptor(proto, i);
                }
                if (attrs && attrs.set == null) {
                    continue;
                }
                child[i] = _clone(parentInner[i], depth - 1);
            }
            if (Object.getOwnPropertySymbols) {
                let symbols = Object.getOwnPropertySymbols(parentInner);
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < symbols.length; i++) {
                    // Don't need to worry about cloning a symbol because it is a primitive,
                    // like a number or string.
                    let symbol = symbols[i];
                    let descriptor = Object.getOwnPropertyDescriptor(parentInner, symbol);
                    if (descriptor && !descriptor.enumerable && !includeNonEnumerable) {
                        continue;
                    }
                    child[symbol] = _clone(parentInner[symbol], depth - 1);
                    if (!descriptor.enumerable) {
                        Object.defineProperty(child, symbol, {
                            enumerable: false,
                        });
                    }
                }
            }
            if (includeNonEnumerable) {
                let allPropertyNames = Object.getOwnPropertyNames(parentInner);
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < allPropertyNames.length; i++) {
                    let propertyName = allPropertyNames[i];
                    let descriptor = Object.getOwnPropertyDescriptor(parentInner, propertyName);
                    if (descriptor && descriptor.enumerable) {
                        continue;
                    }
                    child[propertyName] = _clone(parentInner[propertyName], depth - 1);
                    Object.defineProperty(child, propertyName, {
                        enumerable: false,
                    });
                }
            }
            return child;
        }
        return _clone(parent, depth);
    }
    /**
     * Simple flat clone using prototype, accepts only objects, usefull for property
     * override on FLAT configuration object (no nested props).
     *
     * USE WITH CAUTION! This may not behave as you wish if you do not know how this
     * works.
     */
    clone.clonePrototype = function clonePrototype(parent) {
        if (parent === null) {
            return null;
        }
        let c = function () { };
        c.prototype = parent;
        return new c();
    };
    // private utility functions
    function __objToStr(o) {
        return Object.prototype.toString.call(o);
    }
    clone.__objToStr = __objToStr;
    function __isDate(o) {
        return typeof o === 'object' && __objToStr(o) === '[object Date]';
    }
    clone.__isDate = __isDate;
    function __isArray(o) {
        return typeof o === 'object' && __objToStr(o) === '[object Array]';
    }
    clone.__isArray = __isArray;
    function __isRegExp(o) {
        return typeof o === 'object' && __objToStr(o) === '[object RegExp]';
    }
    clone.__isRegExp = __isRegExp;
    function __getRegExpFlags(re) {
        let flags = '';
        if (re.global) {
            flags += 'g';
        }
        if (re.ignoreCase) {
            flags += 'i';
        }
        if (re.multiline) {
            flags += 'm';
        }
        return flags;
    }
    clone.__getRegExpFlags = __getRegExpFlags;
    return clone;
})();
// if (typeof module === 'object' && module.exports) {
//   module.exports = clone;
// }
