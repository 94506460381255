var PiBaseStatistic_1;
import { __decorate } from "tslib";
import { VueBase } from 'bc$/bases/vue.base';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './pi-base-statistic.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-statistic.component.scss';
import { hODeepObjectMerge } from 'bc$/utils';
let PiBaseStatistic = PiBaseStatistic_1 = class PiBaseStatistic extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseStatistic';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseStatistic_1?.NAME,
        author: 'hex',
        title: '统计组件',
        version: require('bc$/../package.json').version,
        updateDate: '2020-06-23',
        description: '',
    };
    //#endregion
    //#region props
    option;
    //#endregion
    //#region data
    defaultOption = {
        icon: {
            style: {
                color: 'rgba(255, 255, 255, 0.9)',
                fontSize: '60px',
            },
        },
        title: {
            style: {
                fontSize: '18px',
                color: '#fff',
                paddingLeft: '12px',
            },
        },
        content: {
            style: {
                fontSize: '30px',
                color: '#fff',
                paddingLeft: '12px',
            },
        },
        style: {
            paddingLeft: '50px',
            background: '',
        },
    };
    title = '';
    content = '';
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseStatistic_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return PiBaseStatistic_1.ABOUT.title;
    }
    get mergedOption() {
        const option = Object.assign({}, this.option);
        if (typeof option.icon == 'string') {
            option.icon = {
                class: option.icon,
            };
        }
        if (option.title) {
            if (typeof option.title == 'string') {
                this.title = option.title;
                option.title = {};
            }
            else if (Object.prototype.toString.call(option.title) == '[object Object]') {
                this.title = option.title.value || '';
            }
        }
        if (option.content) {
            if (typeof option.content == 'string') {
                this.content = option.content;
                option.content = {};
            }
            else if (Object.prototype.toString.call(option.content) == '[object Object]') {
                this.content =
                    option.content.value || '';
            }
        }
        if (!option.style || !option.style.background) {
            option.style = option.style || {};
            let backgroundItemList = [];
            if (option.backgroundImage) {
                backgroundItemList.push(`url(${option.backgroundImage}) center bottom / contain no-repeat`);
            }
            if (option.backgroundColor) {
                backgroundItemList.push(option.backgroundColor);
            }
            option.style.background = backgroundItemList.join(',');
        }
        return hODeepObjectMerge({}, this.defaultOption, option);
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    getRootStyle() {
        return this.mergedOption.style;
    }
    getIconClass() {
        return this.mergedOption.icon.class;
    }
    getIconStyle() {
        return this.mergedOption.icon.style;
    }
    getTitleStyle() {
        return this.mergedOption.title.style;
    }
    getContentStyle() {
        return this.mergedOption.content.style;
    }
};
__decorate([
    Prop()
], PiBaseStatistic.prototype, "option", void 0);
PiBaseStatistic = PiBaseStatistic_1 = __decorate([
    Component({
        template,
    })
], PiBaseStatistic);
export default PiBaseStatistic;
