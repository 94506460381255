var PiBaseUeditor_1;
import { __decorate } from "tslib";
import { VueBase } from '../../bases/vue.base';
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator';
import { BcVersion } from 'bc$/bases/config.base';
// tslint:disable-next-line: no-import-side-effect
import 'bc$/typings/ueditor/ueditor';
import template from './pi-base-ueditor.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-ueditor.component.scss';
// tslint:disable-next-line: no-import-side-effect
import { hODeepObjectMerge, hErrorShow } from 'bc$/utils';
let PiBaseUeditor = PiBaseUeditor_1 = class PiBaseUeditor extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseUeditor';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseUeditor_1?.NAME,
        author: 'caiqw',
        title: '富文本框',
        version: BcVersion,
        updateDate: '2019-07-06',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 双向数据绑定的值
     */
    value;
    /**
     * 富文本框配置项
     */
    ueditorConfig;
    /**
     * 只读模式
     */
    readonly;
    //#endregion
    //#region data
    /**
     * 当前实例加载状态
     */
    status = 0;
    ueditorConfigDefault = {
        UEDITOR_HOME_URL: process.env.BASE_URL
            ? process.env.BASE_URL + 'ueditor/'
            : 'assets/ueditor/',
    };
    randomId = 'editor_' + Math.random() * 1e17;
    instance = null;
    // scriptTagStatus -> 0:代码未加载，1:两个代码依赖加载了一个，2:两个代码依赖都已经加载完成
    scriptTagStatus = 0;
    //#endregion
    //#region computed
    get about() {
        return PiBaseUeditor_1.ABOUT;
    }
    get titleAll() {
        return '';
    }
    //#endregion
    'watch.ueditorConfig'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (oldVal !== undefined) {
            this.instance = null;
            window.UE = null;
            this.$nextTick(() => this.initEditor());
        }
    }
    'watch.value'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        // 0: 尚未初始化 1: 开始初始化但尚未ready 2 初始化完成并已ready
        switch (this.status) {
            case 0:
                this.status = 1;
                // 判断执行环境是服务端还是客户端，这里的 process.client 是 Nuxt 添加的环境变量
                (typeof process !== 'undefined' || typeof window !== 'undefined') &&
                    this.$refs[this.randomId]
                    ? this.initEditor()
                    : this.$nextTick(() => this.initEditor());
                break;
            case 1:
                // this.value = val;
                break;
            case 2:
                this.setContent(newVal);
                break;
            default:
                break;
        }
    }
    'watch.readonly'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            this.instance && this.instance.setDisabled();
        }
        else {
            this.instance && this.instance.setEnabled();
        }
    }
    //#region watch
    //#endregion
    //#region methods
    // 检测依赖,确保 UEditor 资源文件已加载完毕
    checkDependencies() {
        return new Promise((resolve) => {
            // 判断ueditor.config.js和ueditor.all.js是否均已加载(仅加载完ueditor.config.js时UE对象和UEDITOR_CONFIG对象存在,仅加载完ueditor.all.js时UEDITOR_CONFIG对象存在,但为空对象)
            let scriptsLoaded = !!window.UE?.getEditor;
            if (scriptsLoaded) {
                resolve(void 0);
            }
            else {
                // 如果在其他地方只引用ueditor.all.min.js，在加载ueditor.config.js之后仍需要重新加载ueditor.all.min.js，所以必须确保ueditor.config.js已加载
                this.loadConfig()
                    .then(() => this.loadCore())
                    .then(() => {
                    resolve(void 0);
                })
                    .catch((err) => {
                    console.log(err);
                });
            }
        });
    }
    loadConfig() {
        return new Promise((resolve, reject) => {
            if (window.UE) {
                resolve(void 0);
                return;
            }
            let configScript = document.createElement('script');
            configScript.type = 'text/javascript';
            const ueditorConfig = hODeepObjectMerge(this.ueditorConfigDefault, this.ueditorConfig);
            configScript.src = ueditorConfig.UEDITOR_HOME_URL + 'ueditor.config.js';
            document.getElementsByTagName('head')[0].appendChild(configScript);
            configScript.onload = function () {
                if (window.UE) {
                    resolve(void 0);
                }
                else {
                    console.error('加载ueditor.config.js失败,请检查您的配置地址UEDITOR_HOME_URL填写是否正确!\n', configScript.src);
                }
            };
        });
    }
    loadCore() {
        return new Promise((resolve) => {
            if (window.UE?.getEditor) {
                resolve(void 0);
                return;
            }
            let coreScript = document.createElement('script');
            coreScript.type = 'text/javascript';
            const ueditorConfig = hODeepObjectMerge(this.ueditorConfigDefault, this.ueditorConfig);
            coreScript.src = ueditorConfig.UEDITOR_HOME_URL + 'ueditor.all.min.js';
            document.getElementsByTagName('head')[0].appendChild(coreScript);
            coreScript.onload = function () {
                if (window.UE?.getEditor) {
                    resolve(void 0);
                }
                else {
                    console.error('加载ueditor.all.min.js失败,请检查您的配置地址UEDITOR_HOME_URL填写是否正确!\n', coreScript.src);
                }
            };
        });
    }
    // 实现双向绑定
    contentChangeHandler() {
        this.$emit('input', this.instance.getContent?.());
    }
    // 获取最新的content值
    getCurrentContent() {
        return this.instance.getContent?.();
    }
    // 基于 UEditor 的 contentChange 事件
    normalChangeListener() {
        this.instance.addListener('contentChange', this.contentChangeHandler);
    }
    // 实例化编辑器
    initEditor() {
        return this.checkDependencies()
            .then(() => {
            // tslint:disable-next-line: no-floating-promises
            this.$nextTick().then(() => {
                this.instance = window.UE?.getEditor?.(this.randomId, hODeepObjectMerge(this.ueditorConfigDefault, this.ueditorConfig)); // 初始化UE
                // 绑定事件，当 UEditor 初始化完成后，将编辑器实例通过自定义的 ready 事件交出去
                this.instance.addListener('ready', () => {
                    if (this.status === 2) {
                        // 使用 keep-alive 组件会出现这种情况
                        this.setContent(this.value);
                    }
                    else {
                        this.status = 2;
                        this.$emit('ready', this.instance);
                        this.setContent(this.value);
                    }
                    this.normalChangeListener();
                });
            });
        })
            .catch((err) => hErrorShow({
            err,
            showInPage: true,
            context: this,
        }));
    }
    // 设置内容
    setContent(value) {
        value === this.instance.getContent?.() ||
            this.instance.setContent?.(value);
    }
    //#endregion
    //#region hooks
    created() { }
    beforeDestroy() {
        // 组件销毁的时候，要销毁 UEditor 实例
        if (this.instance !== null && this.instance.destroy) {
            this.instance.destroy();
        }
    }
};
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseUeditor.prototype, "value", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseUeditor.prototype, "ueditorConfig", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseUeditor.prototype, "readonly", void 0);
__decorate([
    Watch('ueditorConfig', { immediate: true, deep: true })
], PiBaseUeditor.prototype, "watch.ueditorConfig", null);
__decorate([
    Watch('value', { immediate: true, deep: true })
], PiBaseUeditor.prototype, "watch.value", null);
__decorate([
    Watch('readonly', { immediate: true, deep: true })
], PiBaseUeditor.prototype, "watch.readonly", null);
PiBaseUeditor = PiBaseUeditor_1 = __decorate([
    Component({
        template,
    })
], PiBaseUeditor);
export default PiBaseUeditor;
