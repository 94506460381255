var PiBaseVideo_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import Hls from 'hls.js';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import PiBaseVideoResizeMixin from './mixins/pi-base-video-resize.mixin';
import template from './pi-base-video.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-video.component.scss';
let PiBaseVideo = PiBaseVideo_1 = class PiBaseVideo extends Mixins(VueBase, PiBaseVideoResizeMixin, WindowEventMixin) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseVideo';
    /**
     * 关于信息
     */
    static ABOUT = Object.freeze({
        name: PiBaseVideo_1?.NAME,
        author: 'yangd',
        title: '视频播放组件',
        version: BcVersion,
        updateDate: '2020-12-14',
        description: '',
    });
    //#endregion
    //#region props
    /**
     * 静音
     */
    muted;
    /**
     * 工具栏
     */
    controls;
    /**
     * 视频url
     */
    src;
    /**
     * 是否启用视频播放按钮
     */
    hasPlayBtn;
    //#endregion
    //#region data
    /**
     * HLS实例
     */
    hls = null;
    /**
     * 播放中
     */
    isPlaying = false;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseVideo_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    /**
     * 开始时间
     * @param newVal
     * @param oldVal
     */
    'watch.src'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.$nextTick(() => {
            if (!newVal) {
                this.hls?.destroy();
                this.hls = null;
                const video = this.$el.querySelector('video');
                video.src = '';
                return;
            }
            this.clickPlay();
        });
    }
    //#endregion
    //#region methods
    /**
     * 点击播放
     */
    clickPlay() {
        const video = this.$el.querySelector('video');
        if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = this.src;
            video.paused && video.play();
            return;
        }
        let { hls } = this;
        if (hls) {
            hls.destroy();
        }
        hls = this.hls = new Hls({
            /**
             * (默认值:false)
             * 如果设置为true，自适应算法的限制级别可用的自动质量的HTML视频元素的尺寸(宽度和高度)
             * 如果设置为false，级别将不受限制。所有可用的水平可以在自动质量模式中使用，只考虑带宽。
             */
            capLevelToPlayerSize: true,
            /**
             * (默认值:false)
             * 设置config.debug = true;将在JS控制台打开调试日志。
             * 一个日志对象也可以提供自定义日志:config.debug = customLogger;
             */
            // debug: false,
            /**
             * (默认值:true)
             * 如果设置为true，在触发Hls.Events后，会自动加载开始级播放列表和第一个片段。MANIFEST_PARSED事件
             * 如果设置为false，将需要一个显式的API调用(hls.startLoad(startPosition=-1))来开始质量级别/片段加载。
             */
            // autoStartLoad: true,
            /**
             * (默认为1)
             * 如果设置为-1，播放将从初始时间=0开始为VoD，并根据Live的liveSyncDuration/liveSyncDurationCount配置参数
             * 否则，回放将从预设值开始。(除非在autoStartLoad=false模式中另有说明:在这种情况下，可以使用hls.startLoad(startPosition)重写startPosition)。
             */
            // startPosition: 1,
            /**
             * (默认值:未定义)
             * 如果音频编解码器没有信号的变体清单，或如果只有一个流清单提供，hls.js试图通过解析音频采样率ADTS头猜测音频编解码器。
             * 如果采样率小于或等于22050 Hz, hls.js假设为HE-AAC，否则假设为AAC-LC。
             * 这可能导致错误的猜测，导致音频解码错误，结束在媒体错误。
             * 可以通过配置该值提示默认audiocodec到hls.js:
             * mp4a.40.2 (AAC-LC)或
             * mp4a.40.5 (HE-AAC)或
             * 未定义(基于抽样率的猜测)
             */
            // defaultAudioCodec: undefined,
            /**
             * (默认值:1)
             * 启动直播流回放所需的段数。
             */
            // initialLiveManifestSize: 1,
            /**
             * (默认为30秒)
             * 最大缓冲区长度(秒)。如果缓冲区长度小于这个值，就会加载一个新的片段。
             * 这是hls.js试图达到的保证缓冲区长度，而不管maxBufferSize是多少。
             */
            // maxBufferLength: 30,
            /**
             * 600s(默认)
             * 最大缓冲区长度(秒)。js永远不会超过这个值，即使还没有达到maxBufferSize。
             * hls.js试图缓冲到最大字节数(默认情况下是60 MB)，而不是缓冲到最大nb秒。
             * 这是模仿浏览器行为(当浏览器检测到视频缓冲区大小达到字节限制时，缓冲区驱逐算法开始)
             * maxBufferLength是hls.js试图达到的保证缓冲区长度的最小值，即使该值超过60mb的内存字节数。
             * maxMaxBufferLength作为一个上限值，如果比特率真的很低，您可能需要超过一个小时的缓冲区来填满60mb。
             */
            // maxMaxBufferLength: 600,
            /**
             * (默认为60mb)
             * “最小”最大缓冲区大小(字节)。如果前面的缓冲区大小大于这个值，则不会加载片段
             */
            // maxBufferSize: 60,
            /**
             * (默认值:0.5秒)
             * 当搜索下一个要加载的片段时，hls.js可以处理的“最大”片段间缓冲孔容忍度。在质量水平之间切换时，
             * 片段可能没有完全对齐。
             * 这可能会导致媒体缓冲区出现小的重叠或漏洞。这种容忍度有助于应对这种情况。
             */
            // maxBufferHole: 0.5,
            /**
             * (默认值:2s)
             * 如果播放被暂停，并且预先有一个缓冲范围，小于当前媒体位置的maxSeekHole秒，
             * hls.js将跳过此缓冲孔，以达到以下缓冲范围的开始。
             * maxSeekHole允许配置这个跳控阈值。
             */
            // maxSeekHole: 2,
            /**
             * (默认值:4s)
             *
             * ABR算法总是试图选择一个质量水平，应该避免碰头。如果没有质量水平与此标准可以
             * 被发现(比如，缓冲区长度是1，但是获取一个最低质量的片段预计需要花费大约2s…
             * 我们可以预测约1的回绝…)然后ABR算法将试图找到一个水平，应保证小于
             * 缓冲的maxStarvationDelay。
             */
            // maxStarvationDelay: 4,
            /**
             * 0.2(默认)
             * 这个容忍因子在片段查找期间使用。
             * 而不是检查是否缓冲。end位于[start, end]范围内，frag查找将通过检查[start- maxfraglookuptolerance, end- maxfraglookuptolerance]范围来完成。
             * 这种容忍因素是用来应付以下情况:
             * 缓冲。结束= 9.991
             * frag[0]: [0,10]
             * frag[1]: [10,20]
             * 缓冲。end在frag[0]范围内，但由于接近frag[1]，故应选择frag[1]
             * 如果maxFragLookUpTolerance = 0.2，此查找将被调整为
             * frag[0]: [-0.2,9.8]
             * frag[1]: [9.8,19.8]
             * 这一次，缓冲。end在frag[1]范围内，如预期，frag[1]将是下一个加载的片段
             */
            // maxLoadingDelay: 0.2,
            /**
             * (默认值:0.5秒)
             * 媒体元素预期播放，如果currentTime没有移动超过lowBufferWatchdogPeriod，如果有小于maxBufferHole秒缓冲前端，
             * js将尝试轻推播放头以恢复播放
             */
            // lowBufferWatchdogPeriod: 0.5,
            /**
             * (默认值是3s)
             * 如果媒体元素被期望播放，如果currentTime没有移动超过highBufferWatchdogPeriod，如果有超过maxBufferHole秒缓冲前端，
             * js将尝试轻推播放头以恢复播放
             */
            // highBufferWatchdogPeriod: 3,
            /**
             * (默认值:0.1秒)
             * 如果播放在第一次播放头移动后继续停止，currentTime将会随着nudgeOffset移动更多以尝试恢复播放。
             * 媒体。currentTime += (nb nudge retry -1)*nudgeOffset
             */
            // nudgeOffset: 0.1,
            /**
             * (默认值是3s)
             * 如果播放在第一次播放头移动后继续停止，currentTime将会随着nudgeOffset移动更多以尝试恢复播放。
             * 媒体。currentTime += (nb nudge retry -1)*nudgeOffset
             */
            // nudgeMaxRetry: 3,
            /**
             * (默认4s)
             *
             * 在自动启动级别选择中使用的最大视频加载延迟:在这种模式下，ABR控制器将确保视频加载时间(即
             * 在最低质量级别上获取第一个片段的时间+在适当质量级别上获取片段的时间更少
             * 大于maxLoadingDelay)
             */
            // maxFragLookUpTolerance: 4,
            /**
             * (默认值:3)
             * 实时时延的边缘，用多个EXT-X-TARGETDURATION表示。如果设置为3，回放将从片段N-3开始，N是现场播放列表的最后一个片段。
             * 减少此值可能会导致播放暂停。
             */
            // liveSyncDurationCount: 3,
            /**
             * (默认值:无穷)
             * 从live边缘允许的最大延迟，用多个EXT-X-TARGETDURATION表示。
             * 如果设置为10，当下一个要加载的片段超过N-10时，播放器会返回到liveSyncDurationCount, N是现场播放列表的最后一个片段。
             * 如果设置，这个值必须严格优于liveSyncDurationCount。与liveSyncDurationCount太接近的值可能会导致播放暂停。
             */
            // liveMaxLatencyDurationCount: Infinity,
            /**
             * (默认值:未定义)
             * 替代参数的liveSyncDurationCount，表示秒数和段数。
             * 如果在配置对象中定义，liveSyncDuration将优先于缺省的liveSyncDurationCount。
             * 你不能同时在配置对象中定义这个参数和liveSyncDurationCount或liveMaxLatencyDurationCount。
             * A值过低(低于~3段持续时间)可能会导致播放暂停。
             */
            // liveSyncDuration: undefined,
            /**
             * (默认值:未定义)
             * 可选参数liveMaxLatencyDurationCount，表示秒数和段数。
             * 如果在配置对象中定义，liveMaxLatencyDuration将优先于默认的liveMaxLatencyDurationCount。
             * 如果设置，这个值必须严格优于也必须定义的liveSyncDuration值。
             * 你不能同时在配置对象中定义这个参数和liveSyncDurationCount或liveMaxLatencyDurationCount。
             * 距离liveSyncDuration太近的值可能会导致回放中断。
             */
            // liveMaxLatencyDuration: undefined,
            /**
             * (默认值:false)
             * 覆盖当前媒体源持续时间到无穷大，进行现场直播。有用的，如果你是建立一个播放器依赖
             * 在现代浏览器的本地UI功能。如果你想在iOS Safari, Safari这样的环境中有一个本地动态UI，
             * Android谷歌Chrome等设置该值为真。
             */
            // liveDurationInfinity: false,
            /**
             * (默认值:无穷)
             * 设置缓冲区的最大长度，以秒为单位，在实时流中保持。任何视频
             * 缓冲超过这个时间将被驱逐。无穷大意味着对回缓冲长度没有限制;
             * 0保持最小数量。最小的数量等于一个段的目标持续时间
             * 确保当前播放不被中断。
             */
            // liveBackBufferLength: Infinity,
            /**
             * (默认值:true)
             * 启用WebWorker(如果在浏览器上可用)进行TS解调/MP4重调，以提高性能和避免延迟/帧下降。
             */
            // enableWorker: true,
            /**
             * (默认值:true)
             * 启用使用JavaScript版本的AES解密回退WebCrypto API。
             */
            // enableSoftwareAES: true,
            /**
             * (默认值:未定义)
             * 当设置时，使用这个级别作为默认的hls.startLevel。请记住，API的startLevel设置优先于
             * 配置。startLevel配置参数。
             */
            // startLevel: undefined,
            /**
             * (关卡和清单默认为10000ms)
             * URL加载器超时。如果加载时间超过此超时，将触发一个超时回调。不做进一步的操作:加载操作不会被取消/中止。
             * 它是由应用程序来捕捉这个事件并根据需要处理它。
             */
            // manifestLoadingTimeOut: 1e4,
            /**
             * (默认值:3)
             * 负载重试的最大次数。
             */
            manifestLoadingMaxRetry: Infinity,
            /**
             * (默认:1000毫秒)
             * XMLHttpRequest错误和第一次加载重试之间的初始延迟(ms)。
             * 任何I/O错误将触发重试每500ms,1s,2s,4s,8s，…
             * 上限为fragLoadingMaxRetryTimeout / manifestLoadingMaxRetryTimeout / levelLoadingMaxRetryTimeout值(指数后退)。
             * Prefetch开始片段，虽然媒体没有附加。
             */
            // manifestLoadingRetryDelay: 1e3,
            /**
             * (默认:64000 ms)
             * 最大frag/manifest/key重试超时(毫秒)，以防I/O错误被满足。
             */
            // manifestLoadingMaxRetryTimeout: 6.4e4,
            /**
             * (片段默认为60000ms)
             * URL加载器超时。如果加载时间超过此超时，将触发一个超时回调。不做进一步的操作:加载操作不会被取消/中止。
             * 它是由应用程序来捕捉这个事件并根据需要处理它。
             */
            // levelLoadingTimeOut: 6e4,
            /**
             * (默认值:3)
             * 负载重试的最大次数。
             */
            levelLoadingMaxRetry: Infinity,
            /**
             * (默认:1000ms)
             * XMLHttpRequest错误和第一次加载重试之间的初始延迟(ms)。
             * 任何I/O错误将触发重试每500ms,1s,2s,4s,8s，…
             * 上限为fragLoadingMaxRetryTimeout / manifestLoadingMaxRetryTimeout / levelLoadingMaxRetryTimeout值(指数后退)。
             * Prefetch开始片段，虽然媒体没有附加。
             */
            // levelLoadingRetryDelay: 1e3,
            /**
             * (默认:64000ms)
             * 最大frag/manifest/key重试超时(毫秒)，以防I/O错误被满足。
             */
            // levelLoadingMaxRetryTimeout: 6.4e4,
            /**
             * (默认:60000ms的片段)
             * URL加载器超时。如果加载时间超过此超时，将触发一个超时回调。不做进一步的操作:加载操作不会被取消/中止。
             * 它是由应用程序来捕捉这个事件并根据需要处理它。
             */
            // fragLoadingTimeOut: 6e4,
            /**
             * (默认值:3)
             * 负载重试的最大次数。
             */
            fragLoadingMaxRetry: Infinity,
            /**
             * (默认:1000毫秒)
             * XMLHttpRequest错误和第一次加载重试之间的初始延迟(ms)。
             * 任何I/O错误将触发重试每500ms,1s,2s,4s,8s，…
             * 上限为fragLoadingMaxRetryTimeout / manifestLoadingMaxRetryTimeout / levelLoadingMaxRetryTimeout值(指数后退)。
             * Prefetch开始片段，虽然媒体没有附加。
             */
            // fragLoadingRetryDelay: 1e3,
            /**
             * (默认:64000 ms)
             * 最大frag/manifest/key重试超时(毫秒)，以防I/O错误被满足。
             */
            // fragLoadingMaxRetryDelay: 6e4,
            /**
             * (默认值:false)
             * 开始预取开始片段，尽管媒体尚未附加。追加重试的最大次数。
             */
            // startFragPrefetch: false,
            /**
             * (默认值:3)
             * sourceBuffer.appendBuffer()的最大数量错误重试。这种错误可能发生在循环与UHD流，当内部缓冲区是满的。(将触发配额超过错误)
             * 在这种情况下，我们需要等待浏览器驱逐一些数据之前，能够添加缓冲区正确。
             */
            // appendErrorMaxRetry: 3,
            /**
             * (默认:标准的基于xmlhttprequest的URL加载器)
             * 使用自定义的URL加载程序覆盖标准的URL加载程序。可能对P2P或存根(测试)有用。
             * 使用这个，如果你想要覆盖片段和播放列表加载程序。
             * 注意:如果使用了fLoader或pLoader，它们会覆盖加载程序!
             */
            // loader: Hls.Loader,
            /**
             * (默认值:未定义)
             * 这使得操作片段加载器成为可能。
             * 注意:这将覆盖默认加载程序，以及你自己的加载程序功能。
             */
            // fLoader: undefined,
            /**
             * (默认值:未定义)
             * 这允许操作播放列表加载程序。
             * 注意:这将覆盖默认加载程序，以及你自己的加载程序功能。
             */
            // pLoader: undefined,
            /**
             * (默认值:未定义)
             * 针对默认的基于XHR的加载器的XMLHttpRequest定制回调。
             * 参数应该是一个有两个参数的函数(xhr: XMLHttpRequest, url: string)。
             * 如果指定了xhrSetup，默认加载器将在调用xhr.send()之前调用它。这允许用户轻松地修改/设置XHR。
             */
            // xhrSetup: undefined,
            /**
             * (默认值:未定义)
             * 自定义回调Fetch基于加载器。
             * 参数应该是一个有两个参数的函数(上下文和请求Init参数)。
             * 如果指定了fetchSetup，并且使用了Fetch loader, fetchSetup将被触发来实例化请求对象。这允许用户轻松地调整抓取加载程序。
             */
            // fetchSetup: undefined,
            /**
             * (默认:内部ABR控制器)
             * 自定义自适应比特率流控制器。
             * 参数应该是一个类，提供2个getter方法，2个setter方法和一个destroy()方法:
             * 获取/设置nextAutoLevel:返回下一个自动质量等级/强制返回下一个应该返回的自动质量等级(当前用于紧急关闭开关)
             * 获取/设置autoLevelCapping: ABR控制器可以使用的最大液位值
             * destroy():应该清除所有使用的资源
             */
            // abrController: Hls.AbrController,
            /**
             * (默认:内部轨道时间轴控制器)
             * 自定义文本轨道同步控制器。
             * 参数应该是一个带有destroy()方法的类:
             * destroy():应该清除所有使用的资源
             */
            // timelineController: Hls.TimelineController,
            /**
             * (默认值:true)
             * 是否在HLS上启用WebVTT字幕
             */
            // enableWebVTT: true,
            /**
             * (默认值:true)
             * 是否启用CEA-708字幕
             */
            // enableCEA708Captions: true,
            /**
             * (默认值:English)
             * 为CEA-708字幕生成的文本音轨标签音轨1。这就是它在浏览器的本地字幕和字幕菜单中显示的方式。
             */
            // captionsTextTrack1Label: 'English',
            /**
             * (默认值:en)
             * RFC 3066语言代码为文本轨道生成的CEA-708字幕轨道1。
             */
            // captionsTextTrack1LanguagedCode: 'en',
            /**
             * (默认值:Spanish)
             * 为为CEA-708字幕生成的文本轨的标签轨2。这就是它在浏览器的本地字幕和字幕菜单中显示的方式。
             */
            // captionsTextTrack2Label: 'Spanish',
            /**
             * (默认值:es)
             * RFC 3066语言代码为文本轨道生成的CEA-708字幕轨道2。
             */
            // captionsTextTrack2LanguageCode: 'es',
            /**
             * (默认值:false)
             * 如果一个片段的视频音轨比它的音频音轨短> min(maxSeekHole, maxBufferHole)，延长最终视频帧的持续时间以匹配音频音轨的持续时间。
             * 这有助于回放在某些情况下继续，否则可能会卡住。
             */
            // stretchShortVideoTrack: false,
            /**
             * (默认值:1)
             * 浏览器对音频帧的计时非常严格。他们通常播放音频帧一个接一个，不管
             * fmp4中公布的时间戳。如果音频时间戳不一致(连续的音频帧太近或太远)
             * 从对方)，音频将很容易漂移。js是重新设置音频帧，使连续音频之间的距离
             * 帧保持不变。如果距离大于最大允许漂移，hls.js也可以
             * *删除下一个音频帧，如果距离太小(如果下一个音频帧的时间戳小于预期)
             * 时间戳-允许最大漂流者)
             * *插入无声帧，如果距离太大(下一个音频帧时间戳大于预期)
             * 时间戳+最大允许漂移)
             * 参数应该是一个整数，表示允许漂移的音频帧的最大数量。记住这一点
             * 音频帧是1024音频样本(如果使用AAC)，在44.1 kHz，它给1024/44100 = 23ms。
             */
            // maxAudioFramesDrift: 1,
            /**
             * (默认值:true)
             * 是否在不连续后的第一个AVC样本中强制设置关键帧。
             * 如果设置为true，在不连续后，没有任何关键帧的AVC样本将被删除，直到找到一个包含关键帧的样本。
             * 如果设置为false，将保留所有的AVC样品，这样可以避免流中出现孔洞。
             * 将此参数设置为false也会在切换电平或寻码时产生解码异常。
             */
            // forceKeyFrameOnDiscontinuity: true,
            /**
               * (默认值:5.0)
               * 快速比特率指数移动平均半衰期，用于计算实时流的平均比特率。
               * 估计的一半是基于样本历史的最后一秒钟。每个样品的重量由碎片加载时间。
               参数应为大于0的浮点数
               */
            // abrEwmaFastLive: 5.0,
            /**
             * (默认值:9.0)
             * 慢码率指数移动平均半衰期，用于计算实时流的平均码率。
             * 估计的一半是基于样本历史的最后一秒。每个样品的重量由碎片加载时间。
             * 参数应该是一个大于abrEwmaFastLive的浮点数
             */
            // abrEwmaSlowLive: 9.0,
            /**
             * (默认值:4.0)
             * 快速比特率指数移动平均半衰期，用于计算VoD流的平均比特率。
             * 估计的一半是基于样本历史的最后一秒。每个样品的重量由碎片加载时间。
             * 参数应为大于0的浮点数
             */
            // abrEwmaFastVod: 4.0,
            /**
             * (默认值:15.0)
             * 慢码率指数移动平均半衰期，用于计算VoD流的平均码率。
             * 估计的一半是基于最后一秒的样本历史。每个样品的重量由碎片加载时间。
             * 参数应该是一个大于abrEwmaFastVoD的浮点数
             */
            // abrEwmaSlowVod: 15.0,
            /**
             * (默认:500000)
             * 收集片段带宽样本前的默认带宽估计以比特/秒为单位。
             * 参数应该是一个浮点数
             */
            // abrEwmaDefaultEstimate: 5e5,
            /**
             * (默认值:0.8)
             * 比例因子将应用于测量的平均带宽，以决定我们是否可以保持当前或较低的质量水平。
             * 如果带宽宽度因子*带宽平均<电平。然后ABR可以切换到那个水平，提供它等于或小于当前水平。
             */
            // abrBandWidthFactor: 0.8,
            /**
             * (默认值:0.7)
             * 衡量平均带宽的比例因子，以决定我们是否可以切换到更高的质素水平。
             * 如果abrBandWidthUpFactor *带宽平均< level。比特率然后ABR可以切换到那个质量级别。
             */
            // abrBandWidthUpFactor: 0.7,
            /**
             * (默认值:false)
             * avg测量的比特率在ABR中使用的最大比特率，即，如果在一个给定级别的变体清单中显示的比特率是2Mb/s，而在这个级别上测量的平均比特率是2.5Mb/s，
             * 如果config值设置为true, ABR将使用2.5 Mb/s来达到这个质量级别。
             */
            // abrMaxWithRealBitrate: false,
            /**
             * (默认值:0)
             * 返回可被自动电平选择算法使用的上限/最小带宽值。
             * 当浏览器或浏览器标签不在焦点和带宽下降时非常有用
             */
            // minAutoBitrate: 0,
        });
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
            video.paused && video.play();
        });
        hls.loadSource(this.src);
    }
    //#endregion
    //#region hooks
    mounted() {
        const video = this.$el.querySelector('video');
        //播放
        const fnPlay = () => (this.isPlaying = true);
        video?.addEventListener('play', fnPlay);
        //暂停
        const fnPause = () => (this.isPlaying = false);
        video?.addEventListener('pause', fnPause);
        //组件销毁时取消事件绑定
        this.$once('hook:beforeDestroy', () => {
            video?.removeEventListener('play', fnPlay);
            video?.removeEventListener('pause', fnPause);
        });
        //video不允许右键菜单
        const fnContextMenu = (e) => e.preventDefault();
        video.addEventListener('contextmenu', fnContextMenu);
        this.$once('hook:beforeDestroy', () => video.removeEventListener('contextmenu', fnContextMenu));
    }
    beforeDestroy() {
        this.hls?.destroy();
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseVideo.prototype, "muted", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseVideo.prototype, "controls", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBaseVideo.prototype, "src", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseVideo.prototype, "hasPlayBtn", void 0);
__decorate([
    Watch('src', { immediate: true })
], PiBaseVideo.prototype, "watch.src", null);
PiBaseVideo = PiBaseVideo_1 = __decorate([
    Component({
        template,
    })
], PiBaseVideo);
export default PiBaseVideo;
