export const getInputAndTagName = (el, binding, vnode) => {
    let input = el;
    const tagName = vnode.componentOptions && vnode.componentOptions.tag;
    if (input.tagName.toLowerCase() !== 'input') {
        if (tagName !== 'Input') {
            input = input.getElementsByTagName('input')[0];
        }
        if (!input) {
            console.error('指令v-input-filter配置错误！', binding);
        }
    }
    return {
        input,
        tagName,
    };
};
