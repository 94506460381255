import { BigNumber } from 'bignumber.js';
/**
 * 精确加法
 *
 * @param v1
 * @param v2
 * @return
 */
function hBigNumAdd(v1, v2) {
    let a = new BigNumber(v1); // "11"
    let b = new BigNumber(v2); // "1295.25"
    let c = a.plus(b);
    return c.toNumber();
}
/**
 * 精确减法
 *
 * @param v1
 * @param v2
 * @return
 */
function hBigNumSubtract(v1, v2) {
    let x = new BigNumber(v1);
    return x.minus(v2).toNumber();
}
/**
 * 精确乘法
 *
 * @param v1
 * @param v2
 * @return
 */
function hBigNumMultiply(v1, v2) {
    let a = new BigNumber(v1);
    let b = new BigNumber(v2);
    return a.multipliedBy(b).toNumber();
}
/**
 * 精确除法
 * @param v1
 * @param v2
 * @return
 */
function hBigNumDivide(v1, v2) {
    let a = new BigNumber(v1);
    let b = new BigNumber(v2);
    return a.dividedBy(b).toNumber();
}
export { hBigNumAdd, hBigNumSubtract, hBigNumMultiply, hBigNumDivide };
