var PiBaseHeaderToolbar_1;
import { __decorate } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { DropdownMenu, Dropdown, DropdownItem, Icon } from 'iview';
import { ApiUrlSetting } from 'bc$/urls/api-url-setting';
import { hAjaxPost, UrlType, PiStatusEnum, PiBaseStoreUserModule, hTokenRemove, hVuexClearStore, hErrorShow, hLocalPrivate, } from 'bc$/main';
import template from './pi-base-header-toolbar.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-header-toolbar.component.scss';
let PiBaseHeaderToolbar = PiBaseHeaderToolbar_1 = class PiBaseHeaderToolbar extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseHeaderToolbar';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseHeaderToolbar_1?.NAME,
        author: 'wuzp',
        title: '头部右边工具条',
        version: BcVersion,
        updateDate: '2019-07-16',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 下拉菜单开启 transfer 时，给浮层添加额外的 class 名称
     */
    transferClassName;
    /**
     * 下拉菜单可选清单
     */
    dropMenuList;
    //#endregion
    //#region data
    /**
     *本地时间
     */
    currentTime = '';
    //#region 函数类属性
    //#region computed
    get about() {
        return PiBaseHeaderToolbar_1.ABOUT;
    }
    get titleAll() {
        return PiBaseHeaderToolbar_1?.NAME;
    }
    get userName() {
        return PiBaseStoreUserModule.userName;
    }
    get fullName() {
        return PiBaseStoreUserModule.fullName || PiBaseStoreUserModule.userName;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     *点击下拉框
     * @memberof PiBaseHeaderToolbar
     */
    handleDropdown(name) {
        if (name === 'passwordModify') {
            this.showPasswordModify();
        }
        else if (name === 'loginOut') {
            this.loginOut();
        }
        this.$emit('on-handle-dropdown', name);
    }
    /**
     *修改密码
     * @memberof PiBaseHeaderToolbar
     */
    showPasswordModify() {
        this.$emit('showPasswordModify');
        //(this.$slots.passwordModify as any).showPasswordModify()
    }
    /**
     *退出登录
     * @memberof PiBaseHeaderToolbar
     */
    loginOut() {
        const logoutUrl = ApiUrlSetting.AccountInfo.Logout;
        if (!logoutUrl) {
            return;
        }
        let params = {
            userName: this.userName,
        };
        const reqUrl = this.convertUrl(logoutUrl, UrlType.Api);
        return this.resetAjax('loginOut', () => {
            const ajaxInfo = hAjaxPost(reqUrl, params);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    // 清空vuex数据和session storage数据
                    hTokenRemove();
                    hVuexClearStore();
                    hLocalPrivate.removeItem('isAutoLogin');
                    this.$emit('loginOutOk');
                }
                else {
                    this.$Message.error('登出失败');
                }
            })
                .catch((err) => hErrorShow({
                context: this,
                err,
                showInPage: true,
            }));
            return ajaxInfo;
        });
    }
    /**
     *获取时间并格式化
     */
    getTime() {
        let currentTime = new Date();
        currentTime = new Date(currentTime.setMinutes(currentTime.getMinutes() + 1));
        let year = currentTime.getFullYear();
        let month = (currentTime.getMonth() + 1).toString().padStart(2, '0');
        let day = currentTime.getDay().toString().padStart(2, '0');
        let hour = currentTime.getHours().toString().padStart(2, '0');
        let minute = currentTime.getMinutes().toString().padStart(2, '0');
        this.currentTime = `${year}年${month}月${day}日 ${hour}:${minute}`;
    }
    /**
     * 累加显示系统当前时间
     */
    setTime(iTimes = 1) {
        this.getTime();
        this.resetTimeOut('setTime', () => {
            this.getTime();
            this.setTime(++iTimes);
        }, 60000);
    }
    //#endregion
    //#region hooks
    mounted() {
        this.setTime();
    }
    created() {
        //设置数据源弹窗显示的可选项内容
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
    }
};
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseHeaderToolbar.prototype, "transferClassName", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [
            {
                name: 'passwordModify',
                text: '修改密码',
            },
            {
                name: 'loginOut',
                text: '退出',
            },
        ],
    })
], PiBaseHeaderToolbar.prototype, "dropMenuList", void 0);
PiBaseHeaderToolbar = PiBaseHeaderToolbar_1 = __decorate([
    Component({
        components: {
            Dropdown,
            DropdownMenu,
            DropdownItem,
            Icon,
        },
        template,
    })
], PiBaseHeaderToolbar);
export default PiBaseHeaderToolbar;
