var PiBaseIconSelect_1;
import { __decorate } from "tslib";
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { hODeepClone } from 'bc$/utils/object.helper';
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { Select, Icon, Input, Row, Col, Option, Button } from 'iview';
import PiBaseModal from '../pi-base-modal/pi-base-modal.component';
// tslint:disable-next-line: no-var-requires
export let IconListAll = require('bc$/assets/fonts/iconfont/icon-list.json');
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import template from './pi-base-icon-select.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-icon-select.component.scss';
let PiBaseIconSelect = PiBaseIconSelect_1 = class PiBaseIconSelect extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseIconSelect';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseIconSelect_1?.NAME,
        author: 'caiqw',
        title: '基础图标选择组件',
        version: BcVersion,
        updateDate: '2019-05-22',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 打开图标选择模态框
     */
    isModalMask;
    /**
     * 的icon class
     */
    iconClassName;
    /**
     * 模态框标题
     */
    title;
    /**
     * 模态框标题
     */
    width;
    /**
     * 是否隐藏模态框footer
     */
    footerHide;
    /**
     * 确认方法
     */
    onOkBtn;
    //#endregion
    //#region data
    /**
     * 是否关闭图标组件弹框
     */
    isShow = false;
    /**
     * 弹窗层级
     */
    zIndex = 1000;
    /**
     * 宽度
     */
    modalWidth = 0;
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    isNone = false;
    /**
     * 旋转样式
     */
    iconTransforms = [
        {
            text: '默认',
            className: 'pi-transform-default',
        },
        {
            text: '旋转90度',
            className: 'pi-transform-90',
        },
        {
            text: '旋转180度',
            className: 'pi-transform-180',
        },
        {
            text: '旋转270度',
            className: 'pi-transform-270',
        },
        {
            text: '水平翻转',
            className: 'pi-transform-flip-hor',
        },
        {
            text: '水平翻转旋转90度',
            className: 'pi-transform-flip-hor-90',
        },
        {
            text: '水平翻转旋转180度',
            className: 'pi-transform-flip-hor-180',
        },
        {
            text: '水平翻转旋转270度',
            className: 'pi-transform-flip-hor-270',
        },
    ];
    /**
     * icon图标列表
     */
    iconList = [];
    searchIcon = '';
    titleAll;
    /**
     * 图标选中的分类
     */
    iconClassifySlcted = '';
    /**
     * 选中的icon 的 class
     */
    iconSlcted = '';
    /**
     * 当前选中的旋转样式
     */
    iconTsformCur = 'pi-transform-default';
    /**
     * 当前选中的图标在该类下的索引
     */
    iconIndex = -1;
    /**
     * 选中的分类索引
     */
    classifyIndex = -1;
    /**
     * 第一次选中的图标在该类下的索引
     */
    fstIconIndex = -1;
    /**
     * 第一次选中的icon 的 class
     */
    fstIconSlcted = '';
    /**
     * 第一次选中的旋转样式
     */
    fstIconTsformCur = 'pi-transform-default';
    /**
     * 第一次选中的分类
     */
    fstIconClassifySlcted = '';
    //#endregion
    //#region computed
    get about() {
        return PiBaseIconSelect_1.ABOUT;
    }
    //#endregion
    //#region watch
    'watch.isModalMask'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.isShow = newVal;
        this.setIconList();
    }
    'watch.isShow'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.$destroy();
    }
    'watch.iconClassName'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.getSelectedIndex(newVal);
    }
    /**
     * 查询图标，并向父组件返回当前查询字段
     * @param val 新值
     * @param oVal 旧值
     */
    onSearchIconChange(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.judgeIconIsShow(newVal);
    }
    /**
     * 选择图标分类，并向父组件返回当前选中的分类
     * @param val 新值
     * @param oVal 旧值
     */
    onIconClassifySlctedChange(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (oldVal) {
            this.getSelectedIndexByClassify(newVal);
        }
    }
    //#endregion
    //#region methods
    /**
     * 获取分类
     * @param className 传进来的classname
     */
    getClassifyName(className) {
        let reg = new RegExp(` pi-type-([^ ]+)`);
        const arr = reg.exec(className);
        return (arr && arr[1]) || '';
    }
    /**
     * 获取旋转样式
     * @param className 传进来的classname
     */
    getTransformName(className) {
        let reg = new RegExp(` (pi-transform-[^ ]+)`);
        const arr = reg.exec(className);
        return (arr && arr[1]) || 'pi-transform-default';
    }
    /**
     * 切换分类时获取当前分类的索引
     * @param classifyId 分类的id
     */
    getSelectedIndexByClassify(classifyId) {
        this.classifyIndex = this.iconList.findIndex((p) => p.id === classifyId);
        if (this.fstIconClassifySlcted === classifyId) {
            this.iconIndex = this.fstIconIndex;
            this.iconSlcted = this.fstIconSlcted;
            this.iconTsformCur = this.fstIconTsformCur;
        }
        else {
            this.iconIndex = -1;
            this.iconSlcted = '';
            this.iconTsformCur = 'pi-transform-default';
        }
    }
    /**
     * 获取当前选中的索引值（iconIndex），当前选中图标的class（iconIndex）当前的旋转样式（iconTsformCur）
     * @param className 当前选中图标的class
     */
    getSelectedIndex(className) {
        // 当前选中icon分类
        const iconClassifySlcted = (this.iconClassifySlcted = this.getClassifyName(className)) ||
            (this.iconList && this.iconList[0].id) ||
            '';
        if (!iconClassifySlcted) {
            return;
        }
        // 当前选中旋转样式
        const iconTsformCur = (this.iconTsformCur = this.getTransformName(className));
        const iconObj = this.iconList.find((p) => p.id === iconClassifySlcted) ||
            {};
        let prefix = '';
        let iconList = [];
        if (Object.keys(iconObj).length) {
            prefix = iconObj.prefix;
            iconList = iconObj.list;
        }
        //当前选中的icon在该类中的索引
        const index = iconList.findIndex((p) => `${prefix}${p.name} ${iconTsformCur} pi-type-${iconClassifySlcted}` ===
            className);
        // if (!~index) {
        //   return;
        // }
        const classifyIndex = this.iconList.findIndex((p) => p.id === iconClassifySlcted);
        const curIcon = iconList[index];
        this.iconIndex = index;
        this.iconSlcted = (curIcon && curIcon.name) || '';
        this.classifyIndex = classifyIndex;
        this.fstIconClassifySlcted = iconClassifySlcted;
        this.iconClassifySlcted = iconClassifySlcted;
        this.fstIconIndex = this.iconIndex;
        this.fstIconSlcted = this.iconSlcted;
        this.fstIconTsformCur = this.iconTsformCur;
    }
    /**
     * 判断搜索关键词是否在icon的tag关键词中
     * @param tag icon原本的搜索关键词
     * @param key 用户的搜索关键词
     */
    getIconKey(tag, key) {
        if (key === '') {
            return true;
        }
        const keyList = key.split(' ');
        for (let i = 0, length = keyList.length; i < length; i++) {
            if (keyList[i] && tag.indexOf(keyList[i]) !== -1) {
                return true;
            }
        }
        return false;
    }
    /**
     * 判断当前图标是否应该显示与否
     * @param key 当前图标
     */
    judgeIconIsShow(key) {
        this.iconList.forEach((item) => {
            item.list.forEach((iconItem) => {
                iconItem.show = this.getIconKey(iconItem.tag, key);
            });
        });
    }
    /**
     * 将icon列表拷贝一份给 this.iconList，并添加show属性，判断是否显示
     */
    setIconList() {
        this.iconList = hODeepClone(IconListAll);
        this.iconList.forEach((item) => {
            item.list.forEach((iconItem) => {
                iconItem.show = true;
            });
        });
    }
    /**
     * 选择图标
     * @param item 当前选中的图标
     * @param index 当前图标索引
     */
    chooseIcon(item, index) {
        this.iconSlcted = item.name;
        this.iconIndex = index;
        if (this.iconSlcted === this.fstIconSlcted) {
            this.iconTsformCur = this.fstIconTsformCur;
        }
        else {
            this.iconTsformCur = 'pi-transform-default';
        }
    }
    /**
     * 选择备选图标
     * @param item  当前选中的备选图标
     */
    changeFormCur(item) {
        this.iconTsformCur = item.className;
    }
    /**
     * 取消按钮
     */
    onCancel() {
        this.isShow = false;
    }
    /**
     * 确认按钮
     */
    onOk() {
        this.isShow = false;
        let res = '';
        if (this.iconSlcted) {
            res = `${this.iconList[this.classifyIndex].prefix}${this.iconSlcted} ${this.iconTsformCur} pi-type-${this.iconClassifySlcted}`;
        }
        typeof this.onOkBtn === 'function' && this.onOkBtn(res);
    }
    onWindowResize() {
        this.modalWidth = hReactiveUtil.convertToReactivePx(this.width);
    }
    //#endregion
    //#region hooks
    created() {
        //监控弹窗状态,显示时置为上层
        this.zIndex = WidgetBase.iModalCount++;
        //获取宽度
        this.modalWidth = hReactiveUtil.convertToReactivePx(this.width);
    }
    /**
     * 销毁实例前
     */
    beforeDestroy() {
        WidgetBase.iModalCount--;
    }
};
__decorate([
    Prop({
        default: false,
    })
], PiBaseIconSelect.prototype, "isModalMask", void 0);
__decorate([
    Prop({
        default: '',
    })
], PiBaseIconSelect.prototype, "iconClassName", void 0);
__decorate([
    Prop({
        default: '选择图标',
    })
], PiBaseIconSelect.prototype, "title", void 0);
__decorate([
    Prop({
        default: 800,
    })
], PiBaseIconSelect.prototype, "width", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBaseIconSelect.prototype, "footerHide", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBaseIconSelect.prototype, "onOkBtn", void 0);
__decorate([
    Watch('isModalMask', { immediate: true, deep: true })
], PiBaseIconSelect.prototype, "watch.isModalMask", null);
__decorate([
    Watch('isShow', { immediate: true, deep: true })
], PiBaseIconSelect.prototype, "watch.isShow", null);
__decorate([
    Watch('iconClassName', { immediate: true, deep: true })
], PiBaseIconSelect.prototype, "watch.iconClassName", null);
__decorate([
    Watch('searchIcon')
], PiBaseIconSelect.prototype, "onSearchIconChange", null);
__decorate([
    Watch('iconClassifySlcted', { immediate: true, deep: true })
], PiBaseIconSelect.prototype, "onIconClassifySlctedChange", null);
PiBaseIconSelect = PiBaseIconSelect_1 = __decorate([
    Component({
        components: {
            Select,
            Input,
            Row,
            Col,
            Option,
            Button,
            Icon,
            PiBaseModal,
        },
        template,
        mixins: [WindowEventMixin],
    })
], PiBaseIconSelect);
export const PiBaseStaticIconSelect = {
    install: (Vue, options) => {
        function createDialogCmp(options) {
            const opt = {
                propsData: {
                    isModalMask: options.isModalMask || true,
                    iconClassName: options.iconClassName || '',
                    title: options.title || '选择图标',
                    width: options.width || 800,
                    footerHide: options.footerHide || true,
                    onOkBtn: options.onOkBtn || '',
                },
                destroyed: () => {
                    el && el.remove();
                },
            };
            const render = options.render;
            if (render && typeof render === 'function') {
                opt.render = function (createElement, context) {
                    const propsData = opt.propsData;
                    const optCreateEle = {
                        props: hODeepClone(propsData),
                    };
                    return createElement(PiBaseIconSelect, optCreateEle, [
                        render(createElement, context),
                    ]);
                };
            }
            const cmp = new PiBaseIconSelect(opt);
            const el = cmp.$mount().$el;
            const piParentNode = options.piParentNode || document.body;
            piParentNode.appendChild(el);
        }
        function show(options) {
            let opt;
            switch (typeof options) {
                case 'string':
                    opt = {};
                    break;
                case 'object':
                    opt = options;
                    break;
                default:
                    console.error('参数错误！');
                    return;
            }
            return createDialogCmp(opt);
        }
        const property = (options && options.property) || '$piIconSelect';
        Vue.prototype[property] = show;
    },
};
import { WidgetBase } from 'bc$/bases/widget.base';
