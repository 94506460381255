var PiBaseCurveBar_1;
import { __decorate } from "tslib";
import { VueBase } from 'bc$/main';
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BcVersion } from 'bc$/bases/config.base';
import { WidgetInstanceEntity } from 'bc$/entities/layout';
import template from './pi-base-curve-bar.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-curve-bar.component.scss';
import { Spin, Dropdown } from 'iview';
import VEcharts from 'vue-echarts-v3';
import { fnEChartsOptionsFactory } from '../../contents/echarts/pi-options-factory';
import { registerEchartsTheme } from '../../contents/echarts/pi-themes';
import { hODeepObjectMerge, hAjaxGet, hODeepClone, hErrorShow, hReactiveUtil, } from 'bc$/utils';
var Type;
(function (Type) {
    Type["LINE"] = "line";
    Type["BAR"] = "bar";
})(Type || (Type = {}));
let PiBaseCurveBar = PiBaseCurveBar_1 = class PiBaseCurveBar extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseCurvePolyline';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseCurveBar_1?.NAME,
        author: 'caiQw',
        title: '曲线折线图',
        version: BcVersion,
        updateDate: '2019-08-9',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * echart数据 请求地址
     */
    url;
    /**
     * option参数配置,属性值的优先级都次于以下单独传入的值
     */
    option;
    /**
     * x轴横向坐标值
     */
    xData;
    /**
     * y轴横向坐标值
     */
    yAxis;
    /**
     * 标题
     */
    title;
    /**
     * 副标题
     */
    subTitle;
    /**
     * 单位
     */
    unit;
    /**
     * Bar值的列表组合
     */
    barConfig;
    /**
     * line值的列表组合
     */
    lineConfig;
    /**
     * type:line 每一项的系列配置 背景颜色、折线颜色等
     */
    seriesLine;
    //#endregion
    //#region data
    // 标志是否初始化过xAxis.data
    isFinishedXAxisData = false;
    seriesBarConfig = {
        name: '',
        type: 'bar',
        /*设置柱状图颜色*/
        // itemStyle: {
        //   normal: {
        //     color: '#42A5F5',
        //   },
        // },
        data: [],
    };
    seriesLineConfig = {
        name: '',
        type: 'line',
        smooth: true,
        data: [],
    };
    /**
     * echarts配置数据
     */
    ecConfig = {
        theme: 'pi-default',
        loading: true,
        options: fnEChartsOptionsFactory({
            color: [
                '#42A5F5',
                '#46eda9',
                '#47e4ed',
                '#4bbbee',
                '#7646d8',
                '#924ae2',
                '#C6E579',
                '#F4E001',
                '#F0805A',
                '#26C0C0',
            ],
            grid: { top: hReactiveUtil.convertToReactivePx(80) },
            //标题
            title: {
                text: this.title || '',
                subtext: this.subTitle || '',
                x: 'center', //位置默认居中
            },
            //提示
            tooltip: {
                // 'axis' 坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
                trigger: 'axis',
            },
            //组建
            legend: {
                top: hReactiveUtil.convertToReactivePx(10),
                right: '0',
            },
            xAxis: [
                {
                    type: 'category',
                    axisLine: {
                        lineStyle: {
                            type: 'solid',
                            color: '#ccc',
                            width: hReactiveUtil.convertToReactivePx(1), //坐标线的宽度
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#999', //坐标值得具体的颜色
                        },
                    },
                    data: [],
                },
            ],
            // y轴坐标
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        type: 'dotted',
                        color: '#999',
                        width: hReactiveUtil.convertToReactivePx(1),
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: '#999',
                    },
                },
            },
            series: [],
        }),
    };
    //#endregion
    isShowProperty = false;
    options = (() => {
        const options = new WidgetInstanceEntity();
        options.attrObj.Attr = {
            isShowDownLoad: false,
        };
        return options;
    })();
    //#endregion
    //#region computed
    get about() {
        return PiBaseCurveBar_1.ABOUT;
    }
    get titleAll() {
        return this.options.title;
    }
    get attr() {
        return this.options.attrObj.Attr;
    }
    //#endregion
    //#region watch
    'watch.url'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            this.getChartData(newVal);
        }
    }
    'watch.option'(val, oVal) {
        this.option.xAxis &&
            this.$set(this.ecConfig.options, 'xAxis', this.option.xAxis);
        if (this.option.yAxis &&
            Object.prototype.toString.call(this.ecConfig.options.yAxis) ===
                Object.prototype.toString.call(this.option.yAxis)) {
            // 合并配置参数
            hODeepObjectMerge(this.ecConfig.options, this.option);
        }
        else if (this.option.yAxis) {
            this.$set(this.ecConfig.options, 'yAxis', this.option.yAxis);
            hODeepObjectMerge(this.ecConfig.options, this.option);
        }
        if (val !== oVal && Object.keys(val).length) {
            hODeepObjectMerge(this.ecConfig.options, this.option);
            let { series } = this.option;
            if (series && series.length) {
                this.ecConfig.options.series = [];
                series.forEach((item) => {
                    const { type } = item;
                    switch (type) {
                        case Type.BAR:
                            let barConfig = {};
                            hODeepObjectMerge(barConfig, this.seriesBarConfig, item);
                            this.ecConfig.options.series.push(barConfig);
                            break;
                        case Type.LINE:
                            let lineConfig = {};
                            hODeepObjectMerge(lineConfig, this.seriesLineConfig, item);
                            this.ecConfig.options.series.push(lineConfig);
                            break;
                        default:
                            break;
                    }
                });
            }
        }
    }
    'watch.barConfig'(newVal, oldVal) {
        if (newVal.length && !this.url) {
            if (newVal && Array.isArray(newVal)) {
                // 过滤掉非bar的数据
                let series = this.ecConfig.options.series.filter((item) => {
                    return item.type !== Type.BAR;
                });
                this.ecConfig.options.series = series;
                //清空xAxis的数据
                // this.ecConfig.options.xAxis instanceof Array &&
                //   this.$set(this.ecConfig.options.xAxis[0], 'data', []);
                this.barConfig.forEach((item, index) => {
                    let barConfig = {};
                    hODeepObjectMerge(barConfig, this.seriesBarConfig, item);
                    this.ecConfig.options.series.push(barConfig);
                    if (!index && !this.isFinishedXAxisData) {
                        this.setXAxisData(item);
                    }
                });
            }
        }
    }
    'watch.lineConfig'(newVal, oldVal) {
        if (newVal.length && !this.url) {
            if (newVal && Array.isArray(newVal)) {
                let series = this.ecConfig.options.series.filter((item) => {
                    return item.type !== Type.LINE;
                });
                this.ecConfig.options.series = series;
                this.lineConfig.forEach((item, index) => {
                    let lineConfig = {};
                    hODeepObjectMerge(lineConfig, this.seriesLineConfig, item);
                    // 根据传入的折线配置项设置对应的值
                    switch (Object.prototype.toString.call(this.seriesLine)) {
                        case '[object Object]':
                            hODeepObjectMerge(lineConfig, this.seriesLine);
                            break;
                        case '[object Array]':
                            let seriesLine = (this.seriesLine)[index];
                            seriesLine && hODeepObjectMerge(lineConfig, seriesLine);
                            break;
                    }
                    this.ecConfig.options.series.push(lineConfig);
                    if (!index && !this.isFinishedXAxisData) {
                        this.setXAxisData(item);
                    }
                });
            }
        }
    }
    'watch.yAxis'(newVal, oldVal) {
        this.ecConfig.options.yAxis = newVal;
    }
    'watch.xData'(newVal, oldVal) {
        if (!newVal.length) {
            return;
        }
        const isArray = this.ecConfig.options.xAxis instanceof Array;
        const isObject = this.ecConfig.options.xAxis instanceof Object;
        if (newVal !== oldVal && newVal.length && isArray) {
            this.ecConfig.options.xAxis instanceof Array &&
                (this.ecConfig.options.xAxis[0].data = newVal);
        }
        else if (isObject) {
            this.ecConfig.options.xAxis instanceof Object &&
                this.$set(this.ecConfig.options.xAxis, 'data', newVal);
        }
    }
    'watch.title'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.$set(this.ecConfig.options.title, 'text', newVal);
    }
    'watch.subTitle'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.$set(this.ecConfig.options.title, 'subtext', newVal);
    }
    'watch.unit'(newVal, oldVal) {
        // 当传入的单位为字符串时
        if (typeof newVal === 'string') {
            // 判断当前yAxis对象的类型
            switch (Object.prototype.toString.call(this.ecConfig.options.yAxis)) {
                case '[object Object]':
                    this.ecConfig.options.yAxis &&
                        ((this.ecConfig.options.yAxis).name = newVal);
                    break;
                case '[object Array]':
                    this.ecConfig.options.yAxis &&
                        ((this.ecConfig.options.yAxis)[0].name = newVal);
                    break;
                default:
                    break;
            }
        }
        // 当传入的单位为数组时
        else if (newVal instanceof Array) {
            // 判断当前yAxis对象的类型
            switch (Object.prototype.toString.call(this.ecConfig.options.yAxis)) {
                case '[object Object]':
                    this.ecConfig.options.yAxis &&
                        (this.ecConfig.options.yAxis.name =
                            newVal[0]);
                    break;
                case '[object Array]':
                    this.ecConfig.options.yAxis.forEach((item, index) => {
                        item.name = newVal[index] || '';
                    });
                    break;
                default:
                    break;
            }
        }
    }
    //#endregion
    //#region 函数类属性
    /**
     * 属性工具栏菜单,任何组件都是使用自身的属性配置方法
     */
    toolbarShowProperty = () => {
        this.isShowProperty = true;
    };
    /**
     * 给外部调用执行联动的实现函数
     * @param data
     */
    toolbarCbLink = (data) => {
        //修改查询条件
    };
    //#endregion
    //#region methods
    /**
     * 修改options数据
     */
    changeOptions(optionsNew) {
        // this.options = hODeepClone(optionsNew);
        // // this.widgetInfo.attrJson = JSON.stringify(optionsNew);
        // this.$emit('on-change-widget-info', this.options);
    }
    /**
     * 获取eChart的数据源，优先级最高
     * @param url 请求地址
     */
    getChartData(url) {
        hAjaxGet(url)
            .instance.then((res) => {
            const { data: rData } = res;
            if (!rData) {
                return;
            }
            const { data: dData } = rData;
            if (!dData || !dData.length) {
                return;
            }
            const { yAxis } = this.ecConfig.options;
            // 设置yAxis、yAxisIndex
            let yAxisConfig;
            switch (Object.prototype.toString.call(this.ecConfig.options.yAxis)) {
                case '[object Object]':
                    yAxisConfig = (this.ecConfig.options.yAxis);
                    break;
                case '[object Array]':
                    yAxisConfig = [];
                    this.ecConfig.options.yAxis.forEach((item) => {
                        yAxisConfig.push(item);
                    });
                    break;
                default:
                    break;
            }
            let yAxisTemp = [];
            let unitList = [];
            dData.forEach((item, index) => {
                const { unit } = item;
                let yAxisConfigTemp;
                switch (Object.prototype.toString.call(this.ecConfig.options.yAxis)) {
                    case '[object Object]':
                        yAxisConfigTemp = hODeepClone(this.ecConfig.options.yAxis);
                        break;
                    case '[object Array]':
                        yAxisConfigTemp = hODeepClone(this.ecConfig.options.yAxis[index] || {});
                        break;
                    default:
                        break;
                }
                if (unit && !unitList.includes(unit)) {
                    this.$set(yAxisConfigTemp, 'name', unit);
                    unitList.push(unit);
                    yAxisTemp.push(yAxisConfigTemp);
                    item.yAxisIndex = index;
                }
            });
            if (Array.isArray(yAxis)) {
                yAxisTemp &&
                    this.ecConfig.options.yAxis &&
                    hODeepObjectMerge(this.ecConfig.options.yAxis, yAxisTemp);
            }
            else {
                this.$set(this.ecConfig.options, 'yAxis', yAxisTemp);
            }
            hODeepObjectMerge(this.ecConfig.options.series, dData);
            dData[0] && this.setXAxisData(dData[0]);
        })
            .catch((err) => hErrorShow({
            err,
            showInPage: true,
            context: this,
        }));
    }
    /**
     *  设置XAxis data的值
     * @param series
     */
    setXAxisData(series) {
        const { data } = series;
        Array.isArray(data) &&
            data.forEach((item, index) => {
                //往xAxis添加数据
                if (Array.isArray(this.ecConfig.options.xAxis)) {
                    this.ecConfig.options.xAxis instanceof Array &&
                        this.$set(this.ecConfig.options.xAxis[0].data, index, item.name || '');
                }
                else if (typeof this.ecConfig.options.xAxis === 'object') {
                    !index && (this.ecConfig.options.xAxis.data = []);
                    this.ecConfig.options.xAxis.data.push(item.name);
                }
                this.isFinishedXAxisData = true;
            });
    }
    //#endregion
    //#region hooks
    beforeCreate() {
        registerEchartsTheme();
    }
    mounted() {
        this.$nextTick(() => {
            window.onresize = () => {
                this.$refs['vecharts'] && this.$refs['vecharts'].resize();
            };
        });
    }
    created() {
        //设置数据源弹窗显示的可选项内容
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
    }
    beforeDestroy() {
        window.onresize = null;
    }
};
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseCurveBar.prototype, "url", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseCurveBar.prototype, "option", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseCurveBar.prototype, "xData", void 0);
__decorate([
    Prop({
        type: Array || Object,
    })
], PiBaseCurveBar.prototype, "yAxis", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseCurveBar.prototype, "title", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseCurveBar.prototype, "subTitle", void 0);
__decorate([
    Prop({
        type: String || Array,
    })
], PiBaseCurveBar.prototype, "unit", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseCurveBar.prototype, "barConfig", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseCurveBar.prototype, "lineConfig", void 0);
__decorate([
    Prop({
        type: Object || Array,
    })
], PiBaseCurveBar.prototype, "seriesLine", void 0);
__decorate([
    Watch('url', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.url", null);
__decorate([
    Watch('option', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.option", null);
__decorate([
    Watch('barConfig', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.barConfig", null);
__decorate([
    Watch('lineConfig', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.lineConfig", null);
__decorate([
    Watch('yAxis', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.yAxis", null);
__decorate([
    Watch('xData', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.xData", null);
__decorate([
    Watch('title', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.title", null);
__decorate([
    Watch('subTitle', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.subTitle", null);
__decorate([
    Watch('unit', { immediate: true, deep: true })
], PiBaseCurveBar.prototype, "watch.unit", null);
PiBaseCurveBar = PiBaseCurveBar_1 = __decorate([
    Component({
        components: {
            Spin,
            Dropdown,
            VEcharts,
        },
        template,
    })
], PiBaseCurveBar);
export default PiBaseCurveBar;
