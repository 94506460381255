(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("svg-pan-zoom"), require("iview"), require("draggable-vue-directive"), require("vuex"), require("lodash"), require("hls.js"), require("bignumber.js"), require("qs"), require("tweenjs"), require("vue-i18n"), require("vue"), require("crypto-js"), require("sortablejs"), require("vue-echarts-v3"), require("vue-context-menu"), require("@stomp/stompjs"), require("axios"), require("better-scroll"), require("tree-vue-component"));
	else if(typeof define === 'function' && define.amd)
		define(["svg-pan-zoom", "iview", "draggable-vue-directive", "vuex", "lodash", "hls.js", "bignumber.js", "qs", "tweenjs", "vue-i18n", "vue", "crypto-js", "sortablejs", "vue-echarts-v3", "vue-context-menu", "@stomp/stompjs", "axios", "better-scroll", "tree-vue-component"], factory);
	else if(typeof exports === 'object')
		exports["front-basic-components"] = factory(require("svg-pan-zoom"), require("iview"), require("draggable-vue-directive"), require("vuex"), require("lodash"), require("hls.js"), require("bignumber.js"), require("qs"), require("tweenjs"), require("vue-i18n"), require("vue"), require("crypto-js"), require("sortablejs"), require("vue-echarts-v3"), require("vue-context-menu"), require("@stomp/stompjs"), require("axios"), require("better-scroll"), require("tree-vue-component"));
	else
		root["PiFrontBasicComponents"] = factory(root["svgPanZoom"], root["iview"], root["DraggableVueDirective"], root["Vuex"], root["_"], root["Hls"], root["BigNumber"], root["Qs"], root["TWEEN"], root["VueI18n"], root["Vue"], root["CryptoJS"], root["Sortable"], root["VueECharts"], root["VueContextMenu"], root["StompJs"], root["axios"], root["BetterScroll"], root["Tree"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__2426__, __WEBPACK_EXTERNAL_MODULE__3955__, __WEBPACK_EXTERNAL_MODULE__5468__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__60bb__, __WEBPACK_EXTERNAL_MODULE__712b__, __WEBPACK_EXTERNAL_MODULE__77f0__, __WEBPACK_EXTERNAL_MODULE__796d__, __WEBPACK_EXTERNAL_MODULE__80de__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_a2c5__, __WEBPACK_EXTERNAL_MODULE_a352__, __WEBPACK_EXTERNAL_MODULE_bfcd__, __WEBPACK_EXTERNAL_MODULE_c208__, __WEBPACK_EXTERNAL_MODULE_cd95__, __WEBPACK_EXTERNAL_MODULE_cebe__, __WEBPACK_EXTERNAL_MODULE_ec51__, __WEBPACK_EXTERNAL_MODULE_f24d__) {
return 