/**
 * 请求api接口时的参数对象实体类
 */
export class ApiParamsEntity {
    /**
     * 请求token,常在请求头中添加，具体请求可以不用重复添加,可不传
     */
    token;
    /**
     * 当前登录用户主键,可不传
     */
    currUserPkId;
    /**
     * 当前登录角色主键,可不传
     */
    currRolePkId;
    /**
     * 当前登录用户名称,可不传
     */
    currUserName;
    /**
     * 语言类型,可不传,默认中文简写
     * @default 1
     */
    lang;
    /**
     * 角色类型,可不传
     */
    currRoleType;
    /**
     * 运营商组织机构代码（9位）,可不传
     */
    currOperatorId;
}
/**
 * 请求可排序的api接口时的请求参数对象实体类
 */
export class ApiParamsPagedEntity extends ApiParamsEntity {
    /**
     * 页码 默认1，第一页
     * @default 1
     */
    globalPageIndex;
    /**
     *  每页大小 默认每页10条
     * @default 10
     */
    globalPageSize;
    /**
     * 排序字段， 格式：name=asc,age=desc
     * @default 10
     */
    globalOrder;
    /**
     * 搜索关键字
     * @default 1
     */
    globalSearch;
}
