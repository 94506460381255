var PiBaseEasyTableDropdown_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-easy-table-dropdown.component.html';
import { default as Settings } from '../../settings/settings';
import { default as ClickOutside } from '../../directives/click-outside';
import { default as VCheckboxGroup } from '../pi-base-easy-table-checkbox-group/pi-base-easy-table-checkbox-group.component';
import { default as VCheckbox } from '../pi-base-easy-table-checkbox/pi-base-easy-table-checkbox.component';
import { default as LayerAdjustmentMixin } from '../../mixins/layer-adjustment.mixin';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
const PiBase = VueBase;
let PiBaseEasyTableDropdown = PiBaseEasyTableDropdown_1 = class PiBaseEasyTableDropdown extends PiBase {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEasyTableDropdown';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEasyTableDropdown_1?.NAME,
        author: 'yangd',
        title: '无标题',
        version: BcVersion,
        updateDate: '2019-08-08',
        description: '',
    };
    //#endregion
    //#region props
    // 如果是select 组件将特殊处理
    isSelect;
    showOperation;
    size;
    width;
    // select的最大宽度(超出隐藏)
    maxWidth;
    // 如果为true 会包含 checkbox
    isMultiple;
    // 用户传入v-model 的值 [{value/label/selected}]
    value;
    // 占位符
    placeholder;
    // 文本居中方式 left|center|right
    textAlign;
    // 最小选中数量
    min;
    // 最大选中数量
    max;
    // 是否支持输入input
    isInput;
    //#endregion
    //#region data
    visible = false;
    internalOptions = [];
    // checkboxGroup 选中的项
    checkboxGroupList = [];
    // 样式前缀
    textAlignPrefix = 'v-dropdown-items-li-a-';
    inputValue = '';
    // 是否有选项被改变（初始值为null 为了区分首次internalOptions 赋值的问题）
    isOperationChange = null;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEasyTableDropdown_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    get sizeClass() {
        let size = Settings.sizeMaps[this.size] ||
            Settings.sizeMapDefault;
        return size === Settings.sizeMaps['large']
            ? ' v-dropdown--large'
            : size === Settings.sizeMaps['middle']
                ? ' v-dropdown--middle'
                : ' v-dropdown--small';
    }
    // 获取最大宽度(不设置则是无穷大)
    get getMaxWidth() {
        let result = Infinity, maxWidth = this.maxWidth, width = this.width;
        if (maxWidth && maxWidth > 0 && maxWidth > width) {
            result = maxWidth;
        }
        return result;
    }
    //#endregion
    //#region watch
    'watch.value'(newVal, oldVal) {
        this.init();
    }
    'watch.internalOptions'(newVal, oldVal) {
        this.isOperationChange =
            this.showOperation && this.isOperationChange !== null ? true : false;
    }
    //#endregion
    //#region methods
    // 初始化
    init() {
        this.internalOptions = Object.assign([], this.value);
        this.checkboxGroupList = this.selectedLabels();
        if (this.isInput) {
            this.setInputValue();
        }
    }
    // operation filter confirm
    confirm() {
        if (this.isOperationChange) {
            this.$emit('on-filter-method', this.internalOptions);
            this.isOperationChange = false;
        }
        this.hideDropDown();
    }
    // operation filter reset
    rest() {
        if (this.internalOptions.some((x) => x.selected)) {
            this.internalOptions.map((x) => {
                if (x.selected) {
                    x.selected = false;
                }
                return x;
            });
            this.checkboxGroupList = [];
            // 使用户传入的v-model 生效
            this.$emit('input', this.internalOptions);
            this.$emit('change');
            // 修复执行两次的bug
            /*this.$emit('on-filter-method',this.internalOptions);
      
              this.isOperationChange = false;*/
        }
        setTimeout(() => {
            this.hideDropDown();
        }, 50);
    }
    hideDropDown() {
        if (this.showOperation && this.isOperationChange) {
            this.$emit('on-filter-method', this.internalOptions);
            this.isOperationChange = false;
        }
        this.visible = false;
    }
    showDropDown() {
        this.visible = true;
    }
    // 设置文本框的值
    setInputValue() {
        let result, labels;
        labels = this.selectedLabels();
        if (Array.isArray(labels) && labels.length > 0) {
            result = labels.join();
        }
        this.inputValue = result;
    }
    // checkbox 选中改变事件
    checkboxGroupChange() {
        this.selectOptionClick();
    }
    toggleItems() {
        //this.visible = !this.visible;
        if (this.visible) {
            this.hideDropDown();
        }
        else {
            this.showDropDown();
            this.$nextTick(() => {
                this.dropDownClick();
            });
        }
    }
    selectOptionClick(item) {
        if (!this.isMultiple) {
            this.internalOptions.map((x) => {
                if (item && item.label === x.label) {
                    x.selected = true;
                }
                else {
                    x.selected = false;
                }
                return x;
            });
        }
        else {
            // 多选
            this.internalOptions.map((x) => {
                if (this.checkboxGroupList.includes(x.label)) {
                    x.selected = true;
                }
                else {
                    x.selected = false;
                }
                return x;
            });
        }
        if (!this.isMultiple) {
            this.toggleItems();
        }
        if (this.isInput) {
            this.setInputValue();
        }
        // 使用户传入的v-model 生效
        this.$emit('input', this.internalOptions);
        this.$emit('change');
    }
    // 获取样式名称
    getTextAlignClass() {
        return this.textAlignPrefix + this.textAlign;
    }
    // 当前选中项的label
    selectedLabels() {
        return this.internalOptions
            .filter((x) => x.selected)
            .map((x) => {
            if (x.selected) {
                return x.label;
            }
        });
    }
    clickOutside() {
        this.hideDropDown();
        //this.visible = false
    }
    // 下拉点击显示
    dropDownClick() {
        const dtEle = this.$el.querySelector('.v-dropdown-dt'), ddItem = this.$el.querySelector('.v-dropdown-items');
        this.layerAdjustmentOnce(ddItem, dtEle, 2);
        return false;
    }
    // 确定下拉框的位置
    dropdownAdjust() {
        const dtEle = this.$el.querySelector('.v-dropdown-dt'), ddItem = this.$el.querySelector('.v-dropdown-items');
        this.layerAdjustmentBind(ddItem, dtEle, 2);
    }
    //#endregion
    //#region hooks
    created() {
        this.init();
    }
    mounted() {
        this.dropdownAdjust();
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTableDropdown.prototype, "isSelect", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTableDropdown.prototype, "showOperation", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBaseEasyTableDropdown.prototype, "size", void 0);
__decorate([
    Prop({
        type: Number,
        default: hReactiveUtil.convertToReactivePx(90),
    })
], PiBaseEasyTableDropdown.prototype, "width", void 0);
__decorate([
    Prop({
        type: Number,
    })
], PiBaseEasyTableDropdown.prototype, "maxWidth", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTableDropdown.prototype, "isMultiple", void 0);
__decorate([
    Prop({
        type: [Object, Array],
    })
], PiBaseEasyTableDropdown.prototype, "value", void 0);
__decorate([
    Prop({
        type: String,
        default: '请选择',
        validator: function (value) {
            return value.length > 0;
        },
    })
], PiBaseEasyTableDropdown.prototype, "placeholder", void 0);
__decorate([
    Prop({
        type: String,
        default: 'left',
    })
], PiBaseEasyTableDropdown.prototype, "textAlign", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0,
    })
], PiBaseEasyTableDropdown.prototype, "min", void 0);
__decorate([
    Prop({
        type: Number,
        default: 999,
    })
], PiBaseEasyTableDropdown.prototype, "max", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTableDropdown.prototype, "isInput", void 0);
__decorate([
    Watch('value')
], PiBaseEasyTableDropdown.prototype, "watch.value", null);
__decorate([
    Watch('internalOptions')
], PiBaseEasyTableDropdown.prototype, "watch.internalOptions", null);
PiBaseEasyTableDropdown = PiBaseEasyTableDropdown_1 = __decorate([
    Component({
        name: 'v-dropdown',
        components: {
            VCheckboxGroup,
            VCheckbox,
        },
        directives: {
            'click-outside': ClickOutside,
        },
        template,
        mixins: [LayerAdjustmentMixin],
    })
], PiBaseEasyTableDropdown);
export default PiBaseEasyTableDropdown;
