/**
 * 后台api接口返回的对象实体类
 */
export class SResultEntity {
    /**
     * 返回状态
     */
    status;
    /**
     * 返回结果描述
     */
    msg;
    /**
     * 返回结果对象
     */
    data;
}
/**
 * 后台返回带有分页数据信息的对象实体类
 */
export class SResultPagedEntity extends SResultEntity {
    /**
     * 当前页码
     */
    globalPageIndex;
    /**
     * 每页记录数
     */
    globalPageSize;
    /**
     * 总记录数
     */
    total;
    /**
     * 总页数
     */
    totalPage;
}
