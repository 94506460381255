var PiBaseEasyTableEmpty_1;
import { __decorate } from "tslib";
import { Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-easy-table-empty.component.html';
let PiBaseEasyTableEmpty = PiBaseEasyTableEmpty_1 = class PiBaseEasyTableEmpty extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEasyTableEmpty';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEasyTableEmpty_1?.NAME,
        author: 'yangd',
        title: '无标题',
        version: BcVersion,
        updateDate: '2019-08-08',
        description: '',
    };
    //#endregion
    //#region props
    // 表头的宽度
    titleHeight;
    // 内容显示的高度
    contentHeight;
    // 显示的宽度
    width;
    // 所有列的宽度和
    totalColumnsWidth;
    // 没数据时显示的内容
    errorContent;
    // 是否正在加载
    isLoading;
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEasyTableEmpty_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    /**
     * 获取当前要显示的内容
     */
    get getCurrentContent() {
        let result = '';
        if (!this.isLoading) {
            result = this.errorContent;
        }
        return result;
    }
};
__decorate([
    Prop({
        type: [Number, String],
        required: true,
    })
], PiBaseEasyTableEmpty.prototype, "titleHeight", void 0);
__decorate([
    Prop({
        type: [Number, String],
        required: true,
    })
], PiBaseEasyTableEmpty.prototype, "contentHeight", void 0);
__decorate([
    Prop({
        type: [Number, String],
        required: true,
    })
], PiBaseEasyTableEmpty.prototype, "width", void 0);
__decorate([
    Prop({
        type: [Number, String],
        required: true,
    })
], PiBaseEasyTableEmpty.prototype, "totalColumnsWidth", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseEasyTableEmpty.prototype, "errorContent", void 0);
__decorate([
    Prop({
        type: Boolean,
        required: true,
    })
], PiBaseEasyTableEmpty.prototype, "isLoading", void 0);
PiBaseEasyTableEmpty = PiBaseEasyTableEmpty_1 = __decorate([
    Component({
        template,
    })
], PiBaseEasyTableEmpty);
export default PiBaseEasyTableEmpty;
