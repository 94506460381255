import { __decorate } from "tslib";
import { UserSessionStorage } from 'bc$/enums/user/pi-user.enum';
import { ApiUrlSetting } from 'bc$/main';
import { Store } from 'bc$/store/store';
import { hSessionPrivate, hSessionPublic } from 'bc$/utils/storage.helper';
import { hTokenStopRefresh, hTokenUpdate } from 'bc$/utils/token.helper';
import { getModule, Module, Mutation, VuexModule, } from 'vuex-module-decorators';
const refreshUrlDefault = '/system-manage-service/v1/account/refresh_token';
/**
 * 除去loginInfo的信息外,其余的信息存入SessionPublic以考虑未来在多个项目公用Session
 */
let PiBaseStoreUser = class PiBaseStoreUser extends VuexModule {
    _isUpdateToken = '';
    _accessToken = '';
    _refreshToken = '';
    _expiresTime = 0;
    _refreshUrl = '';
    _targetUrl = '';
    _userName = '';
    _fullName = '';
    _userPkId = '';
    _hasAdmin = '';
    _roleType = Infinity;
    _loginInfo = undefined;
    get isUpdateToken() {
        let data = this._isUpdateToken;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.isUpdateToken) || 'true';
            if (data === 'true') {
                hTokenStopRefresh(false);
                hTokenUpdate(2e4);
            }
            store.setIsUpdateToken({ data });
        }
        return data;
    }
    get accessToken() {
        let data = this._accessToken;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.accessToken) || '';
            //定时刷新token
            if (data && this._isUpdateToken === 'true') {
                hTokenStopRefresh(false);
                hTokenUpdate(2e4);
            }
        }
        store.setAccessToken({ data });
        return data;
    }
    get refreshToken() {
        let data = this._refreshToken;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.refreshToken) || '';
        }
        store.setRefreshToken({ data });
        return data;
    }
    get expiresTime() {
        let data = this._expiresTime;
        if (!data) {
            const str = hSessionPublic.getItem(UserSessionStorage.expiresTime);
            data = Number(str);
            if (isNaN(data)) {
                data = 0;
            }
        }
        store.setExpiresTime({ data });
        return data;
    }
    get refreshUrl() {
        let data = this._refreshUrl;
        if (!data) {
            data =
                hSessionPublic.getItem(UserSessionStorage.refreshUrl) ||
                    ApiUrlSetting.AccountInfo.RefreshToken ||
                    refreshUrlDefault;
        }
        store.setRefreshUrl({ data });
        return data;
    }
    get targetUrl() {
        let data = this._targetUrl;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.targetUrl) || '';
        }
        store.setTargetUrl({ data });
        return data;
    }
    get userPkId() {
        let data = this._userPkId;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.userPkId) || '';
        }
        store.setUserPkId({ data });
        return data;
    }
    get hasAdmin() {
        let data = this._hasAdmin;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.hasAdmin) || '';
        }
        store.setHasAdmin({ data });
        return data;
    }
    get userName() {
        let data = this._userName;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.userName) || '';
        }
        store.setUserName({ data });
        return data;
    }
    get fullName() {
        let data = this._fullName;
        if (!data) {
            data = hSessionPublic.getItem(UserSessionStorage.fullName) || '';
        }
        store.setFullName({ data });
        return data;
    }
    get roleType() {
        let data = this._roleType;
        if ((!data && data !== 0) || data === Infinity) {
            const str = hSessionPublic.getItem(UserSessionStorage.roleType);
            data = Number(str);
            if (isNaN(data)) {
                data = Infinity;
            }
        }
        store.setRoleType({ data });
        return data;
    }
    get loginInfo() {
        let data = this._loginInfo;
        if (!data) {
            const str = hSessionPrivate.getItem(UserSessionStorage.loginInfo) || '{}';
            try {
                data = JSON.parse(str);
            }
            catch (err) {
                data = {};
            }
        }
        store.setLoginInfo({ data });
        return data;
    }
    /**
     * 清空 Store 数据
     */
    emptyStore() {
        //清除storage中写入的数据
        [
            UserSessionStorage.accessToken,
            UserSessionStorage.refreshToken,
            UserSessionStorage.expiresTime,
            UserSessionStorage.refreshUrl,
            UserSessionStorage.targetUrl,
            UserSessionStorage.userPkId,
            UserSessionStorage.hasAdmin,
            UserSessionStorage.userName,
            UserSessionStorage.fullName,
            UserSessionStorage.roleType,
            UserSessionStorage.loginInfo,
        ].forEach((key) => {
            hSessionPublic.removeItem(key);
        });
        //清除vuex的store
        this._accessToken = '';
        this._refreshToken = '';
        this._expiresTime = 0;
        this._userPkId = '';
        this._refreshUrl = '';
        this._targetUrl = '';
        this._hasAdmin = '';
        this._userName = '';
        this._fullName = '';
        this._roleType = Infinity;
        this._loginInfo = undefined;
    }
    /**
     * 设置 user
     * @param data
     */
    setUser(data) {
        //清空之前的数据
        store.emptyStore();
        //写到storage
        hSessionPublic.setItem(UserSessionStorage.accessToken, data.accessToken);
        hSessionPublic.setItem(UserSessionStorage.refreshToken, data.refreshToken);
        const expiresTime = new Date().getTime() + data.expiresIn * 1e3;
        hSessionPublic.setItem(UserSessionStorage.expiresTime, `${expiresTime}`);
        hSessionPublic.setItem(UserSessionStorage.userPkId, data.userPkId);
        const strHasAdmin = data.hasAdmin ? '1' : '';
        hSessionPublic.setItem(UserSessionStorage.hasAdmin, strHasAdmin);
        hSessionPublic.setItem(UserSessionStorage.userName, data.userName);
        hSessionPublic.setItem(UserSessionStorage.fullName, data.fullName);
        hSessionPublic.setItem(UserSessionStorage.roleType, `${data.roleType}`);
        //写入vuex的store
        this._accessToken = data.accessToken;
        this._refreshToken = data.refreshToken;
        this._expiresTime = expiresTime;
        this._refreshUrl =
            data.refreshUrl || ApiUrlSetting.AccountInfo.RefreshToken || '';
        this._targetUrl = data.targetUrl || '';
        this._userPkId = data.userPkId;
        this._hasAdmin = strHasAdmin;
        this._userName = data.userName;
        this._fullName = data.fullName;
        this._roleType = data.roleType;
        [
            'accessToken',
            'refreshToken',
            'targetUrl',
            'userPkId',
            'hasAdmin',
            'userName',
            'fullName',
            'roleType',
            'expiresIn',
            'refreshUrl',
        ].forEach((key) => {
            delete data[key];
        });
        const loginInfo = data;
        hSessionPrivate.setItem(UserSessionStorage.loginInfo, JSON.stringify(loginInfo));
        this._loginInfo = loginInfo;
        //定时刷新token
        if (this._isUpdateToken === 'true') {
            hTokenStopRefresh(false);
            hTokenUpdate(2e4);
        }
    }
    /**
     * 设置 isUpdateToken
     * @param data
     */
    setIsUpdateToken(state) {
        const { data } = state;
        const isUpdateToken = (this._isUpdateToken = data);
        hSessionPublic.setItem(UserSessionStorage.isUpdateToken, isUpdateToken);
        hTokenStopRefresh(isUpdateToken !== 'true');
    }
    /**
     * 设置 accessToken
     * @param data
     */
    setAccessToken(state) {
        const { data } = state;
        const accessToken = (this._accessToken = data);
        hSessionPublic.setItem(UserSessionStorage.accessToken, accessToken);
    }
    /**
     * 设置 refreshToken
     * @param data
     */
    setRefreshToken(state) {
        const { data } = state;
        const refreshToken = (this._refreshToken = data);
        hSessionPublic.setItem(UserSessionStorage.refreshToken, refreshToken);
    }
    /**
     * 设置 expiresTime
     * @param data
     */
    setExpiresTime(state) {
        const { data } = state;
        const expiresTime = (this._expiresTime = data);
        hSessionPublic.setItem(UserSessionStorage.expiresTime, `${expiresTime}`);
    }
    /**
     * 设置 refreshUrl
     * @param data
     */
    setRefreshUrl(state) {
        const { data } = state;
        const refreshUrl = (this._refreshUrl =
            data || ApiUrlSetting.AccountInfo.RefreshToken || refreshUrlDefault);
        hSessionPublic.setItem(UserSessionStorage.refreshUrl, refreshUrl);
    }
    /**
     * 设置 targetUrl
     * @param data
     */
    setTargetUrl(state) {
        const { data } = state;
        const targetUrl = (this._targetUrl = data || '');
        hSessionPublic.setItem(UserSessionStorage.targetUrl, targetUrl);
    }
    /**
     * 设置 userPkId
     * @param data
     */
    setUserPkId(state) {
        const { data } = state;
        const userPkId = (this._userPkId = data || '');
        hSessionPublic.setItem(UserSessionStorage.userPkId, userPkId);
    }
    /**
     * 设置 hasAdmin
     * @param data
     */
    setHasAdmin(state) {
        const { data } = state;
        const hasAdmin = (this._hasAdmin = data || '');
        hSessionPublic.setItem(UserSessionStorage.hasAdmin, hasAdmin);
    }
    /**
     * 设置 userName
     * @param data
     */
    setUserName(state) {
        const { data } = state;
        const userName = (this._userName = data || '');
        hSessionPublic.setItem(UserSessionStorage.userName, userName);
    }
    /**
     * 设置 fullName
     * @param data
     */
    setFullName(state) {
        const { data } = state;
        const fullName = (this._fullName = data || '');
        hSessionPublic.setItem(UserSessionStorage.fullName, fullName);
    }
    /**
     * 设置 roleType
     * @param data
     */
    setRoleType(state) {
        const { data } = state;
        const roleType = (this._roleType = data);
        hSessionPublic.setItem(UserSessionStorage.roleType, `${roleType}`);
    }
    /**
     * 设置 roleType
     * @param data
     */
    setLoginInfo(state) {
        const { data } = state;
        const loginInfo = (this._loginInfo = data);
        hSessionPrivate.setItem(UserSessionStorage.loginInfo, JSON.stringify(loginInfo));
    }
};
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "emptyStore", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setUser", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setIsUpdateToken", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setAccessToken", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setRefreshToken", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setExpiresTime", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setRefreshUrl", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setTargetUrl", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setUserPkId", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setHasAdmin", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setUserName", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setFullName", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setRoleType", null);
__decorate([
    Mutation
], PiBaseStoreUser.prototype, "setLoginInfo", null);
PiBaseStoreUser = __decorate([
    Module({
        namespaced: true,
        dynamic: true,
        store: Store,
        name: 'piBaseUser',
    })
], PiBaseStoreUser);
export { PiBaseStoreUser };
const store = getModule(PiBaseStoreUser);
export { store as PiBaseStoreUserModule };
