import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// 创建一个新的 VueI18n 实例
export const i18n = new VueI18n({
    silentTranslationWarn: true,
});
export function loadCmpLang(lang, obj) {
    const { constructor } = this;
    const cmpName = constructor.name;
    i18n.setLocaleMessage(lang, { [cmpName]: obj });
}
