import { __decorate } from "tslib";
import { WidgetEntity, WidgetInstanceEntity } from 'bc$/entities/layout';
import { Store } from 'bc$/store/store';
import { getModule, Module, Mutation, VuexModule, } from 'vuex-module-decorators';
let PiBaseStoreWidget = class PiBaseStoreWidget extends VuexModule {
    /**
     * 所有打开的页面,可以重复
     */
    tabs = [];
    /**
     * 组件基础信息
     */
    widgetInfos = [];
    /**
     * 保存工具条需要实例信息
     */
    toolbarInfo = {};
    /**
     * 保存是否创建工具条实例信息
     */
    isCreateWidgetInstanceInfos = false;
    /**
     * 保存实例信息
     */
    toolPosition = {
        left: 0,
        top: 0,
        isShow: false,
    };
    /**
     * 添加一个tab页面时添加相关页面的默认信息
     * @param tabId
     */
    addTab(state) {
        const { tabId } = state;
        const tab = {
            tabId,
            widgetInstanceInfos: [],
            widgetInfos: [],
        };
        this.tabs.push(tab);
    }
    /**
     * 清空 Store 数据
     * @param tabId
     */
    emptyStore(state) {
        if (!state || !state.tabId) {
            this.tabs.splice(0);
            return;
        }
        const { tabId } = state;
        const tab = this.tabs.find(p => p.tabId === tabId);
        if (!tab) {
            return;
        }
        tab.widgetInstanceInfos.splice(0);
        this.widgetInfos.splice(0);
    }
    /**
     * 移除一个tab
     * @param tabId
     */
    removeTab(state) {
        const { tabId } = state;
        const tabIndex = this.tabs.findIndex(p => p.tabId === tabId);
        ~tabIndex && this.tabs.splice(tabIndex, 1);
    }
    setWidgetInfos(state) {
        const { data } = state;
        const { widgetInfos } = this;
        widgetInfos.splice(0);
        data && widgetInfos.push(...data.map(item => new WidgetEntity(item)));
    }
    setWidgetInstanceInfos(state) {
        const { tabId, data } = state;
        const tab = this.tabs.find(p => p.tabId === tabId);
        if (!tab) {
            return;
        }
        // 不存在组件实例则清除
        tab.widgetInstanceInfos.splice(0);
        if (data && data.length) {
            tab.widgetInstanceInfos.push(...data.map(item => new WidgetInstanceEntity(item)));
        }
    }
    /**
     * 添加单个组件实例
     */
    addWidgetInstanceInfo(state) {
        const { tabId, data } = state;
        const tab = this.tabs.find(p => p.tabId === tabId);
        if (!tab) {
            return;
        }
        if (!data || JSON.stringify(data) === '{}' || !data.pkid) {
            return;
        }
        tab.widgetInstanceInfos.push(data);
    }
    /**
     * 更新单个组件实例
     */
    updateWidgetInstanceInfo(state) {
        const { tabId, data } = state;
        const tab = this.tabs.find(p => p.tabId === tabId);
        if (!tab) {
            return;
        }
        if (!data || JSON.stringify(data) === '{}' || !data.pkid) {
            return;
        }
        const widgetInstanceIndex = tab.widgetInstanceInfos.findIndex(item => item.pkid === data.pkid);
        const oldWidgetInstance = tab.widgetInstanceInfos[widgetInstanceIndex];
        tab.widgetInstanceInfos.splice(widgetInstanceIndex, 1, Object.assign({}, oldWidgetInstance, data));
    }
    /**
     * 根据列 pkId 删除实例
     * @param columnPkId
     */
    deleteWidgetInstanceInfo(state) {
        const { tabId, columnPkIds } = state;
        const tab = this.tabs.find(p => p.tabId === tabId);
        if (!tab) {
            return;
        }
        if (!columnPkIds || !columnPkIds.length) {
            return;
        }
        let newWidgetInstanceInfos = [];
        tab.widgetInstanceInfos.forEach(item => {
            if (columnPkIds.indexOf(item.columnPkId) === -1) {
                newWidgetInstanceInfos.push(item);
            }
        });
        store.setWidgetInstanceInfos({
            tabId,
            data: newWidgetInstanceInfos,
        });
    }
    /**
     * 保存当前工具条所需实例信息
     */
    setSaveToolbarInfo(state) {
        const { data } = state;
        this.toolbarInfo = data;
    }
    /**
     * 创建工具条
     */
    createToolbar() {
        this.isCreateWidgetInstanceInfos = true;
    }
    /**
     * 工具条位置
     */
    setToolbarPosition(state) {
        this.toolPosition = {
            left: state.left,
            top: state.top,
            isShow: state.isShow,
        };
    }
};
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "addTab", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "emptyStore", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "removeTab", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "setWidgetInfos", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "setWidgetInstanceInfos", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "addWidgetInstanceInfo", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "updateWidgetInstanceInfo", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "deleteWidgetInstanceInfo", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "setSaveToolbarInfo", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "createToolbar", null);
__decorate([
    Mutation
], PiBaseStoreWidget.prototype, "setToolbarPosition", null);
PiBaseStoreWidget = __decorate([
    Module({
        namespaced: true,
        dynamic: true,
        store: Store,
        name: 'piBaseWidget',
    })
], PiBaseStoreWidget);
export { PiBaseStoreWidget };
const store = getModule(PiBaseStoreWidget);
export { store as PiBaseStoreWidgetModule };
