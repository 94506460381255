var PiBaseGraphicsConfigurationSvg_1;
import { __decorate } from "tslib";
import { BcVersion, VueBase } from 'bc$/main';
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import PiBaseCustomDataModal from './components/pi-base-custom-data-modal/pi-base-custom-data-modal.component';
import PiBaseSvgVideoModal from './components/pi-base-svg-video-modal/pi-base-svg-video-modal.component';
import ContextMenuMixin from './mixins/context-menu.mixin';
import FullScreenMixin from './mixins/full-screen.mixin';
import NodeParseMixin from './mixins/node-parse.mixin';
import PanZoomMixin from './mixins/pan-zoom.min';
import PointToolTipMixin from './mixins/point-tooltip.mixin';
import SvgDomEventParseMixin from './mixins/svg-dom-event-parse-mixin';
import SvgPointRuleParserMixin from './mixins/svg-point-rule-parser.mixin';
import SvgTransitionMixin from './mixins/svg-transition.mixin';
import SvgWebsocketMixin from './mixins/svg-websocket.mixin';
import VueComputedMixin from './mixins/vue-computed.mixin';
import VueDataMixin from './mixins/vue-data.mixin';
import VueHookMixin from './mixins/vue-hook.mixin';
import VueMethodsMixin from './mixins/vue-methods.mixin';
import VuePropMixin from './mixins/vue-prop.mixin';
import VueWatchMixin from './mixins/vue-watch.mixin';
import { DYN_DATA_DEFAULT_DISPLAY_VALUE } from './pi-base-graphics-configuration-svg.constant';
import template from './pi-base-graphics-configuration-svg.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-graphics-configuration-svg.component.scss';
let PiBaseGraphicsConfigurationSvg = PiBaseGraphicsConfigurationSvg_1 = class PiBaseGraphicsConfigurationSvg extends Mixins(VueBase, WindowEventMixin, VuePropMixin, VueDataMixin, VueComputedMixin, VueMethodsMixin, VueHookMixin, VueWatchMixin, FullScreenMixin, PanZoomMixin, ContextMenuMixin, SvgTransitionMixin, NodeParseMixin, PointToolTipMixin, SvgPointRuleParserMixin, SvgWebsocketMixin, SvgDomEventParseMixin) {
    //#region 构造函数
    // /**
    //  * Transition 参数
    //  */
    // private static TRANSITION_OPTIONS = {
    //   classPrefix: 'animate__animated animate__',
    //   enterActiveClass: 'fadeInDown',
    //   leaveActiveClass: 'fadeOutDown',
    // } as IPiTransition;
    static animationCssPrefix_v4 = 'animate__animated animate__';
    static animationCssPrefix_v3 = 'animated ';
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseGraphicsConfigurationSvg';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseGraphicsConfigurationSvg_1?.NAME,
        author: 'luot',
        title: 'SVG-组态图',
        version: BcVersion,
        updateDate: '2020-09-22',
        description: '',
    };
    /**
     * 实时数据默认显示值
     */
    static DYN_DATA_DEFAULT_DISPLAY_VALUE = DYN_DATA_DEFAULT_DISPLAY_VALUE;
    //#endregion
    //#region props
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseGraphicsConfigurationSvg_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
};
PiBaseGraphicsConfigurationSvg = PiBaseGraphicsConfigurationSvg_1 = __decorate([
    Component({
        template,
        components: {
            PiBaseSvgVideoModal,
            PiBaseCustomDataModal,
        },
    })
], PiBaseGraphicsConfigurationSvg);
export default PiBaseGraphicsConfigurationSvg;
