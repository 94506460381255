import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
let VueHookMixin = class VueHookMixin extends VueMixinBase {
    /**
     * vue 生命周期 created
     */
    created() { }
    /**
     * vue 生命周期 mounted
     */
    mounted() { }
    /**
     * vue 生命周期 beforeDestroy
     */
    beforeDestroy() {
        this.destroySvg();
    }
    /**
     * vue 生命周期 destroyed
     */
    destroyed() { }
};
VueHookMixin = __decorate([
    Component
], VueHookMixin);
export default VueHookMixin;
