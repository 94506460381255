/**
 * 向下递归设置selected
 * @param tree
 * @param selected
 */
export function setSelectionOfTree(tree, selected) {
    if (tree.state.selected !== selected) {
        tree.state.selected = selected;
    }
    if (tree.children) {
        for (const child of tree.children) {
            setSelectionOfTree(child, selected);
        }
    }
}
/**
 * 向上递归设置selected
 * @param tree
 * @param path
 */
export function setParentsSelection(tree, path) {
    const parents = [];
    const parentPath = path.slice(0, path.length - 1);
    for (const index of parentPath) {
        if (parents.length === 0) {
            parents.unshift(tree[index]);
        }
        else {
            parents.unshift(parents[0].children[index]);
        }
    }
    for (const parent of parents) {
        parent.state.selected = parent.children.every((child) => child.state.selected);
    }
}
/**
 * 向下递归设置selected为false
 * @param tree
 */
export function clearSelectionOfTree(tree) {
    if (tree.state.selected) {
        tree.state.selected = false;
    }
    if (tree.children) {
        for (const child of tree.children) {
            clearSelectionOfTree(child);
        }
    }
}
/**
 * 收集所有选择的树节点，从父到子
 */
export function collectSelectedTree(treeDataList) {
    let selectedTree = [];
    function recursion(treeDataList) {
        treeDataList.forEach((item) => {
            const { state, children } = item;
            if (state.selected) {
                selectedTree.push(item);
            }
            if (children && children.length) {
                recursion(children);
            }
        });
    }
    recursion(treeDataList);
    return selectedTree;
}
