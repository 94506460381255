import { EL_BETTER_SCROLL } from 'bc$/directives/scroll/scroll.directive';
/**
 * table 表格数据，通过设置一些特殊属性实现某些功能，如 checkbox 多选功能
 */
export class VTableData {
    /**
     * 唯一引号
     */
    _pi_guid;
    /**
     * 是否选中（当开启多选时有效）,默认值false
     */
    _checked;
    /**
     * 是否禁用选中\未选中（当开启多选时有效）,默认值false
     */
    _disabled;
}
/**
 * 表格列
 */
export class VTableColumn {
    /**
     * 唯一引号
     */
    _pi_guid;
    /**
     * 对应列的字段
     */
    field;
    /**
     * 列头显示文字
     */
    title;
    /**
     * selection，即可自动开启多选功能
     */
    type;
    /**
     * 每一列的宽度，列设置横向自适应is-horizontal-resize:true时，必须要设置值
     */
    width;
    /**
     * 表头列内容对齐方式
     */
    titleAlign;
    /**
     * 列内容对齐方式
     */
    columnAlign;
    /**
     * 用来格式化内容
     */
    formatter;
    render;
    /**
     * 自定义列传入组件的名字
     */
    componentName;
    /**
     * 表头单元格设置className
     */
    titleCellClassName;
    /**
     * 此列是否要固定,默认值false
     */
    isFrozen;
    /**
     * 此列是否要自适应，前提是设置了is-horizontal-resize:true,默认值false
     */
    isResize;
    /**
     * 排序规则,默认值
     */
    orderBy;
    /**
     * 是否启用单元格编辑,默认值false
     */
    isEdit;
    /**
     * title提示,默认值false
     */
    overflowTitle;
    /**
     * 数据过滤条件，具体格式见demo
     */
    filters;
    /**
     * 是否支持过滤条件多选,默认值false
     */
    filterMultiple;
    /**
     * 合并行的数目
     */
    rowspan;
    /**
     * 合并列的数目
     */
    colspan;
    /**
     * 此列跨几个字段
     */
    fields;
    /**
     * 显示的文本
     */
    label;
    /**
     * 当前值
     */
    value;
    /**
     * 是否选中
     */
    selected;
    /**
     * 内容
     */
    content;
    /**
     * 对齐方式
     */
    align;
}
