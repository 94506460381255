var PiBaseColumn_1;
import { __decorate } from "tslib";
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { ColumnEntity, WidgetInstanceEntity, } from 'bc$/entities/layout';
import { Checkbox } from 'iview';
import { PiBaseStoreLayoutModule } from 'bc$/store/modules/pi-base-layout.store';
import { SortableJsOptionName, LayoutMode } from 'bc$/enums/layout/layout.enum';
import { getGridTemplate, revertDom, getGridTemplatePctValuesCalc, SORTABLE_ACTIVE_CLASS_NAME, } from '../pi-base-center/pi-base-center.utils';
import { default as PiBaseCenter } from '../pi-base-center/pi-base-center.component';
import { hAjaxPostJson, UrlType, PiStatusEnum, ApiUrlSetting, AjaxAbortName, hErrorShow, } from 'bc$/main';
import { ColumnSetUpdate, } from './pi-base-column.component.entity';
import { PiBaseStoreWidgetModule } from 'bc$/store/modules/pi-base-widget.store';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import template from './pi-base-column.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-column.component.scss';
let PiBaseColumn = PiBaseColumn_1 = class PiBaseColumn extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseColumn';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseColumn_1?.NAME,
        author: 'luot',
        title: '基础布局列组件',
        version: BcVersion,
        updateDate: '2019-05-21',
        description: '',
    };
    /**
     * sortableJs 插件
     */
    static SORTABLEJS_GROUP_NAME = SortableJsOptionName.row;
    /**
     * 列最小宽度
     */
    static MIN_COLUMN_WIDTH = hReactiveUtil.convertToReactivePx(100);
    /**
     * 更新行错误信息
     */
    static UPDATE_ROW_DATA_FAIL = `Component ${PiBaseColumn_1?.NAME} update row data fail:`;
    /**
     * 更新行集合-错误信息
     */
    static UPDATE_COLUMN_SET_FAIL = `Component ${PiBaseColumn_1?.NAME} update column set fail:`;
    /**
     * 默认列宽最大值
     */
    static MAX_COLUMN_WIDTH = 24;
    //#endregion
    //#region props
    /**
     * 当前所在页面唯一页签id
     */
    tabId;
    /**
     * 列信息
     */
    column;
    /**
     * 列 index
     */
    index;
    //#endregion
    //#region data
    /**
     * 组件实例
     */
    widgetInstance = null;
    /**
     * 特殊处理虚拟滚动条问题
     */
    handledMouseWheel = false;
    /**
     * 动态组件 - 模板名
     */
    dynComponentName = '';
    /**
     * sortableJs options
     */
    columnSortableJsOptions = {
        group: {
            name: PiBaseColumn_1.SORTABLEJS_GROUP_NAME,
            put: this.sortableJsPut,
        },
        handle: '.pi-row-head',
        draggable: '.pi-widget-pi-base-row',
        easing: 'cubic-bezier(1, 0, 0, 1)',
        animation: 500,
        onStart: this.sortableJsEventStart,
        onChange: this.sortableJsEventChange,
        onEnd: this.sortableJsEventEnd,
    };
    /**
     * 列样式
     */
    childrenDomStyle = '';
    /**
     * 列 resize 指令参数
     */
    columnResizeOptions = {
        sticks: ['mr'],
        onStart: this.resizeColumnStart,
        onStop: this.resizeColumnStop,
        disabled: true,
        minWidth: PiBaseColumn_1.MIN_COLUMN_WIDTH,
    };
    //#endregion
    //#region computed
    get about() {
        return PiBaseColumn_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * store中存储的布局相关的信息
     */
    get storeLayout() {
        return PiBaseStoreLayoutModule;
    }
    /**
     * store中存储的组件相关的信息
     */
    get storeWidget() {
        return PiBaseStoreWidgetModule;
    }
    /**
     * 当前行数据
     */
    get rows() {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        const columnPkid = this.column.pkid;
        const rows = tab.Rows.filter((item) => item.columnPkId === columnPkid);
        return rows;
    }
    /**
     * 现在页面是否处于页面布局编辑模式
     */
    get isLayoutMode() {
        return this.storeLayout.layoutMode === LayoutMode.design;
    }
    /**
     * 当前页面组件实例信息清单
     */
    get widgetInstanceInfos() {
        const tab = this.storeWidget.tabs.find((p) => p.tabId === this.tabId);
        return tab.widgetInstanceInfos;
    }
    //#endregion
    //#region watch
    'watch.rows'(newVal, oldVal) {
        const { template } = getGridTemplate(newVal, true);
        // TODO: rows变化导致触发多次 待优化
        this.childrenDomStyle = `grid-template-rows: ${template};`;
    }
    /**
     * 更新 Vuex 中选中的行列信息
     */
    'watch.column.piSelected'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        // 更新Vuex中选中的行列
        this.storeLayout.updateSelectedRowsOrColumns({
            tabId: this.tabId,
        });
    }
    /**
     * 观察 isLayoutMode
     * @param newVal
     */
    'watch.isLayoutMode'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        // 更新 v-resize 指令 disabled 属性值
        this.columnResizeOptions.disabled = newVal ? false : true;
    }
    /**
     * 观察 widgetInstanceInfos,设置当前组件实例
     * @param isLayoutMode
     */
    'watch.widgetInstanceInfos'(newVal, oldVal) {
        const columnPkid = this.column.pkid;
        const result = newVal.find((item) => item.columnPkId === columnPkid);
        const wiOld = this.widgetInstance;
        if (result && wiOld && wiOld.pkid === result.pkid) {
            return;
        }
        let wiNew = null;
        if (result) {
            wiNew = new WidgetInstanceEntity(result);
        }
        this.widgetInstance = wiNew;
        if (!this.rows || !this.rows.length) {
            this.childrenDomStyle = `grid-template-rows: 1fr;`;
            this.initStopMouseWheel().catch((err) => {
                throw err;
            });
        }
        else {
            this.childrenDomStyle = '';
            this.destroyStopMouseWheel().catch((err) => {
                throw err;
            });
        }
        //生成动态组件名
        let dynComponentName = '';
        if (wiNew) {
            const widgetInfo = this.getWidgetInfo(wiNew.widgetPkId);
            if (widgetInfo) {
                dynComponentName = widgetInfo.templateName;
            }
        }
        this.dynComponentName = dynComponentName;
    }
    //#endregion
    //#region methods
    /**
     * 更新组件实例
     * @param widgetInstance 组件实例
     */
    updateWidgetInstance(widgetInstance) {
        if (widgetInstance) {
            this.storeWidget.updateWidgetInstanceInfo({
                tabId: this.tabId,
                data: widgetInstance,
            });
        }
    }
    /**
     * 获取组件
     * @param widgetPkId 组件 pkId
     */
    getWidgetInfo(widgetPkId) {
        if (!widgetPkId) {
            return;
        }
        const widgetInfos = this.storeWidget.widgetInfos;
        const result = widgetInfos.find((item) => item.pkid === widgetPkId);
        return result;
    }
    /**
     * sortableJs put方法回调
     */
    sortableJsPut(to) {
        const childComponentName = this.dynComponentName;
        const dynComponent = this.$refs.dynComponent;
        if (childComponentName === '') {
            // 1. 子组件为空，则允许拖入行/业务组件
            return [SortableJsOptionName.row, 'piWidgets'];
        }
        else if (dynComponent &&
            dynComponent['getSortableJsGroupName'] &&
            dynComponent['getSortableJsGroupName']() ===
                SortableJsOptionName.row) {
            // 2. 列允许放置多行组件
            return [SortableJsOptionName.row];
        }
        else {
            // 3. 存在业务组件，禁止拖入
            return false;
        }
    }
    /**
     * Element dragging started
     * @param event
     */
    sortableJsEventStart(event) {
        // 禁用滚动条
        this.$piDispatch(this, 'updateScrollBarState', false, 'PiBasePageLayout', true);
    }
    /**
     * Called when dragging element changes position
     * @param event
     */
    sortableJsEventChange(event) {
        event.from.classList.remove(SORTABLE_ACTIVE_CLASS_NAME);
        event.to.classList.add(SORTABLE_ACTIVE_CLASS_NAME);
    }
    /**
     * Element is dropped into the list from another list
     */
    sortableJsEventEnd(event) {
        const eleFrom = event.from;
        const draggableIndexFrom = event.oldDraggableIndex;
        const indexFrom = event.oldIndex;
        const vueFrom = eleFrom['__vue__'];
        const rowFrom = Object.assign({}, vueFrom.rows[draggableIndexFrom]);
        const eleTo = event.to;
        const draggableIndexTo = event.newDraggableIndex;
        const indexTo = event.newIndex;
        let vueTo = null;
        // 还原 sortablejs 操作的 dom, 防止和 vue 虚拟 dom 冲突
        revertDom(eleTo, eleFrom, indexTo, indexFrom);
        this.$nextTick(() => {
            let newColumnPkId = '';
            // let toTargetIsColumn = false; // 移动到的目标是否为列
            // 获取新的column.pkid
            if (eleTo['__vue__'].$data._IS_CENTER_COMPONENT) {
                newColumnPkId = PiBaseCenter.ROOT_ROWS_COLUMN_PKID;
                vueTo = eleTo['__vue__'];
            }
            else {
                // toTargetIsColumn = true;
                vueTo = eleTo['__vue__'];
                const toColumn = vueTo.column;
                newColumnPkId = toColumn.pkid;
            }
            // 更新 vuex 数据
            // const toRows = toVue.rows.slice(0);
            // let newToRows = renderRowsOrderNo(
            //   toRows,
            //   fromRow,
            //   toDraggableIndex,
            //   newColumnPkId,
            // );
            // LayoutModule.UPDATE_ROWS(newToRows);
            this.reqRowUpdate({
                pkid: rowFrom.pkid,
                columnPkId: newColumnPkId,
                orderNo: draggableIndexTo,
                attrJson: rowFrom.attrJson,
            }, () => {
                this.$nextTick(() => {
                    // 启用滚动条
                    this.$piDispatch(this, 'updateScrollBarState', true, 'PiBasePageLayout', true);
                });
            });
        });
    }
    /**
     * 更新行信息
     */
    reqRowUpdate(rowUpdateParam, cbFunc) {
        if (!rowUpdateParam || JSON.stringify(rowUpdateParam) === '{}') {
            return { instance: Promise.resolve(void 0) };
        }
        const reqUrl = ApiUrlSetting.WidgetConfig.PiBaseRow.RowUpdateUrl;
        return this.resetAjax('reqRowUpdate', () => {
            const ajaxInfo = hAjaxPostJson(this.convertUrl(reqUrl, UrlType.Api), rowUpdateParam);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    // 后台请求成功
                    const respRows = data.data && data.data.length ? data.data[0] : null;
                    if (respRows) {
                        // 将返回值和提交值合并
                        const reqRow = rowUpdateParam;
                        if (!respRows) {
                            return;
                        }
                        const cloneRespRows = respRows.map((item) => {
                            if (item.pkid === reqRow.pkid) {
                                return Object.assign({}, reqRow, item);
                            }
                            return item;
                        });
                        // 更新 Vuex 中的rows, 强行转换类型  IRowUpdateParams => RowEntity
                        this.storeLayout.updateRows({
                            tabId: this.tabId,
                            rows: cloneRespRows,
                        });
                        this.$Message.success('更新行信息成功！');
                    }
                }
                else {
                    this.$Message.error('更新行信息失败！');
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseColumn_1.UPDATE_ROW_DATA_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onUpdateRowDataError', res);
                }
            })
                .finally(() => {
                cbFunc && cbFunc();
            })
                .catch((err) => hErrorShow({
                context: this,
                err,
                showInPage: true,
                message: '更新行信息错误！',
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onUpdateRowDataError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 判断当前组件组件名称
     */
    getSortableJsGroupName() {
        return PiBaseColumn_1.SORTABLEJS_GROUP_NAME;
    }
    /**
     * resize 指令开始执行回调
     * @param resizeDom
     */
    resizeColumnStart(resizeDom) {
        // 禁用滚动条
        this.$piDispatch(this, 'updateScrollBarState', false, 'PiBasePageLayout', true);
    }
    /**
     * resize 指令执行完成回调
     * @param resizeDom
     */
    resizeColumnStop(resizeDom) {
        // 禁用滚动条
        this.$piDispatch(this, 'updateScrollBarState', true, 'PiBasePageLayout', true);
        // 处理拖拉大小
        this.handleColumnResize(resizeDom);
    }
    /**
     * 处理 template column 大小变化，同步后台数据
     * resize handler
     */
    handleColumnResize(resizeDom) {
        // 删除 resize 指令在 dom 上设置的 width 和 height
        this.resetWidthHeight(this.$el);
        const parentWidget = this.$parent;
        const parentEl = parentWidget.$el;
        const parentWidth = Number(getComputedStyle(parentEl).width.replace(/px|rem|em/g, ''));
        const parentWidthPCT = getGridTemplatePctValuesCalc(parentEl.style.gridTemplateColumns);
        const { domRectLast } = resizeDom;
        const widthNew = domRectLast.width;
        const widthNewPCT = (widthNew / parentWidth) * parentWidthPCT;
        const newWidthPCT = Math.round(widthNewPCT);
        // 更新 vuex 中当前 column 数据
        let updateColumnSet = [];
        // const currentColumn = new ColumnEntity(Object.assign({}, this.column));
        const currentColumn = new ColumnEntity(this.column);
        const attrJsonObj = currentColumn.attrObj;
        const widthPctOld = attrJsonObj.widthPct;
        // 宽度改变值
        const widthPctChanged = newWidthPCT - widthPctOld;
        //检测是否有改变
        if (widthPctChanged === 0) {
            this.$Message.error('拖动未达到最小宽度！');
            return;
        }
        attrJsonObj.widthPct = newWidthPCT;
        updateColumnSet.push(new ColumnSetUpdate({
            pkid: currentColumn.pkid,
            attrJson: currentColumn.attrJson,
        }));
        // 更新下一个兄弟节点宽度
        const nextSiblingColumn = this.getNextOrPreviousSiblingColumn();
        if (nextSiblingColumn) {
            const nextAttrJsonObj = nextSiblingColumn.attrObj;
            nextAttrJsonObj.widthPct += widthPctChanged * -1;
            updateColumnSet.push(new ColumnSetUpdate({
                pkid: nextSiblingColumn.pkid,
                attrJson: nextSiblingColumn.attrJson,
            }));
        }
        // 请求后台并更新 Vuex 数据
        this.reqColumnSetUpdate({
            pkid: this.column.rowPkId,
            columns: this.getUpdateColumnSet(updateColumnSet),
        });
    }
    /**
     * 获取所有兄弟节点，包含自身
     * @param ColumnSetUpdate
     */
    getUpdateColumnSet(columnSetUpdate) {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        const columnSetUpdatePkid = columnSetUpdate.map((item) => item.pkid);
        const parentRowId = this.column.rowPkId;
        const siblingColumns = tab.Columns.filter((item) => item.rowPkId === parentRowId);
        return siblingColumns.map((item) => {
            const columnSetUpdateIndex = columnSetUpdatePkid.indexOf(item.pkid);
            if (columnSetUpdateIndex !== -1) {
                return columnSetUpdate[columnSetUpdateIndex];
            }
            else {
                return new ColumnSetUpdate(item);
            }
        });
    }
    /**
     * 更新列信息
     */
    reqColumnSetUpdate(columnSetUpdateParams, cbFunc) {
        if (!columnSetUpdateParams ||
            JSON.stringify(columnSetUpdateParams) === '{}') {
            return {
                instance: Promise.resolve(void 0),
            };
        }
        const columnSetUpdateUrl = ApiUrlSetting.WidgetConfig.PiBaseColumn.UpdateAttrJsonUrl;
        if (!columnSetUpdateUrl) {
            return {
                instance: Promise.resolve(void 0),
            };
        }
        const reqUrl = this.convertUrl(columnSetUpdateUrl, UrlType.Api);
        return this.resetAjax('reqColumnSetUpdate', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, columnSetUpdateParams);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status !== PiStatusEnum.Success) {
                    this.$Message.error('更新列信息失败！');
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseColumn_1.UPDATE_COLUMN_SET_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onUpdateColumnSetError', res);
                    return;
                }
                // 更新 Vuex 中的 columns, 强行转换类型  IRowSetUpdate => IColumnEntity
                this.storeLayout.updateColumnsAttr({
                    tabId: this.tabId,
                    columns: columnSetUpdateParams.columns,
                });
                this.$Message.success('更新列信息成功！');
            })
                .finally(() => {
                cbFunc && cbFunc();
            })
                .catch((err) => hErrorShow({
                context: this,
                err,
                showInPage: true,
                message: '更新列信息错误！',
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onUpdateColumnSetError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 重置 dom 的高宽值
     * @param ele
     */
    resetWidthHeight(ele) {
        if (!ele) {
            return;
        }
        ele.style.removeProperty('height');
        ele.style.removeProperty('width');
    }
    /**
     * 获取下一个或者上一个兄弟节点 column 信息
     *
     */
    getNextOrPreviousSiblingColumn() {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        const curColumn = this.column;
        const siblingColumns = tab.Columns.filter((item) => item.rowPkId === curColumn.rowPkId);
        const curColumnIndex = siblingColumns.findIndex((item) => item.pkid === curColumn.pkid);
        const siblingColumnsLen = siblingColumns.length;
        let col = null;
        if (curColumnIndex <= siblingColumnsLen - 2) {
            // 存在下一个数据
            col = siblingColumns[curColumnIndex + 1];
        }
        else if (siblingColumnsLen > 1) {
            // 最后一个数据则取前一个
            col = siblingColumns[curColumnIndex - 1];
        }
        return (col && new ColumnEntity(col)) || null;
    }
    /**
     * 虚拟滚动条 - 列内的原生滚轮事件特殊处理
     * @param e
     */
    stopPropagation(e) {
        this.handledMouseWheel = true;
        e.stopPropagation();
    }
    /**
     * 虚拟滚动条-特殊处理，内部原生滚动条无法触发
     */
    async initStopMouseWheel() {
        await this.$nextTick();
        this.destroyStopMouseWheel().catch((err) => {
            throw err;
        });
        if (this.handledMouseWheel) {
            return;
        }
        const cmpEl = this.$el.querySelector('.pi-base-column-content');
        cmpEl && cmpEl.addEventListener('wheel', this.stopPropagation);
    }
    /**
     * 销毁滚动条特殊处理
     */
    async destroyStopMouseWheel() {
        await this.$nextTick();
        const cmpEl = this.$el.querySelector('.pi-base-column-content');
        cmpEl && cmpEl.removeEventListener('wheel', this.stopPropagation);
    }
    //#endregion
    //#region hook
    beforeDestroy() {
        this.destroyStopMouseWheel().catch((err) => {
            throw err;
        });
    }
};
__decorate([
    Prop({ required: true })
], PiBaseColumn.prototype, "tabId", void 0);
__decorate([
    Prop({
        required: true,
    })
], PiBaseColumn.prototype, "column", void 0);
__decorate([
    Prop({ default: -1 })
], PiBaseColumn.prototype, "index", void 0);
__decorate([
    Watch('rows', { immediate: true, deep: true })
], PiBaseColumn.prototype, "watch.rows", null);
__decorate([
    Watch('column.piSelected')
], PiBaseColumn.prototype, "watch.column.piSelected", null);
__decorate([
    Watch('isLayoutMode', { immediate: true })
], PiBaseColumn.prototype, "watch.isLayoutMode", null);
__decorate([
    Watch('widgetInstanceInfos', { immediate: true, deep: true })
], PiBaseColumn.prototype, "watch.widgetInstanceInfos", null);
PiBaseColumn = PiBaseColumn_1 = __decorate([
    Component({
        components: {
            Checkbox,
        },
        template,
    })
], PiBaseColumn);
export default PiBaseColumn;
