var PiBaseEchartsPie_1;
import { __decorate } from "tslib";
import { BcVersion, hODeepObjectMerge } from 'bc$/main';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import PiBaseEcharts from '../pi-base-echarts.base';
let PiBaseEchartsPie = PiBaseEchartsPie_1 = class PiBaseEchartsPie extends Mixins(PiBaseEcharts) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEchartsPie';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEchartsPie_1?.NAME,
        author: 'hex',
        title: '图表-饼',
        version: BcVersion,
        updateDate: '2020-07-21',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 内圆色值，默认透明
     */
    centerPieColor;
    /**
     * 内圆距离饼图内圈的距离（单位取决与series的radius是pixel or percent）
     */
    centerPieGap;
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEchartsPie_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     * 生成图表数据
     * @param data
     */
    generateChartData(newOptions) {
        // 补齐series
        if (newOptions.series) {
            newOptions.series = newOptions.series.map((item) => {
                return hODeepObjectMerge(this.getSeriesItem(), item);
            });
            // center pie
            const minRadius = this.getMinRadius(newOptions.series);
            newOptions.series.push(this.getCenterPie(minRadius));
        }
        const mergedOption = hODeepObjectMerge(this.getEchartsOptions(), newOptions);
        this.localOption = mergedOption;
    }
    /**
     * 获取最新radius
     * @param series
     */
    getMinRadius(series) {
        let minRadiusPercent = '';
        let minRadiusNumber = 0;
        series.forEach((item) => {
            const lastRadiusValue = item.radius[0];
            // 仅处理百分比
            if (typeof lastRadiusValue == 'string' && ~lastRadiusValue.indexOf('%')) {
                let minRadius = this.getNumberOfPercent(minRadiusPercent);
                let radius = this.getNumberOfPercent(lastRadiusValue);
                if (!minRadius || radius < minRadius) {
                    minRadiusPercent = lastRadiusValue;
                }
            }
            // 处理数字
            else if (typeof lastRadiusValue == 'number') {
                if (minRadiusNumber == 0 || lastRadiusValue < minRadiusNumber) {
                    minRadiusPercent = lastRadiusValue;
                }
            }
        });
        if (minRadiusPercent && minRadiusNumber) {
            const echartsInstance = this.getEchartsInstance();
            const minWrapWidth = Math.min(echartsInstance.getWidth(), echartsInstance.getHeight());
            const minRadiusPercentActualPixel = (this.getNumberOfPercent(minRadiusPercent) / 100) * minWrapWidth;
            return Math.min(minRadiusPercentActualPixel, minRadiusNumber);
        }
        return minRadiusPercent || minRadiusNumber;
    }
    /**
     * 获取图表Bar默认option
     */
    getEchartsOptions() {
        return {
            title: {
                left: 'center',
                top: 'middle',
                itemGap: 16,
                textStyle: {
                    color: '#fff',
                    fontSize: 14,
                },
                subtextStyle: {
                    color: '#00A6FF',
                    fontSize: 24,
                },
            },
            tooltip: {
                show: true,
            },
            series: [],
        };
    }
    /**
     * 获取Series Bar
     */
    getSeriesItem() {
        return {
            type: 'pie',
            radius: ['65%', '85%'],
            label: {
                show: false,
            },
            labelLine: {
                show: false,
            },
            animation: false,
            data: [],
        };
    }
    getNumberOfPercent(percent) {
        return Number(percent.replace('%', ''));
    }
    /**
     * 获取内圆配置
     * @param centerPie
     */
    getCenterPie(minRadius) {
        if (minRadius) {
            if (typeof minRadius == 'string') {
                minRadius =
                    this.getNumberOfPercent(minRadius) - this.centerPieGap + '%';
            }
            else if (typeof minRadius == 'number') {
                minRadius = minRadius - this.centerPieGap;
            }
        }
        return {
            type: 'pie',
            radius: [0, minRadius || '55%'],
            data: [
                {
                    value: 0,
                    itemStyle: {
                        color: this.centerPieColor,
                    },
                },
            ],
            label: {
                show: false,
            },
            labelLine: {
                show: false,
            },
            animation: false,
            tooltip: {
                show: false,
            },
        };
    }
};
__decorate([
    Prop({ type: String, default: 'transparent' })
], PiBaseEchartsPie.prototype, "centerPieColor", void 0);
__decorate([
    Prop({ type: Number, default: 10 })
], PiBaseEchartsPie.prototype, "centerPieGap", void 0);
PiBaseEchartsPie = PiBaseEchartsPie_1 = __decorate([
    Component
], PiBaseEchartsPie);
export default PiBaseEchartsPie;
