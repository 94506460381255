import { addListener } from './listener';
/**
 * 根据传入的正则过滤掉指定的字符,绑定
 * @param el
 * @param regStr
 */
export const regexFilter = (el, regStr, tagName) => {
    const vnode = el.__vue__;
    const reg = new RegExp(regStr);
    const callback = () => {
        if (tagName === 'Input') {
            const value = vnode.value.replace(reg, '');
            vnode.$emit('input', value);
        }
        else {
            el.value = el.value.replace(reg, '');
        }
    };
    if (tagName === 'Input') {
        vnode.$on('on-keyup', callback);
        vnode.$on('on-blur', callback);
    }
    else {
        addListener(el, 'keyup', callback);
        addListener(el, 'blur', callback);
        el.__input_filter__ = {
            keyup: callback,
            blur: callback,
        };
    }
};
