var PiBaseForgetPassword_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { ApiUrlSetting, hAjaxPostJson, hCrypSha256, hErrorShow, PiStatusEnum, UrlType, } from 'bc$/main';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import { Button, Form, FormItem, Input } from 'iview';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import template from './pi-base-forget-password.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-forget-password.component.scss';
let PiBaseForgetPassword = PiBaseForgetPassword_1 = class PiBaseForgetPassword extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseForgetPassword';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseForgetPassword_1?.NAME,
        author: 'wuzp',
        title: '修改密码',
        version: BcVersion,
        updateDate: '2019-10-08',
        description: '',
    };
    //#endregion
    //#region props
    //#endregion
    //#region data
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    //#region 函数类属性
    //#endregion
    //#endregion
    /**
     * 是否错误提示
     */
    isShowErrorNotice = false;
    /**
     * 错误提示
     */
    noticeText = '';
    /**
     * 是否禁止
     */
    isDisable = true;
    /**
     * 验证码间隔
     */
    codeIntervalSecond = 120;
    /**
     * 倒计时-发送验证码
     */
    countDownNumber = 0;
    /**
     * 表单参数
     */
    formData = {
        telephoneNumber: '',
        telephoneVerificationCode: '',
        newPassword: '',
        newPasswordCheck: '',
    };
    /**
     * 表单验证
     */
    ruleValidate = {};
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseForgetPassword_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region 函数类属性
    /**
     * 表单密码验证
     */
    validateNewPassword = (rule, value, callback) => {
        let reg = /\s+/g;
        if (!(value.length >= 6 && value.length <= 20)) {
            callback(new Error('验证密码长度 6 - 20位'));
        }
        else if (reg.test(value)) {
            callback(new Error('密码不能包含空格、制表符、换行符'));
        }
        else {
            callback();
        }
    };
    //密码验证
    validateNewPasswordCheck = (rule, value, callback) => {
        if (this.formData.newPassword !== value) {
            callback(new Error('两次输入密码不一致'));
        }
        else {
            callback();
        }
    };
    //#endregion
    //#region watch
    'watch.formData'(newVal, oldVal) {
        let isNotHasEmpty = true;
        for (let item in this.formData) {
            if (!this.formData[item]) {
                isNotHasEmpty = false;
            }
        }
        //验证表单是否正确
        if (isNotHasEmpty) {
            this.$refs['formValidate']
                .validate((valid) => {
                if (valid) {
                    this.isDisable = !isNotHasEmpty;
                }
            })
                .catch((err) => {
                console.log(err);
            });
        }
    }
    /**
     * 获取验证码
     */
    handleVerificationCode() {
        this.$refs['formValidate'].validateField('telephoneNumber', (res) => {
            if (res) {
                this.$Message.error('手机号码格式错误');
                return;
            }
            else {
                this.validTelExists();
            }
        });
    }
    /**
     * 验证手机号码是否存在
     */
    validTelExists() {
        return this.resetAjax('validTelExists', () => {
            let params = {
                userPhone: this.formData.telephoneNumber,
            };
            const url = this.convertUrl(ApiUrlSetting.AccountInfo.ValidSmsCode, UrlType.Api);
            const ajaxInfo = hAjaxPostJson(url, params);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    this.startCountDown(); // 倒计时
                    this.$Message.success('获取验证码成功');
                }
                else {
                    this.$Message.error(data.msg);
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
            }));
            return ajaxInfo;
        });
    }
    /**
     * 获取验证码
     */
    getVerificationCode() {
        return this.resetAjax('getVerificationCode', () => {
            let params = {
                userPhone: this.formData.telephoneNumber,
            };
            const url = this.convertUrl(ApiUrlSetting.AccountInfo.SendSmsCode, UrlType.Api);
            const ajaxInfo = hAjaxPostJson(url, params);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                console.log(data.status, PiStatusEnum.Success, 'res.data222');
                if (data.status === PiStatusEnum.Success) {
                    this.startCountDown(); // 倒计时
                    this.$Message.success('获取验证码成功');
                }
                else {
                    this.$Message.error('获取验证码失败');
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
            }));
            return ajaxInfo;
        });
    }
    /**
     * 验证码-倒计时
     */
    startCountDown() {
        this.countDownNumber = this.codeIntervalSecond;
        const countDown = () => {
            this.resetTimeOut('startCountDown' + this.countDownNumber, () => {
                this.countDownNumber--;
                if (this.countDownNumber > 0) {
                    countDown();
                }
            }, 1000);
        };
        countDown();
    }
    /**
     * 提交表单
     */
    handleSubmit() {
        return this.resetAjax('handleSubmit', () => {
            let params = {
                userPhone: this.formData.telephoneNumber,
                verificationCode: this.formData.telephoneVerificationCode,
                newPassword: hCrypSha256(this.formData.newPassword),
            };
            const url = this.convertUrl(ApiUrlSetting.AccountInfo.ForgetPassword, UrlType.Api);
            const ajaxInfo = hAjaxPostJson(url, null, { params });
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    this.isShowErrorNotice = false;
                    this.$emit('on-reset-password-success');
                    this.$Message.success('重置密码成功');
                }
                else if (data.status === PiStatusEnum.OtherError) {
                    this.isShowErrorNotice = true;
                    this.noticeText = data.msg;
                    this.isDisable = true;
                    this.$Message.error('密码修改失败');
                }
                else {
                    this.$Message.error('密码修改失败');
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
            }));
            return ajaxInfo;
        });
    }
    //#endregion
    //#region methods
    //#endregion
    //#region hooks
    created() {
        //设置数据源弹窗显示的可选项内容
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
        this.ruleValidate = {
            telephoneNumber: [
                { required: true, message: '手机号码不能为空', trigger: 'blur' },
                {
                    type: 'string',
                    pattern: /^1\d{10}$/,
                    message: '手机号码格式错误',
                    trigger: 'blur',
                },
            ],
            telephoneVerificationCode: [
                { required: true, message: '验证码不能为空', trigger: 'blur' },
            ],
            newPassword: [
                { required: true, message: '新密码不能为空', trigger: 'blur' },
                { validator: this.validateNewPassword, trigger: 'blur' },
            ],
            newPasswordCheck: [
                { required: true, message: '验证密码不能为空', trigger: 'blur' },
                { validator: this.validateNewPasswordCheck, trigger: 'blur' },
            ],
        };
    }
};
__decorate([
    Watch('formData', { deep: true })
], PiBaseForgetPassword.prototype, "watch.formData", null);
PiBaseForgetPassword = PiBaseForgetPassword_1 = __decorate([
    Component({
        components: {
            Form,
            FormItem,
            Input,
            Button,
        },
        template,
    })
], PiBaseForgetPassword);
export default PiBaseForgetPassword;
