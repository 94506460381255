export default {
    name: 'PiBaseEasyTableColumnCustom',
    functional: true,
    props: {
        render: Function,
        rowData: Object,
        index: Number,
        field: String,
    },
    render: (h, ctx) => {
        const params = {
            rowData: ctx.props.rowData,
            index: ctx.props.index,
            field: ctx.props.field,
        };
        return ctx.props.render?.(h, params, ctx);
    },
};
