import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { MixinData, } from '../pi-base-graphics-configuration-svg.entity';
let VueDataMixin = class VueDataMixin extends MixinData {
    svgContentEl = null;
    svgContent = '';
    svgJson = {};
    ptPathData = {};
    svgContainerClass = 'pi-svg-container';
    svgContainerEl = null;
    isLoading = false;
    svgViewBox = '';
    isMoveByMiddle = false;
    contextMenu = {
        isFullScreenSvg: false,
    };
    runConfData = {};
    videoModal = {
        id: '',
        videoControlUrl: '',
        videoInfoUrl: '',
        pointAnimateData: {},
        isShow: false,
    };
    ptPathSubscriptData = {};
};
VueDataMixin = __decorate([
    Component
], VueDataMixin);
export default VueDataMixin;
