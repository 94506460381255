/**
 * 组件配置
 */
export class AccountInfoEntity {
    /**
     * 登录地址
     */
    Login;
    /**
     * 登录地址
     */
    Logout;
    /**
     * 刷新token地址
     */
    RefreshToken;
    /**
     * 忘记密码地址
     */
    ForgetPassword;
    /**
     * 获取手机验证码
     */
    SendSmsCode;
    /**
     * 修改密码地址
     */
    ModifyPassword;
    /**
     * 有校验号码
     */
    ValidSmsCode;
}
