/**
 * 组件配置
 */
export class WidgetConfigEntity {
    /**
     * 曲线折线组件
     */
    CurvePolyline;
    /**
     * Header 组件 - PiBaseHead
     */
    PiBaseHead;
    /**
     * 表格组件 - PiBaseTable
     */
    PiBaseTable;
    /**
     * Center 组件 - PiBaseCenter
     */
    PiBaseCenter;
    /**
     * 列组件 - PiBaseColumn
     */
    PiBaseColumn;
    /**
     * 行组件 - PiBaseRow
     */
    PiBaseRow;
    /**
     * 工具栏组件 - PiBaseToolBar
     */
    PiBaseToolBar;
    PiBasePageLayout;
    /**
     * 页面列表组件列表
     */
    PageListMenus;
    /**
     * 组件菜单列表
     */
    ComponentMenus;
    /**
     * 组件实例
     */
    ComponentInstance;
}
