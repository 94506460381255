var PiBasePageLayout_1;
import { __decorate } from "tslib";
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { EL_BETTER_SCROLL } from 'bc$/directives/scroll';
// import debounce from 'lodash/debounce';
import { PiBaseStoreUserModule } from 'bc$/store';
import template from './pi-base-page-layout.view.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-page-layout.view.scss';
let PiBasePageLayout = PiBasePageLayout_1 = class PiBasePageLayout extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBasePageLayout';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBasePageLayout_1?.NAME,
        author: 'luot',
        title: '基础布局组件',
        version: BcVersion,
        updateDate: '2019-05-21',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 左侧区域-显示/隐藏
     */
    showLeftLayout;
    /**
     * 底部区域-显示/隐藏
     */
    showBottomLayout;
    /**
     * 头部区域-显示/隐藏
     */
    showHeadLayout;
    /**
     * 面包屑区域-显示/隐藏
     */
    showBreadcrumbLayout;
    /**
     * 工具栏区域-显示/隐藏
     */
    showToolBarLayout;
    /**
     * 全屏/非全屏
     */
    isFullScreen;
    /**
     * 右侧区域-显示/隐藏
     */
    showRightLayout;
    /**
     * 右侧布局宽度
     */
    rightLayoutWidth;
    //#endregion
    //#region data
    isShowProperty = false;
    options = {
        titlePre: '',
        title: PiBasePageLayout_1.ABOUT.title,
        titleSuf: '',
        isShowDownLoad: false,
    };
    /**
     * 当前页的父节点信息
     */
    currentPageParents = [];
    // /**
    //  * head 组件所需 prop 参数 - systemInfo
    //  */
    // headerSystemInfo = {} as PiBaseHeadSDataEntity;
    //#endregion
    //#region computed
    get about() {
        return PiBasePageLayout_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * 是否超级管理员
     */
    get isSuperAdmin() {
        return !!Number(PiBaseStoreUserModule.hasAdmin);
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     * 更新滚动条状态, 多用于其他指令执行时禁用滚动条
     * @param state
     */
    updateScrollBarState(state = true) {
        const centerLayoutScrollBarInstance = this.$refs.centerLayout[EL_BETTER_SCROLL];
        if (centerLayoutScrollBarInstance) {
            // 特殊处理更新后鼠标事件仍触发滚动条移动，升级 better-scroll 可能导致该方法失效
            centerLayoutScrollBarInstance['initiated'] = undefined;
            !!state
                ? centerLayoutScrollBarInstance.enable()
                : centerLayoutScrollBarInstance.disable();
        }
    }
    // /**
    //  * 更新所有页面信息
    //  *
    //  * @param data
    //  */
    // private updateAllPageInfo(data: IPageData[]) {
    //   // 存储所有页面信息到 VueX
    //   PiBasePageModule.SET_ALL_PAGES(data);
    // }
    // /**
    //  * 更新当前页面信息
    //  * @param data
    //  */
    // private updateCurrentPageInfo(data: IPageData) {
    //   console.warn('updateCurrentPageInfo: ', JSON.stringify(data));
    //   if (data && data.pkid) {
    //     PiBasePageModule.addPage(data);
    //   }
    //   // 更新面包屑组件 prop
    //   this.currentPageParents = PiBasePageModule.GET_PAGE_PARENTS();
    // }
    /**
     * 窗体大小变更处理器
     */
    // private windowResizeHandler() {
    //   this.$piBroadcast(this, 'onWindowResize', undefined, undefined, true);
    // }
    //#endregion
    //#region hooks
    mounted() {
        // 监听滚动条状态变化事件
        this.$on('updateScrollBarState', this.updateScrollBarState);
        // window.addEventListener('resize', debounce(this.windowResizeHandler, 200));
    }
};
__decorate([
    Prop({
        default: true,
    })
], PiBasePageLayout.prototype, "showLeftLayout", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBasePageLayout.prototype, "showBottomLayout", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBasePageLayout.prototype, "showHeadLayout", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBasePageLayout.prototype, "showBreadcrumbLayout", void 0);
__decorate([
    Prop({
        default: false,
    })
], PiBasePageLayout.prototype, "showToolBarLayout", void 0);
__decorate([
    Prop({
        default: false,
    })
], PiBasePageLayout.prototype, "isFullScreen", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBasePageLayout.prototype, "showRightLayout", void 0);
__decorate([
    Prop({
        default: 0,
    })
], PiBasePageLayout.prototype, "rightLayoutWidth", void 0);
PiBasePageLayout = PiBasePageLayout_1 = __decorate([
    Component({
        template,
    })
], PiBasePageLayout);
export default PiBasePageLayout;
