/**
 * 清空二级数组内容
 * @param arr 传入待处理的数组
 */
function hAClearArray(...arr) {
    arr.forEach((a) => {
        if (Array.isArray(a)) {
            a.splice(0, a.length);
        }
    });
}
/**
 * 清空对象里面的所有数组，用于对象extend合并之前
 * @param arr 对象列表
 */
function hAClearArrayOfObject(arr) {
    if (!Array.isArray(arr)) {
        arr = [arr];
    }
    const arrNew = arr;
    for (let i = 0, length = arrNew.length; i < length; i++) {
        const obj = arrNew[i];
        for (const key in obj) {
            const value = obj[key];
            if (Array.isArray(value)) {
                value.splice(0, value.length);
            }
            else if (typeof value === 'object') {
                hAClearArrayOfObject(value);
            }
        }
    }
}
/**
 *清空数组中的子孙数组
 * @param {Array} arr 数组
 */
function hARemoveArrChild(arr) {
    if (!Array.isArray(arr)) {
        return;
    }
    let _index = 0;
    let _arr = arr && [...arr];
    _arr &&
        _arr.forEach((key) => {
            if (Array.isArray(key)) {
                hARemoveArrChild(key);
            }
            else {
                arr && arr.splice(_index, 1);
                _index -= 1;
            }
            _index += 1;
        });
}
/**
 * 按回调结果删除数组项
 * @param {Array} arr 数组
 * @param {Array} predicate 回调函数,用来确定是否需要删除当前项
 */
function hARemoveArrItem(arr, predicate) {
    const removeds = [];
    for (let i = arr.length - 1; i >= 0; i--) {
        if (predicate(arr[i], i)) {
            removeds.push(...arr.splice(i, 1));
        }
    }
    return removeds;
}
/**
 * 根据值从数组中删除
 * @param arr 待处理的数组
 * @param val 待删除的值
 */
function hARemoveByValue(arr, val) {
    const removeds = [];
    for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i] == val) {
            removeds.push(...arr.splice(i, 1));
        }
    }
    return removeds;
}
export { hAClearArray, hAClearArrayOfObject, hARemoveArrChild, hARemoveArrItem, hARemoveByValue, };
