var PiBaseFullCalendarBody_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase, hODeepClone, BcVersion } from 'bc$/main';
import template from './pi-base-full-calendar-body.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-full-calendar-body.component.scss';
import { dateFunc } from '../dataMap/dateFunc';
let PiBaseFullCalendarBody = PiBaseFullCalendarBody_1 = class PiBaseFullCalendarBody extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    // public static NAME = 'PiBaseFullCalendarBody';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: 'PiBaseFullCalendarBody',
        author: 'zhanjx',
        title: '无标题',
        version: BcVersion,
        updateDate: '2021-11-03',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 节假日
     */
    holidayArr;
    /**
     * 当前日期
     */
    currentDate;
    /**
     * 事件集
     */
    events;
    /**
     * 周一到周日名称
     */
    weekNames;
    /**
     * 一月到十二月名称
     */
    monthNames;
    /**
     * 第一天
     */
    firstDay;
    //#endregion
    //#region data
    // weekNames : DAY_NAMES,
    weekMask = [1, 2, 3, 4, 5, 6, 7];
    // events : [],
    isLismit = true;
    // 最多显示的数据行
    eventLimit = 4;
    showMore = false;
    showDuty = false;
    morePos = {
        top: 0,
        left: 0,
    };
    dutyPos = {
        top: 0,
        left: 0,
    };
    selectDay = {};
    selectEvent = {};
    cacheEvent = null;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseFullCalendarBody_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    /**
     * 获取当前日期
     */
    get currentDates() {
        return this.getCalendar();
    }
    //#endregion
    //#region 函数类属性
    //#endregion
    //#region watch
    'watch.showDuty'(newValue) {
        // console.log('watch weekNames', newValue);
        if (!newValue) {
            if (!!this.cacheEvent) {
                this.$set(this.cacheEvent, 'selected', false);
            }
        }
    }
    //#endregion
    //#region methods
    /**
     * 是否是开始
     * @param event
     * @param date
     * @param index
     * @returns
     */
    beginTitle(event, date, weekDay, monthDay) {
        let st = new Date(event.start);
        if (weekDay == 0 ||
            st.toDateString() == date.toDateString() ||
            monthDay == 1) {
            return event.title;
        }
        return '　';
    }
    /**
     * 进一步将单元格尽可能填满----冒泡式排序日期事件（持续时间仅为当天的往上冒泡）
     * @param dayEvents
     * @returns
     */
    sortDayEventsByBubble(dayEvents) {
        const events = hODeepClone(dayEvents);
        // 如果前四个中有某一个不显示，则往下查找将仅存在于当日的排班往往上放置
        if (events.length > 4) {
            let i = 0;
            let j = this.eventLimit;
            for (; i < this.eventLimit; i++) {
                let temp = events[i];
                // 有不显示的
                if (!events[i].isShow || events[i].cellIndex > this.eventLimit) {
                    for (; j < events.length; j++) {
                        // 交互两者位置
                        if (events[j].start == events[j].end && events[j].isShow) {
                            events[i] = events[j];
                            events[j] = temp;
                            temp = events[i].cellIndex;
                            events[i].cellIndex = events[j].cellIndex;
                            events[j].cellIndex = temp;
                            if (events[i].cellIndex > this.eventLimit) {
                                events[i].cellIndex = i + 1;
                            }
                            j++;
                            break;
                        }
                    }
                }
            }
        }
        return events;
    }
    /**
     * 判定是否为节假日
     * @param day
     * @returns
     */
    isHoliday(day) {
        if (!!day.date && day.date instanceof Date) {
            // console.log('isHoliday->', day);
            // console.log('holidayArr->', this.holidayArr);
            const dateStr = day.date.format('yyyy-MM-dd');
            return this.holidayArr.includes(dateStr);
        }
        return false;
    }
    /**
     * 更多信息弹框提示
     * @param date
     * @returns
     */
    moreTitle(date) {
        const dt = new Date(date);
        return (this.weekNames[dt.getDay()] +
            ', ' +
            this.monthNames[dt.getMonth()] +
            dt.getDate() +
            '日');
    }
    /**
     * 返回css类名称
     * @param cssClass
     * @returns
     */
    classNames(cssClass) {
        if (!cssClass) {
            return '';
        }
        // string
        if (typeof cssClass == 'string') {
            return cssClass;
        }
        // Array
        if (Array.isArray(cssClass)) {
            return cssClass.join(' ');
        }
        // else
        return '';
    }
    /**
     * 获取日历 ----周日
     * @returns
     */
    getCalendar() {
        // 计算每个月的二维数组
        // 获取当前时间
        const now = new Date();
        // 获取传入的当前时间
        const current = new Date(this.currentDate);
        // 获取当前月的第一天日期
        const startDate = dateFunc.getStartDate(current);
        // getDay方法返回指定日期是星期几（从 0 到 6）星期日为 0，星期一为 1，依此类推
        const curWeekDay = startDate.getDay();
        // 此表的开始日期可能是上个月的某一天
        let diff = parseInt(this.firstDay) - curWeekDay;
        diff = diff > 0 ? diff - 7 : diff;
        // getDate方法返回指定日期在月中的第几天（从 1 到 31）  setDate方法将月份中的某一天设置为日期对象（以当前月首日日期为基准往前回溯或往后推进多少）
        startDate.setDate(startDate.getDate() + diff);
        const calendar = [];
        // 此表最多包含6个周的日期，即上月最后一周，当月所有周以及下月第一周
        for (let perWeek = 0; perWeek < 6; perWeek++) {
            // 存储显示的周
            let week = [];
            // 星期日到星期六，生成一周的各个日期信息
            for (let perDay = 0; perDay < 7; perDay++) {
                week.push({
                    // 遍历的日期是在某月的第几天
                    monthDay: startDate.getDate(),
                    // 遍历的日期是否是当前时间日期
                    isToday: now.toDateString() == startDate.toDateString(),
                    // 遍历的日期是否落在当前时间日期的月份上
                    isCurMonth: startDate.getMonth() == current.getMonth(),
                    // 遍历的日期是星期几 （从 0 到 6）星期日为 0，星期一为 1，依此类推
                    weekDay: perDay,
                    // 精确遍历的日期
                    date: new Date(startDate),
                    // 遍历的日期的所含排班
                    events: this.slotEvents(startDate),
                });
                // 日期对象往后推进
                startDate.setDate(startDate.getDate() + 1);
            }
            // 存储此表最多包含的6个周日期数组信息
            calendar.push(week);
        }
        // 返回此表显示的日历对象
        return calendar;
    }
    /**
     * 查找从此日期开始的所有事件
     * @param date
     * @returns
     */
    slotEvents(date) {
        // 过滤一遍排班数据，找出存在于指定日期的值班数据（并且保证值班日期都含有开始和结束时间）
        const thisDayEvents = this.events.filter((day) => {
            // 字符串转日期
            let dt = new Date(day.start);
            // 进一步精准化开始日期对象
            let st = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
            // 是否存在结束日期，不存在则将开始日期昨晚结束日期
            let ed = day.end ? new Date(day.end) : st;
            // console.log('slotEvt', st, ed, date)
            return date >= st && date <= ed;
        });
        // 按持续时间排序
        thisDayEvents.sort((a, b) => {
            if (!a.cellIndex) {
                return 1;
            }
            if (!b.cellIndex) {
                return -1;
            }
            return a.cellIndex - b.cellIndex;
        });
        // 标记单元索引和位置保持的div
        for (let i = 0; i < thisDayEvents.length; i++) {
            // 原cellIndex为空则单元格索引+1作为cellIndex值
            thisDayEvents[i].cellIndex = thisDayEvents[i].cellIndex || i + 1;
            thisDayEvents[i].isShow = true;
            // 原cellIndex为空或索引大于2则跳过进行下一次循环
            if (thisDayEvents[i].cellIndex == i + 1 || i > 2) {
                continue;
            }
            // 保持位置跨列排班的div
            thisDayEvents.splice(i, 0, {
                // title: thisDayEvents[i].title,
                title: 'holder',
                cellIndex: i + 1,
                start: dateFunc.format(date, 'yyyy-MM-dd'),
                end: dateFunc.format(date, 'yyyy-MM-dd'),
                isShow: false,
            });
        }
        return thisDayEvents;
    }
    /**
     * 是否是开始时间
     * @param eventDate
     * @param date
     * @returns
     */
    isStart(eventDate, date) {
        let st = new Date(eventDate);
        return st.toDateString() == date.toDateString();
    }
    /**
     * 是否是结束时间
     * @param eventDate
     * @param date
     * @returns
     */
    isEnd(eventDate, date) {
        let ed = new Date(eventDate);
        return ed.toDateString() == date.toDateString();
    }
    /**
     * 显示当天更多的值班信息
     * @param day
     * @param jsEvent
     */
    computeShowMore(day, jsEvent) {
        this.showMore = false;
        // 过渡一下实现transition
        setTimeout(() => {
            this.selectDay = day;
            this.showDuty = false;
            this.morePos = this.computePos(jsEvent.target, 245);
            this.morePos.top -= 185;
            this.showMore = true;
            // console.log('this.morePos->', this.morePos);
            let events = day.events.filter((item) => {
                return item.isShow == true;
            });
            this.$emit('moreclick', day.date, events, jsEvent);
        }, 250);
    }
    /**
     * 查看值班详情
     * @param event
     * @param jsEvent
     * @param isChild
     * @returns
     */
    computeShowDuty(day, event, jsEvent, isChild) {
        this.showDuty = false;
        if (!event.isShow) {
            return;
        }
        // console.log('day->', day, '\t selectEvent->', event);
        // 过渡一下实现transition
        setTimeout(() => {
            if (!!this.cacheEvent) {
                this.$set(this.cacheEvent, 'selected', false);
            }
            this.cacheEvent = event;
            this.$set(this.cacheEvent, 'selected', true);
            this.selectEvent = event.data;
            jsEvent.stopPropagation();
            this.dutyPos = this.computePos(jsEvent.target, 0);
            if (!isChild) {
                this.dutyPos.left += 17;
                this.dutyPos.top -= 140;
            }
            else {
                // 周日则显示在右侧其他都显示在左侧
                const weekday = day.getDay() == 3
                    ? day.getDay() + Math.round(Math.random())
                    : day.getDay();
                if (weekday <= 3) {
                    this.dutyPos.left += 260;
                    this.dutyPos.top -= 140;
                }
                else {
                    this.dutyPos.left -= 230;
                    this.dutyPos.top -= 140;
                }
                // console.log('this.dutyPos.top->', this.dutyPos.top);
            }
            this.showDuty = true;
            this.$emit('eventclick', event, jsEvent, this.dutyPos);
        }, 250);
    }
    /**
     * 计算弹窗显示位置
     * @param target
     * @returns
     */
    computePos(target, offset) {
        let eventRect = target.getBoundingClientRect();
        let pageRect = this.$refs.dates.getBoundingClientRect();
        return {
            left: eventRect.left - pageRect.left - offset,
            top: eventRect.top + eventRect.height - pageRect.top,
        };
    }
    /**
     * 日期点击事件
     * @param day
     * @param jsEvent
     */
    dayClick(day, jsEvent) {
        this.$emit('dayclick', day, jsEvent);
    }
    /**
     * 判定有效事件的个数
     */
    judgeEffectEventLength(events) {
        const effectArr = events.filter((item) => {
            return item.isShow;
        });
        return effectArr.length;
    }
    //#endregion
    //#region hooks
    created() {
        this.events.forEach((item, index) => {
            item._id = item.id || index; // 如果无id默认索引为id
            item.end = item.end || item.start; // 如果结束日期为空则默认为
        });
        // this.events = events
    }
};
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseFullCalendarBody.prototype, "holidayArr", void 0);
__decorate([
    Prop({
        type: Date,
        default: () => new Date(),
    })
], PiBaseFullCalendarBody.prototype, "currentDate", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseFullCalendarBody.prototype, "events", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseFullCalendarBody.prototype, "weekNames", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseFullCalendarBody.prototype, "monthNames", void 0);
__decorate([
    Prop({
        type: Number,
        default: 0,
    })
], PiBaseFullCalendarBody.prototype, "firstDay", void 0);
__decorate([
    Watch('showDuty', { immediate: true, deep: true })
], PiBaseFullCalendarBody.prototype, "watch.showDuty", null);
PiBaseFullCalendarBody = PiBaseFullCalendarBody_1 = __decorate([
    Component({
        template,
    })
], PiBaseFullCalendarBody);
export default PiBaseFullCalendarBody;
