import { hSessionPublic } from 'bc$/utils/storage.helper';
import { PiStatusEnum } from 'bc$/enums';
import { hAjaxPostJson } from './ajax.helper';
import { ConvertUrl, UrlType, AjaxAbortName } from 'bc$/bases/config.base';
import { UserSessionStorage } from 'bc$/enums/user/pi-user.enum';
import { PiBaseStoreUserModule } from 'bc$/store/modules/pi-base-user.store';
import { hSessionPrivate } from './storage.helper';
import { hErrorShow } from './error.helper';
/**
 * 是否正在执行
 */
let ajaxRunning = false;
/**
 * 是否已经强制停止刷新token
 */
let isStopRefresh = false;
/**
 * 当前发起的延时执行事件
 */
let tmrRefreshToken = undefined;
/**
 * 设置是否停止刷新token
 */
function hTokenStopRefresh(isStop) {
    isStopRefresh = isStop;
}
/**
 * 移除用户相关token
 */
function hTokenRemove() {
    hTokenStopRefresh(true);
    clearTimeout(tmrRefreshToken);
    hSessionPublic.removeItem(UserSessionStorage.accessToken, UserSessionStorage.refreshToken, UserSessionStorage.expiresTime, UserSessionStorage.userPkId, UserSessionStorage.hasAdmin, UserSessionStorage.userName, UserSessionStorage.roleType);
    hSessionPrivate.removeItem(UserSessionStorage.loginInfo);
}
/**
 * 刷新token
 * @param minTime 间隔最少毫秒数
 * @param callback 回调
 */
function hTokenUpdate(minTime = 2e4, callback) {
    //上一个刷新任务在执行不继续调用
    if (ajaxRunning) {
        return;
    }
    const expiresTime = PiBaseStoreUserModule.expiresTime;
    let num = (isNaN(expiresTime) ? 0 : expiresTime) - new Date().getTime();
    const advanceRefreshTime = 60 * 5 * 1e3;
    num = Math.max(minTime, num - advanceRefreshTime);
    // 定时器延迟有最大限制，超出等同0
    num = Math.min(num, Math.pow(2, 31) - advanceRefreshTime);
    //节流
    clearTimeout(tmrRefreshToken);
    tmrRefreshToken = window.setTimeout(function () {
        if (isStopRefresh) {
            return;
        }
        const refreshToken = PiBaseStoreUserModule.refreshToken;
        const url = PiBaseStoreUserModule.refreshUrl;
        if (!refreshToken || !url) {
            console.error('更新token失败！获取不到刷新token需要的 refresh_token 或者refresh_url！');
            hTokenUpdate();
            return;
        }
        ajaxRunning = true;
        const reqUrl = ConvertUrl(url, UrlType.Api);
        hAjaxPostJson(reqUrl, {
            refreshToken,
        })
            .instance.then((res) => {
            if (!res || !res.data) {
                console.error('更新token失败！');
                return;
            }
            const sData = res.data;
            if (!sData || sData.status !== PiStatusEnum.Success) {
                console.error('更新token失败！', sData.msg);
                callback && callback();
                return;
            }
            const dData = sData.data;
            if (!dData || dData.length <= 0) {
                console.error('更新token失败！');
                callback && callback();
                return;
            }
            const result = dData[0];
            PiBaseStoreUserModule.setAccessToken({
                data: result.accessToken,
            });
            PiBaseStoreUserModule.setRefreshToken({
                data: result.refreshToken,
            });
            PiBaseStoreUserModule.setExpiresTime({
                data: new Date().getTime() + result.expiresIn * 1e3,
            });
            callback && callback(result);
        })
            .finally(() => {
            ajaxRunning = false;
            if (!isStopRefresh) {
                window.dispatchEvent(new Event('hTokenUpdate'));
                hTokenUpdate();
            }
        })
            .catch((err) => hErrorShow({
            err,
            message: '更新token错误！',
            callback: () => {
                if (err.message !== AjaxAbortName) {
                    callback && callback();
                }
            },
        }));
    }, num);
}
export { hTokenStopRefresh, hTokenRemove, hTokenUpdate };
