var PiBaseLoadingGif_1;
import { __decorate } from "tslib";
import { VueBase } from 'bc$/bases/vue.base';
import { Spin } from 'iview';
import { Component, Mixins } from 'vue-property-decorator';
import template from './pi-base-loading-gif.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-loading-gif.component.scss';
let PiBaseLoadingGif = PiBaseLoadingGif_1 = class PiBaseLoadingGif extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseLoadingGif';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseLoadingGif_1?.NAME,
        author: 'yangd',
        title: '基础加载中gif组件',
        version: require('bc$/../package.json').version,
        updateDate: '2020-12-21',
        description: '',
    };
    //#endregion
    //#region props
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseLoadingGif_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
};
PiBaseLoadingGif = PiBaseLoadingGif_1 = __decorate([
    Component({
        components: {
            Spin,
        },
        template,
    })
], PiBaseLoadingGif);
export default PiBaseLoadingGif;
