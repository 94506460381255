import { __decorate } from "tslib";
import { Store } from 'bc$/store/store';
import { getModule, Module, Mutation, VuexModule, } from 'vuex-module-decorators';
let PiBaseStoreMap = class PiBaseStoreMap extends VuexModule {
    version = '1.4.15';
    key = '';
    get url() {
        if (!this.version) {
            console.error('未配置高德Map JS key!');
        }
        return ('https://webapi.amap.com/maps' + '?v=' + this.version + '&key=' + this.key);
    }
    /**
     * 配置高德JS资源库库key,version
     * @param data
     */
    config(config) {
        const { version, key } = config;
        if (version) {
            this.version = version;
        }
        if (key) {
            this.key = key;
        }
        if (!this.version) {
            console.error('未配置高德Map JS key!');
        }
    }
};
__decorate([
    Mutation
], PiBaseStoreMap.prototype, "config", null);
PiBaseStoreMap = __decorate([
    Module({
        namespaced: true,
        dynamic: true,
        store: Store,
        name: 'piBaseStoreMap',
    })
], PiBaseStoreMap);
export { PiBaseStoreMap };
const store = getModule(PiBaseStoreMap);
export { store as PiBaseStoreMapModule };
