import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
let CheckboxSelectionMixin = class CheckboxSelectionMixin extends Vue {
    // 是否全部选中
    isAllChecked = false;
    checkboxGroupModel = [];
    indeterminate = false;
    // 禁用未选中的复选框集合
    get disabledUnChecked() {
        const that = this;
        let result = [];
        that.internalTableData.filter((item, index) => {
            if (item._disabled && !item._checked) {
                result.push(index);
            }
        });
        return result;
    }
    // 获取当前选中的行信息
    get getCheckedTableRow() {
        const that = this;
        return that.internalTableData.filter((item, index) => {
            const _index = this.checkboxGroupModel.indexOf(index);
            if (_index > -1) {
                item._checked = true;
                return item;
            }
            else {
                item._checked && (item._checked = false);
            }
        });
    }
    // 检测是否有
    get hasSelectionColumns() {
        const that = this;
        return that.internalColumns.some((x) => {
            return x.type && x.type === 'selection';
        });
    }
    // 检测复杂表头是否需要设置 checkbox
    isSelectionCol(filedArr) {
        const that = this;
        if (Array.isArray(filedArr) && filedArr.length === 1) {
            return that.internalColumns.some((x) => x.field === filedArr[0] && x.type === 'selection');
        }
        return false;
    }
    // 禁用已选中的复选框集合
    disabledChecked() {
        const that = this;
        let result = [];
        that.internalTableData.filter((item, index) => {
            if (item._disabled && item._checked) {
                result.push(index);
            }
        });
        return result;
    }
    // check all trigger event
    handleCheckAll() {
        const that = this;
        if (this.isAllChecked) {
            this.checkboxGroupModel = [];
            let allLen = that.internalTableData.length;
            if (allLen > 0) {
                for (let i = 0; i < allLen; i++) {
                    if (this.disabledUnChecked.indexOf(i) === -1) {
                        this.checkboxGroupModel.push(i);
                    }
                }
            }
        }
        else {
            this.checkboxGroupModel = this.disabledChecked();
        }
        that.selectAll?.(this.getCheckedTableRow);
        this.setIndeterminateState();
    }
    // checkbox change event
    handleCheckChange(rowData) {
        const that = this;
        this.$nextTick(() => {
            that.selectChange?.(this.getCheckedTableRow, rowData);
        });
    }
    // 处理行点击触发复选框状态改变状态
    handelRowClickCheckGroupChange(rowIndex, rowData, column) {
        // 表格多选状态
        const multipleCheck = this.multipleCheck;
        const { checkboxGroupModel } = this;
        // 判断该行是否在选中列表里
        const index = checkboxGroupModel.findIndex((item) => item === rowIndex);
        if (!multipleCheck) {
            // 单选状态
            this.checkboxGroupModel.splice(0);
            if (!~index) {
                this.checkboxGroupModel.push(rowIndex);
            }
        }
        else {
            // 多选状态
            if (!~index) {
                // 不在列表，添加
                this.checkboxGroupModel.push(rowIndex);
            }
            else {
                // 存在则移除
                this.checkboxGroupModel.splice(index, 1);
            }
        }
        this.handleCheckGroupChange();
    }
    // checkbox-group change event
    handleCheckGroupChange() {
        const that = this;
        that.selectGroupChange?.(this.getCheckedTableRow);
        this.setCheckState();
    }
    // 设置部分选中状态（全选或者取消全选时）
    setIndeterminateState() {
        const that = this;
        let checkedLen = this.checkboxGroupModel.length, allLen = that.internalTableData.length;
        // 全选
        if (checkedLen > 0 && checkedLen === allLen) {
            this.indeterminate = false;
        }
        else if (checkedLen > 0 && checkedLen < allLen) {
            // 部分选中
            this.indeterminate = true;
        }
        else {
            // 全不选
            this.indeterminate = false;
        }
    }
    // 设置选中状态
    setCheckState() {
        const that = this;
        let checkedLen = this.checkboxGroupModel.length, allLen = that.internalTableData.length;
        // 全选
        if (checkedLen > 0 && checkedLen === allLen) {
            this.indeterminate = false;
            this.isAllChecked = true;
        }
        else if (checkedLen > 0 && checkedLen < allLen) {
            // 部分选中
            this.isAllChecked = false;
            this.indeterminate = true;
        }
        else {
            // 全不选
            this.indeterminate = false;
            this.isAllChecked = false;
        }
    }
    // 修改checkbox 选中状态(table.vue 中调用)
    updateCheckboxGroupModel() {
        const that = this;
        if (!this.hasSelectionColumns) {
            return false;
        }
        this.checkboxGroupModel = [];
        that.internalTableData.filter((item, index) => {
            if (item._checked) {
                this.checkboxGroupModel.push(index);
            }
        });
        this.setCheckState();
    }
};
CheckboxSelectionMixin = __decorate([
    Component
], CheckboxSelectionMixin);
export default CheckboxSelectionMixin;
