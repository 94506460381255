import { hODeepObjectMerge, hReactiveUtil } from 'bc$/utils';
export const fnEChartsOptionsFactory = (options) => {
    return hODeepObjectMerge({
        //提示
        tooltip: {
            // 'axis' 坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
            trigger: 'axis',
        },
        grid: {
            left: hReactiveUtil.convertToReactivePx(76),
            right: hReactiveUtil.convertToReactivePx(16),
            top: hReactiveUtil.convertToReactivePx(30),
            bottom: hReactiveUtil.convertToReactivePx(60),
        },
        legend: {
            type: 'scroll',
            top: 'bottom',
            padding: [
                hReactiveUtil.convertToReactivePx(5),
                hReactiveUtil.convertToReactivePx(10),
                hReactiveUtil.convertToReactivePx(10),
                hReactiveUtil.convertToReactivePx(10),
            ],
        },
    }, options);
};
