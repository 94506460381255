/**
 * 浏览器类别
 */
// export type BrowserType = 'msie' | 'firefox' | 'chrome' | 'safari' | null;
export var BrowserType;
(function (BrowserType) {
    BrowserType["IE"] = "IE";
    BrowserType["EDGE"] = "Edge";
    BrowserType["CHROME"] = "Chrome";
    BrowserType["FIREFOX"] = "Firefox";
    BrowserType["SAFARI"] = "Safari";
})(BrowserType || (BrowserType = {}));
/**
 * 检测浏览器型号与版本相关的函数
 */
function hBIsLowVersionChrome() {
    let result = false;
    if (hBBrowserName() === BrowserType.CHROME && hBBrowserVersion() < 65) {
        result = true;
    }
    return result;
}
function hBBrowserInfo() {
    let result = null;
    //执行检测并取得结果
    let agent = navigator.userAgent.toLowerCase();
    let regIE = /(?<name>msie) (?<version>\d+)/gi, regIE11 = /(?<name>trident).*rv:(?<version>\d+)/gi, edge = /(?<name>edge)\/(?<version>\d+)/gi, regFF = /(?<name>firefox)\/(?<version>\d+)/gi, regChrome = /(?<name>chrome)\/(?<version>\d+)/gi, regSafari = /version\/(?<version>\d+).*(?<name>safari)/gi;
    // IE 11以下
    if (~agent.indexOf('msie')) {
        result = regIE.exec(agent);
        if (result.groups) {
            result.groups.name = BrowserType.IE;
        }
    }
    // IE 11
    else if (~agent.indexOf('trident')) {
        result = regIE11.exec(agent);
        if (result.groups) {
            result.groups.name = BrowserType.IE;
        }
    }
    // edge
    else if (~agent.indexOf('edge')) {
        result = edge.exec(agent);
        if (result.groups) {
            result.groups.name = BrowserType.EDGE;
        }
    }
    //firefox
    else if (~agent.indexOf('firefox')) {
        result = regFF.exec(agent);
        if (result.groups) {
            result.groups.name = BrowserType.FIREFOX;
        }
    }
    //Safari
    else if (~agent.indexOf('safari') && !~agent.indexOf('chrome')) {
        result = regSafari.exec(agent);
        if (result.groups) {
            result.groups.name = BrowserType.SAFARI;
        }
    }
    //Chrome
    else if (~agent.indexOf('chrome')) {
        result = regChrome.exec(agent);
        if (result.groups) {
            result.groups.name = BrowserType.CHROME;
        }
    }
    return {
        name: result.groups.name,
        version: Number(result.groups.version),
    };
}
function hBBrowserName() {
    let info = hBBrowserInfo();
    return info.name;
}
function hBBrowserVersion() {
    let info = hBBrowserInfo();
    return info.version;
}
export { hBIsLowVersionChrome, hBBrowserInfo, hBBrowserName, hBBrowserVersion };
