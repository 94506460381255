import { __decorate } from "tslib";
import { apiAjaxHandle } from 'bc$/bases/api.base';
import { ConvertUrl, hAjaxGet, hAjaxPost, UrlType, } from 'bc$/main';
import svgPanZoom from 'svg-pan-zoom';
import { Component } from 'vue-property-decorator';
import baseSvgShadowCss from '../pi-base-svg.shadow.scss';
import { VueMixinBase } from './vue-mixin.base';
let VueMethodsMixin = class VueMethodsMixin extends VueMixinBase {
    /**
     * 销毁 dom 事件、ws 订阅等
     */
    destroySvg() {
        // 销毁 ws 订阅
        this.unSubscribePointList();
        // 销毁 dom 事件
        this.unBindEvent();
        // 销毁 dom
        const { $el } = this;
        if ($el) {
            const svgContainer = $el.querySelector(`.${this.svgContainerClass}`);
            if (svgContainer) {
                const containerShadowRoot = svgContainer.shadowRoot;
                containerShadowRoot && (containerShadowRoot.innerHTML = '');
            }
        }
    }
    /**
     * 初始化 svg
     */
    initSvg() {
        // 销毁dom事件 、ws订阅等
        this.destroySvg();
        // 初始化数据
        this.getSvgBasicData()
            .then(() => {
            this.initSvgContainerEl();
            this.initSvgContent();
            this.initSvgRunConfData();
        })
            .catch((err) => {
            console.error(err);
        });
    }
    /**
     * svg 监听resize
     */
    onWindowResize() {
        this.pointTooltipOptions.isShow = false;
        this.$nextTick(() => {
            this.resizeSvg();
        });
    }
    /**
     * 清除所有异步请求，动作等
     */
    removeTimeoutAndAJax() {
        this.removeTimeOut(true);
        this.removeAjax(true);
    }
    // /**
    //  * 重置当前点界面的显示数据
    //  */
    // resetSvgPointDataView() {
    //   this.updateSvgDomRealTimeData(true);
    // }
    /**
     * 请求svg内容
     *
     * @param params
     * @param options
     */
    apiGetV1SubsystemCommonSvgInfo(options) {
        const { reqSvgInfoUrl } = this;
        if (!reqSvgInfoUrl) {
            console.warn('获取svg内容url未配置，请联系系统管理员！');
            return Promise.resolve(void 0);
        }
        // 请求地址
        let url_ = ConvertUrl(`${reqSvgInfoUrl}?`, UrlType.Api);
        url_ = url_.replace(/[?&]$/g, '');
        const { config = {} } = options || {};
        const ajaxInfo = hAjaxGet(url_, config);
        return apiAjaxHandle(false, ajaxInfo, '获取svg图', options).promise;
    }
    /**
     * 请求后台获取
     */
    getSvgBasicData() {
        // 获取相关庶
        this.isLoading = true;
        return this.apiGetV1SubsystemCommonSvgInfo({
            cbThen: (dData) => {
                if (!dData || !dData.length) {
                    return;
                }
                const data = dData[0];
                if (data) {
                    const { jsonContent, svgContent } = data;
                    this.svgContent = svgContent || '';
                    if (jsonContent) {
                        try {
                            this.svgJson = JSON.parse(jsonContent);
                        }
                        catch (err) {
                            console.error(`Svg json 解析异常：${err}`);
                        }
                    }
                }
            },
            cbFinally: () => {
                this.isLoading = false;
            },
        });
    }
    /**
     * 获取svg配置信息和实时数据
     *
     * @param params ajax请求需要的参数对象
     * ptPathUnion0_ptPathDev (optional) 设备信息
     * ptPathUnion0_ptPathType (optional) 类型信息
     * ptPaths (optional) 测点标识数组
     *
     * @param options 自定义配置参数
     * @return OK
     */
    apiPostV1SubsystemCommonSvgRunConf(params, options) {
        // 请求地址
        const { runConfUrl } = this.svgUrlData;
        if (!runConfUrl) {
            console.warn(`Svg runConf url 未配置, 请联系系统管理员！`);
            return Promise.reject();
        }
        const { param } = params || {};
        // 请求地址
        let url_ = ConvertUrl(runConfUrl, UrlType.Api);
        url_ = url_.replace(/[?&]$/g, '');
        const { config = {} } = options || {};
        const ajaxInfo = hAjaxPost(url_, param, config);
        return apiAjaxHandle(false, ajaxInfo, '获取svg配置信息和实时数据', options);
    }
    /**
     * 请求后台 run/conf 获取相关数据
     */
    initSvgRunConfData() {
        // 重置显示效果
        // this.resetSvgPointDataView();
        const ptPaths = this.getAllPtPathFromJson();
        const ptPathUnion = this.getPtPathUnionFromJson();
        if ((!ptPaths || !ptPaths.length) &&
            (!ptPathUnion || !ptPathUnion?.length)) {
            return;
        }
        this.apiPostV1SubsystemCommonSvgRunConf({ param: { ptPaths, ptPathUnion } }, {
            cbThen: (dData) => {
                const data = dData && dData[0];
                if (data) {
                    this.runConfData = Object.assign({}, data);
                    // 初始化相关数据
                    this.initBindData();
                    // 更新实时值
                    this.updateSvgDomRealTimeData();
                    // 订阅初始化
                    this.initSubscribePointList();
                }
                else {
                    this.runConfData = {};
                }
            },
        });
    }
    /**
     * 初始化ptPath绑定的数据
     */
    initBindData() {
        const { runConfData } = this;
        if (!runConfData) {
            return;
        }
        const { ptPathUnion, confList } = runConfData;
        // 处理子图逻辑  ptPathDstToPtPathData 数据
        if (ptPathUnion && ptPathUnion.length) {
            const ptPathDstToPtPathData = (this.ptPathDstToPtPathData =
                this.ptPathDstToPtPathData || {});
            ptPathUnion.forEach((item) => {
                const { ptPathDst, ptPathType } = item;
                if (ptPathDst !== undefined &&
                    ptPathDst !== null &&
                    ptPathType !== undefined &&
                    ptPathType !== null) {
                    ptPathDstToPtPathData[ptPathDst] = ptPathType;
                }
            });
        }
        // 处理 ptPathData
        let newPtPathData = {};
        if (confList && confList.length) {
            // tslint:disable-next-line: prefer-for-of
            for (let index = 0, len = confList.length; index < len; index++) {
                const item = confList[index];
                const { ptPath } = item;
                if (ptPath === undefined || ptPath === null) {
                    continue;
                }
                // 获取对应 dom 信息
                const animates = this.getAnimationDataByPtPath(ptPath);
                newPtPathData[ptPath] = {
                    configuration: item,
                    animates,
                };
            }
        }
        if (newPtPathData && Object.keys.length) {
            this.ptPathData = newPtPathData;
        }
    }
    /**
     * 根据ID获取对应的动画数据
     *
     * @param id
     * @param dnyType
     */
    getDynDataByiIdType(id, dnyType) {
        const { svgJsonListDraw } = this;
        if (!svgJsonListDraw ||
            id === undefined ||
            id === null ||
            dnyType === undefined ||
            dnyType === null) {
            return;
        }
        let res = undefined;
        svgJsonListDraw.some((item) => {
            const { dyn } = item;
            if (item.id === id) {
                res = dyn.listDyn.find((dItem) => dItem.DynType === dnyType);
                return true;
            }
        });
        return res;
    }
    /**
     * 获取动画信息和对应 ele id
     */
    getAnimationDataByPtPath(ptPath) {
        const { svgJsonListDraw } = this;
        if (!svgJsonListDraw || ptPath === undefined || ptPath === null) {
            return;
        }
        let res = [];
        // 遍历不同点 id 下的 json 数据
        const { svgContentEl } = this;
        svgJsonListDraw.forEach((item) => {
            // 遍历里面再遍历查找子元素
            const { listDyn } = item.dyn;
            // 当前 id 下的 animationList
            const animationList = listDyn.filter((LItem) => LItem.PtPath == ptPath);
            if (animationList && animationList.length) {
                const pointId = item.id;
                const pointEle = svgContentEl
                    ? svgContentEl.querySelector(`[id="${pointId}"]`) ||
                        undefined
                    : undefined;
                res.push({ pointId: item.id, ele: pointEle, data: animationList });
            }
        });
        return res;
    }
    /**
     * 解析 Tag
     *
     * 如果tag数据为空，则设置默认值
     */
    renderTagData(data) {
        if (!data || JSON.stringify(data) === '{}') {
            return {
                value: null,
                valid: false,
                alarm: false,
            };
        }
        return data;
    }
    //#region svg 实时数据逻辑
    /**
     * 判断点数据是否有效
     *
     * tag data 不存在，且在设备联合类型中存在，则认为无效
     *
     * @param data
     */
    isValidRunConfPointData() {
        const { ptPathDstToPtPathData } = this;
        return (data) => {
            if (!data) {
                return false;
            }
            const { tag, ptPath } = data;
            // tag 不存在
            if (!tag) {
                // 不存在 ptPathDstToPtPathData 则认为点数据有效
                const ptPathList = Object.values(ptPathDstToPtPathData || {});
                if (!ptPathDstToPtPathData || ptPathList.length === 0) {
                    return true;
                }
                // ptPath 在联合类型（ptPathDstToPtPathData）中存在，则认为无效数据，不再处理相关dom更新等
                return ptPathList.findIndex((item) => item === ptPath) === -1;
            }
            return true;
        };
    }
    /**
     * 更新 SVG 显示 - 实时数据
     *
     * @param isResetData
     */
    updateSvgDomRealTimeData(isResetData = false) {
        const { runConfData } = this;
        if (!runConfData) {
            console.warn(`未配置SVG点数据`);
            return;
        }
        const { confList } = runConfData;
        if (!confList || !confList.length) {
            return;
        }
        const { ptPathDstToPtPathData, isValidRunConfPointData, renderTagData, updateSvgPointEleByPtPath, } = this;
        // 获取实时数据并更新 svg dom
        const isValidRunConfPointDataFn = isValidRunConfPointData();
        confList.forEach((item) => {
            // 判断实时数据是否需要执行更新 dom
            if (isValidRunConfPointDataFn(item)) {
                // 从方法入参获取 tagData
                let { tag, ptPath, pointPkId } = item;
                // 清空tag数据
                if (isResetData) {
                    tag = renderTagData();
                }
                else if (pointPkId) {
                    // 如果点ID不存在，则认为是无效数据， // TODO: 这个判断条件可能有问题
                    // tag不存在时候，设置为默认值
                    tag = renderTagData(tag);
                }
                if (tag && ptPath) {
                    // 显示到 svg dom 的值
                    const { value } = tag;
                    let val = value;
                    // 转换 ptPath 用于更新动画
                    const parsedPtPath = ptPathDstToPtPathData
                        ? ptPathDstToPtPathData[ptPath] || ptPath
                        : ptPath;
                    // 更新 svg dom
                    updateSvgPointEleByPtPath.call(this, parsedPtPath, tag, item);
                }
            }
        });
    }
    /**
     * 根据 ptPath 更新dom中显示值
     *
     * @param ptPath
     * @param tagData
     * @param value 枚举或者具体值
     */
    updateSvgPointEleByPtPath(ptPath, tagData, configuration) {
        if (!configuration) {
            console.warn(`不存在点配置信息，请联系系统管理员！`);
            return;
        }
        const animationData = this.getAnimationDataByPtPath(ptPath);
        if (!animationData || !animationData.length) {
            return;
        }
        animationData.forEach((item) => {
            const { data, ele } = item;
            if (ele && data && data.length) {
                // 存在多个 ptPath
                data.forEach((dItem) => {
                    this.updatePtPathView(ele, dItem, tagData, configuration);
                });
            }
        });
    }
    /**
     * 枚举解析
     *
     * 当枚举集合不存在，返回当前 key
     */
    getEnumDisplayValue(key, confEnumList) {
        if (key === undefined ||
            key === null ||
            !confEnumList ||
            !confEnumList.length) {
            // 枚举不存在, 显示值
            return `${key === null || key === undefined ? '' : key}`;
        }
        let result = '';
        // 去除尾0并转为数值
        const keyStr = `${1 * key}`;
        confEnumList.some((item) => {
            if (`${item.key}` === keyStr) {
                result = item.value;
                return true;
            }
        });
        return result;
    }
    //#endregion
    /**
     * 更新 SvgContainerEl
     */
    initSvgContainerEl() {
        this.svgContainerEl = this.$el.querySelector(`.${this.svgContainerClass}:last-of-type`);
    }
    /**
     * 获取当前显示的svg的shadowRoot
     */
    getCurrentRootShadow() {
        const { svgContainerEl } = this;
        return svgContainerEl?.getRootNode();
    }
    /**
     * 获取svg element
     */
    getSvgElement() {
        const { svgContainerEl } = this;
        if (!svgContainerEl) {
            return;
        }
        const svgEl = svgContainerEl.shadowRoot?.querySelector('svg');
        if (!svgEl) {
            return;
        }
        const svgViewportEl = svgEl.querySelector('.svg-pan-zoom_viewport');
        return {
            svgEl,
            svgViewportEl,
        };
    }
    /**
     * 初始化svg容器
     */
    initSvgContainer() {
        // 准备 shadow dom
        const { svgContainerEl } = this;
        if (!svgContainerEl) {
            return;
        }
        const hadShadow = !!svgContainerEl.shadowRoot;
        if (!hadShadow) {
            svgContainerEl.attachShadow({ mode: 'open' });
        }
        // 初始化shadow dom style
        const styleHtml = `<style>${baseSvgShadowCss}</style>`;
        svgContainerEl.shadowRoot.innerHTML = styleHtml;
    }
    /**
     * 更新ViewBox值
     *
     * 用于svg不同缩放模式显示效果 拉伸 自适应等
     */
    updateViewBoxData() {
        const { svgContainerEl } = this;
        if (!svgContainerEl) {
            return;
        }
        const svgContent = svgContainerEl.shadowRoot?.querySelector('svg');
        this.svgViewBox = svgContent?.getAttribute('viewBox') || '';
    }
    /**
     * 初始化 svg content
     */
    initSvgContent() {
        const { svgContainerEl, svgContent } = this;
        if (!svgContainerEl || !svgContent) {
            return;
        }
        // 初始化svg容器
        this.initSvgContainer();
        const svgEl = window.document
            .createRange()
            .createContextualFragment(svgContent);
        // 对svg进行缩放 更新viewBox值
        const newSvgEl = this.refactorSvgContent(svgEl);
        svgContainerEl.shadowRoot?.appendChild(newSvgEl);
        this.updateViewBoxData();
        this.resizeSvg();
        this.svgContentEl = svgContainerEl?.shadowRoot?.querySelector('svg');
        // 事件绑定
        this.$nextTick(() => {
            this.unBindEvent();
            this.bindEvent();
        });
    }
    /**
     * 重新组装 Svg dom
     *
     * @param data
     */
    refactorSvgContent(data) {
        const divEl = document.createElement('div');
        divEl.style.height = '100%';
        divEl.appendChild(data);
        return divEl;
    }
    /**
     * 判断 mouse down 的事件是否为鼠标中键点击
     */
    handleSvgMouseDownMiddle(evt) {
        this.isMoveByMiddle = evt.buttons === 4;
    }
    /**
     * 获取 event target (支持shadow root)
     *
     * @param e
     */
    getEventTarget(e) {
        const { svgContainerEl } = this;
        if (!e || !svgContainerEl) {
            return;
        }
        const rootEle = svgContainerEl.getRootNode();
        let isInsideShadowRoot = false;
        if (rootEle !== document) {
            isInsideShadowRoot = true;
        }
        if (isInsideShadowRoot) {
            const paths = e.composedPath();
            return (paths && paths.length && paths[0]);
        }
        return e.target;
    }
    /**
     * 绑定事件
     */
    bindEvent() {
        const { svgContentEl } = this;
        if (svgContentEl) {
            // 处理 beforePan 中无法判断鼠标点击方式为鼠标中键
            svgContentEl.addEventListener('mousedown', this.handleSvgMouseDownMiddle);
            // 处理设备点 Tooltip hover 事件绑定
            svgContentEl.addEventListener('mouseover', this.tooltipEventListener);
            // 处理 svg 元素点击事件
            svgContentEl.addEventListener('click', this.onSvgElementClick);
        }
    }
    /**
     * 解绑事件
     */
    unBindEvent() {
        const { svgContentEl } = this;
        if (svgContentEl) {
            svgContentEl.removeEventListener('mousedown', this.handleSvgMouseDownMiddle);
            // 设备点鼠标移上去显示提示信息
            svgContentEl.removeEventListener('mouseover', this.tooltipEventListener);
            // svg 元素点击事件
            svgContentEl.removeEventListener('click', this.onSvgElementClick);
        }
    }
    /**
     * svg 自适应，缩放
     */
    resizeSvg() {
        const { svgContainerEl } = this;
        if (!svgContainerEl) {
            return;
        }
        // 隐藏svg, 包装容器计算正确
        const svgWrapStyle = window.getComputedStyle(svgContainerEl);
        const svgWrapHeight = svgWrapStyle.height.slice(0, -2); //假定为px
        const svgWrapWidth = svgWrapStyle.width.slice(0, -2); //假定为px
        const svgShadowDom = svgContainerEl.shadowRoot?.querySelector('svg');
        if (svgShadowDom) {
            let svgWidth = svgWrapWidth;
            let svgHeight = svgWrapHeight;
            svgContainerEl.style.display = 'none';
            svgShadowDom.setAttribute('height', `${svgHeight}`);
            svgShadowDom.setAttribute('width', `${svgWidth}`);
            svgContainerEl.style.display = 'block';
            const svgEl = svgContainerEl.shadowRoot?.querySelector('svg');
            if (svgEl) {
                try {
                    // 重置缩放
                    svgPanZoom(svgEl).destroy();
                    //执行转换
                    svgPanZoom(svgEl, {
                        viewportSelector: '.svg-pan-zoom_viewport',
                        dblClickZoomEnabled: false,
                        controlIconsEnabled: false,
                        zoomEnabled: this.isZoomEnable,
                        // panEnabled: this.isDragEnable,
                        beforePan: this.beforePan,
                        beforeZoom: this.beforeZoom,
                    });
                    //当前视窗缩放比例控制div
                    const jSvg = svgContainerEl.shadowRoot?.querySelector('svg'), jVp = jSvg?.querySelector('.svg-pan-zoom_viewport');
                    //设置值样式禁止拖动改变本组件的位置
                    jVp.classList.add('pi-cancel-drag');
                    //全屏模式
                    if (this.contextMenu.isFullScreenSvg) {
                        this.pausePanZoom(jSvg, jVp);
                    }
                    //设置当前变换样式
                    this.resetTimeOut('changeSvgTransform', () => {
                        const vPComStyle = window.getComputedStyle(jVp);
                        let transform = vPComStyle.transform;
                        transform === 'none' &&
                            (transform = jVp.getAttribute('transform'));
                        if (transform === undefined) {
                            transform = 'matrix(1, 0, 0, 1, 0, 0)';
                        }
                        if (transform === 'matrix(0,0,0,0,0,0)') {
                            transform = 'matrix(1, 0, 0, 1, 0, 0)';
                            jVp.style.transform = transform;
                        }
                        let matrixArr = transform
                            .slice(transform.indexOf('(') + 1, transform.length - 1)
                            .replace(/ /g, '')
                            .split(',');
                        //水平缩放比例/x轴旋转/y轴旋转/垂直缩放比例/水平偏移距离/垂直偏移距离
                        transform = `matrix(${matrixArr.join(',')})`;
                        jVp.setAttribute('transform', transform);
                        jVp.style.transform = transform;
                    }, 100);
                }
                catch (error) {
                    console.error(`svg渲染失败：`, error);
                }
            }
        }
    }
    /**
     * 获取目标点下的所有 ptPath
     *
     * @param id
     */
    getPtPathList(id) {
        const { svgJsonListDraw } = this;
        if (!svgJsonListDraw || !svgJsonListDraw.length) {
            return [];
        }
        const pointData = svgJsonListDraw.find((item) => item.id === id);
        const result = [];
        pointData?.dyn.listDyn.forEach((item) => {
            const tmpPath = item.PtPath;
            if (result.indexOf(tmpPath) === -1) {
                result.push(tmpPath);
            }
        });
        return result;
    }
    /**
     * 获取所有 json 文件的设备点
     */
    getAllPointId() {
        const { svgJson } = this;
        if (!svgJson) {
            return;
        }
        const pointSetList = new Set();
        const { listDraw } = svgJson;
        if (!listDraw) {
            return;
        }
        listDraw.forEach((item) => {
            const { id } = item;
            id && pointSetList.add(id);
        });
        return Array.from(pointSetList);
    }
    /**
     * 组装 ptPathUnion
     */
    getPtPathUnionFromJson(ptPathList) {
        ptPathList = ptPathList || this.getAllPtPathFromJson();
        if (!ptPathList || !ptPathList.length) {
            return;
        }
        // 获取ptPath
        const ptPathUnion = [];
        const { unionDeviceIdPrefix, svgDevIdList } = this;
        if (!svgDevIdList || !svgDevIdList.length) {
            return;
        }
        svgDevIdList.forEach((id) => {
            const ptPathDev = `${unionDeviceIdPrefix}${id}`;
            ptPathList.forEach((ptPathType) => {
                ptPathUnion.push({
                    ptPathDev,
                    ptPathType,
                });
            });
        });
        return ptPathUnion;
    }
    /**
     * 获取所有 ptPath
     *
     * 不包含 ptPathUnion.ptPathDst
     */
    getAllPtPathFromJson() {
        const { svgJson } = this;
        if (!svgJson) {
            return;
        }
        const ptPathSetList = new Set();
        const { listDraw } = svgJson;
        listDraw &&
            listDraw.forEach((item) => {
                const listDyn = item?.dyn?.listDyn;
                listDyn &&
                    listDyn.forEach((dItem) => {
                        const { PtPath } = dItem;
                        // 判断是否存在重复 ptPath
                        PtPath && ptPathSetList.add(PtPath);
                    });
            });
        return Array.from(ptPathSetList);
    }
    /**
     * 获取目标 ptPath 下的所有 confList
     *
     * @param ptPathList
     */
    getPointRunConfData(ptPathList) {
        // 获取点配置数据
        const { runConfData } = this;
        if (!runConfData || !ptPathList || !ptPathList.length) {
            return [];
        }
        const result = [];
        const confList = runConfData.confList;
        if (!confList || !confList.length) {
            return [];
        }
        ptPathList.forEach((ptPath) => {
            result.push(...confList.filter((cItem) => cItem.ptPath === ptPath));
        });
        return result;
    }
    /**
     * 获取元素ID对应动画
     */
    getPointAnimationList(id) {
        if (!id) {
            return;
        }
        const { svgJson } = this;
        if (!svgJson) {
            return;
        }
        const { listDraw } = svgJson;
        if (!listDraw || !listDraw.length) {
            return;
        }
        const animateList = listDraw.filter((item) => item.id === id);
        if (!animateList || !animateList.length) {
            return;
        }
        const animate = animateList[0];
        if (!animate) {
            return;
        }
        return animate?.dyn?.listDyn;
    }
};
VueMethodsMixin = __decorate([
    Component
], VueMethodsMixin);
export default VueMethodsMixin;
