import { PiBaseLoading, PiBaseError } from 'bc$/main';
export function piImport(vueCmp, options) {
    const _options = {
        loading: PiBaseLoading,
        error: PiBaseError,
        delay: 600,
        timeout: 50000,
        ...(options || {}),
    };
    const cmp = () => ({
        component: vueCmp(),
        loading: _options.loading,
        error: _options.error,
        delay: _options.delay,
        timeout: _options.timeout,
    });
    const cmpObj = cmp;
    let error = undefined;
    Object.defineProperty(cmp, 'error', {
        get: function () {
            return error;
        },
        set: function (value) {
            error = value;
            value &&
                setTimeout(() => {
                    cmpObj.error = undefined;
                    cmpObj.loading = undefined;
                    cmpObj.owners = undefined;
                    cmpObj.resolved = undefined;
                    cmpObj.errorComp = undefined;
                    cmpObj.loadingComp = undefined;
                }, 2000);
        },
    });
    return cmp;
}
