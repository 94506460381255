import Vue from 'vue';
// has class
export function HasClass(el, cls) {
    if (!el || !cls) {
        return false;
    }
    if (cls.indexOf(' ') !== -1) {
        throw new Error('className should not contain space.');
    }
    if (el.classList) {
        return el.classList.contains(cls);
    }
    else {
        return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
    }
}
// add class
export function AddClass(el, cls) {
    if (!el || !cls) {
        return;
    }
    if (el.classList) {
        el.classList.add(cls);
    }
    else {
        let clsArr = el.className.split(' ');
        if (clsArr.indexOf(cls) === -1) {
            el.className += ' ' + cls;
        }
    }
}
// remove class
export function RemoveClass(el, cls) {
    if (!el || !cls) {
        return;
    }
    if (el.classList) {
        el.classList.remove(cls);
    }
    else {
        let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        el.className = el.className.replace(reg, ' '); // For IE9 and earlier
    }
}
// get rootNode -- support shadow dom
export function GetRootNode(el) {
    if (!el || !(el instanceof Element)) {
        return document;
    }
    return el.getRootNode();
}
// get vue instance root element
export function GetVueRootEl(that) {
    if (that instanceof Vue) {
        return that.$root.$el;
    }
}
/**
 * 判断当前DOM元素位于shadow dom
 *
 * @param el
 */
export function IsInsideShadowDom(el) {
    if (!el || !(el instanceof HTMLElement)) {
        return false;
    }
    // 获取根元素
    const rootNode = GetRootNode(el);
    return rootNode !== document;
}
/**
 * 获取元素根节点 （shadowRoot 除外）
 *
 *
 * @param el
 */
export function GetRootParentNode(el) {
    if (!el || !(el instanceof Element)) {
        return document;
    }
    const rootNode = el.getRootNode();
    let res = el;
    while (true) {
        const parentEl = res.parentElement;
        if (!el || !parentEl || parentEl === rootNode) {
            break;
        }
        else {
            res = parentEl;
        }
    }
    return res || document;
}
