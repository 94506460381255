/**
 * 取得数值数组中最大的数字
 * @param arr 待处理的数组
 */
function hNumMax(arr) {
    const result = Math.max(...arr);
    return result;
}
/**
 * 取得数值数组中最小的数字
 * @param arr 待处理的数组
 */
function hNumMin(arr) {
    const result = Math.min(...arr);
    return result;
}
/**
 * 取得数值数组总和
 * @param arr 待处理的数组
 */
function hNumSum(arr) {
    const result = arr.reduce((sum, item) => {
        return (sum += item);
    }, 0);
    return result;
}
/**
 * 取得数值数组平均值
 * @param arr 待处理的数组
 */
function hNumAvg(arr) {
    if (arr.length == 0) {
        return 0;
    }
    const result = hNumSum(arr) / arr.length;
    return result;
}
/**
 * 获取一个随机数指定范围内的最大最小值，包括最大和最小值
 * @param min 最小值
 * @param max 最大值
 */
function hNumGetNumFrom(min, max) {
    //取整
    min = Math.round(min);
    max = Math.round(max);
    //取值范围总数
    const choice = max - min + 1;
    //返回随机数
    return Math.floor(Math.random() * choice + min);
}
/**
 * 数值保留小数点后几位小数
 *@param {number} val - 数值
 *@param {number} num - 保留小数点个数
 */
function hNumToFixed(val, num) {
    // tslint:disable-next-line:no-construct
    const _val = new Number(val);
    return _val.toFixed(num);
}
/**
 * 千分符
 * @param value 原始值
 * @param n 小数位数，默认：2
 * @param b 四舍五入，默认：true
 */
function hNumFormatMoney(value, n, b) {
    if (!isFinite(value) || value === null) {
        return '--';
    }
    typeof value !== 'number' && (value = parseFloat(value));
    b = b == null ? true : b;
    n = n >= 0 && n <= 20 ? n : 2;
    let isNegative = false;
    if (parseFloat(value + '') < 0) {
        value = 0 - parseFloat(value + '');
        isNegative = true;
    }
    const s = !b
        ? (value + '').replace(/[^\d\.-]/g, '')
        : parseFloat((value + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
    let l = s.split('.')[0].split('').reverse(), r = s.split('.')[1];
    let t = '';
    for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
    }
    if (!r) {
        r = '0';
    }
    if (!b) {
        if (r.length < n) {
            for (let i = r.length; i < n; i++) {
                r += '0';
            }
        }
        else {
            r = r.substr(0, n);
        }
    }
    return ((isNegative ? '-' : '') +
        t.split('').reverse().join('') +
        (n > 0 ? '.' + r : ''));
}
/**
 *
 * @param value
 */
function hNumFormatEchToolTipNum(value) {
    if (value === null) {
        return '--';
    }
    typeof value !== 'number' && (value = parseFloat(value));
    if (!isFinite(value)) {
        return '--';
    }
    let decNum = 2;
    const valAbs = Math.abs(value);
    if (valAbs < 1e1) {
        decNum = 2;
    }
    else if (valAbs < 1e2) {
        decNum = 2;
    }
    else if (valAbs < 1e3) {
        decNum = 2;
    }
    else if (valAbs < 1e4) {
        // 1万
        decNum = 1;
    }
    else if (valAbs < 1e5) {
        // 10万
        decNum = 2;
    }
    else if (valAbs < 1e6) {
        // 100万
        decNum = 2;
    }
    else if (valAbs < 1e7) {
        // 1000万
        decNum = 1;
    }
    else if (valAbs < 1e8) {
        // 1亿
        decNum = 0;
    }
    else if (valAbs < 1e9) {
        // 10亿
        decNum = 2;
    }
    else if (valAbs < 1e10) {
        // 100亿
        decNum = 2;
    }
    else if (valAbs < 1e11) {
        // 1000亿
        decNum = 1;
    }
    else if (valAbs < 1e12) {
        // 1兆
        decNum = 0;
    }
    else if (valAbs < 1e13) {
        // 10兆
        decNum = 2;
    }
    else if (valAbs < 1e14) {
        // 100兆
        decNum = 2;
    }
    else if (valAbs < 1e15) {
        // 1000兆
        decNum = 1;
    }
    else if (valAbs < 1e16) {
        // 1京
        decNum = 0;
    }
    else if (valAbs < 1e17) {
        // 10京
        decNum = 2;
    }
    else if (valAbs < 1e18) {
        // 100兆
        decNum = 2;
    }
    else if (valAbs < 1e19) {
        // 1000兆
        decNum = 1;
    }
    else {
        // TODO:
        // 兆、京、垓、秭、穰、沟、涧、正、载、极
        decNum = 0;
    }
    return hNumFormatUnit(value, decNum, false);
}
/**
 * 单位转换
 * @param n 小数位数，默认：2
 * @param b 四舍五入，默认：true
 */
function hNumFormatUnit(value, n = 2, b = true) {
    if (value === null) {
        return '--';
    }
    typeof value !== 'number' && (value = parseFloat(value));
    if (!isFinite(value)) {
        return '--';
    }
    // 处理 n不为有效数值情况，默认 2
    n = Number.isNaN(Number(n)) ? 2 : n;
    let isNegative = false;
    if (value < 0) {
        isNegative = true;
        value = value * -1;
    }
    b = b == null ? true : b;
    n = n >= 0 && n <= 20 ? n : 2;
    let result = '';
    let unit = '';
    if (!isNaN(value)) {
        if (value >= 1e8) {
            value = value / 1e8;
            unit = '亿';
        }
        else if (value >= 1e4) {
            value = value / 1e4;
            unit = '万';
        }
    }
    if (b) {
        result = value.toFixed(n);
    }
    else {
        let l = (value + '').split('.')[0], r = (value + '').split('.')[1];
        if (!r) {
            r = '0';
        }
        if (r.length < n) {
            for (let i = r.length; i < n; i++) {
                r += '0';
            }
        }
        else {
            r = r.substr(0, n);
        }
        // 特殊处理 1.万情况
        const decimalPart = r === '' ? r : `.${r}`;
        result = l + decimalPart;
    }
    return (isNegative ? '-' : '') + result + unit;
}
/**
 * 执行修约数值
 * @param value 需要修约的数值
 * @param digit 需要修约到的最大位数
 */
function hNumRoundingOff(value, digit = 2) {
    if (!isFinite(value) || value === null) {
        return '--';
    }
    typeof value !== 'number' && (value = parseFloat(value));
    let isNegative = false;
    if (value < 0) {
        isNegative = true;
        value = value * -1;
    }
    const units = ',万,亿,兆,京,垓,秭,穰,沟,涧,正,载,极'.split(',');
    const length = units.length;
    if (value >= Math.pow(10, length * 4)) {
        return 'overflow';
    }
    let unit = '';
    for (let i = length - 1; i >= 0; i--) {
        const iNode = Math.pow(10, i * 4);
        if (value >= iNode) {
            value = value / iNode;
            unit = units[i];
            break;
        }
    }
    let iSlice = Math.min(unit.length > 0 ? 3 : 4, digit);
    if (Math.round(value) >= 995 && Math.round(value) < 1e4) {
        iSlice -= 1;
    }
    const pw = Math.pow(10, iSlice);
    value = Math.round(value * pw) / pw;
    return (isNegative ? '-' : '') + value + unit;
}
export { hNumMin, hNumMax, hNumSum, hNumAvg, hNumGetNumFrom, hNumToFixed, hNumFormatMoney, hNumFormatEchToolTipNum, hNumFormatUnit, hNumRoundingOff, };
