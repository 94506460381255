import { SHA1, SHA256, SHA512, MD5, enc, HmacSHA256 } from 'crypto-js';
/**
 * sha1加密算法
 * @param message 加密字符串
 * @param salt 盐值
 * @param count 加密次数
 */
function hCrypSha1(message, salt = '', count = 1) {
    //判断是否为0`10次加密
    count = Math.max(1, Math.min(count, 10));
    let _message = message + salt;
    for (let i = 0; i < count; i++) {
        _message = SHA1(_message).toString();
    }
    return _message;
}
/**
 * sha256加密算法
 * @param message 加密字符串
 * @param salt 盐值
 * @param count 加密次数
 */
function hCrypSha256(message, salt = '', count = 1) {
    //判断是否为0`10次加密
    count = Math.max(1, Math.min(count, 10));
    let _message = message + salt;
    for (let i = 0; i < count; i++) {
        _message = SHA256(_message).toString();
    }
    return _message;
}
/**
 * sha1加密算法
 * @param message 加密字符串
 * @param salt 盐值
 * @param count 加密次数
 */
function hCrypSha512(message, salt = '', count = 1) {
    //判断是否为0`10次加密
    count = Math.max(1, Math.min(count, 10));
    let _message = message + salt;
    for (let i = 0; i < count; i++) {
        _message = SHA512(_message).toString();
    }
    return _message;
}
/**
 * md5加密算法
 * @param message 加密字符串
 * @param salt 盐值
 * @param count 加密次数
 */
function hCrypMd5(message, salt = '', count = 1) {
    //判断是否为0`10次加密
    count = Math.max(1, Math.min(count, 10));
    let _message = message + salt;
    for (let i = 0; i < count; i++) {
        _message = MD5(_message).toString();
    }
    return _message;
}
/**
 * base64编码
 * @param message 编码字符串
 */
function hCrypEncodeBase64(message) {
    return enc.Base64.stringify(enc.Utf8.parse(message));
}
/**
 * base64解码
 * @param message 解码字符串
 */
function hCrypDecodeBase64(message) {
    return enc.Base64.parse(message).toString(enc.Utf8);
}
/**
 * HmacSha256加密算法
 * @param message 加密字符串
 * @param salt 盐值
 * @param count 加密次数
 */
function hCrypHmacSha256(message, salt = '', count = 1) {
    //判断是否为0`10次加密
    count = Math.max(1, Math.min(count, 10));
    let _message = message + salt;
    for (let i = 0; i < count; i++) {
        _message = HmacSHA256(_message, salt).toString();
    }
    return _message;
}
export { hCrypSha1, hCrypSha256, hCrypSha512, hCrypMd5, hCrypEncodeBase64, hCrypDecodeBase64, hCrypHmacSha256, };
