var PiBaseTimingHide_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import Vue from 'vue';
import { Draggable } from '../../directives/draggable';
Vue.directive('drag', Draggable);
import template from './pi-base-timing-hide.component.html';
// tslint:disable-next-line: no-import-side-effect
import 'animate.css/animate.css';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-timing-hide.component.scss';
import { hErrorShow } from 'bc$/utils/error.helper';
let PiBaseTimingHide = PiBaseTimingHide_1 = class PiBaseTimingHide extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseTimingHide';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseTimingHide_1?.NAME,
        author: 'yangd',
        title: '定时隐藏组件',
        version: BcVersion,
        updateDate: '2019-06-05',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 给组件添加的额外class名称
     */
    className;
    /**
     * 外部控制本组件是否显示,默认true
     */
    isShow;
    /**
     * 多少毫秒后自动隐藏,默认5000
     */
    afterHide;
    /**
     * 移动多少距离后才显示,默认50
     */
    afterShow;
    //#endregion
    //#region data
    iMoveCount = 0;
    draggableOptions = {
        onPositionChange: () => { },
        onDragEnd: () => { },
        onDragStart: () => { },
    };
    //#endregion
    //#region computed
    get about() {
        return PiBaseTimingHide_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    'watch.isShow'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        //设置浮动的顶部下拉菜单的状态
        this.setStatus();
    }
    //#endregion
    //#region methods
    /**
     * 执行mousemove事件
     */
    bindEvent() {
        this.iMoveCount++;
        if (this.iMoveCount < this.afterShow) {
            return;
        }
        //解绑触发事件
        this.iMoveCount = 0;
        const eleBody = document.querySelector('body');
        if (eleBody) {
            eleBody.removeEventListener('mousemove', this.bindEvent);
            eleBody.removeEventListener('touchend', this.showDropdown);
        }
        //触发一次显示
        this.showDropdown();
    }
    /**
     * 执行显示菜单
     */
    showDropdown() {
        //是否设置隐藏
        if (!this.isShow) {
            return;
        }
        const { classList } = this.$el;
        classList.remove('fadeOut');
        classList.add('fadeIn');
        this.resetTimeOut('showDropdown-hide', () => {
            //经过指定时间后再次隐藏
            this.hideDropdown();
        }, 1000);
    }
    /**
     * 执行隐藏
     */
    hideDropdown() {
        this.$nextTick(() => {
            //设置延时任务,统一管理
            this.resetTimeOut('hideDropdown', () => {
                const { classList } = this.$el;
                classList.remove('fadeIn');
                classList.add('fadeOut');
                this.resetTimeOut('hideDropdown-show', () => {
                    const eleBody = document.querySelector('body');
                    if (eleBody) {
                        eleBody.removeEventListener('mousemove', this.bindEvent);
                        eleBody.removeEventListener('touchend', this.showDropdown);
                        eleBody.addEventListener('mousemove', this.bindEvent);
                        eleBody.addEventListener('touchend', this.showDropdown);
                    }
                }, 1000);
            }, this.afterHide);
        });
    }
    /**
     * 设置浮动的块的状态
     */
    setStatus() {
        this.$nextTick()
            .then(() => {
            //添加class
            const { classList } = this.$el;
            classList.add('animated');
            this.className && classList.add(this.className);
            //清除之前的延时回调
            const eleBody = document.querySelector('body');
            if (eleBody) {
                //设置延时任务,统一管理
                this.resetTimeOut('hideDropdown');
                //解绑事件
                eleBody.removeEventListener('mousemove', this.bindEvent);
                eleBody.removeEventListener('touchend', this.showDropdown);
            }
            //当前下拉菜单
            let ele = this.$el;
            ['mouseenter', 'touchstart'].forEach((ev) => {
                ele.removeEventListener(ev, this.setStatusMouseenter);
            });
            ['mouseleave', 'touchend'].forEach((ev) => {
                ele.removeEventListener(ev, this.hideDropdown);
            });
            //是否设置隐藏
            if (this.isShow) {
                //绑定事件鼠标移入事件
                ['mouseenter', 'touchstart'].forEach((ev) => {
                    ele.addEventListener(ev, this.setStatusMouseenter);
                });
                ['mouseleave', 'touchend'].forEach((ev) => {
                    ele.addEventListener(ev, this.hideDropdown);
                });
                //触发一次显示
                this.showDropdown();
            }
        })
            .catch((err) => hErrorShow({
            err,
        }));
    }
    /**
     * 设置浮动的块的状态鼠标移入事件
     */
    setStatusMouseenter() {
        this.resetTimeOut('hideDropdown');
    }
    /**
     * 拖动开始时
     */
    onDragStart(posDiff, pos, event) {
        event &&
            event.target &&
            (event.target.style.right = 'initial');
        this.$emit('onDragStart');
    }
    /**
     * 拖动时
     */
    onPositionChange() {
        this.$emit('onDragging');
    }
    /**
     * 拖动结束时
     */
    onDragEnd() {
        this.$emit('onDragStop');
    }
    //#endregion
    //#region hooks
    created() {
        this.draggableOptions = {
            onDragStart: this.onDragStart.bind(this),
            onPositionChange: this.onPositionChange.bind(this),
            onDragEnd: this.onDragEnd.bind(this),
        };
    }
    mounted() {
        //设置浮动的顶部块的状态
        this.setStatus();
    }
    /**
     * 销毁实例前
     */
    beforeDestroy() {
        const eleBody = document.querySelector('body');
        const ele = this.$el;
        if (eleBody) {
            eleBody.removeEventListener('mousemove', this.bindEvent);
            eleBody.removeEventListener('touchend', this.showDropdown);
        }
        ['mouseenter', 'touchstart'].forEach((ev) => {
            ele.removeEventListener(ev, this.setStatusMouseenter);
        });
        ['mouseleave', 'touchend'].forEach((ev) => {
            ele.removeEventListener(ev, this.hideDropdown);
        });
    }
};
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseTimingHide.prototype, "className", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseTimingHide.prototype, "isShow", void 0);
__decorate([
    Prop({
        type: Number,
        default: 5000,
    })
], PiBaseTimingHide.prototype, "afterHide", void 0);
__decorate([
    Prop({
        type: Number,
        default: 50,
    })
], PiBaseTimingHide.prototype, "afterShow", void 0);
__decorate([
    Watch('isShow', { immediate: true })
], PiBaseTimingHide.prototype, "watch.isShow", null);
PiBaseTimingHide = PiBaseTimingHide_1 = __decorate([
    Component({
        template,
    })
], PiBaseTimingHide);
export default PiBaseTimingHide;
