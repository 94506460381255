/**
 * 显示错误,并在控制台打印
 */
function hErrorShow(options) {
    const { context, err, message, showInPage, callback } = options;
    if (!err.__CANCEL__ &&
        err.message &&
        err.response &&
        err.response.status !== 401) {
        showInPage &&
            context &&
            context.$Message.destroy() &&
            context.$Message.error(message || err.message);
        console.error(message, err);
    }
    callback && callback();
}
export { hErrorShow };
