var PiBaseTimePicker_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { DropdownMenu, Dropdown, DropdownItem } from 'iview';
import { BcVersion } from 'bc$/bases/config.base';
// import { PiBaseTimePickerProperty } from './components/pi-base-time-picker-property/pi-base-time-picker-property.component';
import template from './pi-base-time-picker.component.html';
import { Input, Select, Option } from 'iview';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-time-picker.component.scss';
import { VueBase, } from 'bc$/main';
let PiBaseTimePicker = PiBaseTimePicker_1 = class PiBaseTimePicker extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseTimePicker';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseTimePicker_1?.NAME,
        author: 'yangd',
        title: '无标题',
        version: BcVersion,
        updateDate: '2019-08-14',
        description: '',
    };
    //#endregion
    //#region props
    value;
    //#endregion
    //#region data
    isShow = true;
    startHour = '';
    startMin = '';
    endHour = '';
    endMin = '';
    isInit = false;
    startHourInstance;
    startMinInstance;
    endHourInstance;
    endMinInstance;
    /**
     * 是否显示属性弹窗
     */
    isShowProperty = false;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseTimePicker_1.ABOUT;
    }
    get titleAll() {
        return '';
    }
    /**
     * 获取小时
     */
    get getHours() {
        let hours = [];
        for (let i = 0; i < 25; i++) {
            hours.push(i);
        }
        return hours;
    }
    /**
     * 获取分钟
     */
    get getMins() {
        let mins = [];
        for (let i = 0; i < 60; i++) {
            mins.push(i);
        }
        return mins;
    }
    /**返回当前选中时间 */
    get piTime() {
        if (!this.isInit) {
            return '';
        }
        const isStartHourActive = this.startHour >= 10 ? this.startHour : '0' + this.startHour;
        const isStartMinActive = this.startMin >= 10 ? this.startMin : '0' + this.startMin;
        const isEndHourActive = this.endHour >= 10 ? this.endHour : '0' + this.endHour;
        const isEndMinActive = this.endMin >= 10 ? this.endMin : '0' + this.endMin;
        const time = `${isStartHourActive} : ${isStartMinActive} - ${isEndHourActive} : ${isEndMinActive}`;
        if (this.isShow) {
            this.$emit('input', time);
        }
        return time;
    }
    /**
     * 返回开始时间h的滚动条配置信息
     */
    get startHourScrollConfig() {
        return {
            stopPropagation: true,
            preventDefault: false,
            scrollX: false,
            initScrollSuccess: (instance) => {
                this.startHourInstance = instance;
                this.isShow = false;
            },
        };
    }
    /**
     * 返回开始时间m的滚动条配置信息
     */
    get startMinScrollConfig() {
        return {
            stopPropagation: true,
            preventDefault: false,
            scrollX: false,
            initScrollSuccess: (instance) => {
                this.startMinInstance = instance;
                this.isShow = false;
            },
        };
    }
    /**
     * 返回结束时间h的滚动条配置信息
     */
    get endHourScrollConfig() {
        return {
            stopPropagation: true,
            preventDefault: false,
            scrollX: false,
            initScrollSuccess: (instance) => {
                this.endHourInstance = instance;
                this.isShow = false;
            },
        };
    }
    /**
     * 返回结束时间m的滚动条配置信息
     */
    get endMinScrollConfig() {
        return {
            stopPropagation: true,
            preventDefault: false,
            scrollX: false,
            initScrollSuccess: (instance) => {
                this.endMinInstance = instance;
                this.isShow = false;
            },
        };
    }
    //#endregion
    //#region 函数类属性
    /**
     * 属性工具栏菜单,任何组件都是使用自身的属性配置方法
     */
    toolbarShowProperty = () => {
        this.isShowProperty = true;
    };
    /**
     * 给外部调用执行联动的实现函数
     * @param data
     */
    toolbarCbLink = (data) => {
        //修改查询条件
    };
    //#endregion
    //#region watch
    'watch.value'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal && newVal.includes('-')) {
            const timeList = newVal.split('-');
            const startTime = timeList[0];
            const endTime = timeList[1];
            const startTimeList = startTime.split(':');
            const endTimeList = endTime.split(':');
            if (Number(startTimeList[0]) > 24 || Number(endTimeList[0]) > 24) {
                console.error(`日期格式错误：${newVal}`);
                return;
            }
            this.startHour =
                typeof Number(startTimeList[0]) === 'number'
                    ? Number(startTimeList[0])
                    : '';
            this.startMin =
                typeof Number(startTimeList[1]) === 'number'
                    ? Number(startTimeList[1])
                    : '';
            this.endHour =
                typeof Number(endTimeList[0]) === 'number'
                    ? Number(endTimeList[0])
                    : '';
            this.endMin =
                typeof Number(endTimeList[1]) === 'number'
                    ? Number(endTimeList[1])
                    : '';
        }
    }
    'watch.isShow'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            typeof this.startHour === 'number' &&
                this.onSelectStartHour(this.startHour);
            typeof this.startMin === 'number' && this.onSelectStartMin(this.startMin);
            typeof this.endHour === 'number' && this.onSelectEndHour(this.endHour);
            typeof this.endMin === 'number' && this.onSelectEndMin(this.endMin);
        }
        else {
            this.isInit && this.$emit('onChange', this.piTime);
            // this.$emit('input', this.piTime) &&
        }
    }
    'watch.startHour'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (typeof newVal === 'number') {
            this.isInit = true;
            this.onSelectStartHour(newVal);
            // 开始时间m没赋值时，设值为0
            if (!this.startMin || newVal === 24) {
                // 重置开始时间m
                this.onSelectStartMin(0);
            }
            // 结束时间h没赋值时，设值为0
            if (!this.endHour) {
                // 重置开始时间h
                this.onSelectEndHour(0);
            }
            // 结束时间m没赋值时，设值为0
            if (!this.endMin || newVal === 24) {
                // 重置开始时间m
                this.onSelectEndMin(0);
            }
            // 当开始时间h>结束时间h时 | 结束时间未赋值时，重置结束时间
            if (newVal > Number(this.endHour) || typeof this.endHour !== 'number') {
                // 重置结束时间h
                this.onSelectEndHour(newVal);
            }
            // h时间相同条件下
            if (this.startHour === this.endHour && this.startMin > this.endMin) {
                // 重置结束时间m
                this.onSelectEndMin(Number(this.startMin) || 0);
            }
        }
    }
    'watch.startMin'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (typeof newVal === 'number') {
            this.onSelectStartMin(newVal);
            if (this.startHour === 24) {
                this.onSelectStartMin(0);
                return;
            }
            // 开始时间h没赋值时，设值为0
            if (!this.startHour) {
                // 重置开始时间h
                this.onSelectStartHour(0);
            }
            // 结束时间h没赋值时，设值为0
            if (!this.endHour) {
                // 重置开始时间h
                this.onSelectEndHour(0);
            }
            // 结束时间m没赋值时，设值为0
            if (!this.endMin) {
                // 重置开始时间m
                this.onSelectEndMin(0);
            }
            // 当开始时间>结束时间时 | 结束时间未赋值时，重置结束时间
            if (this.startHour === this.endHour && newVal > this.endMin) {
                // 重置结束时间m
                this.onSelectEndMin(newVal);
            }
        }
    }
    'watch.endHour'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (typeof newVal === 'number') {
            this.onSelectEndHour(newVal);
            // 开始时间h没赋值时，设值为0
            if (!this.startHour) {
                // 重置开始时间h
                this.onSelectStartHour(0);
            }
            // 结束时间h没赋值时，设值为0
            if (!this.startMin || newVal === 24) {
                // 重置开始时间h
                this.onSelectStartMin(0);
            }
            // 结束时间m没赋值时，设值为0
            if (!this.endMin || newVal === 24) {
                // 重置开始时间m
                this.onSelectEndMin(0);
            }
            // 当开始时间h>结束时间h时 | 结束时间未赋值时，重置结束时间
            if (typeof this.endHour === 'number' && newVal < this.startHour) {
                // 重置结束时间h
                this.onSelectStartHour(newVal);
            }
            // h时间相同条件下
            if (this.startHour === this.endHour && this.startMin > this.endMin) {
                // 重置开始时间m
                this.onSelectStartMin(Number(this.endMin) || 0);
            }
        }
    }
    'watch.endMin'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (typeof newVal === 'number') {
            this.onSelectEndMin(newVal);
            if (this.endHour === 24) {
                this.onSelectEndMin(0);
                return;
            }
            if (!this.startHour) {
                // 重置开始时间h
                this.onSelectStartHour(0);
            }
            // 开始时间m没赋值时，设值为0
            if (!this.startMin) {
                // 重置开始时间m
                this.onSelectStartMin(0);
            }
            // 结束时间h没赋值时，设值为0
            if (!this.endHour) {
                // 重置开始时间h
                this.onSelectEndHour(0);
            }
            // 当开始时间m>结束时间时m | 结束时间未赋值时，重置结束时间
            if (this.startHour === this.endHour && newVal < this.startMin) {
                // 重置开始时间m
                this.onSelectStartMin(newVal);
            }
        }
    }
    //#endregion
    //#region methods
    /**
     * 显示时间选择框
     */
    toggleShowTimeSelect() {
        if (!this.isShow) {
            this.isShow = true;
        }
    }
    /**
     * 隐藏时间选择框
     */
    hiddenTimeSelect() {
        this.isShow = false;
        this.$refs['timeInput'] && this.$refs['timeInput'].blur();
    }
    /**
     * 清除时间
     */
    clearTime() {
        this.startHour = '';
        this.startMin = '';
        this.endHour = '';
        this.endMin = '';
        this.isInit = false;
        this.$emit('input', this.piTime);
        this.$emit('onChange', this.piTime);
    }
    /**
     * 选中开始时间h
     * @param index 当前选中时间
     */
    onSelectStartHour(index) {
        this.$nextTick(() => {
            /**
             * 设置滚动条滚动位置
             */
            const ref = this.$refs['start-hours'];
            const { childNodes } = ref;
            /**
             * 激活当前点击的索引
             */
            if (childNodes && childNodes[index] && this.startHourInstance) {
                this.startHourInstance.scrollToElement(childNodes[index], undefined, undefined, undefined);
                this.startHour = index;
            }
        });
    }
    /**
     * 选中开始时间m
     * @param index 当前选中时间
     */
    onSelectStartMin(index) {
        this.$nextTick(() => {
            const ref = this.$refs['start-mins'];
            const { childNodes } = ref;
            if (childNodes && childNodes[index] && this.startMinInstance) {
                this.startMinInstance.scrollToElement(childNodes[index], undefined, undefined, undefined);
                this.startMin = index;
            }
        });
    }
    /**
     * 选中结束时间h
     * @param index 当前选中时间
     */
    onSelectEndHour(index) {
        this.$nextTick(() => {
            const ref = this.$refs['end-hours'];
            const { childNodes } = ref;
            if (childNodes && childNodes[index] && this.endHourInstance) {
                this.endHourInstance.scrollToElement(childNodes[index], undefined, undefined, undefined);
                this.endHour = index;
            }
        });
    }
    /**
     * 选中结束时间m
     * @param index 当前选中时间
     */
    onSelectEndMin(index) {
        this.$nextTick(() => {
            const ref = this.$refs['end-mins'];
            const { childNodes } = ref;
            if (childNodes && childNodes[index] && this.endMinInstance) {
                this.endMinInstance.scrollToElement(childNodes[index], undefined, undefined, undefined);
                this.endMin = index;
            }
        });
    }
    /**
     * 修改options数据
     */
    changeOptions(optionsNew) {
        //this.options = hODeepClone(optionsNew);
        //this.widgetInfo.attrJson = optionsNew;
        // this.$emit('on-change-widget-info', this.options);
    }
    //#endregion
    //#region hooks
    mounted() {
        document.addEventListener('click', this.hiddenTimeSelect);
    }
    destroyed() {
        document.removeEventListener('click', this.hiddenTimeSelect);
    }
};
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseTimePicker.prototype, "value", void 0);
__decorate([
    Watch('value', { immediate: true, deep: true })
], PiBaseTimePicker.prototype, "watch.value", null);
__decorate([
    Watch('isShow', { immediate: true, deep: true })
], PiBaseTimePicker.prototype, "watch.isShow", null);
__decorate([
    Watch('startHour', { immediate: true, deep: true })
], PiBaseTimePicker.prototype, "watch.startHour", null);
__decorate([
    Watch('startMin', { immediate: true, deep: true })
], PiBaseTimePicker.prototype, "watch.startMin", null);
__decorate([
    Watch('endHour', { immediate: true, deep: true })
], PiBaseTimePicker.prototype, "watch.endHour", null);
__decorate([
    Watch('endMin', { immediate: true, deep: true })
], PiBaseTimePicker.prototype, "watch.endMin", null);
PiBaseTimePicker = PiBaseTimePicker_1 = __decorate([
    Component({
        components: {
            Input,
            Dropdown,
            DropdownMenu,
            DropdownItem,
            Select,
            Option,
            // PiBaseTimePickerProperty,
        },
        template,
    })
], PiBaseTimePicker);
export default PiBaseTimePicker;
