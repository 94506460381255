var PiBase401_1;
import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-401.view.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-401.view.scss';
let PiBase401 = PiBase401_1 = class PiBase401 extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBase401';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBase401_1?.NAME,
        author: 'yangd',
        title: '401错误组件',
        version: BcVersion,
        updateDate: '2019-05-27',
        description: '',
    };
    //#endregion
    //#region props
    //#endregion
    //#region data
    //#endregion
    //#region computed
    get about() {
        return PiBase401_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
};
PiBase401 = PiBase401_1 = __decorate([
    Component({
        template,
    })
], PiBase401);
export default PiBase401;
