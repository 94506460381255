/**
 * 月份简短名称显示
 */
export const shortMonth = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
/**
 * 月份完整名称显示
 */
export const defMonthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
/**
 * 日期相关操作函数
 */
export const dateFunc = {
    /**
     * 获取日期时间断
     * @param date
     * @returns
     */
    getDuration(date) {
        // how many days of this month
        const dt = new Date(date);
        const month = dt.getMonth();
        // console.log('get month->', month);
        dt.setMonth(dt.getMonth() + 1);
        dt.setDate(0);
        return dt.getDate();
    },
    /**
     * 改变日期中的日
     * @param date
     * @param num
     * @returns
     */
    changeDay(date, num) {
        const dt = new Date(date);
        return new Date(dt.setDate(dt.getDate() + num));
    },
    /**
     * 获取某个日期所在月的第一天日期
     * @param date
     * @returns
     */
    getStartDate(date) {
        // return first day of this month
        return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    /**
     * 获取结束的日期
     * @param date
     * @returns
     */
    getEndDate(date) {
        // get last day of this month
        const dt = new Date(date.getFullYear(), date.getMonth() + 1, 1); // 1st day of next month
        return new Date(dt.setDate(dt.getDate() - 1)); // last day of this month
    },
    /**
     * 格式化日期
     * @param date
     * @param format
     * @param monthNames
     * @returns
     */
    format(date, format, monthNames = defMonthNames) {
        // monthNames = monthNames || defMonthNames;
        if (typeof date === 'string') {
            date = new Date(date.replace(/-/g, '/'));
        }
        else {
            date = new Date(date);
        }
        const map = {
            M: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            m: date.getMinutes(),
            s: date.getSeconds(),
            q: Math.floor((date.getMonth() + 3) / 3),
            S: date.getMilliseconds(),
        };
        format = format.replace(/([yMdhmsqS])+/g, (all, t) => {
            // console.log('all', all, t, format);
            let v = map[t];
            if (v !== undefined) {
                if (all === 'MMMM') {
                    return monthNames[v - 1];
                }
                if (all === 'MMM') {
                    return shortMonth[v - 1];
                }
                if (all.length > 1) {
                    v = '0' + v;
                    v = v.substr(v.length - 2);
                }
                return v;
            }
            else if (t === 'y') {
                return String(date.getFullYear()).substr(4 - all.length);
            }
            return all;
        });
        // console.log('format res', format);
        return format;
    },
};
