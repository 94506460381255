/**
 * 高德 JS API key
 */
// const AMAP_Key = '783505b5ec6f283b3f657f7e217a861b';
const AMAP_Key = '3e5bc254ef49db1f5617256f81cd536d';
//样式id
const AmapStyleId = '90fabfb8c0bedd5c09e36053d4326bce';
/**
 * 请求脚本地址
 */
const SOURCE_URL = 'https://webapi.amap.com/maps?v=1.4.15&key=' + AMAP_Key;
/**
 * UI请求脚本地址
 */
const UI_SOURCE_URL = 'https://webapi.amap.com/ui/1.0/main.js?v=1.0.11';
/**
 * 是否高德js库正在加载中
 */
let loading = false;
/**
 * loadAMap可能为异步加载完才执行，所以保存一个回调栈
 */
let callbackList = [];
/**
 * 加载AMap
 * @param callback
 */
const loadAMap = (callback, piParentNode) => {
    if (typeof AMap === 'undefined') {
        if (callback) {
            callbackList.push(callback);
        }
        if (loading) {
            return;
        }
        loading = true;
        const script = document.createElement('script');
        script.src = SOURCE_URL;
        script.onload = () => {
            loading = false;
            for (let callback of callbackList) {
                callback && callback();
            }
            callbackList = [];
        };
        piParentNode = piParentNode || document.body;
        piParentNode.appendChild(script);
    }
    else {
        callback && callback();
    }
};
export { loadAMap, SOURCE_URL, AmapStyleId };
