import { hODeepClone, hODeepObjectMerge } from 'bc$/main';
import Vue from 'vue';
/**
 * 前端默认树节点结构实例对象
 */
export const defaultPiTreeData = {
    id: '',
    path: [],
    text: undefined,
    value: {},
    icon: false,
    loaded: false,
    level: 0,
    state: {
        opened: false,
        selected: false,
        disabled: false,
        loading: false,
        highlighted: false,
        openable: false,
        dropPosition: 0 /* empty */,
        dropAllowed: false, // 是否允许拖拽
    },
    children: [],
    contextmenu: '',
    component: '',
};
export const defaultPiNode = {
    id: '',
    label: '',
    isDisabled: false,
    isDefaultExpanded: false,
};
/**
 * 将PiTreeData转换为PiNode数据结构
 * @param treeData
 * @returns
 */
export const transPiTreeDataToPiNode = (treeData) => {
    const node = hODeepClone(defaultPiNode);
    node.id = treeData.id;
    node.label = treeData.text || '';
    node.isDisabled = treeData.state.disabled;
    node.icon = treeData.icon || undefined;
    node.contextmenu = treeData.contextmenu;
    node.component = treeData.component;
    // 其他非必要属性置入回PiNode数据的非必要属性中
    // node.isDefaultExpanded = treeData.value.isDefaultExpanded || false;
    const value = treeData.value;
    const keys = Object.keys(value);
    for (const key of keys) {
        node[key] = value[key];
    }
    // 不可展开属性则判定为叶子节点
    if (treeData.state.openable == false) {
        node.children = undefined;
    }
    else if (!treeData.loaded) {
        // 未加载项
        node.children = null;
    }
    else {
        node.children = [];
        const children = treeData.children;
        for (const child of children) {
            node.children.push(transPiTreeDataToPiNode(child));
        }
    }
    return node;
};
/**
 * 将PiNode转换为PiTreeData数据结构 ----静态转换
 * @param data 包含当前节点, 层级值， 路径值
 * @param selectIds 选中的id
 * @returns
 */
export const transPiNodeToPiTreeDataSync = (data, selectIds = [], selectedCollector = {}) => {
    const { node, level, path } = data;
    const treeDataEle = hODeepClone(defaultPiTreeData);
    // PiNode原有的属性字段
    treeDataEle.level = level;
    treeDataEle.path = path;
    treeDataEle.id = node.id;
    treeDataEle.text = node.label;
    treeDataEle.icon = node.icon || false;
    treeDataEle.state.disabled = node.isDisabled || false;
    treeDataEle.contextmenu = node.contextmenu;
    treeDataEle.component = node.component;
    // 当前节点是否处于选中状态
    switch (_toString(selectIds)) {
        case 'Array':
            if (selectIds.includes(node.id)) {
                treeDataEle.state.selected = true;
                // 置入到对应类型的选中收集器
                defineUniProperty(selectedCollector[_toString(node.id)], node.id, treeDataEle);
            }
            else {
                treeDataEle.state.selected = false;
            }
            break;
        case 'Number':
        case 'String':
            if (node.id === selectIds) {
                treeDataEle.state.selected = true;
                // 置入到对应类型的选中收集器
                defineUniProperty(selectedCollector[_toString(node.id)], node.id, treeDataEle);
            }
            else {
                treeDataEle.state.selected = false;
            }
            break;
        default:
            break;
    }
    // 将PiNode对象中的非必要属性字段及值置入到value中
    const excludeKeys = [
        'id',
        'label',
        'children',
        'isDisabled',
        // 'isDefaultExpanded',
        'icon',
        'contextmenu',
        'component',
    ];
    const keys = Object.keys(node);
    const value = {};
    for (const key of keys) {
        if (!excludeKeys.includes(key)) {
            value[key] = node[key];
        }
    }
    treeDataEle.value = value;
    // 由children判定节点性质
    if (node.children === null) {
        // 标记为延迟加载项
        treeDataEle.loaded = false;
        treeDataEle.state.openable = true;
        treeDataEle.children = [];
    }
    else if (node.children === undefined) {
        // 代表当前节点是叶子节点
        treeDataEle.state.openable = false;
        treeDataEle.loaded = true;
        treeDataEle.children = [];
    }
    else if (node.children instanceof Array) {
        // 直接置入children
        treeDataEle.state.openable = true;
        treeDataEle.loaded = true;
        treeDataEle.children = [];
        let index = 0;
        for (const child of node.children) {
            treeDataEle.children.push(transPiNodeToPiTreeDataSync({
                node: child,
                level: level + 1,
                path: path.concat([++index]),
            }, selectIds, selectedCollector));
        }
    }
    return treeDataEle;
};
/**
 * 将PiNode转换为PiTreeData数据结构 ----异步转换
 * @param data 包含当前节点, 层级值， 路径值
 * @param loadOptions 异步加载当前节点的z子节点数据方法
 * @param selectIds 选中的id
 * @returns
 */
export const transPiNodeToPiTreeDataAsync = async (data, loadOptions, selectIds = [], selectedCollector = {}, // 选中的节点收集器
noneText = '没有更多数据了...', errorText = '加载失败请稍后重试...') => {
    const { node, level, path } = data;
    const treeDataEle = hODeepClone(defaultPiTreeData);
    // PiNode原有的属性字段
    treeDataEle.level = level;
    treeDataEle.path = path;
    treeDataEle.id = node.id;
    treeDataEle.text = node.label;
    treeDataEle.icon = node.icon || false;
    treeDataEle.state.disabled = node.isDisabled || false;
    treeDataEle.contextmenu = node.contextmenu;
    treeDataEle.component = node.component;
    // 当前节点是否处于选中
    switch (_toString(selectIds)) {
        case 'Array':
            if (selectIds.includes(node.id)) {
                treeDataEle.state.selected = true;
                // 置入到对应类型的选中收集器
                defineUniProperty(selectedCollector[_toString(node.id)], node.id, treeDataEle);
            }
            else {
                treeDataEle.state.selected = false;
            }
            break;
        case 'Number':
        case 'String':
            if (node.id === selectIds) {
                treeDataEle.state.selected = true;
                // 置入到对应类型的选中收集器
                defineUniProperty(selectedCollector[_toString(node.id)], node.id, treeDataEle);
            }
            else {
                treeDataEle.state.selected = false;
            }
            break;
        default:
            break;
    }
    // 将PiNode对象中的非必要属性字段及值置入到value中
    const excludeKeys = [
        'id',
        'label',
        'children',
        'isDisabled',
        // 'isDefaultExpanded',
        'icon',
        'contextmenu',
        'component',
    ];
    const keys = Object.keys(node);
    const value = {};
    for (const key of keys) {
        if (!excludeKeys.includes(key)) {
            value[key] = node[key];
        }
    }
    treeDataEle.value = value;
    // 由children判定节点性质
    if (node.children === null) {
        treeDataEle.loaded = false; // 标记为未加载项
        treeDataEle.state.openable = true;
        treeDataEle.children = [];
    }
    else if (node.children === undefined) {
        // 代表当前节点是叶子节点
        treeDataEle.state.openable = false;
        treeDataEle.loaded = true;
        treeDataEle.children = [];
    }
    else if (node.children instanceof Array) {
        // 直接置入children
        treeDataEle.state.openable = true;
        treeDataEle.loaded = true;
        treeDataEle.children = [];
        let index = 0;
        for (const child of node.children) {
            treeDataEle.children.push(await transPiNodeToPiTreeDataAsync({ node: child, level: level + 1, path: path.concat([index++]) }, loadOptions, selectIds, selectedCollector, noneText, errorText));
        }
    }
    // 默认展开并且是未加载项则发起异步请求
    if (node.isDefaultExpanded && !treeDataEle.loaded) {
        // 继续往下拉取节点数据
        await asyncPullNodeChildren(treeDataEle, loadOptions, selectIds, selectedCollector, noneText, errorText);
    }
    return treeDataEle;
};
/**
 * 展开某个未加载项节点时的请求方法
 * @param treeDataEle
 * @param loadOptions
 * @param selectIds
 * @param noneText
 * @param errorText
 * @returns
 */
export const asyncPullNodeChildren = async (treeDataEle, loadOptions, selectIds = [], selectedCollector = {}, noneText = '', errorText = '') => {
    // PiTreeData转PiNode
    const origin = transPiTreeDataToPiNode(treeDataEle);
    const { level, path } = treeDataEle;
    // 默认展开并标记为已加载项
    treeDataEle.state.opened = true;
    treeDataEle.loaded = true;
    treeDataEle.children = [];
    // 等待其子数据加载完毕
    let hasError = false;
    let children = [];
    try {
        children = await loadOptions(origin);
    }
    catch (error) {
        // 重新标记为未加载项
        treeDataEle.loaded = false;
        // 添加错误数据提示节点
        const errorDataEle = customizeNodeComponent(errorText, {
            className: 'pi-error-node',
            icon: 'ivu-icon ivu-icon-ios-warning-outline',
        });
        treeDataEle.children = [errorDataEle];
        hasError = true;
    }
    finally {
        if (!hasError) {
            if (!!children && children.length > 0) {
                let index = 0;
                for (const child of children) {
                    const dataEle = await transPiNodeToPiTreeDataAsync({
                        node: child,
                        level: Number(level) + 1,
                        path: path.concat([index++]),
                    }, loadOptions, selectIds, selectedCollector, noneText, errorText);
                    treeDataEle.children.push(dataEle);
                }
            }
            else {
                // 添加空数据提示节点
                const noneDataEle = customizeNodeComponent(noneText, {
                    className: 'pi-none-node',
                    icon: 'ivu-icon ivu-icon-ios-alert-outline',
                });
                treeDataEle.children = [noneDataEle];
            }
        }
    }
    return;
};
/**
 * 定制的提示信息的树节点
 * @param nodeText
 * @param config
 * @returns
 */
export const customizeNodeComponent = (nodeText, config) => {
    const treeDataEle = hODeepClone(defaultPiTreeData);
    treeDataEle.text = nodeText;
    treeDataEle.state.highlighted = true;
    treeDataEle.state.disabled = true;
    treeDataEle.icon = !!config.icon ? config.icon : ``;
    const className = config.className;
    treeDataEle.component = (fn1, fn2) => {
        fn1(Vue.component('Node', {
            props: {
                data: {
                    type: Object,
                    required: true,
                },
            },
            template: `<span class="` + className + `">` + `{{data.text}}</span>`,
        }));
    };
    return treeDataEle;
};
/**
 * 使得两块子树状态保持一致并以后者为基准
 */
export const tryNodesTreeStateBeConsistent = (nodeTree1, nodeTree2) => {
    if (nodeTree1.id == nodeTree2.id) {
        hODeepObjectMerge(nodeTree1.state, nodeTree2.state);
        for (const child1 of nodeTree1.children) {
            const findChild = nodeTree2.children.find((child2) => {
                return child2.id == child1.id;
            });
            if (findChild) {
                tryNodesTreeStateBeConsistent(child1, findChild);
            }
        }
    }
};
/**
 * 定义一个对象的Uni属性
 * @param obj
 * @param key
 * @param value
 */
export const defineUniProperty = (obj, key, value) => {
    if (_toString(obj) === 'Object') {
        // 根据id构建键值对
        Object.defineProperty(obj, key, {
            value: value,
            writable: true,
            configurable: true,
            enumerable: true, // 是否出现在for in或Object.keys()中
        });
    }
};
/**
 * 移除一个对象的Uni属性
 * @param obj
 * @param key
 * @returns
 */
export const removeUniProperty = (obj, key) => {
    if (_toString(obj) === 'Object') {
        const keys = Object.keys(obj);
        if (keys.includes(key)) {
            return delete obj[key];
        }
        else {
            return true;
        }
    }
    return false;
};
/**
 * 清空一个对象所有的Uni属性
 * @param collector
 * @returns
 */
export const clearUniProperties = (obj) => {
    if (_toString(obj) === 'Object') {
        const keys = Object.keys(obj);
        if (keys.length === 0) {
            return true;
        }
        const isClear = [];
        for (const key of keys) {
            isClear.push(delete obj[key]);
        }
        return !isClear.includes(false);
    }
    return false;
};
/**
 * 默认生成16位的UUID值
 */
export const frontUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
/**
 * 获取值的具体打印类型
 * @param val
 * @returns
 */
export const _toString = (val) => {
    const str = Object.prototype.toString.call(val);
    const strType = str.slice(8, -1);
    return strType;
};
/**
 * 是否是空值
 * @param val
 * @returns
 */
export const isUndef = (val) => {
    return val === undefined || val === null;
};
/**
 * 是否是非空值
 * @param val
 * @returns
 */
export const isDef = (val) => {
    return val !== undefined && val !== null;
};
/**
 * 是否是一般类型
 * @param val
 * @returns
 */
export const isPrimitive = (val) => {
    return (typeof val === 'string' ||
        typeof val === 'number' ||
        // $flow-disable-line
        typeof val === 'symbol' ||
        typeof val === 'boolean');
};
/**
 * 是否是对象类型
 * @param obj
 * @returns
 */
export const isObject = (obj) => {
    return obj !== null && typeof obj === 'object';
};
/**
 * 是否是一个Promise
 * @param val
 * @returns
 */
export const isPromise = (val) => {
    return (isDef(val) &&
        typeof val.then === 'function' &&
        typeof val.catch === 'function');
};
/**
 * 是否是异步方法
 * @param fn
 * @returns
 */
export const isAsyncFn = (fn) => {
    return isDef(fn) && _toString(fn) == 'AsyncFunction';
};
/**
 * 是否是方法
 * @param fn
 * @returns
 */
export const isFunction = (fn) => {
    return (isDef(fn) &&
        (_toString(fn) == 'AsyncFunction' || _toString(fn) == 'Function'));
};
