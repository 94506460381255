export class BaseConfigUrl {
    apiUrls = [];
    cdnUrls = [];
    fileUrls = [];
    sonUrl = { value: '' };
    apiPrev = { value: '/api/serv/' };
    cdnPrev = { value: '/api/cdn/' };
    filePrev = { value: '/api/file/' };
}
export const BaseConfigInfo = new BaseConfigUrl();
