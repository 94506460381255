import { __decorate } from "tslib";
import VueContextMenu from 'vue-context-menu';
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
/**
 * 右键菜单
 */
let ContextMenuMixin = class ContextMenuMixin extends VueMixinBase {
    /**
     * 右键菜单弹出事件
     */
    onContextmenu(e) {
        const { contextMenuOptions } = this;
        if (!contextMenuOptions || !contextMenuOptions.enable) {
            return;
        }
        e = e || window.event;
        // 当前被右键点击的元素
        // 兼容ShadowDom JS事件隔离问题
        const composedPathList = e.composedPath && e.composedPath();
        let eventTarget = null;
        if (composedPathList && composedPathList.length) {
            eventTarget = composedPathList[0] || e.target || e.srcElement;
        }
        if (!eventTarget) {
            return;
        }
        // TODO: 根据当前节点显示不同右键菜单，如在设备点右键，显示历史曲线等
        //  const $target = $(eventTarget as HTMLElement);
        //打开右键菜单
        this.$refs['ctx'].open(e);
    }
    /**
     * 挂载右键菜单根节点
     */
    mountedContextMenu() {
        const svgEl = this.$el;
        this.$nextTick(() => {
            // 取得内部承载内容
            const ctx = this.$refs['ctx'];
            const ele = ctx.$el;
            //#region 处理 shadowRoot 下插入元素逻辑
            const rootNode = svgEl.getRootNode();
            const rBody = rootNode.body;
            if (rBody) {
                rBody.appendChild(ele);
            }
            else {
                rootNode.appendChild(ele);
            }
            //#endregion
            ctx.$once('hook:beforeDestroy', () => {
                ele.remove();
            });
        });
    }
};
ContextMenuMixin = __decorate([
    Component({
        components: {
            VueContextMenu,
        },
    })
], ContextMenuMixin);
export default ContextMenuMixin;
