import { AccountInfoEntity } from './account-info.entity';
import { LayoutWidgetEntity } from './layout-widget.entity';
import { WidgetConfigEntity } from './widget-config.entity';
import { WidgetToolbarEntity } from './widget-toolbar.entity';
import { ErrorCodeEntity } from './error-code.entity';
/**
 * API文档接口
 */
class ApiUrlSettingClass {
    /**
     * 用户账户相关接口
     */
    AccountInfo;
    /**
     * 组件实例信息接口
     */
    LayoutWidget;
    /**
     * 组件实例内部数据接口
     */
    WidgetConfig;
    /**
     * 删除组件接口
     */
    WidgetToolbar;
    /**
     * 错误页和登录页地址
     */
    ErrorCode;
}
//设置
const ApiUrlSetting = new ApiUrlSettingClass();
ApiUrlSetting.AccountInfo = new AccountInfoEntity();
ApiUrlSetting.LayoutWidget = new LayoutWidgetEntity();
ApiUrlSetting.WidgetConfig = new WidgetConfigEntity();
ApiUrlSetting.WidgetToolbar = new WidgetToolbarEntity();
ApiUrlSetting.ErrorCode = new ErrorCodeEntity();
export { ApiUrlSetting, AccountInfoEntity, LayoutWidgetEntity, WidgetConfigEntity, WidgetToolbarEntity, ErrorCodeEntity, };
