function hConvertAttr(target, attrJsonProp, attrObjProp) {
    let _attrObj = {};
    const fnConfigObj = (enumerable) => {
        Object.defineProperty(target, attrObjProp, {
            configurable: true,
            enumerable: enumerable,
            get: () => {
                return _attrObj;
            },
        });
    };
    fnConfigObj(false);
    Object.defineProperty(target, attrJsonProp, {
        configurable: true,
        enumerable: true,
        get: () => {
            fnConfigObj(true);
            const value = JSON.stringify(_attrObj);
            fnConfigObj(false);
            return value;
        },
        set: (value) => {
            fnConfigObj(true);
            switch (typeof value) {
                case 'string':
                    _attrObj = JSON.parse(value);
                    break;
                case 'object':
                    _attrObj = value;
                    break;
                default:
                    _attrObj = {};
                    break;
            }
            fnConfigObj(false);
        },
    });
}
export { hConvertAttr };
