import { __decorate } from "tslib";
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
let TableRowMouseEventsMixin = class TableRowMouseEventsMixin extends Vue {
    hoverRowIndex = -1;
    clickRowIndex = -1;
    handleMouseEnter(rowIndex) {
        const that = this;
        if (that.rowHoverColor && that.rowHoverColor.length > 0) {
            this.hoverRowIndex = rowIndex;
        }
        that.rowMouseEnter?.(rowIndex);
    }
    handleMouseOut(rowIndex) {
        const that = this;
        if (that.rowHoverColor && that.rowHoverColor.length > 0) {
            this.hoverRowIndex = -1;
        }
        that.rowMouseLeave?.(rowIndex);
    }
    /*
     * 表头单元格单击事件
     * 注意：如果为复杂表头，field 为数组
     * */
    titleCellClick(field, title) {
        const that = this;
        that.titleClick?.(title, field);
    }
    /*
     * 表头单元格双击事件
     * 注意：如果为复杂表头，field 为数组
     * */
    titleCellDblClick(field, title) {
        const that = this;
        that.titleDblclick?.(title, field);
    }
    // 行单击事件
    rowCellClick(rowIndex, rowData, column) {
        const that = this;
        if (that.rowClickColor && that.rowClickColor.length > 0) {
            this.clickRowIndex = rowIndex;
        }
        that.rowClickCheckGroupChange &&
            that.handelRowClickCheckGroupChange(rowIndex, rowData, column);
        that.rowClick?.(rowIndex, rowData, column);
    }
    // 设置选中行索引
    setClickRowIndex(rowIndex) {
        this.clickRowIndex = rowIndex;
    }
    // 行双击事件
    rowCellDbClick(rowIndex, rowData, column) {
        const that = this;
        that.rowDblclick?.(rowIndex, rowData, column);
        //开启允许双击复制
        that.isAllowDbClickCopyContent && that.dbClickCopyContent(rowData[column.field]);
    }
    /*
     * @method getHighPriorityBgColor 获取高优先级的行背景色 优先级 click color > hover color > 奇偶color > table bg color
     * */
    getHighPriorityBgColor(rowIndex) {
        const that = this;
        let result = '';
        if (this.clickRowIndex === rowIndex) {
            result = that.rowClickColor || '';
        }
        else if (this.hoverRowIndex === rowIndex) {
            result = that.rowHoverColor || '';
        }
        if (result.length <= 0) {
            if ((that.evenBgColor && that.evenBgColor.length > 0) ||
                (that.oddBgColor && that.oddBgColor.length > 0)) {
                result = (rowIndex + 1) % 2 === 0 ? that.evenBgColor : that.oddBgColor;
            }
        }
        if (result.length <= 0) {
            result = that.tableBgColor.toString();
        }
        return result;
    }
    setRowBgColor(newVal, oldVal, color) {
        const that = this;
        let el = this.$el;
        if (!el) {
            return false;
        }
        let rowsCollection = [], oldRow, newRow;
        if (that.hasFrozenColumn) {
            rowsCollection.push(el.querySelectorAll('.v-table-leftview .v-table-row'));
        }
        rowsCollection.push(el.querySelectorAll('.v-table-rightview .v-table-row'));
        rowsCollection.forEach((rows) => {
            oldRow = rows[oldVal];
            newRow = rows[newVal];
            if (oldRow) {
                oldRow.style.backgroundColor = this.getHighPriorityBgColor(oldVal);
            }
            if (newRow) {
                newRow.style.backgroundColor = color || '';
            }
        });
    }
    // 取消当前选中的行
    clearCurrentRow() {
        this.clickRowIndex = -1;
    }
    //#region watch
    'watch.hoverRowIndex'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        const that = this;
        this.setRowBgColor(newVal, oldVal, that.rowHoverColor);
    }
    'watch.clickRowIndex'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        const that = this;
        this.setRowBgColor(newVal, oldVal, that.rowClickColor);
    }
};
__decorate([
    Watch('hoverRowIndex')
], TableRowMouseEventsMixin.prototype, "watch.hoverRowIndex", null);
__decorate([
    Watch('clickRowIndex')
], TableRowMouseEventsMixin.prototype, "watch.clickRowIndex", null);
TableRowMouseEventsMixin = __decorate([
    Component
], TableRowMouseEventsMixin);
export default TableRowMouseEventsMixin;
