var PiBaseAbout_1;
import { __decorate } from "tslib";
import { VueBase } from 'bc$/main';
import { Prop, Component, Mixins } from 'vue-property-decorator';
import { BcVersion } from 'bc$/bases/config.base';
import { Row, Col } from 'iview';
import template from './pi-base-about.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-about.component.scss';
let PiBaseAbout = PiBaseAbout_1 = class PiBaseAbout extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseAbout';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseAbout_1?.NAME,
        author: 'caiqw',
        title: '关于组件',
        version: BcVersion,
        updateDate: '2019-06-04',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 关于信息
     */
    aboutInfo;
    //#endregion
    //#region data
    //#endregion
    //#region computed
    get about() {
        return PiBaseAbout_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
};
__decorate([
    Prop({
        type: Object,
        required: true,
    })
], PiBaseAbout.prototype, "aboutInfo", void 0);
PiBaseAbout = PiBaseAbout_1 = __decorate([
    Component({
        components: {
            Row,
            Col,
        },
        template,
    })
], PiBaseAbout);
export default PiBaseAbout;
