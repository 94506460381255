/**
 * 手机号码验证
 * @param {string} str - 手机号码
 */
function hVCheckPhone(str) {
    if (/^1[3456789]\d{9}$/.test(str)) {
        return true;
    }
    return false;
}
/**
 * 固定电话验证
 * @param str
 */
function hVCheckTelephone(str) {
    if (/^(\(0{1,2}\d{2,3}\)|0{1,2}\d{2,3}-)? ?[1-9]\d{6,8}$/.test(str)) {
        return true;
    }
    return false;
}
/**
 * 400/800服务号码验证
 * @param str
 */
function hVCheckServiceNum(str) {
    if (/^[48]0{2}-?(\d{4}-?\d{3}|\d{3}-?\d{4})$/.test(str)) {
        return true;
    }
    return false;
}
/**
 * 身份证验证
 * @param {string} str - 身份证号
 */
function hVCheckIdCard(str) {
    let reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
    if (reg.test(str)) {
        return true;
    }
    return false;
}
/**
 * 邮箱地址验证
 * @param {string} str - 邮箱
 */
function hVCheckEmail(str) {
    let reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    if (reg.test(str)) {
        return true;
    }
    return false;
}
/**
 * 日期验证
 * @param {string} _str - 日期
 */
function hVCheckDate(date) {
    let result = date.match(/^(\d{1,4})(-|\/|.|_)(\d{1,2})\2(\d{1,2})$/);
    if (result == null) {
        return false;
    }
    let d = new Date(result[1], result[3] - 1, result[4]);
    return (d.getFullYear() == result[1] &&
        d.getMonth() + 1 == result[3] &&
        d.getDate() == result[4]);
}
/**
 * 判断是否为日期格式
 * @param str
 */
function hVCheckDateTime(str) {
    //const reg = '((^((1[8-9]\\d{2})|([2-9]\\d{3}))([-\\/\._])(10|12|0?[13578])([-\\/\._])(3[01]|[12][0-9]|0?[1-9])$)|';
    //reg += '(^((1[8-9]\\d{2})|([2-9]\\d{3}))([-\\/\._])(11|0?[469])([-\\/\._])(30|[12][0-9]|0?[1-9])$)|';
    //reg += '(^((1[8-9]\\d{2})|([2-9]\\d{3}))([-\\/\._])(0?2)([-\\/\._])(2[0-8]|1[0-9]|0?[1-9])$)|';
    //reg += '(^([2468][048]00)([-\\/\._])(0?2)([-\\/\._])(29)$)|(^([3579][26]00)([-\\/\._])(0?2)([-\\/\._])(29)$)|';
    //reg += '(^([1][89][0][48])([-\\/\._])(0?2)([-\\/\._])(29)$)|(^([2-9][0-9][0][48])([-\\/\._])(0?2)([-\\/\._])(29)$)|';
    //reg += '(^([1][89][2468][048])([-\\/\._])(0?2)([-\\/\._])(29)$)|(^([2-9][0-9][2468][048])([-\\/\._])(0?2)([-\\/\._])(29)$)|';
    //reg += '(^([1][89][13579][26])([-\\/\._])(0?2)([-\\/\._])(29)$)|(^([2-9][0-9][13579][26])([-\\/\._])(0?2)([-\\/\._])(29)$))';
    str = str.replace('T', ' ');
    let reg = /^(\d{4})-(\d{1,2})-(\d{1,2}) ?([01]?\d|2[0-4])?:?([0-5]?\d|60)?:?([0-5]?\d|60)?(\+08:00)?$/;
    const result = reg.test(str);
    return result;
}
/**
 * 经度校验
 * -180.0000 ~ 180.0000
 * @param str
 */
function hVCheckLongitude(str) {
    const regExp = /^-?((\d|[1-9]\d|1[0-7]\d)(\.\d+)?|180(\.0+)?)$/;
    return regExp.test(str);
}
/**
 * 纬度校验
 * -90.0000 ~ 90.0000
 * @param str
 */
function hVCheckLatitude(str) {
    const regExp = /^-?((\d|[1-8]\d)(\.\d+)?|90(\.0+)?)$/;
    return regExp.test(str);
}
/**
 * 经纬度校验
 * -180.0000 ~ 180.0000,-90.0000 ~ 90.0000
 * @param str
 */
function hVCheckLngLat(str) {
    const regExp = /^-?((\d|[1-9]\d|1[0-7]\d)(\.\d+)?|180(\.0+)?),-?((\d|[1-8]\d)(\.\d+)?|90(\.0+)?)$/;
    return regExp.test(str);
}
/**
   * 通用密码校验
   * 参照阿里：
    1、6-20位
    2、只能包含大小写字母、数字以及标点符号（除空格、制表符、换行符）
    3、大写字母、小写字母、数字和标点符号至少包含两种
   * @param str
   */
function hVCheckPwd(str) {
    const { length } = str;
    if (!(6 <= length && length <= 20)) {
        return '密码长度为 6 - 20位';
    }
    if (!/[\x21-\x7e]{6,20}/g.test(str)) {
        return '只能包含字母、数字及标点符号（除空格）';
    }
    const regNum = /\d/;
    const regLetterUpper = /[A-Z]/;
    const regLetterLower = /[a-z]/;
    const regChar = /[\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]/;
    const regList = [regNum, regLetterUpper, regLetterLower, regChar];
    let count = 0;
    for (let index = 0, length = regList.length; index < length; index++) {
        const reg = regList[index];
        if (reg.test(str)) {
            count++;
            if (count >= 2) {
                break;
            }
        }
    }
    if (count < 2) {
        return '大、小写字母、数字、符号至少包含两种';
    }
    //校验成功
    return '';
}
export { hVCheckPhone, hVCheckTelephone, hVCheckServiceNum, hVCheckIdCard, hVCheckEmail, hVCheckDate, hVCheckDateTime, hVCheckLongitude, hVCheckLatitude, hVCheckLngLat, hVCheckPwd, };
