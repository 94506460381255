var PiBaseImageUpload_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { Upload, Progress, Icon, Modal, Button } from 'iview';
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase, ConvertUrl, UrlType, hAjaxDelete, hODeepClone, hErrorShow, hReactiveUtil, } from 'bc$/main';
import template from './pi-base-image-upload.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-image-upload.component.scss';
let isDel = false;
let PiBaseImageUpload = PiBaseImageUpload_1 = class PiBaseImageUpload extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseImageUpload';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseImageUpload_1?.NAME,
        author: 'caiQw',
        title: '基础图片选择组件',
        version: BcVersion,
        updateDate: '2019-05-21',
        description: '',
    };
    //#region props
    /**
     * 上传地址
     */
    uploadUrl;
    /**
     *  请求头部
     */
    headers;
    //删除url
    deleteUrl;
    /**
     * 是否手动上传
     */
    isAutoUpload;
    /**
     * 默认显示的图片
     */
    defaultList;
    /**
     * 最大上传图片数量
     */
    maxNum;
    /**
     * 上传图片icon
     */
    uploadIcon;
    /**
     * 查看图片icon
     */
    seeIcon;
    /**
     * 删除图片icon
     */
    deleteIcon;
    /**
     * 是否有查看图片功能
     */
    isHasSee;
    /**
     *  是否有删除图片功能
     */
    isHasDelete;
    //#endregion
    //#region data
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    imageList = [];
    visible = false;
    isModal = true;
    imageSrc = '';
    /**服务器地址 */
    serviceURL = '';
    /**上一次查看图片索引 */
    indexPre = 0;
    //#endregion
    //#region computed
    get about() {
        return PiBaseImageUpload_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * 转换为绝对路径，该路径会根据类型随机获取url 基础地址并添加子站点url
     */
    get currentUrl() {
        return (this.uploadUrl && ConvertUrl(this.uploadUrl, UrlType.Api)) || '';
    }
    //#endregion
    //#region watch
    'watch.defaultList'(newVal, oldVal) {
        (newVal || []).forEach((item) => {
            let imgSrc = ConvertUrl(item.filePath, UrlType.File);
            let fileTemp = {
                fileName: item.fileName,
                filePath: imgSrc,
                thumbnailFilePath: imgSrc,
            };
            this.imageList.push(fileTemp);
        });
    }
    //#endregion
    //#region methods
    /**
     * 查看图片
     * @param name 图片名称
     */
    handleView(file, index) {
        this.indexPre = index;
        this.imageSrc = file.filePath;
        this.visible = true;
        document.onkeyup = (e) => {
            const keycode = document.all ? e.keyCode : e.which;
            if (keycode == 37) {
                // <--按键
                this.handlePervious();
            }
            else if (keycode == 39) {
                // -->按键
                this.handleNext();
            }
            else if (keycode == 27 || keycode == 13) {
                //enter、esc按键
                this.closeModal();
            }
            return false;
        };
    }
    /**查看上一张图片 */
    handlePervious() {
        if (this.indexPre < 0 || this.indexPre > this.imageList.length - 1) {
            this.indexPre = this.imageList.length - 1;
        }
        if (this.indexPre == 0) {
            this.indexPre = this.imageList.length - 1;
        }
        else {
            this.indexPre -= 1;
        }
        this.imageSrc = this.imageList[this.indexPre].filePath;
    }
    /**查看下一张图片 */
    handleNext() {
        if (this.indexPre > this.imageList.length - 1 || this.indexPre < 0) {
            this.indexPre = 0;
        }
        if (this.indexPre == this.imageList.length - 1) {
            this.indexPre = 0;
        }
        else {
            this.indexPre += 1;
        }
        this.imageSrc = this.imageList[this.indexPre].filePath;
    }
    /**
     * 关闭图片模态框
     */
    closeModal() {
        this.visible = false;
        document.onkeypress = null;
    }
    /**
     * 获取图片实际路径
     * @param path 传进来的 图片绝对路径
     */
    getImagePath(path) {
        let prev = this.baseConfigInfo.filePrev.value;
        let reg = new RegExp(`${prev}([^ ]+)`);
        const arr = reg.exec(path);
        return (arr && arr[1]) || '';
    }
    /**
     * 删除文件
     * @param file 文件信息
     */
    handleRemove(file) {
        if (!this.deleteUrl) {
            console.warn('如需删除，可输入删除URL');
        }
        // 判断是否正在删除
        if (isDel) {
            return;
        }
        let delFileUrls = [];
        delFileUrls.push(this.getImagePath(file.filePath));
        let config = {
            params: { fileUrls: delFileUrls },
        };
        const reqUrl = this.convertUrl(this.deleteUrl, UrlType.Api);
        return this.resetAjax('handleRemove', () => {
            isDel = true;
            const ajaxInfo = hAjaxDelete(reqUrl, config);
            ajaxInfo.instance
                .then((res) => {
                const { status, msg, data } = res.data;
                if (!status) {
                    if (!data[0].failureList.length) {
                        // 从 upload 实例删除数据
                        this.imageList.splice(this.imageList.indexOf(file), 1);
                        this.$Message.info('删除成功');
                        let imageList = hODeepClone(this.imageList);
                        imageList.forEach((item) => {
                            let { filePath, thumbnailFilePath } = item;
                            filePath = this.getImagePath(filePath);
                            thumbnailFilePath = this.getImagePath(thumbnailFilePath);
                            item.filePath = filePath;
                            item.thumbnailFilePath = thumbnailFilePath;
                        });
                        this.$emit('handleRemove', imageList);
                    }
                    else {
                        this.$Message.error('删除失败');
                    }
                }
                else {
                    this.$Message.error(msg);
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
                message: '删除失败！',
            }))
                .finally(() => {
                isDel = false;
            })
                .catch((err) => {
                console.error(err);
            });
            return ajaxInfo;
        });
    }
    handleError(err) {
        console.error(err);
        this.$Message.error('上传失败');
    }
    /**
     * 上传成功后的返回新的图片数组
     */
    handleSuccess(res, file, fileList) {
        let { data, msg, status } = res;
        if (!status) {
            if (!data[0]) {
                return;
            }
            file.fileName = data[0].fileName;
            file.filePath = data[0].filePath;
            file.thumbnailFilePath = data[0].thumbnailFilePath;
            let imgSrc = ConvertUrl(file.filePath, UrlType.File);
            let fileTemp = {
                fileName: file.fileName,
                filePath: imgSrc,
                thumbnailFilePath: imgSrc,
            };
            this.imageList.push(fileTemp);
            let imageList = hODeepClone(this.imageList);
            imageList.forEach((item) => {
                let { filePath, thumbnailFilePath } = item;
                filePath = this.getImagePath(filePath);
                thumbnailFilePath = this.getImagePath(thumbnailFilePath);
                item.filePath = filePath;
                item.thumbnailFilePath = thumbnailFilePath;
            });
            this.$emit('handleSuccess', {
                fileList: imageList,
                uploadImages: res,
            });
        }
        else {
            this.$Message.error(msg);
        }
    }
    /**
     * 上传文件格式不正确
     * @param file 文件信息
     */
    handleFormatError(file) {
        this.$Notice.warning({
            title: '文件格式不正确',
            desc: '文件 ' + file.fileName + ' 格式不正确，请上传 jpg 或 png 格式的图片。',
        });
    }
    /**
     * 上传图片大小超出
     * @param file 文件信息
     */
    handleMaxSize(file) {
        this.$Notice.warning({
            title: '超出文件大小限制',
            desc: '文件 ' + file.fileName + ' 太大，不能超过 2M。',
        });
    }
    /**
     * 判断图片个数是否超出
     */
    handleBeforeUpload(file) {
        const check = this.imageList.length < this.maxNum;
        if (!check) {
            this.$Notice.warning({
                title: `最多只能上传 ${this.maxNum} 张图片。`,
            });
            return false;
        }
        if (!this.isAutoUpload) {
            this.$emit('hdUImagesUpload', {
                imageList: this.imageList,
                uploadImages: this.$refs['uploadImages'],
            });
        }
        return this.isAutoUpload;
    }
    //#endregion
    //#region hooks
    created() {
        this.imageList = hODeepClone(this.defaultList);
    }
};
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseImageUpload.prototype, "uploadUrl", void 0);
__decorate([
    Prop({
        type: Object,
    })
], PiBaseImageUpload.prototype, "headers", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseImageUpload.prototype, "deleteUrl", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseImageUpload.prototype, "isAutoUpload", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseImageUpload.prototype, "defaultList", void 0);
__decorate([
    Prop({
        type: Number,
        default: 10,
    })
], PiBaseImageUpload.prototype, "maxNum", void 0);
__decorate([
    Prop({
        type: String,
        default: 'ios-camera',
    })
], PiBaseImageUpload.prototype, "uploadIcon", void 0);
__decorate([
    Prop({
        type: String,
        default: 'ios-eye-outline',
    })
], PiBaseImageUpload.prototype, "seeIcon", void 0);
__decorate([
    Prop({
        type: String,
        default: 'ios-close',
    })
], PiBaseImageUpload.prototype, "deleteIcon", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseImageUpload.prototype, "isHasSee", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseImageUpload.prototype, "isHasDelete", void 0);
__decorate([
    Watch('defaultList', { immediate: true, deep: true })
], PiBaseImageUpload.prototype, "watch.defaultList", null);
PiBaseImageUpload = PiBaseImageUpload_1 = __decorate([
    Component({
        components: {
            Upload,
            Progress,
            Icon,
            Modal,
            Button,
        },
        template,
    })
], PiBaseImageUpload);
export default PiBaseImageUpload;
