import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { debounce } from 'lodash';
import Vue from 'vue';
/**
 * Window事件
 */
let WindowEventMixin = class WindowEventMixin extends Vue {
    /**
     *
     */
    resizeDebounced;
    /**
     * 防抖等待毫秒数
     */
    resizeDebounceWait = 200;
    resizeCount = 0;
    /**
     * 组件实例创建后，添加resize监听
     */
    created() {
        this.resizeDebounced = debounce(() => {
            this.onWindowResize();
            this.resizeCount++;
        }, this.resizeDebounceWait);
        window.addEventListener('resize', this.debounceResize);
    }
    /**
     * 组件销毁前，移除resize监听
     */
    beforeDestroy() {
        window.removeEventListener('resize', this.debounceResize);
    }
    /**
     * resize注册、注销函数
     */
    debounceResize() {
        this.resizeDebounced();
    }
    /**
     * window resize事件
     * 父类未实现，需要子类重写
     */
    onWindowResize() { }
};
WindowEventMixin = __decorate([
    Component
], WindowEventMixin);
export { WindowEventMixin };
