import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
/*
 * 排序
 */
let SortControlMixin = class SortControlMixin extends Vue {
    sortColumns = {};
    sortArr = [];
    // 是否允许排序
    enableSort(val) {
        return typeof val === 'string' ? true : false;
    }
    // 允许排序的列集合
    setSortColumns() {
        const that = this;
        const sortColumns = {}, titleRowsToSortInfo = [];
        if (that.internalTitleRows.length > 0) {
            that.internalTitleRows.filter(function (row) {
                row.filter(function (column, index) {
                    if (typeof column.orderBy === 'string' &&
                        column.fields.length === 1) {
                        column.field = column.fields[0];
                        titleRowsToSortInfo.push(column);
                    }
                });
            });
        }
        let collection = titleRowsToSortInfo.length > 0
            ? titleRowsToSortInfo
            : that.internalColumns;
        collection.filter((item, index) => {
            if (this.enableSort(item.orderBy)) {
                sortColumns[item.field] = item.orderBy;
            }
        });
        this.sortColumns = sortColumns;
        this.singleSortInit();
    }
    // 获取当前排序规则
    getCurrentSort(field) {
        return this.sortColumns[field];
    }
    // 排序控制
    sortControl(field) {
        const that = this;
        let orderBy = this.sortColumns[field];
        if (this.enableSort(orderBy)) {
            if (that.sortAlways) {
                this.sortColumns[field] = orderBy === 'asc' ? 'desc' : 'asc';
            }
            else {
                this.sortColumns[field] =
                    orderBy === 'asc'
                        ? 'desc'
                        : this.sortColumns[field] === 'desc'
                            ? ''
                            : 'asc';
            }
            if (!that.multipleSort) {
                for (let col in this.sortColumns) {
                    if (col !== field) {
                        this.sortColumns[col] = '';
                    }
                }
            }
            const { sortArr } = this;
            if (!that.multipleSort) {
                sortArr.splice(0);
            }
            else {
                const index = sortArr.findIndex((p) => p.field === field);
                if (~index) {
                    sortArr.splice(index, 1);
                }
            }
            const dir = this.sortColumns[field];
            if (dir) {
                sortArr.push({ field, dir });
            }
            this.$emit('sort-change', this.sortColumns, sortArr);
        }
    }
    // 单排时只允许保留第一个排序规则（‘asc’或者‘desc’）
    singleSortInit() {
        const that = this;
        let self = this, result = false;
        if (!that.multipleSort && self.sortColumns) {
            for (let col in self.sortColumns) {
                if (result) {
                    self.sortColumns[col] = '';
                }
                result = true;
            }
        }
    }
    // 对外暴露的方法（重置排序规则）
    resetOrder() {
        this.setSortColumns();
        this.$emit('sort-change', this.sortColumns);
    }
};
SortControlMixin = __decorate([
    Component
], SortControlMixin);
export default SortControlMixin;
