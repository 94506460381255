var PiBaseLoading_1;
import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import template from './pi-base-loading.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-loading.component.scss';
let PiBaseLoading = PiBaseLoading_1 = class PiBaseLoading extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseLoading';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseLoading_1?.NAME,
        author: 'wuzp',
        title: '基础加载中组件',
        version: BcVersion,
        updateDate: '2019-05-22',
        description: '',
    };
    //#endregion
    //#region data
    lineNumbers = 1;
    //#endregion
    //#region computed
    get about() {
        return PiBaseLoading_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /** 根据index改变长方形class
     * @param[index]为第几个长方形
     */
    generateClassName(index) {
        return 'pi-loading-block' + index;
    }
    //#endregion
    //#region hooks
    mounted() {
        //let height = (this.$refs.piLoadingContainer as HTMLElement).clientHeight;
        // if (document.getElementById("piLoadingContainer") !== null) {
        //   let height = (document.getElementById('piLoadingContainer') as HTMLElement).clientHeight;
        // }
        this.$nextTick(() => {
            let height = this.$refs.piLoadingContainer
                .clientHeight;
            this.lineNumbers = height > 0 ? Math.floor((height + 10) / 25) : 1;
        });
    }
};
PiBaseLoading = PiBaseLoading_1 = __decorate([
    Component({
        template,
    })
], PiBaseLoading);
export default PiBaseLoading;
