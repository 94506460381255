import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { default as Utils } from '../utils/utils';
/*
 * 列自适应
 */
let TableResizeMixin = class TableResizeMixin extends Vue {
    resizeColumns = []; // 所有需要自适应的列集合
    initTotalColumnsWidth = 0; // 所有列初始化时的总宽度
    hasContainerWidth = false; // 容器是否有宽度（display：none 时没有）
    noHorizontalScroll = false; //是否显示滚动条
    // containerWidthCheckTimer: number | null | undefined = null;
    // 获取所有自适应列的集合
    getResizeColumns() {
        const that = this;
        const result = [];
        that.internalColumns.forEach((item) => {
            if (item.isResize) {
                result.push({
                    width: item.width,
                    field: item.field,
                });
            }
        });
        this.resizeColumns = result;
    }
    // 初始化
    initResizeColumns() {
        const that = this;
        this.initTotalColumnsWidth = that.totalColumnsWidth;
        this.getResizeColumns();
    }
    // // 如果初始化时document上包含滚动条，渲染完document滚动条消失会造成表格宽度计算有误的问题
    // containerWidthCheck() {
    //   this.containerWidthCheckTimer = setTimeout(() => {
    //     let tableContainerWidth = this.$el.clientWidth;
    //     // 3为容错值
    //     if (tableContainerWidth - this.internalWidth > 3) {
    //       this.resize();
    //     }
    //   });
    // }
    // 目前适用于有横向自适应功能的表格
    adjustHeight(hasScrollBar) {
        const that = this;
        if (!this.$el || that.isVerticalResize) {
            return false;
        }
        const totalColumnsHeight = that.getTotalColumnsHeight(), scrollbarWidth = that.scrollbarWidth;
        // 有footer 功能
        if (that.hasTableFooter) {
            if (hasScrollBar) {
                if (that.footerTotalHeight === that.getFooterTotalRowHeight) {
                    that.footerTotalHeight += scrollbarWidth;
                    if (!(that.height && that.height > 0) ||
                        that.height > totalColumnsHeight) {
                        that.internalHeight += scrollbarWidth;
                    }
                }
            }
            else if (!hasScrollBar) {
                if (that.footerTotalHeight > that.getFooterTotalRowHeight) {
                    that.footerTotalHeight -= scrollbarWidth;
                    if (!(that.height && that.height > 0) ||
                        that.height > totalColumnsHeight) {
                        that.internalHeight -= scrollbarWidth;
                    }
                }
            }
        }
        // 当没有设置高度时计算总高度 || 设置的高度大于所有列高度之和时
        else if (!(that.height && that.height > 0) ||
            that.height > totalColumnsHeight) {
            if (hasScrollBar &&
                that.internalHeight + 2 < totalColumnsHeight + scrollbarWidth) {
                that.internalHeight += scrollbarWidth;
            }
            else if (!hasScrollBar) {
                that.internalHeight = that.getTotalColumnsHeight();
            }
        }
    }
    // 随着窗口改变表格自适应
    tableResize() {
        const that = this;
        if (!that.isHorizontalResize && !that.isVerticalResize) {
            return false;
        }
        let view = this.$el;
        let viewPar = view.parentElement;
        if (!viewPar) {
            return;
        }
        let currentWidth = 0;
        let currentHeight = 0;
        if (that.isHorizontalResize && that.isVerticalResize) {
            //横向纵向都自适应就获取父级宽高
            currentWidth =
                viewPar.getBoundingClientRect !== undefined
                    ? viewPar.getBoundingClientRect().width
                    : viewPar.clientWidth;
            currentHeight =
                viewPar.getBoundingClientRect !== undefined
                    ? viewPar.getBoundingClientRect().height
                    : viewPar.clientHeight;
        }
        else if (that.isHorizontalResize) {
            //仅仅横向自适应,则仅仅横向使用父级宽度
            currentWidth =
                viewPar.getBoundingClientRect !== undefined
                    ? viewPar.getBoundingClientRect().width
                    : viewPar.clientWidth;
            currentHeight =
                view.getBoundingClientRect !== undefined
                    ? view.getBoundingClientRect().height
                    : view.clientHeight;
        }
        else if (that.isVerticalResize) {
            //仅仅纵向自适应,则仅仅纵向使用父级宽度
            currentWidth =
                view.getBoundingClientRect !== undefined
                    ? view.getBoundingClientRect().width
                    : view.clientWidth;
            currentHeight =
                viewPar.getBoundingClientRect !== undefined
                    ? viewPar.getBoundingClientRect().height
                    : viewPar.clientHeight;
        }
        let totalColumnsHeight = that.getTotalColumnsHeight(), maxWidth = that.maxWidth, maxHeight = that.height && that.height > 0 ? that.height : totalColumnsHeight, minWidth = that.propReactiveProxy.minWidth, minHeight = that.propReactiveProxy.minHeight > totalColumnsHeight
            ? totalColumnsHeight
            : that.propReactiveProxy.minHeight, viewOffset = Utils.getViewportOffset(view), 
        // bottom =
        //   window.document.documentElement.clientHeight -
        //   currentHeight -
        //   viewOffset.top -
        //   2,
        viewParStyles = getComputedStyle(viewPar), viewParContentHeight = Number(viewParStyles.height.slice(0, -2) || 0), viewParPaddingVer = Number(viewParStyles.paddingTop.slice(0, -2) || 0) +
            Number(viewParStyles.paddingBottom.slice(0, -2) || 0), bottom = viewParContentHeight - viewParPaddingVer - 2, bottom2 = viewOffset.bottom2, scrollbarWidth = that.scrollbarWidth;
        if (that.isHorizontalResize &&
            that.internalWidth &&
            that.internalWidth > 0 &&
            currentWidth > 0) {
            currentWidth = currentWidth > maxWidth ? maxWidth : currentWidth;
            currentWidth = currentWidth < minWidth ? minWidth : currentWidth;
            // if (currentWidth >= 10000 && view.parentElement) {
            //   currentWidth = view.parentElement.getBoundingClientRect().width;
            // }
            that.internalWidth = currentWidth;
        }
        if (that.isVerticalResize && currentHeight > 0) {
            bottom -= that.verticalResizeOffset;
            currentHeight = currentHeight + bottom; // - this.VerticalResizeOffset;
            currentHeight = currentHeight > maxHeight ? maxHeight : currentHeight;
            currentHeight = currentHeight < minHeight ? minHeight : currentHeight;
            // 有横向滚动条
            if (currentWidth <= this.initTotalColumnsWidth && !that.isTableEmpty) {
                bottom2 -= that.verticalResizeOffset;
                let differ = bottom2 - totalColumnsHeight;
                // 高度足够（table 顶部到文档底部的高度 > 表格高度+滚动条高度）
                if (bottom2 > totalColumnsHeight + scrollbarWidth) {
                    currentHeight += scrollbarWidth;
                }
                else if (differ > 0 && differ < scrollbarWidth) {
                    currentHeight += differ;
                }
            }
            //修复offset计算问题,高度采用获取父级元素方式
            const parent = this.$el.parentElement;
            if (parent) {
                if (that.isFooterFollow) {
                    currentHeight = Math.min(currentHeight, parent.clientHeight);
                }
                else {
                    currentHeight = parent.clientHeight;
                }
            }
            that.internalHeight = currentHeight;
        }
        this.changeColumnsWidth(currentWidth);
    }
    // 改变所有需要自适应列的宽度
    changeColumnsWidth(currentWidth) {
        const that = this;
        //与原js不同,Math.floor处理小数宽度导致计算不正确的bug
        let differ = Math.floor(currentWidth - that.totalColumnsWidth), initResizeWidths = Math.floor(this.initTotalColumnsWidth), rightViewBody = this.$el.querySelector('.v-table-rightview .v-table-body'), rightViewFooter = this.$el.querySelector('.v-table-rightview .v-table-footer');
        if (currentWidth <= initResizeWidths && !that.isTableEmpty) {
            // 排除表格无数据的影响
            if (that.hasTableFooter) {
                rightViewFooter.style.overflowX = 'scroll';
            }
            else {
                rightViewBody.style.overflowX = 'scroll';
            }
            this.adjustHeight(true);
        }
        else {
            // 防止最后一列右距中时内容显示不全
            if (that.getTotalColumnsHeight() > that.internalHeight) {
                differ -= that.scrollbarWidth;
            }
            if (that.hasTableFooter) {
                rightViewFooter.style.overflowX = 'hidden';
            }
            else {
                rightViewBody.style.overflowX = 'hidden';
            }
            this.adjustHeight(false);
        }
        //修复无故出现滚动条的bug
        if (that.hasFrozenColumn) {
            differ -= 2;
        }
        if (currentWidth >= initResizeWidths || differ > 0) {
            this.noHorizontalScroll = true;
            this.setColumnsWidth(differ);
        }
        else {
            this.noHorizontalScroll = false;
            // 最小化有滚动条时
            that.columns.forEach((col, index) => {
                if (col.isResize) {
                    that.internalColumns[index].width = col.width;
                }
            });
        }
        // this.containerWidthCheck();
    }
    /*
     * 自适应时给列设置宽度
     * 备注：浏览器 px 必须精确到整数
     * */
    setColumnsWidth(differ) {
        const that = this;
        let resizeColumnsLen = this.resizeColumns.length, average = Math.floor(differ / resizeColumnsLen), totalAverage = average * resizeColumnsLen, leftAverage = differ - totalAverage, leftAverageFloor = Math.floor(leftAverage), averageColumnsWidthArr = new Array(resizeColumnsLen).fill(average), index = 0;
        // 剩余的宽度以整数的形式平均到每个列
        for (let i = 0; i < leftAverageFloor; i++) {
            averageColumnsWidthArr[i] += 1;
        }
        // 剩余的小数给最后一列
        averageColumnsWidthArr[resizeColumnsLen - 1] +=
            leftAverage - leftAverageFloor;
        that.internalColumns.map((item) => {
            if (item.isResize) {
                item.width += averageColumnsWidthArr[index++];
            }
            return item;
        });
    }
    mounted() {
        // Utils.bind(window as any, 'resize', this.resize);
    }
    beforeDestroy() {
        // Utils.unbind(window as any, 'resize', this.resize);
        // clearTimeout(this.containerWidthCheckTimer as number);
    }
};
TableResizeMixin = __decorate([
    Component
], TableResizeMixin);
export default TableResizeMixin;
