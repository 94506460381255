var PiBaseCalendar_1;
import { __decorate } from "tslib";
import { BcVersion, hODeepObjectMerge, PiBaseCalendarModeEnum, VueBase, } from 'bc$/main';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-calendar.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-calendar.component.scss';
export { PiBaseCalendarModeEnum } from './pi-base-calendar.enum';
let PiBaseCalendar = PiBaseCalendar_1 = class PiBaseCalendar extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseCalendar';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseCalendar_1?.NAME,
        author: 'luot',
        title: '日历组件',
        version: BcVersion,
        updateDate: '2020-09-16',
        description: '',
    };
    //#endregion
    //#region props
    /**
     *
     */
    optionsProp;
    /**
     * 日期扩展数据
     */
    extendDataList;
    /**
     *
     */
    slotCompMountedCount;
    //#endregion
    //#region data
    /**
     * 参数
     */
    options = {
        year: new Date().getFullYear(),
        mode: PiBaseCalendarModeEnum.View,
    };
    /**
     * 年数据
     */
    annual = {
        '1月': { interval: [], values: [] },
        '2月': { interval: [], values: [] },
        '3月': { interval: [], values: [] },
        '4月': { interval: [], values: [] },
        '5月': { interval: [], values: [] },
        '6月': { interval: [], values: [] },
        '7月': { interval: [], values: [] },
        '8月': { interval: [], values: [] },
        '9月': { interval: [], values: [] },
        '10月': { interval: [], values: [] },
        '11月': { interval: [], values: [] },
        '12月': { interval: [], values: [] },
    };
    /**
     * 所有日期集合 [2010-01-01,...,2010-12-31]
     */
    allDateList = [];
    isShowTable = false;
    /**
     * v-select 参数
     */
    vSelectOption = {
        boundingBoxSelector: '.pi-base-calendar-wrap',
        selectBoxSelector: '.pi-calendar-selection',
        // scrollDocumentEnabled: true,
        selectedSetter: this.onSelected,
        selectingSetter: this.onSelecting,
        selected: [],
        selecting: [],
        _piEnable: false,
    };
    /**
     * 判断动态组件加载状态
     */
    dynCompData = {
        count: 366,
        mountedCount: 0,
    };
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseCalendar_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    /**
     * 年
     */
    get yearVal() {
        const year = this.options.year;
        return year || new Date().getFullYear();
    }
    // /**
    //  * 是否处于编辑状态（可通过鼠标选择）
    //  */
    // get isSelectable() {
    //   const { options } = this;
    //   return options ? options.mode === PiBaseCalendarMode.selectable : false;
    // }
    /**
     *日历一维数据
     */
    get calendarFlatData() {
        const result = [];
        function flatAnnual(data) {
            if (!data) {
                return;
            }
            const keys = Object.getOwnPropertyNames(data);
            for (let i = 0, length = keys.length; i < length; i++) {
                const item = keys[i];
                if (item === '__ob__') {
                    continue;
                }
                const { values } = data[item];
                result.push(...values);
            }
        }
        flatAnnual(this.annual);
        return result;
    }
    //#endregion
    //#region watch
    /**
     *
     * @param newVal
     * @param oldVal
     */
    'watch.optionsProp'(newVal, oldVal) {
        this.options = hODeepObjectMerge(this.options, this.optionsProp);
        if (newVal) {
            this.getData();
        }
        else {
            // 重置 options
            this.options = {
                year: new Date().getFullYear(),
                mode: PiBaseCalendarModeEnum.View,
            };
            this.getData();
        }
    }
    /**
     * 更新日期单元格绑定的selected数据
     *
     * @param newVal
     * @param oldVal
     */
    'watch.vSelectOption.selected'(newVal, oldVal) {
        // 更新 annual->values->selected 值
        const { calendarFlatData } = this;
        if (newVal && newVal.length) {
            newVal.forEach((selected, index) => {
                const data = calendarFlatData[index];
                // 更新选中状态
                data.selected = selected;
                calendarFlatData.splice(index, 1, data);
            });
        }
    }
    /**
     * 更新日期单元格绑定的selecting数据
     *
     * @param newVal
     * @param oldVal
     */
    'watch.vSelectOption.selecting'(newVal, oldVal) {
        // 更新 annual->values->selected 值
        const { calendarFlatData } = this;
        if (newVal && newVal.length) {
            newVal.forEach((selecting, index) => {
                const data = calendarFlatData[index];
                // 更新选中状态
                data.selecting = selecting;
                calendarFlatData.splice(index, 1, data);
            });
        }
    }
    /**
     * 设置可拖选的dom元素
     *
     * @param newVal
     * @param oldVal
     */
    'watch.slotCompMountedCount'(newVal, oldVal) {
        // 非编辑模式不执行
        if (this.options.mode !== PiBaseCalendarModeEnum.Selectable) {
            return;
        }
        // 更新 annual->values->selected 值
        const dataLen = this.calendarFlatData ? this.calendarFlatData.length : 0;
        if (dataLen && newVal >= dataLen) {
            this.setItemSelectable();
        }
    }
    /**
     * 设置可拖选的dom元素
     *
     * @param newVal
     * @param oldVal
     */
    'watch.extendDataList'(newVal, oldVal) {
        this.getData();
    }
    //#endregion
    //#region methods
    /**
     *  获取扩展数据
     *
     * @param date
     */
    filterExtendData(dateStr) {
        const { extendDataList } = this;
        if (!extendDataList || !extendDataList.length) {
            return;
        }
        let result = undefined;
        try {
            const dateObj = new Date(dateStr);
            const dateVal = dateObj.valueOf();
            result = extendDataList.find((item) => {
                const tmpDate = item.date;
                const tmpDateObj = new Date(new Date(tmpDate).format('yyyy-MM-dd'));
                if (tmpDateObj.valueOf() === dateVal) {
                    return true;
                }
            });
        }
        catch (error) {
            console.error(`基础组件-日历解析数据报错：${error}`);
        }
        return result;
    }
    /**
     * 绑定图表数据
     */
    updateAnnual(date) {
        // 所有日期集合 [2020-01-01,...,2020-12-31];
        const allDateList = [];
        let lunarInfo = [
            0x04bd8,
            0x04ae0,
            0x0a570,
            0x054d5,
            0x0d260,
            0x0d950,
            0x16554,
            0x056a0,
            0x09ad0,
            0x055d2,
            0x04ae0,
            0x0a5b6,
            0x0a4d0,
            0x0d250,
            0x1d255,
            0x0b540,
            0x0d6a0,
            0x0ada2,
            0x095b0,
            0x14977,
            0x04970,
            0x0a4b0,
            0x0b4b5,
            0x06a50,
            0x06d40,
            0x1ab54,
            0x02b60,
            0x09570,
            0x052f2,
            0x04970,
            0x06566,
            0x0d4a0,
            0x0ea50,
            0x06e95,
            0x05ad0,
            0x02b60,
            0x186e3,
            0x092e0,
            0x1c8d7,
            0x0c950,
            0x0d4a0,
            0x1d8a6,
            0x0b550,
            0x056a0,
            0x1a5b4,
            0x025d0,
            0x092d0,
            0x0d2b2,
            0x0a950,
            0x0b557,
            0x06ca0,
            0x0b550,
            0x15355,
            0x04da0,
            0x0a5d0,
            0x14573,
            0x052d0,
            0x0a9a8,
            0x0e950,
            0x06aa0,
            0x0aea6,
            0x0ab50,
            0x04b60,
            0x0aae4,
            0x0a570,
            0x05260,
            0x0f263,
            0x0d950,
            0x05b57,
            0x056a0,
            0x096d0,
            0x04dd5,
            0x04ad0,
            0x0a4d0,
            0x0d4d4,
            0x0d250,
            0x0d558,
            0x0b540,
            0x0b5a0,
            0x195a6,
            0x095b0,
            0x049b0,
            0x0a974,
            0x0a4b0,
            0x0b27a,
            0x06a50,
            0x06d40,
            0x0af46,
            0x0ab60,
            0x09570,
            0x04af5,
            0x04970,
            0x064b0,
            0x074a3,
            0x0ea50,
            0x06b58,
            0x055c0,
            0x0ab60,
            0x096d5,
            0x092e0,
            0x0c960,
            0x0d954,
            0x0d4a0,
            0x0da50,
            0x07552,
            0x056a0,
            0x0abb7,
            0x025d0,
            0x092d0,
            0x0cab5,
            0x0a950,
            0x0b4a0,
            0x0baa4,
            0x0ad50,
            0x055d9,
            0x04ba0,
            0x0a5b0,
            0x15176,
            0x052b0,
            0x0a930,
            0x07954,
            0x06aa0,
            0x0ad50,
            0x05b52,
            0x04b60,
            0x0a6e6,
            0x0a4e0,
            0x0d260,
            0x0ea65,
            0x0d530,
            0x05aa0,
            0x076a3,
            0x096d0,
            0x04bd7,
            0x04ad0,
            0x0a4d0,
            0x1d0b6,
            0x0d250,
            0x0d520,
            0x0dd45,
            0x0b5a0,
            0x056d0,
            0x055b2,
            0x049b0,
            0x0a577,
            0x0a4b0,
            0x0aa50,
            0x1b255,
            0x06d20,
            0x0ada0,
        ];
        let Animals = [
            `鼠`,
            `牛`,
            `虎`,
            `兔`,
            `龙`,
            `蛇`,
            `马`,
            `羊`,
            `猴`,
            `鸡`,
            `狗`,
            `猪`,
        ];
        let Gan = [`甲`, `乙`, `丙`, `丁`, `戊`, `己`, `庚`, `辛`, `壬`, `癸`];
        let Zhi = [
            `子`,
            `丑`,
            `寅`,
            `卯`,
            `辰`,
            `巳`,
            `午`,
            `未`,
            `申`,
            `酉`,
            `戌`,
            `亥`,
        ];
        let now = null;
        let SY = null;
        let SM = null;
        let SD = null;
        function getDateList(yy, mm, dd) {
            if (yy) {
                now = new Date(yy, mm, dd);
            }
            else {
                now = new Date();
            }
            SY = now.getFullYear();
            SM = now.getMonth();
            SD = now.getDate();
            return setCalendar.call(this);
        }
        //==== 传入 offset 传回干支, 0=甲子
        function cyclical(num) {
            return Gan[num % 10] + Zhi[num % 12];
        }
        //==== 传回农历 y年的总天数
        function lYearDays(y) {
            let i, sum = 348;
            for (i = 0x8000; i > 0x8; i >>= 1) {
                sum += lunarInfo[y - 1900] & i ? 1 : 0;
            }
            return sum + leapDays(y);
        }
        //==== 传回农历 y年闰月的天数
        function leapDays(y) {
            if (leapMonth(y)) {
                return lunarInfo[y - 1900] & 0x10000 ? 30 : 29;
            }
            else {
                return 0;
            }
        }
        //==== 传回农历 y年闰哪个月 1-12 , 没闰传回 0
        function leapMonth(y) {
            return lunarInfo[y - 1900] & 0xf;
        }
        //====================================== 传回农历 y年m月的总天数
        function monthDays(y, m) {
            return lunarInfo[y - 1900] & (0x10000 >> m) ? 30 : 29;
        }
        //==== 算出农历, 传入日期物件, 传回农历日期物件
        //     该物件属性有 .year .month .day .isLeap .yearCyl .dayCyl .monCyl
        function Lunar(objDate) {
            let i, leap = 0, temp = 0;
            let baseDate = new Date(1900, 0, 31);
            let offset = (objDate.getTime() - baseDate.getTime()) / 86400000;
            this.dayCyl = Math.round(offset) + 40;
            this.monCyl = 14;
            for (i = 1900; i < 2050 && offset > 0; i++) {
                temp = lYearDays(i);
                offset -= temp;
                this.monCyl += 12;
            }
            if (offset < 0) {
                offset += temp;
                i--;
                this.monCyl -= 12;
            }
            this.year = i;
            this.yearCyl = i - 1864;
            leap = leapMonth(i); //闰哪个月
            this.isLeap = false;
            for (i = 1; i < 13 && offset > 0; i++) {
                //闰月
                if (leap > 0 && i == leap + 1 && this.isLeap == false) {
                    --i;
                    this.isLeap = true;
                    temp = leapDays(this.year);
                }
                else {
                    temp = monthDays(this.year, i);
                }
                //解除闰月
                if (this.isLeap == true && i == leap + 1) {
                    this.isLeap = false;
                }
                offset -= temp;
                if (this.isLeap == false) {
                    this.monCyl++;
                }
            }
            if (offset == 0 && leap > 0 && i == leap + 1) {
                if (this.isLeap) {
                    this.isLeap = false;
                }
                else {
                    this.isLeap = true;
                    --i;
                    --this.monCyl;
                }
            }
            if (offset < 0) {
                offset += temp;
                --i;
                --this.monCyl;
            }
            this.month = i;
            this.day = offset + 1;
        }
        function YYMMDD() {
            let isVacation = false;
            return {
                date: SY +
                    '-' +
                    (parseInt(SM) < 9 ? '0' + (SM + 1) : SM + 1) +
                    '-' +
                    (parseInt(SD) < 10 ? '0' + SD : SD),
                year: SY,
                month: SM + 1,
                day: SD,
            };
        }
        function weekday() {
            let day = [
                '星期日',
                '星期一',
                '星期二',
                '星期三',
                '星期四',
                '星期五',
                '星期六',
            ];
            let isVacation = false;
            if (now.getDay() == 6) {
                isVacation = true;
            }
            if (now.getDay() == 0) {
                isVacation = true;
            }
            return {
                week: day[now.getDay()],
                interval: now.getDay() === 0 ? 6 : now.getDay() - 1,
                isVacation: isVacation,
            };
        }
        //==== 中文日期
        function cDay(m, d) {
            let nStr1 = [
                '日',
                '一',
                '二',
                '三',
                '四',
                '五',
                '六',
                '七',
                '八',
                '九',
                '十',
            ];
            let nStr2 = ['初', '初十', '廿', '卅', '　'];
            let s = '';
            let r = '';
            if (m > 10) {
                s = '十' + nStr1[m - 10];
            }
            else {
                s = nStr1[m];
            }
            s += `月`;
            switch (d) {
                case 10:
                    r = `初十`;
                    break;
                case 20:
                    r += `二十`;
                    break;
                case 30:
                    r += `三十`;
                    break;
                default:
                    r += nStr2[Math.floor(d / 10)];
                    r += nStr1[Math.floor(d % 10)];
            }
            return { s: s, r: r };
        }
        function solarDay1() {
            let sDObj = new Date(SY, SM, SD);
            let lDObj = new Lunar(sDObj);
            let Zodiac = '【' + Animals[(SY - 4) % 12] + '】', BSMonths = cyclical(lDObj.monCyl) + `月 `, agriculDay = cyclical(lDObj.dayCyl++) + '日';
            return { Zodiac: Zodiac, BSMonths: BSMonths, agriculDay: agriculDay };
        }
        function solarDay2() {
            let sDObj = new Date(SY, SM, SD);
            let lDObj = new Lunar(sDObj);
            //农历BB'+(cld[d].isLeap?'闰 ':' ')+cld[d].lMonth+' 月 '+cld[d].lDay+' 日
            let calendarYear = cyclical(SY - 1900 + 36) + `年 `, calendar = cDay.call(this, lDObj.month, lDObj.day);
            return {
                calendarYear: calendarYear,
                calendarMonth: calendar.s,
                calendarDay: calendar.r,
            };
        }
        function solarDay3() {
            let sTermInfo = [
                0,
                21208,
                42467,
                63836,
                85337,
                107014,
                128867,
                150921,
                173149,
                195551,
                218072,
                240693,
                263343,
                285989,
                308563,
                331033,
                353350,
                375494,
                397447,
                419210,
                440795,
                462224,
                483532,
                504758,
            ];
            let solarTerm = [
                `小寒`,
                `大寒`,
                `立春`,
                `雨水`,
                `惊蛰`,
                `春分`,
                `清明`,
                `谷雨`,
                `立夏`,
                `小满`,
                `芒种`,
                `夏至`,
                `小暑`,
                `大暑`,
                `立秋`,
                `处暑`,
                `白露`,
                `秋分`,
                `寒露`,
                `霜降`,
                `立冬`,
                `小雪}`,
                `大雪`,
                `冬至`,
            ];
            let lFtv = [
                `0101 春节`,
                `0115 元宵节`,
                `0505 端午节`,
                `0815 中秋节`,
                `0909 重阳节`,
                `1230*除夕`,
            ];
            let sFtv = [
                `0101 元旦`,
                `0501 劳动节`,
                `0701 建党节 香港回归纪念`,
                `0801 建军节`,
                `0910 教师节`,
                `1001*国庆节`,
            ];
            let sDObj = new Date(SY, SM, SD);
            let lDObj = new Lunar(sDObj);
            let festival = '', solarTerms = '', solarFestival = '', lunarFestival = '', tmp1, tmp2;
            //农历节日
            // tslint:disable-next-line: no-for-in-array
            for (let i in lFtv) {
                if (lFtv[i].match(/^(\d{2})(.{2})([\s\*])(.+)$/)) {
                    tmp1 = Number(RegExp.$1) - lDObj.month;
                    tmp2 = Number(RegExp.$2) - lDObj.day;
                    if (tmp1 == 0 && tmp2 == 0) {
                        lunarFestival = RegExp.$4;
                    }
                }
            }
            //国历节日
            // tslint:disable-next-line: no-for-in-array
            for (let i in sFtv) {
                if (sFtv[i].match(/^(\d{2})(\d{2})([\s\*])(.+)$/)) {
                    tmp1 = Number(RegExp.$1) - (SM + 1);
                    tmp2 = Number(RegExp.$2) - SD;
                    if (tmp1 == 0 && tmp2 == 0) {
                        solarFestival = RegExp.$4;
                    }
                }
            }
            //节气
            tmp1 = new Date(31556925974.7 * (SY - 1900) +
                sTermInfo[SM * 2 + 1] * 60000 +
                Date.UTC(1900, 0, 6, 2, 5));
            tmp2 = tmp1.getUTCDate();
            if (tmp2 == SD) {
                solarTerms = solarTerm[SM * 2 + 1];
            }
            tmp1 = new Date(31556925974.7 * (SY - 1900) +
                sTermInfo[SM * 2] * 60000 +
                Date.UTC(1900, 0, 6, 2, 5));
            tmp2 = tmp1.getUTCDate();
            if (tmp2 == SD) {
                solarTerms = solarTerm[SM * 2];
            }
            if (solarTerms == '' && solarFestival == '' && lunarFestival == '') {
                festival = null;
            }
            else {
                festival = solarTerms + ' ' + solarFestival + ' ' + lunarFestival; //节气 世界节  农历节
            }
            return {
                festival: festival,
                isSection: solarFestival || lunarFestival ? true : false,
            };
        }
        const filterExtendData = this.filterExtendData;
        const calendarMode = this.optionsProp.mode;
        function setCalendar() {
            let YYMMDDYear = YYMMDD(), week = weekday.call(this), lunar1 = solarDay1.call(this), lunar2 = solarDay2.call(this), lunar3 = solarDay3.call(this), month = YYMMDDYear.month, intervalLength = Number(week.interval);
            const dateStr = YYMMDDYear.date;
            // 存储所有数据
            allDateList.push(dateStr);
            let monObj = {
                date: YYMMDDYear.date,
                year: YYMMDDYear.year,
                month: YYMMDDYear.month,
                day: YYMMDDYear.day,
                week: week.week,
                interval: week.interval,
                isVacation: week.isVacation || lunar3.isSection,
                Zodiac: lunar1.Zodiac,
                BSMonths: lunar1.BSMonths,
                agriculDay: lunar1.agriculDay,
                calendarYear: lunar2.calendarYear,
                calendarMonth: lunar2.calendarMonth,
                calendarDay: lunar2.calendarDay,
                festival: lunar3.festival,
                selected: false,
                selecting: false,
                calendarMode,
                _piExtendValue: filterExtendData(dateStr), // 扩展字段
            };
            switch (month) {
                case 1:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 2:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 3:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 4:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 5:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 6:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 7:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 8:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 9:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 10:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 11:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                case 12:
                    if (YYMMDDYear.day === 1) {
                        annualRes[month + `月`].interval.length = intervalLength;
                    }
                    annualRes[month + `月`].values.push(monObj);
                    break;
                default:
                    break;
            }
        }
        let annualRes = {
            '1月': { interval: [], values: [] },
            '2月': { interval: [], values: [] },
            '3月': { interval: [], values: [] },
            '4月': { interval: [], values: [] },
            '5月': { interval: [], values: [] },
            '6月': { interval: [], values: [] },
            '7月': { interval: [], values: [] },
            '8月': { interval: [], values: [] },
            '9月': { interval: [], values: [] },
            '10月': { interval: [], values: [] },
            '11月': { interval: [], values: [] },
            '12月': { interval: [], values: [] },
        };
        let temDate = new Date(date.format('yyyy/01/01')), isLeapYear = temDate.getFullYear(), isLeap = (0 === isLeapYear % 4 && 0 != isLeapYear % 100) ||
            0 === isLeapYear % 400, days = isLeap ? 366 : 365;
        temDate = new Date(temDate.setDate(temDate.getDate() - 1));
        for (let a = 0; a < days; a++) {
            let dd = new Date(temDate.setDate(temDate.getDate() + 1));
            getDateList(dd.getFullYear(), dd.getMonth(), dd.getDate());
        }
        // 更新 annual
        this.annual = annualRes;
        this.allDateList.push(...allDateList);
    }
    /**
     * 获取日历管理数据
     */
    getData() {
        const { yearVal } = this;
        const date = new Date(new Date().setFullYear(yearVal));
        this.updateAnnual(date);
        this.isShowTable = true;
    }
    /**
     * 日期选中后事件
     */
    onSelected(v) {
        const { vSelectOption: vSelectOption } = this;
        vSelectOption.selected = v;
        const dateSelected = this.getSelectedDate();
        this.$emit('on-date-selected-change', dateSelected);
    }
    /**
     * 获取选中的日期
     */
    getSelectedDate() {
        const selectedDateIndexList = this.vSelectOption.selected;
        const { allDateList } = this;
        if (!allDateList || !allDateList.length) {
            return;
        }
        const resDate = [];
        selectedDateIndexList.map((selected, index) => {
            if (selected) {
                const date = allDateList[index];
                if (date) {
                    resDate.push(date);
                }
            }
        });
        return resDate;
    }
    /**
     * 日期选中ing事件
     *
     * @param v
     */
    onSelecting(v) {
        const { vSelectOption: vSelectOption } = this;
        vSelectOption.selecting = v;
    }
    /**
     * 获取 select 指令实例
     */
    getSelectableInstance() {
        const selEl = this.$el.querySelector('.pi-base-calendar-wrap');
        const selectableIns = selEl['_pi-selectable'];
        return selectableIns;
    }
    /**
     * 处理 v-select 可选节点逻辑
     */
    setItemSelectable() {
        const selectableIns = this.getSelectableInstance();
        const elList = [].map.call(this.$el.querySelectorAll('.pi-selectable-item'), (div) => div);
        selectableIns.setSelectables(elList);
    }
    //#endregion
    //#region hooks
    created() {
        // 更新 options
        this.options = Object.assign(this.options, this.optionsProp);
        this.vSelectOption._piEnable =
            this.options.mode === PiBaseCalendarModeEnum.Selectable;
    }
    mounted() {
        this.getData();
    }
};
__decorate([
    Prop({
        type: Object,
        default: () => {
            return {
                year: new Date().getFullYear(),
                mode: PiBaseCalendarModeEnum.View,
            };
        },
    })
], PiBaseCalendar.prototype, "optionsProp", void 0);
__decorate([
    Prop({
        type: Array,
    })
], PiBaseCalendar.prototype, "extendDataList", void 0);
__decorate([
    Prop({
        type: Number,
        required: true,
        default: 0,
    })
], PiBaseCalendar.prototype, "slotCompMountedCount", void 0);
__decorate([
    Watch('optionsProp', { deep: true })
], PiBaseCalendar.prototype, "watch.optionsProp", null);
__decorate([
    Watch('vSelectOption.selected', { immediate: true })
], PiBaseCalendar.prototype, "watch.vSelectOption.selected", null);
__decorate([
    Watch('vSelectOption.selecting', { immediate: true })
], PiBaseCalendar.prototype, "watch.vSelectOption.selecting", null);
__decorate([
    Watch('slotCompMountedCount')
], PiBaseCalendar.prototype, "watch.slotCompMountedCount", null);
__decorate([
    Watch('extendDataList', { deep: true })
], PiBaseCalendar.prototype, "watch.extendDataList", null);
PiBaseCalendar = PiBaseCalendar_1 = __decorate([
    Component({
        template,
    })
], PiBaseCalendar);
export default PiBaseCalendar;
