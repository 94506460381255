import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
let TitleCellMergeMixin = class TitleCellMergeMixin extends Vue {
    // 获取表头行合并的数量
    get getTitleRowspanTotalCount() {
        const that = this;
        let titleRowspanTotalCount1 = 0, titleRowspanTotalCount2 = 0, rowspanCountArr, minVal;
        // 不能用noFrozenTitleCols 要用原始数据处理
        that.noFrozenTitleCols.forEach((row) => {
            rowspanCountArr = this.getTitleRowspanCountArr(row);
            // 如果每一项的rowspan值都大于1则继续处理
            if (Array.isArray(rowspanCountArr) && rowspanCountArr.length > 0) {
                minVal = Math.min.apply(null, rowspanCountArr);
                titleRowspanTotalCount1 += minVal - 1;
            }
        });
        that.frozenTitleCols.forEach((row) => {
            rowspanCountArr = this.getTitleRowspanCountArr(row);
            // 如果每一项的rowspan值都大于1则继续处理
            if (Array.isArray(rowspanCountArr) && rowspanCountArr.length > 0) {
                minVal = Math.min.apply(null, rowspanCountArr);
                titleRowspanTotalCount2 += minVal - 1;
            }
        });
        return titleRowspanTotalCount1 < titleRowspanTotalCount2
            ? titleRowspanTotalCount1
            : titleRowspanTotalCount2;
    }
    // return array
    getTitleRowspanCountArr(row) {
        let rowspanCountArr = [];
        let shouldDeal = row.every((col) => {
            if (col.rowspan && parseInt(col.rowspan + '') > 1) {
                rowspanCountArr.push(parseInt(col.rowspan + ''));
                return true;
            }
            else {
                return false;
            }
        });
        if (shouldDeal) {
            return rowspanCountArr;
        }
        else {
            return [];
        }
    }
    // 获取最小的 rowspan
    getMinRowspan(row) {
        let result = 0;
        let rowspanCountArr = this.getTitleRowspanCountArr(row);
        // 如果每一项的rowspan值都大于1则继续处理
        if (Array.isArray(rowspanCountArr) && rowspanCountArr.length > 0) {
            result = Math.min.apply(null, rowspanCountArr);
        }
        return result - 1;
    }
};
TitleCellMergeMixin = __decorate([
    Component
], TitleCellMergeMixin);
export default TitleCellMergeMixin;
