import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { default as DeepClone } from '../utils/deep-clone';
let TableFilterMixin = class TableFilterMixin extends Vue {
    footerTotalHeight = 0;
    get frozenFooterCols() {
        const result = [];
        if (this.initInternalFooter.length > 0) {
            this.initInternalFooter.forEach((columns) => {
                result.push(columns.filter((col) => col.isFrozen));
            });
        }
        return result;
    }
    get noFrozenFooterCols() {
        const result = [];
        if (this.initInternalFooter.length > 0) {
            this.initInternalFooter.forEach((columns) => {
                result.push(columns.filter((col) => !col.isFrozen));
            });
        }
        return result;
    }
    get getFooterTotalRowHeight() {
        const that = this;
        if (Array.isArray(that.footer) && that.footer.length > 0) {
            return that.footer.length * that.propReactiveProxy.footerRowHeight;
        }
        return 0;
    }
    get hasTableFooter() {
        const that = this;
        return Array.isArray(that.footer) && !!that.footer.length;
    }
    get initInternalFooter() {
        const that = this;
        if (!(Array.isArray(that.footer) && that.footer.length > 0)) {
            return [];
        }
        const time = new Date().getTime();
        let result = [], resultRow = [], cloneInternalColumns;
        // 防止排序后对原数组进行干扰
        cloneInternalColumns = DeepClone(that.internalColumns);
        cloneInternalColumns.sort(function (a, b) {
            if (a.isFrozen) {
                return -1;
            }
            else if (b.isFrozen) {
                return 1;
            }
            return 0;
        });
        that.footer.forEach((items, index) => {
            resultRow = [];
            items.forEach((value, index2) => {
                resultRow.push({
                    content: value,
                    width: cloneInternalColumns[index2].width,
                    align: cloneInternalColumns[index2].columnAlign,
                    isFrozen: cloneInternalColumns[index2].isFrozen ? true : false,
                    _pi_guid: `${time}_${index}_${index2}`,
                });
            });
            result.push(resultRow);
        });
        return result;
    }
    // 设置 footer 单元格样式
    setFooterCellClassName(isLeftView, rowIndex, colIndex, value) {
        const that = this;
        let _colIndex = colIndex;
        // 如果是右列，并且有固定列
        if (!isLeftView && that.hasFrozenColumn) {
            _colIndex += that.frozenCols.length;
        }
        return (that.footerCellClassName &&
            that.footerCellClassName(rowIndex, _colIndex, value));
    }
};
TableFilterMixin = __decorate([
    Component
], TableFilterMixin);
export default TableFilterMixin;
