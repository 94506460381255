import { __decorate } from "tslib";
import { hNumToFixed } from 'bc$/utils';
import { Component } from 'vue-property-decorator';
import { DYN_DATA_DEFAULT_DISPLAY_VALUE } from '../pi-base-graphics-configuration-svg.constant';
import { PiSvgAnimateTypeEnum, PiSvgRunConfDataEnum, } from '../pi-base-graphics-configuration-svg.enum';
import { VueMixinBase } from './vue-mixin.base';
/**
 * Svg websocket 订阅
 */
let SvgWebsocketMixin = class SvgWebsocketMixin extends VueMixinBase {
    /**
     * 获取所有订阅点，包含 ptPathDst
     */
    getAllSubscribePtPath() {
        let res = [];
        // 获取 ptPathDst
        const { ptPathDstToPtPathData } = this;
        if (ptPathDstToPtPathData) {
            const ptPathDstList = Object.keys(ptPathDstToPtPathData);
            if (ptPathDstList && ptPathDstList.length) {
                res.push(...ptPathDstList);
            }
        }
        // 获取 ptPath
        const { ptPathData } = this;
        if (ptPathData) {
            const ptPaths = Object.keys(ptPathData);
            if (ptPaths && ptPaths.length) {
                res.push(...ptPaths);
            }
        }
        // 去重
        return [...new Set(res)];
    }
    /**
     * 初始化订阅点
     */
    initSubscribePointList() {
        const { runConfData } = this;
        const ptPathList = this.getAllSubscribePtPath();
        if (!ptPathList || !ptPathList.length) {
            return;
        }
        // 调用订阅
        const { subscribePoint } = this;
        ptPathList.forEach((ptPath) => {
            subscribePoint(ptPath).catch((err) => {
                console.error(`订阅设备点数据错误：${err}`);
            });
        });
    }
    /**
     * 取消订阅点
     */
    unSubscribePointList() {
        const { ptPathSubscriptData } = this;
        if (!ptPathSubscriptData) {
            return;
        }
        const ptPathList = Object.keys(ptPathSubscriptData);
        const { unSubscribePoint } = this;
        ptPathList.forEach((ptPath) => {
            const subData = ptPathSubscriptData[ptPath];
            unSubscribePoint(ptPath, subData).catch((err) => {
                console.error(err);
            });
        });
    }
    /**
     * svg 点动画订阅
     *
     * @param eleId
     */
    async subscribePoint(ptPath) {
        const { $piStomp: piStomp } = this;
        if (!piStomp) {
            console.warn('无法初始化ws，请联系系统管理员！');
            return;
        }
        const { unsubscribe, subId } = await piStomp.subscribe({
            topic: this.wsTopic || '',
            params: ptPath,
            customId: ptPath,
            callback: ({ message, subId, customId }) => {
                // 订阅回调
                this.updatePointViewWs(message);
            },
        });
        // 把 ws相关点信息加入
        this.addPtPathSubscriptData(ptPath, subId, unsubscribe);
    }
    /**
     * svg 点动画取消订阅
     *
     * @param subId
     */
    async unSubscribePoint(ptPath, subData) {
        const unSubFn = subData.unSubFn;
        if (unSubFn) {
            const { ptPathSubscriptData } = this;
            try {
                unSubFn();
                // 清除ptPath相关订阅信息
                this.$delete(ptPathSubscriptData, ptPath);
            }
            catch (error) {
                console.error(`取消订阅点信息失败：${ptPath}`);
            }
        }
    }
    /**
     * 添加 paPath 订阅回调信息
     *
     * @param ptPath
     * @param subId
     * @param unSubFn
     */
    addPtPathSubscriptData(ptPath, subId, unSubFn) {
        const { ptPathSubscriptData, svgContentEl } = this;
        if (!svgContentEl) {
            return;
        }
        ptPathSubscriptData[ptPath] = {
            subId,
            unSubFn,
        };
    }
    /**
     * 根据动画规则和ws数据计算出动画数据
     *
     * @param value
     * @param dynData
     */
    parseRuleValue(wsData, dynData) {
        // 通过算法接口解析出动画值
        return this.convertValue(wsData, dynData.ListCondition);
    }
    /**
     * 根据 Websocket 返回数据更新订阅点动画效果
     *
     * websocket 推送的ptPath 可用于查找 run/conf 对应配置点数据
     * 对应 ele id 需要转换为 ptPathType 后，根据svg json 解析出 Id
     */
    updatePointViewWs(data) {
        if (!data || !data.length) {
            return;
        }
        const { ptPathData, ptPathDstToPtPathData } = this;
        if (!ptPathData) {
            return;
        }
        const { updatePtPathView } = this;
        data.forEach((wsData) => {
            let { ptPath, tag } = wsData;
            // 处理推送数据为 null 情况
            tag = this.renderTagData(tag);
            // 特殊处理，
            // ws ptPath 对应的 ptPathInf.configuration 需要从 ptPathData 中获取
            let ptPathInfo = ptPathData && ptPathData[ptPath];
            if (!ptPathInfo) {
                console.warn(`无法查找ptPath关联的动画信息`);
                return;
            }
            const { configuration } = ptPathInfo;
            // ptPathDst info 转 ptPath info
            // 用于获取 animates， 因为animates只存于 原始ptPath中
            // ptPathDst 转 ptPath
            let ptPathParsed = ptPathDstToPtPathData && ptPathDstToPtPathData[ptPath];
            const ptPathInfoParsed = ptPathParsed
                ? ptPathData[ptPathParsed]
                : ptPathData[ptPath];
            // animates 需要原始的 ptPath 信息
            let { animates } = ptPathInfoParsed;
            if (animates && animates.length) {
                animates.forEach((animate) => {
                    const { ele } = animate;
                    if (ele) {
                        const { data } = animate;
                        // 存在多个 ptPath
                        if (data && data.length) {
                            data.forEach((dItem) => {
                                updatePtPathView(ele, dItem, tag, configuration);
                            });
                        }
                    }
                });
            }
        });
    }
    /**
     * 更新设备点样式, 根据不同类型处理
     *
     * @param eleId
     * @param ptPath
     * @param parseValue
     */
    updatePtPathStyle(ele, animateRule, parseValue, displayVal) {
        // 1. 获取ptPath 对应类型
        if (!animateRule) {
            return;
        }
        const animateType = animateRule.DynType;
        // 按照动画类型对dom进行不同的操作
        switch (animateType) {
            case PiSvgAnimateTypeEnum.Flashing:
                // 闪烁动画
                this.nodeFlashingHandler(ele, parseValue);
                break;
            case PiSvgAnimateTypeEnum.Image:
                // 图片动画
                this.nodeImagePathHandler(ele, parseValue);
                break;
            case PiSvgAnimateTypeEnum.Text:
                // 文本动画
                this.nodeTextHandler(ele, parseValue, displayVal);
                break;
            case PiSvgAnimateTypeEnum.Brush:
                // 画刷动画
                this.nodeBrushHandler(ele, parseValue);
                break;
            case PiSvgAnimateTypeEnum.Paint:
                // 画笔动画
                this.nodeStrokeHandler(ele, parseValue);
                break;
            case PiSvgAnimateTypeEnum.Visibility:
                // 显示隐藏动画
                this.nodeShowHandler(ele, parseValue);
                break;
            // TODO: 调用对应动画逻辑
        }
    }
    /**
     * 按照动画类型更新对应效果
     *
     * @param eleId
     * @param ptPath
     * @param tagData
     * @param val
     * @param unit
     * @param configuration
     */
    updatePtPathView(ele, animationData, tagData, configuration) {
        // 判断是否存在有效的 ptPath 和 ele
        if (!animationData || !ele || !tagData) {
            return;
        }
        // 处理枚举、数值和单位等格式化逻辑
        let displayValue = this.getDisplayValue(animationData, tagData.value, configuration);
        // 解析规则值
        const ruleVal = this.parseRuleValue(tagData, animationData);
        // 对元素执行相关样式变化
        this.updatePtPathStyle(ele, animationData, ruleVal, displayValue);
    }
    /**
     * 获取显示值
     *
     */
    getDisplayValue(animationData, value, configuration) {
        if (animationData === undefined || animationData === null) {
            return DYN_DATA_DEFAULT_DISPLAY_VALUE;
        }
        else if (value === undefined || value === null) {
            return DYN_DATA_DEFAULT_DISPLAY_VALUE;
        }
        // 枚举
        if (configuration &&
            configuration.dataType === PiSvgRunConfDataEnum.Enumerate &&
            configuration.tag) {
            return this.getEnumDisplayValue(value, configuration.confEnumList);
        }
        // 其他类型
        const { ShowUnit, Decimals } = animationData;
        let result = `${value}`;
        if (Decimals) {
            // 四舍五入
            result = hNumToFixed(value, Decimals);
        }
        if (!!ShowUnit && configuration) {
            result += ` ${configuration.unit || ''}`;
        }
        return result;
    }
};
SvgWebsocketMixin = __decorate([
    Component
], SvgWebsocketMixin);
export default SvgWebsocketMixin;
