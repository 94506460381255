import { SResultEntity, PiStatusEnum, AjaxAbortName, } from 'bc$/main';
import { Message as MessageType } from 'iview';
const Message = MessageType;
export function apiAjaxHandle(isReturnVoid, ajaxInfo, defaultName, options) {
    const { apiName, cbThen, cbCatch, cbFinally } = options || {}, apiNameNew = typeof apiName === 'string' ? apiName : defaultName;
    let textStatus = '';
    ajaxInfo.promise = ajaxInfo.instance
        .then((res) => {
        if (!res || res.status !== 200) {
            Message.error(`${apiNameNew}失败！`);
            return;
        }
        const rData = res.data;
        if (isReturnVoid || rData instanceof Blob) {
            typeof cbThen === 'function' && cbThen(rData);
            return rData;
        }
        if (!rData ||
            rData.status !== PiStatusEnum.Success ||
            !Array.isArray(rData.data)) {
            Message.error(`${apiNameNew}失败！<br />${(rData && rData.msg) || ''}`);
            return;
        }
        const dData = rData.data;
        typeof cbThen === 'function' && cbThen(dData, rData);
        return dData;
    })
        .catch((err) => {
        textStatus = (err && err.message) || '';
        if (textStatus !== AjaxAbortName) {
            Message.error(`${apiNameNew}错误！<br />${textStatus}`);
            typeof cbCatch === 'function' && cbCatch(err);
            console.error(err);
        }
    })
        .finally(() => {
        if (textStatus !== AjaxAbortName) {
            typeof cbFinally === 'function' && cbFinally();
        }
    })
        .catch((err) => {
        console.error(err);
    });
    return ajaxInfo;
}
