import { csv, exportCsv } from 'iview';
/**
 * 根据指定url下载文件
 * @param url url
 * @param fileName 下载后的文件名称
 */
function hUSaveFile(url, fileName) {
    const lnk = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    //IE直接在新一页中打开
    if (lnk.download === undefined) {
        document.location.href = url;
        return;
    }
    lnk.href = url;
    lnk.download = fileName;
    // 超链接 target="_blank" 要增加 rel="noopener noreferrer" 来堵住钓鱼安全漏洞。
    //如果你在链接上使用 target="_blank"属性，并且不加上rel="noopener"属性，那么你就让用户暴露在一个非常简单的钓鱼攻击之下。
    lnk.target = '_blank';
    lnk.rel = 'noopener noreferrer';
    //修复以下报错改为click
    //TypeError: Failed to execute 'initMouseEvent' on 'MouseEvent': parameter 4 is not of type 'Window'.
    // const event = document.createEvent('MouseEvents');
    // event.initMouseEvent(
    //   'click',
    //   true,
    //   false,
    //   window,
    //   0,
    //   0,
    //   0,
    //   0,
    //   0,
    //   false,
    //   false,
    //   false,
    //   false,
    //   0,
    //   null,
    // );
    // lnk.dispatchEvent(event);
    lnk.click();
}
/**
 * 下载csv文件
 */
function hUExportCsv(params, noBom = false) {
    if (params.filename) {
        if (params.filename.indexOf('.csv') === -1) {
            params.filename += '.csv';
        }
    }
    else {
        params.filename = 'table.csv';
    }
    let columns = params.columns || [];
    let datas = params.data || [];
    let noHeader = !!params.noHeader;
    const data = csv(columns, datas, params, noHeader);
    if (params.callback) {
        params.callback(data);
    }
    else {
        const dataType = noBom ? '' : '\uFEFF';
        exportCsv.download(params.filename, data, dataType);
    }
}
/**
 * 获取组件字符串名称
 * @param this
 */
function hUGetCmpName() {
    let name = this.constructor?.NAME;
    if (!name) {
        name = this.$options._componentTag;
    }
    if (!name) {
        name = this.constructor.name || '';
    }
    return name;
}
/**
 * 根据输入的流下载成客户端文件
 * @param rData 流内容
 */
function hUExportFile({ blob: blobPart, type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8', fileName = `${+new Date()}.xlsx`, }) {
    // IE11
    const { navigator } = window;
    if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blobPart, fileName);
    }
    else {
        // rData为blob类文件对象 Blob(4412) {size: 4412, type: "application/octet-stream"}
        const blob = new Blob([blobPart], { type });
        //构造模拟点击请求执行下载
        const downloadElement = document.createElement('a');
        //创建下载的链接
        const href = URL.createObjectURL(blob);
        downloadElement.href = href;
        //下载后文件名
        downloadElement.download = fileName;
        //添加到页面上
        // document.body.appendChild(downloadElement);
        //点击下载
        downloadElement.click();
        // //下载完成移除元素
        // document.body.removeChild(downloadElement);
        //释放掉blob对象
        URL.revokeObjectURL(href);
    }
}
export { hUSaveFile, hUExportCsv, hUGetCmpName, hUExportFile };
