import axios from 'axios';
// tslint:disable-next-line: no-import-side-effect
import 'bc$/extends/axios.extend';
// // tslint:disable-next-line: no-import-side-effect
// import 'bc$/extends/js.extend';
import qs from 'qs';
/**
 * 处理config中的配置，比如添加取消ajax的函数
 * @param config ajax配置参数
 */
function processConfig(config) {
    !config.paramsSerializer &&
        (config.paramsSerializer = (params) => qs.stringify(params, {
            arrayFormat: 'repeat',
        }));
    if (!config.transformRequest) {
        const transformRequestFn = axios.defaults
            .transformRequest[0];
        config.transformRequest = [
            (data, header) => {
                const contentType = header['Content-Type'];
                if (~contentType.indexOf('application/x-www-form-urlencoded')) {
                    if (typeof data === 'object') {
                        return qs.stringify(data);
                    }
                }
                return transformRequestFn(data, header);
            },
        ];
    }
    let cancelFunc = config.cancelFunc;
    if (!cancelFunc) {
        const source = axios.CancelToken.source();
        config.cancelFunc = source.cancel;
        config.cancelToken = source.token;
    }
}
/**
 * get请求,可按外部传入的 headers['Accept']与headers['Content-Type'],默认都为json
 * @param url 请求的url地址
 * @param config ajax配置参数
 */
function hAjaxGet(url, config = {}) {
    processConfig(config);
    const insAxios = axios.get(url, config);
    return {
        instance: insAxios,
        cancelFunc: config.cancelFunc,
    };
}
/**
 * get请求,外部传入的 headers['Accept']与headers['Content-Type']无效,必定是json
 * @param url 请求的url地址
 * @param config ajax配置参数
 */
function hAjaxGetJson(url, config = {}) {
    processConfig(config);
    const headers = config.headers || {};
    headers['Accept'] = '';
    headers['Content-Type'] = '';
    config.headers = headers;
    return hAjaxGet(url, config);
}
/**
 * post请求,可按外部传入的 headers['Accept']与headers['Content-Type'],默认都为json
 * @param url 请求的url地址
 * @param data 请求的参数
 * @param config ajax配置参数
 */
function hAjaxPost(url, data, config = {}) {
    processConfig(config);
    const insAxios = axios.post(url, data, config);
    return {
        instance: insAxios,
        cancelFunc: config.cancelFunc,
    };
}
/**
 * post请求,必定是json格式内容
 * 外部传入的 headers['Accept']与headers['Content-Type']无效
 * @param url 请求的url地址
 * @param data 请求的参数
 * @param config ajax配置参数
 */
function hAjaxPostJson(url, data, config = {}) {
    processConfig(config);
    const headers = config.headers || {};
    headers['Accept'] = '';
    headers['Content-Type'] = '';
    config.headers = headers;
    return hAjaxPost(url, data, config);
}
/**
 * post请求,必定是FormData格式内容
 * 外部传入的 headers['Accept']与headers['Content-Type']无效
 * @param url 请求的url地址
 * @param data 请求的参数
 * @param config ajax配置参数
 */
function hAjaxPostForm(url, data, config = {}) {
    processConfig(config);
    const headers = config.headers || {};
    headers['Content-Type'] = 'multipart/form-data';
    config.headers = headers;
    return hAjaxPost(url, data, config);
}
/**
 * post请求,必定是x-www-form-urlencoded格式内容
 * 外部传入的 headers['Accept']与headers['Content-Type']无效
 * @param url 请求的url地址
 * @param data 请求的参数
 * @param config ajax配置参数
 */
function hAjaxPostEncoded(url, data, config = {}) {
    processConfig(config);
    const headers = config.headers || {};
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers = headers;
    return hAjaxPost(url, data, config);
}
/**
 * delete请求,可按外部传入的 headers['Accept']与headers['Content-Type'],默认都为json
 * @param url 请求的url地址
 * @param config ajax配置参数
 */
function hAjaxDelete(url, config = {}) {
    processConfig(config);
    const insAxios = axios.delete(url, config);
    return {
        instance: insAxios,
        cancelFunc: config.cancelFunc,
    };
}
/**
 * delete请求,外部传入的 headers['Accept']与headers['Content-Type']无效,必定是json
 * @param url 请求的url地址
 * @param config ajax配置参数
 */
function hAjaxDeleteJson(url, config = {}) {
    processConfig(config);
    return hAjaxDelete(url, config);
}
/**
 * 其他method ajax请求
 * @param url 请求的url地址
 * @param config ajax配置参数
 */
function hAjax(url, config = {}) {
    processConfig(config);
    const insAxios = axios(url, config);
    return {
        instance: insAxios,
        cancelFunc: config.cancelFunc,
    };
}
/**
 * 多个ajax并发请求,并在都返回数据都再做操作
 * @param values
 */
function hAjaxAll(configs) {
    const source = axios.CancelToken.source();
    const cancelFunc = source.cancel;
    const cancelToken = source.token;
    const insAxioses = configs.map((config) => {
        config.cancelToken = cancelToken;
        const url = config.url;
        if (!url) {
            console.error('必须提供请求的url！');
            return;
        }
        switch ((config.method || '').toLowerCase()) {
            case 'get': {
                return hAjaxGet(url, config).instance;
            }
            case 'post': {
                let configPostData = config.data;
                delete config.data;
                return hAjaxPost(url, configPostData, config).instance;
            }
            case 'delete': {
                return hAjaxDelete(url, config).instance;
            }
            case 'getjson': {
                config.method = 'get';
                return hAjaxGetJson(url, config).instance;
            }
            case 'postjson': {
                config.method = 'post';
                let configData = config.data;
                delete config.data;
                return hAjaxPostJson(url, configData, config).instance;
            }
            case 'postform': {
                config.method = 'post';
                let configData = config.data;
                delete config.data;
                return hAjaxPostForm(url, configData, config).instance;
            }
            case 'postencoded': {
                config.method = 'post';
                let configData = config.data;
                delete config.data;
                return hAjaxPostEncoded(url, configData, config).instance;
            }
            case 'deletejson': {
                config.method = 'delete';
                return hAjaxDeleteJson(url, config).instance;
            }
            default: {
                return hAjax(url, config).instance;
            }
        }
    });
    const insAxios = axios.all(insAxioses);
    return {
        instance: insAxios,
        cancelFunc,
    };
}
export { hAjax, hAjaxAll, hAjaxDeleteJson, hAjaxGet, hAjaxGetJson, hAjaxPost, hAjaxPostJson, hAjaxPostForm, hAjaxPostEncoded, hAjaxDelete, };
