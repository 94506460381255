import { __decorate } from "tslib";
import { hReactiveUtil, WidgetBase } from 'bc$/main';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/**
 * PiBaseModal 混入
 */
let PiBaseModalMixin = class PiBaseModalMixin extends Vue {
    //#region props
    /**
     * 是否显示弹窗
     */
    value;
    //#endregion
    //#region data
    /**
     * 响应式工具对象
     */
    hReactiveUtil = hReactiveUtil;
    /**
     * Modal显示/隐藏
     */
    isShow = false;
    /**
     * 弹窗的z-index
     */
    zIndex = 1000;
    /**
     * Modal的加载状态
     */
    isLoading = false;
    /**
     * 弹窗动画完成后的回调 - 一般处理表格宽度渲染异常
     */
    showOnCb;
    //#endregion
    //#region watch
    'watch.isShow'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.$emit('input', newVal);
    }
    'watch.value'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.isShow = newVal;
    }
    //#endregion
    //#region methods
    onModalMounted() {
        const modal = this.$children[0];
        if (!modal) {
            return;
        }
        //弹窗垂直居中显示
        modal.$nextTick(() => {
            const elModal = modal.$children[0].$el.querySelector('.ivu-modal');
            const elBody = elModal.querySelector('.ivu-modal-body');
            const top = ((document.body.offsetHeight - elBody.offsetHeight) / 227) * 100; //UI要求的比例
            elModal.style.top = `${top}px`;
            //显示表格
            modal.resetTimeOut('onModalMounted', () => {
                this.showOnCb?.();
            }, 300);
        });
    }
    /**
     * 设置焦点
     */
    setFocus() {
        this.$nextTick(() => {
            const input = this.$children[0].$children[0].$el.querySelector('input[type=text]:enabled');
            input && input.focus();
        });
    }
    //#endregion
    //#region hooks
    created() {
        //监控弹窗状态,显示时置为上层
        this.zIndex = WidgetBase.iModalCount++;
        this.setFocus();
    }
    mounted() {
        this.onModalMounted();
    }
    /**
     * 销毁实例前
     */
    beforeDestroy() {
        WidgetBase.iModalCount--;
    }
};
__decorate([
    Prop({
        type: Boolean,
        required: true,
    })
], PiBaseModalMixin.prototype, "value", void 0);
__decorate([
    Watch('isShow')
], PiBaseModalMixin.prototype, "watch.isShow", null);
__decorate([
    Watch('value', { immediate: true })
], PiBaseModalMixin.prototype, "watch.value", null);
PiBaseModalMixin = __decorate([
    Component
], PiBaseModalMixin);
export { PiBaseModalMixin };
