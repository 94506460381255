var PiBaseHead_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { PiStatusEnum } from 'bc$/enums/system/pi-status.enum';
import { AjaxAbortName, ApiUrlSetting, hAjaxGet, hErrorShow, PiBaseStorePageModule, UrlType, VueBase, } from 'bc$/main';
import { hReactiveUtil } from 'bc$/utils/';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-head.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-head.component.scss';
let PiBaseHead = PiBaseHead_1 = class PiBaseHead extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseHead';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseHead_1?.NAME,
        author: 'caiqw',
        title: '页头组件',
        version: BcVersion,
        updateDate: '2019-06-10',
        description: '',
    };
    //#endregion
    //#region props
    className;
    /**
     * 当前活动子系统id
     */
    activeIdProp;
    /**
     * 子系统信息清单
     */
    systemInfoListProp;
    /**
     * 是否平铺所有导航项
     */
    isFlat;
    /**
     * 导航列表项最小宽度
     */
    systemItemMinWidth;
    /**
     * 关闭平铺时，导航列表项最大数量
     */
    systemItemMaxLimit;
    /**
     * 关闭平铺时，是否自动调节导航列表项最大数量
     */
    isAutoResizeMaxLimit;
    /**
     * 关闭平铺时，是否开启文字提示
     */
    isShowTextTips;
    /**
     * 箭头图片地址配置
     */
    arrowImgConfig;
    /**
     * 仅一个系统时隐藏子系统信息清单
     */
    hiddenWhenOnlyOne;
    /**
     *
     */
    systemInfoConfigDefaultProp;
    /**
     *logo图标是否可点击啊
     */
    isLogoCanClick;
    //#endregion
    //#region data
    /**
     * 关闭平铺时，最终导航列表项最大数量
     */
    systemItemTmlMaxLimit = 8;
    /**
     *箭头图片总宽
     */
    arrowTotalWidth = hReactiveUtil.convertToReactivePx(30);
    /**
     *是否显示左箭头
     */
    isShowLeftArrow = false;
    /**
     *是否显示右箭头
     */
    isShowRightArrow = false;
    hReactiveUtil = hReactiveUtil;
    UrlType = UrlType;
    /** logo图片 */
    logoImage = '';
    systemInfoConfigDefault = {};
    sysConfig = {};
    systemInfoList = [];
    options = {
        titlePre: '',
        title: PiBaseHead_1.ABOUT.title,
        titleSuf: '',
        isShowDownLoad: false,
    };
    activeId = '';
    //#endregion
    //#region computed
    get about() {
        return PiBaseHead_1.ABOUT;
    }
    get titleAll() {
        const options = this.options;
        return `${options.titlePre}${options.title}${options.titleSuf}`;
    }
    /**
     * store中存储的页面相关的信息
     */
    get storePage() {
        return PiBaseStorePageModule;
    }
    /**
     * 左箭头图标
     */
    get leftArrow() {
        if (this.arrowImgConfig && this.arrowImgConfig.leftArrowUrl) {
            return this.arrowImgConfig.leftArrowUrl;
        }
        else {
            return require('bc$/assets/imgs/to_left.png');
        }
    }
    /**
     * 右箭头图标
     */
    get rightArrow() {
        if (this.arrowImgConfig && this.arrowImgConfig.rightArrowUrl) {
            return this.arrowImgConfig.rightArrowUrl;
        }
        else {
            return require('bc$/assets/imgs/to_right.png');
        }
    }
    /**
     * 导航列表项响应式最小宽度
     */
    get systemItemTmlMinWidth() {
        return `${hReactiveUtil.convertToReactivePx(this.systemItemMinWidth)}px`;
    }
    /**
     * 导航列表项响应式最大宽度
     */
    get systemListContainerMaxWidth() {
        return hReactiveUtil.convertToReactivePx(this.systemItemMinWidth) * this.systemItemTmlMaxLimit;
    }
    /**
     * 导航列表容器响应式最大宽度(含箭头)
     */
    get systemListTmlMaxWidth() {
        if (!this.isFlat) {
            return `${this.systemListContainerMaxWidth + this.arrowTotalWidth}px`;
        }
        else {
            return 'unset';
        }
    }
    //#endregion
    //#region watch
    'watch.systemItemMaxLimit'(newVal) {
        this.systemItemTmlMaxLimit = newVal;
    }
    /**
     * 外部传入的当前系统id变化
     */
    'watch.activeIdProp'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.activeId = newVal || '';
    }
    /**
     * 外部传入的子系统清单变化
     */
    'watch.systemInfoListProp'(newVal) {
        const list = [...(newVal || [])];
        this.systemInfoList = list;
        this.setDefaultConfig();
    }
    /**
     * 监听当前激活的子系统
     */
    'watch.activeId'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        //存入vuex
        this.storePage.setCurSystemId({ systemId: newVal });
        //通知父级组件
        this.$emit('on-change-sub-system', `${newVal}`);
    }
    /**
     * 监听当前激活的子系统
     */
    'watch.sysConfig'(newVal, oldVal) {
        if (!newVal || (oldVal && newVal.image === oldVal.image)) {
            return;
        }
        this.logoImage = '';
        const imageUrl = newVal.image;
        if (!imageUrl) {
            (!this.isFlat && this.isAutoResizeMaxLimit) && this.autoSetSysItemTmlMaxLimit();
            return;
        }
        const img = new Image();
        img.onload = () => {
            this.logoImage = imageUrl;
            (!this.isFlat && this.isAutoResizeMaxLimit) && this.autoSetSysItemTmlMaxLimit();
            this.setArrowShowStatus();
        };
        img.src = imageUrl;
    }
    'watch.systemInfoConfigDefaultProp'() {
        this.setDefaultConfig();
    }
    //#endregion
    //#region methods
    /**
     * 获取子系统列表数据
     */
    getSystemList() {
        const getPageBaseInfoUrl = ApiUrlSetting.WidgetConfig.PiBasePageLayout.SystemListUrl;
        if (!getPageBaseInfoUrl) {
            return {
                promise: Promise.resolve(void 0),
                instance: Promise.resolve(void 0),
            };
        }
        const reqUrl = `${this.convertUrl(getPageBaseInfoUrl, UrlType.Api)}`;
        return this.resetAjax('getSystemList', () => {
            const ajaxInfo = hAjaxGet(reqUrl);
            ajaxInfo.promise = ajaxInfo.instance
                .then((res) => {
                const rData = res.data || {};
                if (!rData || rData.status !== PiStatusEnum.Success) {
                    // 后台请求失败，emit 错误信息
                    console.error(`获取子系统清单失败！${res}`);
                    this.$emit('on-load-page-base-info-error', res);
                }
                const dData = rData.data || [];
                this.systemInfoList = dData;
                // this.setDefaultConfig();
                // // 通知父组件已获取头部组件所需数据
                // this.$emit('update-header-system-list', rData.data || []);
                return dData;
            })
                .catch((err) => hErrorShow({
                err,
                message: '获取子系统清单错误！',
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('on-load-page-base-info-error', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 获取页头配置信息
     */
    getHeaderData() {
        //加载组件菜单布局
        const reqUrl = this.convertUrl(ApiUrlSetting.WidgetConfig.PiBaseHead.ContentUrl, UrlType.Api);
        return this.resetAjax('getHeaderData', () => {
            const ajaxInfo = hAjaxGet(reqUrl);
            ajaxInfo.promise = ajaxInfo.instance
                .then((res) => {
                if (!res) {
                    this.$Message.error('获取系统信息数据请求失败！');
                    return;
                }
                const { data: rData } = res;
                if (!rData) {
                    this.$Message.error('获取系统信息数据请求失败！');
                    return;
                }
                if (rData.status !== PiStatusEnum.Success) {
                    this.$Message.error('获取系统信息数据失败！');
                    return;
                }
                const dData = rData.data;
                if (!dData || !dData.length) {
                    this.$Message.error('获取系统信息数据失败！');
                    return;
                }
                const systemInfo = dData[0];
                if (systemInfo.image) {
                    systemInfo.image = this.convertUrl(systemInfo.image, UrlType.File);
                }
                this.systemInfoConfigDefault = systemInfo;
                // this.setDefaultConfig();
                return dData;
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
                message: '数据加载错误',
            }));
            return ajaxInfo;
        });
    }
    /**
     * 获取子系统列表和头部信息
     */
    getAll() {
        const hasUrl = !!ApiUrlSetting.WidgetConfig.PiBaseHead.ContentUrl;
        if (!hasUrl && this.systemInfoListProp) {
            const sysItem = this.systemInfoListProp.find((p) => p.pkid === this.activeId);
            this.systemInfoConfigDefault =
                (sysItem && sysItem.config) || {};
            this.setDefaultConfig();
            return;
        }
        const promises = [];
        if (hasUrl) {
            promises.push(this.getHeaderData().promise);
        }
        if (!this.systemInfoListProp) {
            promises.push(this.getSystemList().promise);
        }
        Promise.all(promises)
            .then(([dDataHeader, dDataSystem]) => {
            if (dDataHeader || dDataSystem) {
                this.setDefaultConfig();
            }
            if (dDataSystem) {
                // 通知父组件已获取头部组件所需数据
                this.$emit('update-header-system-list', dDataSystem);
            }
        })
            .catch((err) => {
            console.error(err);
        });
    }
    /**
     * 检测子系统中无config信息则由systemInfoConfigDefault替代
     */
    setDefaultConfig() {
        let { systemInfoList, systemInfoConfigDefaultProp, systemInfoConfigDefault: defaultInfo, } = this;
        if (!systemInfoList || !systemInfoList.length) {
            //虚拟子系统
            this.systemInfoList = [
                {
                    pageName: defaultInfo.title,
                    icon: defaultInfo.icon,
                    pkid: '0',
                },
            ];
        }
        if (JSON.stringify(defaultInfo) === '{}') {
            if (JSON.stringify(systemInfoConfigDefaultProp) === '{}') {
                return;
            }
            defaultInfo = this.systemInfoConfigDefault = {
                ...systemInfoConfigDefaultProp,
            };
        }
        //设置默认值作为当前选中子系统
        this.systemInfoList.forEach((p) => {
            if (!p.config) {
                p.config = { ...defaultInfo };
            }
        });
        //检测当前选中的子系统菜单
        //优先使用内容activeId
        let systemInfo = systemInfoList.find((p) => p.pkid === this.activeId);
        if (!systemInfo) {
            //使用内容外部传入的activeId
            systemInfo = systemInfoList.find((p) => p.pkid === this.activeIdProp);
            if (!systemInfo) {
                //取第一项作为当前项
                systemInfo = systemInfoList[0];
                //重设activeId
                systemInfo && (this.activeId = systemInfo.pkid);
            }
            else {
                //重设activeId
                this.activeId = this.activeIdProp;
            }
        }
        //设置当前选择的系统的配置项config
        this.sysConfig = this.getSysConfig();
    }
    /**
     * 当前选择的系统的配置项config,比如logo,title等信息
     */
    getSysConfig() {
        const { activeId } = this;
        if (activeId) {
            const systemInfo = this.systemInfoList.find((p) => p.pkid === activeId);
            if (systemInfo) {
                const sysConfig = systemInfo.config || {};
                return sysConfig;
            }
        }
        return {};
    }
    /**
     * 点击logo图标事件
     */
    onHandleLogo() {
        this.$emit('on-handle-logo');
    }
    /**
     * 向左移动导航列表
     */
    navTranslateLeft() {
        const containerInstance = this.$refs.systemItemContainer;
        containerInstance.scrollLeft =
            containerInstance.scrollLeft - containerInstance.clientWidth;
        this.setArrowShowStatus('left');
    }
    /**
     * 向右移动导航列表
     */
    navTranslateRight() {
        const containerInstance = this.$refs.systemItemContainer;
        containerInstance.scrollLeft =
            containerInstance.scrollLeft + containerInstance.clientWidth;
        this.setArrowShowStatus('right');
    }
    /**
     * 设置箭头显示状态
     */
    setArrowShowStatus(direction) {
        this.$nextTick(() => {
            const containerInstance = this.$refs.systemItemContainer;
            if (containerInstance) {
                this.isShowLeftArrow = containerInstance.scrollLeft !== 0;
                this.isShowRightArrow = containerInstance.scrollLeft + containerInstance.clientWidth !== containerInstance.scrollWidth;
                const isShowAllArrows = this.isShowRightArrow && this.isShowLeftArrow;
                if (isShowAllArrows && direction) {
                    this.arrowTotalWidth = hReactiveUtil.convertToReactivePx(60);
                }
                else {
                    this.arrowTotalWidth = hReactiveUtil.convertToReactivePx(30);
                }
            }
        });
    }
    /**
     * 自动调节设置导航列表项最大数量
     */
    autoSetSysItemTmlMaxLimit() {
        this.$nextTick(() => {
            const containerInstance = this.$refs.container;
            const logoInstance = this.$refs.logo;
            const toolBarInstance = this.$refs.toolbar;
            const containerWidth = containerInstance.clientWidth;
            const logoWidth = logoInstance.clientWidth;
            const toolBarWidth = toolBarInstance.clientWidth;
            const maxWidth = containerWidth - logoWidth
                - toolBarWidth - hReactiveUtil.convertToReactivePx(15);
            this.systemItemTmlMaxLimit = Math.floor(maxWidth / this.systemItemMinWidth);
        });
    }
    /**
     * 初始化箭头状态
     */
    initArrowStatus() {
        // 加延迟器保证能够正常渲染
        setTimeout(() => {
            // 非平铺模式下特殊处理
            if (!this.isFlat) {
                const targetIndex = this.systemInfoList.findIndex((item) => item.pkid === this.activeId);
                const moveNums = Math.floor(targetIndex / this.systemItemTmlMaxLimit);
                // 刷新页面时候scroll自动滚动至选中处
                if (isFinite(moveNums) && moveNums > 0) {
                    const containerInstance = this.$refs
                        .systemItemContainer;
                    containerInstance.scrollLeft =
                        containerInstance.scrollLeft +
                            moveNums * containerInstance.clientWidth;
                    this.setArrowShowStatus('right');
                }
                else {
                    this.setArrowShowStatus();
                }
            }
            else {
                this.setArrowShowStatus();
            }
        }, 500);
    }
    //#endregion
    //#region hooks
    created() {
        //获取子系统列表
        this.getAll();
    }
    mounted() {
        this.initArrowStatus();
    }
};
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseHead.prototype, "className", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBaseHead.prototype, "activeIdProp", void 0);
__decorate([
    Prop({
        type: Array,
    })
], PiBaseHead.prototype, "systemInfoListProp", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseHead.prototype, "isFlat", void 0);
__decorate([
    Prop({
        type: Number,
        default: 130,
    })
], PiBaseHead.prototype, "systemItemMinWidth", void 0);
__decorate([
    Prop({
        type: Number,
        default: 8,
    })
], PiBaseHead.prototype, "systemItemMaxLimit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseHead.prototype, "isAutoResizeMaxLimit", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseHead.prototype, "isShowTextTips", void 0);
__decorate([
    Prop({
        type: Object,
    })
], PiBaseHead.prototype, "arrowImgConfig", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseHead.prototype, "hiddenWhenOnlyOne", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => { },
    })
], PiBaseHead.prototype, "systemInfoConfigDefaultProp", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseHead.prototype, "isLogoCanClick", void 0);
__decorate([
    Watch('systemItemMaxLimit', { immediate: true })
], PiBaseHead.prototype, "watch.systemItemMaxLimit", null);
__decorate([
    Watch('activeIdProp', { immediate: true })
], PiBaseHead.prototype, "watch.activeIdProp", null);
__decorate([
    Watch('systemInfoListProp', { immediate: true, deep: true })
], PiBaseHead.prototype, "watch.systemInfoListProp", null);
__decorate([
    Watch('activeId', { immediate: true })
], PiBaseHead.prototype, "watch.activeId", null);
__decorate([
    Watch('sysConfig', { immediate: true, deep: true })
], PiBaseHead.prototype, "watch.sysConfig", null);
__decorate([
    Watch('systemInfoConfigDefaultProp', { immediate: true, deep: true })
], PiBaseHead.prototype, "watch.systemInfoConfigDefaultProp", null);
PiBaseHead = PiBaseHead_1 = __decorate([
    Component({
        template,
    })
], PiBaseHead);
export default PiBaseHead;
