var PiBaseModal_1;
import { __decorate } from "tslib";
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import { VueBase, hErrorShow } from 'bc$/main';
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-modal.component.html';
import { Modal, Button, Icon } from 'iview';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-modal.component.scss';
let PiBaseModal = PiBaseModal_1 = class PiBaseModal extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseModal';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseModal_1?.NAME,
        author: 'caiqw',
        title: '基础弹窗组件',
        version: BcVersion,
        updateDate: '2019-05-22',
        description: '',
    };
    static modalList = [];
    /**
     * 判断是否为单例
     */
    static isSingle = 0;
    //#endregion
    //#region props
    /**
     * 是否显示模态框
     */
    value;
    /**
     * Tab标签的id
     */
    tabId;
    /**
     * 模态框标题
     */
    title;
    /**
     * 模态框默认插槽内容
     */
    content;
    /**
     * 模态框自带确定按钮名称
     */
    okText;
    /**
     * 模态框自带确定按钮隐藏
     */
    okHidden;
    /**
     * 模态框自带取消按钮名称
     */
    cancelText;
    /**
     * 模态框自带取消按钮隐藏
     */
    cancelHidden;
    /**
     * 模态框宽度
     */
    width;
    /**
     * 是否隐藏模态框自带footer
     */
    footerHide;
    /**
     * 是否隐藏上方关闭图标
     */
    closable;
    /**
     * 模态框modal样式
     */
    styles;
    /**
     * 模态框头部样式
     */
    headerStyle;
    className;
    /**
     * 判断mask遮幕层点击后是否可关闭模态框
     */
    maskClosable;
    /**
     * 是否显示遮罩层，开启 draggable 时，强制不显示
     */
    mask;
    /**
     * 是否显示遮罩层，开启 draggable 时，只有设置整个属性才可以显示遮罩
     */
    maskForce;
    /**
     * 判断是否页面加载中
     */
    isLoading;
    /**
     * 点击确认按钮执行的方法
     */
    onOk;
    /**
     * 点击取消按钮执行的方法
     */
    onCancel;
    /**
     * 判断模态框是否可拖拽
     */
    draggable;
    /**
     * 判断模态框是否居中，与拖拽功能相斥，有拖拽功能时不起效果
     */
    vCenter;
    /**
     * 是否允许按esc关闭
     */
    isEsc;
    /**
     * 弹窗动画完成后的回调 - 一般处理表格宽度渲染异常
     */
    showOnCb;
    //#endregion
    //#region data
    /**
     * 真实模态框宽度
     */
    modalWidth = 0;
    options = {
        titlePre: '',
        title: PiBaseModal_1.ABOUT.title,
        titleSuf: '',
        isShowDownLoad: false,
    };
    /**
     * 判断模态框是否创建删除
     */
    isShowModal = false;
    /**
     * 判断模态框是否隐藏（下次设置false可直接显示）
     */
    isHidden = null;
    isOkRunning = false;
    isCancelRunning = false;
    //#endregion
    //#region computed
    get about() {
        return PiBaseModal_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    'watch.value'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            // 第一次创建模态框是往modalList记录this对象
            let index = PiBaseModal_1.modalList.findIndex((item) => {
                return item === this;
            });
            if (!~index) {
                PiBaseModal_1.modalList.push(this);
            }
        }
        this.isShowModal = newVal;
    }
    'watch.isShowModal'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        // 显示状态修改时双向绑定父元素的值
        if (oldVal !== undefined) {
            this.$emit('input', newVal);
        }
        if (!newVal && typeof oldVal === 'boolean') {
            // 当前isHidden状态为非隐藏时，isShowModal为false将改对象从modalList中清除，并直接销毁单例组件，
            if (!this.isHidden) {
                let index = PiBaseModal_1.modalList.findIndex((item) => {
                    return item === this;
                });
                PiBaseModal_1.modalList.splice(index, 1);
                // 当为单例创建时直接销毁对象，否则直接隐藏即可
                if (PiBaseModal_1.isSingle) {
                    this.$emit('destroy');
                }
            }
        }
        if (newVal && this.isHidden) {
            this.isHidden = false;
        }
    }
    'watch.isHidden'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (oldVal !== undefined) {
            this.isShowModal = !newVal;
        }
    }
    //#endregion
    //#region methods
    /**
     * 确认
     */
    ok() {
        const { onOk } = this;
        const promise = typeof onOk === 'function' && onOk();
        if (!(promise instanceof Promise)) {
            this.isShowModal = false;
            return;
        }
        this.isOkRunning = true;
        promise
            .then(() => {
            this.isShowModal = false;
        })
            .finally(() => {
            this.isOkRunning = false;
        })
            .catch((err) => hErrorShow({
            err,
            message: '确认弹窗时出现错误！',
            callback: () => {
                if (!err.__CANCEL__ && err.message) {
                    throw err;
                }
            },
        }));
    }
    /**
     * 取消
     */
    cancel() {
        const { onCancel } = this;
        const promise = typeof onCancel === 'function' && onCancel();
        if (!(promise instanceof Promise)) {
            this.isShowModal = false;
            return;
        }
        this.isCancelRunning = true;
        promise
            .then(() => {
            this.isShowModal = false;
        })
            .finally(() => {
            this.isCancelRunning = false;
        })
            .catch((err) => hErrorShow({
            err,
            message: '确认弹窗时出现错误！',
            callback: () => {
                if (!err.__CANCEL__ && err.message) {
                    throw err;
                }
            },
        }));
    }
    onVisibleChange(val) {
        this.isShowModal = val;
    }
    /**
     * 设置弹窗垂直居中 、显示动画结束回调
     */
    setModalVCenter() {
        const modalComp = this.$children[0];
        if (!modalComp) {
            return;
        }
        modalComp.$nextTick(() => {
            //弹窗垂直居中显示
            if (this.vCenter) {
                const compEl = this.$el;
                const elModal = compEl.querySelector('.ivu-modal');
                if (!elModal) {
                    return;
                }
                const elBody = compEl.querySelector('.ivu-modal-body');
                if (!elBody) {
                    return;
                }
                const top = ((document.body.offsetHeight - elBody.offsetHeight) / 227) * 100; //UI要求的比例
                elModal.style.top = `${top}px`;
            }
            // modal 动画结束后回调
            this.resetTimeOut('onModalMounted', () => {
                this.showOnCb?.();
            }, 300);
        });
    }
    onWindowResize() {
        this.modalWidth = hReactiveUtil.convertToReactivePx(this.width);
    }
    //#endregion
    //#region hooks
    mounted() {
        this.$nextTick(() => {
            this.setModalVCenter();
            this.modalWidth = hReactiveUtil.convertToReactivePx(this.width);
        });
    }
};
__decorate([
    Prop({
        type: Boolean,
        required: true,
    })
], PiBaseModal.prototype, "value", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBaseModal.prototype, "tabId", void 0);
__decorate([
    Prop({
        type: String,
        default: '默认标题',
    })
], PiBaseModal.prototype, "title", void 0);
__decorate([
    Prop({
        default: '',
    })
], PiBaseModal.prototype, "content", void 0);
__decorate([
    Prop({
        type: String,
        default: '确定',
    })
], PiBaseModal.prototype, "okText", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseModal.prototype, "okHidden", void 0);
__decorate([
    Prop({
        type: String,
        default: '取消',
    })
], PiBaseModal.prototype, "cancelText", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseModal.prototype, "cancelHidden", void 0);
__decorate([
    Prop({
        type: Number,
        default: 600,
    })
], PiBaseModal.prototype, "width", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseModal.prototype, "footerHide", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseModal.prototype, "closable", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseModal.prototype, "styles", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseModal.prototype, "headerStyle", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseModal.prototype, "className", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseModal.prototype, "maskClosable", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseModal.prototype, "mask", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseModal.prototype, "maskForce", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseModal.prototype, "isLoading", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseModal.prototype, "onOk", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseModal.prototype, "onCancel", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseModal.prototype, "draggable", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseModal.prototype, "vCenter", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseModal.prototype, "isEsc", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseModal.prototype, "showOnCb", void 0);
__decorate([
    Watch('value', { immediate: true, deep: true })
], PiBaseModal.prototype, "watch.value", null);
__decorate([
    Watch('isShowModal', { immediate: true, deep: true })
], PiBaseModal.prototype, "watch.isShowModal", null);
__decorate([
    Watch('isHidden', { immediate: true, deep: true })
], PiBaseModal.prototype, "watch.isHidden", null);
PiBaseModal = PiBaseModal_1 = __decorate([
    Component({
        components: {
            Modal,
            Button,
            Icon,
        },
        template,
        mixins: [WindowEventMixin],
    })
], PiBaseModal);
export default PiBaseModal;
export const PiBaseStaticModal = {
    install: (Vue, options) => {
        /**
         * 创建弹窗组件api
         * @param options
         * @returns
         */
        function createDialogCmp(options) {
            const { content, render, renderHeader, renderFooter, 
            // 根据vue生成对应模板
            renderVue, renderHeaderVue, renderFooterVue, } = options;
            // 利用vue构建弹窗内容
            const getVNodeRender = (getVueInstance) => {
                return getVueInstance
                    ? (h, props) => {
                        // const element = document.createElement('div');
                        const vnode = h('div');
                        const element = vnode.componentInstance?.$el;
                        const instance = getVueInstance();
                        // 此处实现移花接木，利用VNode替换VNode
                        instance.$mount(element);
                        return instance._vnode;
                    }
                    : undefined;
            };
            // 当value为false时不创建模态框
            if (options.value === false) {
                return {};
            }
            else if (options.value === undefined) {
                options.value = true;
            }
            const cmp = new Vue({
                render: (h) => {
                    return h(PiBaseModal, {
                        props: options,
                        // 回调
                        on: {
                            /**
                             * 销毁函数
                             */
                            destroy() {
                                piParentNode.removeChild(cmp.$mount().$el);
                                cmp.$destroy();
                            },
                        },
                        // 定义插槽
                        scopedSlots: (() => {
                            const scopedSlots = {};
                            [
                                {
                                    key: 'default',
                                    value: render || getVNodeRender(renderVue), // 默认渲染
                                },
                                {
                                    key: 'header',
                                    value: renderHeader || getVNodeRender(renderHeaderVue), // 渲染头部
                                },
                                {
                                    key: 'footer',
                                    value: renderFooter || getVNodeRender(renderFooterVue), // 渲染底部
                                },
                            ]
                                .filter((item) => item.value)
                                .forEach((item) => {
                                scopedSlots[item.key] = (props) => item.value(h, props);
                            });
                            // 不存在默认渲染函数时
                            if (!render && !getVNodeRender(renderVue)) {
                                // 构建默认插槽内容
                                scopedSlots['default'] = () => h('div', {
                                    domProps: {
                                        innerHTML: content,
                                    },
                                });
                            }
                            return scopedSlots;
                        })(),
                    });
                },
            });
            const el = cmp.$mount().$el;
            const { piParentNodeFn } = options;
            let piParentNode = piParentNodeFn ? piParentNodeFn() : document.body;
            // 处理document下插入元素需要在body内执行
            if (piParentNode === document) {
                piParentNode = document.body;
            }
            piParentNode.appendChild(el);
            PiBaseModal.isSingle = 1;
            return cmp.$children[0];
        }
        /**
         * 暴露的构建api
         * @param options
         * @returns
         */
        function show(options) {
            let opt;
            switch (typeof options) {
                case 'string':
                    opt = {};
                    break;
                case 'object':
                    opt = options;
                    break;
                default:
                    console.error('参数类型错误！');
                    return;
            }
            return createDialogCmp(opt);
        }
        /**
         * 挂载到全局
         */
        const property = (options && options.property) || '$piModal';
        Vue.prototype[property] = show;
    },
};
