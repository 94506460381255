import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
let PanZoomMixin = class PanZoomMixin extends VueMixinBase {
    /**
     * svg内viewBox的transform值
     */
    gViewBoxTransform = '';
    /**
     * svg内viewBox的style的值
     */
    gViewBoxStyle = '';
    /**
     * 暂时禁用放大缩小和svg的功能
     * @param svgEl 当前svg对象
     * @param svgViewPortEl 当前svg对象首个g标签
     */
    pausePanZoom(svgEl, svgViewPortEl) {
        //记录之前的transform和style
        this.gViewBoxTransform = svgViewPortEl.getAttribute('transform');
        this.gViewBoxStyle = svgViewPortEl.getAttribute('style');
        (() => {
            const valueNew = this.svgViewBox, valueOld = svgEl.getAttribute('viewBox');
            if (valueNew !== valueOld) {
                //写回viewBox的值
                svgEl.setAttribute('viewBox', valueNew);
            }
        })();
        (() => {
            const valueNew = 'none', valueOld = svgEl.getAttribute('preserveAspectRatio');
            if (valueNew !== valueOld) {
                //设置拉伸
                svgEl.setAttribute('preserveAspectRatio', valueNew);
            }
        })();
        this.$nextTick(() => {
            //设置延时任务加载,统一管理
            this.resetTimeOut('pausePanZoom', () => {
                //移除svg-pan-zoom的viewport放大缩小设置
                svgViewPortEl.removeAttribute('transform');
                svgViewPortEl.removeAttribute('style');
            });
        });
    }
    /**
     * 继续开放放大缩小和svg的功能
     * @param svgEl 当前svg对象
     * @param svgViewportEl 当前svg对象首个g标签
     */
    resumePanZoom(svgEl, svgViewportEl) {
        //去掉viewBox的值
        svgEl.removeAttribute('viewBox');
        //恢复原来的svg-pan-zoom的viewport放大缩小设置
        if (this.gViewBoxTransform &&
            this.gViewBoxStyle &&
            !svgViewportEl.getAttribute('transform') &&
            !svgViewportEl.getAttribute('style')) {
            svgViewportEl.setAttribute('transform', this.gViewBoxTransform);
            svgViewportEl.setAttribute('style', this.gViewBoxStyle);
        }
    }
    /**
     *
     */
    beforePan() {
        const { svgContainerEl } = this;
        if (!svgContainerEl) {
            return;
        }
        const svgElObj = this.getSvgElement();
        if (!svgElObj) {
            return;
        }
        this.pointTooltipOptions.isShow = false;
        this.resumePanZoom(svgElObj.svgEl, svgElObj.svgViewportEl);
        if (!this.isDragEnable || !this.isMoveByMiddle) {
            return false;
        }
    }
    /**
     *
     */
    beforeZoom() {
        const svgElObj = this.getSvgElement();
        if (!svgElObj) {
            return;
        }
        this.pointTooltipOptions.isShow = false;
        this.resumePanZoom(svgElObj.svgEl, svgElObj.svgViewportEl);
    }
};
PanZoomMixin = __decorate([
    Component
], PanZoomMixin);
export default PanZoomMixin;
