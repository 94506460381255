/**
 * 基础日历-模式
 */
export var PiBaseCalendarModeEnum;
(function (PiBaseCalendarModeEnum) {
    /**
     * 查看模式
     */
    PiBaseCalendarModeEnum[PiBaseCalendarModeEnum["View"] = 1] = "View";
    /**
     * 可框选模式
     */
    PiBaseCalendarModeEnum[PiBaseCalendarModeEnum["Selectable"] = 2] = "Selectable";
})(PiBaseCalendarModeEnum || (PiBaseCalendarModeEnum = {}));
