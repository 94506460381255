import { VueBase, SResultEntity } from 'bc$/main';
import Vue from 'vue';
//#region dom event
/**
 * Svg websocket 订阅
 */
export class PiSvgDomEventParseMixin extends VueBase {
    /**
     * 点击事件监听 - svg元素
     */
    onSvgElementClick(e) { }
}
//#endregion
//#region websocket mixin
/**
 * Svg websocket 订阅
 */
export class PiSvgWebsocketMixin extends VueBase {
    /**
     * 初始化订阅点
     */
    initSubscribePointList() { }
    /**
     * 取消所有订阅点
     */
    unSubscribePointList() { }
    /**
     * 按照动画类型更新对应效果
     *
     * @param eleId
     * @param ptPath
     * @param tagData
     * @param val
     */
    updatePtPathView(ele, dynData, tagData, configuration) { }
}
//#endregion
//#region 设备点算法解析
export class PiSvgPointRuleParserMixin extends VueBase {
    /**
     * svg设备点规则转换算法
     *
     * @param value
     * @param listCondition
     */
    convertValue(data, listCondition) {
        return '';
    }
}
//#endregion
//#region prop
/**
 * prop mixin
 */
export const MixinProp = Vue;
//#endregion
//#region data
/**
 * data mixin
 */
export const MixinData = Vue;
//#endregion
//#region svg computed
/**
 * computed mixin
 */
export const MixinComputed = Vue;
/**
 * svg组件 computed 定义
 */
export class IPiSvgComputed extends VueBase {
    get svgJsonListDraw() {
        return [];
    }
    /**
     * 请求svg内容的url
     */
    get reqSvgInfoUrl() {
        return '';
    }
    /**
     * 全屏目标dom
     */
    get fullScreenHEl() {
        return {};
    }
}
//#endregion
//#region svg methods
/**
 * methods
 */
export const MixinMethods = Vue;
/**
 * svg组件 computed 定义
 */
export class PiSvgMethods extends VueBase {
    /**
     * 移除所有的 Timeout aJax 异步请求
     */
    removeTimeoutAndAJax() { }
    /**
     * 根据ID获取对应的动画数据
     *
     * @param id
     * @param dnyType
     */
    getDynDataByiIdType(id, dnyType) {
        return {};
    }
    /**
     * 获取动画信息和对应 ele id
     *
     * @param ptPath
     */
    getAnimationDataByPtPath(ptPath) {
        return [];
    }
    /**
     * 判断点数据是否有效
     *
     * tag data 不存在，且在设备联合类型中存在，则认为无效
     *
     * @param data
     */
    isValidRunConfPointData() {
        return (data) => {
            return false;
        };
    }
    /**
     * 解析 Tag
     *
     * 如果tag数据为空，则设置默认值
     */
    renderTagData(data) {
        return {};
    }
    // /**
    //  * 获取 svg 动画绑定的 dom
    //  * @param id
    //  */
    // getSvgAnimationEl(id: string) {
    //   return {} as SVGElement | null | undefined;
    // }
    /**
     * 销毁SVG
     */
    destroySvg() { }
    /**
     * 初始化svg
     */
    initSvg() { }
    /**
     * 请求后台 获取对应数据
     *
     * @param params TODO: 预留参数
     */
    getSvgBasicData(params) {
        return Promise.resolve(void 0);
    }
    /**
     * 绑定事件
     */
    bindEvent() { }
    /**
     * 解绑事件
     */
    unBindEvent() { }
    /**
     * 初始化 svg 容器 dom
     */
    initSvgContainerEl() { }
    /**
     * 获取后台 run conf data
     */
    initSvgRunConfData() { }
    /**
     * 获取根shadow
     */
    getCurrentRootShadow() {
        return {};
    }
    /**
     * 获取svg element和 svg viewport element
     */
    getSvgElement() {
        return {};
    }
    /**
     * svg 重置宽高
     */
    resizeSvg() { }
    /**
     * svg 容器初始化
     */
    initSvgContainer() { }
    /**
     *初始化svg内容
     */
    initSvgContent() { }
    /**
     *  通过后台请求重构svg模板内容
     *
     *  如设备点信息数据，开关状态等
     */
    refactorSvgContent(data) { }
    /**
     * 暂时禁用放大缩小和svg的功能
     * @param jSvg 当前svg对象
     * @param jVp 当前svg对象首个g标签
     */
    pausePanZoom(jSvg, jVp) { }
    /**
     * 继续开放放大缩小和svg的功能
     * @param jSvg 当前svg对象
     * @param jVp 当前svg对象首个g标签
     */
    resumePanZoom(jSvg, jVp) { }
    /**
     * svg zoom beforeZoom事件
     */
    beforeZoom() { }
    /**
     * svg zoom beforePan事件
     */
    beforePan() { }
    /**
     * 根据ID获取 ptPath
     *
     * @param id
     */
    getPtPathList(id) {
        return [''];
    }
    /**
     * 查找 ptPath 相关的 runConf数据
     * @param ptPathLit
     */
    getPointRunConfData(ptPathList) {
        return [];
    }
    /**
     * 根据 svg json 获取节点对应动画规则
     *
     * @param pointId
     */
    getAnimateRules(pointId) {
        return [];
    }
    /**
     * 获取所有 ptPath
     */
    getAllPtPathFromJson() {
        return [];
    }
    /**
     * 获取所有 json 文件的设备点
     */
    getAllPointId() {
        return [];
    }
    /**
     *  获取event target
     * @param e
     */
    getEventTarget(e) {
        return {};
    }
    /**
     * 根据点ID获取元素绑定动画
     * @param id
     */
    getPointAnimationList(id) {
        return [];
    }
    /**
     * 枚举解析
     */
    getEnumDisplayValue(key, confEnumList) {
        return '';
    }
}
//#endregion
//#region svg websocket
/**
 * ws
 */
// export const MixinPointWs = Vue as VueClass<PiSvgPointWs>;
export class PiSvgPointWs extends VueBase {
    /**
     * svg 点动画订阅
     *
     * @param eleId
     */
    async subscribePoint(ptPath) { }
    /**
     * svg 点动画取消订阅
     *
     * @param subId
     */
    async unSubscribePoint(ptPath, subData) { }
}
//#endregion
//#region svg 设备点动画解析
export class PiSvgPointAnimateParse extends VueBase {
    /**
     * 节点闪烁逻辑
     *
     * @param id
     * @param isFlashing
     */
    nodeFlashingHandler(ele, isFlashing) {
        return;
    }
    /**
     * 图片路径动画
     *
     * @param id
     * @param imgPath
     */
    nodeImagePathHandler(ele, imgPath) {
        return;
    }
    /**
     * 节点显示、隐藏逻辑
     *
     * @param ele
     * @param isShow
     */
    nodeShowHandler(ele, isShow) {
        return;
    }
    /**
     * 命令链接
     *
     * @param PointId
     * @param PtPath
     * @param DynDesc
     * @param Param1
     * @param Param2
     * @param Param3
     * @param Param4
     * @param Param5
     */
    nodeCustomeCommand(PointId, PtPath, DynDesc, Param1, Param2, Param3, Param4, Param5) {
        return;
    }
    /**
     * 根据 dom 获取
     *
     * @param id
     */
    getDom(id) {
        return null;
    }
    /**
     * 文本动画
     */
    nodeTextHandler(ele, color, content) {
        return;
    }
    /**
     * 画刷动画
     */
    nodeBrushHandler(ele, color) {
        return;
    }
    /**
     * 画笔动画
     */
    nodeStrokeHandler(ele, color) {
        return;
    }
    /**
     * 更新svg文本显示值
     *
     * @param id
     * @param displayValue
     */
    updateSvgTxtEleDisplayVal(dom, displayValue) { }
}
//#endregion
//#region tooltip提示
/**
 *
 */
export const MixinPointTooltip = Vue;
/**
 * svg组件 computed 定义
 */
export class PiPointTooltip extends VueBase {
    /**
     * 设备点tooltip事件绑定逻辑
     *
     * @param event
     */
    tooltipEventListener(event) {
        return;
    }
}
//#endregion
