/**
 * svg websocket topic 订阅
 */
export var PiSvgRunConfDataEnum;
(function (PiSvgRunConfDataEnum) {
    /**
     * 数值
     */
    PiSvgRunConfDataEnum[PiSvgRunConfDataEnum["Number"] = 0] = "Number";
    /**
     * 枚举
     */
    PiSvgRunConfDataEnum[PiSvgRunConfDataEnum["Enumerate"] = 1] = "Enumerate";
})(PiSvgRunConfDataEnum || (PiSvgRunConfDataEnum = {}));
/**
 * Iview Pager 每页条数切换的配置
 */
export var PagerSizeOptsEnum;
(function (PagerSizeOptsEnum) {
    /**
     * 20
     */
    PagerSizeOptsEnum[PagerSizeOptsEnum["Size20"] = 20] = "Size20";
    /**
     * 50
     */
    PagerSizeOptsEnum[PagerSizeOptsEnum["Size50"] = 50] = "Size50";
    /**
     * 100
     */
    PagerSizeOptsEnum[PagerSizeOptsEnum["Size100"] = 100] = "Size100";
})(PagerSizeOptsEnum || (PagerSizeOptsEnum = {}));
/**
 * svg websocket topic 订阅
 */
export var PiSvgAnimateTypeEnum;
(function (PiSvgAnimateTypeEnum) {
    /**
     * 文本动画（类型-1）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Text"] = 1] = "Text";
    /**
     * 画笔动画（类型-2）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Paint"] = 2] = "Paint";
    /**
     * 画刷动画（类型-3）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Brush"] = 3] = "Brush";
    /**
     * 图片动画（类型-4）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Image"] = 4] = "Image";
    /**
     * 子图动画（类型-5）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["SubSvg"] = 5] = "SubSvg";
    /**
     * 闪烁动画（类型-6）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Flashing"] = 6] = "Flashing";
    /**
     * 可见性动画（类型-7）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Visibility"] = 7] = "Visibility";
    /**
     * 控制操作（类型-8）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Control"] = 8] = "Control";
    /**
     * 画面链接（类型-9）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Link"] = 9] = "Link";
    /**
     * 命令链接（类型-11）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Command"] = 11] = "Command";
    /**
     * 水平移动（类型-12）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["HorizontalMove"] = 12] = "HorizontalMove";
    /**
     * 垂直移动（类型-13）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["VerticalMove"] = 13] = "VerticalMove";
    /**
     * 缩放动画（类型-14）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Scale"] = 14] = "Scale";
    /**
     * 自旋转（类型-15）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Rotate"] = 15] = "Rotate";
    /**
     * 中心旋转（类型-16）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["RotateCenter"] = 16] = "RotateCenter";
    /**
     * 时间动画（类型-17）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Time"] = 17] = "Time";
    /**
     * 视频动画（类型-18）
     */
    PiSvgAnimateTypeEnum[PiSvgAnimateTypeEnum["Video"] = 18] = "Video";
})(PiSvgAnimateTypeEnum || (PiSvgAnimateTypeEnum = {}));
/**
 * svg 动效 - 命令链接（类型-11）: param 1
 */
export var PiSvgCommandAnimateEnum;
(function (PiSvgCommandAnimateEnum) {
    // 表格弹窗
    PiSvgCommandAnimateEnum["TableModal"] = "popwnd_table";
    // 视频浮动提示
    PiSvgCommandAnimateEnum["VideoToolTip"] = "popwnd_view";
})(PiSvgCommandAnimateEnum || (PiSvgCommandAnimateEnum = {}));
