var PiBaseError_1;
import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import template from './pi-base-error.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-error.component.scss';
let PiBaseError = PiBaseError_1 = class PiBaseError extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseError';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseError_1?.NAME,
        author: 'wuzp',
        title: '基础错误组件',
        version: BcVersion,
        updateDate: '2019-05-23',
        description: '',
    };
    //#endregion
    //#region props
    //#endregion
    //#region data
    //#endregion
    //#region computed
    get about() {
        return PiBaseError_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
};
PiBaseError = PiBaseError_1 = __decorate([
    Component({
        template,
    })
], PiBaseError);
export default PiBaseError;
