import { hReactiveUtil } from 'bc$/utils/reactive.helper';
/**
 * 版本：echarts 4.3.0
 * 响应式处理各属性像素值
 * @description
 * 1.默认值为0的未处理
 * 2.非常规项未处理
 * @author hex
 */
/**
 * 坐标轴统一配置项
 */
const getAxisSetting = function () {
    return {
        nameTextStyle: {
            fontSize: hReactiveUtil.convertToReactivePx(12),
        },
        nameGap: hReactiveUtil.convertToReactivePx(15),
        axisLine: {
            lineStyle: {
                width: hReactiveUtil.convertToReactivePx(1),
            },
        },
        axisTick: {
            length: hReactiveUtil.convertToReactivePx(5),
            lineStyle: {
                width: hReactiveUtil.convertToReactivePx(1),
            },
        },
        axisLabel: {
            margin: hReactiveUtil.convertToReactivePx(8),
            fontSize: hReactiveUtil.convertToReactivePx(12),
        },
        splitLine: {
            lineStyle: {
                width: hReactiveUtil.convertToReactivePx(1),
            },
        },
        axisPointer: {
            label: {
                margin: hReactiveUtil.convertToReactivePx(3),
                fontSize: hReactiveUtil.convertToReactivePx(12),
                padding: [
                    hReactiveUtil.convertToReactivePx(5),
                    hReactiveUtil.convertToReactivePx(7),
                ],
            },
            lineStyle: {
                width: hReactiveUtil.convertToReactivePx(1),
            },
        },
    };
};
/**
 * 响应式配置对象
 */
const getReactiveConfig = function () {
    return {
        textStyle: {
            fontSize: hReactiveUtil.convertToReactivePx(12),
        },
        title: {
            padding: hReactiveUtil.convertToReactivePx(5),
            itemGap: hReactiveUtil.convertToReactivePx(10),
            textStyle: {
                fontSize: hReactiveUtil.convertToReactivePx(18),
            },
        },
        legend: {
            padding: hReactiveUtil.convertToReactivePx(5),
            itemGap: hReactiveUtil.convertToReactivePx(10),
            itemWidth: hReactiveUtil.convertToReactivePx(25),
            itemHeight: hReactiveUtil.convertToReactivePx(14),
            textStyle: {
                fontSize: hReactiveUtil.convertToReactivePx(12),
            },
        },
        grid: {
            top: hReactiveUtil.convertToReactivePx(60),
            bottom: hReactiveUtil.convertToReactivePx(60),
            borderWidth: hReactiveUtil.convertToReactivePx(1),
        },
        categoryAxis: getAxisSetting(),
        valueAxis: getAxisSetting(),
        logAxis: getAxisSetting(),
        timeAxis: getAxisSetting(),
        tooltip: {
            trigger: 'axis',
            borderRadius: hReactiveUtil.convertToReactivePx(4),
            padding: hReactiveUtil.convertToReactivePx(5),
            textStyle: {
                fontSize: hReactiveUtil.convertToReactivePx(14),
            },
            formatter: (params) => {
                const width = hReactiveUtil.convertToReactivePx(10);
                const marginRight = hReactiveUtil.convertToReactivePx(5);
                function make(paramsData) {
                    const marker = `<span style="display:inline-block;margin-right:${marginRight}px;border-radius:${width}px;
          width:${width}px;height:${width}px;background-color:${paramsData.color};"></span>`;
                    return `${marker}${paramsData.value}`;
                }
                if (Array.isArray(params)) {
                    let template = `${params[0].name}`;
                    params.forEach(paramsData => {
                        template += '<br/>' + make(paramsData);
                    });
                    return template;
                }
                else {
                    let template = `${params.name}<br/>`;
                    return (template += make(params));
                }
            },
        },
    };
};
/**
 * 获取合并后的theme
 */
export const getTheme = function () {
    return Object.assign(
    //UI给的json文件替换以下内容
    {
        color: [
            '#1b9ee0',
            '#57ce9e',
            '#e5b94c',
            '#21c6c8',
            '#8890db',
            '#67ca29',
            '#ca8622',
            '#626c91',
            '#6e7074',
            '#546570',
            '#bda29a',
        ],
        backgroundColor: 'rgba(0, 0, 0, 0)',
        textStyle: {},
        title: {
            textStyle: {
                color: '#1b9ee0',
            },
            subtextStyle: {
                color: '#495060',
            },
        },
        line: {
            itemStyle: {
                normal: {
                    borderWidth: 1,
                },
            },
            lineStyle: {
                normal: {
                    width: 2,
                },
            },
            symbolSize: 4,
            symbol: 'emptyCircle',
            smooth: false,
        },
        radar: {
            itemStyle: {
                normal: {
                    borderWidth: 1,
                },
            },
            lineStyle: {
                normal: {
                    width: 2,
                },
            },
            symbolSize: 4,
            symbol: 'emptyCircle',
            smooth: false,
        },
        bar: {
            itemStyle: {
                normal: {
                    barBorderWidth: '0',
                    barBorderColor: '#cccccc',
                },
                emphasis: {
                    barBorderWidth: '0',
                    barBorderColor: '#cccccc',
                },
            },
        },
        pie: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
                emphasis: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
        },
        scatter: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
                emphasis: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
        },
        boxplot: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
                emphasis: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
        },
        parallel: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
                emphasis: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
        },
        sankey: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
                emphasis: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
        },
        funnel: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
                emphasis: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
        },
        gauge: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
                emphasis: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
        },
        candlestick: {
            itemStyle: {
                normal: {
                    color: '#c23531',
                    color0: '#314656',
                    borderColor: '#c23531',
                    borderColor0: '#314656',
                    borderWidth: 1,
                },
            },
        },
        graph: {
            itemStyle: {
                normal: {
                    borderWidth: '0',
                    borderColor: '#cccccc',
                },
            },
            lineStyle: {
                normal: {
                    width: 1,
                    color: '#aaa',
                },
            },
            symbolSize: 4,
            symbol: 'emptyCircle',
            smooth: false,
            color: [
                '#1b9ee0',
                '#57ce9e',
                '#e5b94c',
                '#21c6c8',
                '#8890db',
                '#67ca29',
                '#ca8622',
                '#626c91',
                '#6e7074',
                '#546570',
                '#bda29a',
            ],
            label: {
                normal: {
                    textStyle: {
                        color: '#ffffff',
                    },
                },
            },
        },
        map: {
            itemStyle: {
                normal: {
                    areaColor: '#eee',
                    borderColor: '#444',
                    borderWidth: 0.5,
                },
                emphasis: {
                    areaColor: 'rgba(255,215,0,0.8)',
                    borderColor: '#444',
                    borderWidth: 1,
                },
            },
            label: {
                normal: {
                    textStyle: {
                        color: '#000',
                    },
                },
                emphasis: {
                    textStyle: {
                        color: 'rgb(100,0,0)',
                    },
                },
            },
        },
        geo: {
            itemStyle: {
                normal: {
                    areaColor: '#eee',
                    borderColor: '#444',
                    borderWidth: 0.5,
                },
                emphasis: {
                    areaColor: 'rgba(255,215,0,0.8)',
                    borderColor: '#444',
                    borderWidth: 1,
                },
            },
            label: {
                normal: {
                    textStyle: {
                        color: '#000',
                    },
                },
                emphasis: {
                    textStyle: {
                        color: 'rgb(100,0,0)',
                    },
                },
            },
        },
        categoryAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisTick: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#495060',
                },
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: ['#ccc'],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
                },
            },
        },
        valueAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisTick: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#495060',
                },
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#d6d6d6'],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
                },
            },
        },
        logAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisTick: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#495060',
                },
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#cccccc'],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
                },
            },
        },
        timeAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisTick: {
                show: true,
                lineStyle: {
                    color: '#495060',
                },
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#333',
                },
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#ccc'],
                },
            },
            splitArea: {
                show: false,
                areaStyle: {
                    color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
                },
            },
        },
        toolbox: {
            iconStyle: {
                normal: {
                    borderColor: '#495060',
                },
                emphasis: {
                    borderColor: '#666',
                },
            },
        },
        legend: {
            textStyle: {
                color: '#333',
            },
        },
        tooltip: {
            axisPointer: {
                lineStyle: {
                    color: '#ccc',
                    width: 1,
                },
                crossStyle: {
                    color: '#ccc',
                    width: 1,
                },
            },
        },
        timeline: {
            lineStyle: {
                color: '#293c55',
                width: 1,
            },
            itemStyle: {
                normal: {
                    color: '#293c55',
                    borderWidth: 1,
                },
                emphasis: {
                    color: '#a9334c',
                },
            },
            controlStyle: {
                normal: {
                    color: '#293c55',
                    borderColor: '#293c55',
                    borderWidth: 0.5,
                },
                emphasis: {
                    color: '#293c55',
                    borderColor: '#293c55',
                    borderWidth: 0.5,
                },
            },
            checkpointStyle: {
                color: '#e43c59',
                borderColor: 'rgba(194,53,49, 0.5)',
            },
            label: {
                normal: {
                    textStyle: {
                        color: '#293c55',
                    },
                },
                emphasis: {
                    textStyle: {
                        color: '#293c55',
                    },
                },
            },
        },
        visualMap: {
            color: ['#bf444c', '#d88273', '#f6efa6'],
        },
        dataZoom: {
            backgroundColor: 'rgba(47,69,84,0)',
            dataBackgroundColor: 'rgba(27,158,224,0.3)',
            fillerColor: 'rgba(170,228,255,0.4)',
            handleColor: '#1b9ee0',
            handleSize: '100%',
            textStyle: {
                color: '#495060',
            },
        },
        markPoint: {
            label: {
                normal: {
                    textStyle: {
                        color: '#ffffff',
                    },
                },
                emphasis: {
                    textStyle: {
                        color: '#ffffff',
                    },
                },
            },
        },
    }, getReactiveConfig());
};
