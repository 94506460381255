var PiBaseEasyTableCheckboxGroup_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { default as Utils } from '../../utils/utils';
import template from './pi-base-easy-table-checkbox-group.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-easy-table-checkbox-group.component.scss';
let PiBaseEasyTableCheckboxGroup = PiBaseEasyTableCheckboxGroup_1 = class PiBaseEasyTableCheckboxGroup extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEasyTableCheckboxGroup';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEasyTableCheckboxGroup_1?.NAME,
        author: 'yangd',
        title: '无标题',
        version: BcVersion,
        updateDate: '2019-08-08',
        description: '',
    };
    //#endregion
    //#region props
    multipleCheck;
    loadingContent;
    value;
    // 是否垂直排列显示（当时checkbox组时生效）
    isVerticalShow;
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEasyTableCheckboxGroup_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    // 更新子组件选中状态
    'watch.value'(newVal, oldVal) {
        let children = Utils.getChildCompsByName(this, 'v-checkbox');
        if (children.length > 0) {
            children.forEach((child) => {
                child.updateModelByGroup(newVal);
            });
        }
    }
    //#endregion
    //#region methods
    updateModel(label, checkedVal) {
        const { value } = this;
        // 单选
        if (!this.multipleCheck) {
            if (value.length > 0) {
                value.splice(0);
            }
            if (checkedVal) {
                value.push(label);
            }
        }
        else {
            // 多选
            const index = value.indexOf(label);
            if (index > -1) {
                if (!checkedVal) {
                    this.value.splice(index, 1);
                }
            }
            else {
                if (checkedVal) {
                    this.value.push(label);
                }
            }
        }
        this.$emit('input', this.value);
        this.$emit('change');
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTableCheckboxGroup.prototype, "multipleCheck", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBaseEasyTableCheckboxGroup.prototype, "loadingContent", void 0);
__decorate([
    Prop({
        type: Array,
        default() {
            return [];
        },
    })
], PiBaseEasyTableCheckboxGroup.prototype, "value", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTableCheckboxGroup.prototype, "isVerticalShow", void 0);
__decorate([
    Watch('value')
], PiBaseEasyTableCheckboxGroup.prototype, "watch.value", null);
PiBaseEasyTableCheckboxGroup = PiBaseEasyTableCheckboxGroup_1 = __decorate([
    Component({
        name: 'v-checkbox-group',
        template,
    })
], PiBaseEasyTableCheckboxGroup);
export default PiBaseEasyTableCheckboxGroup;
