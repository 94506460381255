import { __decorate } from "tslib";
import { hODeepClone, VueBase, hODeepObjectMerge } from 'bc$/main';
import IEcharts from 'vue-echarts-v3';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ECHARTS_EVENTS } from './pi-base-echarts.common';
import template from './pi-base-echarts.base.html';
/**
 * PiBaseEcharts
 * @author hex
 */
let PiBaseEcharts = class PiBaseEcharts extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    //#endregion
    //#region props
    /**
     * IECharts options
     */
    options;
    /**
     * 图表数据（该组件内定格式）
     */
    data;
    //#endregion
    //#region data
    localOption = {};
    localOptions = {
        notMerge: true,
    };
    //#endregion
    //#region watch
    /**
     * Echarts option
     * @param newOptions
     */
    'watch.options'(newOptions) {
        hODeepObjectMerge(this.localOptions, newOptions);
    }
    /**
     * Echarts option
     * @param newOption
     */
    'watch.options.option'(newOption) {
        this.generateChartData(hODeepClone(newOption));
    }
    //#endregion
    //#region methods
    /**
     * 生成图表数据
     * @param data
     */
    generateChartData(data) { }
    getEchartsOptions() {
        return {};
    }
    getSeriesItem() {
        return {};
    }
    /**
     * 转发Echarts事件
     */
    transmitEchartsEvents() {
        const that = this;
        ECHARTS_EVENTS.forEach((eventName) => {
            this.getIEchartsInstance().$on(eventName, function () {
                that.$emit(eventName, ...arguments);
            });
        });
    }
    /**
     * IEcharts ready事件
     */
    onReady() {
        this.$emit('on-ready', ...arguments);
    }
    /**
     * IEcharts resize事件
     */
    onResize() {
        this.$emit('on-resize', ...arguments);
    }
    /**
     * IEcharts实例
     */
    getIEchartsInstance() {
        return this.$refs['IEcharts'];
    }
    /**
     * Echarts实例
     */
    getEchartsInstance() {
        return this.$refs['IEcharts'].instance;
    }
    //#endregion
    //#region hooks
    mounted() {
        this.transmitEchartsEvents();
    }
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], PiBaseEcharts.prototype, "options", void 0);
__decorate([
    Prop({ type: Array })
], PiBaseEcharts.prototype, "data", void 0);
__decorate([
    Watch('options', { deep: true, immediate: true })
], PiBaseEcharts.prototype, "watch.options", null);
__decorate([
    Watch('options.option', { deep: true, immediate: true })
], PiBaseEcharts.prototype, "watch.options.option", null);
PiBaseEcharts = __decorate([
    Component({
        template,
        components: {
            IEcharts,
        },
    })
], PiBaseEcharts);
export default PiBaseEcharts;
