/**
 * 布局模式
 */
export var LayoutMode;
(function (LayoutMode) {
    /**
     * 页面设计模式
     */
    LayoutMode[LayoutMode["design"] = 0] = "design";
    /**
     * 页面非设计模式 (退出编辑)
     */
    LayoutMode[LayoutMode["noDesign"] = 1] = "noDesign";
})(LayoutMode || (LayoutMode = {}));
/**
 * SortableJs put pull option 限制器
 */
export var SortableJsOptionName;
(function (SortableJsOptionName) {
    SortableJsOptionName["row"] = "piRow";
    SortableJsOptionName["column"] = "piColumn";
})(SortableJsOptionName || (SortableJsOptionName = {}));
/**
 * 行列选中集合的类型
 */
export var RowsColumnsSelectedType;
(function (RowsColumnsSelectedType) {
    /**
     * 未知类型
     */
    RowsColumnsSelectedType[RowsColumnsSelectedType["unknown"] = -1] = "unknown";
    /**
     * 全部数据为行类型
     */
    RowsColumnsSelectedType[RowsColumnsSelectedType["allRows"] = 0] = "allRows";
    /**
     * 全部数据为列类型
     */
    RowsColumnsSelectedType[RowsColumnsSelectedType["allColumns"] = 1] = "allColumns";
    /**
     * 全部数据为混合类型 行和列
     */
    RowsColumnsSelectedType[RowsColumnsSelectedType["mixinRowsColumns"] = 2] = "mixinRowsColumns";
})(RowsColumnsSelectedType || (RowsColumnsSelectedType = {}));
