import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
/**
 * SVG - 切换动画
 */
let SvgTransitionMixin = class SvgTransitionMixin extends VueMixinBase {
    //#region data
    transitionOptions = {
        classPrefix: 'animate__animated animate__',
        enterActiveClass: 'zoomIn',
        leaveActiveClass: 'zoomOut',
    };
    //#endregion
    //#region computed
    /**
     * transition 进入样式
     */
    get transitionEnterActiveClass() {
        const { classPrefix, enterActiveClass } = this.transitionOptions;
        return `${classPrefix + enterActiveClass}`;
    }
    /**
     * transition 离开样式
     */
    get transitionLeaveActiveClass() {
        const { classPrefix, leaveActiveClass } = this.transitionOptions;
        return `${classPrefix + leaveActiveClass}`;
    }
    //#endregion
    //#region methods
    /**
     * 事件回调： Transition离开前
     */
    onTransitionBeforeLeave() {
        this.unBindEvent();
        this.initSvgContainerEl();
    }
    /**
     * 事件回调： Transition进入前
     */
    onTransitionBeforeEnter() {
        this.initSvgContent();
    }
};
SvgTransitionMixin = __decorate([
    Component
], SvgTransitionMixin);
export default SvgTransitionMixin;
