import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
/*
 * 固定列
 */
let FrozenColumnsMixin = class FrozenColumnsMixin extends Vue {
    // 冻结的列集合
    get frozenCols() {
        const that = this;
        return that.internalColumns.filter((x) => x.isFrozen === true);
    }
    // 非冻结列集合
    get noFrozenCols() {
        const that = this;
        return that.internalColumns.filter((x) => x.isFrozen !== true);
    }
    // 冻结的表头列集合
    get frozenTitleCols() {
        const that = this;
        const frozenTitleCols = [];
        if (that.internalTitleRows.length > 0) {
            // 获取当前锁定的字段集合
            const frozenFields = that.frozenCols.map((x) => x.field);
            that.internalTitleRows.forEach(function (rows) {
                const frozenTitleRows = rows.filter(function (row) {
                    if (Array.isArray(row.fields)) {
                        if (row.fields.every((field) => frozenFields.indexOf(field) !== -1)) {
                            return true;
                        }
                    }
                });
                if (frozenTitleRows.length > 0) {
                    frozenTitleCols.push(frozenTitleRows);
                    const minRowspan = that.getMinRowspan(frozenTitleRows);
                    if (minRowspan && minRowspan > 0) {
                        for (let i = 0; i < minRowspan; i++) {
                            frozenTitleCols.push([]);
                        }
                    }
                }
            });
        }
        return frozenTitleCols;
    }
    // 未的表头列集合
    get noFrozenTitleCols() {
        const that = this;
        const noFrozenTitleCols = [];
        if (that.internalTitleRows.length > 0) {
            // 获取当前未锁定的字段集合
            const noFrozenFields = this.noFrozenCols.map((x) => x.field);
            that.internalTitleRows.forEach(function (rows) {
                const noFrozenTitleRows = rows.filter(function (row) {
                    if (Array.isArray(row.fields)) {
                        return row.fields.every((field) => noFrozenFields.indexOf(field) !== -1);
                    }
                });
                if (noFrozenTitleRows.length > 0) {
                    noFrozenTitleCols.push(noFrozenTitleRows);
                    const minRowspan = that.getMinRowspan(noFrozenTitleRows);
                    if (minRowspan && minRowspan > 0) {
                        for (let i = 0; i < minRowspan; i++) {
                            noFrozenTitleCols.push([]);
                        }
                    }
                }
            });
        }
        return noFrozenTitleCols;
    }
};
FrozenColumnsMixin = __decorate([
    Component
], FrozenColumnsMixin);
export default FrozenColumnsMixin;
