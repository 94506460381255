import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
let VueComputedMixin = class VueComputedMixin extends VueMixinBase {
    /**
     * svg json ListDraw数据
     */
    get svgJsonListDraw() {
        const { svgJson } = this;
        if (svgJson) {
            return svgJson.listDraw;
        }
        return [];
    }
    /**
     * 请求svg内容的url
     */
    get reqSvgInfoUrl() {
        // TODO: 以后扩展这里逻辑实现下钻获取新svg内容
        return this.svgUrlData.svgInfoUrl;
    }
    /**
     * 全屏目标对象
     */
    get fullScreenHEl() {
        return this.fullScreenHElProp || this.$el;
    }
};
VueComputedMixin = __decorate([
    Component
], VueComputedMixin);
export default VueComputedMixin;
