import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
let BodyCellMergeMixin = class BodyCellMergeMixin extends Vue {
    // 跳过渲染的列集合
    skipRenderCells = [];
    /*
     * isFrozenColumns:是否是固定列
     * */
    cellMergeInit(rowIndex, field, rowData, isFrozenColumns) {
        const that = this;
        // 包含在 skipRenderCells 内，则不渲染
        if (this.skipRenderCells.indexOf(rowIndex + '-' + field) !== -1) {
            return false;
        }
        let setting = that.cellMerge?.(rowIndex, rowData, field);
        if (setting &&
            ((setting.colSpan && setting.colSpan > 1) ||
                (setting.rowSpan && setting.rowSpan > 1))) {
            this.setSkipRenderCells(setting.colSpan, setting.rowSpan, rowIndex, field, isFrozenColumns);
        }
        return true;
    }
    // 设置不渲染的列
    setSkipRenderCells(colSpan, rowSpan, rowIndex, field, isFrozenColumns) {
        const that = this;
        let columnsFields = isFrozenColumns
            ? that.getFrozenColumnsFields
            : that.getNoFrozenColumnsFields, skipCell = '', startPosX, endPosX, startPosY, endPosY;
        endPosX = startPosX = columnsFields.indexOf(field);
        if (colSpan && colSpan > 1) {
            endPosX = startPosX + colSpan - 1;
        }
        endPosY = startPosY = rowIndex;
        if (rowSpan && rowSpan > 1) {
            endPosY = rowIndex + rowSpan - 1;
        }
        for (let posX = startPosX; posX <= endPosX; posX++) {
            for (let posY = startPosY; posY <= endPosY; posY++) {
                if (posX == startPosX && posY == startPosY) {
                    continue;
                }
                skipCell = posY + '-' + columnsFields[posX];
                // 避免状态改变重新渲染的情况
                if (this.skipRenderCells.indexOf(skipCell) === -1) {
                    this.skipRenderCells.push(skipCell);
                }
            }
        }
    }
    // 设置 colSpan
    setColRowSpan(rowIndex, field, rowData) {
        const that = this;
        let result = {
            colSpan: '',
            rowSpan: '',
        }, setting = that.cellMerge?.(rowIndex, rowData, field);
        if (setting) {
            result = {
                colSpan: setting.colSpan ? `${setting.colSpan}` : '',
                rowSpan: setting.rowSpan ? `${setting.rowSpan}` : '',
            };
        }
        return result;
    }
    /*
     * 并检测不合法的设置，如果设置不合法则不会合并行和列
     * */
    isCellMergeRender(rowIndex, field, rowData) {
        const that = this;
        let setting = that.cellMerge?.(rowIndex, rowData, field);
        if (setting &&
            ((setting.colSpan && setting.colSpan > 0) ||
                (setting.rowSpan && setting.rowSpan > 0))) {
            return true;
        }
        return false;
    }
    // 获取行高
    getRowHeightByRowSpan(rowIndex, field, rowData) {
        const that = this;
        let setting = that.cellMerge?.(rowIndex, rowData, field);
        if (setting && setting.rowSpan && setting.rowSpan > 1) {
            return that.propReactiveProxy.rowHeight * setting.rowSpan;
        }
        return that.propReactiveProxy.rowHeight;
    }
    /*
     * 获取单元格宽度
     */
    getRowWidthByColSpan(rowIndex, field, rowData) {
        const that = this;
        let endPosX, startPosX, columnsFields = that.getColumnsFields, setting = that.cellMerge?.(rowIndex, rowData, field), colSpan = (setting && setting.colSpan) || 1, //与原js文件不同
        totalWidth = 0;
        if (setting && colSpan && colSpan >= 1) {
            startPosX = columnsFields.indexOf(field);
            endPosX = startPosX + colSpan - 1;
            for (let i = startPosX; i <= endPosX; i++) {
                that.internalColumns.forEach((x) => {
                    if (columnsFields[i] === x.field) {
                        totalWidth += x.width || 0;
                    }
                });
            }
        }
        return totalWidth;
    }
    // 合并的单元格渲染的内容类型
    cellMergeContentType(rowIndex, field, rowData) {
        const that = this;
        let result = {
            isComponent: false,
            isContent: false,
        };
        const setting = that.cellMerge?.(rowIndex, rowData, field);
        if (setting) {
            if (setting.componentName &&
                typeof setting.componentName === 'string' &&
                setting.componentName.length > 0) {
                result.isComponent = true;
            }
            else if (setting.content && setting.content.length > 0) {
                result.isContent = true;
            }
        }
        return result;
    }
};
BodyCellMergeMixin = __decorate([
    Component
], BodyCellMergeMixin);
export default BodyCellMergeMixin;
