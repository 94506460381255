import { hVCheckDateTime } from './validate.helper';
/**
 * 日期转字符串
 *@param date - 日期
 */
function hDDate2String(date, format = 'yyyy-MM-dd') {
    if (!date) {
        return '';
    }
    switch (typeof date) {
        case 'string':
            if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
                date = date + 'T00:00';
            }
            date = new Date(date);
            if (!date.getTime()) {
                return '';
            }
            // date = new Date(date.replace(' ', 'T'));
            break;
        case 'number':
            date = new Date(date);
            break;
        case 'object':
            if (Object.prototype.toString.call(date) !== '[object Date]' ||
                isNaN(date.getFullYear())) {
                return '';
            }
            break;
        default:
            return '';
    }
    let _date = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds(),
        W: ['日', '一', '二', '三', '四', '五', '六'][date.getDay()], //周几,中文
    };
    if (/(y+)/i.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in _date) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1
                ? _date[k]
                : ('00' + _date[k]).substr(('' + _date[k]).length));
        }
    }
    return format;
}
/**
 * 转换单时间字符串，用于界面显示时间
 * @param timeList
 * @param timeType
 */
function hDFormatDateTime2Str(timeList, timeType) {
    let _timeStr = '今天';
    const _timeType = parseInt(timeType);
    const _timeList = [];
    for (let dIndex = 0; dIndex < timeList.length; dIndex++) {
        const tempDate = new Date(timeList[dIndex].replace(/-/g, '/'));
        _timeList[dIndex] = tempDate;
    }
    switch (_timeType) {
        case 0: //年
            {
                if (_timeList[0].format('yyyy') ==
                    _timeList[_timeList.length - 1].format('yyyy')) {
                    _timeStr = _timeList[0].format('yyyy年');
                }
                else {
                    _timeStr =
                        _timeList[0].format('yyyy年') +
                            ' - ' +
                            _timeList[_timeList.length - 1].format('yyyy年');
                }
            }
            break;
        case 1: //月
            {
                if (_timeList[0].format('yyyy-MM') ==
                    _timeList[_timeList.length - 1].format('yyyy-MM')) {
                    _timeStr = _timeList[0].format('yyyy年MM月');
                }
                else {
                    if (_timeList[0].getFullYear() ==
                        _timeList[_timeList.length - 1].getFullYear()) {
                        _timeStr =
                            _timeList[0].format('yyyy年MM月') +
                                ' - ' +
                                _timeList[_timeList.length - 1].format('MM月');
                    }
                    else {
                        _timeStr =
                            _timeList[0].format('yyyy年MM月') +
                                ' - ' +
                                _timeList[_timeList.length - 1].format('yyyy年MM月');
                    }
                }
            }
            break;
        case 2: //日
            {
                if (_timeList[0].format('yyyy-MM-dd') ==
                    _timeList[_timeList.length - 1].format('yyyy-MM-dd')) {
                    _timeStr = _timeList[0].format('yyyy年MM月dd日');
                }
                else {
                    if (_timeList[0].getFullYear() ==
                        _timeList[_timeList.length - 1].getFullYear()) {
                        if (_timeList[0].getMonth() ==
                            _timeList[_timeList.length - 1].getMonth()) {
                            _timeStr =
                                _timeList[0].format('yyyy年MM月dd日') +
                                    ' - ' +
                                    _timeList[_timeList.length - 1].format('dd日');
                        }
                        else {
                            _timeStr =
                                _timeList[0].format('yyyy年MM月dd日') +
                                    ' - ' +
                                    _timeList[_timeList.length - 1].format('MM月dd日');
                        }
                    }
                    else {
                        _timeStr =
                            _timeList[0].format('yyyy年MM月dd日') +
                                ' - ' +
                                _timeList[_timeList.length - 1].format('yyyy年MM月dd日');
                    }
                }
            }
            break;
        case 3: //时
            {
                if (_timeList[0].format('yyyy-MM-dd HH') ==
                    _timeList[_timeList.length - 1].format('yyyy-MM-dd HH')) {
                    _timeStr = _timeList[0].format('yyyy年MM月dd日HH时');
                }
                else {
                    if (_timeList[0].format('yyyy-MM-dd') ==
                        _timeList[_timeList.length - 1].format('yyyy-MM-dd')) {
                        _timeStr =
                            _timeList[0].format('yyyy年MM月dd日HH时') +
                                ' - ' +
                                _timeList[_timeList.length - 1].format('HH时');
                    }
                    else {
                        if (_timeList[0].getFullYear() ==
                            _timeList[_timeList.length - 1].getFullYear()) {
                            if (_timeList[0].getMonth() ==
                                _timeList[_timeList.length - 1].getMonth()) {
                                _timeStr =
                                    _timeList[0].format('yyyy年MM月dd日HH时') +
                                        ' - ' +
                                        _timeList[_timeList.length - 1].format('dd日HH时');
                            }
                            else {
                                _timeStr =
                                    _timeList[0].format('yyyy年MM月dd日HH时') +
                                        ' - ' +
                                        _timeList[_timeList.length - 1].format('MM月dd日HH时');
                            }
                        }
                        else {
                            _timeStr =
                                _timeList[0].format('yyyy年MM月dd日HH时') +
                                    ' - ' +
                                    _timeList[_timeList.length - 1].format('yyyy年MM月dd日HH时');
                        }
                    }
                }
            }
            break;
        case 9: //分钟
            {
                if (_timeList[0].format('yyyy-MM-dd HH') ==
                    _timeList[_timeList.length - 1].format('yyyy-MM-dd HH')) {
                    _timeStr =
                        _timeList[0].format('yyyy年MM月dd日HH时mm分') +
                            ' - ' +
                            _timeList[_timeList.length - 1].format('mm分');
                }
                else {
                    if (_timeList[0].format('yyyy-MM-dd') ==
                        _timeList[_timeList.length - 1].format('yyyy-MM-dd')) {
                        _timeStr =
                            _timeList[0].format('yyyy年MM月dd日HH时mm分') +
                                ' - ' +
                                _timeList[_timeList.length - 1].format('HH时mm分');
                    }
                    else {
                        if (_timeList[0].getFullYear() ==
                            _timeList[_timeList.length - 1].getFullYear()) {
                            if (_timeList[0].getMonth() ==
                                _timeList[_timeList.length - 1].getMonth()) {
                                _timeStr =
                                    _timeList[0].format('yyyy年MM月dd日HH时mm分') +
                                        ' - ' +
                                        _timeList[_timeList.length - 1].format('dd日HH时mm分');
                            }
                            else {
                                _timeStr =
                                    _timeList[0].format('yyyy年MM月dd日HH时mm分') +
                                        ' - ' +
                                        _timeList[_timeList.length - 1].format('MM月dd日HH时mm分');
                            }
                        }
                        else {
                            _timeStr =
                                _timeList[0].format('yyyy年MM月dd日HH时mm分') +
                                    ' - ' +
                                    _timeList[_timeList.length - 1].format('yyyy年MM月dd日HH时mm分');
                        }
                    }
                }
            }
            break;
        case 12: //实时十五分钟
            _timeStr = '实时十五分钟';
            break;
        case 13: //今日
            _timeStr = '今日';
            break;
        case 14: //本周
            _timeStr = '本周';
            break;
        case 15: //本月
            _timeStr = '本月';
            break;
        case 16: //本季
            _timeStr = '本季';
            break;
        case 17: //本年
            _timeStr = '本年';
            break;
        case 18: //昨日
            _timeStr = '昨日';
            break;
        case 19: //上周
            _timeStr = '上周';
            break;
        case 20: //上月
            _timeStr = '上月';
            break;
        case 21: //上季
            _timeStr = '上季';
            break;
        case 22: //去年
            _timeStr = '去年';
            break;
        default:
            _timeStr = '时间格式错误！';
            break;
    }
    return _timeStr;
}
/**
 * 导出Excel的日期格式化
 * @param value
 * @param index
 * @param timeType
 */
function hDFormatDateTime(value, timeType = -1) {
    if (Object.keys(value).length === 0 || !hVCheckDateTime(value)) {
        return value;
    }
    // 格式化成月/日，只在第一个刻度显示年份
    let date = new Date(value);
    let result = '';
    switch (timeType) {
        case -1:
            result = date.format('yyyy年MM月dd日HH时mm分');
            break;
        case 0:
            result = date.format('yyyy年');
            break;
        case 1:
        case 16:
        case 17:
        case 21:
        case 22:
            result = date.format('yyyy年MM月');
            break;
        case 2:
        case 10:
        case 14:
        case 15:
        case 19:
        case 20:
            result = date.format('MM月dd日');
            break;
        case 3:
        case 13:
        case 18:
            result = date.format('dd日HH时');
            break;
        case 9:
        case 12:
            result = date.format('dd日HH时mm分');
            break;
        default:
            result = date.format('dd日HH时mm分');
            break;
    }
    return result;
}
/**
 * 格式化X轴日期
 * @param value
 * @param index
 * @param timeType
 */
function hDXAxisformat(value, index, timeType) {
    // 格式化成月/日，只在第一个刻度显示年份
    const date = new Date(value);
    let texts = [];
    switch (timeType) {
        case 0:
            texts = [`${date.getFullYear()}`];
            break;
        case 1:
        case 16:
        case 17:
        case 21:
        case 22:
            {
                const first = `${date.getFullYear()}`;
                const last = date.getMonth() > 8
                    ? `${date.getMonth() + 1}`
                    : '0' + (date.getMonth() + 1);
                texts = [first, last];
            }
            break;
        case 2:
        case 10:
        case 14:
        case 15:
        case 19:
        case 20:
            {
                const first = date.getMonth() > 8
                    ? `${date.getMonth() + 1}`
                    : '0' + (date.getMonth() + 1);
                const last = date.getDate() > 9 ? `${date.getDate()}` : '0' + date.getDate();
                texts = [first, last];
                if (index === 0) {
                    texts.unshift(`${date.getFullYear()}`);
                }
            }
            break;
        case 3:
        case 13:
        case 18: {
            const first = date.getHours() > 9 ? `${date.getHours()}` : '0' + date.getHours();
            const last = date.getMinutes() > 9
                ? `${date.getMinutes()}`
                : '0' + date.getMinutes();
            texts = [first, last];
            if (index === 0 || date.getHours() === 0) {
                return (date.getMonth() + 1 + '/' + date.getDate() + ' ' + texts.join(':'));
            }
            else {
                return texts.join(':');
            }
        }
        case 9:
        case 12: {
            const first = date.getHours() > 9 ? `${date.getHours()}` : '0' + date.getHours();
            const last = date.getMinutes() > 9
                ? `${date.getMinutes()}`
                : '0' + date.getMinutes();
            texts = [first, last];
            return texts.join(':');
        }
    }
    const result = texts.join('/');
    return result;
}
/**
 * 字符串转日期
 *@param date - 日期
 */
function hDString2Date(date) {
    let timestamp = Date.parse(date);
    if (!timestamp) {
        return '';
    }
    return timestamp;
}
export { hDDate2String, hDFormatDateTime2Str, hDFormatDateTime, hDXAxisformat, hDString2Date, };
