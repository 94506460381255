import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { PiSvgAnimateTypeEnum, PiSvgCommandAnimateEnum, } from '../pi-base-graphics-configuration-svg.enum';
import { VueMixinBase } from './vue-mixin.base';
/**
 * dom事件
 */
let SvgDomEventParseMixin = class SvgDomEventParseMixin extends VueMixinBase {
    customDataModal = {
        isShow: false,
        dataUrl: '',
        deviceId: '',
        pointId: '',
    };
    /**
     * 点击事件监听 - svg元素
     */
    onSvgElementClick(e) {
        // 强制隐藏 toolTip
        this.pointTooltipOptions.isShow = false;
        const el = this.getEventTarget(e);
        if (!el) {
            return;
        }
        const eleId = this.getDomAttrId(el);
        if (!eleId) {
            return;
        }
        this.parseDomClickId(eleId);
    }
    /**
     * 获取点击元素对应 Id
     *
     * @param el
     */
    getDomAttrId(el) {
        /**
         * 递归向父节点查找元素ID
         *
         * @param el
         */
        const recursionFindId = (el) => {
            if (!el || el === el.getRootNode()) {
                return;
            }
            const eleId = el.getAttribute('id');
            if (!eleId) {
                return recursionFindId(el.parentElement);
            }
            return eleId;
        };
        const result = recursionFindId(el);
        return result;
    }
    /**
     * 获取点击事件
     *
     * @param data
     */
    getClickableDynData(data) {
        if (!data || !data.length) {
            return;
        }
        const res = data.filter((item) => {
            const { DynType } = item;
            if (DynType === PiSvgAnimateTypeEnum.Control ||
                DynType === PiSvgAnimateTypeEnum.Link ||
                DynType === PiSvgAnimateTypeEnum.Command ||
                DynType === PiSvgAnimateTypeEnum.Video) {
                return true;
            }
        });
        return res;
    }
    /**
     * 解析点击的 Dom ID
     */
    parseDomClickId(id) {
        if (!id) {
            return;
        }
        const pointAnimationList = this.getPointAnimationList(id);
        if (!pointAnimationList || !pointAnimationList.length) {
            console.warn(`点ID未绑定动画！`);
            return;
        }
        // 存在多个可点击动画
        const processAnimationList = this.getClickableDynData(pointAnimationList);
        if (processAnimationList && processAnimationList.length) {
            processAnimationList.forEach((processAnimation) => {
                // 动画类别
                const { DynType } = processAnimation;
                switch (DynType) {
                    case PiSvgAnimateTypeEnum.Control:
                        // TODO: 控制操作
                        break;
                    case PiSvgAnimateTypeEnum.Link:
                        // TODO: 画面链接
                        break;
                    case PiSvgAnimateTypeEnum.Command:
                        // TODO: 命令链接
                        this.onShowCommandAnimation(id, processAnimation);
                        break;
                    case PiSvgAnimateTypeEnum.Video:
                        this.onShowVideoModal(id, processAnimation);
                        break;
                    default:
                        break;
                }
            });
        }
    }
    //#region  命令动画
    /**
     * 显示命令动画相关
     */
    onShowCommandAnimation(id, params) {
        if (!id) {
            return;
        }
        const { Param1, Param2, Param3, Param4, Param5 } = params;
        // 自定义数据表格
        if (Param1 === PiSvgCommandAnimateEnum.TableModal) {
            if (!Param2) {
                this.$Message.warning(`设备点命令动画配置异常，未定义Param2表格URL数据`);
                return;
            }
            if (!Param3) {
                this.$Message.warning(`设备点命令动画配置异常，未定义Param3-设备ID数据`);
                return;
            }
            const { customDataModal } = this;
            customDataModal.dataUrl = Param2 || '';
            customDataModal.deviceId = Param3;
            customDataModal.pointId = id;
            customDataModal.isShow = true;
        }
        else {
            this.$emit('svg-command-animation', params, id);
        }
    }
    //#endregion
    /**
     * 显示视频
     */
    onShowVideoModal(id, param) {
        const { videoModal, svgUrlData } = this;
        videoModal.id = id;
        videoModal.pointAnimateData = param;
        videoModal.videoControlUrl = svgUrlData.videoControlUrl || '';
        videoModal.videoInfoUrl = svgUrlData.videoInfoUrl || '';
        videoModal.isShow = true;
    }
};
SvgDomEventParseMixin = __decorate([
    Component
], SvgDomEventParseMixin);
export default SvgDomEventParseMixin;
