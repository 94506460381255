import { addListener } from './listener';
/**
 * 过滤掉非英文字母,数字,中文,绑定
 * @param el
 */
export const specialFilterAdd = (el, tagName) => {
    const reg = /[^a-zA-Z\d\u4E00-\u9FA5]/g;
    const vnode = el.__vue__;
    const callback = () => {
        if (tagName === 'Input') {
            const value = vnode.value.replace(reg, '');
            vnode.$emit('input', value);
        }
        else {
            el.value = el.value.replace(reg, '');
        }
    };
    if (tagName === 'Input') {
        vnode.$on('on-keyup', callback);
        vnode.$on('on-blur', callback);
    }
    else {
        addListener(el, 'keyup', callback);
        addListener(el, 'blur', callback);
        el.__input_filter__ = {
            keyup: callback,
            blur: callback,
        };
    }
};
