var PiBaseCustomDataModal_1;
import { __decorate } from "tslib";
import { apiAjaxHandle, } from 'bc$/bases/api.base';
import { BcVersion, ConvertUrl, hAjaxGet, hODeepClone, PiBaseEasyTable as VTable, PiBaseModal, UrlType, VueBase, } from 'bc$/main';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PiBaseModalMixin } from '../../mixins/pi-base-modal.mixin';
import { PagerSizeOptsEnum } from '../../pi-base-graphics-configuration-svg.enum';
import template from './pi-base-custom-data-modal.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-custom-data-modal.component.scss';
let PiBaseCustomDataModal = PiBaseCustomDataModal_1 = class PiBaseCustomDataModal extends Mixins(VueBase, PiBaseModalMixin) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseCustomDataModal';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseCustomDataModal_1?.NAME,
        author: 'luot',
        title: '自定义数据弹窗',
        version: BcVersion,
        updateDate: '2020-09-24',
        description: '',
    };
    static DEFAULT_OPTIONS_SEARCH = {
        isPaged: true,
        field: undefined,
        pageNo: 1,
        pageSize: PagerSizeOptsEnum.Size20,
        searchKey: undefined,
        type: undefined,
    };
    //#endregion
    //#region props
    /**
     * 数据请求url
     */
    dataUrl;
    /**
     * 设备ID
     */
    deviceId;
    /**
     * 点ID
     */
    pointId;
    //#endregion
    //#region data
    /**
     * 弹窗标题
     */
    modalTitle = '设备信息';
    /**
     *表格
     */
    table = {
        total: 0,
        /**
         * 表格加载状态
         */
        isLoading: false,
        /**
         * 表格数据
         */
        rows: [],
        /**
         * 表格列
         */
        columns: [],
        /**
         *
         */
        search: hODeepClone(PiBaseCustomDataModal_1.DEFAULT_OPTIONS_SEARCH),
    };
    /**
     * 搜索条件
     */
    search = hODeepClone(PiBaseCustomDataModal_1.DEFAULT_OPTIONS_SEARCH);
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseCustomDataModal_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     * 解析树数据
     */
    renderTableData(data) {
        const { table } = this;
        let { columns, rows } = this.table;
        // 分页信息
        // table.total = data.totalCount;
        // this.pageNoHandler(data.totalPage);
        // const tableSearch = table.search;
        // tableSearch.pageNo = data.pageIndex;
        // tableSearch.pageSize = data.pageSize;
        // 渲染列信息
        const respColumns = data.titleList;
        if (!respColumns || !respColumns.length) {
            columns.splice(0);
            rows.splice(0);
            return;
        }
        table.columns = respColumns.map((item) => {
            const columnData = {
                title: '',
                field: '',
                titleAlign: 'center',
                columnAlign: 'center',
                width: 100,
                isResize: true,
                overflowTitle: true,
            };
            columnData.title = item.title;
            columnData.field = item.field;
            return columnData;
        });
        // 表格数据
        const rowData = data.dataList || [];
        table.rows.splice(0, table.rows.length, ...rowData);
    }
    /**
     * 获取表格数据
     */
    getPage(pageNo) {
        if (pageNo) {
            this.search.pageNo = pageNo;
        }
        //统一管理ajax请求
        this.resetAjax('getPage', () => {
            const { table } = this;
            table.isLoading = true;
            const params = [this.deviceId];
            return this.apiGetCustomData({ deviceList: params }, {
                cbThen: (dData, rData) => {
                    const { table } = this;
                    table.rows.splice(0);
                    table.columns.splice(0);
                    if (dData && dData.length) {
                        this.renderTableData(dData[0]);
                    }
                },
                cbFinally: () => {
                    table.isLoading = false;
                },
            });
        });
    }
    /**
     * 请求设备弹窗信息
     *
     * @param params
     * @param options
     */
    apiGetCustomData(params, options) {
        const { deviceList } = params || {};
        // 请求地址
        let url_ = `${ConvertUrl(this.dataUrl, UrlType.Api)}?`;
        if (deviceList !== undefined) {
            deviceList &&
                deviceList.forEach((item) => {
                    url_ += 'deviceList=' + encodeURIComponent('' + item) + '&';
                });
        }
        url_ = url_.replace(/[?&]$/g, '');
        const { config = {} } = options || {};
        const ajaxInfo = hAjaxGet(url_, config);
        return apiAjaxHandle(false, ajaxInfo, '获取设备弹窗测点信息', options);
    }
    /**
     * 页码发生变化
     * 重新请求数据
     *
     * @param pageNo
     */
    pageNoHandler(newTotalPageNo) {
        const { pageNo } = this.table.search;
        if (newTotalPageNo && newTotalPageNo > 0 && pageNo > newTotalPageNo) {
            // 后端返回的页码小于现在页码数，则重新请求数据
            this.table.search.pageNo = newTotalPageNo;
            this.getPage();
        }
    }
    /**
     * 切换页
     * @param pageIndex
     */
    onPageChange(pageIndex) {
        const { search } = this;
        const { search: searchNew } = this.table;
        search.pageNo = searchNew.pageNo = pageIndex;
        this.getPage();
    }
    /**
     * 切换分页大小
     * @param pageSize
     */
    onPageSizeChange(pageSize) {
        const { search } = this;
        const { search: searchNew } = this.table;
        search.pageSize = searchNew.pageSize = pageSize;
        search.pageNo = searchNew.pageNo = 1;
        this.getPage();
    }
    /**
     * 列排序
     */
    onSortChange(params, sortArr) {
        const { search } = this;
        const { search: searchNew } = this.table;
        const sortColumn = sortArr && sortArr[0];
        search.field = sortColumn.field;
        search.type = sortColumn.dir;
        search.pageNo = searchNew.pageNo = 1;
        //重新获取数据
        this.getPage();
    }
    //#endregion
    //#region hooks
    created() {
        this.getPage();
    }
};
__decorate([
    Prop({ type: String, required: true })
], PiBaseCustomDataModal.prototype, "dataUrl", void 0);
__decorate([
    Prop({ type: String, required: true })
], PiBaseCustomDataModal.prototype, "deviceId", void 0);
__decorate([
    Prop({ type: String, required: true })
], PiBaseCustomDataModal.prototype, "pointId", void 0);
PiBaseCustomDataModal = PiBaseCustomDataModal_1 = __decorate([
    Component({
        template,
        components: {
            PiBaseModal,
            VTable,
        },
    })
], PiBaseCustomDataModal);
export default PiBaseCustomDataModal;
