import { __decorate } from "tslib";
import { ColumnEntity, RowEntity, } from 'bc$/entities/layout';
import { LayoutMode, RowsColumnsSelectedType, } from 'bc$/enums/layout/layout.enum';
import { Store } from 'bc$/store/store';
import { getModule, Module, Mutation, VuexModule, } from 'vuex-module-decorators';
import { PiBaseStorePageModule } from './pi-base-page.store';
let PiBaseStoreLayout = class PiBaseStoreLayout extends VuexModule {
    /**
     * 所有打开的页面,可以重复
     */
    tabs = [];
    /**
     * 当前布局模式
     */
    layoutMode = LayoutMode.noDesign;
    //#region mutation
    /**
     * 添加一个tab页面时添加相关页面的默认信息
     * @param tabId 当前tab唯一id
     */
    addTab(state) {
        const tab = {
            tabId: state.tabId,
            Rows: [],
            Columns: [],
            RowsOrColumnsSelected: [],
            RowDeleteClicked: false,
            RowSplitClicked: false,
            RowSpanClicked: false,
            ColumnSpanClicked: false,
            ColumnSplitClicked: false,
            WidgetInstances: [],
        };
        this.tabs.push(tab);
    }
    /**
     * 清空 Store 数据
     * @param tabId 当前tab唯一id
     */
    emptyStore(state) {
        if (!state || !state.tabId) {
            this.tabs.splice(0);
            this.layoutMode = LayoutMode.noDesign;
            return;
        }
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        tab.Rows.splice(0);
        tab.Columns.splice(0);
        tab.RowsOrColumnsSelected.splice(0);
        tab.RowDeleteClicked = false;
        tab.RowSplitClicked = false;
        tab.RowSpanClicked = false;
        tab.ColumnSpanClicked = false;
        tab.ColumnSplitClicked = false;
        tab.WidgetInstances.splice(0);
    }
    /**
     * 移除一个tab
     * @param tabId 当前tab唯一id
     */
    removeTab(state) {
        const tabIndex = this.tabs.findIndex((p) => p.tabId === state.tabId);
        ~tabIndex && this.tabs.splice(tabIndex, 1);
    }
    /**
     *
     * @param tabId 当前tab唯一id
     * @param isClicked
     */
    setRowDeleteClicked(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        tab.RowDeleteClicked = state.isClicked;
    }
    /**
     *
     * @param tabId 当前tab唯一id
     * @param isClicked
     */
    setRowSplitClicked(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        tab.RowSplitClicked = state.isClicked;
    }
    /**
     *
     * @param tabId 当前tab唯一id
     * @param isClicked
     */
    setRowSpanClicked(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        tab.RowSpanClicked = state.isClicked;
    }
    /**
     *
     * @param tabId 当前tab唯一id
     * @param isClicked
     */
    setColumnSpanClicked(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        tab.ColumnSpanClicked = state.isClicked;
    }
    /**
     *
     * @param tabId 当前tab唯一id
     * @param isClicked
     */
    setColumnSplitClicked(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        tab.ColumnSplitClicked = state.isClicked;
    }
    /**
     * 修改当前布局模式
     * @param mode
     */
    setLayoutMode(state) {
        this.layoutMode = state.mode;
        const tab = this.tabs.find((p) => p.tabId === PiBaseStorePageModule.curTab.tabId);
        if (!tab) {
            return;
        }
        // 退出设计模式需重置选中状态
        if (state.mode !== LayoutMode.noDesign) {
            return;
        }
        tab.Rows.forEach((item) => {
            item.piSelected = false;
        });
        tab.Columns.forEach((item) => {
            item.piSelected = false;
        });
    }
    /**
     * 排序
     * @param tab
     */
    sortRows(state) {
        if (!state.tab) {
            return;
        }
        state.tab.Rows.sort((a, b) => {
            // 不存在 piSelected 时。初始化选中状态为 false
            if (!a.hasOwnProperty('piSelected')) {
                a['piSelected'] = false;
            }
            if (!b.hasOwnProperty('piSelected')) {
                b['piSelected'] = false;
            }
            return a.orderNo - b.orderNo;
        });
    }
    /**
     * 添加新行
     * @param tabId 当前tab唯一id
     * @param row
     * @param isClear
     */
    addRows(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        if (!state.rows || !state.rows.length) {
            return;
        }
        if (state.isClear) {
            tab.Rows.splice(0);
        }
        // 插入新行
        tab.Rows.push(...state.rows);
        // 更新排序
        store.sortRows({ tab });
    }
    /**
     * 添加新行
     * @param tabId 当前tab唯一id
     * @param row
     * @param isClear
     */
    addColumns(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        if (!state.columns || !state.columns.length) {
            return;
        }
        if (state.isClear) {
            tab.Columns.splice(0);
        }
        // 插入新行
        tab.Columns.push(...state.columns);
        // 更新排序
        store.sortColumns({ tab });
    }
    /**
     * 修改指定的多行的扩展信息
     * @param tabId 当前tab唯一id
     * @param columns 需要更新的行
     */
    updateRowsAttr(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        const { rows } = state;
        if (!rows) {
            return;
        }
        const allRows = tab.Rows;
        for (const item of rows) {
            const tmpPkid = item.pkid;
            if (!tmpPkid) {
                continue;
            }
            const row = allRows.find((p) => p.pkid === tmpPkid);
            if (!row) {
                continue;
            }
            row.attrJson = item.attrJson;
        }
    }
    /**
     * 根据新的 rows 更新所有行数据
     * @param tabId 当前tab唯一id
     * @param rows 需要更新的行数据
     */
    updateRows(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        if (!state.rows || !state.rows.length) {
            return;
        }
        const allRows = tab.Rows.slice(0);
        // 根据选中行
        state.rows.forEach((item) => {
            const tmpPkid = item.pkid;
            if (tmpPkid) {
                const tmpIndex = allRows.findIndex((item) => item.pkid === tmpPkid);
                if (tmpIndex !== -1) {
                    const tmpNewRow = Object.assign({}, allRows[tmpIndex], item);
                    const tmpNewRowEntity = new RowEntity(tmpNewRow);
                    // 处理 new RowEntity 后 piSelected 字丢失
                    tmpNewRowEntity.piSelected = tmpNewRow.piSelected;
                    allRows.splice(tmpIndex, 1, tmpNewRowEntity);
                }
                else {
                    const rowAdd = new RowEntity(item);
                    rowAdd.piSelected = false;
                    allRows.push(rowAdd);
                }
            }
        });
        tab.Rows.splice(0);
        tab.Rows.push(...allRows);
        store.sortRows({ tab });
    }
    /**
     * 根据 row pkIds 删除栅格行
     * @param tabId 当前tab唯一id
     * @param rowPkIds 需要删除的行数据
     */
    deleteRows(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        if (!state.rowPkIds || !state.rowPkIds.length) {
            return;
        }
        let newRows = [];
        tab.Rows.forEach((row) => {
            if (state.rowPkIds.indexOf(row.pkid) === -1) {
                newRows.push(row);
            }
        });
        tab.Rows.splice(0, tab.Rows.length, ...newRows);
    }
    /**
     * 根据 column pkIds 删除栅格列
     * @param tabId 当前tab唯一id
     * @param rowPkIds 需要删除的列数据
     */
    deleteColumns(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        if (!state.columnPkIds || !state.columnPkIds.length) {
            return;
        }
        let newColumns = [];
        tab.Columns.forEach((column) => {
            if (state.columnPkIds.indexOf(column.pkid) === -1) {
                newColumns.push(column);
            }
        });
        tab.Columns.splice(0, tab.Columns.length, ...newColumns);
    }
    /**
     * 修改指定的多列的扩展信息
     * @param tabId 当前tab唯一id
     * @param columns 需要更新的列
     */
    updateColumnsAttr(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        const { columns } = state;
        if (!columns) {
            return;
        }
        const allColumns = tab.Columns;
        for (const item of columns) {
            const tmpPkid = item.pkid;
            if (!tmpPkid) {
                continue;
            }
            const col = allColumns.find((p) => p.pkid === tmpPkid);
            if (!col) {
                continue;
            }
            col.attrJson = item.attrJson;
        }
    }
    /**
     * 根据新的 columns 更新所有行数据
     * @param tabId 当前tab唯一id
     * @param columns 需要更新的行数据
     */
    updateColumns(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        if (!state.columns || !state.columns.length) {
            return;
        }
        const allColumns = tab.Columns.slice(0);
        // 根据选中行
        state.columns.forEach((item) => {
            const tmpPkid = item.pkid;
            if (tmpPkid) {
                const tmpIndex = allColumns.findIndex((item) => item.pkid === tmpPkid);
                if (tmpIndex !== -1) {
                    const tmpNewColumn = Object.assign({}, allColumns[tmpIndex], item);
                    const tmpNewColumnEntity = new ColumnEntity(tmpNewColumn);
                    // 处理 new ColumnEntity 后 piSelected 字丢失
                    tmpNewColumnEntity.piSelected = tmpNewColumn.piSelected;
                    allColumns.splice(tmpIndex, 1, tmpNewColumnEntity);
                }
                else {
                    const columnAdd = new ColumnEntity(item);
                    columnAdd.piSelected = false;
                    allColumns.push(columnAdd);
                }
            }
        });
        tab.Columns.splice(0);
        tab.Columns.push(...allColumns);
        store.sortColumns({ tab });
    }
    /**
     * 列排序
     * @param columns
     */
    sortColumns(state) {
        if (!state.tab) {
            return;
        }
        state.tab.Columns.sort((a, b) => {
            if (!a.hasOwnProperty('piSelected')) {
                a.piSelected = false;
            }
            if (!b.hasOwnProperty('piSelected')) {
                b.piSelected = false;
            }
            return a.orderNo - b.orderNo;
        });
    }
    /**
     *
     * @param tabId 当前tab唯一id
     * @param WidgetInstances
     */
    setWidgetInstances(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        tab.WidgetInstances = state.WidgetInstances;
    }
    /**
     * 取消行列选中状态
     * @param tabId 当前tab唯一id
     */
    cancelSelectedRowsColumns(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        const _cancelSelectedData = (rowsOrColumns) => {
            rowsOrColumns.forEach((item) => {
                item.piSelected = false;
            });
        };
        // 将自动取消dom上选中效果
        if (!tab.RowsOrColumnsSelected || !tab.RowsOrColumnsSelected.length) {
            return;
        }
        _cancelSelectedData(tab.Rows);
        _cancelSelectedData(tab.Columns);
        // 更新 Vuex 选中行列信息
        tab.RowsOrColumnsSelected.splice(0);
    }
    /**
     * 更新选中行列集合
     * @param tabId 当前tab唯一id
     */
    updateSelectedRowsOrColumns(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        const _getSelectedData = function (rowsOrColumns) {
            if (!rowsOrColumns) {
                return [];
            }
            return rowsOrColumns.filter((item) => item.piSelected);
        };
        const rowsSelected = _getSelectedData(tab.Rows);
        const columnsSelected = _getSelectedData(tab.Columns);
        tab.RowsOrColumnsSelected = [...rowsSelected, ...columnsSelected].sort((a, b) => a.orderNo - b.orderNo);
    }
    /**
     * 根据 pkIds 删除对应行列数据
     * @param tabId 当前tab唯一id
     * @param param
     */
    delRowsColumns(state) {
        const tab = this.tabs.find((p) => p.tabId === state.tabId);
        if (!tab) {
            return;
        }
        const { param } = state;
        if (!param || !param.pkIds || !param.pkIds.length) {
            return;
        }
        const rowsColumns = param.dataType === RowsColumnsSelectedType.allRows
            ? tab.Rows
            : tab.Columns;
        param.pkIds.forEach((pkid) => {
            const delIndex = rowsColumns.findIndex((item) => item.pkid === pkid);
            rowsColumns.splice(delIndex, 1);
        });
    }
};
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "addTab", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "emptyStore", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "removeTab", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "setRowDeleteClicked", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "setRowSplitClicked", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "setRowSpanClicked", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "setColumnSpanClicked", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "setColumnSplitClicked", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "setLayoutMode", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "sortRows", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "addRows", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "addColumns", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "updateRowsAttr", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "updateRows", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "deleteRows", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "deleteColumns", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "updateColumnsAttr", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "updateColumns", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "sortColumns", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "setWidgetInstances", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "cancelSelectedRowsColumns", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "updateSelectedRowsOrColumns", null);
__decorate([
    Mutation
], PiBaseStoreLayout.prototype, "delRowsColumns", null);
PiBaseStoreLayout = __decorate([
    Module({ namespaced: true, dynamic: true, store: Store, name: 'piBaseLayout' })
], PiBaseStoreLayout);
export { PiBaseStoreLayout };
const store = getModule(PiBaseStoreLayout);
export { store as PiBaseStoreLayoutModule };
