import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import settings from '../settings/settings';
let ClassesMixin = class ClassesMixin extends Vue {
    get vTableRightBody() {
        let result = {
            'v-table-rightview-special-border': true,
            [settings.scrollbarClass]: true,
        };
        return result;
    }
    get vTableFooter() {
        let result = {
            'v-table-rightview-special-border': true,
            [settings.scrollbarClass]: true,
        };
        return result;
    }
    get vTableBodyInner() {
        const that = this;
        return {
            'v-table-body-inner-pb': !that.hasTableFooter,
        };
    }
    get vTableBodyCell() {
        const that = this;
        return {
            'vertical-border': that.showVerticalBorder,
            'horizontal-border': that.showHorizontalBorder,
        };
    }
    vTableFiltersIcon(filters) {
        const that = this;
        return {
            'v-icon-filter': true,
            checked: filters.some((x) => x.selected && x.value !== that.filterSpecialValue),
        };
    }
};
ClassesMixin = __decorate([
    Component
], ClassesMixin);
export default ClassesMixin;
