import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import utils from '../utils/utils';
const __autoAdjustment__events__ = [];
let LayerAdjustmentMixin = class LayerAdjustmentMixin extends Vue {
    /*
     * 自动调整浮层（不绑定事件）
     *
     * @method layerAdjustmentBind
     * @param  {Dom}        layerElement        浮层元素
     * @param  {Dom}        targetElement       控制元素
     * @param  {Number}     distance            浮层元素和控制元素的上下间距
     */
    layerAdjustmentOnce(layerElement, targetElement, distance) {
        const viewportOffset = utils.getViewportOffset(targetElement), layerElemHeight = typeof layerElement.getBoundingClientRect !== 'undefined'
            ? layerElement.getBoundingClientRect().height
            : layerElement.clientHeight;
        if (viewportOffset.bottom < layerElemHeight) {
            layerElement.style.top =
                viewportOffset.top - layerElemHeight - distance + 'px';
        }
        else {
            layerElement.style.top =
                viewportOffset.top + targetElement.clientHeight + distance + 'px';
        }
        layerElement.style.left = viewportOffset.left + 'px';
    }
    /*
     * 滚动时自动调整浮层
     *
     * @method layerAdjustmentBind
     * @param  {Dom}        layerElement        浮层元素
     * @param  {Dom}        targetElement       控制元素
     * @param  {Number}     distance            浮层元素和控制元素的上下间距
     */
    layerAdjustmentBind(layerElement, targetElement, distance) {
        const handler = () => {
            setTimeout(() => {
                this.layerAdjustmentOnce(layerElement, targetElement, distance);
            });
        };
        __autoAdjustment__events__.push(handler);
        utils.bind(window, 'scroll', handler);
        utils.bind(window, 'resize', handler);
    }
    beforeDestroy() {
        utils.unbind(window, 'scroll', __autoAdjustment__events__);
        utils.unbind(window, 'resize', __autoAdjustment__events__);
    }
};
LayerAdjustmentMixin = __decorate([
    Component
], LayerAdjustmentMixin);
export default LayerAdjustmentMixin;
