import settings from '../settings/settings';
import { GetRootNode } from './dom';
/**
 * 在 shadow dom 内部可穿透的事件
 */
export const ComposedUIEvents = () => [
    'blur',
    'focus',
    'focusin',
    'focusout',
    'click',
    'dblclick',
    'mousedown',
    'mouseup',
    'mousemove',
    'mouseout',
    'mouseover',
    'wheel',
    'beforeinput',
    'input',
    'keydown',
    'onkeyup',
];
export default {
    /**
     * 判断当前UI事件是否可穿透 shadow dom
     *
     * @param eventName
     */
    isComposedUIEvent(eventName) {
        return ComposedUIEvents().indexOf(eventName) !== -1;
    },
    /*获取当前元素的left、top偏移
     *   left：元素最左侧距离文档左侧的距离
     *   top:元素最顶端距离文档顶端的距离
     *   right:元素最右侧距离文档右侧的距离
     *   bottom：元素最底端距离文档底端的距离
     *   right2：元素最左侧距离文档右侧的距离
     *   bottom2：元素最底端距离文档最底部的距离
     * */
    getViewportOffset(element) {
        const doc = document.documentElement, box = typeof element.getBoundingClientRect !== 'undefined'
            ? element.getBoundingClientRect()
            : 0, scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0), scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0), offsetLeft = (box ? box.left : 0) + window.pageXOffset, offsetTop = (box ? box.top : 0) + window.pageYOffset;
        const left = offsetLeft - scrollLeft, top = offsetTop - scrollTop;
        return {
            left: left,
            top: top,
            right: window.document.documentElement.clientWidth -
                (box ? box.width : 0) -
                left,
            bottom: window.document.documentElement.clientHeight -
                (box ? box.height : 0) -
                top,
            right2: window.document.documentElement.clientWidth - left,
            bottom2: window.document.documentElement.clientHeight - top,
        };
    },
    /*
     * 事件绑定
     *
     * @method bind
     * @param  {dom||window}   elem        需要绑定的dom节点或window对象
     * @param  {String}        event       绑定的事件名称
     * @param  {Function}      handler     事件处理方法
     */
    bind(elem, event, handler) {
        if (elem && elem !== undefined && event && handler) {
            let rootNode = null;
            // 非可穿透的事件将挂载到其他地方
            if (this.isComposedUIEvent(event)) {
                rootNode = document;
            }
            else {
                rootNode = GetRootNode(elem);
            }
            if (!rootNode) {
                return;
            }
            event =
                event === 'mousewheel'
                    ? rootNode.onmousewheel !== undefined
                        ? 'mousewheel'
                        : 'DOMMouseScroll'
                    : event;
            if (rootNode.attachEvent) {
                //if IE (and Opera depending on user setting)
                elem.attachEvent('on' + event, handler);
            }
            else {
                //WC3 browsers
                elem.addEventListener(event, handler, false);
            }
        }
    },
    /*
     * 移除事件绑定
     *
     * @method unbind
     * @param  {dom||window}   elem         需要移除绑定的dom节点或window对象
     * @param  {String}        event        绑定的事件名称
     * @param  {Function||Array<Function>}  handler    事件处理方法，可以为数组
     */
    unbind(elem, event, handler) {
        if (elem && elem !== undefined && event && handler) {
            const rootNode = GetRootNode(elem);
            if (!rootNode) {
                return;
            }
            event =
                event === 'mousewheel'
                    ? rootNode.onmousewheel !== undefined
                        ? 'mousewheel'
                        : 'DOMMouseScroll'
                    : event;
            let handlers = [];
            if (Array.isArray(handler) && handler.length > 0) {
                handlers = handler;
            }
            else {
                handlers.push(handler);
            }
            if (typeof rootNode.removeEventListener === 'function') {
                handlers.forEach((e) => {
                    elem.removeEventListener(event, e, false);
                });
            }
            else {
                handlers.forEach((e) => {
                    elem.removeEventListener('on' + event, e);
                });
            }
        }
    },
    // 判断当前是否包含html元素
    isHtml(val) {
        return /<[a-z][\s\S]*>/i.test(val);
    },
    // 获取当前dislpay值
    getDisplayValue(ele) {
        if (ele) {
            return ele.currentStyle
                ? ele.currentStyle.display
                : getComputedStyle(ele, null).display;
        }
    },
    // 是否包含横向滚动条
    hasHorizontalScrollBar(ele) {
        if (ele) {
            return ele.scrollWidth > ele.clientWidth;
        }
    },
    // 是否包含纵向滚动条
    hasVerticalScrollBar(ele) {
        if (ele) {
            return ele.scrollHeight > ele.clientHeight;
        }
    },
    // 获取滚动条的宽度
    getScrollbarWidth(piParentNode) {
        const outer = document.createElement('div');
        outer.className = settings.scrollbarClass;
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        outer.style.position = 'absolute';
        outer.style.top = '-9999px';
        const rootNode = GetRootNode(piParentNode);
        if (!rootNode) {
            return;
        }
        piParentNode =
            piParentNode || (rootNode && rootNode.body) || rootNode;
        piParentNode.appendChild(outer);
        const widthNoScroll = outer.offsetWidth;
        outer.style.overflow = 'scroll';
        const inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);
        const widthWithScroll = inner.offsetWidth;
        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    },
    // 获取父组件信息
    getParentCompByName(context, name) {
        let parent = context.$parent;
        while (parent) {
            if (parent.$options.name !== name) {
                parent = parent.$parent;
            }
            else {
                return parent;
            }
        }
        return null;
    },
    // 获取多个符合条件的子组件信息
    getChildCompsByName(context, name) {
        let result = [];
        let childrens = context.$children;
        while (childrens && childrens.length > 0) {
            childrens.forEach((child) => {
                childrens = child.$children ? child.$children : null;
                if (child.$options.name === name) {
                    result.push(child);
                }
            });
        }
        return result;
    },
};
