import { __decorate } from "tslib";
import { hConvertAttr } from 'bc$/main';
import { PiUndefined } from 'bc$/decorators/pi-undefined.decorator';
/**
 * 实体-栅格列
 */
export class ColumnEntity {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 属性信息，Json格式
     */
    attrJson;
    /**
     * 属性信息对象
     */
    attrObj;
    /**
     * 排序
     */
    orderNo;
    /**
     * 主键
     */
    pkid;
    /**
     * 栅格行主键 顶级列该字段为0
     */
    rowPkId;
    /**
     * 是否选中，组件内使用该属性
     */
    piSelected;
}
__decorate([
    PiUndefined()
], ColumnEntity.prototype, "attrJson", void 0);
__decorate([
    PiUndefined()
], ColumnEntity.prototype, "orderNo", void 0);
__decorate([
    PiUndefined()
], ColumnEntity.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], ColumnEntity.prototype, "rowPkId", void 0);
__decorate([
    PiUndefined()
], ColumnEntity.prototype, "piSelected", void 0);
