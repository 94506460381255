var PiBaseSelectLngLat_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/main';
import { AmapStyleId, loadAMap } from 'bc$/utils/amap';
import { Button, Input, Modal } from 'iview';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-select-lng-lat.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-select-lng-lat.component.scss';
let PiBaseSelectLngLat = PiBaseSelectLngLat_1 = class PiBaseSelectLngLat extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiSelectLngLat';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseSelectLngLat_1?.NAME,
        author: 'hex',
        title: '经纬度选择组件',
        version: BcVersion,
        updateDate: '2019-07-02',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 显示弹窗
     */
    value;
    /**
     * 经度
     */
    lng;
    /**
     * 纬度
     */
    lat;
    /**
     * 是否进行地点模糊搜索
     */
    isShowTipInput;
    //#endregion
    //#region data
    showModal = false;
    /**
     * 坐标
     */
    position = {
        lng: '',
        lat: '',
    };
    /**
     * 地图实例
     */
    mapInstance = null;
    /**
     * 标记实例
     */
    markerInstance = null;
    /**
     * 地图是否加载完
     */
    isLoadedMap = false;
    /**
     * 地点搜索关键词
     */
    tipSearchKey = '';
    /**
     * 模糊搜索下拉框
     */
    placeSearch = null;
    /**
     * 补全关键词
     */
    autocomplete = null;
    //#endregion
    //#region computed
    get about() {
        return PiBaseSelectLngLat_1.ABOUT;
    }
    get titleAll() {
        return PiBaseSelectLngLat_1.ABOUT.title;
    }
    //#endregion
    //#region watch
    'watch.lng'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.position.lng = newVal;
    }
    'watch.lat'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.position.lat = newVal;
    }
    /**
     * 监视父组件value
     * @param newVal
     */
    'watch.value'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.showModal = newVal;
    }
    //#endregion
    //#region methods
    /**
     * 初始化地图
     */
    initMap() {
        const mapOptions = {
            zoom: 10,
            mapStyle: `amap://styles/${AmapStyleId}`,
        };
        this.mapInstance = new AMap.Map('pi-widget-pi-select-lng-lat-map', mapOptions);
        this.mapInstance.on('complete', () => {
            this.isShowTipInput && this.initTipInput();
        });
        // 标记
        this.initMarker();
        // 点击标记
        this.mapInstance.on('click', (evt) => {
            this.locationMark(evt.lnglat.getLng(), evt.lnglat.getLat());
        });
    }
    /**
     * 初始化标记
     */
    initMarker() {
        if (!this.position.lng ||
            !this.position.lat ||
            isNaN(Number(this.position.lng)) ||
            isNaN(Number(this.position.lat))) {
            return;
        }
        const position = new AMap.LngLat(Number(this.position.lng), Number(this.position.lat));
        const mapInstance = this.mapInstance;
        // 清除标记
        if (this.markerInstance) {
            mapInstance.remove([this.markerInstance]);
        }
        // 标记
        const markerOptions = {
            position,
        };
        this.markerInstance = new AMap.Marker(markerOptions);
        mapInstance.add([this.markerInstance]);
        // 定位
        mapInstance.setCenter(position);
    }
    /**
     * 地点模糊搜索框初始化
     */
    initTipInput() {
        let that = this;
        AMap.plugin(['AMap.Autocomplete'], function () {
            that.autocomplete = new AMap.Autocomplete({
                input: 'tipInput',
            });
        });
        //构造地点查询类
        AMap.plugin(['AMap.PlaceSearch'], function () {
            that.placeSearch = new AMap.PlaceSearch({
                map: that.mapInstance,
            });
        });
        this.searchSelectTipInput();
    }
    /**
     * 地点placer框Select精确搜索
     */
    searchSelectTipInput() {
        let that = this;
        AMap.event.addListener(that.autocomplete, 'select', select); //注册监听，当选中某条记录时会触发
        function select(e) {
            that.placeSearch.setCity(e.poi.adcode);
            that.placeSearch.search(e.poi.name, (status, result) => {
                that.placeSearch.clear(); //清除mark
                if (status === 'complete') {
                    let lngLat = result.poiList.pois[0].location;
                    that.locationMark(lngLat.getLng() + '', lngLat.getLat() + '');
                }
            });
        }
    }
    /**
     * 地点精确搜索
     */
    handleSearchTipKeys() {
        let that = this;
        this.placeSearch.search(this.tipSearchKey, (status, result) => {
            this.placeSearch.clear(); //清除mark
            if (status === 'complete') {
                let lngLat = result.poiList.pois[0].location;
                that.locationMark(lngLat.getLng() + '', lngLat.getLat() + '');
            }
        });
    }
    /**
     * 定位mark
     * @param lng 精度
     * @param lat 纬度
     */
    locationMark(lng, lat) {
        this.position.lng = lng;
        this.position.lat = lat;
        // 标记
        this.initMarker();
    }
    /**
     * 搜索
     */
    search() {
        if (!this.position.lng) {
            this.$Message.warning('请填写经度数值！');
            return;
        }
        if (!this.position.lat) {
            this.$Message.warning('请填写纬度数值！');
            return;
        }
        // 标记
        this.initMarker();
    }
    /**
     * 确定
     */
    ensure() {
        if (!this.position.lng || !this.position.lat) {
            this.$Message.warning('请选择标记点！');
            return;
        }
        // 传递已选择的经纬度
        this.$emit('on-change', this.position);
        // 父组件v-model绑定的属性置为false
        this.$emit('input', false);
    }
    /**
     * modal显示变化
     */
    onVisibleChange(shown) {
        this.$emit('input', shown);
    }
    //#endregion
    //#region hooks
    mounted() {
        // 我的天呐，mounted一瞬间，AMap是获取不到dom的
        setTimeout(() => {
            const piParentNode = this.piParentNodeFn?.() || document.body;
            loadAMap(this.initMap, piParentNode);
            //地图加载 显示搜索框
            this.isLoadedMap = true;
        }, 500);
    }
};
__decorate([
    Prop({
        required: true,
        type: Boolean,
    })
], PiBaseSelectLngLat.prototype, "value", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBaseSelectLngLat.prototype, "lng", void 0);
__decorate([
    Prop({
        type: String,
    })
], PiBaseSelectLngLat.prototype, "lat", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseSelectLngLat.prototype, "isShowTipInput", void 0);
__decorate([
    Watch('lng', { immediate: true })
], PiBaseSelectLngLat.prototype, "watch.lng", null);
__decorate([
    Watch('lat', { immediate: true })
], PiBaseSelectLngLat.prototype, "watch.lat", null);
__decorate([
    Watch('value', { immediate: true })
], PiBaseSelectLngLat.prototype, "watch.value", null);
PiBaseSelectLngLat = PiBaseSelectLngLat_1 = __decorate([
    Component({
        components: {
            Modal,
            Input,
            Button,
        },
        template,
    })
], PiBaseSelectLngLat);
export default PiBaseSelectLngLat;
