import SortableJs from 'sortablejs';
export const isSortableJsInitialized = function (el) {
    return !!getSortableJsInstance(el);
};
export const getSortableJsInstance = function (el) {
    let result = null;
    const elKeys = Object.keys(el);
    const regex = /^Sortable.+/;
    elKeys.some((key) => {
        if (regex.test(key)) {
            result = el[key];
            return true;
        }
        return false;
    });
    return result;
};
/**
 * 插件初始化
 * @param el
 * @param options
 */
const _initPlugin = function (el, options = {}) {
    // 不进行重复初始化
    // if (isSortableJsInitialized(el)) {
    //   console.warn(`Please don't init sortableJs again !`);
    //   return;
    // }
    if (options.sort === false) {
        return;
    }
    if (!isSortableJsInitialized(el)) {
        new SortableJs(el, options);
    }
};
export const Sortable = {
    bind: function (el, binding, vNode) {
        const vm = vNode.context;
        vm.$on('hook:mounted', function () {
            vm.$nextTick(function () {
                _initPlugin(el, binding.value);
            });
        });
    },
    update: function (el, binding) {
        if (isSortableJsInitialized(el)) {
            // 销毁插件，重新初始化应用更新
            const pluginInstance = getSortableJsInstance(el);
            pluginInstance && pluginInstance.destroy();
        }
        _initPlugin(el, binding.value);
    },
    unbind(el) {
        const sortableInstance = getSortableJsInstance(el);
        sortableInstance && sortableInstance.destroy && sortableInstance.destroy();
    },
};
