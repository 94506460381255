export var DirectionEnum;
(function (DirectionEnum) {
    /**
     * 左上
     */
    DirectionEnum["LeftUp"] = "left-up";
    /**
     * 中上
     */
    DirectionEnum["Up"] = "up";
    /**
     * 右上
     */
    DirectionEnum["RightUp"] = "right-up";
    /**
     * 左中
     */
    DirectionEnum["Left"] = "left";
    /**
     * 中中
     */
    DirectionEnum["Center"] = "center";
    /**
     * 右中
     */
    DirectionEnum["Right"] = "right";
    /**
     * 左下
     */
    DirectionEnum["LeftDown"] = "left-down";
    /**
     * 中下
     */
    DirectionEnum["Down"] = "down";
    /**
     * 右下
     */
    DirectionEnum["RightDown"] = "right-down";
})(DirectionEnum || (DirectionEnum = {}));
export var FeatureEnum;
(function (FeatureEnum) {
    /**
     * 镜头拉远
     */
    FeatureEnum["ZoomOut"] = "zoom-out";
    /**
     * 镜头拉近
     */
    FeatureEnum["ZoomIn"] = "zoom-in";
    /**
     * 聚焦点变远
     */
    FeatureEnum["FocusFar"] = "focus-far";
    /**
     * 聚集点变近
     */
    FeatureEnum["FocusNear"] = "focus-near";
    /**
     * 光圈减少
     */
    FeatureEnum["IrisReduce"] = "iris-reduce";
    /**
     * 光圈增大
     */
    FeatureEnum["IrisIncrease"] = "iris-increase";
    //AUTO_SCAN 自动线扫
    //WIPER_SWITCH 接通雨刷开关
    //START_TRACK 开始轨迹
    //STOP_TRACK 停止轨迹
})(FeatureEnum || (FeatureEnum = {}));
