import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
let VueWatchMixin = class VueWatchMixin extends VueMixinBase {
    /**
     * 观察svg图url
     *
     * @param newVal
     * @param oldVal
     */
    'watch.reqSvgInfoUrl'(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
            this.removeTimeoutAndAJax();
            // 初始化svg
            this.initSvg();
        }
    }
    /**
     * 设备ID发生变化后，执行更新 runConf 相关数据
     *
     * @param newVal
     * @param oldVal
     */
    'watch.svgDevIdList'(newVal, oldVal) {
        if (newVal && newVal.length) {
            this.removeTimeoutAndAJax();
            // 销毁svg相关dom和事件
            this.destroySvg();
            // 初始化svg内容
            this.initSvgContent();
            // 初始化svg数据
            this.initSvgRunConfData();
        }
    }
};
__decorate([
    Watch('reqSvgInfoUrl', { immediate: true })
], VueWatchMixin.prototype, "watch.reqSvgInfoUrl", null);
__decorate([
    Watch('svgDevIdList', { deep: true })
], VueWatchMixin.prototype, "watch.svgDevIdList", null);
VueWatchMixin = __decorate([
    Component
], VueWatchMixin);
export default VueWatchMixin;
