var PiBaseCrumbsNav_1;
import { __decorate } from "tslib";
import { Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { DropdownMenu, Dropdown, DropdownItem } from 'iview';
import template from './pi-base-crumbs-nav.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-crumbs-nav.component.scss';
let PiBaseCrumbsNav = PiBaseCrumbsNav_1 = class PiBaseCrumbsNav extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseCrumbsNav';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseCrumbsNav_1?.NAME,
        author: 'wuzp',
        title: '面包屑导航',
        version: BcVersion,
        updateDate: '2019-07-16',
        description: '',
    };
    //#endregion
    //#region props
    //#endregion
    //#region data
    //#region 函数类属性
    //#endregion
    pageNavInfo;
    //#region computed
    get about() {
        return PiBaseCrumbsNav_1.ABOUT;
    }
    get titleAll() {
        return PiBaseCrumbsNav_1?.NAME;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    //#endregion
    //#region hooks
    created() {
        //设置数据源弹窗显示的可选项内容
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
    }
};
__decorate([
    Prop()
], PiBaseCrumbsNav.prototype, "pageNavInfo", void 0);
PiBaseCrumbsNav = PiBaseCrumbsNav_1 = __decorate([
    Component({
        components: {
            Dropdown,
            DropdownMenu,
            DropdownItem,
        },
        template,
    })
], PiBaseCrumbsNav);
export default PiBaseCrumbsNav;
