var PiBaseRow_1;
import { __decorate } from "tslib";
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { Checkbox } from 'iview';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-row.component.html';
import { RowEntity, } from 'bc$/entities/layout';
import { PiBaseStoreLayoutModule } from 'bc$/store/modules/pi-base-layout.store';
import { getGridTemplate, getGridTemplatePctValuesCalc, } from '../pi-base-center/pi-base-center.utils';
import { LayoutMode } from 'bc$/enums/layout/layout.enum';
import { RowSetUpdate, } from './pi-base-row.component.entity';
import { hAjaxPostJson, UrlType, PiStatusEnum, ApiUrlSetting, AjaxAbortName, hErrorShow, } from 'bc$/main';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-row.component.scss';
let PiBaseRow = PiBaseRow_1 = class PiBaseRow extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseRow';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseRow_1?.NAME,
        author: 'luot',
        title: '基础布局行组件',
        version: BcVersion,
        updateDate: '2019-05-21',
        description: '',
    };
    /**
     * 最小行高
     */
    static MIN_ROW_HEIGHT = hReactiveUtil.convertToReactivePx(100);
    /**
     * 更新行集合错误信息
     */
    static UPDATE_ROW_SET_FAIL = `Component ${PiBaseRow_1?.NAME} update row set fail:`;
    /**
     * 默认行高
     */
    static DEFAULT_ROW_HEIGHT = hReactiveUtil.convertToReactivePx(100);
    //#endregion
    //#region props
    /**
     * 当前所在页面唯一页签id
     */
    tabId;
    /**
     * 行信息
     */
    row;
    /**
     * 行 index
     */
    index;
    //#endregion
    //#region data
    /**
     * 列 grid 布局样式
     */
    columnsStyle = '';
    /**
     * sortableJs options
     */
    rowResizeOptions = {
        sticks: ['bm'],
        onStart: this.resizeRowStart,
        onStop: this.resizeRowStop,
        disabled: true,
        minHeight: PiBaseRow_1.MIN_ROW_HEIGHT,
    };
    //#endregion
    //#region computed
    get about() {
        return PiBaseRow_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * store中存储的布局相关的信息
     */
    get storeLayout() {
        return PiBaseStoreLayoutModule;
    }
    /**
     * 获取 Vuex 中存储的列信息
     */
    get columns() {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        return tab.Columns.filter((column) => column.rowPkId === this.row.pkid); //.sort((a, b) => a.orderNo - b.orderNo);
    }
    /**
     * 页面状态：是否处于布局编辑模式
     */
    get isLayoutMode() {
        return this.storeLayout.layoutMode === LayoutMode.design;
    }
    //#endregion computed
    //#region watch
    /**
     * 观察行组件中绑定的列Id变更
     * @param columnPkid
     */
    'watch.columns'(newVal, oldVal) {
        // 行信息变化后，更新关联的列信息
        const { template } = getGridTemplate(newVal, false);
        this.columnsStyle = `grid-template-columns: ${template};`;
    }
    /**
     * 观察行选中变化
     */
    'watch.row.piSelected'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        // 更新Vuex中选中的行列
        this.storeLayout.updateSelectedRowsOrColumns({
            tabId: this.tabId,
        });
    }
    /**
     * 观察页面模式变化
     * @param isLayoutMode
     */
    'watch.isLayoutMode'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        // 更新 v-resize 指令 disabled 属性值
        this.rowResizeOptions.disabled = newVal ? false : true;
    }
    //#endregion
    //#region methods
    /**
     * resize 指令开始执行回调
     * @param resizeDom
     */
    resizeRowStart() {
        // 禁用滚动条
        this.$piDispatch(this, 'updateScrollBarState', false, 'PiBasePageLayout', true);
    }
    /**
     * resize 指令执行完成回调
     * @param resizeDom
     */
    resizeRowStop(resizeDom) {
        // 禁用滚动条
        this.$piDispatch(this, 'updateScrollBarState', true, 'PiBasePageLayout', true);
        // 处理拖拉大小，更新  vuex 数据和后台数据
        this.rowResizeHandler(resizeDom);
    }
    /**
     * 处理 template rows 大小变化，同步后台数据
     * resize handler
     */
    rowResizeHandler(resizeDom) {
        const parentWidget = this.$parent;
        const isCenterWidget = this.$parent.$data
            ._IS_CENTER_COMPONENT;
        const parentEl = parentWidget.$el;
        const parentHeight = Number(getComputedStyle(parentEl).height.replace(/px|rem|em/g, ''));
        const parentHeightPCT = isCenterWidget
            ? Number(parentEl.style.height.slice(0, -1))
            : getGridTemplatePctValuesCalc(parentEl.style.gridTemplateRows);
        const { domRectLast } = resizeDom;
        const heightNew = domRectLast.height;
        const heightNewPCT = (heightNew / parentHeight) * parentHeightPCT;
        const newHeightPCT = Math.round(heightNewPCT);
        // 更新 vuex 中当前 row 数据
        let updateRowSet = [];
        // const currentRow = Object.assign({}, this.row);
        const currentRow = new RowEntity(this.row);
        const attrJsonObj = currentRow.attrObj;
        const heightPctOld = attrJsonObj.heightPct;
        // 行高改变值
        const heightPctChanged = newHeightPCT - heightPctOld;
        //检测是否有改变
        if (heightPctChanged === 0) {
            this.$Message.error('拖动未达到最小高度！');
            return;
        }
        attrJsonObj.heightPct = newHeightPCT;
        updateRowSet.push(new RowSetUpdate({
            pkid: currentRow.pkid,
            attrJson: currentRow.attrJson,
        }));
        // 更新下一个兄弟节点行高
        const nextSiblingRow = this.getNextOrPreviousSiblingRow();
        if (nextSiblingRow) {
            const nextAttrJsonObj = nextSiblingRow.attrObj;
            nextAttrJsonObj.heightPct += heightPctChanged * -1;
            updateRowSet.push(new RowSetUpdate({
                pkid: nextSiblingRow.pkid,
                attrJson: nextSiblingRow.attrJson,
            }));
        }
        // 删除 resize 指令在 dom 上设置的 width 和 height
        this.resetWidthHeight(this.$el);
        // 请求后台并更新 Vuex 数据
        this.reqRowSetUpdate({
            rows: this.getUpdateRowSet(updateRowSet),
        });
    }
    /**
     * 获取所有兄弟节点，包含自身
     * @param ColumnSetUpdate
     */
    getUpdateRowSet(rowSetUpdate) {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        const columnSetUpdatePkid = rowSetUpdate.map((item) => item.pkid);
        const parentColumnId = this.row.columnPkId;
        const siblingColumns = tab.Rows.filter((item) => item.columnPkId === parentColumnId);
        return siblingColumns.map((item) => {
            const columnSetUpdateIndex = columnSetUpdatePkid.indexOf(item.pkid);
            if (columnSetUpdateIndex !== -1) {
                return rowSetUpdate[columnSetUpdateIndex];
            }
            else {
                return new RowSetUpdate(item);
            }
        });
    }
    /**
     * 重置 dom 的高宽值
     * @param ele
     */
    resetWidthHeight(ele) {
        if (!ele) {
            return;
        }
        ele.style.removeProperty('height');
        ele.style.removeProperty('width');
    }
    /**
     * 更新 row 数据
     */
    updateRow(row, params) {
        if (!row) {
            return null;
        }
        if (!params || JSON.stringify(params) === '{}') {
            return row;
        }
        const keys = Object.getOwnPropertyNames(params);
        keys.forEach((item) => {
            row[item] = params[item];
        });
        return row;
    }
    /**
     * 获取下一个或者上一个兄弟节点 row 信息
     */
    getNextOrPreviousSiblingRow() {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        const curRow = this.row;
        const siblingRows = tab.Rows.filter((item) => item.columnPkId === curRow.columnPkId);
        const curRowIndex = siblingRows.findIndex((item) => item.pkid === curRow.pkid);
        const siblingRowsLen = siblingRows.length;
        let row = null;
        if (curRowIndex <= siblingRowsLen - 2) {
            // 存在下一个数据
            row = siblingRows[curRowIndex + 1];
        }
        else if (siblingRowsLen > 1) {
            // 最后一个数据则取前一个
            row = siblingRows[curRowIndex - 1];
        }
        return (row && new RowEntity(row)) || null;
    }
    /**
     * 更新行信息
     */
    reqRowSetUpdate(rowSetUpdateParams, cbFunc) {
        if (!rowSetUpdateParams || JSON.stringify(rowSetUpdateParams) === '{}') {
            return;
        }
        const rowSetUpdateUrl = ApiUrlSetting.WidgetConfig.PiBaseRow.UpdateAttrJsonUrl;
        if (!rowSetUpdateUrl) {
            return;
        }
        const reqUrl = this.convertUrl(rowSetUpdateUrl, UrlType.Api);
        const { tabId } = this;
        return this.resetAjax('reqRowSetUpdate', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, rowSetUpdateParams);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status !== PiStatusEnum.Success) {
                    this.$Message.error('更新行信息失败！');
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseRow_1.UPDATE_ROW_SET_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onUpdateRowSetError', res);
                    return;
                }
                // 后台请求成功
                const { rows } = rowSetUpdateParams;
                // 更新 Vuex 中的rows, 强行转换类型  IRowSetUpdate => RowEntity
                this.storeLayout.updateRows({
                    tabId,
                    rows,
                });
                this.$Message.success('更新行信息成功！');
            })
                .finally(() => {
                cbFunc && cbFunc();
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
                message: PiBaseRow_1.UPDATE_ROW_SET_FAIL,
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onUpdateRowSetError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
};
__decorate([
    Prop({ required: true })
], PiBaseRow.prototype, "tabId", void 0);
__decorate([
    Prop({
        required: true,
    })
], PiBaseRow.prototype, "row", void 0);
__decorate([
    Prop({
        default: -1,
    })
], PiBaseRow.prototype, "index", void 0);
__decorate([
    Watch('columns', { immediate: true, deep: true })
], PiBaseRow.prototype, "watch.columns", null);
__decorate([
    Watch('row.piSelected')
], PiBaseRow.prototype, "watch.row.piSelected", null);
__decorate([
    Watch('isLayoutMode', { immediate: true })
], PiBaseRow.prototype, "watch.isLayoutMode", null);
PiBaseRow = PiBaseRow_1 = __decorate([
    Component({
        components: {
            Checkbox,
        },
        template,
    })
], PiBaseRow);
export default PiBaseRow;
