import TWEEN from 'tweenjs';
import { hODeepObjectMerge } from 'bc$/utils/object.helper';
/**
 * 存储在 HTMLElement 的插件 tween 实例 key
 */
const EL_TWEEN = '_pi-tween';
/**
 * 获取目标 dom
 * @param el
 * @param options
 */
const getEle = function (el, options) {
    if (typeof options !== 'number' && options?.selector) {
        return el.querySelector(options.selector);
    }
    return el;
};
/**
 * 获取 tween 插件实例
 * @param el
 */
export const getTweenJsInstance = function (el, options) {
    let elNew = el;
    elNew = getEle(elNew, options);
    if (elNew) {
        return elNew[EL_TWEEN];
    }
    return null;
};
/**
 * 检测如果数字则转为参数对象
 * @param options 参数
 */
function num2Obj(options) {
    let optionsNew = options;
    switch (typeof optionsNew) {
        case 'number':
            let precision = 0;
            const str = `${optionsNew}`;
            const index = str.indexOf('.');
            if (~index) {
                precision = str.length - index - 1;
            }
            optionsNew = {
                precision,
                options: {
                    // 目标值
                    to: {
                        properties: {
                            value: optionsNew,
                        },
                    },
                },
            };
            break;
        default:
            optionsNew = {};
            break;
    }
    return optionsNew;
}
/**
 * 与默认参数合并
 * @param target 当前html元素
 * @param options 用户参数
 */
function mergeOptions(target, options) {
    // 默认值
    const optionsNew = {
        options: {
            init: {
                object: {
                    value: 0,
                },
            },
            to: {
                duration: 500,
            },
            onUpdate: function (object) {
                const value = object.value?.toFixed(optionsNew.precision);
                switch (target.tagName.toLowerCase()) {
                    case 'input':
                        target.value = `${value}`;
                        break;
                    default:
                        target.textContent = `${value}`;
                        break;
                }
            },
        },
    };
    //合并新的到默认值
    hODeepObjectMerge(optionsNew, options);
    return optionsNew;
}
/**
 * 执行差值动画
 * @param options 动画参数
 */
function tweenAnimate(options) {
    //检测输入的起始和结束值参数
    const { from: fromOrigin, to: toOrigin } = options;
    //设置缓动效果
    // 初始化
    let { object, group } = options.options.init;
    if (fromOrigin) {
        object = object || {};
        object.value = fromOrigin;
    }
    const pTween = new TWEEN.Tween(object, group);
    //to
    let { to } = options.options;
    if (toOrigin) {
        to = to || {};
        to.properties = to.properties || {};
        to.properties.value = to.properties.value || 0;
        to.properties.value = toOrigin;
    }
    to && pTween.to(to.properties, to.duration);
    //duration
    const { duration } = options.options;
    duration && pTween.delay(duration.d);
    //pause
    const { pause } = options.options;
    pause && pTween.pause(pause.time);
    //resume
    const { resume } = options.options;
    resume && pTween.resume(resume.time);
    //group
    const { group: groupMethod } = options.options;
    groupMethod && pTween.group(groupMethod.group);
    //delay
    const { delay } = options.options;
    delay && pTween.delay(delay.amount);
    //repeat
    const { repeat } = options.options;
    repeat && pTween.repeat(repeat.times);
    //repeatDelay
    const { repeatDelay } = options.options;
    repeatDelay && pTween.repeatDelay(repeatDelay.times);
    //yoyo
    const { yoyo } = options.options;
    yoyo && pTween.yoyo(yoyo.enable);
    //easing
    const { easing } = options.options;
    easing && pTween.easing(easing.easing);
    //interpolation
    const { interpolation } = options.options;
    interpolation && pTween.interpolation(interpolation.interpolation);
    //chain
    const { chain } = options.options;
    chain && pTween.chain(...chain.tweens);
    //update
    const { update } = options.options;
    update && pTween.update(update.time);
    //onStart,onStop,onUpdate,onRepeat,onComplete
    const { onStart, onStop, onUpdate, onRepeat, onComplete } = options.options;
    typeof onStart === 'function' && pTween.onStart(onStart);
    typeof onStop === 'function' && pTween.onStop(onStop);
    typeof onUpdate === 'function' && pTween.onUpdate(onUpdate);
    typeof onRepeat === 'function' && pTween.onRepeat(onRepeat);
    typeof onComplete === 'function' && pTween.onComplete(onComplete);
    //start
    pTween.start();
    //比包内的函数,用来做动画效果
    (function animate() {
        if (TWEEN.update()) {
            requestAnimationFrame(animate);
        }
    })();
    return pTween;
}
/**
 * tween 插件初始化
 * @param el 当前html元素
 * @param options 传入的用户参数
 */
function init(el, options) {
    let optionsNew = num2Obj(options);
    let promise = optionsNew?.initAsync || Promise.resolve(void 0);
    promise
        .then(() => {
        const target = getEle(el, optionsNew);
        if (!target) {
            console.warn('未找到差值动画目标元素！', el, optionsNew);
            return;
        }
        //之前存在直接销毁
        let tweenInstance = getTweenJsInstance(target, optionsNew);
        tweenInstance?.stop();
        tweenInstance?.end();
        //与默认值合并
        optionsNew = mergeOptions(target, optionsNew);
        //执行动画
        const pTween = tweenAnimate(optionsNew);
        //将对象挂到html元素上
        target[EL_TWEEN] = pTween;
    })
        .catch((err) => Promise.reject(err));
}
/**
 * tween 插件更新
 * @param el 当前html元素
 * @param options 传入的用户参数
 */
function update(el, options) {
    let optionsNew = num2Obj(options);
    let promise = optionsNew?.initAsync || Promise.resolve(void 0);
    promise
        .then(() => {
        const target = getEle(el, optionsNew);
        if (!target) {
            console.warn('未找到差值动画目标元素！', el, optionsNew);
            return;
        }
        //与默认值合并
        optionsNew = mergeOptions(target, optionsNew);
        //执行更新
        let tweenInstance = getTweenJsInstance(target, optionsNew);
        tweenInstance?.update(optionsNew.options?.update?.time || new Date().getTime());
    })
        .catch((err) => Promise.reject(err));
}
/**
 * Vue 指令初始化
 */
export const TweenDirective = {
    inserted(el, binding, vNode) {
        init(el, binding.value);
    },
    update(el, binding, vNode) {
        update(el, binding.value);
    },
    unbind(el, binding) {
        let tweenInstance = getTweenJsInstance(el, binding.value);
        //直接销毁解绑
        tweenInstance?.stop();
        tweenInstance?.end();
        //将对象挂到html元素上
        el[EL_TWEEN] = null;
    },
};
