export const LocalesAll = [
    {
        value: 'zh_cn',
        alias: 'zh-CN',
        text: '简体中文',
    },
    {
        value: 'zh_tw',
        alias: 'zh-TW',
        text: '繁體中文',
    },
    {
        value: 'en',
        alias: 'en-US',
        text: 'English',
    },
    {
        value: 'ja_jp',
        alias: 'ja-JP',
        text: '日本語',
    },
];
// {"ar_eg":"ar-EG"},
// {"de":"de-DE"},
// {"fa_ir":"fa-IR"},
// {"id":"id-ID"},
// {"mn":"mn-MN"},
// {"pt_br":"pt-BR"},
// {"sv_se":"sv-SE"},
// {"vi_vn":"vi-VN"},
// {"ar_sa":"ar-SA"},
// {"el_gr":"el-GR"},
// {"fi":"fi-FI"},
// {"it":"it-IT"},
// {"mn_tr":"mn-TR"},
// {"pt":"pt-PT"},
// {"th":"th-TH"},
// {"cs_cz":"cs-CZ"},
// {"fr":"fr-FR"},
// {"nl":"nl-NL"},
// {"ro":"ro-RO"},
// {"tr":"tr-TR"},
// {"da_dk":"da-DK"},
// {"es":"es-ES"},
// {"hi_in":"hi-IN"},
// {"ko_kr":"ko-KR"},
// {"pl":"pl-PL"},
// {"ru":"ru-RU"},
// {"uk":"uk-UA"},
