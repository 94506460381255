var PiBaseToolbar_1;
import { __decorate } from "tslib";
import { PiBaseStorePageModule } from 'bc$/store/modules/pi-base-page.store';
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { ColumnEntity, } from 'bc$/entities/layout';
import { RowEntity } from 'bc$/entities/layout';
import { Icon, ButtonGroup, Button } from 'iview';
import { PiBaseStoreLayoutModule, } from 'bc$/store/modules/pi-base-layout.store';
import { LayoutMode, RowsColumnsSelectedType, } from 'bc$/enums/layout/layout.enum';
import { PiBaseStoreWidgetModule } from 'bc$/store/modules/pi-base-widget.store';
import { hAjaxPostJson, UrlType, PiStatusEnum, hAjaxDelete, AjaxAbortName, hErrorShow, } from 'bc$/main';
import { RowUpdateParam, ColumnAddParam, ColumnSpanReqParamColumn, RowSpanParam, ColumnParam, ColumnSplitReqParamColumn, } from './pi-base-toolbar.component.entity';
import { default as PiBaseRow } from '../pi-base-row/pi-base-row.component';
import { default as PiBaseColumn } from '../pi-base-column/pi-base-column.component';
import { ApiUrlSetting } from 'bc$/urls';
import template from './pi-base-toolbar.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-toolbar.component.scss';
let PiBaseToolbar = PiBaseToolbar_1 = class PiBaseToolbar extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseWidgetToolbar';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseToolbar_1?.NAME,
        author: 'wuzp',
        title: '基础工具栏组件',
        version: BcVersion,
        updateDate: '2019-05-28',
        description: '',
    };
    /**
     * 行合并错误信息
     */
    static SPAN_ROW_FAIL = `Component ${PiBaseToolbar_1?.NAME} span row fail: `;
    /**
     * 行拆分错误信息
     */
    static SPLIT_ROW_FAIL = `Component ${PiBaseToolbar_1?.NAME} split row fail: `;
    /**
     * 行拆分错误信息
     */
    static COLUMN_SPLIT_ROW_FAIL = `Component ${PiBaseToolbar_1?.NAME} column split row fail: `;
    /**
     * 删除分错误信息
     */
    static DELETE_ROW_FAIL = `Component ${PiBaseToolbar_1?.NAME} delete row fail: `;
    /**
     * 合并列错误信息
     */
    static SPAN_COLUMN_FAIL = `Component ${PiBaseToolbar_1?.NAME} span column fail: `;
    /**
     * 列拆分错误信息
     */
    static SPLIT_COLUMN_FAIL = `Component ${PiBaseToolbar_1?.NAME} split column fail: `;
    //#endregion
    //#region props
    /**
     * 合并行
     */
    showRowSpan;
    /**
     * 拆分行
     */
    showRowSplit;
    /**
     * 删除行
     */
    showRowDelete;
    /**
     * 拆分列
     */
    showColumnSplit;
    /**
     * 合并列
     */
    showColumnSpan;
    //#endregion
    //#region data
    /**
     * 合并行 -- 禁用
     */
    disabledRowSpan = true;
    /**
     * 拆分行 -- 禁用
     */
    disabledRowSplit = true;
    /**
     * 删除行 -- 禁用
     */
    disabledRowDelete = true;
    /**
     * 合并列 -- 禁用
     */
    disabledColumnSpan = false;
    /**
     * 拆分列 -- 禁用
     */
    disabledColumnSplit = false;
    //#endregion
    //#region computed
    get about() {
        return PiBaseToolbar_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * 隐藏工具栏
     */
    get hideToolbar() {
        return (this.isLayoutMode ||
            (!this.showRowSpan &&
                !this.showColumnSpan &&
                !this.showRowSplit &&
                !this.showColumnSplit &&
                !this.showRowDelete));
    }
    /**
     * store中存储的页面相关的信息
     */
    get storePage() {
        return PiBaseStorePageModule;
    }
    /**
     * store中存储的布局相关的信息
     */
    get storeLayout() {
        return PiBaseStoreLayoutModule;
    }
    /**
     * store中存储的组件相关的信息
     */
    get storeWidget() {
        return PiBaseStoreWidgetModule;
    }
    /**
     * 当前打开的tab的信息
     */
    get curTab() {
        return this.storePage.curTab;
    }
    /**
     * 当前活动tab
     */
    get layoutInfo() {
        //页面store中当前tab信息
        const curTab = this.curTab;
        if (!curTab) {
            return {};
        }
        //布局store中当前布局信息
        const tab = this.storeLayout.tabs.find((p) => p.tabId === curTab.tabId);
        return tab || {};
    }
    /**
     * 所有行数据
     */
    get rows() {
        return this.layoutInfo.Rows || [];
    }
    /**
     * 所有列数据
     */
    get columns() {
        return this.layoutInfo.Columns || [];
    }
    /**
     * 是否处于页面设计模式
     */
    get isLayoutMode() {
        return this.storeLayout.layoutMode === LayoutMode.design;
    }
    /**
     * vuex 中选中的行列集合
     */
    get rowsOrColumnsSelected() {
        return (this.layoutInfo.RowsOrColumnsSelected ||
            []);
    }
    //#endregion
    //#region watch
    /**
     * 观察选中行列变化
     */
    'watch.rowsOrColumnsSelected'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        const rowsOrColumnsSelected = this.rowsOrColumnsSelected;
        const rowsColumnsSelectedType = this.getRowColumnSelectedType(rowsOrColumnsSelected);
        this.updateToolBarIconState(rowsColumnsSelectedType, rowsOrColumnsSelected);
    }
    //#endregion
    //#region methods
    /**
     * 根据当前类型禁用启用/显示隐藏对应图标
     * @param type
     */
    updateToolBarIconState(type, rowsOrColumnsSelected) {
        const iconSpanSplitState = this.getIconSpanSplitState(rowsOrColumnsSelected, type);
        if (type === RowsColumnsSelectedType.allColumns) {
            // 选中的数据皆为行数据
            this.disabledRowSpan = true;
            this.disabledRowSplit = true;
            this.disabledRowDelete = true;
            this.disabledColumnSpan = iconSpanSplitState.iconSpanDisabled;
            this.disabledColumnSplit = iconSpanSplitState.iconSplitDisabled;
            // 特殊处理单选列允许分割行, 条件: 列的子组件为空或者业务组件
            if (rowsOrColumnsSelected.length === 1) {
                const column = rowsOrColumnsSelected[0];
                const subComponent = this.getColumnSubComponent(column);
                if (!subComponent || subComponent.hasOwnProperty('widgetPkId')) {
                    this.disabledRowSplit = false;
                }
            }
        }
        else if (type === RowsColumnsSelectedType.allRows) {
            // 选中的数据皆为行数据
            this.disabledRowSpan = iconSpanSplitState.iconSpanDisabled;
            this.disabledRowSplit = iconSpanSplitState.iconSplitDisabled;
            this.disabledRowDelete = false;
            this.disabledColumnSpan = true;
            this.disabledColumnSplit = true;
        }
        else {
            // 选中的数据皆为行列混合数据
            this.disabledRowSpan = true;
            this.disabledRowSplit = true;
            this.disabledRowDelete = !this.rowsOrColumnsSelected.length;
            this.disabledColumnSpan = true;
            this.disabledColumnSplit = true;
        }
    }
    /**
     * 获取列组件的子组件
     *
     * @param column
     */
    getColumnSubComponent(column) {
        if (!column) {
            return;
        }
        const columnPkid = column.pkid;
        const row = this.layoutInfo.Rows.find((row) => row.columnPkId === columnPkid);
        if (row) {
            return row;
        }
        else {
            const tabId = this.curTab.tabId;
            if (!tabId) {
                return;
            }
            const tab = this.storeWidget.tabs.find((p) => p.tabId === tabId);
            const widgetInstanceInfo = tab.widgetInstanceInfos.find((widgetBaseInfo) => widgetBaseInfo.columnPkId === columnPkid);
            return widgetInstanceInfo;
        }
    }
    /**
     * 获取合并和拆分按钮状态
     * @param rowColumnData 行列数据集合
     * @param dataType 数据类型
     */
    getIconSpanSplitState(rowColumnData, dataType) {
        let result = {
            iconSpanDisabled: true,
            iconSplitDisabled: true,
        };
        if (!rowColumnData) {
            return result;
        }
        // 判断当前行列是否存在子业务组件
        const childrenWidgetInstance = (dataType === RowsColumnsSelectedType.allRows ||
            dataType === RowsColumnsSelectedType.allColumns) &&
            this.getWidgetInstances(rowColumnData, dataType);
        if (rowColumnData.length === 1) {
            // 单条数据，禁用合并
            result.iconSplitDisabled = false;
        }
        else {
            if (dataType === RowsColumnsSelectedType.allRows) {
                // 获取第一个行数据判断
                const row = rowColumnData[0];
                const rowColumnPkid = row.columnPkId;
                // 相同父节点下的兄弟节点
                const siblingRows = this.rows.filter((item) => rowColumnPkid === item.columnPkId);
                const rowIndex = siblingRows.findIndex((item) => item.pkid === row.pkid);
                // 判断是否为兄弟节点
                let iconSpanDisabled = false;
                rowColumnData.some((item, index) => {
                    if (index > 0) {
                        if (item.pkid !== siblingRows[rowIndex + index].pkid) {
                            iconSpanDisabled = true;
                            return true;
                        }
                        return false;
                    }
                    return false;
                });
                result.iconSpanDisabled =
                    iconSpanDisabled &&
                        childrenWidgetInstance &&
                        childrenWidgetInstance.length;
            }
            else if (dataType === RowsColumnsSelectedType.allColumns) {
                // 获取第一个行数据判断
                const column = rowColumnData[0];
                const columnRowPkid = column.rowPkId;
                // 相同父节点下的兄弟节点
                const siblingColumns = this.columns.filter((item) => columnRowPkid === item.rowPkId);
                const columnIndex = siblingColumns.findIndex((item) => item.pkid === column.pkid);
                // 判断是否为兄弟节点
                let iconSpanDisabled = false;
                rowColumnData.some((item, index) => {
                    if (index > 0) {
                        if (!siblingColumns[columnIndex + index] ||
                            item.pkid !== siblingColumns[columnIndex + index].pkid) {
                            iconSpanDisabled = true;
                            return true;
                        }
                        return false;
                    }
                    return false;
                });
                result.iconSpanDisabled =
                    iconSpanDisabled &&
                        childrenWidgetInstance &&
                        childrenWidgetInstance.length;
            }
        }
        return result;
    }
    /**
     * 根据选中行列判断子业务组件
     * @param rowColumnData
     */
    getWidgetInstances(rowColumnData, dataType) {
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        const tab = this.storeWidget.tabs.find((p) => p.tabId === tabId);
        const widgetBaseInfos = tab.widgetInstanceInfos;
        if (!widgetBaseInfos ||
            !widgetBaseInfos.length ||
            dataType === RowsColumnsSelectedType.mixinRowsColumns) {
            return [];
        }
        // 按照选中行列向下查子行列
        let widgetInstances = [];
        rowColumnData.forEach((item) => {
            const tmpWidgetInstance = this.getWidgetInstance(item.pkid);
            tmpWidgetInstance && widgetInstances.push(tmpWidgetInstance);
        });
        return widgetInstances;
    }
    /**
     * 根据列pkid 查找组件信息
     */
    getWidgetInstance(columnPkid) {
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        const tab = this.storeWidget.tabs.find((p) => p.tabId === tabId);
        const widgetBaseInfos = tab.widgetInstanceInfos;
        return (widgetBaseInfos &&
            widgetBaseInfos.find((item) => item.columnPkId === columnPkid));
    }
    /**
     * 判断选中行列的数据类型
     * @param data
     */
    getRowColumnSelectedType(data) {
        let type = RowsColumnsSelectedType.unknown;
        /**
         * 根据数据类型获取选中行列集合的类型
         *
         * @param oldType
         * @param newType
         */
        const getType = (oldType, newType) => {
            if (oldType === RowsColumnsSelectedType.unknown) {
                return newType;
            }
            else {
                if (oldType === newType) {
                    return newType;
                }
                else {
                    return RowsColumnsSelectedType.mixinRowsColumns;
                }
            }
        };
        data.some((item) => {
            if (item.hasOwnProperty('columnPkId')) {
                // 行数据
                type = getType(type, RowsColumnsSelectedType.allRows);
            }
            else if (item.hasOwnProperty('rowPkId')) {
                // 列数据
                type = getType(type, RowsColumnsSelectedType.allColumns);
            }
            if (type === RowsColumnsSelectedType.mixinRowsColumns) {
                // 混合模式，退出循环
                return true;
            }
            return false;
        });
        return type;
    }
    /**
     * 合并行
     */
    toggleRowSpan() {
        const cloneRowsOrColumnsSelected = this.rowsOrColumnsSelected.slice(0);
        const rowSpanUrl = ApiUrlSetting.WidgetConfig.PiBaseToolBar.RowSpanUrl;
        if (!rowSpanUrl ||
            !cloneRowsOrColumnsSelected ||
            !cloneRowsOrColumnsSelected.length) {
            return { instance: Promise.resolve(void 0) };
        }
        const reqParams = this.getRowSpanReqParam(cloneRowsOrColumnsSelected);
        if (!reqParams) {
            return { instance: Promise.resolve(void 0) };
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        const reqUrl = this.convertUrl(rowSpanUrl, UrlType.Api);
        return this.resetAjax('toggleRowSpan', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, reqParams);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                // 后台请求成功
                if (data.status === PiStatusEnum.Success) {
                    // 1. 删除被合并行，更新行列数据
                    this.storeLayout.delRowsColumns({
                        tabId,
                        param: {
                            pkIds: reqParams ? reqParams.rowsDel : [],
                            dataType: RowsColumnsSelectedType.allRows,
                        },
                    });
                    this.storeLayout.updateRows({
                        tabId,
                        rows: [{ ...reqParams.rowsUpd }],
                    });
                    // 2. 更新组件实例
                    const columnData = cloneRowsOrColumnsSelected[0];
                    const columnPkId = columnData.pkid;
                    const pkid = reqParams && reqParams.widgetInfo && reqParams.widgetInfo.pkid;
                    pkid &&
                        this.storeWidget.updateWidgetInstanceInfo({
                            tabId,
                            data: {
                                pkid,
                                columnPkId,
                            },
                        });
                    this.$Message.success('合并选中行成功！');
                    // 3. 清空选中
                    this.storeLayout.cancelSelectedRowsColumns({ tabId });
                }
                else {
                    this.$Message.error('合并选中行失败！');
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseToolbar_1.SPAN_ROW_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onSpanRowError', res);
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
                message: PiBaseToolbar_1.SPAN_ROW_FAIL,
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onSpanRowError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 获取行列合并 post 参数
     * @param rowsOrColumnsSelected
     */
    getRowSpanReqParam(rowsOrColumnsSelected) {
        if (!rowsOrColumnsSelected || !rowsOrColumnsSelected.length) {
            return;
        }
        let result = {};
        // 处理 rowsDel
        const rowPkIds = this.getRowsColumnsPkIds(rowsOrColumnsSelected);
        if (rowPkIds && rowPkIds.length > 1) {
            result.rowsDel = rowPkIds.slice(1);
        }
        // 处理 rowsUpd
        const updateRow = rowsOrColumnsSelected[0];
        const updateRowPkId = updateRow.pkid;
        const columnsParams = [];
        this.columns.forEach((item) => {
            if (updateRowPkId === item.rowPkId) {
                let tmpColumnParam = new ColumnParam({
                    pkid: item.pkid,
                    orderNo: item.orderNo,
                    attrJson: item.attrJson,
                });
                columnsParams.push(tmpColumnParam);
            }
        });
        const deleteRowsHeightPCTTotal = this.calcRowsColumnsWidthHeightPCT(rowsOrColumnsSelected.slice(1), true);
        let rowsUpd = [];
        rowsUpd.push(new RowSpanParam({
            pkid: updateRow.pkid,
            attrJson: this.calcAttrJSON(true, deleteRowsHeightPCTTotal, updateRow.attrObj),
            columns: columnsParams,
        }));
        // 处理 widgetInfo
        const widgetInstancesInfo = this.getWidgetInstances(rowsOrColumnsSelected, RowsColumnsSelectedType.allRows);
        if (widgetInstancesInfo && widgetInstancesInfo.length === 1) {
            const widgetInstanceInfo = widgetInstancesInfo[0];
            result.widgetInfo = {
                pkid: widgetInstanceInfo.pkid,
                columnPkId: columnsParams[0] ? columnsParams[0].pkid : '', // 这里应该是第一行的第一列,
            };
        }
        return result;
    }
    /**
     * 计算行列新 attrJSON
     * @param isRow
     * @param plusValue
     * @param oriAttrJSON
     */
    calcAttrJSON(isRow, plusValue, oriAttrJSON) {
        if (!oriAttrJSON || !plusValue) {
            return '{}';
        }
        let result = '';
        if (isRow) {
            oriAttrJSON.heightPct += plusValue;
        }
        else {
            oriAttrJSON.widthPct += plusValue;
        }
        result = JSON.stringify(oriAttrJSON);
        return result;
    }
    /**
     * 获取行列数据的 pkid 集合
     */
    getRowsColumnsPkIds(rowsColumns) {
        let pkIds = [];
        rowsColumns.forEach((item) => {
            pkIds.push(item.pkid);
        });
        return pkIds;
    }
    /**
     * 后台请求 - 拆分行
     *
     * 有可能拆分的对象是列
     */
    toggleRowSplit() {
        const cloneRowsOrColumnsSelected = this.rowsOrColumnsSelected.slice(0);
        if (!cloneRowsOrColumnsSelected || cloneRowsOrColumnsSelected.length > 1) {
            return { instance: Promise.resolve(void 0) };
        }
        let reqParams;
        // 1. 操作目标为列
        const cloneRowOrColumnSelected = cloneRowsOrColumnsSelected[0];
        if (cloneRowOrColumnSelected.hasOwnProperty('rowPkId')) {
            reqParams = this.getColumnSplitRowReqParam(cloneRowOrColumnSelected);
            if (!reqParams) {
                return { instance: Promise.resolve(void 0) };
            }
            const tabId = this.curTab.tabId;
            if (!tabId) {
                return { instance: Promise.resolve(void 0) };
            }
            const reqUrl = this.convertUrl(ApiUrlSetting.WidgetConfig.PiBaseToolBar.ColumnSplitRowUrl, UrlType.Api);
            return this.resetAjax('toggleRowSplit', () => {
                const ajaxInfo = hAjaxPostJson(reqUrl, reqParams);
                ajaxInfo.instance
                    .then((res) => {
                    const data = res.data || null;
                    if (data.status === PiStatusEnum.Success) {
                        // 后台请求成功
                        const respData = data.data && data.data.length ? data.data[0] : null;
                        if (respData) {
                            this.handleColumnSplitRowResp(respData, reqParams);
                            this.$Message.success('拆分选中列成功！');
                        }
                        // 清空选中
                        this.storeLayout.cancelSelectedRowsColumns({ tabId });
                    }
                    else {
                        this.$Message.error('拆分选中列失败！');
                        // 后台请求失败，emit 错误信息
                        console.error(`${PiBaseToolbar_1.COLUMN_SPLIT_ROW_FAIL} ${data ? data.msg : res.statusText}`);
                        this.$emit('onColumnSplitRowDataError', res);
                    }
                })
                    .catch((err) => hErrorShow({
                    err,
                    showInPage: true,
                    context: this,
                    message: PiBaseToolbar_1.COLUMN_SPLIT_ROW_FAIL,
                    callback: () => {
                        if (err.message !== AjaxAbortName) {
                            this.$emit('onColumnSplitRowDataError', err);
                        }
                    },
                }));
                return ajaxInfo;
            });
        }
        else {
            // 2. 操作目标为行
            reqParams = this.getRowSplitReqParam(cloneRowsOrColumnsSelected);
            if (!reqParams) {
                return { instance: Promise.resolve(void 0) };
            }
            const tabId = this.curTab.tabId;
            if (!tabId) {
                return { instance: Promise.resolve(void 0) };
            }
            const reqUrl = this.convertUrl(ApiUrlSetting.WidgetConfig.PiBaseToolBar.RowSplitUrl, UrlType.Api);
            return this.resetAjax('toggleRowSplit', () => {
                const ajaxInfo = hAjaxPostJson(reqUrl, reqParams);
                ajaxInfo.instance
                    .then((res) => {
                    const data = res.data || null;
                    if (data.status === PiStatusEnum.Success) {
                        // 后台请求成功
                        const respData = data.data && data.data.length ? data.data[0] : null;
                        if (respData) {
                            this.handleRowSplitResponse(respData, reqParams);
                            this.$Message.success('合并选中行成功！');
                        }
                        // 清空选中
                        this.storeLayout.cancelSelectedRowsColumns({ tabId });
                    }
                    else {
                        this.$Message.error('合并选中行失败！');
                        // 后台请求失败，emit 错误信息
                        console.error(`${PiBaseToolbar_1.SPLIT_ROW_FAIL} ${data ? data.msg : res.statusText}`);
                        this.$emit('onSplitRowDataError', res);
                    }
                })
                    .catch((err) => hErrorShow({
                    err,
                    showInPage: true,
                    context: this,
                    message: PiBaseToolbar_1.SPLIT_ROW_FAIL,
                    callback: () => {
                        if (err.message !== AjaxAbortName) {
                            this.$emit('onSplitRowDataError', err);
                        }
                    },
                }));
                return ajaxInfo;
            });
        }
    }
    /**
     * 后台请求 - 列拆行
     */
    getColumnSplitRowReqParam(column) {
        if (!column) {
            return;
        }
        let result = {};
        result.columnPkId = column.pkid;
        const subComponent = this.getColumnSubComponent(column);
        if (subComponent && subComponent.hasOwnProperty('widgetPkId')) {
            let widgetInfo = {};
            widgetInfo.colIndex = 0;
            widgetInfo.colIndex = 0;
            widgetInfo.pkId = subComponent.pkid;
            result.widgetInfo = widgetInfo;
        }
        /**
         * 生成新增行数据
         * @param rowNum
         */
        const genRowsAdd = (rowNum) => {
            let rows = [];
            for (let i = 0; i < rowNum; i++) {
                let tmpRow = {};
                const rowHeight = PiBaseRow.DEFAULT_ROW_HEIGHT / rowNum;
                tmpRow.orderNo = i + 1;
                tmpRow.attrJson = {
                    heightPct: rowHeight,
                };
                tmpRow.columns = [
                    {
                        orderNo: 1,
                        attrJson: {
                            widthPct: PiBaseColumn.MAX_COLUMN_WIDTH,
                        },
                    },
                ].map((p) => new ColumnAddParam(p));
                rows.push(tmpRow);
            }
            return rows;
        };
        result.rows = genRowsAdd(2);
        return result;
    }
    /**
     * 处理后台请求返回值 - 列拆分行
     * @param IColumnSplitRowRespParam 返回参数
     * @param reqData 请求参数
     */
    handleColumnSplitRowResp(data, reqData) {
        if (!data) {
            return;
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        //#region 新增2行和新增2列
        const respRows = data.rows;
        const columnPkId = reqData.columnPkId;
        let newRows = [];
        let newColumns = [];
        respRows.forEach((respRow, index) => {
            // 行
            const reqRow = reqData.rows[index];
            const rowPkId = respRow.pkid;
            let tmpRow = new RowEntity({
                pkid: rowPkId,
                orderNo: index,
                columnPkId,
                attrJson: reqRow.attrJson,
                piSelected: false,
            });
            newRows.push(tmpRow);
            // 列
            const tmpColumnPkId = respRow.columnPkIds[0];
            if (tmpColumnPkId) {
                const reqColumn = reqRow.columns[0];
                let tmpColumn = new ColumnEntity({
                    pkid: tmpColumnPkId,
                    rowPkId: rowPkId,
                    orderNo: reqColumn.orderNo,
                    attrJson: reqColumn.attrJson,
                    piSelected: false,
                });
                newColumns.push(tmpColumn);
            }
        });
        this.storeLayout.addRows({
            tabId,
            rows: newRows,
        });
        this.storeLayout.addColumns({
            tabId,
            columns: newColumns,
        });
        //#endregion
        // 2. 更新组件实例的 columnPkId
        if (data.widgetInfo) {
            const widgetInstancePkId = data.widgetInfo;
            this.storeWidget.updateWidgetInstanceInfo({
                tabId,
                data: {
                    pkid: widgetInstancePkId.pkid,
                    columnPkId: widgetInstancePkId.columnPkId,
                },
            });
        }
    }
    /**
     * 后台返回结果处理 - 行拆分
     * @param respData 接口返回对象
     * @param reqParams 入参对象
     */
    handleRowSplitResponse(respData, reqParams) {
        if (!respData || !reqParams) {
            return;
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        // 更新 vuex 中 rows 数据
        const rowUpdate = reqParams.rowUpd;
        rowUpdate &&
            this.storeLayout.updateRows({
                tabId,
                rows: [rowUpdate],
            });
        // 处理 vuex 中新增的行 row 数据
        const rowPkid = respData.rowAddPkid;
        const newRow = new RowEntity({
            pkid: rowPkid,
            piSelected: false,
            attrJson: reqParams.rowAdd.attrJson,
            orderNo: reqParams.rowAdd.orderNo,
            columnPkId: reqParams.columnPkId,
        });
        this.storeLayout.addRows({
            tabId,
            rows: [newRow],
        });
        // 处理 vuex 中新增的列 column 数据
        let columns = [];
        respData.columnPkIds.forEach((pkid, index) => {
            const tmpReqColumn = reqParams.rowAdd.columns[index];
            let tmpColumn = new ColumnEntity({
                piSelected: false,
                attrJson: tmpReqColumn.attrJson,
                orderNo: tmpReqColumn.orderNo,
                pkid: pkid,
                rowPkId: rowPkid,
            });
            columns.push(tmpColumn);
        });
        this.storeLayout.addColumns({
            tabId,
            columns,
        });
    }
    /**
     * 生成行拆分请求参数
     * @param rowsOrColumnsSelected
     */
    getRowSplitReqParam(rowsOrColumnsSelected) {
        if (!rowsOrColumnsSelected || !rowsOrColumnsSelected.length) {
            return;
        }
        let result = {};
        const splitRow = rowsOrColumnsSelected[0];
        if (splitRow && JSON.stringify(splitRow) !== '{}') {
            // 1. 拆分行所处在的列
            result.columnPkId = splitRow.pkid;
            // 2. 需要更新的行集合
            result.rowUpd =
                this.getRowUpdateReqParam(splitRow) || {};
            // 3. 新增的行
            const rowAddParams = this.getRowAddReqParam(splitRow);
            rowAddParams && (result.rowAdd = rowAddParams);
        }
        return result;
    }
    /**
     * 生成行更新参数 - rowAdd
     * @param row
     */
    getRowAddReqParam(row) {
        if (!row) {
            return;
        }
        let result = {};
        // 新行 orderNo
        result.orderNo = row.orderNo + 1;
        // 新行信息
        const rowAttrJSONObj = row.attrObj;
        rowAttrJSONObj && (rowAttrJSONObj.heightPct /= 2);
        // 子列信息
        result.columns = [
            {
                orderNo: 1,
                attrJson: {
                    widthPct: PiBaseColumn.MAX_COLUMN_WIDTH, // 默认列宽 24
                },
            },
        ].map((p) => new ColumnAddParam(p));
        return result;
    }
    /**
     * 生成行更新参数 - rowUpd
     * @param row
     */
    getRowUpdateReqParam(row) {
        if (!row) {
            return;
        }
        let result = new RowUpdateParam();
        result.pkid = row.pkid;
        const rowAttrJSONObj = row.attrObj;
        const heightPct = rowAttrJSONObj.heightPct;
        const newHeightPCT = heightPct && heightPct / 2;
        if (newHeightPCT) {
            const newAttrJsonObj = Object.assign({}, rowAttrJSONObj, {
                heightPct: newHeightPCT,
            });
            newAttrJsonObj && (result.attrJson = newAttrJsonObj);
        }
        return result;
    }
    /**
     * 删除行
     */
    toggleRowDelete() {
        const cloneRowsOrColumnsSelected = this.rowsOrColumnsSelected.slice(0);
        if (!cloneRowsOrColumnsSelected || cloneRowsOrColumnsSelected.length < 1) {
            return;
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        const delParams = this.getDelParams(cloneRowsOrColumnsSelected);
        const config = { data: delParams };
        const reqUrl = this.convertUrl(ApiUrlSetting.WidgetConfig.PiBaseToolBar.RowDeleteUrl, UrlType.Api);
        return this.resetAjax('toggleRowDelete', () => {
            const ajaxInfo = hAjaxDelete(reqUrl, config);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                // 后台请求成功
                if (data.status === PiStatusEnum.Success) {
                    this.storeLayout.deleteRows({
                        tabId,
                        rowPkIds: delParams,
                    });
                    this.$Message.success('删除选中行成功！');
                    // 清空选中
                    this.storeLayout.cancelSelectedRowsColumns({ tabId });
                }
                else {
                    this.$Message.error('删除选中行失败！');
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseToolbar_1.DELETE_ROW_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onDeleteRowError', res);
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
                message: PiBaseToolbar_1.DELETE_ROW_FAIL,
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onDeleteRowError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 获取删除栅格行
     */
    getDelParams(rows) {
        // this.rows.
        let result = [];
        rows.forEach((item) => {
            result.push(item.pkid);
        });
        return result;
    }
    /**
     * 合并列
     */
    toggleColumnSpan() {
        // 注意：接口定义columns：把所有的新子列传给后台
        const columnsSelected = this.rowsOrColumnsSelected;
        if (!columnsSelected || columnsSelected.length < 2) {
            return { instance: Promise.resolve(void 0) };
        }
        const reqParams = this.getColumnSpanReqParam(columnsSelected);
        if (!reqParams) {
            return { instance: Promise.resolve(void 0) };
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return { instance: Promise.resolve(void 0) };
        }
        const reqUrl = this.convertUrl(ApiUrlSetting.WidgetConfig.PiBaseToolBar.ColumnSpanUrl, UrlType.Api);
        return this.resetAjax('toggleColumnSpan', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, reqParams);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                // 后台请求成功
                if (data.status === PiStatusEnum.Success) {
                    // 后台请求成功
                    const respData = data.data && data.data.length ? data.data[0] : null;
                    if (respData) {
                        this.handleColumnSpanResponse(respData, reqParams, columnsSelected);
                        this.$Message.success('合并选中列成功！');
                        // 清空选中
                        this.storeLayout.cancelSelectedRowsColumns({ tabId });
                    }
                    else {
                        this.$Message.error('合并选中列失败！');
                        // 后台请求失败，emit 错误信息
                        console.error(`${PiBaseToolbar_1.SPAN_COLUMN_FAIL} ${data ? data.msg : res.statusText}`);
                        this.$emit('onSpanColumnError', res);
                    }
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
                message: PiBaseToolbar_1.SPAN_COLUMN_FAIL,
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onSpanColumnError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 处理请求响应 - 合并列
     * @param respData
     * @param reqData
     * @param selectedColumns
     */
    handleColumnSpanResponse(respData, reqData, selectedColumns) {
        if (!respData || !reqData) {
            return;
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        // 1. 删除列
        const deleteColumnPkIds = this.getDeleteColumnPkIds(selectedColumns);
        this.storeLayout.deleteColumns({
            tabId,
            columnPkIds: deleteColumnPkIds,
        });
        // 2. 更新列
        this.storeLayout.updateColumns({
            tabId,
            columns: respData.columns,
        });
        // 3. 更新组件实例
        this.storeWidget.deleteWidgetInstanceInfo({
            tabId,
            columnPkIds: deleteColumnPkIds,
        });
    }
    /**
     * 根据列获取删除列的 pkIds
     * @param columns
     */
    getDeleteColumnPkIds(columns) {
        if (!columns || !columns.length) {
            return [];
        }
        let result = [];
        columns.forEach((item, index) => {
            if (index > 0) {
                result.push(item.pkid);
            }
        });
        return result;
    }
    /**
     * 计算行列组件 attrJSON 中 heightPct / widthPct 总和值
     * @param rowsColumns
     * @param isRow
     */
    calcRowsColumnsWidthHeightPCT(rowsColumns, isRow) {
        if (!rowsColumns) {
            return 0;
        }
        let result = 0;
        rowsColumns.forEach((item) => {
            const tmpAttrJSONObj = item.attrObj;
            if (tmpAttrJSONObj) {
                result += isRow
                    ? tmpAttrJSONObj.heightPct
                    : tmpAttrJSONObj.widthPct;
            }
        });
        return result;
    }
    /**
     * 获取后台请求参数 - 合并列
     */
    getColumnSpanReqParam(columns) {
        if (!columns || columns.length < 2) {
            return;
        }
        let result = {};
        const rowPkId = columns[0].rowPkId;
        result.pkid = rowPkId;
        // 这里的 reqColumns 是合并后的值
        // 生成需删除的列 pkIds
        let deleteColumnPkIds = [];
        let deleteColumnWidthPCTTotal = 0; // 待删除的列宽总值
        columns.forEach((column, index) => {
            if (index > 0) {
                // 统计待删除的列 pkId
                deleteColumnPkIds.push(column.pkid);
                // 统计待删除的列 widthPct
                deleteColumnWidthPCTTotal += Number(column.attrObj.widthPct);
            }
        });
        // 获取当前行下所有列数据
        const combineColumn = columns[0];
        const childrenColumns = this.columns.filter((item) => item.rowPkId === rowPkId);
        let reqColumns = [];
        childrenColumns.forEach((column) => {
            let cloneColumn = Object.assign({}, column);
            if (deleteColumnPkIds.indexOf(column.pkid) === -1) {
                let tmpReqColumn = new ColumnSpanReqParamColumn();
                if (combineColumn.pkid === cloneColumn.pkid) {
                    // 合并的列，需更新 attrJson 中的 widthPct 值
                    tmpReqColumn.pkid = cloneColumn.pkid;
                    tmpReqColumn.orderNo = cloneColumn.orderNo;
                    tmpReqColumn.attrJson = {
                        widthPct: column.attrObj.widthPct + deleteColumnWidthPCTTotal,
                    };
                }
                else {
                    // 其他子列，无需合并
                    tmpReqColumn = new ColumnSpanReqParamColumn(cloneColumn);
                }
                reqColumns.push(tmpReqColumn);
            }
        });
        result.columns = reqColumns;
        return result;
    }
    /**
     * 拆分列
     */
    toggleColumnSplit() {
        // 处理行信息
        const rowsOrColumnsSelected = this.rowsOrColumnsSelected;
        if (!rowsOrColumnsSelected || rowsOrColumnsSelected.length !== 1) {
            return { instance: Promise.resolve(void 0) };
        }
        const splitColumn = rowsOrColumnsSelected[0];
        const reqParams = this.getColumnSplitReqParam(splitColumn);
        if (!reqParams) {
            return { instance: Promise.resolve(void 0) };
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return { instance: Promise.resolve(void 0) };
        }
        const reqUrl = this.convertUrl(ApiUrlSetting.WidgetConfig.PiBaseToolBar.ColumnSplitUrl, UrlType.Api);
        return this.resetAjax('toggleColumnSplit', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, reqParams);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                // 后台请求成功
                if (data.status === PiStatusEnum.Success) {
                    // 后台请求成功
                    const respData = data.data && data.data.length ? data.data[0] : null;
                    if (respData) {
                        this.handleColumnSplitResp(respData, reqParams.pkid);
                        this.$Message.success('拆分选中列成功！');
                    }
                    // 清空选中
                    this.storeLayout.cancelSelectedRowsColumns({ tabId });
                }
                else {
                    this.$Message.error('拆分选中列失败！');
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseToolbar_1.SPLIT_COLUMN_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onSplitColumnError', res);
                }
            })
                .catch((err) => hErrorShow({
                err,
                showInPage: true,
                context: this,
                message: PiBaseToolbar_1.SPLIT_COLUMN_FAIL,
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        this.$emit('onSplitColumnError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 后台请求响应 - 列拆分
     * @param resp
     */
    handleColumnSplitResp(resp, rowPkId) {
        if (!resp) {
            return;
        }
        const tabId = this.curTab.tabId;
        if (!tabId) {
            return;
        }
        // 因为新行不存在于旧 columns 中，updateColumns 无法找到目标列，所以需手动填补所有数据（rowPkId）
        let columns = [];
        resp.columns.forEach((item) => {
            let column = new ColumnEntity(item);
            columns.push(column);
        });
        // 更新列信息
        this.storeLayout.updateColumns({
            tabId,
            columns,
        });
    }
    /**
     * 后台请求参数 - 列拆分
     * @param column 拆分列
     */
    getColumnSplitReqParam(column) {
        if (!column) {
            return;
        }
        // 处理参数 pkid
        let result = {};
        const parentRowPkid = column.rowPkId;
        result.pkid = parentRowPkid;
        // 处理参数 columns
        const columnPkId = column.pkid;
        const columnAttrJSONObj = column.attrObj;
        const newAttrJSON = Object.assign({}, columnAttrJSONObj, {
            widthPct: columnAttrJSONObj.widthPct / 2,
        });
        const siblingColumns = this.columns.filter((item) => {
            item.rowPkId === parentRowPkid;
        });
        let newColumns = [];
        let newOrderNo = 0;
        siblingColumns.forEach((item) => {
            if (item.pkid !== columnPkId) {
                let itemOrderNo = item.orderNo;
                if (newOrderNo !== 0 && newOrderNo >= itemOrderNo) {
                    itemOrderNo += newOrderNo - itemOrderNo + 1;
                }
                newColumns.push(new ColumnSplitReqParamColumn({
                    pkid: item.pkid,
                    orderNo: itemOrderNo,
                    attrJson: item.attrJson,
                }));
            }
            else {
                const curOrderNo = item.orderNo;
                newOrderNo = curOrderNo + 1;
                newColumns.push(...[
                    {
                        pkid: item.pkid,
                        orderNo: curOrderNo,
                        attrJson: newAttrJSON,
                    },
                    {
                        orderNo: newOrderNo,
                        attrJson: newAttrJSON,
                    },
                ].map((p) => new ColumnSplitReqParamColumn(p)));
            }
        });
        result.columns = newColumns;
        return result;
    }
};
__decorate([
    Prop({
        default: true,
    })
], PiBaseToolbar.prototype, "showRowSpan", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBaseToolbar.prototype, "showRowSplit", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBaseToolbar.prototype, "showRowDelete", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBaseToolbar.prototype, "showColumnSplit", void 0);
__decorate([
    Prop({
        default: true,
    })
], PiBaseToolbar.prototype, "showColumnSpan", void 0);
__decorate([
    Watch('rowsOrColumnsSelected', { deep: true, immediate: true })
], PiBaseToolbar.prototype, "watch.rowsOrColumnsSelected", null);
PiBaseToolbar = PiBaseToolbar_1 = __decorate([
    Component({
        components: {
            Icon,
            ButtonGroup,
            Button,
        },
        template,
    })
], PiBaseToolbar);
export default PiBaseToolbar;
