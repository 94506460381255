var PiBaseVideoCtrlPlay1_1;
import { __decorate } from "tslib";
import { BcVersion, VueBase } from 'bc$/main';
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import PiBaseVideo from 'bc$/components/pi-base-video/pi-base-video.component';
import template from './pi-base-video-ctrl-play-1.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-video-ctrl-play-1.component.scss';
let PiBaseVideoCtrlPlay1 = PiBaseVideoCtrlPlay1_1 = class PiBaseVideoCtrlPlay1 extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseVideoCtrlPlay1';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseVideoCtrlPlay1_1?.NAME,
        author: 'yangd',
        title: '视频播放组件',
        version: BcVersion,
        updateDate: '2020-10-09',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 视频地址
     */
    videoUrl;
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseVideoCtrlPlay1_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
};
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseVideoCtrlPlay1.prototype, "videoUrl", void 0);
PiBaseVideoCtrlPlay1 = PiBaseVideoCtrlPlay1_1 = __decorate([
    Component({
        template,
        mixins: [WindowEventMixin],
        components: {
            PiBaseVideo,
        },
    })
], PiBaseVideoCtrlPlay1);
export default PiBaseVideoCtrlPlay1;
