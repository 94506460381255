import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { VueMixinBase } from './vue-mixin.base';
/**
 * 右键菜单
 */
let SvgPointRuleParserMixin = class SvgPointRuleParserMixin extends VueMixinBase {
    /**
     * svg设备点规则转换算法
     *
     * @param value
     * @param listCondition
     */
    convertValue(data, listCondition) {
        const { alarm, // 是否告警
        valid, // 有效/无效
         } = data;
        // 处理特殊情况下 value 值存在非数值类型情况
        let value = 1 * data.value;
        const dict = {
            '0': (m, n) => {
                return value === m;
            },
            '1': (m, n) => {
                return value !== m;
            },
            '2': (m, n) => {
                return value < m;
            },
            '3': (m, n) => {
                return value > m;
            },
            '4': (m, n) => {
                return value <= m;
            },
            '5': (m, n) => {
                return value >= m;
            },
            '6': (m, n) => {
                return value >= m && value <= n;
            },
            '7': (m, n) => {
                return value > m && value < n;
            },
            '8': (m, n) => {
                return value > m && value <= n;
            },
            '9': (m, n) => {
                return value >= m && value < n;
            },
            '10': (m, n) => {
                return value < m || value > n;
            },
            '11': (m, n) => {
                return value <= m || value >= n;
            },
            '12': (m, n) => {
                return value < m || value >= n;
            },
            '13': (m, n) => {
                return value <= m || value > n;
            },
            '14': (m, n) => {
                return valid === false;
            },
            '15': (m, n) => {
                return alarm === true;
            },
            '16': (m, n) => {
                return valid === true;
            },
        };
        // 处理 listCondition 不存在
        if (listCondition) {
            for (const cond of listCondition) {
                const { Condition: conditionDef, LimitM: limitMDef, LimitN: limitNDef, } = cond.key;
                const { value: valueDef } = cond;
                if (dict[`${conditionDef}`]?.(limitMDef, limitNDef)) {
                    return valueDef;
                }
            }
        }
        return '';
    }
};
SvgPointRuleParserMixin = __decorate([
    Component
], SvgPointRuleParserMixin);
export default SvgPointRuleParserMixin;
