import { addListener } from './listener';
/**
 * 过滤掉非键盘能输入的字符
 * @param el
 */
export const passwordFilterAdd = (el, tagName) => {
    const reg = /[^a-zA-Z\d~!@#\$%\^&\*\(\)_\+\{\}\|:"<>\?`\-=\[\]\\;',\./ ]/g;
    const vnode = el.__vue__;
    const callback = () => {
        if (tagName === 'Input') {
            const value = vnode.value.replace(reg, '');
            vnode.$emit('input', value);
        }
        else {
            el.value = el.value.replace(reg, '');
        }
    };
    if (tagName === 'Input') {
        vnode.$on('on-keyup', callback);
        vnode.$on('on-blur', callback);
    }
    else {
        addListener(el, 'keyup', callback);
        addListener(el, 'blur', callback);
        el.__input_filter__ = {
            keyup: callback,
            blur: callback,
        };
    }
};
