import { __decorate } from "tslib";
import { hConvertAttr } from 'bc$/main';
import { PiUndefined } from 'bc$/decorators/pi-undefined.decorator';
class WidgetBaseEntity {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 组件标题
     */
    title;
    /**
     * 是否显示标题
     */
    hasTitle;
    /**
     * 组件透明度
     */
    opacity;
    /**
     * 组件皮肤
     */
    skin;
    /**
     * 组件图标
     */
    icon;
}
__decorate([
    PiUndefined()
], WidgetBaseEntity.prototype, "title", void 0);
__decorate([
    PiUndefined()
], WidgetBaseEntity.prototype, "hasTitle", void 0);
__decorate([
    PiUndefined()
], WidgetBaseEntity.prototype, "opacity", void 0);
__decorate([
    PiUndefined()
], WidgetBaseEntity.prototype, "skin", void 0);
__decorate([
    PiUndefined()
], WidgetBaseEntity.prototype, "icon", void 0);
export class WidgetEntity extends WidgetBaseEntity {
    constructor(options) {
        super(options);
    }
    /**
     * 分组主键
     */
    widgetGroupPkId;
    /**
     * 组件主键
     */
    pkid;
    /**
     * 组件注册时用的英文名称
     */
    templateName;
    /**
     * 组件注册时用的中文名称
     */
    widgetName;
}
__decorate([
    PiUndefined()
], WidgetEntity.prototype, "widgetGroupPkId", void 0);
__decorate([
    PiUndefined()
], WidgetEntity.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], WidgetEntity.prototype, "templateName", void 0);
__decorate([
    PiUndefined()
], WidgetEntity.prototype, "widgetName", void 0);
/**
 * 组件实例
 */
export class WidgetInstanceEntity extends WidgetBaseEntity {
    constructor(options) {
        super(options);
    }
    /**
     * 组件实例主键
     */
    pkid;
    /**
     * 组件类主键
     */
    widgetPkId;
    /**
     * 组件所属栅格列
     */
    columnPkId;
    /**
     * 自定义数据,序列化后的字符串
     */
    attrJson;
    /**
     * 自定义数据对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], WidgetInstanceEntity.prototype, "pkid", void 0);
__decorate([
    PiUndefined()
], WidgetInstanceEntity.prototype, "widgetPkId", void 0);
__decorate([
    PiUndefined()
], WidgetInstanceEntity.prototype, "columnPkId", void 0);
__decorate([
    PiUndefined()
], WidgetInstanceEntity.prototype, "attrJson", void 0);
