var PiBaseCard_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './pi-base-card.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-card.component.scss';
let PiBaseCard = PiBaseCard_1 = class PiBaseCard extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseCard';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseCard_1?.NAME,
        author: 'hex',
        title: '卡片',
        version: BcVersion,
        updateDate: '2020-05-21',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 标题
     */
    title;
    /**
     * 标题样式
     */
    titleStyle;
    /**
     * 标题标志
     */
    titleTag;
    //#endregion
    //#region data
    //#endregion;
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseCard_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return PiBaseCard_1.ABOUT.title;
    }
};
__decorate([
    Prop({ type: String })
], PiBaseCard.prototype, "title", void 0);
__decorate([
    Prop({ type: Object })
], PiBaseCard.prototype, "titleStyle", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], PiBaseCard.prototype, "titleTag", void 0);
PiBaseCard = PiBaseCard_1 = __decorate([
    Component({
        template,
    })
], PiBaseCard);
export default PiBaseCard;
