/**
 * 字符串转 asccii 码
 *@param str 待转换的字符串
 */
function hSStr2Ascii(str) {
    let strResult = '';
    for (let i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        strResult += code;
    }
    return strResult;
}
/**
 * 字符串转 asccii 码并将值累加
 *@param str 待转换的字符串
 */
function hSStr2AsciiTotal(str) {
    let iResult = 0;
    for (let i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        iResult += code;
    }
    return iResult;
}
/**
 * 十六进制转为RGBA
 * @param hex
 * @param opacity
 */
function hSHex2Rgba(hex, opacity = 0.7) {
    //十六进制转为RGB
    if (/^\#[0-9A-F]{8}$/i.test(hex)) {
        //判断传入是否为#三位十六进制数
        return hex;
    }
    const rgb = []; // 定义rgb数组
    if (/^\#[0-9A-F]{3}$/i.test(hex)) {
        //判断传入是否为#三位十六进制数
        let sixHex = '#';
        hex
            .substr(1)
            .match(/[0-9A-F]/gi)
            .forEach((kw) => {
            sixHex += kw + kw;
        });
        hex = sixHex; //保存回hex
    }
    if (/^#[0-9A-F]{6}$/i.test(hex)) {
        //判断传入是否为#六位十六进制数
        hex
            .substr(1)
            .match(/[0-9A-F]{2}/gi)
            .forEach((kw) => {
            rgb.push(parseInt('0x' + kw)); //十六进制转化为十进制并存如数组
        });
        return `rgba(${rgb.join(',')},${opacity})`; //输出RGB格式颜色
    }
    else {
        return `rgba(0,0,0,${opacity})`;
    }
}
/**
 * 字符串转码
 * @param value 需要转码的字符
 * @param isReplaceSlant 是否转换斜杠
 */
function hSPiEncodeURI(value, isReplaceSlant = false) {
    let result = value
        .replace('%', '%25')
        .replace('+', '%2B')
        .replace(' ', '%20')
        .replace('?', '%3F')
        .replace('#', '%23')
        .replace('&', '%26')
        .replace('=', '%3D');
    isReplaceSlant && (result = result.replace('/', '%2F'));
    return result;
}
/**
 * 字符串解码
 * @param value 需要转码的字符
 * @param isReplaceSlant 是否转换斜杠
 */
function hSPiDecodeURI(value, isReplaceSlant = false) {
    let result = value
        .replace(/\%25/g, '%')
        .replace(/\%2B/g, '+')
        .replace(/\%20/, ' ')
        .replace(/\%3F/g, '?')
        .replace(/\%23/g, '#')
        .replace(/\%26/g, '&')
        .replace(/\%3D/g, '=');
    isReplaceSlant && (result = result.replace(/\%2F/g, '/'));
    return result;
}
export { hSStr2Ascii, hSStr2AsciiTotal, hSHex2Rgba, hSPiEncodeURI, hSPiDecodeURI, };
