import { __decorate } from "tslib";
import { PageSessionStorageEnum } from 'bc$/enums/page/page.enum';
import { hSessionPublic } from 'bc$/main';
import { Store } from 'bc$/store/store';
import { getModule, Module, Mutation, VuexModule, } from 'vuex-module-decorators';
import { PiBaseStoreLayoutModule } from './pi-base-layout.store';
import { PiBaseStoreWidgetModule } from './pi-base-widget.store';
/**
 * 页面菜单树根节点 PkId
 */
const ROOT_PAGE_PKID = '0';
/**
 * 当前累加到的页面tabId
 */
let tabId = 0;
let PiBaseStorePage = class PiBaseStorePage extends VuexModule {
    /**
     * 是否为全屏状态
     */
    _isFullScreen = false;
    /**
     * 是否允许同一个页面显示多次,也就是多开
     */
    isOpenMultiple = true;
    /**
     * 当前系统id
     */
    systemId = '';
    /**
     * 当前页面的父级页面信息
     */
    curPageParents = [];
    /**
     * 所有打开的页面,可以重复
     */
    tabs = [];
    /**
     * 当前页面信息
     */
    curTab = {};
    /**
     * 所有页面信息
     */
    allPages = [];
    /**
     * 正在路由跳转中
     */
    isJumping = false;
    /**
     * 页面全屏状态
     *
     * @param tab
     */
    get isFullScreen() {
        let data = this._isFullScreen;
        if (typeof data !== 'boolean') {
            const str = hSessionPublic.getItem(PageSessionStorageEnum.Fullscreen);
            data = Boolean(str);
            store.setIsFullScreen({ isFullScreen: data });
        }
        return data;
    }
    /**
     * 设置是否允许同一个页面显示多次,也就是多开
     * @param isOpenMultiple 系统id
     */
    setIsOpenMultiple(state) {
        this.isOpenMultiple = !!state.isOpenMultiple;
    }
    /**
     * 设置当前活动系统id
     * @param systemId 系统id
     */
    setCurSystemId(state) {
        this.systemId = state.systemId;
    }
    /**
     * 清空 Store 数据
     */
    emptyStore() {
        //移除组件实例store中全部页面
        PiBaseStoreWidgetModule.emptyStore();
        //移除布局store中全部页面
        PiBaseStoreLayoutModule.emptyStore();
        this.curPageParents.splice(0);
        this.curTab = {};
        this.tabs.splice(0);
        this.allPages.splice(0);
        // this.systemId = '';
    }
    /**
     * 添加选中的页面并设置为当前页
     * @param tab
     */
    addTab(state) {
        const { tab } = state;
        let targetTab = undefined;
        //检测多开条件
        if (!this.isOpenMultiple) {
            targetTab = this.tabs.find((p) => p.pkid === tab.pkid);
        }
        //能多开或者没有指定页面未打开则新开一个页面
        if (!targetTab) {
            const targetTabId = `${++tabId}`;
            targetTab = Object.assign({
                tabId: targetTabId,
            }, tab);
            this.tabs.push(targetTab);
            //添加到组件实例store
            PiBaseStoreWidgetModule.addTab({ tabId: targetTabId });
            //添加到布局store
            PiBaseStoreLayoutModule.addTab({ tabId: targetTabId });
        }
        store.setCurTab({ tabId: targetTab.tabId });
        return targetTab;
    }
    /**
     * 设置祖先页面到数组供面包屑导航使用
     */
    setPageParents() {
        let result = [];
        const { curTab, allPages, curPageParents } = this;
        if (allPages.length && curTab.pkid) {
            //获取父页面信息
            const getParentPageInfo = (result, pagePkid) => {
                if (pagePkid || pagePkid === ROOT_PAGE_PKID) {
                    const tmpPageInfo = allPages.find((item) => item.pkid === pagePkid);
                    if (tmpPageInfo) {
                        result.unshift(tmpPageInfo);
                        getParentPageInfo(result, tmpPageInfo.parentPkId);
                    }
                }
            };
            getParentPageInfo(result, curTab.pkid);
        }
        //设置新的值
        if (curPageParents.length || result.length) {
            curPageParents.splice(0);
            curPageParents.push(...result);
        }
    }
    /**
     * 设置当前页
     * @param page
     */
    setCurTab(state) {
        const { tabId } = state;
        const curPage = this.tabs.find((p) => p.tabId === tabId);
        if (curPage) {
            this.curTab = curPage;
            //设置祖先页面
            store.setPageParents();
        }
    }
    /**
     * 关闭打开的指定页面
     */
    removeTab(state) {
        const { tabId } = state;
        //移除组件实例
        PiBaseStoreWidgetModule.removeTab({ tabId });
        //移除布局
        PiBaseStoreLayoutModule.removeTab({ tabId });
        //选中的页面是否包含该页面
        const { tabs: selectedPages } = this;
        const index = this.tabs.findIndex((p) => p.tabId === tabId);
        if (!~index) {
            return;
        }
        selectedPages.splice(index, 1);
        //删除该页面后没有其他页面
        if (selectedPages.length <= 0) {
            this.curPageParents.splice(0);
            this.curTab = {};
            return;
        }
        //删除的是当前页面
        if (this.curTab.tabId === tabId) {
            const newIndex = index >= selectedPages.length ? index - 1 : index;
            const newTabId = selectedPages[newIndex].tabId;
            store.setCurTab({ tabId: newTabId });
            return;
        }
    }
    /**
     * 存储所有页面信息
     * @param data
     */
    setAllPages(state) {
        const { data } = state;
        this.allPages.splice(0, this.allPages.length, ...data);
        //设置祖先页面
        store.setPageParents();
    }
    /**
     * 页面点击修改页面跳转
     * @param tab
     */
    setIsJumping(state) {
        const { isJump } = state;
        this.isJumping = isJump;
        return this.isJumping;
    }
    /**
     * 设置全屏
     *
     * @param isFullScreen
     */
    setIsFullScreen(state) {
        const { isFullScreen } = state;
        this._isFullScreen = isFullScreen;
        hSessionPublic.setItem(PageSessionStorageEnum.Fullscreen, `${isFullScreen}`);
    }
};
__decorate([
    Mutation
], PiBaseStorePage.prototype, "setIsOpenMultiple", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "setCurSystemId", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "emptyStore", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "addTab", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "setPageParents", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "setCurTab", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "removeTab", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "setAllPages", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "setIsJumping", null);
__decorate([
    Mutation
], PiBaseStorePage.prototype, "setIsFullScreen", null);
PiBaseStorePage = __decorate([
    Module({ namespaced: true, dynamic: true, store: Store, name: 'piBasePage' })
], PiBaseStorePage);
export { PiBaseStorePage };
const store = getModule(PiBaseStorePage);
export { store as PiBaseStorePageModule };
