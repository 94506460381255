import { __decorate } from "tslib";
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
let ScrollBarControlMixin = class ScrollBarControlMixin extends Vue {
    scrollbarWidth = 0;
    // 如果存在footer 则横向滚动条体现在footer上
    controlScrollBar() {
        const that = this;
        if (that.hasTableFooter) {
            const body = this.$el.querySelector('.v-table-rightview .v-table-body');
            body.style.overflowX = 'hidden';
        }
    }
    /**
     * 计算设置滚动条宽度
     * 因为采用虚拟滚动条,此处的函数实现代码被注释
     */
    setScrollbarWidth() {
        // if (this.scrollWidth !== Infinity) {
        //   this.scrollbarWidth = this.scrollWidth;
        // } else {
        //   this.scrollbarWidth = Utils.getScrollbarWidth();
        // }
    }
};
ScrollBarControlMixin = __decorate([
    Component
], ScrollBarControlMixin);
export default ScrollBarControlMixin;
