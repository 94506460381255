var PiBaseTree_1;
import { __decorate } from "tslib";
import { VueBase } from 'bc$/bases/vue.base';
import { hODeepObjectMerge } from 'bc$/utils/object.helper';
import { Tree, } from 'tree-vue-component';
import Vue from 'vue';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-tree.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-tree.component.scss';
import { clearSelectionOfTree, collectSelectedTree, setParentsSelection, setSelectionOfTree, } from './pi-base-tree.utils';
// tslint:disable-next-line: no-import-side-effect
import './theme-pilot/theme-pilot.scss';
let PiBaseTree = PiBaseTree_1 = class PiBaseTree extends Mixins(VueBase, Vue.extend({
    methods: {
        clearSelectionOfTree,
        setSelectionOfTree,
        setParentsSelection,
        collectSelectedTree,
    },
})) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseTree';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseTree_1?.NAME,
        author: 'hex&yangd',
        title: '树组件',
        version: require('bc$/../package.json').version,
        updateDate: '2020-07-13',
        description: '',
    };
    /**
     * 默认树配置
     * @param text
     * @param value
     */
    static DEFAULT_OPTIONS_DATA = {
        text: '',
        value: null,
        icon: false,
        state: {
            opened: false,
            selected: false,
            loading: false,
            disabled: false,
            openable: false,
            highlighted: false,
            dropPosition: 0,
            dropAllowed: false,
        },
        children: [],
    };
    //#endregion
    //#region props
    /**
     * Tree 原有属性和自定制属性
     * @description Tree Props
     */
    options;
    /**
     * 是否由组件自动控制树节点状态
     */
    autoState;
    /**
     * checkbox情况下,选择节点是否关联父子节点
     */
    checkboxLink;
    /**
     * 提供cbToggle情况下，是否递归到子节点,且选择第一个子节点
     */
    selectFirstChild;
    /**
     * 直接return子节点数据数组 或 return promise（需要resolve子节点数据数组）
     * 该帮助函数会将子节点数据自动插入当前节点的子节点数组中
     */
    cbToggle;
    //#endregion
    //#region data
    optionsInner = {
        data: [],
    };
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseTree_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return PiBaseTree_1.ABOUT.title;
    }
    //#endregion
    //#region 函数类属性
    //#endregion
    //#region watch
    'watch.options.data'(newVal) {
        const data = this.mergeDefaultData(newVal);
        if (this.selectFirstChild) {
            this.firstRecursionLoad(data);
        }
        this.optionsInner.data = data;
    }
    //#endregion
    //#region static methods
    //#endregion
    //#region methods
    /**
     * 根据默认配置补齐剩余项
     * @param oldData
     */
    mergeDefaultData(oldData) {
        if (!oldData?.length) {
            return [];
        }
        const defaultOptionsData = PiBaseTree_1.DEFAULT_OPTIONS_DATA;
        const data = oldData.map((p) => {
            const result = {};
            hODeepObjectMerge(result, defaultOptionsData, p);
            const children = this.mergeDefaultData(result.children);
            result.children = children;
            return result;
        });
        return data;
    }
    firstRecursionLoad(data) {
        if (data && data.length) {
            const firstNode = data[0];
            // 可展开
            if (firstNode && firstNode.state.openable) {
                const mockEventData = {
                    path: [0],
                    data: firstNode,
                };
                this.recursionFetchChildren(mockEventData);
            }
        }
    }
    /**
     * 递归获取字节点数据
     * @param eventData
     */
    recursionFetchChildren(eventData) {
        if (!eventData || !eventData.data) {
            return;
        }
        // 未提供子节点获取函数，返回
        if (!this.cbToggle) {
            return;
        }
        const { state, children } = eventData.data;
        // 节点不可展开，返回
        if (!state.openable) {
            return;
        }
        const result = this.cbToggle(eventData);
        if (result instanceof Promise) {
            state.loading = true;
            result
                .then((childNodes) => {
                children.push(...this.mergeDefaultData(childNodes));
                // 继续获取第一个子节点
                const firstChild = children[0];
                const newPath = Array.from(eventData.path);
                newPath.push(0);
                const mockEventData = {
                    path: newPath,
                    data: firstChild,
                };
                if (firstChild.state.openable) {
                    this.recursionFetchChildren(mockEventData);
                }
                else {
                    // 推送选择事件
                    this.change(mockEventData);
                }
            })
                .finally(() => {
                state.loading = false;
                state.opened = true;
            })
                .catch();
        }
        // 返回节点数组
        else if (Array.isArray(result)) {
            const childNodes = result;
            children.push(...this.mergeDefaultData(childNodes));
            // 继续获取第一个子节点
            const firstChild = children[0];
            const newPath = Array.from(eventData.path);
            newPath.push(0);
            const mockEventData = {
                path: newPath,
                data: firstChild,
            };
            if (firstChild.state.openable) {
                this.recursionFetchChildren(mockEventData);
            }
            else {
                // 推送选择事件
                this.change(mockEventData);
            }
            state.opened = true;
        }
    }
    /**
     * triggered when selecting or deselecting a node
     * @description Tree Event
     */
    change(eventData) {
        if (this.autoState) {
            // 多选：切换节点选择状态
            if (this.options && this.options.checkbox) {
                if (this.checkboxLink) {
                    this.handleCheckboxChangeWithLink(eventData);
                }
                else {
                    this.handleCheckboxChangeWithoutLink(eventData);
                }
            }
            // 单选：切换节点选择状态
            else {
                this.handleSelectChange(eventData);
            }
            const selectedTreeList = collectSelectedTree(this.optionsInner.data);
            // 第三个参数，为已选择项数组
            this.$emit('on-change', eventData, selectedTreeList);
        }
        else {
            this.$emit('on-change', eventData);
        }
    }
    /**
     * triggered when opening or closing a node
     * @description Tree Event
     */
    toggle(eventData) {
        if (this.autoState) {
            // 自动展开 或 补充子集数据
            this.handleToggle(eventData);
        }
        else {
            this.$emit('on-toggle', eventData);
        }
    }
    /**
     * triggered when drag a node, then drop it
     * @description Tree Event
     */
    drop(dropData) {
        this.$emit('on-drop', dropData);
    }
    /**
     * triggered when drag target changed
     * @description Tree Event
     */
    changeDragTarget(dragTarget) {
        this.$emit('on-change-drag-target', dragTarget);
    }
    /**
     * 处理折叠/展开
     * @param eventData
     */
    handleToggle(eventData) {
        const { state, children } = eventData.data;
        if (state.loading) {
            return;
        }
        return new Promise((resolve, reject) => {
            // case1：已有子节点数据
            if (children.length > 0) {
                state.opened = !state.opened;
                return resolve(void 0);
            }
            // case2: 需要加载数据
            const promise = this.cbToggle?.(eventData);
            if (!promise) {
                return resolve(void 0);
            }
            // 异步返回promise
            if (promise instanceof Promise) {
                state.loading = true;
                promise
                    .then((childrenNew) => {
                    children.push(...this.mergeDefaultData(childrenNew));
                    state.opened = !state.opened;
                })
                    .finally(() => {
                    state.loading = false;
                    resolve(void 0);
                })
                    .catch((err) => {
                    console.error(err);
                });
            }
            // 返回节点数组
            else if (Array.isArray(promise)) {
                const childrenNew = promise;
                children.push(...this.mergeDefaultData(childrenNew));
                state.opened = !state.opened;
                return resolve(void 0);
            }
            else {
                console.warn('cbToggle 必须返回TreeData[] 或者 Promise<TreeData<any>[]>');
                return resolve(void 0);
            }
        })
            .then(() => {
            this.$emit('on-toggle', eventData);
        })
            .catch((err) => {
            console.error(err);
        });
    }
    /**
     * 处理单选选择事件
     */
    handleSelectChange(eventData) {
        const { state } = eventData.data;
        if (!state.selected) {
            for (let item of this.optionsInner.data) {
                clearSelectionOfTree(item);
            }
        }
        state.selected = !state.selected;
    }
    /**
     * 处理多选选择事件
     * checkbox情况下，选择节点是否关联父子节点: 是
     */
    handleCheckboxChangeWithLink(eventData) {
        setSelectionOfTree(eventData.data, !eventData.data.state.selected);
        setParentsSelection(this.optionsInner.data, eventData.path);
    }
    /**
     * 处理多选选择事件
     * checkbox情况下，选择节点是否关联父子节点：否
     */
    handleCheckboxChangeWithoutLink(eventData) {
        eventData.data.state.selected = !eventData.data.state.selected;
    }
};
__decorate([
    Prop({
        type: Object,
    })
], PiBaseTree.prototype, "options", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseTree.prototype, "autoState", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], PiBaseTree.prototype, "checkboxLink", void 0);
__decorate([
    Prop({ type: Boolean })
], PiBaseTree.prototype, "selectFirstChild", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseTree.prototype, "cbToggle", void 0);
__decorate([
    Watch('options.data', { immediate: true, deep: true })
], PiBaseTree.prototype, "watch.options.data", null);
PiBaseTree = PiBaseTree_1 = __decorate([
    Component({
        components: {
            Tree: Tree,
        },
        template,
    })
], PiBaseTree);
export default PiBaseTree;
