import { hNumToFixed } from 'bc$/main';
import { default as PiBaseColumn } from '../pi-base-column/pi-base-column.component';
import { default as PiBaseRow } from '../pi-base-row/pi-base-row.component';
const SORTABLE_ACTIVE_CLASS_NAME = 'pi-sortable-active';
/**
 * 根据行列 attrJson 解析出 grid 布局值
 * @param rowsOrColumns 行列数据集合
 * @param isRow 是否为行
 * @param minSize 高/宽 最小值 minmax(xxx, 50px)
 */
const getGridTemplate = function (rowsOrColumns, isRow, minSize = '50px') {
    if (!rowsOrColumns || !rowsOrColumns.length) {
        return {
            template: '',
            total: 0,
        };
    }
    const defaultUnit = '_DEFAULT_';
    const valUnit = 'fr';
    let gridTemplateValues = [];
    let calcPctVal = 0; // 统计现在百分比值是否超过 100 % 或者 24 fr
    rowsOrColumns.forEach(item => {
        try {
            const attrJsonObj = item.attrObj;
            if (!attrJsonObj ||
                !(attrJsonObj.hasOwnProperty('heightPct') ||
                    attrJsonObj.hasOwnProperty('widthPct'))) {
                // 未定义高度值，默认为 1fr, 高度由剩余空间平分
                gridTemplateValues.push(`minmax(${minSize}, ${defaultUnit})`);
            }
            else {
                const PctValue = attrJsonObj.heightPct || attrJsonObj.widthPct;
                // 判断 heightPct 是否为有效的数字
                if (Number(PctValue) !== NaN) {
                    calcPctVal += PctValue;
                    gridTemplateValues.push(`minmax(${minSize}, ${PctValue}${valUnit})`);
                }
            }
        }
        catch (error) {
            gridTemplateValues.push(`minmax(${minSize}, ${defaultUnit})`);
            console.error(`Parse row/column data "${item.pkid}" attrJson fail "${item.attrJson}": ${error}`);
        }
    });
    let result = gridTemplateValues.join(' ');
    // 初始化未定义行高的行高/列宽值 PCT
    const maxVal = isRow
        ? PiBaseRow.DEFAULT_ROW_HEIGHT
        : PiBaseColumn.MAX_COLUMN_WIDTH;
    // 存在超出一屏幕高度的页面布局场景，所以判断是否小于 100 or 24
    const noDefData = gridTemplateValues.filter(item => item === defaultUnit);
    const noDefDataLen = noDefData.length;
    let noDefPCT = '0';
    if (noDefData && noDefData.length > 0) {
        noDefPCT = maxVal + '';
        if (calcPctVal < maxVal) {
            noDefPCT = hNumToFixed((maxVal - calcPctVal) / noDefDataLen, isRow ? 2 : 0);
        }
        // 统计最大值(包含未定义得高宽值)
        calcPctVal += noDefDataLen * Number(noDefPCT);
        const regX = new RegExp(defaultUnit, 'g');
        result = result.replace(regX, `${noDefPCT}${valUnit}`);
    }
    return {
        template: result,
        total: calcPctVal,
    };
};
/**
 * 还原 dom
 * 用于解决 sortableJs 和 vue 虚拟 dom 更新冲突
 * @param toHel 目标dom
 * @param fromHel 来源dom
 * @param toIndex 目标index
 * @param fromIndex 来源index
 * @param isClone 是否使用克隆
 */
const revertDom = function (eleTo, eleFrom, indexTo, indexFrom, isClone = false) {
    if (!eleTo || !eleTo.children || !eleTo.children.length || !eleFrom) {
        return;
    }
    const toElement = eleTo.children[indexTo];
    eleTo.removeChild(toElement);
    eleTo.classList.remove(SORTABLE_ACTIVE_CLASS_NAME);
    // 把删除元素还原回旧位置
    !isClone && eleFrom.insertBefore(toElement, eleFrom.children[indexFrom]);
};
/**
 * 根据 grid-template-rows/columns 计算和值(单位均为 fr / % 才可用)
 *
 * 支持的格式：'minmax(50px, 50fr) minmax(50px, 40fr)' 或者 '50fr 40fr'
 *
 * @param templateVal
 */
const getGridTemplatePctValuesCalc = function (templateVal) {
    if (!templateVal || !templateVal.match(/\s?(\d+(fr|%)\s?){1}/g)) {
        console.warn(`grid template parse fail: ${templateVal}`);
        return 0;
    }
    // 处理 无效数据
    // const valuesStr = templateVal.replace(/(fr|%)/g, '');
    const valuesStr = templateVal
        .replace(/(minmax\(\d+(px|fr|%),\s?)/g, '')
        .replace(/(fr\)?|%\)?)/g, '');
    const totalGridValues = valuesStr.split(/\s/);
    let result = 0;
    totalGridValues.forEach(item => {
        try {
            result += Number(item);
        }
        catch (e) {
            console.warn(`Parse Number fail: ${item}, ${e}`);
        }
    });
    return result;
};
export { getGridTemplate, SORTABLE_ACTIVE_CLASS_NAME, revertDom, getGridTemplatePctValuesCalc, };
