import { hODeepObjectMerge, hODeepClone, WidgetInstanceEntity, } from 'bc$/main';
/**
 * attrJson转换合并函数
 */
function hAttrMerge(valueDefault, valueNew) {
    const attrJsonDefault = valueDefault.attrJson;
    let attrObjDefault;
    if (typeof attrJsonDefault === 'string') {
        attrObjDefault = JSON.parse(attrJsonDefault);
    }
    else {
        attrObjDefault = attrJsonDefault;
    }
    const attrJsonNew = valueNew.attrJson || '{}';
    let attrObjNew;
    if (typeof attrJsonNew === 'string') {
        attrObjNew = JSON.parse(attrJsonNew);
    }
    else {
        attrObjNew = attrJsonNew;
    }
    const attrJson = JSON.stringify(hODeepObjectMerge(attrObjDefault, attrObjNew));
    const result = hODeepObjectMerge(hODeepClone(valueDefault), valueNew, {
        attrJson,
    });
    const wie = new WidgetInstanceEntity(result);
    return wie;
}
export { hAttrMerge };
