var PiBaseLogin_1;
import { __decorate } from "tslib";
import { BcVersion, UrlType } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { PiStatusEnum } from 'bc$/main';
import { PiBaseStoreUserModule } from 'bc$/store/modules/pi-base-user.store';
import { GetGlobalConfig } from 'bc$/urls';
import { hAjaxPost, hCrypSha256, hErrorShow, hLocalPrivate, hODeepClone, hODeepObjectMerge, } from 'bc$/utils';
import { hAjaxGet, hAjaxGetJson } from 'bc$/utils/ajax.helper';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import { Button, Checkbox, CheckboxGroup, Col, Form, FormItem, Icon, Input, Row, Switch, } from 'iview';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-login.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-login.component.scss';
let PiBaseLogin = PiBaseLogin_1 = class PiBaseLogin extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseLogin';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseLogin_1?.NAME,
        author: 'caiqw',
        title: '登录组件',
        version: BcVersion,
        updateDate: '2019-06-04',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 登录地址
     */
    loginUrl;
    /**
     * 获取验证码图片地址
     */
    verificationCodeImgUrl;
    /**
     * 获取查询登录错误次数地址
     */
    wrongTimesUrl;
    /**
     * 全局配置url地址
     */
    globalConfigUrl;
    /**
     * 获取CDN/API/File接口域名或IP地址端口列表地址
     */
    globalUrlsUrl;
    /**
     * 登录成功时执行二次验证函数
     */
    secondaryValidate;
    /**
     * 自定义验证规则
     */
    rules;
    /**
     * 自定义样式
     */
    styles;
    /**
     * 样式名称
     */
    className;
    /**
     * 系统名称
     */
    systemName;
    /**
     * 登录框外层class名称
     */
    loginWarpClassName;
    /**
     * 登录参数字段
     */
    loginParamsData;
    /**
     * 错误次数参数字段
     */
    wrongTimeParamsData;
    /**
     * 验证码图片参数字段
     */
    verificationCodeImgPramsData;
    /**
     * 登录框外层class名称
     */
    placeholder;
    /**
     * 注册自定义名称
     */
    registerTxt;
    /**
     * 忘记密码是否显示
     */
    isShowForget;
    /**
     * 忘记密码自定义名称~
     */
    forgetTxt;
    /**
     * 标题的位置配置
     */
    titlePositionConfig;
    loginPositionConfig;
    /**
     * 加密次数
     */
    crypCount;
    /**
     * 获取全局配置后的回调
     */
    cbGlobalConfig;
    //#endregion
    //#region data
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    options = {
        titlePre: '',
        title: PiBaseLogin_1.ABOUT.title,
        titleSuf: '',
        isShowDownLoad: false,
    };
    isAutoLogin = false;
    loading = false;
    formItem = {
        userName: '',
        password: '',
        verificationCode: '',
    };
    /**
     * 账户名修改前
     */
    curUserName = '';
    /**
     * 验证码图片
     */
    verificationCodeImg = '';
    /**
     * 密码输入错误次数
     */
    wrongTimes = 0;
    /**
     * 标题定位样式
     */
    titlePositionStyle = {};
    /**
     * 登录框定位配置
     */
    loginPositionStyle = {};
    /**
     * 表单字段keys
     */
    formItemKeys = ['userName', 'password', 'verificationCode'];
    /**
     * 默认验证规则
     */
    ruleValidate = {
        userName: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        password: [
            {
                required: true,
                message: '密码不能为空',
                trigger: 'blur',
            },
        ],
    };
    //#endregion
    //#region computed
    get about() {
        return PiBaseLogin_1.ABOUT;
    }
    get titleAll() {
        const options = this.options;
        return `${options.titlePre}${options.title}${options.titleSuf}`;
    }
    //#endregion
    //#region watch
    'watch.rules'(newVal, oldVal) {
        hODeepObjectMerge(this.ruleValidate, newVal);
    }
    'watch.titlePositionConfig'(newVal, oldVal) {
        const keys = Object.keys(newVal);
        if (keys.length && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.setPosition(keys, newVal, this.titlePositionStyle);
        }
    }
    'watch.loginPositionConfig'(newVal, oldVal) {
        const keys = Object.keys(newVal);
        if (keys.length && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.setPosition(keys, newVal, this.loginPositionStyle);
        }
    }
    //#endregion
    //#region methods
    /**
     * 获取输入密码错误次数
     */
    getWrongTimes() {
        let params = {};
        if (this.wrongTimeParamsData) {
            params = hODeepClone(this.wrongTimeParamsData);
            let dataItem = params;
            for (const item in params) {
                if (this.formItemKeys.includes(dataItem[item])) {
                    const key = dataItem[item];
                    if (key === 'password') {
                        dataItem[item] = hCrypSha256(this.formItem.password, '', this.crypCount);
                    }
                    else {
                        dataItem[item] = this.formItem[key];
                    }
                }
            }
        }
        else {
            params = {
                randomCode: this.formItem.userName,
            };
        }
        const reqUrl = this.convertUrl(this.wrongTimesUrl, UrlType.Api);
        return this.resetAjax('getWrongTimes', () => {
            const ajaxInfo = hAjaxGet(reqUrl, { params });
            ajaxInfo.instance
                .then((res) => {
                if (res.data.status === PiStatusEnum.Success) {
                    let data = res.data.data;
                    this.wrongTimes = data[0] || 0;
                }
            })
                .catch((err) => hErrorShow({
                err,
            }));
            return ajaxInfo;
        });
    }
    /**
     * 获取验证码图片
     */
    getVerificationCodeImg() {
        let params = {};
        if (this.verificationCodeImgPramsData) {
            params = hODeepClone(this.verificationCodeImgPramsData);
            let dataItem = params;
            for (const item in params) {
                if (this.formItemKeys.includes(dataItem[item])) {
                    const key = dataItem[item];
                    if (key === 'password') {
                        dataItem[item] = hCrypSha256(this.formItem.password, '', this.crypCount);
                    }
                    else {
                        dataItem[item] = this.formItem[key];
                    }
                }
            }
        }
        else {
            params = {
                randomCode: this.formItem.userName,
            };
        }
        const reqUrl = this.convertUrl(this.verificationCodeImgUrl, UrlType.Api);
        return this.resetAjax('getVerificationCodeImg', () => {
            const ajaxInfo = hAjaxGet(reqUrl, { params });
            ajaxInfo.instance
                .then((res) => {
                if (res.data.status === PiStatusEnum.Success) {
                    let data = res.data.data;
                    let img = `data:image/png;base64,${data[0]}` || '';
                    // img = img.replace(/\\n/g, ' \n');
                    this.verificationCodeImg = img;
                }
            })
                .catch((err) => hErrorShow({
                err,
            }));
            return ajaxInfo;
        });
    }
    /**
     * 手机号/用户名离开焦点事件
     */
    async onBlurUserName() {
        if (this.formItem.userName === this.curUserName) {
            return;
        }
        await this.getWrongTimes().instance;
        if (this.wrongTimes >= 3) {
            this.getVerificationCodeImg();
        }
        else {
            this.verificationCodeImg = '';
            this.formItem.verificationCode = '';
        }
    }
    /**
     * 手机号/用户名获得焦点事件
     */
    onFocusUserName() {
        this.curUserName = this.formItem.userName;
    }
    /**
     * 根据传入的定位配置信息设置相应定位对象的值
     * @param keys 定位配置属性数组
     * @param val 定位配置传入的值
     * @param positionType 当前配置要设置到那个定位对象中
     */
    setPosition(keys, val, positionType) {
        keys.forEach((key) => {
            switch (key.toLowerCase()) {
                case 'row':
                    if (!Array.isArray(val.row)) {
                        return;
                    }
                    // val.row &&
                    //   val.row[0] &&
                    //   this.$set(
                    //     positionType,
                    //     'grid-row-start',
                    //     `row-${
                    //     val.row[0] < 0
                    //       ? 1
                    //       : val.row[0] && val.row[0] > 4
                    //         ? 4
                    //         : val.row[0]
                    //     }-start`,
                    //   );
                    // val.row &&
                    //   val.row[1] &&
                    //   this.$set(
                    //     positionType,
                    //     'grid-row-end',
                    //     `row-${
                    //     val.row[1] < 0
                    //       ? 1
                    //       : val.row[1] && val.row[1] > 4
                    //         ? 4
                    //         : val.row[1]
                    //     }-start`,
                    //   );
                    val.row &&
                        val.row[0] &&
                        this.$set(positionType, 'grid-row-start', `${val.row[0] < 0
                            ? 1
                            : val.row[0] && val.row[0] > 4
                                ? 4
                                : val.row[0]}`);
                    val.row &&
                        val.row[1] &&
                        this.$set(positionType, 'grid-row-end', `${val.row[1] < 0
                            ? 1
                            : val.row[1] && val.row[1] > 4
                                ? 4
                                : val.row[1]}`);
                    break;
                case 'col':
                    if (!Array.isArray(val.col)) {
                        return;
                    }
                    // val.col &&
                    //   val.col[0] &&
                    //   this.$set(
                    //     positionType,
                    //     'grid-column-start',
                    //     `col-${
                    //     val.col[0] < 0
                    //       ? 1
                    //       : val.col[0] && val.col[0] > 4
                    //         ? 4
                    //         : val.col[0]
                    //     }-start`,
                    //   );
                    // val.col &&
                    //   val.col[1] &&
                    //   this.$set(
                    //     positionType,
                    //     'grid-column-end',
                    //     `col-${
                    //     val.col[1] < 0
                    //       ? 1
                    //       : val.col[1] && val.col[1] > 4
                    //         ? 4
                    //         : val.col[1]
                    //     }-start`,
                    //   );
                    val.col &&
                        val.col[0] &&
                        this.$set(positionType, 'grid-column-start', `${val.col[0] < 0
                            ? 1
                            : val.col[0] && val.col[0] > 4
                                ? 4
                                : val.col[0]}`);
                    val.col &&
                        val.col[1] &&
                        this.$set(positionType, 'grid-column-end', `${val.col[1] < 0
                            ? 1
                            : val.col[1] && val.col[1] > 4
                                ? 4
                                : val.col[1]}`);
                    break;
                case 'justify':
                    this.$set(positionType, 'justify-self', val.justify);
                    break;
                case 'align':
                    this.$set(positionType, 'align-self', val.align);
                    break;
                default:
                    break;
            }
        });
    }
    /**
     * 登录请求
     */
    loginAjax() {
        let config = {};
        let data = {};
        if (this.loginParamsData) {
            data = hODeepClone(this.loginParamsData);
            let dataItem = data;
            for (const item in data) {
                if (this.formItemKeys.includes(dataItem[item])) {
                    const key = dataItem[item];
                    if (key === 'password') {
                        dataItem[item] = hCrypSha256(this.formItem.password, '', this.crypCount);
                    }
                    else {
                        dataItem[item] = this.formItem[key];
                    }
                }
            }
        }
        else {
            data = {
                phoneVerifyCode: '',
                // picVerifyCode: '',
                randomCode: '',
                loginType: 0,
                userName: this.formItem.userName,
                password: hCrypSha256(this.formItem.password, '', this.crypCount),
                picVerifyCode: this.formItem.verificationCode,
            };
        }
        const reqUrl = this.convertUrl(this.loginUrl, UrlType.Api);
        const resetAjax = this.resetAjax('loginAjax', () => {
            const ajaxInfo = hAjaxPost(reqUrl, data, config);
            ajaxInfo.instance
                .then((res) => res)
                .catch((err) => hErrorShow({
                err,
            }));
            return ajaxInfo;
        });
        return resetAjax;
    }
    async handleLogin() {
        let res = await this.loginAjax().instance;
        if (!res) {
            this.$Message.error('登录错误！');
            this.loading = false;
            return;
        }
        if (res.status !== 200) {
            this.$Message.error('登录失败！');
            this.loading = false;
            return;
        }
        const rData = res.data;
        if (!rData) {
            this.$Message.error('返回数据格式错误！');
            this.loading = false;
            return;
        }
        const dData = rData.data;
        if (!dData || !dData.length) {
            this.$Message.destroy();
            this.$Message.error(rData.msg || '返回数据格式错误！');
            this.loading = false;
            rData.exCode === 1 ? this.getVerificationCodeImg() : '';
            return;
        }
        const result = dData[0];
        const { secondaryValidate } = this;
        if (secondaryValidate && !secondaryValidate(result)) {
            this.$Message.error('您没有登录权限！');
            this.loading = false;
            return;
        }
        //自动登录写入localStorage
        if (this.isAutoLogin) {
            hLocalPrivate.setItem('userName', this.formItem.userName);
            hLocalPrivate.setItem('password', this.formItem.password);
            hLocalPrivate.setItem('loginTime', new Date().getTime().toString());
            hLocalPrivate.setItem('isAutoLogin', JSON.stringify(this.isAutoLogin));
        }
        else {
            hLocalPrivate.removeItem('userName');
            hLocalPrivate.removeItem('password');
            hLocalPrivate.removeItem('isAutoLogin');
        }
        //写入vuex和storage
        PiBaseStoreUserModule.setUser(result);
        // 登录成功后继续请求全局配置信息
        if (await this.getGlobalConfig()) {
            this.$Message.info('登录成功');
            this.loading = false;
            this.$emit('onOk');
        }
        else {
            this.$Message.info('登录失败');
            this.loading = false;
        }
    }
    /**
     * 点击确认后将进行登录请求
     * @param form 的ref
     */
    handleSubmit(name) {
        if (!this.loginUrl) {
            console.error('请正确输入登录地址loginUrl参数');
            return;
        }
        if (!this.verificationCodeImgUrl) {
            console.error('请正确输入获取验证码图片地址verificationCodeImgUrl参数');
            return;
        }
        if (!this.wrongTimesUrl) {
            console.error('请正确输入查询登录错误次数地址wrongTimesUrl参数');
            return;
        }
        // if (!this.globalConfigUrl) {
        //   console.error('请正确输入全局配置地址globalConfigUrl参数');
        //   return;
        // }
        if (!this.globalUrlsUrl) {
            console.error('请正确输入全局Urls配置地址globalUrlsUrl参数');
            return;
        }
        this.loading = true;
        this.$refs[name]
            .validate((valid) => {
            if (valid) {
                this.handleLogin().catch(() => {
                    this.$Message.error('登录时出现错误！');
                    this.loading = false;
                });
            }
            else {
                this.loading = false;
            }
        })
            .catch(() => {
            this.$Message.error('登录时出现错误！');
            this.loading = false;
        });
    }
    /**
     * 全局配置请求与获取CDN/API/File接口域名或IP地址端口列表
     */
    async getGlobalConfigUrls() {
        let isSuccess = false;
        //此处同步
        await hAjaxGetJson(this.globalUrlsUrl)
            .instance.then((res) => {
            if (!res) {
                this.$Message.error('获取全局Url配置错误！');
                return;
            }
            let { data: resultData } = res;
            if (!resultData) {
                this.$Message.error('返回全局Url配置数据格式错误！');
                return;
            }
            const configData = resultData.data;
            if (!configData || !configData.length) {
                this.$Message.error(resultData.msg || '返回全局Url配置数据格式错误！');
                return;
            }
            const cContent = configData[0];
            const { apiUrls, cdnUrls, fileUrls, sonUrl } = this.baseConfigInfo;
            apiUrls.push(...(cContent.apiUrls || []));
            cdnUrls.push(...(cContent.cdnUrls || []));
            fileUrls.push(...(cContent.fileUrls || []));
            sonUrl.value = cContent.sonUrl || '';
        })
            .then(() => {
            isSuccess = true;
        });
        //返回是否成功
        return isSuccess;
    }
    /**
     * 全局配置
     */
    getGlobalConfig() {
        return GetGlobalConfig(this.globalConfigUrl, this.cbGlobalConfig);
    }
    /**
     * 忘记密码
     */
    handleForget() {
        this.$emit('onForget', this.formItem);
    }
    /**注册 */
    handleRegister() {
        this.$emit('onRegister');
    }
    /**
     * 重置操作
     */
    handleCancel(name) {
        this.$refs[name].resetFields();
        this.formItem.userName = '';
        this.formItem.password = '';
    }
    /**
     * 判断是否在登录有效期内
     */
    judgeIsPeriodOfValidity(beginTime) {
        const endTime = new Date().getTime();
        const nTime = endTime - beginTime;
        const day = Math.floor(nTime / 86400000);
        if (day <= 7) {
            return true;
        }
        else {
            return false;
        }
    }
    /**
     * 回车登录
     * @param e event
     */
    onEnterKey(e) {
        console.log(e);
        const keycode = document.all ? e.keyCode : e.which;
        if (keycode == 13) {
            this.handleSubmit('formValidate'); // 登录方法名
        }
    }
    //#region 函数类属性
    //#endregion
    //#region hooks
    created() {
        this.isAutoLogin = JSON.parse(hLocalPrivate.getItem('isAutoLogin') || 'false');
        if (this.isAutoLogin) {
            const loginTime = JSON.parse(hLocalPrivate.getItem('loginTime') || 'null');
            if (!(loginTime && this.judgeIsPeriodOfValidity(loginTime))) {
                return;
            }
            this.formItem.userName = hLocalPrivate.getItem('userName') || '';
            this.formItem.password = hLocalPrivate.getItem('password') || '';
            this.handleLogin().catch(() => {
                this.$Message.error('登录时出现错误！');
            });
        }
        document.addEventListener('keypress', this.onEnterKey);
        //获取urls配置
        this.getGlobalConfigUrls().catch((err) => hErrorShow({
            err,
            showInPage: true,
            context: this,
            message: '获取全局urls配置错误！',
        }));
    }
    beforeDestroy() {
        document.removeEventListener('keypress', this.onEnterKey);
    }
};
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseLogin.prototype, "loginUrl", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseLogin.prototype, "verificationCodeImgUrl", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseLogin.prototype, "wrongTimesUrl", void 0);
__decorate([
    Prop({
        type: String,
        // required: true,
    })
], PiBaseLogin.prototype, "globalConfigUrl", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseLogin.prototype, "globalUrlsUrl", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseLogin.prototype, "secondaryValidate", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseLogin.prototype, "rules", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseLogin.prototype, "styles", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseLogin.prototype, "className", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseLogin.prototype, "systemName", void 0);
__decorate([
    Prop({
        type: String,
        default: '',
    })
], PiBaseLogin.prototype, "loginWarpClassName", void 0);
__decorate([
    Prop()
], PiBaseLogin.prototype, "loginParamsData", void 0);
__decorate([
    Prop()
], PiBaseLogin.prototype, "wrongTimeParamsData", void 0);
__decorate([
    Prop()
], PiBaseLogin.prototype, "verificationCodeImgPramsData", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({
            name: '请输入手机号/账户名',
            password: '请输入密码',
            verificationCode: '请输入验证码',
        }),
    })
], PiBaseLogin.prototype, "placeholder", void 0);
__decorate([
    Prop({
        type: String,
        default: '账号注册',
    })
], PiBaseLogin.prototype, "registerTxt", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseLogin.prototype, "isShowForget", void 0);
__decorate([
    Prop({
        type: String,
        default: '忘记密码？',
    })
], PiBaseLogin.prototype, "forgetTxt", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseLogin.prototype, "titlePositionConfig", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({}),
    })
], PiBaseLogin.prototype, "loginPositionConfig", void 0);
__decorate([
    Prop({
        type: Number,
        default: 1,
    })
], PiBaseLogin.prototype, "crypCount", void 0);
__decorate([
    Prop({
        type: Function,
    })
], PiBaseLogin.prototype, "cbGlobalConfig", void 0);
__decorate([
    Watch('rules', { immediate: true, deep: true })
], PiBaseLogin.prototype, "watch.rules", null);
__decorate([
    Watch('titlePositionConfig', { immediate: true, deep: true })
], PiBaseLogin.prototype, "watch.titlePositionConfig", null);
__decorate([
    Watch('loginPositionConfig', { immediate: true, deep: true })
], PiBaseLogin.prototype, "watch.loginPositionConfig", null);
PiBaseLogin = PiBaseLogin_1 = __decorate([
    Component({
        components: {
            Form,
            FormItem,
            ISwitch: Switch,
            Checkbox,
            CheckboxGroup,
            Icon,
            Input,
            Row,
            Col,
            Button,
        },
        template,
    })
], PiBaseLogin);
export default PiBaseLogin;
