import { __decorate } from "tslib";
// 表格数据为空时的处理逻辑
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { default as Utils } from '../utils/utils';
let TableEmptyMixin = class TableEmptyMixin extends Vue {
    isTableEmpty = false;
    // 表格数据为空时表头的高度（若有滚动条会包含滚动条的宽度）
    //tableEmptyContentHeight: 50,
    // 表格数据为空时的总高度
    tableEmptyHeight = 0;
    // table 数据为空的处理
    tableEmpty() {
        const that = this;
        let tableData = that.internalTableData, tableEmptyHeight = 0;
        if (Array.isArray(tableData) && tableData.length > 0) {
            this.isTableEmpty = false;
            return false;
        }
        this.isTableEmpty = true;
        tableEmptyHeight =
            that.getTotalColumnsHeight() + that.propReactiveProxy.errorContentHeight;
        this.tableEmptyHeight = tableEmptyHeight;
        this.$nextTick(() => {
            this.tableEmptyScroll();
        });
    }
    tableEmptyScrollEvent(e) {
        const el = this.$el;
        if (typeof el.querySelector === 'function') {
            const headerEle = el.querySelector('.v-table-rightview .v-table-header'), tableEmptyEle = el.querySelector('.v-table-empty .v-table-empty-scroll');
            if (tableEmptyEle) {
                headerEle.scrollLeft = tableEmptyEle.scrollLeft;
            }
        }
    }
    // 无数据时的滚动条控制
    tableEmptyScroll() {
        const el = this.$el;
        if (typeof el.querySelector === 'function') {
            const tableEmptyEle = el.querySelector('.v-table-empty .v-table-empty-scroll');
            // 无数据时的滚动条控制
            Utils.bind(tableEmptyEle, 'scroll', this.tableEmptyScrollEvent);
        }
    }
    beforeDestroy() {
        const el = this.$el;
        if (typeof el.querySelector === 'function') {
            const tableEmptyEle = el.querySelector('.v-table-empty .v-table-empty-scroll');
            // 无数据时的滚动条控制
            Utils.unbind(tableEmptyEle, 'scroll', this.tableEmptyScrollEvent);
        }
    }
};
TableEmptyMixin = __decorate([
    Component
], TableEmptyMixin);
export default TableEmptyMixin;
