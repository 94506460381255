var PiBaseFooter_1;
import { __decorate } from "tslib";
import { Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import template from './pi-base-footer.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-footer.component.scss';
let PiBaseFooter = PiBaseFooter_1 = class PiBaseFooter extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseFooter';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseFooter_1?.NAME,
        author: 'wuzp',
        title: '页脚组件',
        version: BcVersion,
        updateDate: '2019-06-08',
        description: '',
    };
    //#endregion
    text;
    //#region props
    //#endregion
    //#region data;
    //#endregion
    //#region computed
    get about() {
        return PiBaseFooter_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
};
__decorate([
    Prop()
], PiBaseFooter.prototype, "text", void 0);
PiBaseFooter = PiBaseFooter_1 = __decorate([
    Component({
        template,
    })
], PiBaseFooter);
export default PiBaseFooter;
