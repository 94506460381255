var PiBaseUploadProgress_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { hReactiveUtil } from 'bc$/utils/reactive.helper';
import { Dropdown, DropdownItem, DropdownMenu, Progress } from 'iview';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-upload-progress.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-upload-progress.component.scss';
let PiBaseUploadProgress = PiBaseUploadProgress_1 = class PiBaseUploadProgress extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseUploadProgress';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseUploadProgress_1?.NAME,
        author: 'wuzp',
        title: '文件上传进度条',
        version: BcVersion,
        updateDate: '2019-10-11',
        description: '',
    };
    //#region props
    /**
     * 上传进度
     */
    percent;
    /**
     * 上传完成
     */
    isCloseProgressModal;
    //#endregion
    //#region data
    /**
     * 响应式的转换工具
     */
    hReactiveUtil = hReactiveUtil;
    //#region 函数类属性
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseUploadProgress_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    'watch.isCloseProgressModal'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            this.$emit('on-close-Modal');
        }
    }
    //#endregion
    //#region methods
    //#endregion
    //#region hooks
    created() {
        //设置数据源弹窗显示的可选项内容
        //this.dataSourceOptions.timeNodeTypes = [DataSourceOptionsDefaultTimeNodeEnum.curMonth];
    }
};
__decorate([
    Prop({
        default: 0,
    })
], PiBaseUploadProgress.prototype, "percent", void 0);
__decorate([
    Prop({
        default: false,
    })
], PiBaseUploadProgress.prototype, "isCloseProgressModal", void 0);
__decorate([
    Watch('isCloseProgressModal')
], PiBaseUploadProgress.prototype, "watch.isCloseProgressModal", null);
PiBaseUploadProgress = PiBaseUploadProgress_1 = __decorate([
    Component({
        components: {
            Dropdown,
            DropdownMenu,
            DropdownItem,
            Progress,
        },
        template,
    })
], PiBaseUploadProgress);
export default PiBaseUploadProgress;
