export const PermissionSettings = [];
import { BaseConfigInfo } from 'bc$/urls/base-config-url';
import { hSStr2AsciiTotal } from '../utils/string.helper';
// tslint:disable-next-line: no-var-requires
const _package = require('bc$/../package.json');
export const BcVersion = _package.version;
export var UrlType;
(function (UrlType) {
    UrlType[UrlType["Api"] = 0] = "Api";
    UrlType[UrlType["Cdn"] = 1] = "Cdn";
    UrlType[UrlType["File"] = 2] = "File";
    UrlType[UrlType["SonUrl"] = 3] = "SonUrl";
})(UrlType || (UrlType = {}));
/**
 * 转换为绝对路径，该路径会根据类型随机获取url 基础地址并添加子站点url
 * 相同的参数应该返回相同的url地址
 * @param urlRelative
 * @param urlType
 */
export function ConvertUrl(urlRelative, urlType) {
    //携带域名的绝对路径直接返回
    if (!urlRelative || ~urlRelative.indexOf('//')) {
        return urlRelative;
    }
    const baseConfigInfo = BaseConfigInfo;
    let baseUrls = [];
    let prev = '';
    switch (urlType) {
        case UrlType.Api:
            prev = baseConfigInfo.apiPrev.value;
            baseUrls = baseConfigInfo.apiUrls;
            break;
        case UrlType.Cdn:
            prev = baseConfigInfo.cdnPrev.value;
            baseUrls = baseConfigInfo.cdnUrls;
            break;
        case UrlType.File:
            prev = baseConfigInfo.filePrev.value;
            baseUrls = baseConfigInfo.fileUrls;
            break;
        case UrlType.SonUrl:
            prev = baseConfigInfo.sonUrl.value;
            baseUrls = [];
            break;
    }
    // if (prev.length <= 0) {
    //   return urlRelative;
    // }
    if (prev.slice(-1) !== '/') {
        prev += '/';
    }
    let url = prev;
    if (urlRelative.slice(0, 1) === '/') {
        url = url + urlRelative.slice(1);
    }
    else {
        url = url + urlRelative;
    }
    const uLength = baseUrls.length;
    //UrlType.SonUrl将在此处返回
    if (uLength <= 0) {
        return url;
    }
    const code = hSStr2AsciiTotal(url.slice(-12));
    const index = code % uLength;
    let baseUrl = baseUrls[index];
    if (baseUrl.slice(-1) === '/') {
        baseUrl = baseUrl.slice(0, baseUrl.length - 1);
    }
    const urlResult = baseUrl + url;
    return urlResult;
}
export const AjaxAbortName = 'pi-ajax-abort-reset';
