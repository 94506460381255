import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { MixinProp, } from '../pi-base-graphics-configuration-svg.entity';
let VuePropMixin = class VuePropMixin extends MixinProp {
    /**
     * 缩放svg功能
     */
    isZoomEnable;
    /**
     * 鼠标中键拖动svg功能
     */
    isDragEnable;
    /**
     * svg 请求 URL
     */
    svgUrlData;
    /**
     * svg 绑定的设备ID
     */
    svgDevIdList;
    /**
     * svg 唯一 id
     *
     * TODO: 为实现下钻预留 prop，暂时无效
     */
    svgId;
    /**
     * 点推送信息 ws接口所需的订阅主题
     */
    wsTopic;
    /**
     * svg 全屏目标 dom 元素
     */
    fullScreenHElProp;
    /**
     * svg 右键参数
     */
    contextMenuOptions;
    /**
     * 联合类型的 deviceId 前缀
     */
    unionDeviceIdPrefix;
};
__decorate([
    Prop({ type: Boolean, default: true })
], VuePropMixin.prototype, "isZoomEnable", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], VuePropMixin.prototype, "isDragEnable", void 0);
__decorate([
    Prop({ type: Object, required: true })
], VuePropMixin.prototype, "svgUrlData", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => {
            return [];
        },
    })
], VuePropMixin.prototype, "svgDevIdList", void 0);
__decorate([
    Prop({ type: String, default: '' })
], VuePropMixin.prototype, "svgId", void 0);
__decorate([
    Prop({ type: String, default: '/user/realDataMsg' })
], VuePropMixin.prototype, "wsTopic", void 0);
__decorate([
    Prop({ type: Object })
], VuePropMixin.prototype, "fullScreenHElProp", void 0);
__decorate([
    Prop({ type: Object })
], VuePropMixin.prototype, "contextMenuOptions", void 0);
__decorate([
    Prop({ type: String, default: 'base,subdev,' })
], VuePropMixin.prototype, "unionDeviceIdPrefix", void 0);
VuePropMixin = __decorate([
    Component
], VuePropMixin);
export default VuePropMixin;
