var PiBaseEchartsLine_1;
import { __decorate } from "tslib";
import { BcVersion, hODeepObjectMerge } from 'bc$/main';
import { Component, Mixins } from 'vue-property-decorator';
import PiBaseEcharts from '../pi-base-echarts.base';
/**
 * PiBaseEchartsLine使用指南
 * @model 简洁模式（推荐）：只需提供data,theme（可选）属性，本组件根据theme采取option样式配置，生成data数据
 * @model 原生模式：只提供包裹的options，本组件参与任何处理。
 * @model 混合模式：提供options,data,theme（可选），本组件混合option样式配置，生成data数据
 * @example <PiBaseEchartsLine :data="chartData" />
 * @author hex
 */
let PiBaseEchartsLine = PiBaseEchartsLine_1 = class PiBaseEchartsLine extends Mixins(PiBaseEcharts) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEchartsLine';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEchartsLine_1?.NAME,
        author: 'hex',
        title: '图表-线',
        version: BcVersion,
        updateDate: '2020-07-17',
        description: '',
    };
    //#endregion
    //#region props
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEchartsLine_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     * 生成图表数据
     * @param data
     */
    generateChartData(newOptions) {
        // 补齐series
        if (newOptions.series) {
            newOptions.series = newOptions.series.map((item) => {
                return hODeepObjectMerge(this.getSeriesItem(), item);
            });
        }
        const mergedOption = hODeepObjectMerge(this.getEchartsOptions(), newOptions);
        // 补齐xAxis data
        let xAxis = mergedOption.xAxis;
        if (Object.prototype.toString.call(xAxis) == '[object Object]') {
            xAxis = xAxis;
            if (!(xAxis.data && xAxis.data.length)) {
                xAxis.data = this.collectKeys(newOptions.series);
            }
        }
        this.localOption = mergedOption;
    }
    /**
     * 获取图表Line默认option
     */
    getEchartsOptions() {
        return {
            legend: {
                itemGap: 28,
                itemWidth: 28,
            },
            tooltip: {
                show: true,
                trigger: 'axis',
            },
            xAxis: {
                data: [],
            },
            yAxis: {},
            series: [],
        };
    }
    /**
     * 获取Series Line
     */
    getSeriesItem() {
        return {
            type: 'line',
            name: '',
            showSymbol: false,
            data: [],
            smooth: true,
        };
    }
    /**
     * 收集所有数据的key（去重）
     * @param data
     */
    collectKeys(data) {
        const keys = [];
        data.forEach((item) => {
            item.data.forEach((itemData) => {
                if (itemData.name && !keys.includes(itemData.name)) {
                    keys.push(itemData.name);
                }
            });
        });
        return keys;
    }
};
PiBaseEchartsLine = PiBaseEchartsLine_1 = __decorate([
    Component
], PiBaseEchartsLine);
export default PiBaseEchartsLine;
