"use strict";
// 添加Element.matches
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
            Element.prototype.webkitMatchesSelector;
}
// 添加Element.closest
if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        let el = this;
        if (!document.documentElement.contains(el)) {
            return null;
        }
        do {
            if (el.matches(s)) {
                return el;
            }
            el = el.parentElement;
        } while (el !== null);
        return null;
    };
}
/**
 * 格式化日期函数
 */
Date.prototype.format = function (fmt) {
    //检测是否日期
    if (isNaN(this.getMonth())) {
        return '';
    }
    //检测是否输入样式,没有输入使用默认样式
    if (!fmt) {
        fmt = 'yyyy-MM-dd HH:mm';
    }
    //样式
    let data = {
        'y+': this.getFullYear(),
        'M+': this.getMonth() + 1,
        'd+': this.getDate(),
        'H+': this.getHours(),
        'm+': this.getMinutes(),
        's+': this.getSeconds(),
        'q+': Math.floor((this.getMonth() + 3) / 3),
        S: this.getMilliseconds(),
        w: (() => {
            let day = this.getDay();
            if (day === 0) {
                day = 7;
            }
            return day;
        })(),
        W: ['日', '一', '二', '三', '四', '五', '六'][this.getDay()], //周几,中文
    };
    let k;
    for (k in data) {
        let reg = new RegExp(`(${k})`);
        if (reg.test(fmt)) {
            let value = data[k].toString(), pty = RegExp.$1;
            fmt = fmt.replace(pty, pty.length === 1
                ? value
                : k === 'y+'
                    ? value.substr(4 - value.length)
                    : ('00' + value).substr(value.length));
        }
    }
    return fmt;
};
/**
 * 时间戳函数
 */
Date.prototype.timespan = function (perTime, fmt) {
    //检测是否日期
    if (isNaN(this.getMonth())) {
        return 0;
    }
    switch (fmt) {
        case 'yy':
        case 'yyyy':
            return this.getFullYear() - perTime.getFullYear();
        case 'MM':
            return (this.getFullYear() * 12 +
                this.getMonth() -
                (perTime.getFullYear() * 12 + perTime.getMonth()));
        case 'dd':
            return (this.getTime() - perTime.getTime()) / (1000 * 60 * 60 * 24);
        case 'HH':
            return (this.getTime() - perTime.getTime()) / (1000 * 60 * 60);
        case 'mm':
            return (this.getTime() - perTime.getTime()) / (1000 * 60);
        case 'ss':
            return (this.getTime() - perTime.getTime()) / 1000;
        case 'S':
            return this.getTime() - perTime.getTime();
    }
    return 0;
};
