var PiBaseEasyTableCheckbox_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { default as Utils } from '../../utils/utils';
import template from './pi-base-easy-table-checkbox.component.html';
let PiBaseEasyTableCheckbox = PiBaseEasyTableCheckbox_1 = class PiBaseEasyTableCheckbox extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseEasyTableCheckbox';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseEasyTableCheckbox_1?.NAME,
        author: 'yangd',
        title: '无标题',
        version: BcVersion,
        updateDate: '2019-08-08',
        description: '',
    };
    //#endregion
    //#region props
    value;
    // use in checkbox-group
    label;
    disabled;
    // partial selection effect
    indeterminate;
    showSlot;
    //#endregion
    //#region data
    model = false;
    _checkboxGroup = {};
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseEasyTableCheckbox_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    get checkboxClasses() {
        return [
            'v-checkbox',
            {
                ['v-checkbox-checked']: this.model,
                ['v-checkbox-disabled']: this.disabled,
                ['v-checkbox-indeterminate']: this.indeterminate,
            },
        ];
    }
    get isCheckBoxGroup() {
        this._checkboxGroup = Utils.getParentCompByName(this, 'v-checkbox-group');
        return this._checkboxGroup ? true : false;
    }
    // 是否横向显示还是纵向显示
    get displayType() {
        let style = 'inline-block';
        if (this._checkboxGroup) {
            style = this._checkboxGroup.isVerticalShow ? 'block' : 'inline-block';
        }
        return style;
    }
    //#endregion
    //#region watch
    'watch.value'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        this.updateModelBySingle();
    }
    //#endregion
    //#region methods
    change(event) {
        if (this.disabled) {
            this.model = false;
            return false;
        }
        const checked = event.target.checked;
        this.$emit('input', checked);
        this.$emit('change');
        if (this.isCheckBoxGroup) {
            this._checkboxGroup.updateModel(this.label, checked);
        }
    }
    initModel() {
        if (this.isCheckBoxGroup) {
            let checkboxGroup = this._checkboxGroup;
            if (Array.isArray(checkboxGroup.value) &&
                checkboxGroup.value.length > 0) {
                if (checkboxGroup.value.indexOf(this.label) > -1) {
                    this.model = true;
                }
            }
        }
        else {
            this.model = this.value;
        }
    }
    // 通过单选更新 model
    updateModelBySingle() {
        if (!this.disabled) {
            this.model = this.value;
        }
    }
    // 父组件调用更新 model
    updateModelByGroup(checkBoxGroup) {
        if (checkBoxGroup.indexOf(this.label) > -1) {
            if (!this.disabled) {
                this.model = true;
            }
        }
        else {
            if (!this.disabled) {
                this.model = false;
            }
        }
    }
    //#endregion
    //#region hooks
    created() {
        // this.model = this.value;
        this.initModel();
    }
};
__decorate([
    Prop({
        type: [String, Number, Boolean],
    })
], PiBaseEasyTableCheckbox.prototype, "value", void 0);
__decorate([
    Prop({
        type: [String, Number],
        required: true,
    })
], PiBaseEasyTableCheckbox.prototype, "label", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTableCheckbox.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseEasyTableCheckbox.prototype, "indeterminate", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: true,
    })
], PiBaseEasyTableCheckbox.prototype, "showSlot", void 0);
__decorate([
    Watch('value', { immediate: true })
], PiBaseEasyTableCheckbox.prototype, "watch.value", null);
PiBaseEasyTableCheckbox = PiBaseEasyTableCheckbox_1 = __decorate([
    Component({
        name: 'v-checkbox',
        template,
    })
], PiBaseEasyTableCheckbox);
export default PiBaseEasyTableCheckbox;
