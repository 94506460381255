import { addListener } from './listener';
/**
 * url匹配,不匹配置空,绑定
 * @param el
 */
export const urlFilter = (el, tagName) => {
    const reg = /(^#)|(^http(s*):\/\/[^\s]+\.[^\s]+)/;
    const vnode = el.__vue__;
    const callback = () => {
        let value = '';
        if (tagName === 'Input') {
            value = vnode.value.replace(reg, '');
        }
        else {
            value = el.value;
        }
        if (!value) {
            return;
        }
        if (!reg.test(value)) {
            if (tagName === 'Input') {
                vnode.$emit('input', '');
            }
            else {
                el.value = '';
            }
        }
    };
    if (tagName === 'Input') {
        vnode.$on('on-blur', callback);
    }
    else {
        addListener(el, 'blur', callback);
        el.__input_filter__ = {
            keyup: callback,
            blur: callback,
        };
    }
};
