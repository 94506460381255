import { SelectableExtend } from './selectable-extend.directive';
/**
 * 处理 ShadowDom
 * @param el
 * @param selector
 */
const _querySelector = function (el, selector) {
    let rootEle = el.getRootNode();
    if (rootEle === document) {
        rootEle = document.body;
    }
    return rootEle.querySelector(selector);
};
/**
 * 存储在 HTMLElement 的插件 selectable 实例 key
 */
const EL_SELECTABLE = '_pi-selectable';
/**
 * 虚拟选中框样式
 */
const _SELECTION_CLASS_NAME = 'pi-selection';
/**
 * 初始化插件 selectable
 * @param el
 * @param params
 * @param arg
 */
const _initSelectableJs = function (el, params = {}, arg = {}) {
    if (GetSelectableInstance(el)) {
        console.warn(`Directive v-select init fail, please don't call it again.`);
        return;
    }
    createUpdateSelectionDiv(el, params.box);
    el[EL_SELECTABLE] = new SelectableExtend(Object.assign({
        boundingBox: !!params.constraint
            ? _querySelector(el, params.constraint)
            : el,
        selectBoxSelector: params.box || '.selection',
        boundingBoxSelector: params.constraint,
        el,
    }, arg));
    el[EL_SELECTABLE].setSelectables([].slice.call(el.querySelectorAll(params.items || '.selectable')));
};
/**
 * 销毁 selectable 插件
 * @param selectableInstance
 */
const _destroySelectableJs = function (selectableInstance) {
    if (selectableInstance) {
        selectableInstance.detach();
    }
};
/**
 * 获取 selectable 插件实例
 * @param el
 */
export const GetSelectableInstance = function (el) {
    if (el) {
        return el[EL_SELECTABLE];
    }
    return null;
};
/**
 * 创建选择虚拟框
 * @param rootEl
 * @param className 虚框自定义样式
 */
const createUpdateSelectionDiv = function (el, className = _SELECTION_CLASS_NAME) {
    if (!el) {
        return;
    }
    // 移除 class name 空格
    className = className.replace(/(\s|\.)/g, '');
    const selectionDOM = el.querySelector(className) || _querySelector(el, className);
    if (selectionDOM) {
        // 追加选择框样式
        let curClassNames = selectionDOM.className.split(' ');
        curClassNames.push(className);
        selectionDOM.className = curClassNames ? curClassNames.join(' ') : '';
    }
    else {
        // 创建新选择框
        const elm = document.createElement('div');
        elm.className = className;
        el.appendChild(elm);
    }
    return el;
};
export const Selectable = {
    bind: function (el, binding, vNode) {
        const { value } = binding;
        let isEnable = true;
        if (value && value.hasOwnProperty('_piEnable')) {
            isEnable = value._piEnable;
        }
        if (!isEnable) {
            return;
        }
        _initSelectableJs(el, el.dataset, binding.value);
        // TODO: 这里如果是异步组件，可能有问题, 待处理
        // const vm = vNode.context!;
        // vm.$on('hook:mounted', function () {
        //   vm.$nextTick(function () {
        //     _initSelectableJs(el, el.dataset, binding.value);
        //   });
        // });
        // }
    },
    unbind(el) {
        let selectableInstance = el[EL_SELECTABLE];
        _destroySelectableJs(selectableInstance);
        selectableInstance = null;
    },
};
