var PiBaseVideoCtrl_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases';
import { apiAjaxHandle, } from 'bc$/bases/api.base';
import { ConvertUrl, hAjaxPost, UrlType, VueBase, } from 'bc$/main';
import { WindowEventMixin } from 'bc$/mixins/window-event.mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './pi-base-video-ctrl.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-video-ctrl.component.scss';
import { DirectionEnum, FeatureEnum } from './pi-base-video-ctrl.enum';
let PiBaseVideoCtrl = PiBaseVideoCtrl_1 = class PiBaseVideoCtrl extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseVideoCtrl';
    /**
     * 关于信息
     */
    static ABOUT = Object.freeze({
        name: PiBaseVideoCtrl_1?.NAME,
        author: 'yangd',
        title: '视频控制组件',
        version: BcVersion,
        updateDate: '2020-10-09',
        description: '',
    });
    //#endregion
    //#region props
    /**
     * 有无云台控制功能
     */
    controlFlag;
    /**
     * 摄像头编码
     */
    cameraCode;
    /**
     * 摄像头控制URL
     */
    cameraControlUrl;
    /**
     * 方向集合
     */
    directionListReceive;
    //#endregion
    //#region data
    /**
     * 方向按键枚举
     */
    DirectionEnum = DirectionEnum;
    /**
     * 自动线扫中
     */
    isAutoScanning = false;
    /**
     * 方向按键
     */
    directionList = [
        {
            dir: DirectionEnum.LeftUp,
            title: '左上',
        },
        {
            dir: DirectionEnum.Up,
            title: '上',
        },
        {
            dir: DirectionEnum.RightUp,
            title: '右上',
        },
        {
            dir: DirectionEnum.Left,
            title: '左',
        },
        {
            dir: DirectionEnum.Center,
            title: '线扫开关',
        },
        {
            dir: DirectionEnum.Right,
            title: '右',
        },
        {
            dir: DirectionEnum.LeftDown,
            title: '左下',
        },
        {
            dir: DirectionEnum.Down,
            title: '下',
        },
        {
            dir: DirectionEnum.RightDown,
            title: '右下',
        },
    ];
    /**
     * 按钮功能
     */
    featureList = [
        {
            name: FeatureEnum.ZoomOut,
            icon: 'icon iconfont icon-tongyongkuer-79gongjutubiao-jingtoulayuan',
            title: '镜头拉远',
        },
        {
            name: FeatureEnum.ZoomIn,
            icon: 'icon iconfont icon-tongyongkuer-80gongjutubiao-jingtoulajin',
            title: '镜头拉近',
        },
        {
            name: FeatureEnum.FocusFar,
            icon: 'icon iconfont icon-tongyongkuer-84gongjutubiao-jujiaodianbianyuan',
            title: '聚焦点变远',
        },
        {
            name: FeatureEnum.FocusNear,
            icon: 'icon iconfont icon-tongyongkuer-83gongjutubiao-jujiaodianbianjin',
            title: '聚集点变近',
        },
        {
            name: FeatureEnum.IrisReduce,
            icon: 'icon iconfont icon-tongyongkuer-82gongjutubiao-guangquanjianshao',
            title: '光圈减小',
        },
        {
            name: FeatureEnum.IrisIncrease,
            icon: 'icon iconfont icon-tongyongkuer-81gongjutubiao-guangquanzengda',
            title: '光圈增大',
        },
    ];
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseVideoCtrl_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    convertCommand(dir) {
        let command = '';
        switch (dir) {
            case DirectionEnum.LeftUp: {
                command = 'LEFT_UP';
                break;
            }
            case DirectionEnum.Up: {
                command = 'UP';
                break;
            }
            case DirectionEnum.RightUp: {
                command = 'RIGHT_UP';
                break;
            }
            case DirectionEnum.Left: {
                command = 'LEFT';
                break;
            }
            case DirectionEnum.Center: {
                break;
            }
            case DirectionEnum.Right: {
                command = 'RIGHT';
                break;
            }
            case DirectionEnum.LeftDown: {
                command = 'LEFT_DOWN';
                break;
            }
            case DirectionEnum.Down: {
                command = 'DOWN';
                break;
            }
            case DirectionEnum.RightDown: {
                command = 'RIGHT_DOWN';
                break;
            }
        }
        return command;
    }
    /**
     * 按下方向按键
     * @param dir 方向
     */
    downDirection(dir) {
        //方向中键不用调用普通后台云台命令
        if (dir === DirectionEnum.Center) {
            return;
        }
        //是否无此功能
        if (!this.controlFlag) {
            this.$Message.error('选择的录像机不支持云台控制操作！');
            return;
        }
        //摄像头编码
        const { cameraCode } = this;
        if (!cameraCode) {
            this.$Message.error('请选择摄像机后再执行控制操作！');
            return;
        }
        //命令类型
        let command = this.convertCommand(dir);
        if (!command) {
            return;
        }
        //停止线扫
        if (this.isAutoScanning) {
            this.toggleAutoScanning();
        }
        //执行云台控制
        return this.apiPostV1VideoCameraControlling({
            cameraControllingParam: {
                action: 0,
                cameraCode,
                command,
            },
        });
    }
    /**
     * 弹起方向按键
     * @param dir 方向
     */
    upDirection(dir) {
        //方向中键不用调用普通后台云台命令
        if (dir === DirectionEnum.Center) {
            return;
        }
        //是否无此功能
        if (!this.controlFlag) {
            return;
        }
        //摄像头编码
        const { cameraCode } = this;
        if (!cameraCode) {
            return;
        }
        //命令类型
        let command = this.convertCommand(dir);
        if (!command) {
            return;
        }
        return this.apiPostV1VideoCameraControlling({
            cameraControllingParam: {
                action: 1,
                cameraCode,
                command,
            },
        });
    }
    /**
     * 线扫开关
     */
    toggleAutoScanning() {
        //是否无此功能
        if (!this.controlFlag) {
            this.$Message.error('选择的录像机不支持云台控制操作！');
            return;
        }
        //摄像头编码
        const { cameraCode } = this;
        if (!cameraCode) {
            return;
        }
        let command = 'AUTO_SCAN';
        const isAutoScanning = (this.isAutoScanning = !this.isAutoScanning);
        const action = isAutoScanning ? 0 : 1;
        return this.apiPostV1VideoCameraControlling({
            cameraControllingParam: {
                action,
                cameraCode,
                command,
            },
        });
    }
    /**
     * 点击功能按钮
     * @param name 按钮名称
     */
    clickFeature(name) {
        //是否无此功能
        if (!this.controlFlag) {
            this.$Message.error('选择的录像机不支持云台控制操作！');
            return;
        }
        //摄像头编码
        const { cameraCode } = this;
        if (!cameraCode) {
            this.$Message.error('请选择摄像机后再执行控制操作！');
            return;
        }
        //命令类型
        let command = '';
        switch (name) {
            case FeatureEnum.ZoomOut: {
                command = 'ZOOM_OUT';
                break;
            }
            case FeatureEnum.ZoomIn: {
                command = 'ZOOM_IN';
                break;
            }
            case FeatureEnum.FocusFar: {
                command = 'FOCUS_FAR';
                break;
            }
            case FeatureEnum.FocusNear: {
                command = 'FOCUS_NEAR';
                break;
            }
            case FeatureEnum.IrisReduce: {
                command = 'IRIS_REDUCE';
                break;
            }
            case FeatureEnum.IrisIncrease: {
                command = 'IRIS_ENLARGE';
                break;
            }
        }
        if (!command) {
            return;
        }
        return this.apiPostV1VideoCameraControlling({
            cameraControllingParam: {
                action: 0,
                cameraCode,
                command,
            },
        });
    }
    /**
     * 对摄像头进行云台控制
     * @param params ajax请求需要的参数对象
     * cameraControllingParam cameraControllingParam
     * @param options 自定义配置参数
     * @return OK
     */
    apiPostV1VideoCameraControlling(params, options) {
        const { cameraControlUrl } = this;
        const {} = this;
        const { cameraControllingParam } = params || {};
        // 请求地址
        let url_ = ConvertUrl(cameraControlUrl, UrlType.Api);
        url_ = url_.replace(/[?&]$/g, '');
        const { config = {} } = options || {};
        const ajaxInfo = hAjaxPost(url_, cameraControllingParam, config);
        return apiAjaxHandle(false, ajaxInfo, '对摄像头进行云台控制', options);
    }
    //#endregion
    //#region hooks
    created() {
        this.directionList = this.directionListReceive || this.directionList;
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseVideoCtrl.prototype, "controlFlag", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseVideoCtrl.prototype, "cameraCode", void 0);
__decorate([
    Prop({
        type: String,
        required: true,
    })
], PiBaseVideoCtrl.prototype, "cameraControlUrl", void 0);
__decorate([
    Prop({
        type: Array,
    })
], PiBaseVideoCtrl.prototype, "directionListReceive", void 0);
PiBaseVideoCtrl = PiBaseVideoCtrl_1 = __decorate([
    Component({
        template,
        mixins: [WindowEventMixin],
    })
], PiBaseVideoCtrl);
export default PiBaseVideoCtrl;
