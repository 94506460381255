var PiBaseWidgetToolbar_1;
import { __decorate } from "tslib";
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { hODeepClone } from 'bc$/utils/object.helper';
import template from './pi-base-widget-toolbar.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-widget-toolbar.component.scss';
import { Dropdown, Icon, DropdownItem, DropdownMenu } from 'iview';
import { UrlType, hAjaxDelete, PiStatusEnum, ApiUrlSetting, PiBaseAbout, hErrorShow, hReactiveUtil, } from 'bc$/main';
import { DropdownItemValue } from './pi-base-widget-toolbar.enum';
import { PiBaseStoreWidgetModule, PiBaseStorePageModule } from 'bc$/store';
let PiBaseWidgetToolbar = PiBaseWidgetToolbar_1 = class PiBaseWidgetToolbar extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseWidgetToolbar';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseWidgetToolbar_1?.NAME,
        author: 'wuzp',
        title: '工具条组件',
        version: BcVersion,
        updateDate: '2019-06-17',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 是否显示工具条
     */
    isShowProperty;
    //#endregion
    //#region data
    vwgComponent = null;
    //#endregion
    //#region computed
    get about() {
        return PiBaseWidgetToolbar_1.ABOUT;
    }
    get titleAll() {
        return '';
    }
    /**
     * store中存储的页面相关的信息
     */
    get storePage() {
        return PiBaseStorePageModule;
    }
    /**
     * store中存储的组件相关的信息
     */
    get storeWidget() {
        return PiBaseStoreWidgetModule;
    }
    /**
     * store中存储的组件相关的信息
     */
    get toolbarInfo() {
        return this.storeWidget.toolbarInfo;
    }
    /**
     * store中存储的组件相关的信息
     */
    get toolPosition() {
        return this.storeWidget.toolPosition;
    }
    /**
     * css样式计算结果
     */
    get styleObj() {
        const { toolPosition } = this;
        return {
            left: `${toolPosition.left}px`,
            top: `${toolPosition.top}px`,
            opacity: toolPosition.isShow ? 1 : 0,
        };
    }
    //#endregion
    //#region watch
    /*
     *实例改变,则数据跟着变
     */
    'watch.toolbarInfo'(newVal, oldVal) {
        this.vwgComponent = newVal;
    }
    //#endregion
    //#region methods
    /**
     * 下来框点击事件
     * @param index
     */
    dropdownHandler(index) {
        if (index === DropdownItemValue.Property) {
            this.showPropertyModal();
        }
        else if (index === DropdownItemValue.Delete) {
            this.deleteWidget();
        }
        else if (index === DropdownItemValue.About) {
            this.showAboutModal();
        }
    }
    /**
     * 显示本组件
     */
    showToolWidget() {
        const { toolPosition } = this;
        this.storeWidget.setToolbarPosition({
            left: toolPosition.left,
            top: toolPosition.top,
            isShow: true,
        });
    }
    /**
     *弹出属性弹出框
     */
    showPropertyModal() {
        const { vwgComponent } = this;
        if (!vwgComponent) {
            return;
        }
        const { toolbarShowProperty } = vwgComponent;
        typeof toolbarShowProperty === 'function' && toolbarShowProperty();
    }
    /**
     *弹出关于弹出框
     */
    showAboutModal() {
        const { vwgComponent } = this;
        if (!vwgComponent) {
            return;
        }
        this.$piModal({
            piParentNodeFn: this.$el.getRootNode,
            title: '关于',
            okText: '确定',
            width: hReactiveUtil.convertToReactivePx(400),
            footerHide: false,
            className: 'pi-base-about-wrap',
            closable: true,
            draggable: false,
            styles: {},
            headerStyle: {},
            maskClosable: false,
            cancelHidden: true,
            render: (h) => h(PiBaseAbout, {
                props: {
                    aboutInfo: vwgComponent.about,
                },
            }),
        });
    }
    /**
     *删除组件
     */
    deleteWidget() {
        const tabId = this.storePage.curTab.tabId;
        if (!tabId) {
            return;
        }
        const { vwgComponent } = this;
        if (!vwgComponent) {
            return;
        }
        let widgetInfo = vwgComponent.widgetInfo;
        let id = widgetInfo.pkid;
        this.$Modal.confirm({
            title: '删除组件',
            content: '<h3>删除后将无法恢复,是否确认？</h3>',
            onOk: () => {
                const reqUrl = this.convertUrl(ApiUrlSetting.LayoutWidget.WidgetDelete + id, UrlType.Api);
                return this.resetAjax('deleteWidget', () => {
                    const ajaxInfo = hAjaxDelete(reqUrl);
                    ajaxInfo.instance
                        .then((res) => {
                        const data = res.data || null;
                        if (data.status === PiStatusEnum.Success) {
                            this.$Message.info({
                                content: '删除组件成功',
                            });
                            // 通知Vuex删除组件实例
                            this.storeWidget.deleteWidgetInstanceInfo({
                                tabId,
                                columnPkIds: [widgetInfo.columnPkId],
                            });
                        }
                        else {
                            this.$Message.error(data.msg);
                        }
                    })
                        .catch((err) => hErrorShow({
                        context: this,
                        err,
                        showInPage: true,
                    }));
                    return ajaxInfo;
                });
            },
        });
    }
    /**
     * 移除工具条
     */
    removeToolbar() {
        this.$destroy();
    }
};
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseWidgetToolbar.prototype, "isShowProperty", void 0);
__decorate([
    Watch('toolbarInfo', { immediate: true, deep: true })
], PiBaseWidgetToolbar.prototype, "watch.toolbarInfo", null);
PiBaseWidgetToolbar = PiBaseWidgetToolbar_1 = __decorate([
    Component({
        components: {
            Dropdown,
            Icon,
            DropdownItem,
            DropdownMenu,
        },
        template,
    })
], PiBaseWidgetToolbar);
export const PiBaseStaticWidgetToolbar = {
    install: (Vue, options) => {
        function createDialogCmp(options) {
            const opt = {
                propsData: {},
                destroyed: () => {
                    piParentNode.removeChild(el);
                },
            };
            const render = options.render;
            let propsData;
            let isCreateWidgetInstanceInfos = PiBaseStoreWidgetModule.isCreateWidgetInstanceInfos;
            PiBaseStoreWidgetModule.createToolbar();
            if (render && typeof render === 'function') {
                opt.render = function (createElement, context) {
                    propsData = opt.propsData;
                    const optCreateEle = {
                        props: hODeepClone(propsData),
                        on: {
                            destroyed: () => {
                                //piParentNode.removeChild(el);
                            },
                        },
                    };
                    return createElement(PiBaseWidgetToolbar, optCreateEle, [
                        render(createElement, context),
                    ]);
                };
            }
            if (!(opt && opt.propsData && !isCreateWidgetInstanceInfos)) {
                return;
            }
            const cmp = new PiBaseWidgetToolbar(opt);
            const el = cmp.$mount().$el;
            const piParentNode = options.piParentNode || document.body;
            piParentNode.appendChild(el);
        }
        function show(options) {
            let opt;
            switch (typeof options) {
                case 'string':
                    opt = {};
                    break;
                case 'object':
                    opt = options;
                    break;
                default:
                    console.error('参数错误！');
                    return;
            }
            return createDialogCmp(opt);
        }
        const property = (options && options.property) || '$piWidgetToolbar';
        Vue.prototype[property] = show;
    },
};
