var PiBaseRankV2_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/bases/vue.base';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './pi-base-rank-v2.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-rank-v2.component.scss';
let PiBaseRankV2 = PiBaseRankV2_1 = class PiBaseRankV2 extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseRankV2';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseRankV2_1?.NAME,
        author: 'hex',
        title: '排名组件',
        version: BcVersion,
        updateDate: '2020-05-25',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 最大显示条数
     */
    maxItem;
    /**
     * 进度项配置
     */
    options;
    /**
     * 进度条宽度
     */
    progressHeight;
    /**
     * 进度条色值
     */
    progressColor;
    /**
     * 进度条底色色值
     */
    progressBgColor;
    /**
     * 进度条圆角
     */
    progressRadius;
    /**
     * 进度条条纹
     */
    progressStripe;
    /**
     * 进度条背景图
     */
    progressBgImage;
    /**
     * 进度条背景尺寸
     */
    progressBgSize;
    //#endregion
    //#region data
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseRankV2_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return PiBaseRankV2_1.ABOUT.title;
    }
    //#endregion
    //#region watch
    //#endregion
    //#region methods
    /**
     * 滚动容器样式
     */
    getScrollStyle() {
        const { maxItem } = this;
        if (maxItem) {
            const length = this.options?.length || 0;
            const percent = 100 / maxItem;
            const gtr = Math.max(maxItem, length);
            return {
                'grid-template-rows': `${gtr * percent}%`,
            };
        }
        return {
            'grid-template-rows': `1fr`,
        };
    }
    /**
     * 局部样式
     */
    getGridStyle() {
        const { maxItem } = this;
        if (maxItem) {
            const length = this.options?.length || 0;
            return {
                display: 'grid',
                'grid-template-rows': `repeat(${length},1fr)`,
                'grid-template-columns': '1fr',
            };
        }
        return {};
    }
    /**
     * 进度条外部样式
     * @param item
     */
    getOuterStyle() {
        return {
            'background-color': this.progressBgColor,
            height: this.progressHeight + 'px',
            'border-radius': this.progressRadius + 'px',
        };
    }
    /**
     * 进度条内部样式
     * @param item
     */
    getInnerStyle(item) {
        let backgroundSize = this.progressBgSize, backgroundImage = this.progressBgImage;
        if (this.progressStripe) {
            const size = this.progressHeight * 2 - Math.ceil(this.progressHeight / 2);
            backgroundSize = `${size}px ${size}px`;
            backgroundImage = `linear-gradient(
        45deg,
        transparent 25%,
        rgba(255, 255, 255, 0.15) 25%,
        rgba(255, 255, 255, 0.15) 50%,
        transparent 50%,
        transparent 75%,
        rgba(255, 255, 255, 0.15) 75%
      )`;
        }
        return {
            width: (item.percent ? item.percent : 0) + '%',
            'background-color': item.progressColor
                ? item.progressColor
                : this.progressColor,
            'border-radius': this.progressRadius + 'px',
            'background-size': backgroundSize,
            'background-image': backgroundImage,
        };
    }
};
__decorate([
    Prop()
], PiBaseRankV2.prototype, "maxItem", void 0);
__decorate([
    Prop()
], PiBaseRankV2.prototype, "options", void 0);
__decorate([
    Prop({ default: 14 })
], PiBaseRankV2.prototype, "progressHeight", void 0);
__decorate([
    Prop({ default: '#4ba3f5' })
], PiBaseRankV2.prototype, "progressColor", void 0);
__decorate([
    Prop({ default: '#ebf9ff' })
], PiBaseRankV2.prototype, "progressBgColor", void 0);
__decorate([
    Prop({ default: 0 })
], PiBaseRankV2.prototype, "progressRadius", void 0);
__decorate([
    Prop({ default: false })
], PiBaseRankV2.prototype, "progressStripe", void 0);
__decorate([
    Prop()
], PiBaseRankV2.prototype, "progressBgImage", void 0);
__decorate([
    Prop()
], PiBaseRankV2.prototype, "progressBgSize", void 0);
PiBaseRankV2 = PiBaseRankV2_1 = __decorate([
    Component({
        template,
    })
], PiBaseRankV2);
export default PiBaseRankV2;
