import { ELE_WRAPS, MOUSE_ENTER, MOUSE_LEAVE, } from './resize.directive.entity';
const OUT_LINE_CLASS_NAME = 'pi-resize-outline';
const HELPER_CLASS_NAME = 'pi-resize-helper';
let isMouseDown = false;
/**
 * 拖拽实现类
 */
class ResizeCmp {
    /**
     * 当前缩放状态
     */
    stickResize = false;
    /**
     * 当前缩放节点
     */
    currentStick = '';
    domRectLast = {};
    domRect = {};
    minWidth = 10;
    minHeight = 10;
    clientPosition = { x: 0, y: 0 };
    helper = null;
    /**
     * 鼠标点击节点
     * @param stick 节点名称
     * @param ev //鼠标事件
     */
    stickDown(ev, stick, el, options) {
        if (this.stickResize) {
            return;
        }
        const domRect = (this.domRect = el.getBoundingClientRect());
        const { left, top, width, height } = (this.domRectLast = {
            left: domRect.left,
            top: domRect.top,
            width: domRect.width,
            height: domRect.height,
        });
        this.stickResize = true;
        this.currentStick = stick;
        this.clientPosition.x =
            typeof ev.clientX !== 'undefined' ? ev.clientX : ev.touches[0].clientX;
        this.clientPosition.y =
            typeof ev.clientY !== 'undefined' ? ev.clientY : ev.touches[0].clientY;
        {
            let helper = this.helper;
            if (!helper) {
                helper = document.createElement('div');
                helper.classList.add(HELPER_CLASS_NAME);
                const piParentNode = options.piParentNode || document.body;
                piParentNode.appendChild(helper);
                this.helper = helper;
            }
            const { style } = helper;
            style.zIndex = '99999';
            style.position = 'fixed';
            style.left = `${left}px`;
            style.top = `${top}px`;
            style.width = `${width}px`;
            style.height = `${height}px`;
            style.outlineOffset = '-2px';
            style.outline = '2px dotted red';
        }
        //执行回调
        const onStart = options && options.onStart;
        typeof onStart === 'function' &&
            onStart({
                el,
                helper: this.helper,
                domRect: this.domRect,
                domRectLast: this.domRectLast,
            });
    }
    /**
     * 鼠标拖动
     * @param ev //鼠标事件
     */
    stickMove(ev, el, options) {
        const { helper } = this;
        if (!helper) {
            return;
        }
        const width = Math.max(options && options.minWidth ? options.minWidth : this.minWidth, this.domRect.width + ev.clientX - this.clientPosition.x);
        const height = Math.max(options && options.minHeight ? options.minHeight : this.minHeight, this.domRect.height + ev.clientY - this.clientPosition.y);
        this.domRectLast.width = width;
        this.domRectLast.height = height;
        const { style } = helper;
        switch (this.currentStick) {
            case 'br':
                style.width = `${width}px`;
                style.height = `${height}px`;
                break;
            case 'bm':
                style.height = `${height}px`;
                break;
            case 'mr':
                style.width = `${width}px`;
                break;
            default:
                break;
        }
        const onMove = options && options.onMove;
        typeof onMove === 'function' &&
            onMove({
                el,
                helper: this.helper,
                domRect: this.domRect,
                domRectLast: this.domRectLast,
            });
    }
    /**
     * 鼠标事件松开
     */
    stickUp(el, options) {
        {
            let cssStyle = window.getComputedStyle(el);
            const { style } = el.parentElement;
            style.width = cssStyle.width;
            style.height = cssStyle.height;
            const onStop = options && options.onStop;
            typeof onStop === 'function' &&
                onStop({
                    el,
                    helper: this.helper,
                    domRect: this.domRect,
                    domRectLast: this.domRectLast,
                });
            this.stickResize = false;
        }
        {
            const { helper } = this;
            helper && helper.parentElement.removeChild(helper);
        }
    }
    /**
     * 鼠标移动
     * @param ev
     * @param el
     * @param options
     */
    move(ev, el, options) {
        if (!this.stickResize) {
            return;
        }
        ev.stopPropagation();
        this.stickMove(ev, el, options);
    }
    /**
     * 鼠标弹起
     * @param ev
     * @param el
     * @param options
     */
    up(ev, el, options) {
        if (this.stickResize) {
            this.stickUp(el, options);
        }
    }
}
/**
 * 初始化
 * @param ele
 * @param binding
 */
function initResize(el, binding) {
    //检测参数
    const options = binding.value;
    //检测参数disabled
    if (!options) {
        return;
    }
    const { disabled } = options;
    const ele = el;
    let eleWraps = [];
    //检测参数selector
    const selector = options.selector;
    if (selector && selector.length) {
        ele
            .querySelectorAll(selector)
            .forEach((elNew) => eleWraps.push(elNew));
    }
    else {
        eleWraps.push(ele);
    }
    if (!eleWraps.length) {
        return;
    }
    eleWraps.push(ele);
    ele[ELE_WRAPS] = eleWraps;
    //鼠标移入被缩放元素，样式更改、触发显示可被选缩放的边
    eleWraps.forEach((eleWrap) => {
        const { style } = eleWrap;
        const { zIndex, userSelect, position } = style;
        const cssStyle = window.getComputedStyle(eleWrap);
        let isEnter = false;
        let timer = null;
        const fnMouseEnter = (eleWrap[MOUSE_ENTER] = () => {
            if (isMouseDown) {
                return;
            }
            if (!['absolute', 'relative', 'fixed'].includes(cssStyle.position || '')) {
                style.position = 'relative';
            }
            isEnter = true;
            clearTimeout(timer);
            style.zIndex = '7';
            style.userSelect = 'none';
            let elInner = eleWrap.getElementsByClassName(OUT_LINE_CLASS_NAME)[0];
            if (!elInner) {
                elInner = document.createElement('div');
                elInner.setAttribute('class', OUT_LINE_CLASS_NAME);
                eleWrap.appendChild(elInner);
            }
            sticksInit(elInner, options);
            /**
             * 初始化拖拽
             * @param elInner 相对元素
             */
            function sticksInit(elInner, options) {
                const { style } = elInner;
                style.position = 'absolute';
                style.left = '0';
                style.top = '0';
                style.right = '0';
                style.bottom = '0';
                style.outline = '2px dotted rgb(201, 201, 201)';
                style.outlineOffset = '-2px';
                let sticks = (options && options.sticks) || ['bm', 'br', 'mr'];
                let resizeObj = new ResizeCmp();
                let fnMousedown = (e) => {
                    style.zIndex = '8';
                    isMouseDown = true;
                    let fnMousemove = (e) => {
                        resizeObj.move(e, elInner, options);
                    };
                    document.addEventListener('mousemove', fnMousemove);
                    let fnMouseup = (e) => {
                        isMouseDown = false;
                        isEnter = false;
                        // 销毁鼠标事件
                        document.removeEventListener('mousemove', fnMousemove);
                        document.removeEventListener('mouseup', fnMouseup);
                        document.removeEventListener('mousedown', fnMousedown);
                        fnMouseLeave();
                        resizeObj.up(e, elInner, options);
                    };
                    document.addEventListener('mouseup', fnMouseup);
                };
                sticks.forEach((stick) => {
                    //创建可选缩放的边
                    let _point = document.createElement('span');
                    let _style = 'display:inline-block;height:5px;position: absolute;';
                    switch (stick) {
                        case 'tl':
                            _style +=
                                'cursor: nwse-resize;left: 0;top: 0;transform: translate(50%,50%);';
                            break;
                        case 'tm':
                            _style +=
                                'cursor: ns-resize;left: 50%;top: 0;transform: translate(50%,-50%);';
                            break;
                        case 'tr':
                            _style +=
                                'cursor: nesw-resize;right: 0;top: 0;transform: translate(-50%,-50%);';
                            break;
                        case 'ml':
                            _style +=
                                'cursor: ew-resize;left: 0;top: 50%;transform: translate(-50%,-50%);';
                            break;
                        case 'bl':
                            _style +=
                                'cursor: nesw-resize;left: 0;bottom: 0;transform: translate(50%,-50%);';
                            break;
                        case 'bm':
                            _style +=
                                'cursor: ns-resize;bottom: 0;transform: translate(0%,50%);left: 0;right: 0;';
                            break;
                        case 'br':
                            _style +=
                                'cursor: nwse-resize;width: 0;right: -8px;height: 0;bottom: -8px;transform: translate(50%, 50%);z-index: 1;border: 8px solid #93ad93;' +
                                    'border-top-color: transparent; border-bottom-color: transparent; border-right-color: transparent; transform: rotateZ(45deg); ';
                            break;
                        case 'mr':
                            _style +=
                                'cursor: ew-resize;width: 5px;height:100%;right: 0px;top: 0;bottom: 0;transform: translate(50%,0%);';
                            break;
                        default:
                            break;
                    }
                    _point.setAttribute('style', _style);
                    _point.setAttribute('class', 'pi-vdr-stick pi-vdr-stick-' + stick);
                    _point.onmousedown = (ev) => {
                        resizeObj.stickDown(ev, stick, elInner, options);
                    };
                    elInner.appendChild(_point);
                });
                let stickArr = elInner.querySelectorAll('.pi-vdr-stick') || [];
                stickArr.forEach((el) => {
                    const ele = el;
                    ele.addEventListener('mousedown', fnMousedown);
                });
            }
        });
        const fnMouseLeave = (eleWrap[MOUSE_LEAVE] = () => {
            if (isMouseDown) {
                return;
            }
            isEnter = true;
            clearTimeout(timer);
            timer = setTimeout(() => {
                isEnter = false;
                if (!isEnter) {
                    [].slice
                        .call(eleWrap.getElementsByClassName(OUT_LINE_CLASS_NAME))
                        .forEach((ele) => {
                        eleWrap.removeChild(ele);
                    });
                    const { style } = eleWrap;
                    style.zIndex = zIndex;
                    style.userSelect = userSelect;
                    style.position = position;
                }
            }, 10);
        });
        if (!disabled) {
            eleWrap.addEventListener('mouseenter', fnMouseEnter);
            //鼠标移入被缩放元素，样式还原
            eleWrap.addEventListener('mouseleave', fnMouseLeave);
        }
    });
}
/**
 * 更新
 * @param el
 * @param binding
 */
function updateResize(el, binding) {
    //检测参数
    const options = binding.value;
    const disabled = !options || options.disabled;
    const ele = el;
    const eleWraps = ele[ELE_WRAPS];
    eleWraps &&
        eleWraps.length &&
        eleWraps.forEach((eleWrap) => {
            const fnEnter = eleWrap[MOUSE_ENTER];
            const fnLeave = eleWrap[MOUSE_LEAVE];
            fnEnter && eleWrap.removeEventListener('mouseenter', fnEnter);
            fnLeave && eleWrap.removeEventListener('mouseleave', fnLeave);
            if (!disabled) {
                eleWrap.addEventListener('mouseenter', fnEnter);
                eleWrap.addEventListener('mouseleave', fnLeave);
            }
        });
}
/**
 * 销毁
 * @param el
 * @param binding
 */
function destroyResize(el, binding) {
    //检测参数
    const ele = el;
    const eleWraps = ele[ELE_WRAPS];
    eleWraps &&
        eleWraps.length &&
        eleWraps.forEach((eleWrap) => {
            const fnEnter = eleWrap[MOUSE_ENTER];
            const fnLeave = eleWrap[MOUSE_LEAVE];
            fnEnter && eleWrap.removeEventListener('mouseenter', fnEnter);
            fnLeave && eleWrap.removeEventListener('mouseleave', fnLeave);
            eleWrap.removeAttribute(MOUSE_ENTER);
            eleWrap.removeAttribute(MOUSE_LEAVE);
        });
    ele.removeAttribute(ELE_WRAPS);
}
/**
 * 使用要求：
 * 1、boxSizing，当前拖动的元素boxSizing必须为'border-box'属性
 */
// 当被绑定的元素插入到 DOM 中时
//v-demo="{disabled:false,selector:'',minWidth:'',minHeight:'',callback:''}"
export const Resize = {
    bind: initResize,
    update: updateResize,
    unbind: destroyResize,
};
