import { __decorate } from "tslib";
import kebabCase from 'lodash/kebabCase';
import Vue from 'vue';
import { PermissionSettings, AjaxAbortName, ConvertUrl } from './config.base';
import { BaseConfigInfo } from 'bc$/urls/base-config-url';
import { Component, Prop } from 'vue-property-decorator';
import { hUGetCmpName } from 'bc$/utils/usually.helper';
let VueBase = class VueBase extends Vue {
    //#region 构造函数
    //#endregion
    //#region 静态方法与属性
    //#endregion
    //#region props
    /**
     * 弹窗内容需要挂载到的Node节点
     */
    piParentNodeFn;
    //#endregion
    //#region data
    /**
     * 保存延时器列表设置,在注销组件时执行销毁该延时器
     */
    configTimeOut = {};
    /**
     * 保存ajax请求列表设置,在注销组件时执行销毁该ajax请求
     */
    configAjax = {};
    //#endregion
    //#region computed
    get localStorage() {
        return window.localStorage;
    }
    get sessionStorage() {
        return window.sessionStorage;
    }
    /**
     * 自定义重置ajax的停止名称
     */
    get ajaxAbortName() {
        return AjaxAbortName;
    }
    /**
     * 权限列表
     */
    get permissionSettings() {
        return PermissionSettings;
    }
    /**
     * 基础全局配置信息
     */
    get baseConfigInfo() {
        return { ...BaseConfigInfo };
    }
    /**
     * 转换为绝对路径，该路径会根据类型随机获取url 基础地址并添加子站点url
     */
    get convertUrl() {
        return ConvertUrl;
    }
    //#endregion
    //#region methods
    /**
     * 广播事件
     * @param curCmp 当前Vue组件实例
     * @param componentName 组件名称,所有后代组件请传入undefined
     * @param eventName 事件名称
     * @param params 参数
     * @param deep 是否递归
     */
    $piBroadcast(curCmp, eventName, params, componentName, deep = false) {
        componentName && (componentName = kebabCase(componentName));
        curCmp.$children.forEach((child) => {
            const name = kebabCase(hUGetCmpName.call(child));
            if (!componentName || name === componentName) {
                child.$emit(eventName, params);
            }
            deep && this.$piBroadcast(child, eventName, params, componentName, deep);
        });
    }
    /**
     * 递推冒泡事件
     * @param curCmp 当前Vue组件实例
     * @param componentName 组件名称,所有祖先组件请传入undefined
     * @param eventName 事件名称
     * @param params 参数
     * @param deep 是否递推
     */
    $piDispatch(curCmp, eventName, params, componentName, deep = false) {
        let parent = curCmp.$parent || curCmp.$root;
        if (parent === curCmp.$root) {
            deep = false;
        }
        if (componentName) {
            let name = kebabCase(hUGetCmpName.call(parent));
            componentName && (componentName = kebabCase(componentName));
            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;
                parent && (name = kebabCase(hUGetCmpName.call(parent)));
            }
        }
        if (parent) {
            parent.$emit(eventName, params);
            deep && this.$piDispatch(parent, eventName, params, componentName, deep);
        }
    }
    /**
     * 根据名称从延时器列表对象中获取延时器,如果不存在就添加一个并返回该对象值
     * @param name 延时器名称
     */
    getTimeOutInfo(name) {
        const configTimeOut = this.configTimeOut, timeOutInfo = (configTimeOut[name] = configTimeOut[name] || {
            value: undefined,
        });
        return timeOutInfo;
    }
    /**
     * 清除之前的延时器,如果之前未设置停止则重新设置回调函数
     * @param name 延时器名称
     * @param callback 要设置的延时回调函数
     * @param iTime 延时时间,单位毫秒
     */
    resetTimeOut(name, callback, iTime) {
        let timeOutInfo = this.getTimeOutInfo(name);
        timeOutInfo.value && clearTimeout(timeOutInfo.value);
        if (callback && typeof callback === 'function') {
            timeOutInfo.value = window.setTimeout(() => {
                this && this.removeTimeOut && this.removeTimeOut(false, name);
                callback && callback.apply(this);
            }, iTime);
        }
        this.configTimeOut[name] = timeOutInfo;
        return timeOutInfo;
    }
    /**
     * 清除当前组件实例的延时任务
     * @param isAll 是否清除全部,如果传入的是字符串则或略本参数,将字符串赋值给name参数,默认为true
     * @param name 延时器名称,默认为''
     */
    removeTimeOut(isAll = true, name = '') {
        //如果直接传入的是字符串则将其视为name,模拟重载
        if (typeof isAll === 'string') {
            name = isAll;
            isAll = false;
        }
        //处理延时器列表对象
        const { configTimeOut } = this, fn = (info) => {
            if (info) {
                //清除延时器
                info.value && clearTimeout(info.value);
            }
        };
        if (isAll) {
            for (const key in configTimeOut) {
                fn(configTimeOut[key]);
            }
            //清除延时器对象
            this.configTimeOut = {};
        }
        else {
            fn(configTimeOut[name]);
            delete configTimeOut[name];
        }
    }
    /**
     * 清除当前组件实例的延时任务
     * @param fn 根据函数执行结果来判断是否需要删除延时器,参数为遍历得到的延时任务名称
     */
    removeTimeOutByFn(fn) {
        //处理延时器列表对象
        const configTimeOut = this.configTimeOut;
        const keys = Object.keys(configTimeOut);
        for (const key of keys) {
            if (fn(key)) {
                this.removeTimeOut(false, key);
            }
        }
    }
    /**
     * 根据名称从ajax 列表对象中获取ajax,如果不存在就添加一个并返回该对象值
     * @param name ajax名称
     */
    getAjaxInfo(name) {
        const configAjax = this.configAjax, ajaxInfo = (configAjax[name] = configAjax[name] || {
            xhr: undefined,
        });
        return ajaxInfo;
    }
    /**
     * 清除当前组件实例的ajax任务
     * @param isAll 是否清除全部,如果传入的是字符串则或略本参数,将字符串赋值给name参数,默认为true
     * @param name 延时器名称,默认为''
     */
    removeAjax(isAll = true, name = '') {
        //如果直接传入的是字符串则将其视为name,模拟重载
        if (typeof isAll === 'string') {
            name = isAll;
            isAll = false;
        }
        //处理延时器列表对象
        let configAjax = this.configAjax, fn = (info) => {
            if (info) {
                //终止ajax
                info.cancelFunc && info.cancelFunc(this.ajaxAbortName);
            }
        };
        if (isAll) {
            for (const key in configAjax) {
                fn(configAjax[key]);
            }
        }
        else {
            fn(configAjax[name]);
        }
        //清除ajax对象
        if (isAll) {
            this.configAjax = {};
        }
        else {
            delete this.configAjax[name];
        }
    }
    /**
     * 清除之前的ajax,如果之前未设置停止则重新设置ajax请求
     * @param name 延时器名称
     * @param fnFactory 要设置的回调函数,函数需要返回JQueryXHR
     */
    resetAjax(name, fnFactory) {
        const ajaxInfo = this.getAjaxInfo(name);
        ajaxInfo.cancelFunc && ajaxInfo.cancelFunc(this.ajaxAbortName);
        if (fnFactory && typeof fnFactory === 'function') {
            const ajaxInfoNew = fnFactory.apply(this);
            ajaxInfo.instance = ajaxInfoNew.instance;
            ajaxInfo.cancelFunc = ajaxInfoNew.cancelFunc;
            ajaxInfo.promise = ajaxInfoNew.promise;
        }
        return ajaxInfo;
    }
    //#endregion
    //#region hooks
    /**
     * 销毁实例前
     */
    beforeDestroy() {
        //清除全部延时任务
        this.removeTimeOut();
        //清除全部ajax任务
        this.removeAjax();
    }
};
__decorate([
    Prop({
        type: Function,
    })
], VueBase.prototype, "piParentNodeFn", void 0);
VueBase = __decorate([
    Component
], VueBase);
export { VueBase };
