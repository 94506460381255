var PiBaseCenter_1;
import { __decorate } from "tslib";
import { PiBaseStorePageModule } from 'bc$/store/modules/pi-base-page.store';
import { RowEntity, ColumnEntity, WidgetInstanceEntity, } from 'bc$/entities/layout';
import { Component, Watch, Prop, Mixins } from 'vue-property-decorator';
import { default as PiBaseRow } from 'bc$/components/pi-base-row/pi-base-row.component';
import { VueBase } from 'bc$/bases/vue.base';
import { BcVersion } from 'bc$/bases/config.base';
import { PiBaseStoreLayoutModule } from 'bc$/store/modules/pi-base-layout.store';
import { LayoutMode, SortableJsOptionName } from 'bc$/enums/layout/layout.enum';
import { getGridTemplate, revertDom } from './pi-base-center.utils';
import { default as PiBaseColumn } from '../pi-base-column/pi-base-column.component';
import { PiStatusEnum, ConvertUrl, UrlType, hAjaxPostJson, AjaxAbortName, hErrorShow, hAjaxAll, } from 'bc$/main';
import { ApiUrlSetting } from 'bc$/urls';
import { PiBaseStoreWidgetModule } from 'bc$/store';
import Vue from 'vue';
Vue.component('PiBaseRow', PiBaseRow);
Vue.component('PiBaseColumn', PiBaseColumn);
import template from './pi-base-center.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-center.component.scss';
let PiBaseCenter = PiBaseCenter_1 = class PiBaseCenter extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * sortableJs 分组名，用于判断拖拽逻辑
     */
    static SORTABLEJS_GROUP_NAME = SortableJsOptionName.row;
    /**
     * columnPkId 为 0 代表是根节点
     */
    static ROOT_ROWS_COLUMN_PKID = '0';
    /**
     * 组件名称
     */
    static NAME = 'PiBaseCenter';
    /**
     * 请求错误信息：grid布局信息
     */
    static LOAD_GRID_DATA_FAIL = `Component ${PiBaseCenter_1?.NAME} request page rows and columns data fail:`;
    /**
     * 更新行错误信息
     */
    static UPDATE_ROW_DATA_FAIL = `Component ${PiBaseCenter_1?.NAME} update row data fail:`;
    /**
     * 更新行错误信息
     */
    static GET_WIDGET_INSTANCES_INFO_FAIL = `Component ${PiBaseCenter_1?.NAME} get widget instance info fail:`;
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseCenter_1?.NAME,
        author: 'caiqw',
        title: '基础组件预览组件',
        version: BcVersion,
        updateDate: '2019-05-21',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * 当前所在页面唯一页签id
     */
    tabId;
    //#endregion
    //#region data
    /**
     * 标记 Vue 实例
     */
    _IS_CENTER_COMPONENT = true;
    /**
     * 行 grid 布局样式
     */
    rowsStyle = '';
    /**
     * 是否显示所有行列边框
     */
    isBorderLayout = false;
    /**
     * sortableJs options
     */
    rowSortableJsOptions = {
        sort: false,
        group: {
            name: PiBaseCenter_1.SORTABLEJS_GROUP_NAME,
            pull: this.sortableJsPull,
            put: this.sortableJsPut,
        },
        onStart: this.sortableJsEventStart,
        onEnd: this.sortableJsEventEnd,
        handle: '.pi-row-head',
        draggable: '.pi-widget-pi-base-row',
        easing: 'cubic-bezier(1, 0, 0, 1)',
        animation: 500,
    };
    //#endregion
    //#region computed
    get about() {
        return PiBaseCenter_1.ABOUT;
    }
    get titleAll() {
        return this.about.title;
    }
    /**
     * store中存储的页面相关的信息
     */
    get storePage() {
        return PiBaseStorePageModule;
    }
    /**
     * store中存储的布局相关的信息
     */
    get storeLayout() {
        return PiBaseStoreLayoutModule;
    }
    /**
     * store中存储的组件相关的信息
     */
    get storeWidget() {
        return PiBaseStoreWidgetModule;
    }
    /**
     * 现在页面是否处于页面布局编辑模式
     */
    get isLayoutMode() {
        return this.storeLayout.layoutMode === LayoutMode.design;
    }
    /**
     * 获取选中的行/列
     */
    get rowsOrColumnsSelected() {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        return (tab && tab.RowsOrColumnsSelected) || {};
    }
    /**
     * 当前组件下的行数据
     */
    get rows() {
        const tab = this.storeLayout.tabs.find((p) => p.tabId === this.tabId);
        const rootRowColumnPkid = PiBaseCenter_1.ROOT_ROWS_COLUMN_PKID;
        return ((tab &&
            tab.Rows.filter((item) => item.columnPkId === rootRowColumnPkid)) ||
            []);
    }
    //#endregion
    //#region watch
    /**
     * 根据行数据筛选出根 rows 和更新行布局
     * @param newVal
     */
    'watch.rows'(newVal, oldVal) {
        const defaultHeight = 100;
        // 行信息发生变化时更新行布局
        const { template, total } = getGridTemplate(newVal, true);
        // 处理高度值，用于处理滚动条问题
        this.rowsStyle = `height: ${total || defaultHeight}%; `; // this.rowsStyle = `height: ${total < 100 ? 100 : total}%; `;
        this.rowsStyle += `grid-template-rows: ${template};`;
    }
    /**
     * 根据选中的行列数量，更新 isBorderLayout
     *  @param newVal 选中的行列数据
     */
    'watch.rowsOrColumnsSelected'(newVal, oldVal) {
        this.isBorderLayout = newVal && newVal.length ? true : false;
    }
    /**
     * 观察布局模式变化
     * @param newVal
     */
    'watch.isLayoutMode'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        if (newVal) {
            this.rowSortableJsOptions.filter = undefined;
        }
        else {
            this.rowSortableJsOptions.filter = '*';
        }
    }
    /**
     * 获取布局行列信息和组件实例信息
     */
    'watch.tabId'(newVal, oldVal) {
        if (newVal === oldVal) {
            return;
        }
        const tab = this.storePage.tabs.find((p) => p.tabId === newVal);
        // 获取页面行列布局信息与业务组件实例信息
        this.getPageComposition(tab.pkid);
    }
    //#endregion
    //#region methods
    /**
     * 判断当前组件组件名称
     */
    getSortableJsGroupName() {
        return PiBaseCenter_1.SORTABLEJS_GROUP_NAME;
    }
    /**
     * sortableJs pull 回调
     */
    sortableJsPull(to, from) {
        return from.el.childElementCount > 1;
    }
    /**
     * sortableJs put 回调
     */
    sortableJsPut(to, from) {
        const fromEL = from.el;
        if (fromEL.className.indexOf('pi-widget') !== -1) {
            // 业务组件不允许放入center中（从右侧组件菜单拖动到当前组件）
            return false;
        }
        return true;
    }
    /**
     * Element dragging started
     * @param event
     */
    sortableJsEventStart(event) {
        // 禁用滚动条，优化性能
        this.$piDispatch(this, 'updateScrollBarState', false, 'PiBasePageLayout', true);
    }
    /**
     * Element dragging ended
     * @param event
     */
    sortableJsEventEnd(event) {
        const eleFrom = event.from;
        const draggableIndexFrom = event.oldDraggableIndex;
        const indexFrom = event.oldIndex;
        const vueFrom = eleFrom['__vue__'];
        const rowFrom = Object.assign({}, vueFrom.rows[draggableIndexFrom]);
        const eleTo = event.to;
        const draggableIndexTo = event.newDraggableIndex;
        const indexTo = event.newIndex;
        const vueTo = eleTo['__vue__'];
        const columnTo = vueTo.column;
        // const toRows = toVue.rows.slice(0);
        // 恢复真实 dom
        revertDom(eleTo, eleFrom, indexTo, indexFrom);
        this.$nextTick(() => {
            this.reqRowUpdate({
                pkid: rowFrom.pkid,
                columnPkId: columnTo.pkid,
                orderNo: draggableIndexTo,
                attrJson: rowFrom.attrJson,
            }, () => {
                this.$nextTick(() => {
                    // 启用滚动条
                    this.$piDispatch(this, 'updateScrollBarState', true, 'PiBasePageLayout', true);
                });
            });
        });
    }
    /**
     * 更新行信息
     * @param rowUpdateParam
     * @param cbFunc
     */
    reqRowUpdate(rowUpdateParam, cbFunc) {
        if (!rowUpdateParam || JSON.stringify(rowUpdateParam) === '{}') {
            return;
        }
        const reqUrl = ConvertUrl(ApiUrlSetting.WidgetConfig.PiBaseRow.RowUpdateUrl, UrlType.Api);
        return this.resetAjax('reqRowUpdate', () => {
            const ajaxInfo = hAjaxPostJson(reqUrl, rowUpdateParam);
            ajaxInfo.instance
                .then((res) => {
                const data = res.data || null;
                if (data.status === PiStatusEnum.Success) {
                    // 后台请求成功
                    const respRows = data.data && data.data.length ? data.data[0] : null;
                    if (respRows) {
                        // 将返回值和提交值合并
                        const reqRow = rowUpdateParam;
                        if (!respRows) {
                            return;
                        }
                        const cloneRespRows = respRows.map((item) => {
                            if (item.pkid === reqRow.pkid) {
                                return Object.assign({}, reqRow, item);
                            }
                            return item;
                        });
                        // 更新 Vuex 中的rows, 强行转换类型  IRowUpdateParams => RowEntity
                        this.storeLayout.updateRows({
                            tabId: this.tabId,
                            rows: cloneRespRows,
                        });
                        this.$Message.success('更新行信息成功！');
                    }
                }
                else {
                    this.$Message.error('更新行信息失败！');
                    // 后台请求失败，emit 错误信息
                    console.error(`${PiBaseCenter_1.UPDATE_ROW_DATA_FAIL} ${data ? data.msg : res.statusText}`);
                    this.$emit('onUpdateRowDataError', res);
                }
            })
                .then(() => {
                cbFunc && cbFunc();
            })
                .catch((err) => hErrorShow({
                context: this,
                err,
                showInPage: true,
                message: '更新行信息错误！',
                callback: () => {
                    if (err.message !== AjaxAbortName) {
                        cbFunc && cbFunc();
                        this.$emit('onUpdateRowDataError', err);
                    }
                },
            }));
            return ajaxInfo;
        });
    }
    /**
     * 获取页面行列布局信息与业务组件实例信息
     * @param pagePkId 页面id
     */
    getPageComposition(pagePkId) {
        let msg = '';
        const rowsColumnsLoadUrl = ApiUrlSetting.WidgetConfig.PiBaseCenter.RowsColumnsLoadUrl;
        if (!pagePkId || !rowsColumnsLoadUrl) {
            msg = '未正确配置获取行列布局的url信息！';
        }
        const widgetInstancesByPageUrl = ApiUrlSetting.WidgetConfig.PiBaseCenter.WidgetInstancesByPageUrl;
        if (!pagePkId || !widgetInstancesByPageUrl) {
            msg = '未正确配置获取页面业务组件清单的url信息！';
        }
        if (msg) {
            this.$Message.error(msg);
            console.error(msg, pagePkId);
            return;
        }
        const reqUrlWidget = `${ConvertUrl(widgetInstancesByPageUrl, UrlType.Api)}?pagePkId=${pagePkId}`;
        const reqUrlRowCol = `${ConvertUrl(rowsColumnsLoadUrl, UrlType.Api)}?pagePkId=${pagePkId}`;
        return this.resetAjax('getPageComposition', () => {
            const ajaxInfo = hAjaxAll([
                {
                    url: reqUrlRowCol,
                    method: 'get',
                },
                {
                    url: reqUrlWidget,
                    method: 'get',
                },
            ]);
            ajaxInfo.instance
                .then((res) => {
                this.setRowsColumnsLoad(res[0]);
                this.setWidgetInstanceInfos(res[1]);
            })
                .catch((err) => {
                hErrorShow({
                    err,
                    message: '获取页面行列布局信息与业务组件实例信息时发生错误！',
                    callback: () => {
                        if (err.message !== AjaxAbortName) {
                            this.$emit('onLoadGridDataError', err);
                            this.$emit('onGetWidgetInstancesInfoError', err);
                        }
                    },
                });
            });
            return ajaxInfo;
        });
    }
    /**
     * 页面布局信息请求
     */
    setRowsColumnsLoad(res) {
        const data = res.data || null;
        if (!data || data.status !== PiStatusEnum.Success) {
            // 后台请求失败，emit 错误信息
            const errMsg = `${PiBaseCenter_1.LOAD_GRID_DATA_FAIL} ${res}`;
            console.error(errMsg);
            this.$Message.error(errMsg);
            this.$emit('onLoadGridDataError', res);
            return;
        }
        // 后台请求成功
        const resultData = data.data ? data.data[0] : null;
        let pageColumns = [];
        let pageRows = [];
        if (resultData) {
            pageColumns = resultData.columns.map((p) => new ColumnEntity(p));
            pageRows = resultData.rows.map((p) => new RowEntity(p));
        }
        this.storeLayout.addColumns({
            tabId: this.tabId,
            columns: pageColumns,
            isClear: true,
        });
        this.storeLayout.addRows({
            tabId: this.tabId,
            rows: pageRows,
            isClear: true,
        });
    }
    /**
     * 获取当前页面组件实例
     */
    setWidgetInstanceInfos(res) {
        const data = res.data || null;
        if (!data || data.status !== PiStatusEnum.Success) {
            // 后台请求失败，emit 错误信息
            console.error(`${PiBaseCenter_1.GET_WIDGET_INSTANCES_INFO_FAIL} ${res}`);
            this.$emit('onGetWidgetInstancesInfoError', res);
            return;
        }
        // 后台请求成功
        const dData = data.data || null;
        const resultData = dData.map((p) => new WidgetInstanceEntity(p));
        this.storeWidget.setWidgetInstanceInfos({
            tabId: this.tabId,
            data: resultData,
        });
    }
};
__decorate([
    Prop({ required: true })
], PiBaseCenter.prototype, "tabId", void 0);
__decorate([
    Watch('rows', { immediate: true, deep: true })
], PiBaseCenter.prototype, "watch.rows", null);
__decorate([
    Watch('rowsOrColumnsSelected', { deep: true })
], PiBaseCenter.prototype, "watch.rowsOrColumnsSelected", null);
__decorate([
    Watch('isLayoutMode')
], PiBaseCenter.prototype, "watch.isLayoutMode", null);
__decorate([
    Watch('tabId', { immediate: true })
], PiBaseCenter.prototype, "watch.tabId", null);
PiBaseCenter = PiBaseCenter_1 = __decorate([
    Component({
        template,
    })
], PiBaseCenter);
export default PiBaseCenter;
