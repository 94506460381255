import { PiBaseStorePageModule, PiBaseStoreUserModule,
// PiBaseStoreWidgetModule,
// PiBaseStoreLayoutModule,
 } from 'bc$/main';
/**
 * 清空 Vuex store 存储的所有数据
 */
const clearStore = function () {
    PiBaseStorePageModule.emptyStore();
    PiBaseStoreUserModule.emptyStore();
    // PiBaseStoreWidgetModule.emptyStore();
    // PiBaseStoreLayoutModule.emptyStore();
};
export { clearStore as hVuexClearStore };
