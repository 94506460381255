var PiBaseTreeVue_1;
import { __decorate } from "tslib";
import { Watch, Prop, Component, Mixins } from 'vue-property-decorator';
import { hODeepClone, VueBase, BcVersion } from 'bc$/main';
import template from './pi-base-tree-vue.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-tree-vue.component.scss';
import { Icon } from 'iview';
import { asyncPullNodeChildren, clearUniProperties, defineUniProperty, isFunction, isPromise, removeUniProperty, transPiNodeToPiTreeDataAsync, transPiNodeToPiTreeDataSync, transPiTreeDataToPiNode, tryNodesTreeStateBeConsistent, _toString, } from './pi-base-tree-vue-base';
import { Tree as PiTree } from 'tree-vue-component';
let PiBaseTreeVue = PiBaseTreeVue_1 = class PiBaseTreeVue extends Mixins(VueBase) {
    //#region constructor
    //#endregion
    //#region property
    /**
     * 组件名称
     */
    static NAME = 'PiBaseTreeVue';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseTreeVue_1?.NAME,
        author: 'zhanjx',
        title: '前端通用树组件',
        version: BcVersion,
        updateDate: '2021-08-27',
        description: '',
    };
    //#endregion
    //#region props
    /**
     * Specifies the currently selected node, corresponding to the ID of the PiNode.
     * If the array is passed, it represents a multiple-selected string or a numeric value,
     * it is selected alone. Otherwise, no node is selected
     */
    value;
    /**
     * Tree data placed by default by the user
     */
    defaultOptions;
    /**
     * The corresponding tree nodes are deployed by specifying the default set of ids
     */
    defaultExpandedIds;
    /**
     * Load Asynchronous callback Load data method
     */
    loadOptions;
    /**
     * Whether the tree can be dragged
     * TODO:(Currently, the asynchronous tree is not supported)
     */
    draggable;
    /**
     * Enable multiple checkboxes
     */
    checkbox;
    /**
     * Text prompted when there is no underlying data
     */
    noneText;
    /**
     * Text prompted when a request for underlying data fails
     */
    errorText;
    //#endregion
    //#region data
    /**
     * Tree data for the current response
     */
    treeData = [];
    /**
     * Id set of selected nodes
     */
    selectIds;
    rollIds;
    /**
     * The selected node collector:
     * Collect two different types of ids
     * That is, numeric type or string type
     */
    selectedCollector = {
        Number: {},
        String: {},
    };
    /**
     * Times of processing results of asynchronous requests
     */
    asyncRecord = 0;
    /**
     * he tree node that is currently being dragged
     */
    dragTarget = null;
    //#endregion
    //#region watch
    /**
     * Father to child of bidirectional binding
     */
    'watch.value'(newValue, oldValue) {
        // console.log('newSelectIds->', newValue);
        switch (_toString(newValue)) {
            // Numeric or string type or array type
            case 'Number':
            case 'String':
            case 'Array':
                if (newValue !== oldValue) {
                    this.selectIds = newValue;
                }
                break;
            default:
                this.selectIds = undefined;
                break;
        }
    }
    /**
     * Two-way bond from child to father
     */
    'watch.selectIds'(newValue, oldValue) {
        // console.log('newSelectIds->', newValue);
        switch (_toString(newValue)) {
            // Numeric or string type or array type
            case 'Number':
            case 'String':
            case 'Array':
                if (newValue !== oldValue) {
                    this.selectIds = newValue;
                    this.$emit('input', newValue);
                }
                break;
            default:
                this.selectIds = undefined;
                break;
        }
    }
    /**
     * Listening for asynchronous methods
     */
    'watch.loadOptions'(newLoadOptions) {
        // console.log('newLoadOptions->', newLoadOptions);
        // The asynchronous request exists and returns a Promise
        if (isFunction(newLoadOptions) && isPromise(newLoadOptions(null))) {
            if (this.defaultOptions &&
                _toString(this.defaultOptions) == 'Array' &&
                this.defaultOptions.length > 0) {
                // Do deep pull substitutions according to defaultOptions
                (async () => {
                    let index = 0;
                    for (const node of this.defaultOptions) {
                        const temp = { node, level: 0, path: [index++] };
                        this.treeData.push(await transPiNodeToPiTreeDataAsync(temp, newLoadOptions, this.value, this.selectedCollector, this.noneText, this.errorText));
                    }
                })()
                    .finally(() => {
                    this.$emit('async-result', this.openTreeData, this.openTreeData, ++this.asyncRecord);
                })
                    .catch();
            }
            else {
                // Do a deep pull substitution based on the first request
                (async () => {
                    const nodes = await newLoadOptions(null);
                    let index = 0;
                    for (const node of nodes) {
                        const temp = { node, level: 0, path: [index++] };
                        this.treeData.push(await transPiNodeToPiTreeDataAsync(temp, newLoadOptions, this.value, this.selectedCollector, this.noneText, this.errorText));
                    }
                })()
                    .finally(() => {
                    this.$emit('async-result', this.openTreeData, this.openTreeData, ++this.asyncRecord);
                })
                    .catch();
            }
        }
        else {
            // Static transformation (no asynchronous requests)
            this.defaultOptions.forEach((node, index) => {
                const temp = { node, level: 0, path: [index] };
                transPiNodeToPiTreeDataSync(temp, this.value, this.selectedCollector);
            });
        }
    }
    //#endregion
    //#region computed
    /**
     * Component About Information
     */
    get about() {
        return PiBaseTreeVue_1.ABOUT;
    }
    /**
     * The component title
     */
    get titleAll() {
        return this.about.title;
    }
    /**
     * Current tree data to PiNode node tree data
     */
    get openTreeData() {
        const openTreeData = [];
        for (const node of this.treeData) {
            openTreeData.push(transPiTreeDataToPiNode(node));
        }
        return openTreeData;
    }
    /**
     * Whether the state is asynchronous
     */
    get isAsync() {
        return isFunction(this.loadOptions) && isPromise(this.loadOptions(null));
    }
    /**
     * Radio mode
     */
    get singleMode() {
        return (_toString(this.selectIds) === 'String' ||
            _toString(this.selectIds) === 'Number');
    }
    /**
     * Multiselect mode
     */
    get multipleMode() {
        return _toString(this.selectIds) === 'Array';
    }
    //#endregion
    //#region methods
    /**
     * Get the tree node according to path
     */
    getNodeFromPath(rootData, path, callback = undefined) {
        let node = null;
        for (const index of path) {
            node = node ? node.children[index] : rootData[index];
            if (!node) {
                return null;
            }
            // If a callback is defined, the callback is executed
            !!callback && callback(node);
        }
        return node;
    }
    /**
     * Traverse the tree data according to the hierarchy
     * @param treeData
     * @param callback The callback operation
     */
    ergodicTreebyHierarchy(treeData, callback, level = 0) {
        if (treeData.length > 0) {
            const layerNodes = [];
            for (const treeNode of treeData) {
                callback(treeNode, level);
                layerNodes.push(...treeNode.children);
            }
            this.ergodicTreebyHierarchy(layerNodes, callback, level + 1);
        }
    }
    /**
     * Traverse the tree data according to the sublevels
     * @param treeData
     * @param callback
     */
    ergodicTreebyChildren(treeData, callback) {
        if (treeData.length > 0) {
            for (const treeNode of treeData) {
                callback(treeNode);
            }
            for (const treeNode of treeData) {
                this.ergodicTreebyChildren(treeNode.children, callback);
            }
        }
    }
    /**
     * Expand the trigger event
     * @param eventData
     * @param customComponent
     * @param next
     */
    toggle(eventData, customComponent, // 定制的节点component
    next) {
        // console.log('toggle eventData->', eventData);
        const { data, path } = eventData;
        // Expand the state to take the inverse
        data.state.opened = !data.state.opened;
        // Upgrade notification
        this.$emit('node-toggle', transPiTreeDataToPiNode(data));
        // The data node is not loaded and the current tree is asynchronous
        if (!data.loaded && this.isAsync) {
            // The node loading icon is displayed
            data.state.loading = true;
            // Asynchronously pull tree data
            asyncPullNodeChildren(data, this.loadOptions, this.selectIds, this.selectedCollector, this.noneText, this.errorText)
                .finally(() => {
                // The node loading icon is closed
                data.state.loading = false;
                data.children.forEach((child) => {
                    // If the selected node exists in the loaded tree node
                    if (child.state.selected) {
                        // When a node is selected, the collection of the selected collector is updated
                        this.changeIdsBySelectNode(child);
                    }
                });
                this.$emit('async-result', this.openTreeData, transPiTreeDataToPiNode(data), ++this.asyncRecord);
                if (next) {
                    next();
                }
            })
                .catch(() => {
                data.state.loading = false;
            });
        }
    }
    /**
     * General node click event
     * @param eventData
     */
    change(eventData) {
        console.log('change eventData->', eventData);
        const { data, path } = eventData;
        // Click items are not disabled
        if (!data.state.disabled) {
            // Take the operation
            data.state.selected = !data.state.selected;
            // Notifying the parent
            this.$emit('change', { data: transPiTreeDataToPiNode(data), path });
            // If it is inverted, it is selected
            if (data.state.selected) {
                // When a node is selected, the collection of the selected collector is updated
                this.changeIdsBySelectNode(data);
            }
            else {
                // In the cancel state, cancel the ID
                this.changeIdsByUnselectNode(data);
            }
        }
    }
    /**
     * Activate the status of the parent node
     * @param node
     */
    selectParentChain(node) {
        // Gets the parent of the current node
        const parent = this.getNodeFromPath(this.treeData, node.path.slice(0, node.path.length - 1));
        if (!!parent) {
            // Place from the ID set when the previous state was not selected
            if (!parent.state.selected && !this.singleMode) {
                // After the node cancels, the collection of the selected collector is updated
                this.changeIdsBySelectNode(parent);
            }
            parent.state.selected = true;
            // Move up to activate the selected state of the node
            this.selectParentChain(parent);
        }
    }
    /**
     * Deselect the parent nodes of all nodes
     * @param node
     */
    unselectParentChain(node) {
        // Gets the parent of the current node
        const parent = this.getNodeFromPath(this.treeData, node.path.slice(0, node.path.length - 1));
        // console.log('parent->', parent);
        if (!!parent) {
            // Place from the ID set when the previous state was not selected
            if (parent.state.selected && !this.singleMode) {
                // Refresh the selected ID set
                this.changeIdsByUnselectNode(parent);
            }
            parent.state.selected = false;
            // Move up to activate the selected state of the node
            this.unselectParentChain(parent);
        }
    }
    /**
     * Select status of all nodes below current node
     * @param node
     */
    selectChildrenChain(node) {
        if (node.children.length > 0) {
            for (const child of node.children) {
                // If the status is not selected before the change, it will be erased from the ID set
                if (!child.state.selected && !this.singleMode) {
                    this.changeIdsBySelectNode(child);
                }
                child.state.selected = true;
                // Proceed to deselect the node
                this.unselectChildrenChian(child);
            }
        }
    }
    /**
     * Example Deactivate the selected status of all nodes below this node
     * @param node
     */
    unselectChildrenChian(node) {
        if (node.children.length > 0) {
            for (const child of node.children) {
                // If the status is not selected before the change, it will be erased from the ID set
                if (child.state.selected && !this.singleMode) {
                    this.changeIdsByUnselectNode(child);
                }
                child.state.selected = false;
                // Proceed to deselect the node
                this.unselectChildrenChian(child);
            }
        }
    }
    /**
     * the collection of the selected collector is updated When a node is selected or deselected
     * @param selectNode
     */
    changeIdsBySelectNode(selectNode) {
        // Gets the selected collector of the corresponding type
        const collector = this.selectedCollector[_toString(selectNode.id)];
        console.log('collector', collector);
        switch (_toString(this.selectIds)) {
            // Belongs to the multi-choice mode
            case 'Array':
                if (!this.selectIds.includes(selectNode.id)) {
                    this.selectIds.push(selectNode.id);
                }
                // Place in the selected collector of the corresponding type
                defineUniProperty(collector, selectNode.id, selectNode);
                break;
            // It is an option mode
            case 'Number':
            case 'String':
                if (this.selectIds !== selectNode.id) {
                    this.selectIds = selectNode.id;
                    // Updates the collection object status for the selected collector of the corresponding type
                    for (const key of Object.keys(collector)) {
                        collector[key].state.selected = false;
                    }
                    // Clears the collection of the selected collector of the corresponding type
                    if (clearUniProperties(collector)) {
                        // Make sure you check Settings
                        selectNode.state.selected = true;
                        // Place in the selected collector of the corresponding type
                        defineUniProperty(collector, selectNode.id, selectNode);
                    }
                }
                break;
        }
    }
    /**
     * The ID set of a node is changed after the node is cancelled. Procedure
     * @param unselectNode
     */
    changeIdsByUnselectNode(unselectNode) {
        // Gets the selected collector of the corresponding type
        const collector = this.selectedCollector[_toString(unselectNode.id)];
        switch (_toString(this.selectIds)) {
            // Belongs to the multi-choice mode
            case 'Array':
                const fIndex = this.selectIds.findIndex((id) => {
                    return id == unselectNode.id;
                });
                if (!!~fIndex) {
                    this.selectIds.splice(fIndex, 1);
                }
                removeUniProperty(collector, unselectNode.id);
                break;
            // It is an option mode
            case 'Number':
            case 'String':
                if (this.selectIds === unselectNode.id) {
                    this.selectIds = '';
                    for (const key of Object.keys(collector)) {
                        collector[key].state.selected = false;
                    }
                    if (clearUniProperties(collector)) {
                        // Make sure the Settings are not selected
                        unselectNode.state.selected = false;
                    }
                }
                break;
        }
    }
    /**
     * find the tree-node common path
     * @param paths
     * @returns
     */
    findCommonPath = (...paths) => {
        if (paths.length == 0 || paths.length == 1) {
            // Returns an empty array (representing that the existing public path is empty)
            return [];
        }
        else if (paths.length == 2) {
            let index = 0;
            const path1 = paths[0];
            const path2 = paths[1];
            const commonPath = [];
            if (path1.length == 0 || path2.length == 0) {
                // Returns an empty array (representing that the existing public path is empty)
                return commonPath;
            }
            else if (path1.length < path2.length) {
                for (const num of path1) {
                    if (num != path2[index]) {
                        break;
                    }
                    commonPath.push(num);
                }
                return commonPath;
            }
            else {
                for (const num of path2) {
                    if (num != path1[index]) {
                        break;
                    }
                    commonPath.push(num);
                }
                return commonPath;
            }
        }
        else {
            // It happens to be a multiple of two
            if (paths.length % 2 == 0) {
                const commonPaths = [];
                // Start pairwise grouping
                for (let index = 0; index + 2 <= paths.length; index += 2) {
                    commonPaths.push(this.findCommonPath(...paths.slice(index, index + 2)));
                }
                return this.findCommonPath(...commonPaths);
            }
            else {
                // Extract the last path individually
                const lastPath = paths[paths.length - 1];
                // The even entries continue to find the common path recursively
                const commonPath = this.findCommonPath(...paths.slice(0, paths.length - 1));
                if (lastPath.length == 0 || commonPath.length == 0) {
                    return [];
                }
                // The even terms continue to recurse
                return this.findCommonPath(lastPath, commonPath);
            }
        }
    };
    /**
     *
     * Asynchronously refresh all the content under a subtree
     * @param nodeTree Sub root node
     * @param oldDominant Whether the original node tree data is the dominant refresh (specifically, the replacement of state)
     */
    asyncRefreshNodeTree(nodeTree, oldDominant = false) {
        if (this.isAsync) {
            const oldTree = hODeepClone(nodeTree);
            nodeTree.state.loading = true;
            asyncPullNodeChildren(nodeTree, this.loadOptions, this.selectIds, this.selectedCollector, this.noneText, this.errorText)
                .finally(() => {
                nodeTree.state.loading = false;
                // Is dominated by the original node state
                if (oldDominant) {
                    tryNodesTreeStateBeConsistent(nodeTree, oldTree);
                }
                this.$emit('async-result', this.openTreeData, transPiTreeDataToPiNode(nodeTree), ++this.asyncRecord);
            })
                .catch();
        }
    }
    /**
     * Check whether the current node can be dragged
     */
    dropAllowed(dropData) {
        // Disabled items cannot be inserted by dragging
        if (dropData.targetData.state.disabled) {
            return false;
        }
        /**
         * The length of the target path is smaller than the original path length.
         * means that the parent node in the same path is not dragged to its child node.
         */
        if (dropData.targetPath.length < dropData.sourcePath.length) {
            return true;
        }
        /**
         * Whether there are different paths in the same layer.
         * means that the target of the drag is not the original path
         * (ensure that the parent node in the same path is not dragged to its child node).
         */
        for (let i = 0; i < dropData.sourcePath.length; i++) {
            if (dropData.targetPath[i] !== dropData.sourcePath[i]) {
                return true;
            }
        }
        /**
         * When the drag is not internal and the target path is equal to the original path.
         * represents the same level of drag but not the original location
         */
        return (dropData.targetData.state.dropPosition !== 2 /* inside */ &&
            dropData.targetPath.length === dropData.sourcePath.length);
    }
    /**
     * Change the target node of the drag
     */
    changeDragTarget(dragTarget) {
        this.dragTarget = dragTarget;
    }
    /**
     * the move function is triggered When the node is dragged
     * @param dropData Drag and drop the data
     */
    drop(dropData) {
        this.move(dropData, this.treeData).catch();
    }
    /**
     * Move the drag node to the drag location ---- TODO: Only static trees are supported for now
     * @param dropData Drag and drop the data
     * @param treeData The whole tree data
     * @param isRollBack Rollback or not. The default value is no rollback
     * @returns
     */
    async move(dropData, treeData, isRollBack = false) {
        // verify the drap rule
        if (!this.dropAllowed(dropData)) {
            return;
        }
        // Index position in the array after leaving
        let startIndex, targetParent, targetChildren;
        // Gets the original parent of the current dragged node
        const sourceParent = this.getNodeFromPath(treeData, dropData.sourcePath.slice(0, dropData.sourcePath.length - 1));
        // Gets all the original siblings of the current dragged node
        const sourceChildren = sourceParent && sourceParent.children ? sourceParent.children : treeData;
        // Index position in the array before leaving
        const leaveIndex = sourceChildren.findIndex((child) => {
            return child.id == dropData.sourceData.id;
        });
        // Gets its index position among all the original siblings of the current dragged node
        let sourceIndex = dropData.sourcePath[dropData.sourcePath.length - 1];
        // The current drag operation is the operation to place the node
        const dropPosition = dropData.targetData.state.dropPosition;
        if (dropPosition === 2 /* inside */) {
            // If the destination node is an unloaded item
            if (!dropData.targetData.loaded && this.isAsync) {
                // Gets the selected collector of the corresponding type
                // Load its children first
                await asyncPullNodeChildren(dropData.targetData, this.loadOptions, this.selectIds, this.selectedCollector, this.noneText, this.errorText);
            }
            targetParent = dropData.targetData;
            targetChildren = targetParent.children;
            // When the target node contains children
            if (targetChildren) {
                startIndex = targetChildren.length;
                // Place the dragged node into it
                targetChildren.push(dropData.sourceData);
            }
            else {
                startIndex = 0;
                // Open the dragged node into it
                targetParent.children = [dropData.sourceData];
            }
            // The destination node is in the expanded state
            dropData.targetData.state.opened = true;
        }
        else {
            // console.log(
            //   'dropData.targetData.state.dropPosition->',
            //   dropData.targetData.state.dropPosition,
            // );
            // The current drag and drop operation is a upstream/downstream insert operation
            startIndex =
                dropData.targetPath[dropData.targetPath.length - 1] +
                    (dropPosition === 1 /* up */ ? 0 : 1); // The index position is calculated based on whether the drag is above or below
            // Gets the parent node at the destination location
            targetParent = this.getNodeFromPath(treeData, dropData.targetPath.slice(0, dropData.targetPath.length - 1));
            // Obtain all brothers in the target location
            targetChildren =
                targetParent && targetParent.children
                    ? targetParent.children
                    : treeData;
            // Insert the drag node in the target sibling according to the calculated drag index
            targetChildren.splice(startIndex, 0, dropData.sourceData);
            // If the target sibling is the original sibling and the target index position is before the original index position
            if (targetChildren === sourceChildren && startIndex < sourceIndex) {
                // The original index will be changed backward, so the original index position needs to be updated
                sourceIndex++;
            }
        }
        // The drag node at the original index position is finally removed from the original sibling
        sourceChildren.splice(sourceIndex, 1);
        // Recalculates the path of the index node in the spanning tree
        if (!sourceParent ||
            !targetParent ||
            dropData.sourceData.path.length == 1) {
            // The target of the drag is the topmost level or when the original drag node is at the topmost level
            this.treeData.forEach((nodeTree, index) => {
                this.computeNodeTreePath(nodeTree, [index]);
            });
        }
        else {
            // Start by recalculating all paths under the target parent tree
            this.computeNodeTreePath(targetParent, targetParent.path);
            // As long as the original parent tree is not the target tree, all paths under it are recalculated
            if (targetParent != sourceParent) {
                this.computeNodeTreePath(sourceParent, sourceParent.path);
            }
        }
        // Notifies upper-layer components when it is not rolled back
        if (!isRollBack) {
            const moveData = {
                dropData: transPiTreeDataToPiNode(dropData.sourceData),
                sourceParent: sourceParent
                    ? transPiTreeDataToPiNode(sourceParent)
                    : null,
                sourceChildren: sourceChildren
                    ? sourceChildren.map((node) => {
                        return transPiTreeDataToPiNode(node);
                    })
                    : null,
                targetParent: targetParent
                    ? transPiTreeDataToPiNode(targetParent)
                    : null,
                targetChildren: targetChildren
                    ? targetChildren.map((node) => {
                        return transPiTreeDataToPiNode(node);
                    })
                    : null,
                leaveIndex,
                startIndex,
            };
            // Rollback function
            const rollbackFn = async () => {
                const isSibling = sourceParent === targetParent;
                let targetData;
                // switch (dropPosition) {
                //   case PiDropPosition.up:
                //     if (isSibling) {
                //       targetData =
                //         leaveIndex > sourceChildren.length - 1
                //           ? sourceChildren[sourceChildren.length - 1]
                //           : sourceChildren[leaveIndex];
                //       targetData.state.dropPosition = PiDropPosition.down;
                //     } else {
                //       targetData = sourceParent!;
                //       targetData.state.dropPosition = PiDropPosition.inside;
                //     }
                //     break;
                //   case PiDropPosition.inside:
                //   case PiDropPosition.down:
                //     targetData =
                //       leaveIndex > sourceChildren.length - 1
                //         ? sourceChildren[sourceChildren.length - 1]
                //         : sourceChildren[leaveIndex];
                //     targetData.state.dropPosition = PiDropPosition.up;
                //     break;
                //   default:
                //     targetData = dropData.targetData;
                //     targetData.state.dropPosition = dropPosition;
                //     break;
                // }
                if (leaveIndex > sourceChildren.length - 1) {
                    // The original parent exists, directly as a built-in operation
                    if (sourceParent) {
                        targetData = sourceParent;
                        targetData.state.dropPosition = 2 /* inside */;
                    }
                    else {
                        console.log('down');
                        targetData = sourceChildren[sourceChildren.length - 1];
                        targetData.state.dropPosition = 3 /* down */;
                    }
                }
                else {
                    console.log('up');
                    targetData = sourceChildren[leaveIndex];
                    const paths = targetData.path;
                    dropPosition === 1 /* up */
                        ? ++paths[paths.length - 1]
                        : paths[paths.length - 1];
                    targetData.state.dropPosition = 1 /* up */;
                }
                const data = {
                    sourceData: dropData.sourceData,
                    sourcePath: dropData.sourceData.path,
                    sourceRoot: this.treeData,
                    targetData,
                    targetPath: targetData.path,
                };
                // Waiting for the rollback
                await this.move(data, this.treeData, true);
                targetData.state.dropPosition = 0 /* empty */;
            };
            this.$emit('drop-move', moveData, rollbackFn);
        }
    }
    /**
     * Drag and change the selected link
     * @param dropNode
     * @param sourceParent
     * @param targetParent
     */
    changeSelectByMove(sourceParent, dropNode, isRollBack) {
        // Radio mode
        if (this.singleMode) {
            // The original parent goes up and cancels
            if (sourceParent) {
                sourceParent.state.selected = false;
                this.unselectParentChain(sourceParent);
            }
        }
        else if (this.multipleMode) {
            // Multiple selection mode, filter out the original not selected items for restoration
            if (isRollBack && this.rollIds) {
                // console.log('rollIds->', this.rollIds);
                const ids = this.selectIds;
                const excludeIds = [];
                for (const id of ids) {
                    if (!this.rollIds.includes(id)) {
                        excludeIds.push(id);
                    }
                }
                this.ergodicTreebyHierarchy(this.treeData, (treeNode) => {
                    if (excludeIds.includes(treeNode.id + '')) {
                        treeNode.state.selected = false;
                        this.changeIdsByUnselectNode(treeNode);
                    }
                });
            }
            else {
                // In multiple selection mode, the ID set before rollback is saved
                this.rollIds = JSON.parse(JSON.stringify(this.selectIds));
            }
        }
        // The new parent continues to be selected
        this.selectParentChain(dropNode);
    }
    /**
     * Recalculate the path of the subtree
     * @param nodeTree
     * @param path
     * @returns
     */
    computeNodeTreePath(nodeTree, path) {
        nodeTree.path = path;
        if (nodeTree.children.length == 0) {
            return;
        }
        nodeTree.children.forEach((node, index) => {
            this.computeNodeTreePath(node, path.concat([index]));
        });
    }
};
__decorate([
    Prop({
        type: [Array, String, Number],
        required: undefined,
    })
], PiBaseTreeVue.prototype, "value", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
        required: false,
    })
], PiBaseTreeVue.prototype, "defaultOptions", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], PiBaseTreeVue.prototype, "defaultExpandedIds", void 0);
__decorate([
    Prop({
        type: Function,
        required: false,
    })
], PiBaseTreeVue.prototype, "loadOptions", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseTreeVue.prototype, "draggable", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false,
    })
], PiBaseTreeVue.prototype, "checkbox", void 0);
__decorate([
    Prop({
        type: String,
        default: '没有更多数据了...',
    })
], PiBaseTreeVue.prototype, "noneText", void 0);
__decorate([
    Prop({
        type: String,
        default: '加载失败请稍后重试...',
    })
], PiBaseTreeVue.prototype, "errorText", void 0);
__decorate([
    Watch('value', { immediate: true, deep: true })
], PiBaseTreeVue.prototype, "watch.value", null);
__decorate([
    Watch('selectIds', { immediate: true, deep: true })
], PiBaseTreeVue.prototype, "watch.selectIds", null);
__decorate([
    Watch('loadOptions', { immediate: true })
], PiBaseTreeVue.prototype, "watch.loadOptions", null);
PiBaseTreeVue = PiBaseTreeVue_1 = __decorate([
    Component({
        template,
        components: {
            PiTree,
            Icon,
        },
    })
], PiBaseTreeVue);
export default PiBaseTreeVue;
