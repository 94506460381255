import { __decorate } from "tslib";
import { hConvertAttr } from 'bc$/utils';
import { PiUndefined } from 'bc$/decorators/pi-undefined.decorator';
/**
 * 更新行
 */
export class RowSetUpdate {
    constructor(options) {
        hConvertAttr(this, 'attrJson', 'attrObj');
        const paramType = typeof options;
        switch (paramType) {
            case 'string':
            case 'object':
                let obj;
                if (paramType === 'string') {
                    obj = JSON.parse(options);
                }
                else {
                    obj = options;
                }
                for (const key in this) {
                    this[key] = obj[key];
                }
        }
    }
    /**
     * 行 id
     */
    pkid;
    /**
     * 行 attrJson
     */
    attrJson;
    /**
     * 自定义数据对象
     */
    attrObj;
}
__decorate([
    PiUndefined()
], RowSetUpdate.prototype, "pkid", void 0);
