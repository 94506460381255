import { removeListener } from './listener';
import { intFilter } from './int';
import { passwordFilterAdd } from './password';
import { phoneFilter } from './phone';
import { priceFilter } from './price';
import { regexFilter } from './regex';
import { specialFilterAdd } from './special';
import { urlFilter } from './url';
import { getInputAndTagName } from './utils';
/**
 *  实现功能
 *  1、默认情况下只禁止空格输入
 *  2、限制只能输入整数
 *  3、限制只能输入整数和小数（价格类）
 *  4、限制只能输入手机号
 *  5、限制最大值和最小值(抛出错误给回调函数)
 */
export const InputFilter = {
    bind(el, binding, vnode) {
        const { input, tagName } = getInputAndTagName(el, binding, vnode);
        if (!input) {
            return;
        }
        switch (binding.arg) {
            case 'pwd':
                passwordFilterAdd(input, tagName);
                break;
            case 'int':
                intFilter(input, tagName);
                break;
            case 'price':
                priceFilter(input, tagName);
                break;
            case 'special':
                specialFilterAdd(input, tagName);
                break;
            case 'phone':
                phoneFilter(input, tagName);
                break;
            case 'url':
                urlFilter(input, tagName);
                break;
            default:
                const options = binding.value;
                options &&
                    options.regStr &&
                    regexFilter(input, options.regStr, tagName);
                break;
        }
    },
    unbind(el, binding, vnode) {
        const { input, tagName } = getInputAndTagName(el, binding, vnode);
        if (!input) {
            return;
        }
        //Input组件绑定的事件,vue会自己解绑
        if (tagName !== 'Input') {
            const obj = input.__input_filter__ || {};
            const keys = Object.keys(obj);
            for (const key of keys) {
                const callback = obj[key];
                typeof callback === 'function' && removeListener(input, key, callback);
            }
        }
    },
};
