export class ErrorCodeEntity {
    /**
     * /system/#/login
     */
    Login;
    /**
     * /system/#/401
     */
    C401;
    /**
     * /system/#/403
     */
    C403;
    /**
     * /system/#/404
     */
    C404;
    /**
     * /system/#/500
     */
    C500;
    /**
     * /system/#/501
     */
    C501;
    /**
     * /system/#/502
     */
    C502;
    /**
     * /system/#/503
     */
    C503;
}
