/**
 * 维度类型 single：单维，multy：多维
 */
export var DataSourceOptionsDimTypeEnum;
(function (DataSourceOptionsDimTypeEnum) {
    /**
     * 单维
     */
    DataSourceOptionsDimTypeEnum[DataSourceOptionsDimTypeEnum["Single"] = 1] = "Single";
    /**
     * 多维
     */
    DataSourceOptionsDimTypeEnum[DataSourceOptionsDimTypeEnum["Multy"] = 2] = "Multy";
})(DataSourceOptionsDimTypeEnum || (DataSourceOptionsDimTypeEnum = {}));
/**
 * 维度节点选中类型 single：单个，multy：多个
 */
export var DataSourceOptionsSelectTypeEnum;
(function (DataSourceOptionsSelectTypeEnum) {
    /**
     * 单个
     */
    DataSourceOptionsSelectTypeEnum[DataSourceOptionsSelectTypeEnum["Single"] = 1] = "Single";
    /**
     * 多个
     */
    DataSourceOptionsSelectTypeEnum[DataSourceOptionsSelectTypeEnum["Multy"] = 2] = "Multy";
})(DataSourceOptionsSelectTypeEnum || (DataSourceOptionsSelectTypeEnum = {}));
/**
 * 数据源显示方式 none：正常显示，node：只显示维度，dim：显示维度相关
 */
export var DataSourceOptionsShowTypeEnum;
(function (DataSourceOptionsShowTypeEnum) {
    /**
     * 正常显示全部
     */
    DataSourceOptionsShowTypeEnum[DataSourceOptionsShowTypeEnum["None"] = 1] = "None";
    /**
     * 只显示维度节点树
     */
    DataSourceOptionsShowTypeEnum[DataSourceOptionsShowTypeEnum["Node"] = 2] = "Node";
    /**
     * 显示维度节点树+能源类型
     */
    DataSourceOptionsShowTypeEnum[DataSourceOptionsShowTypeEnum["NodeAndType"] = 3] = "NodeAndType";
    /**
     * 显示维度节点树和时间
     */
    DataSourceOptionsShowTypeEnum[DataSourceOptionsShowTypeEnum["NodeAndTime"] = 4] = "NodeAndTime";
})(DataSourceOptionsShowTypeEnum || (DataSourceOptionsShowTypeEnum = {}));
/**
 * 查询条件时间类型枚举
 */
export var DataSourceOptionsDefaultTimeNodeEnum;
(function (DataSourceOptionsDefaultTimeNodeEnum) {
    /**
     * 年
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["Year"] = 0] = "Year";
    /**
     * 月
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["Month"] = 1] = "Month";
    /**
     * 日
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["Day"] = 2] = "Day";
    /**
     * 时
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["Hour"] = 3] = "Hour";
    /**
     * 15分钟
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["Min"] = 9] = "Min";
    /**
     * 周
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["Week"] = 10] = "Week";
    /**
     * 季
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["Quarter"] = 11] = "Quarter";
    /**
     * 实时十五分钟
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["CurMin"] = 12] = "CurMin";
    /**
     * 今天
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["CurDay"] = 13] = "CurDay";
    /**
     * 本周
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["CurWeek"] = 14] = "CurWeek";
    /**
     * 本月
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["CurMonth"] = 15] = "CurMonth";
    /**
     * 本季
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["CurQuarter"] = 16] = "CurQuarter";
    /**
     * 本年
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["CurYear"] = 17] = "CurYear";
    /**
     * 昨天
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["LastDay"] = 18] = "LastDay";
    /**
     * 上周
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["LastWeek"] = 19] = "LastWeek";
    /**
     * 上月
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["LastMonth"] = 20] = "LastMonth";
    /**
     * 上季
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["LastQuarter"] = 21] = "LastQuarter";
    /**
     * 去年
     */
    DataSourceOptionsDefaultTimeNodeEnum[DataSourceOptionsDefaultTimeNodeEnum["LastYear"] = 22] = "LastYear";
})(DataSourceOptionsDefaultTimeNodeEnum || (DataSourceOptionsDefaultTimeNodeEnum = {}));
/**
 * 节点类型
 */
export var NodeTypeEnum;
(function (NodeTypeEnum) {
    /**
     * 当前节点
     */
    NodeTypeEnum[NodeTypeEnum["Default"] = 0] = "Default";
    /**
     * 子节点
     */
    NodeTypeEnum[NodeTypeEnum["ChildNode"] = 1] = "ChildNode";
    /**
     * 父节点
     */
    NodeTypeEnum[NodeTypeEnum["ParentNode"] = 2] = "ParentNode";
    /**
     * 兄弟点
     */
    NodeTypeEnum[NodeTypeEnum["SiblingNode"] = 3] = "SiblingNode";
})(NodeTypeEnum || (NodeTypeEnum = {}));
