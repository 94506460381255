var PiBaseTwentyFourTimeRange_1;
import { __decorate } from "tslib";
import { BcVersion } from 'bc$/bases/config.base';
import { VueBase } from 'bc$/main';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import template from './pi-base-twenty-four-time-range.component.html';
// tslint:disable-next-line: no-import-side-effect
import './pi-base-twenty-four-time-range.component.scss';
import { CellClickTypeEnum } from './pi-base-twenty-four-time-range.enum';
let PiBaseTwentyFourTimeRange = PiBaseTwentyFourTimeRange_1 = class PiBaseTwentyFourTimeRange extends Mixins(VueBase) {
    //#region 构造函数
    //#endregion
    //#region 静态属性
    /**
     * 组件名称
     */
    static NAME = 'PiBaseTwentyFourTimeRange';
    /**
     * 关于信息
     */
    static ABOUT = {
        name: PiBaseTwentyFourTimeRange_1?.NAME,
        author: 'hex',
        title: '选择24小时时间范围',
        version: BcVersion,
        updateDate: '2020-01-20',
        description: '',
    };
    //#endregion
    //#region props
    value;
    //#endregion
    //#region data
    /**
     * 开始小时，0-23
     */
    beginHourList = [];
    /**
     * 开始分钟，0-59
     */
    beginMinuteList = [];
    /**
     * 开始小时，0-24
     */
    endHourList = [];
    /**
     * 开始小时，0-59或者0
     */
    endMinuteList = [];
    /**
     * 刻度点击类型
     */
    CellClickTypeEnum = CellClickTypeEnum;
    /**
     * 当前已格式后的时间
     */
    currentTime = '';
    currentTimeEdit = '';
    /**
     * 选择面板的显示
     */
    showSelectPanel = false;
    /**
     * 清除
     */
    showClear = false;
    //#endregion
    //#region computed
    /**
     * 组件关于信息
     */
    get about() {
        return PiBaseTwentyFourTimeRange_1.ABOUT;
    }
    /**
     * 组件标题
     */
    get titleAll() {
        return this.about.title;
    }
    get currentBeginHour() {
        return (this.beginHourList.find((item) => item.selected) || this.beginHourList[0]);
    }
    get currentBeginMinute() {
        return (this.beginMinuteList.find((item) => item.selected) ||
            this.beginMinuteList[0]);
    }
    get currentEndHour() {
        return (this.endHourList.find((item) => item.selected) || this.endHourList[0]);
    }
    get currentEndMinute() {
        return (this.endMinuteList.find((item) => item.selected) || this.endMinuteList[0]);
    }
    get selectedBeginHourIndex() {
        let index = 0;
        for (let item of this.beginHourList) {
            if (item.selected) {
                return index;
            }
            if (!item.hide) {
                ++index;
            }
        }
        return index;
    }
    get selectedBeginMinuteIndex() {
        let index = 0;
        for (let item of this.beginMinuteList) {
            if (item.selected) {
                return index;
            }
            if (!item.hide) {
                ++index;
            }
        }
        return index;
    }
    get selectedEndHourIndex() {
        let index = 0;
        for (let item of this.endHourList) {
            if (item.selected) {
                return index;
            }
            if (!item.hide) {
                ++index;
            }
        }
        return index;
    }
    get selectedEndMinuteIndex() {
        let index = 0;
        for (let item of this.endMinuteList) {
            if (item.selected) {
                return index;
            }
            if (!item.hide) {
                ++index;
            }
        }
        return index;
    }
    //#endregion
    //#region watch
    /**
     * 00:00-24:00
     * @param newValue
     */
    'watch.value'(newValue) {
        if (newValue && newValue.length == 2) {
            this.linkInputToPanel(newValue.join('-'), false);
        }
    }
    //#endregion
    //#region methods
    /**
     * 初始化小时，分钟范围
     */
    init() {
        // 开始小时
        this.beginHourList.splice(0);
        for (let i = 0; i <= 23; i++) {
            this.beginHourList.push({
                index: i,
                value: i,
                selected: i === 0 ? true : false,
                hide: false,
            });
        }
        // 开始分钟，结束分钟
        this.beginMinuteList.splice(0);
        this.endMinuteList.splice(0);
        for (let i = 0; i <= 59; i++) {
            this.beginMinuteList.push({
                index: i,
                value: i,
                selected: i === 0 ? true : false,
                hide: false,
            });
            this.endMinuteList.push({
                index: i,
                value: i,
                selected: i === 0 ? true : false,
                hide: false,
            });
        }
        // 结束小时
        this.endHourList.splice(0);
        for (let i = 0; i <= 24; i++) {
            this.endHourList.push({
                index: i,
                value: i,
                selected: i === 0 ? true : false,
                hide: false,
            });
        }
    }
    /**
     * 输入字符串关联选择面板
     * @param value
     */
    linkInputToPanel(value, isInput) {
        const validRegExp = /^([0-1]{0,1}\d|2[0-3]):[0-5][0-9]\s*-\s*((([0-1]{0,1}\d|2[0-3]):[0-5][0-9])|24:00)$/;
        if (value && validRegExp.test(value)) {
            const trimValue = value.replace(/\s*/g, '');
            const splitValueArray = trimValue.split('-');
            const beginSplitArray = splitValueArray[0].split(':');
            const endSplitArray = splitValueArray[1].split(':');
            const beginHour = Number(beginSplitArray[0]);
            const beginMinute = Number(beginSplitArray[1]);
            const endHour = Number(endSplitArray[0]);
            const endMinute = Number(endSplitArray[1]);
            // 选中
            const beginHourIndex = this.beginHourList.findIndex((item) => item.value == beginHour);
            const beginMinuteIndex = this.beginMinuteList.findIndex((item) => item.value == beginMinute);
            const endHourIndex = this.endHourList.findIndex((item) => item.value == endHour);
            const endMinuteIndex = this.endMinuteList.findIndex((item) => item.value == endMinute);
            // 校验结束小时
            this.onEndHourClick(endHour);
            this.setCellSelected(this.beginHourList, beginHourIndex);
            this.setCellSelected(this.beginMinuteList, beginMinuteIndex);
            this.setCellSelected(this.endHourList, endHourIndex);
            this.setCellSelected(this.endMinuteList, endMinuteIndex);
            // 滚动位置
            this.scrollAllCell();
            // 计算输入框值
            !isInput && this.reComputedCurrentTime();
        }
        else {
            !isInput && (this.currentTimeEdit = this.currentTime);
        }
    }
    /**
     * 设置选择状态
     * @param cellList
     * @param selectedIndex
     */
    setCellSelected(cellList, selectedIndex) {
        cellList.forEach((item, index) => {
            if (index === selectedIndex) {
                item.selected = true;
            }
            else if (item.selected) {
                item.selected = false;
            }
        });
    }
    /**
     * 时间刻度补位
     * @param value
     */
    formatTime(value) {
        return value < 10 ? '0' + value : value + '';
    }
    /**
     * 点击时间刻度（小时，分钟）
     * @param cellList
     * @param index
     * @param clickType
     */
    onCellClick(cellList, index, clickType) {
        // 点击结束小时
        if (clickType == CellClickTypeEnum.EndHour) {
            this.onEndHourClick(cellList[index].value);
        }
        this.setCellSelected(cellList, index);
        this.scrollCell(index, clickType);
        this.reComputedCurrentTime();
    }
    /**
     * 点击结束小时，24为特殊
     * @param index
     */
    onEndHourClick(value) {
        if (value == 24) {
            this.endMinuteList.forEach((item, index) => {
                if (index == 0) {
                    item.selected = true;
                }
                else {
                    item.hide = true;
                    if (item.selected) {
                        item.selected = false;
                    }
                }
            });
        }
        else {
            this.endMinuteList.forEach((item, index) => {
                if (item.hide) {
                    item.hide = false;
                }
            });
        }
    }
    /**
     * 滚动所有刻度
     */
    scrollAllCell() {
        this.scrollCell(this.selectedBeginHourIndex, CellClickTypeEnum.BeginHour);
        this.scrollCell(this.selectedBeginMinuteIndex, CellClickTypeEnum.BeginMinute);
        this.scrollCell(this.selectedEndHourIndex, CellClickTypeEnum.EndHour);
        this.scrollCell(this.selectedEndMinuteIndex, CellClickTypeEnum.EndMinute);
    }
    /**
     * 置顶选择时间刻度
     * @param cellItem
     * @param clickType
     */
    scrollCell(index, clickType) {
        const scrollElement = this.$refs['scroll_' + clickType];
        if (scrollElement) {
            const childHeight = scrollElement.children[0].clientHeight;
            if (scrollElement.scrollTo) {
                scrollElement.scrollTo({
                    top: childHeight * index,
                    behavior: 'smooth',
                });
            }
        }
    }
    /**
     * 计算输入框的值
     */
    reComputedCurrentTime() {
        if (this.currentBeginHour.value ||
            this.currentBeginMinute.value ||
            this.currentEndHour.value ||
            this.currentEndMinute.value) {
            this.currentTime =
                this.formatTime(this.currentBeginHour.value) +
                    ':' +
                    this.formatTime(this.currentBeginMinute.value) +
                    '-' +
                    this.formatTime(this.currentEndHour.value) +
                    ':' +
                    this.formatTime(this.currentEndMinute.value);
        }
        else {
            this.currentTime = '';
        }
        this.currentTimeEdit = this.currentTime;
    }
    /**
     * 点击输入框
     */
    onInputClick() {
        if (!this.showSelectPanel) {
            this.openSelectList();
        }
    }
    /**
     * 输入框值变化
     * @param event
     * @param isInput
     */
    onInputChange(event, isInput) {
        const inputValue = event.target.value;
        this.linkInputToPanel(inputValue, isInput);
    }
    /**
     * 打开时间选择面板
     */
    openSelectList() {
        this.showSelectPanel = true;
        window.removeEventListener('click', this.handleSelectClick);
        window.addEventListener('click', this.handleSelectClick);
        this.$nextTick(() => {
            this.scrollAllCell();
        });
    }
    closeSelectList() {
        this.showSelectPanel = false;
        window.removeEventListener('click', this.handleSelectClick);
        const emitValue = Object.assign([], this.currentTime.split('-'));
        this.$emit('input', emitValue);
        this.$emit('on-change', emitValue);
    }
    handleSelectClick(event) {
        const containsTarget = this.$el.contains(event.target);
        if (!containsTarget) {
            this.closeSelectList();
        }
    }
    /**
     * 输入框-鼠标进入
     */
    onInputMouseEnter() {
        if (this.currentTime) {
            this.showClear = true;
        }
    }
    /**
     * 输入框-鼠标离开
     */
    onInputMouseLeave() {
        this.showClear = false;
    }
    /**
     * 清除已选择的时间
     */
    clearTime() {
        this.init();
        this.reComputedCurrentTime();
        this.closeSelectList();
    }
    //#endregion
    //#region hooks
    created() {
        this.init();
    }
    mounted() {
        if (this.value && this.value.length == 2) {
            this.linkInputToPanel(this.value.join('-'), false);
        }
    }
};
__decorate([
    Prop({ type: Array })
], PiBaseTwentyFourTimeRange.prototype, "value", void 0);
__decorate([
    Watch('value')
], PiBaseTwentyFourTimeRange.prototype, "watch.value", null);
PiBaseTwentyFourTimeRange = PiBaseTwentyFourTimeRange_1 = __decorate([
    Component({
        template,
    })
], PiBaseTwentyFourTimeRange);
export default PiBaseTwentyFourTimeRange;
